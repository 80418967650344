import React from "react";

import { useTranslation } from "react-i18next";

import DriversTableItem from "./DriversTableItem/DriversTableItem";
import TableHeader from "./../../../Form/TableFields/TableHeader/TableHeader";
import TableWrapper from "../../../Form/TableFields/TableWrapper/TableWrapper";

const DriversTable = ({ drivers }) => {
  const { t } = useTranslation();
  return (
    <TableWrapper
      emptyText={t("tms.tables.noInfo.no drivers")}
      total={drivers?.length}
    >
      <TableHeader>
        <th>{t("tms.tables.titles.Driver")}</th>
        <th>{t("tms.tables.titles.Phone number")}</th>
        <th>{t("tms.tables.titles.Total rides")}</th>
        <th colSpan='2'>{t("tms.tables.titles.Linked to")}</th>
      </TableHeader>
      <tbody>
        {drivers.map((driver, index) => (
          <DriversTableItem key={index} driver={driver} />
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default DriversTable;
