import axios from "axios";

const _request = async (props, data) => {
  const DATA = data ? (props.file ? data : JSON.stringify(data)) : null;
  const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const config = {
    /// old
    baseURL: "https://api.cargon.com/api/v1/",
    // baseURL: "https://test-crm-api.cargon.com/api/v1/",

    url: props.url,
    method: props.method ? props.method : "get",
    headers: {
      "Content-Type": props.file ? "multipart/form-data" : "application/json",
      Accept: "application/json",
      "Accept-Language": props.ISO ? props.ISO : "en",
      Timezone: TIMEZONE || "Asia/Tbilisi",
      Authorization: props.token ? "Bearer " + props.token : null,
    },
    ...(DATA && { data: DATA }),
  };
  const response = await axios(config);
  return response.data;
};

export default _request;
