import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import statusUpdatesService from "./statusUpdatesService";

const initialState = {
  getRoadStatusesLoading: true,
  getRoadStatusesResult: null,
  getRoadStatusesSelect: null,
  getRoadStatusesError: null,

  storeStatusUpdateLoading: false,
  storeStatusUpdateResult: null,
  storeStatusUpdateError: null,

  getOrderStatusUpdatesLoading: true,
  getOrderStatusUpdatesResult: null,
  getOrderStatusUpdatesError: null,
};

export const getRoadStatuses = createAsyncThunk(
  "statusUpdates/getRoadStatuses",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await statusUpdatesService.getStatusesList(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const storeStatusUpdate = createAsyncThunk(
  "statusUpdates/storeStatusUpdate",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await statusUpdatesService.storeStatusUpdate(ISO, TOKEN, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrderStatusUpdates = createAsyncThunk(
  "statusUpdates/getOrderStatusUpdates",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await statusUpdatesService.getOrderStatusUpdates(ISO, TOKEN, orderId);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const statusUpdatesSlice = createSlice({
  name: "statusUpdates",
  initialState,
  reducers: {
    resetStoreStatusUpdate: (state) => {
      state.storeStatusUpdateLoading = false;
      state.storeStatusUpdateResult = null;
      state.storeStatusUpdateError = null;
    },
    resetGetOrderStatusUpdates: (state) => {
      state.getOrderStatusUpdatesLoading = true;
      state.getOrderStatusUpdatesResult = null;
      state.getOrderStatusUpdatesError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoadStatuses.pending, (state) => {
        state.getRoadStatusesLoading = true;
        state.getRoadStatusesResult = null;
        state.getRoadStatusesSelect = null;
        state.getRoadStatusesError = null;
      })
      .addCase(getRoadStatuses.fulfilled, (state, action) => {
        state.getRoadStatusesLoading = false;
        state.getRoadStatusesResult = action.payload?.filter((statuses) =>
          statuses?.group_type_ids?.includes(1)
        );
        state.getRoadStatusesSelect = action.payload
          ?.filter((statuses) => statuses?.group_type_ids?.includes(1))
          ?.map((roadStatus) => {
            return {
              value: roadStatus,
              label: roadStatus?.name,
            };
          });
        state.getRoadStatusesError = null;
      })
      .addCase(getRoadStatuses.rejected, (state, action) => {
        state.getRoadStatusesLoading = false;
        state.getRoadStatusesResult = null;
        state.getRoadStatusesSelect = null;
        state.getRoadStatusesError = action.error;
      })

      .addCase(storeStatusUpdate.pending, (state) => {
        state.storeStatusUpdateLoading = true;
        state.storeStatusUpdateResult = null;
        state.storeStatusUpdateError = null;
      })
      .addCase(storeStatusUpdate.fulfilled, (state, action) => {
        state.storeStatusUpdateLoading = false;
        state.storeStatusUpdateResult = action.payload;
        state.storeStatusUpdateError = null;
      })
      .addCase(storeStatusUpdate.rejected, (state, action) => {
        state.storeStatusUpdateLoading = false;
        state.storeStatusUpdateResult = null;
        state.storeStatusUpdateError = action.payload;
      })

      .addCase(getOrderStatusUpdates.pending, (state) => {
        state.getOrderStatusUpdatesLoading = true;
        state.getOrderStatusUpdatesResult = null;
        state.getOrderStatusUpdatesError = null;
      })
      .addCase(getOrderStatusUpdates.fulfilled, (state, action) => {
        state.getOrderStatusUpdatesLoading = false;
        state.getOrderStatusUpdatesResult = action.payload;
        state.getOrderStatusUpdatesError = null;
      })
      .addCase(getOrderStatusUpdates.rejected, (state, action) => {
        state.getOrderStatusUpdatesLoading = false;
        state.getOrderStatusUpdatesResult = null;
        state.getOrderStatusUpdatesError = action.payload;
      });
  },
});

export const { resetStoreStatusUpdate, resetGetOrderStatusUpdates } = statusUpdatesSlice.actions;
export default statusUpdatesSlice.reducer;
