import React, { Fragment, useState } from "react";
import "./BankRequisitesFormItem.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IoIosRemoveCircleOutline } from "react-icons/io";

import DInputs from "../../../../../Form/DInputs/DInputs";
import DSelect from "../../../../../Form/DSelect/DSelect";

const BankRequisitesFormItem = ({ bankRequisites, setBankRequisites, item, index }) => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  const { createInvoiceError } = useSelector((state) => state.invoices);

  const [bankAccountValue, setBankAccountValue] = useState(null);

  const handleChangeBankName = (value) => {
    const data = [...bankRequisites];
    data[index].bankName = value;
    setBankRequisites(data);
  };
  const handleChangeBankCode = (value) => {
    const data = [...bankRequisites];
    data[index].bankCode = value;
    setBankRequisites(data);
  };
  const handleChangeBankACCN = (value) => {
    const data = [...bankRequisites];
    data[index].bankACCN = value;
    setBankRequisites(data);
  };
  const handleRemove = () => {
    const data = [...bankRequisites];
    data.splice(index, 1);
    setBankRequisites(data);
  };

  const convertedBacnkArray =
    user?.bank_accounts?.map((account) => ({
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <p style={{ fontSize: "14px", color: "#2b353a", lineHeight: "14px" }}>
            {account.name} ({account.code})
          </p>
          <span style={{ fontSize: "12px", color: "#a8acae", lineHeight: "11px" }}>
            {account.account}
          </span>
        </div>
      ),
      value: account,
    })) || [];

  const getBankError = (fieldName) =>
    createInvoiceError?.errors && createInvoiceError?.errors[`bank.data.${index}.${fieldName}`];

  const bank_name_error = getBankError("name");
  const bank_code_error = getBankError("code");
  const bank_account_error = getBankError("account");

  return (
    <div id='BankRequisitesFormItem'>
      {convertedBacnkArray && convertedBacnkArray.length > 0 && (
        <Fragment>
          <div>
            <DSelect
              id='BankrequisiteSelect'
              label={t("tms.invoice.Select your bank requisite")}
              options={convertedBacnkArray ? convertedBacnkArray : []}
              value={bankAccountValue}
              setValue={(val) => {
                const updatedRequisites = [...bankRequisites];
                updatedRequisites[index] = {
                  bankName: val.value.name,
                  bankCode: val.value.code,
                  bankACCN: val.value.account,
                };
                setBankRequisites(updatedRequisites);
                setBankAccountValue(val);
              }}
              placeholder={t("tms.invoice.Choose your requisite")}
            />
          </div>

          <div className='orContainer'>
            <div className='or'>{t("tms.invoice.OR")}</div>
          </div>
        </Fragment>
      )}

      <div
        className='bankRequisites'
        style={
          index !== bankRequisites?.length - 1
            ? {
                paddingBottom: "16px",
                borderBottom: "1px solid #eaebeb",
              }
            : {}
        }
      >
        <div className='item'>
          <div className='row'>
            <DInputs
              required
              id={"bankNameBankName"}
              placeholder={t("tms.invoice.Enter bank name")}
              label={t("tms.invoice.Bank name")}
              value={item?.bankName}
              setValue={handleChangeBankName}
              error={bank_name_error}
            />
            <DInputs
              required
              id={"bankCodeBankCode"}
              placeholder={t("tms.invoice.Enter bank code")}
              label={t("tms.invoice.Bank code")}
              value={item?.bankCode}
              setValue={handleChangeBankCode}
              error={bank_code_error}
            />
          </div>

          <DInputs
            required
            id='AccountBankNumber'
            placeholder={t("tms.invoice.Enter bank account number")}
            label={t("tms.invoice.Bank account number")}
            value={item?.bankACCN}
            setValue={handleChangeBankACCN}
            error={bank_account_error}
          />
        </div>
        {bankRequisites?.length > 1 && (
          <div className='remove'>
            <div className='icon' onClick={handleRemove}>
              <IoIosRemoveCircleOutline />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BankRequisitesFormItem;
