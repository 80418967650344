import React from "react";
import "./DQuantitySelect.scss";

import { FiPlus, FiMinus } from "react-icons/fi";

import DLabel from "./../../DLabel/DLabel";
import DErrorText from "./../../DErrorText/DErrorText";

const DQuantitySelect = ({ label, value, setCountIn, setCountDec, error, required, disabled }) => {
  return (
    <div
      id='DQuantitySelectNumber'
      className={`${error ? "error" : ""} ${disabled ? "disabled" : ""}`}
    >
      {label && <DLabel label={label} required={required} />}
      <div className='GroupForm'>
        <div
          className={value === 1 ? "disable button" : "button"}
          onClick={() => setCountDec(value)}
        >
          <FiMinus />
        </div>
        <div className='number'>
          <p>{value}</p>
        </div>
        <div className={value > 99 ? "disable button" : "button"} onClick={() => setCountIn(value)}>
          <FiPlus />
        </div>
      </div>
      {error && <DErrorText error={error} />}
    </div>
  );
};

export default DQuantitySelect;
