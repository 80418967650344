import React from "react";
import "./DashboardOrdersTableHeader.scss";

import { useTranslation } from "react-i18next";

import FiltersContainer from "../../../../FilterForms/FiltersContainer/FiltersContainer";
import FiltersActiveStatusSelect from "../../../../FilterForms/FiltersActiveStatusSelect/FiltersActiveStatusSelect";

const DashboardOrdersTableHeader = ({ status, setStatus }) => {
  const { t } = useTranslation();

  return (
    <div id='DashboardOrdersTableHeader'>
      <h1>{t("tms.titles.Active Orders")}</h1>
      <FiltersContainer>
        <FiltersActiveStatusSelect value={status} setValue={setStatus} />
      </FiltersContainer>
    </div>
  );
};

export default DashboardOrdersTableHeader;
