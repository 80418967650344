import React, { Fragment, useEffect, useState } from "react";
import "./EditPartnersPriceModal.scss";

import { useTranslation } from "react-i18next";

import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import {
  updateOrderExpense,
  resetUpdateOrderExpense,
} from "../../../../../store/orders/orderFinance/orderFinanceSlice";

import DModal from "../../../../Modal/DModal/DModal";
import DCurrencyInput from "../../../../Form/DCurrencyInput/DCurrencyInput";

const EditPartnersPriceModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updateOrderExpenseLoading, updateOrderExpenseResult, updateOrderExpenseError } =
    useSelector((state) => state.orderFinance);

  const [expense, setExpense] = useState(order?.expense || "0");
  const [expenseCurrency, setExpenseCurrency] = useState(
    order?.expense_currency
      ? {
          label: order?.expense_currency?.code,
          value: order?.expense_currency,
        }
      : null
  );

  const submitHandler = () => {
    const data = {
      id: order.id,
      expense: expense || null,
      expense_currency_id: expenseCurrency?.value?.id || null,
    };
    dispatch(updateOrderExpense(data));
  };

  useEffect(() => {
    if (updateOrderExpenseResult) {
      dispatch(resetUpdateOrderExpense());
    }
  }, [dispatch, updateOrderExpenseResult]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateOrderExpense());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Edit partner price")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<MdEdit />}
        status='success'
        onClick={() => submitHandler()}
        loading={updateOrderExpenseLoading}
      >
        <div id='EditOrderPriceModal'>
          <div className='EditOrderPriceModalGroup'>
            <DCurrencyInput
              value={expense}
              setValue={setExpense}
              placeholder={t("tms.modals.placeholder.Enter partner price")}
              label={t("tms.modals.labels.Partner price")}
              currencyValue={expenseCurrency}
              setCurrencyValue={setExpenseCurrency}
              required
              id='FinancialDetailsPartnerPrice'
              error={
                (updateOrderExpenseError?.errors?.expense?.[0] ?? null) ||
                (updateOrderExpenseError?.errors?.expense_currency_id?.[0] ?? null)
              }
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditPartnersPriceModal;
