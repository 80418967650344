import React from "react";
import "./DCargoTypesSelect.scss";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import DSelect from "./../DSelect";

const DCargoTypesSelect = ({ value, setValue, error, required, label, placeholder, leftIcon }) => {
  const { t } = useTranslation();

  const { getCargoTypesLoading, getCargoTypesSelect } = useSelector((state) => state.orderSettings);

  return (
    <DSelect
      id='DCargoTypesSelect'
      label={label ? label : t("tms.forms.labels.Product category")}
      placeholder={placeholder ? placeholder : t("tms.forms.placeholder.Choose product category")}
      required={required}
      value={value}
      setValue={setValue}
      loading={getCargoTypesLoading}
      search={true}
      error={error}
      options={getCargoTypesSelect ? getCargoTypesSelect : []}
      leftIcon={leftIcon ? leftIcon : null}
    />
  );
};

export default DCargoTypesSelect;
