import React, { Fragment, useState } from "react";
import "./ShipperOrderDetailsOffer.scss";

import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";

import { useOrderPaymentSelectSubOptions } from "../../../../helper/useOrderPaymentSelectSubOptions";
import { useMoney } from "../../../../helper/useMoney";

import DButton from "../../../Form/DButtons/DButton/DButton";
import ShipperOfferRejectModal from "../ShipperOfferRejectModal/ShipperOfferRejectModal";
import ShipperOfferConfirmModal from "../ShipperOfferConfirmModal/ShipperOfferConfirmModal";

const ShipperOrderDetailsOffer = ({ order }) => {
  const { t } = useTranslation();
  const paymentSubOptions = useOrderPaymentSelectSubOptions();

  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const orderRevenue = useMoney(order?.revenue || 0, order?.revenue_currency?.code || "$");

  const paymentAmount = useMoney(
    order?.client_payment?.amount || 0,
    order?.client_payment?.currency?.symbol || "$"
  );

  const paymentOptionType = paymentSubOptions?.find((option) => {
    return option?.value?.id === order?.client_payment?.option_type;
  });

  return (
    <Fragment>
      {order.revenue > 0 && order.status.id === 2 && (
        <div id='ShipperOrderDetailsOffer'>
          <div className='logo'>
            <svg
              width='60'
              height='60'
              viewBox='0 0 60 60'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0 30.0511C0 29.6928 0 29.3344 0 28.976C0.613811 12.8498 13.8107 0 30.0256 0C46.2404 0 59.4373 12.8498 60 28.9248C60 29.2832 60 29.6416 60 29.9999C60 46.5869 46.5473 59.9999 30.0256 59.9999C13.4527 60.051 0 46.5869 0 30.0511ZM29.9233 9.77813C18.8747 9.77813 9.92327 18.7372 9.92327 29.7952C9.92327 40.8531 18.8747 49.8122 29.9233 49.8122C40.9719 49.8122 49.9233 40.8531 49.9233 29.7952C49.9744 18.7884 40.9719 9.77813 29.9233 9.77813Z'
                fill='#00B56C'
              />
              <path
                d='M39.9489 18.6348V38.703C39.9489 39.0101 39.8978 39.2661 39.7955 39.5221C39.3351 40.8019 37.6983 41.4675 36.4195 40.5972L34.3223 39.2149L26.4451 33.8907V28.4129C26.4451 28.4129 26.4451 28.3617 26.4962 28.3617L26.5474 28.3105C26.5474 28.3105 26.5474 28.3105 26.5985 28.3105L32.7878 32.4573C32.9412 32.5596 33.197 32.662 33.4016 32.662C33.6573 32.662 33.9643 32.5596 34.1689 32.3549C34.3735 32.1501 34.5269 31.8429 34.5269 31.587V23.9078C34.5269 23.0375 34.7315 22.2184 35.1407 21.4505C36.1126 19.8122 37.8006 18.7372 39.7955 18.686C39.8466 18.6348 39.8978 18.6348 39.9489 18.6348Z'
                fill='#00B56C'
              />
              <path
                d='M33.4527 25.6486V31.2288C33.4527 31.3312 33.3504 31.3312 33.3504 31.3312L27.11 27.1845C26.9565 27.0821 26.7008 26.9797 26.4962 26.9797C25.8824 26.9797 25.422 27.4916 25.422 28.0548V35.7339C25.422 36.6042 25.2174 37.4233 24.8082 38.1913C23.8363 39.8295 22.1483 40.9046 20.1535 40.9558C20.1023 40.9558 20.0512 40.9558 20 40.9558V20.9387C20 20.6316 20.0512 20.3756 20.1535 20.1708C20.6138 18.891 22.2506 18.2254 23.5294 19.0957L24.1944 19.5053L33.4527 25.6486Z'
                fill='#00B56C'
              />
            </svg>
          </div>
          <div className='offerTextContainer'>
            <div className='offerTextTitle'>{t("shipper.titles.Cargon offers you")}</div>
            <div className='offerText'>
              {t(
                "shipper.desc.While we strive to meet all scheduled pickup times, please aware that the pickup date might be delayed by one day due potential scheduling adjustments. We'll keep you updated every step of the way to ensure a smooth process"
              )}
            </div>
          </div>
          {order?.client_payment && (
            <div className='paymentDetailsContainer'>
              <div className='paymentDetailsTitle'>{order?.client_payment?.type?.name || ""}</div>

              <div className='paymentDetailsItems'>
                {order?.client_payment?.amount && (
                  <div className='paymentDetailsItem'>
                    <div className='title'>{t("shipper.desc.Pre payment")}</div>
                    <div className='value'>{paymentAmount}</div>
                  </div>
                )}
                {paymentOptionType && (
                  <div className='paymentDetailsItem'>
                    <div className='title'>{t("shipper.desc.Condition")}</div>
                    <div className='value'>{paymentOptionType?.label || ""}</div>
                  </div>
                )}
                {order?.client_payment?.days && (
                  <div className='paymentDetailsItem'>
                    <div className='title'>{t("shipper.desc.Consignation")}</div>
                    <div className='value'>
                      {order?.client_payment?.days || ""} {t("shipper.units.days")}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className='line' />
          <div className='offerContainer'>
            <div className='offerPrice'>
              {orderRevenue} {order?.sale_confirmation_date ? <FaCheckCircle /> : null}
            </div>

            <div className='buttonsContainer'>
              <div className={`${order?.sale_confirmation_date && `disabled`} reject `}>
                <DButton
                  title={t("shipper.buttons.Reject")}
                  onClick={() => setOpenRejectModal(true)}
                  disabled={order?.sale_confirmation_date ? true : false}
                />
              </div>
              <div className={`${order?.sale_confirmation_date && `disabled`} confirm `}>
                <DButton
                  title={t("shipper.buttons.Confirm")}
                  onClick={() => setOpenConfirmModal(true)}
                  disabled={order?.sale_confirmation_date ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {openRejectModal && (
        <ShipperOfferRejectModal
          isOpen={openRejectModal}
          isClose={() => setOpenRejectModal(false)}
          order={order}
        />
      )}
      {openConfirmModal && (
        <ShipperOfferConfirmModal
          isOpen={openConfirmModal}
          isClose={() => setOpenConfirmModal(false)}
          order={order}
        />
      )}
    </Fragment>
  );
};

export default ShipperOrderDetailsOffer;
