import React from "react";
import "./TooltipItem.scss";

import Tooltip from "@mui/material/Tooltip";
import { useMediaQuery } from "@mui/material";

const TooltipItem = ({ title, children }) => {
  const isSmallScreen = useMediaQuery("(max-width:1200px)");

  return (
    <Tooltip
      placement='right'
      title={isSmallScreen ? null : title}
      classes={{ tooltip: "tooltipItem" }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipItem;
