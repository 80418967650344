import React, { Fragment } from "react";
import "./OrdersTable.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import OrdersTableItem from "./OrdersTableItem/OrdersTableItem";
import InfiniteLoading from "./../UI/InfiniteLoading/InfiniteLoading";
import LoadingPage from "./../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import NoMoreData from "./../UI/NoMoreData/NoMoreData";
import NoInformation from "./../../../Form/TableFields/NoInformation/NoInformation";

const OrdersTable = ({
  page,
  setPage,
  activeOrderId,
  setActiveOrderId,
  activeOrderIndex,
  setActiveOrderIndex,
}) => {
  const { t } = useTranslation();
  const {
    getShipperAcceptedOrdersLoading,
    getShipperAcceptedOrdersResult,
    getShipperAcceptedOrdersMeta,
  } = useSelector((state) => state.shipperAccepted);

  const hasMore =
    getShipperAcceptedOrdersMeta &&
    getShipperAcceptedOrdersMeta.current_page < getShipperAcceptedOrdersMeta.last_page;

  const handleLoadMore = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const screenHeight = window.innerHeight;

  return (
    <div id='ShipperActiveOrdersList'>
      {getShipperAcceptedOrdersLoading && page === 1 && <LoadingPage />}

      {getShipperAcceptedOrdersMeta && getShipperAcceptedOrdersResult && (
        <Fragment>
          <InfiniteScroll
            dataLength={getShipperAcceptedOrdersResult.length}
            next={handleLoadMore}
            hasMore={hasMore}
            loader={<InfiniteLoading />}
            endMessage={<NoMoreData />}
            height={screenHeight < 1060 ? "calc(100vh - 400px)" : "735px"}
            initialScrollY={activeOrderIndex ? 50 * activeOrderIndex : 0}
          >
            <table className='table'>
              <thead className='thead'>
                <tr>
                  <th>{t("shipper.table.labels.Order number")}</th>
                  <th>{t("shipper.table.labels.Status")}</th>
                  <th>{t("shipper.table.labels.Route")}</th>
                  <th>{t("shipper.table.labels.Product category")}</th>
                  <th>{t("shipper.table.labels.Shipping type")}</th>
                  <th>{t("shipper.table.labels.Transportation request")}</th>
                  <th style={{ textAlign: "center" }}>
                    {t("shipper.table.labels.Distance covered")}
                  </th>
                  <th>{t("shipper.table.labels.Pick up date")}</th>
                  <th>{t("shipper.table.labels.Price")}</th>
                </tr>
              </thead>
              <tbody>
                {getShipperAcceptedOrdersResult.map((order, index) => (
                  <OrdersTableItem
                    key={order.id}
                    index={index}
                    order={order}
                    activeOrderId={activeOrderId}
                    setActiveOrderId={setActiveOrderId}
                    activeOrderIndex={activeOrderIndex}
                    setActiveOrderIndex={setActiveOrderIndex}
                  />
                ))}
              </tbody>
            </table>

            {getShipperAcceptedOrdersMeta.total === 0 && (
              <NoInformation title={t("shipper.table.desc.No information")} />
            )}
          </InfiniteScroll>
        </Fragment>
      )}
    </div>
  );
};

export default OrdersTable;
