import React, { Fragment, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import SideBar from "../componentsDashboard/SideBar/SideBar";
import Company from "../routes/Company";

import {
  getOrdersSummary,
  getCustomersSummary,
  getPartnersSummary,
  getTrucksSummary,
  getTrailersSummary,
  getDriversSummary,
  getMaintenancesSummary,
} from "./../store/summary/summarySlice";
import { getTruckBrands } from "./../store/fleet/trucks/trucksSlice";

const AppCompany = () => {
  const dispatch = useDispatch();
  const { openSidebar } = useSelector((state) => state.userUI);
  const { takeBoardResult } = useSelector((state) => state.loadBoards);

  const { createOrderResult, deleteOrderResult } = useSelector((state) => state.orders);

  const { createPartnerResult, deletePartnerResult } = useSelector((state) => state.partner);
  const { createCustomerResult, deleteCustomerResult } = useSelector((state) => state.customer);
  const { createTruckResult, deleteTruckResult } = useSelector((state) => state.trucks);
  const { createTrailerResult, deleteTrailerResult } = useSelector((state) => state.trailers);
  const { createDriverResult, deleteDriverResult } = useSelector((state) => state.drivers);
  const { createMaintenanceResult, deleteMaintenanceResult } = useSelector(
    (state) => state.maintenance
  );
  const { manageTruckResult } = useSelector((state) => state.trucks);

  useEffect(() => {
    dispatch(getTruckBrands());
  }, [dispatch]);

  // count order
  useEffect(() => {
    dispatch(getOrdersSummary());
  }, [dispatch, createOrderResult, deleteOrderResult, takeBoardResult]);

  useEffect(() => {
    dispatch(getCustomersSummary());
    dispatch(getPartnersSummary());
  }, [
    dispatch,
    createPartnerResult,
    deletePartnerResult,
    createCustomerResult,
    deleteCustomerResult,
  ]);

  // count truck
  useEffect(() => {
    dispatch(getTrucksSummary());
  }, [dispatch, createTruckResult, deleteTruckResult, manageTruckResult]);

  // count trailer
  useEffect(() => {
    dispatch(getTrailersSummary());
  }, [dispatch, createTrailerResult, deleteTrailerResult]);

  // count driver
  useEffect(() => {
    dispatch(getDriversSummary());
  }, [dispatch, createDriverResult, deleteDriverResult]);

  // count Maintenance
  useEffect(() => {
    dispatch(getMaintenancesSummary());
  }, [dispatch, createMaintenanceResult, deleteMaintenanceResult]);

  return (
    <Fragment>
      <SideBar />
      <main className={openSidebar ? "max" : "min"}>{<Company />}</main>
    </Fragment>
  );
};

export default AppCompany;
