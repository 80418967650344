import React from "react";
import "./LandingMap.scss";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import VectorMap from "./VectorMap/VectorMap";
import SmallHomeTitle from "../Components/SmallHomeTitle/SmallHomeTitle";

const LandingMap = () => {
  const { t } = useTranslation();

  return (
    <section id='LandingMap' className='LandingMap'>
      <div className='title'>
        <SmallHomeTitle
          title={parse(
            t(
              "landing.titles.landingMainTitles.Our regional office and logistics footprint"
            )
          )}
        />
      </div>
      <VectorMap />
    </section>
  );
};

export default LandingMap;
