import React from "react";
import "./Nav.scss";

import { useTranslation } from "react-i18next";
import { RiDashboardFill } from "react-icons/ri";
import { FaUserFriends, FaTruck, FaMapMarkedAlt, FaMobileAlt } from "react-icons/fa";
import { MdLibraryBooks, MdPayments } from "react-icons/md";
import { PiBagFill } from "react-icons/pi";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { IoMdCalendar } from "react-icons/io";

import { useSelector } from "react-redux";

import NavItem from "../NavItem/NavItem";

const Nav = () => {
  const { t } = useTranslation();
  const { openSidebar } = useSelector((state) => state.userUI);
  const { userType } = useSelector((state) => state.auth);
  const { getOrderscountResponce } = useSelector((state) => state.summary);

  let SIDEBARNAVIGATION;
  switch (userType) {
    case 4:
      SIDEBARNAVIGATION = [
        {
          icon: <RiDashboardFill />,
          text: t("tms.menu.Dashboard"),
          to: "/dashboard/tms/home",
          activePath: "/dashboard/tms/home",
        },
        {
          icon: <MdLibraryBooks />,
          text: t("tms.menu.My Bookings"),
          to: "/dashboard/tms/orders",
          activePath: "/dashboard/tms/orders",
        },
        {
          icon: <PiBagFill />,
          text: t("tms.menu.Load Boards"),
          to: "/dashboard/tms/load_boards",
          activePath: "/dashboard/tms/load_boards",
          badge: getOrderscountResponce?.taken_bids_count || "",
        },
        {
          icon: <IoMdCalendar />,
          text: t("tms.menu.Calendar"),
          to: "/dashboard/tms/planning_assistant",
          activePath: "/dashboard/tms/planning_assistant",
        },
        {
          icon: <MdPayments />,
          text: t("tms.menu.Payment Management"),
          to: "/dashboard/tms/financial/active",
          activePath: "/dashboard/tms/financial/",
        },
        {
          icon: <FaUserFriends />,
          text: t("tms.menu.Customers & Partners"),
          to: "/dashboard/tms/customers_partners/customers",
          activePath: "/dashboard/tms/customers_partners",
        },
        {
          icon: <FaTruck />,
          text: t("tms.menu.Fleet Management"),
          to: "/dashboard/tms/fleet_management/trucks",
          activePath: "/dashboard/tms/fleet_management/",
        },
        // {
        //   icon: <FaMapMarkedAlt />,
        //   text: "Tracking Management",
        //   to: "/dashboard/tms/tracking",
        //   activePath: "/dashboard/tms/tracking",
        // },
        // {
        //   icon: <FaLocationCrosshairs />,
        //   text: "Connect GPS",
        //   to: "/dashboard/tms/tracker/GPS_tracking",
        //   activePath: "/dashboard/tms/tracker",
        // },
        {
          icon: <FaMapMarkedAlt />,
          text: t("tms.menu.Tracking"),
          to: "/dashboard/tms/tracking",
          activePath: "/dashboard/tms/tracking",
          menu: [
            {
              icon: <FaMapMarkedAlt />,
              text: t("tms.menu.Tracking Management"),
              to: "/dashboard/tms/tracking",
              activePath: "/dashboard/tms/tracking",
            },
            {
              icon: <FaLocationCrosshairs />,
              text: t("tms.menu.Connect GPS"),
              to: "/dashboard/tms/tracker/GPS_tracking",
              activePath: "/dashboard/tms/tracker/GPS_tracking",
            },
            {
              icon: <FaMobileAlt />,
              text: t("tms.menu.Connect APP"),
              to: "/dashboard/tms/tracker/mobile_tracking",
              activePath: "/dashboard/tms/tracker/mobile_tracking",
            },
          ],
        },
      ];
      break;
    case 5:
      SIDEBARNAVIGATION = [
        {
          icon: <RiDashboardFill />,
          text: t("tms.menu.Dashboard"),
          to: "/dashboard/shipper/home",
          activePath: "/dashboard/shipper/home",
        },
        // {
        //   icon: <MdLibraryBooks />,
        //   text: "My Bookings",
        //   to: "/dashboard/shipper/orders",
        //   activePath: "/dashboard/shipper/orders",
        // },
      ];
      break;
    case 2:
      SIDEBARNAVIGATION = [
        {
          icon: <RiDashboardFill />,
          text: t("tms.menu.Dashboard"),
          to: "/dashboard/carrier/home",
          activePath: "/dashboard/carrier/home",
        },
      ];
      break;
    default:
      return;
  }

  return (
    <div id='Nav' className={openSidebar ? "max" : "min"}>
      <ul className='list'>
        {SIDEBARNAVIGATION.map((item, index) => (
          <NavItem
            key={index}
            icon={item.icon}
            text={item.text}
            to={item.to}
            activePath={item.activePath}
            menu={item.menu}
            badge={item?.badge}
          />
        ))}
      </ul>
    </div>
  );
};

export default Nav;
