import React, { Fragment, useEffect } from "react";
import "./QuotesTable.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  getShipperActiveOrders,
  resetGetShipperActiveOrders,
} from "../../../store/orders/ordersSlice";

import QuotesTableHeader from "./QuotesTableHeader/QuotesTableHeader";
import TableWrapper from "../../Form/TableFields/TableWrapper/TableWrapper";
import QuotesTableItem from "./QuotesTableItem/QuotesTableItem";
import LoadingPage from "./../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const QuotesTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getShipperActiveOrdersLoading,
    getShipperActiveOrdersResult,
    getShipperActiveOrdersMeta,
  } = useSelector((state) => state.orders);
  const { rejectOrderResult, takeOrderResult } = useSelector((state) => state.shipperOrders);

  useEffect(() => {
    const data = {
      page: 1,
    };
    dispatch(getShipperActiveOrders(data));
    return () => dispatch(resetGetShipperActiveOrders());
  }, [dispatch, rejectOrderResult, takeOrderResult]);

  return (
    <div id='QuotesTable'>
      {getShipperActiveOrdersLoading && <LoadingPage />}
      {getShipperActiveOrdersResult && getShipperActiveOrdersMeta && (
        <Fragment>
          <QuotesTableHeader badge={getShipperActiveOrdersMeta?.total || 0} />
          <TableWrapper
            emptyText={t("shipper.desc.No order with offer")}
            total={getShipperActiveOrdersResult?.length}
          >
            <tbody>
              {getShipperActiveOrdersResult?.slice(0, 5).map((order) => (
                <QuotesTableItem key={order.id} order={order} />
              ))}
            </tbody>
          </TableWrapper>
        </Fragment>
      )}
    </div>
  );
};

export default QuotesTable;
