import React from "react";
import "./OrderProductWeightField.scss";

import { Tooltip } from "@mui/material";

import { useUniqueProductCategory } from "../../../../helper/useUniqueProductCategory";

import Character from "./../../../UI/Character/Character";
import { useTranslation } from "react-i18next";

const OrderProductWeightField = ({ order }) => {
  const { t } = useTranslation();
  const uniqueType = useUniqueProductCategory(order.cargos);

  const TooltipContent = (
    <div className='OrderProductWeightFieldTooltipContent'>
      {uniqueType.map((item, index) => (
        <p key={index}>
          <span>{index + 1}. </span>
          {item?.name || ""}
        </p>
      ))}
    </div>
  );

  return (
    <td id='OrderProductWeightField'>
      <div className='OrderProductWeightFieldContainer'>
        {uniqueType.length > 1 ? (
          <Tooltip
            placement='top'
            title={TooltipContent}
            classes={{ tooltip: "OrderProductWeightFieldTooltip" }}
          >
            <h1 className='tooltip'>
              <Character max={25} title={order?.cargos[0]?.type?.name || ""} />
              <span> (+{uniqueType.length - 1 || ""})</span>
            </h1>
          </Tooltip>
        ) : (
          <h1>
            <Character max={25} title={order?.cargos[0]?.type?.name || ""} />
          </h1>
        )}

        <p>
          {order?.weight} {t("shipper.units.KG")}
        </p>
      </div>
    </td>
  );
};

export default OrderProductWeightField;
