import React from "react";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import DButton from "../../../../Form/DButtons/DButton/DButton";

const ToBookingsButton = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <DButton
      title={t("tms.forms.buttons.Go To My Bookings")}
      onClick={() => {
        navigate(`/dashboard/tms/orders`);
      }}
    />
  );
};

export default ToBookingsButton;
