import React from "react";
import "./BidsHeader.scss";

import BidsLang from "./../BidsLang/BidsLang";

const BidsHeader = () => {
  return (
    <header id='BidsHeader'>
      <nav className='nav'>
        <BidsLang />
      </nav>
    </header>
  );
};

export default BidsHeader;
