import React from "react";
import "./OrderAddressDateField.scss";

import { MdLocationPin, MdLocationSearching } from "react-icons/md";
import { Tooltip } from "@mui/material";
import moment from "moment";

const OrderAddressDateField = ({ order }) => {
  const from = order?.cargos?.[0]?.locations?.find((A) => A.direction === "A")?.country ?? "";

  const to = order?.cargos?.[0]?.locations?.find((B) => B.direction === "B")?.country ?? "";
  const renderTooltip = (
    <div className='OrderAddressDateFieldContent'>
      <div>
        {order?.cargos?.[0]?.locations
          ?.filter((A) => A.direction === "A")
          ?.map((item, index) => (
            <div key={item?.id || index} className='routeContainer'>
              <div className='icon' style={{ color: "#b95000" }}>
                <MdLocationSearching />
              </div>
              <p>{item?.formatted_address}</p>
            </div>
          ))}
      </div>
      <div>
        {order?.cargos?.[0]?.locations
          ?.filter((B) => B.direction === "B")
          ?.map((item, index) => (
            <div key={item?.id || index} className='routeContainer'>
              <div className='icon' style={{ color: "#287d3c" }}>
                <MdLocationPin />
              </div>
              <p>{item?.formatted_address}</p>
            </div>
          ))}
      </div>
    </div>
  );

  const formattedDate = moment(order?.created_at).format("DD/MM/YYYY");
  return (
    <td id='OrderAddressDateField'>
      <div className='OrderAddressDateFieldContainer'>
        <Tooltip
          placement='top'
          title={renderTooltip}
          classes={{ tooltip: "OrderAddressDateFieldTooltip" }}
        >
          <h1>
            {from} - {to}
          </h1>
        </Tooltip>

        <p>{formattedDate}</p>
      </div>
    </td>
  );
};

export default OrderAddressDateField;
