import React from 'react';
import './Marker.scss';

import { BiMapPin, BiPhone } from 'react-icons/bi';
import { FiMail, FiX } from 'react-icons/fi';

const Marker = ({ setActiveIndex, index, item, activeIndex }) => {
  return (
    <div id='VectorMapMarker' className='VectorMapMarker'>
      {activeIndex && activeIndex.toString() === index.toString() && (
        <div
          className='animation'
          data-aos='fade-right'
          data-aos-duration='1500'
          data-aos-once='true'
        >
          <div className='markerContent'>
            <div className='header'>
              <h2>{item.name} Office</h2>
              <div className='close' onClick={() => setActiveIndex(null)}>
                <FiX />
              </div>
            </div>
            <div className='body'>
              <ul className='customMarkerlist'>
                <li className='customMarkeritem'>
                  <div className='icon'>
                    <BiMapPin />
                  </div>
                  <p>{item?.address}</p>
                </li>
              </ul>
              <ul className='customMarkerlist'>
                {item?.tel?.map((i, ind) => (
                  <li key={ind} className='customMarkeritem'>
                    <div className='icon'>
                      <BiPhone />
                    </div>
                    <p>{i}</p>
                  </li>
                ))}
              </ul>
              <ul className='customMarkerlist'>
                <li className='customMarkeritem'>
                  <div className='icon'>
                    <FiMail />
                  </div>
                  <p>{item?.email}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Marker;
