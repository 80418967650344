import React, { Fragment, useState } from "react";
import "./FilledPartnerCarrier.scss";

import { useTranslation } from "react-i18next";

import { IoMdBusiness } from "react-icons/io";
import { MdLinkOff } from "react-icons/md";
import { FiEdit2 } from "react-icons/fi";

import Character from "../../../UI/Character/Character";

import DButton from "../../../Form/DButtons/DButton/DButton";
import DIconButton from "../../../Form/DButtons/DIconButton/DIconButton";
import FillCarrierModal from "../../ManageOrder/FilledAssignCarrier/FillCarrierModal/FillCarrierModal";
import LinkOffPartnerModal from "../../ManageOrder/LinkOffPartnerModal/LinkOffPartnerModal";
import EditFillCarrierModal from "../../ManageOrder/FilledAssignCarrier/EditFillCarrierModal/EditFillCarrierModal";

const FilledPartnerCarrier = ({ order }) => {
  const { t } = useTranslation();

  const [showPartnerLinkOffModal, setShowPartnerLinkOffModal] = useState(false);
  const [fillTruckDataModal, setFillTruckDataModal] = useState(false);
  const [editfillTruckDataModal, setEditFillTruckDataModal] = useState(false);

  return (
    <Fragment>
      <div id='FilledPartnerCarrier'>
        <div className='FilledPartnerCarrierItem'>
          <div className='icon'>
            <IoMdBusiness />
          </div>
          <div className='text'>
            <h3>
              <Character title={order?.partner?.name || "No data"} max={30} />
            </h3>
            <p>
              <Character title={order?.partner?.code || "No data"} max={30} />
            </p>
          </div>
        </div>

        <div className='FilledPartnerCarrierItem'>
          <div className='leftSide'>
            <div
              className='cover'
              style={{
                backgroundImage: `url(${`/assets/images/dashboard/cover9.svg`})`,
              }}
            />
            <div className='text'>
              <h3>
                <Character
                  title={order?.transports?.[0]?.number || t("tms.desc.No truck")}
                  max={30}
                />
              </h3>
            </div>
          </div>
          <div className='editable'>
            <DIconButton
              icon={<FiEdit2 />}
              onClick={() => {
                order.transports.length > 0
                  ? setEditFillTruckDataModal(true)
                  : setFillTruckDataModal(true);
              }}
            />
          </div>
        </div>

        <div className='FilledPartnerCarrierItem'>
          <DButton
            onClick={() => setShowPartnerLinkOffModal(true)}
            title={t("tms.forms.buttons.Unlink")}
            rightIcon={<MdLinkOff />}
          />
        </div>
      </div>
      {fillTruckDataModal && (
        <FillCarrierModal
          isOpen={fillTruckDataModal}
          isClose={() => setFillTruckDataModal(false)}
          order={order}
        />
      )}
      {editfillTruckDataModal && (
        <EditFillCarrierModal
          isOpen={editfillTruckDataModal}
          isClose={() => setEditFillTruckDataModal(false)}
          order={order}
        />
      )}
      {showPartnerLinkOffModal && (
        <LinkOffPartnerModal
          ownCarrier
          isOpen={showPartnerLinkOffModal}
          isClose={() => setShowPartnerLinkOffModal(false)}
          order={order}
        />
      )}
    </Fragment>
  );
};

export default FilledPartnerCarrier;
