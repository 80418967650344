import React, { Fragment, useState, useEffect } from "react";
import "./AddNewDocumentModal.scss";

import { useTranslation } from "react-i18next";

import { MdAttachFile } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import {
  createAttachment,
  resetCreateAttachment,
} from "../../../../../store/orders/attachments/attachmentsSlice";

import DModal from "../../../../Modal/DModal/DModal";
import DFile from "./../../../../Form/DFile/DFile";
import DSelect from "./../../../../Form/DSelect/DSelect";
import UploadDocumentItem from "./../UploadDocumentItem/UploadDocumentItem";
import DErrorText from "./../../../../Form/DErrorText/DErrorText";
import DCheckBox from "../../../../Form/DCheckBox/DCheckBox";

const AddNewDocumentModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    createAttachmentLoading,
    createAttachmentResult,
    createAttachmentError,
    getAttachmentTypesLoading,
    getAttachmentTypesSelect,
  } = useSelector((state) => state.attachments);

  const [documentType, setDocumentType] = useState(null);
  const [file, setFile] = useState(null);
  const [isGroup, setIsGroup] = useState(false);

  const isGroupOrder = order?.groups?.length > 0;

  const submitHandler = async () => {
    const today = new Date();
    const data = {
      id: order.id,

      document_date: today.toISOString().split("T")[0],
      type_id: documentType && documentType.value.id,
      document: file,
      publish_to_group: isGroup ? 1 : 0,
    };
    dispatch(createAttachment(data));
  };

  useEffect(() => {
    if (createAttachmentResult) {
      dispatch(resetCreateAttachment());
      isClose();
    }
  }, [dispatch, isClose, createAttachmentResult]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateAttachment());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose()}
        onClick={() => submitHandler()}
        title={t("tms.modals.titles.Upload document")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<MdAttachFile />}
        status='success'
        loading={createAttachmentLoading}
      >
        <div id='AddNewDocumentModal'>
          <form className='formGroup'>
            <DSelect
              id='OrderAdditionalDocumentsAmount'
              value={documentType}
              setValue={setDocumentType}
              placeholder={t("tms.modals.placeholder.Document type")}
              label={t("tms.modals.labels.What type  of document you are uploading?")}
              options={getAttachmentTypesSelect || []}
              loading={getAttachmentTypesLoading}
              required
              error={createAttachmentError?.errors?.type_id?.[0] ?? null}
            />
            {file ? (
              <UploadDocumentItem
                onClick={() => setFile(null)}
                fileName={file.name ? file.name : ""}
              />
            ) : (
              <DFile
                title={t("tms.modals.labels.Choose from your computer")}
                onChange={(e) => setFile(e.target.files[0])}
              />
            )}

            {isGroupOrder && (
              <div className='isGroupCheckbox'>
                <DCheckBox
                  value={isGroup}
                  label={t(
                    "tms.modals.labels.Upload in every truck document section that are related to this order"
                  )}
                  setValue={setIsGroup}
                />
              </div>
            )}

            <DErrorText
              error={
                (createAttachmentError?.errors?.document?.[0] ?? null) ||
                (createAttachmentError === "Network Error" &&
                  t("tms.modals.errors.Reduce file size"))
              }
            />
          </form>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddNewDocumentModal;
