import React from "react";
import "./ByMode.scss";

import { useTranslation } from "react-i18next";
import { LiaShippingFastSolid } from "react-icons/lia";
import { RiShipLine } from "react-icons/ri";
import { BsAirplane } from "react-icons/bs";

const ByMode = ({ analyticsData }) => {
  const { t } = useTranslation();

  return (
    <div id='ByMode'>
      <div className='ByModeItem'>
        <div className='icon'>
          <LiaShippingFastSolid />
        </div>
        <h3>{t("shipper.titles.Road freight")}</h3>
        <div className='desc'>
          <h5>{analyticsData?.road_freight}</h5>
          <div className='line'></div>
          <h4>{analyticsData?.road_increase_percent}%</h4>
        </div>
      </div>

      <div className='ByModeItem'>
        <div className='icon'>
          <RiShipLine />
        </div>
        <h3>{t("shipper.titles.Ocean freight")}</h3>
        <div className='desc'>
          <h5>{analyticsData?.ocean_freight}</h5>
          <div className='line'></div>
          <h4>{analyticsData?.ocean_increase_percent}%</h4>
        </div>
      </div>

      <div className='ByModeItem'>
        <div className='icon'>
          <BsAirplane />
        </div>
        <h3>{t("shipper.titles.Air freight")}</h3>
        <div className='desc'>
          <h5>{analyticsData?.air_freight}</h5>
          <div className='line'></div>
          <h4>{analyticsData?.air_increase_percent}%</h4>
        </div>
      </div>
    </div>
  );
};

export default ByMode;
