import React, { Fragment, useEffect, useState } from "react";
import "./EditOrderPriceModal.scss";

import { useTranslation } from "react-i18next";

import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import {
  updateOrderRevenue,
  resetUpdateOrderRevenue,
} from "../../../../../store/orders/orderFinance/orderFinanceSlice";

import DModal from "../../../../Modal/DModal/DModal";
import DCurrencyInput from "../../../../Form/DCurrencyInput/DCurrencyInput";

const EditOrderPriceModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updateOrderRevenueLoading, updateOrderRevenueResult, updateOrderRevenueError } =
    useSelector((state) => state.orderFinance);

  const [orderRevenue, setOrderRevenue] = useState(order?.revenue || "0");
  const [orderRevenueCurrency, setOrderRevenueCurrency] = useState(
    {
      label: order?.revenue_currency?.code,
      value: order?.revenue_currency,
    } || null
  );

  const submitHandler = () => {
    const data = {
      id: order.id,
      revenue: orderRevenue || null,
      revenue_currency_id: orderRevenueCurrency?.value?.id || null,
    };
    dispatch(updateOrderRevenue(data));
  };

  useEffect(() => {
    if (updateOrderRevenueResult) {
      dispatch(resetUpdateOrderRevenue());
    }
  }, [dispatch, updateOrderRevenueResult]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateOrderRevenue());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Edit order price")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<MdEdit />}
        status='success'
        onClick={() => submitHandler()}
        loading={updateOrderRevenueLoading}
      >
        <div id='EditOrderPriceModal'>
          <div className='EditOrderPriceModalGroup'>
            <DCurrencyInput
              id='FinancialDetailsOrderPrice'
              value={orderRevenue}
              setValue={setOrderRevenue}
              currencyValue={orderRevenueCurrency}
              setCurrencyValue={setOrderRevenueCurrency}
              placeholder={t("tms.modals.placeholder.Enter order price")}
              label={t("tms.modals.labels.Order price")}
              required
              error={
                (updateOrderRevenueError?.errors?.revenue?.[0] ?? null) ||
                (updateOrderRevenueError?.errors?.revenue_currency_id?.[0] ?? null)
              }
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditOrderPriceModal;
