import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import trucksService from "./trucksService";

const initialState = {
  getTrucksLoading: true,
  getTrucksResult: null,
  getTrucksError: null,

  getTruckLoading: true,
  getTruckResult: null,
  getTruckError: null,

  createTruckLoading: false,
  createTruckResult: null,
  createTruckError: null,

  updateTruckLoading: false,
  updateTruckResult: null,
  updateTruckError: null,

  deleteTruckLoading: false,
  deleteTruckResult: null,
  deleteTruckError: null,

  getTruckBrandsLoading: false,
  getTruckBrandsResult: null,
  getTruckBrandsSelect: null,
  getTruckBrandsError: null,

  manageTruckLoading: false,
  manageTruckResult: null,
  manageTruckError: null,

  getPlanningAssistantTrucksLoading: false,
  getPlanningAssistantTrucksResult: null,
  getPlanningAssistantTrucksError: null,
};

export const getTrucks = createAsyncThunk("trucks/getTrucks", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trucksService.getTrucks(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getTruck = createAsyncThunk("trucks/getTruck", async (id, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trucksService.getTruck(ISO, TOKEN, id);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createTruck = createAsyncThunk("trucks/createTruck", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trucksService.createTruck(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateTruck = createAsyncThunk("trucks/updateTruck", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trucksService.updateTruck(ISO, TOKEN, data.id.toString(), data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteTruck = createAsyncThunk("trucks/deleteTruck", async (id, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trucksService.deleteTruck(ISO, TOKEN, id);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getTruckBrands = createAsyncThunk("trucks/getTruckBrands", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;

    return await trucksService.getTruckBrands(ISO, TOKEN);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const manageTruck = createAsyncThunk("trucks/manageTruck", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trucksService.manageTruck(ISO, TOKEN, data.truckId, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getPlanningAssistantTrucks = createAsyncThunk(
  "trucks/getPlanningAssistantTrucks",
  async (keyword, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trucksService.getPlanningAssistantTrucks(ISO, TOKEN, keyword);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const trucksSlice = createSlice({
  name: "trucks",
  initialState,
  reducers: {
    resetGetTrucks: (state) => {
      state.getTrucksLoading = true;
      state.getTrucksResult = null;
      state.getTrucksError = null;
    },
    resetGetTruck: (state) => {
      state.getTruckLoading = true;
      state.getTruckResult = null;
      state.getTruckError = null;
    },
    resetCreateTruck: (state) => {
      state.createTruckLoading = false;
      state.createTruckResult = null;
      state.createTruckError = null;
    },
    resetUpdateTruck: (state) => {
      state.updateTruckLoading = false;
      state.updateTruckResult = null;
      state.updateTruckError = null;
    },
    resetDeleteTruck: (state) => {
      state.deleteTruckLoading = false;
      state.deleteTruckResult = null;
      state.deleteTruckError = null;
    },
    resetManageTruck: (state) => {
      state.manageTruckLoading = false;
      state.manageTruckResult = null;
      state.manageTruckError = null;
    },
    resetGetPlanningAssistantTrucks: (state) => {
      state.getPlanningAssistantTrucksLoading = true;
      state.getPlanningAssistantTrucksResult = null;
      state.getPlanningAssistantTrucksError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrucks.pending, (state) => {
        state.getTrucksLoading = true;
        state.getTrucksResult = null;
        state.getTrucksError = null;
      })
      .addCase(getTrucks.fulfilled, (state, action) => {
        state.getTrucksLoading = false;
        state.getTrucksResult = action.payload;
        state.getTrucksError = null;
      })
      .addCase(getTrucks.rejected, (state, action) => {
        state.getTrucksLoading = false;
        state.getTrucksResult = null;
        state.getTrucksError = action.payload;
      })

      .addCase(getTruck.pending, (state) => {
        state.getTruckLoading = true;
        state.getTruckResult = null;
        state.getTruckError = null;
      })
      .addCase(getTruck.fulfilled, (state, action) => {
        state.getTruckLoading = false;
        state.getTruckResult = action.payload;
        state.getTruckError = null;
      })
      .addCase(getTruck.rejected, (state, action) => {
        state.getTruckLoading = false;
        state.getTruckResult = null;
        state.getTruckError = action.payload;
      })

      .addCase(createTruck.pending, (state) => {
        state.createTruckLoading = true;
        state.createTruckResult = null;
        state.createTruckError = null;
      })
      .addCase(createTruck.fulfilled, (state, action) => {
        state.createTruckLoading = false;
        state.createTruckResult = action.payload;
        state.createTruckError = null;
      })
      .addCase(createTruck.rejected, (state, action) => {
        state.createTruckLoading = false;
        state.createTruckResult = null;
        state.createTruckError = action.payload;
      })

      .addCase(updateTruck.pending, (state) => {
        state.updateTruckLoading = true;
        state.updateTruckResult = null;
        state.updateTruckError = null;
      })
      .addCase(updateTruck.fulfilled, (state, action) => {
        state.updateTruckLoading = false;
        state.updateTruckResult = action.payload;
        state.updateTruckError = null;
      })
      .addCase(updateTruck.rejected, (state, action) => {
        state.updateTruckLoading = false;
        state.updateTruckResult = null;
        state.updateTruckError = action.payload;
      })

      .addCase(deleteTruck.pending, (state) => {
        state.deleteTruckLoading = true;
        state.deleteTruckResult = null;
        state.deleteTruckError = null;
      })
      .addCase(deleteTruck.fulfilled, (state, action) => {
        state.deleteTruckLoading = false;
        state.deleteTruckResult = action.payload;
        state.deleteTruckError = null;
      })
      .addCase(deleteTruck.rejected, (state, action) => {
        state.deleteTruckLoading = false;
        state.deleteTruckResult = null;
        state.deleteTruckError = action.payload;
      })

      .addCase(getTruckBrands.pending, (state) => {
        state.getTruckBrandsLoading = true;
        state.getTruckBrandsResult = null;
        state.getTruckBrandsSelect = null;
        state.getTruckBrandsError = null;
      })
      .addCase(getTruckBrands.fulfilled, (state, action) => {
        state.getTruckBrandsLoading = false;
        state.getTruckBrandsResult = action.payload;
        state.getTruckBrandsSelect = action.payload.map((truckModel) => ({
          label: truckModel.name,
          value: {
            ...truckModel,
            models: truckModel.models.map((i) => ({
              label: i.name,
              value: i,
            })),
          },
        }));
        state.getTruckBrandsError = null;
      })
      .addCase(getTruckBrands.rejected, (state, action) => {
        state.getTruckBrandsLoading = false;
        state.getTruckBrandsResult = null;
        state.getTruckBrandsSelect = null;
        state.getTruckBrandsError = action.payload;
      })

      .addCase(manageTruck.pending, (state) => {
        state.manageTruckLoading = true;
        state.manageTruckResult = null;
        state.manageTruckError = null;
      })
      .addCase(manageTruck.fulfilled, (state, action) => {
        state.manageTruckLoading = false;
        state.manageTruckResult = action.payload;
        state.manageTruckError = null;
      })
      .addCase(manageTruck.rejected, (state, action) => {
        state.manageTruckLoading = false;
        state.manageTruckResult = null;
        state.manageTruckError = action.payload;
      })

      .addCase(getPlanningAssistantTrucks.pending, (state) => {
        state.getPlanningAssistantTrucksLoading = true;
        state.getPlanningAssistantTrucksResult = null;
        state.getPlanningAssistantTrucksError = null;
      })
      .addCase(getPlanningAssistantTrucks.fulfilled, (state, action) => {
        state.getPlanningAssistantTrucksLoading = false;
        state.getPlanningAssistantTrucksResult = action.payload;
        state.getPlanningAssistantTrucksError = null;
      })
      .addCase(getPlanningAssistantTrucks.rejected, (state, action) => {
        state.getPlanningAssistantTrucksLoading = false;
        state.getPlanningAssistantTrucksResult = null;
        state.getPlanningAssistantTrucksError = action.payload;
      });
  },
});

export const {
  resetGetTrucks,
  resetGetTruck,
  resetCreateTruck,
  resetUpdateTruck,
  resetDeleteTruck,
  resetManageTruck,
  resetGetPlanningAssistantTrucks,
} = trucksSlice.actions;
export default trucksSlice.reducer;
