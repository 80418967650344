import _request from "../../_request";

const getShipperCalendar = async (ISO, TOKEN, data) => {
  let url = "/order/calendar?";
  const { keyword, shipment_type, date } = data || {};

  url += keyword ? `&keyword=${keyword}` : "";
  url += shipment_type ? `&shipment_type=${shipment_type}` : "";
  url += date ? `&date=${date}` : "";

  const config = {
    url: url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const shipperOrdersService = {
  getShipperCalendar,
};

export default shipperOrdersService;
