import React from "react";
import "./DFileFormLargeItem.scss";

import { FiTrash2 } from "react-icons/fi";
import { IoDocumentTextOutline } from "react-icons/io5";

import Character from "../../../UI/Character/Character";

const DFileFormLargeItem = ({ item, removeItem }) => {
  const openFileInBrowser = () => {
    if (item.link) {
      const newTab = window.open(item.link, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        alert("Please allow pop-ups for this website.");
      }
    }
  };

  return (
    <div id='DFileFormLargeItem'>
      <div className='Document'>
        <div className='title' onClick={openFileInBrowser}>
          <div className='fileIcon'>
            <IoDocumentTextOutline />
          </div>

          <h6>
            <Character max={15} title={item?.name || "-"} />
          </h6>
        </div>

        <div className='removeIcon' onClick={() => removeItem(item.id)}>
          <FiTrash2 />
        </div>
      </div>
    </div>
  );
};

export default DFileFormLargeItem;
