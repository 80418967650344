import React, { Fragment, useState } from "react";
import "./PreferencesAction.scss";

import { MdOutlineSettingsSuggest } from "react-icons/md";
import { HiSparkles } from "react-icons/hi2";
import { useTranslation } from "react-i18next";

import DGradientButton from "../../Form/DButtons/DGradientButton/DGradientButton";
import PreferencesModal from "../Modals/PreferencesModal/PreferencesModal";

const PreferencesAction = () => {
  const { t } = useTranslation();
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);

  return (
    <Fragment>
      <div id='PreferencesAction'>
        <div className='leftContainer'>
          <div className='icon'>
            <MdOutlineSettingsSuggest />
          </div>
          <div className='text'>
            {t("tms.desc.Enter your preferences so we can match you with the right shipments.")}
          </div>
        </div>
        <DGradientButton
          title={t("tms.forms.buttons.Set preferences")}
          rightIcon={<HiSparkles />}
          onClick={() => setShowPreferencesModal(true)}
        />
      </div>
      <PreferencesModal
        isOpen={showPreferencesModal}
        isClose={() => setShowPreferencesModal(false)}
      />
    </Fragment>
  );
};

export default PreferencesAction;
