import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import analyticsService from "./analyticsService";

const initialState = {
  getGeneralAnalyticsLoading: false,
  getGeneralAnalyticsResult: null,
  getGeneralAnalyticsError: null,

  getChartAnalyticsLoading: false,
  getChartAnalyticsResult: null,
  getChartAnalyticsError: null,

  getShipperAnalyticsLoading: false,
  getShipperAnalyticsResult: null,
  getShipperAnalyticsError: null,

  getShipperChartAnalyticsLoading: false,
  getShipperChartAnalyticsResult: null,
  getShipperChartAnalyticsError: null,

  getShipperActiveOrdersLoading: true,
  getShipperActiveOrdersResult: null,
  getShipperActiveOrdersMeta: null,
  getShipperActiveOrdersError: null,
};

export const getGeneralAnalytics = createAsyncThunk(
  "analytics/getGeneralAnalytics",
  async (date, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await analyticsService.getGeneralAnalytics(ISO, TOKEN, date);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getChartAnalytics = createAsyncThunk(
  "analytics/getChartAnalytics",
  async (date, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await analyticsService.getChartAnalytics(ISO, TOKEN, date);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShipperAnalytics = createAsyncThunk(
  "analytics/getShipperAnalytics",
  async (date, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await analyticsService.getShipperAnalytics(ISO, TOKEN, date);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShipperChartAnalytics = createAsyncThunk(
  "analytics/getShipperChartAnalytics",
  async (date, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await analyticsService.getShipperChartAnalytics(ISO, TOKEN, date);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShipperActiveOrders = createAsyncThunk(
  "shipperAccepted/getShipperActiveOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await analyticsService.getShipperActiveOrders(ISO, TOKEN, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    resetGetGeneralAnalytics: (state) => {
      state.getGeneralAnalyticsLoading = false;
      state.getGeneralAnalyticsResult = null;
      state.getGeneralAnalyticsError = null;
    },
    resetGetChartAnalytics: (state) => {
      state.getChartAnalyticsLoading = false;
      state.getChartAnalyticsResult = null;
      state.getChartAnalyticsError = null;
    },
    resetGetShipperAnalytics: (state) => {
      state.getShipperAnalyticsLoading = false;
      state.getShipperAnalyticsResult = null;
      state.getShipperAnalyticsError = null;
    },
    resetGetShipperChartAnalytics: (state) => {
      state.getShipperChartAnalyticsLoading = false;
      state.getShipperChartAnalyticsResult = null;
      state.getShipperChartAnalyticsError = null;
    },
    resetGetShipperActiveOrders: (state) => {
      state.getShipperActiveOrdersLoading = true;
      state.getShipperActiveOrdersResult = null;
      state.getShipperActiveOrdersMeta = null;
      state.getShipperActiveOrdersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralAnalytics.pending, (state) => {
        state.getGeneralAnalyticsLoading = true;
        state.getGeneralAnalyticsResult = null;
        state.getGeneralAnalyticsError = null;
      })
      .addCase(getGeneralAnalytics.fulfilled, (state, action) => {
        state.getGeneralAnalyticsLoading = false;
        state.getGeneralAnalyticsResult = action.payload;
        state.getGeneralAnalyticsError = null;
      })
      .addCase(getGeneralAnalytics.rejected, (state, action) => {
        state.getGeneralAnalyticsLoading = false;
        state.getGeneralAnalyticsResult = null;
        state.getGeneralAnalyticsError = action.payload;
      })

      .addCase(getChartAnalytics.pending, (state) => {
        state.getChartAnalyticsLoading = true;
        state.getChartAnalyticsResult = null;
        state.getChartAnalyticsError = null;
      })
      .addCase(getChartAnalytics.fulfilled, (state, action) => {
        state.getChartAnalyticsLoading = false;
        state.getChartAnalyticsResult = action.payload;
        state.getChartAnalyticsError = null;
      })
      .addCase(getChartAnalytics.rejected, (state, action) => {
        state.getChartAnalyticsLoading = false;
        state.getChartAnalyticsResult = null;
        state.getChartAnalyticsError = action.payload;
      })

      .addCase(getShipperAnalytics.pending, (state) => {
        state.getShipperAnalyticsLoading = true;
        state.getShipperAnalyticsResult = null;
        state.getShipperAnalyticsError = null;
      })
      .addCase(getShipperAnalytics.fulfilled, (state, action) => {
        state.getShipperAnalyticsLoading = false;
        state.getShipperAnalyticsResult = action.payload;
        state.getShipperAnalyticsError = null;
      })
      .addCase(getShipperAnalytics.rejected, (state, action) => {
        state.getShipperAnalyticsLoading = false;
        state.getShipperAnalyticsResult = null;
        state.getShipperAnalyticsError = action.payload;
      })

      .addCase(getShipperChartAnalytics.pending, (state) => {
        state.getShipperChartAnalyticsLoading = true;
        state.getShipperChartAnalyticsResult = null;
        state.getShipperChartAnalyticsError = null;
      })
      .addCase(getShipperChartAnalytics.fulfilled, (state, action) => {
        state.getShipperChartAnalyticsLoading = false;
        state.getShipperChartAnalyticsResult = action.payload;
        state.getShipperChartAnalyticsError = null;
      })
      .addCase(getShipperChartAnalytics.rejected, (state, action) => {
        state.getShipperChartAnalyticsLoading = false;
        state.getShipperChartAnalyticsResult = null;
        state.getShipperChartAnalyticsError = action.payload;
      })

      .addCase(getShipperActiveOrders.pending, (state) => {
        state.getShipperActiveOrdersLoading = true;
        state.getShipperActiveOrdersResult = null;
        state.getShipperActiveOrdersMeta = null;
        state.getShipperActiveOrdersError = null;
      })
      .addCase(getShipperActiveOrders.fulfilled, (state, action) => {
        state.getShipperActiveOrdersLoading = false;
        state.getShipperActiveOrdersResult = action.payload.data;
        state.getShipperActiveOrdersMeta = action.payload.meta;
        state.getShipperActiveOrdersError = null;
      })
      .addCase(getShipperActiveOrders.rejected, (state, action) => {
        state.getShipperActiveOrdersLoading = false;
        state.getShipperActiveOrdersResult = null;
        state.getShipperActiveOrdersMeta = null;
        state.getShipperActiveOrdersError = action.payload;
      });
  },
});

export const {
  resetGetGeneralAnalytics,
  resetGetChartAnalytics,
  resetGetShipperAnalytics,
  resetGetShipperChartAnalytics,
  resetGetShipperActiveOrders,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
