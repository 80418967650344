import React, { Fragment } from "react";
import "./NameField.scss";

import { Link } from "react-router-dom";

import Character from "../../../UI/Character/Character";

const NameField = ({ to, label, max, onClick, leftIcon, badge }) => {
  return (
    <Fragment>
      {to && (
        <td id='NameField' className='link'>
          <Link to={to}>
            {leftIcon && leftIcon}
            <Character title={label} max={max ? max : 20} />
            {badge && <div className='badge'>{badge}</div>}
          </Link>
        </td>
      )}

      {onClick && (
        <td id='NameFieldOnClick' onClick={onClick}>
          <p>
            <Character title={label} max={max ? max : 20} />
          </p>
        </td>
      )}
    </Fragment>
  );
};

export default NameField;
