import React, { Fragment, useState } from "react";
import "./ShipperCalendarOrder.scss";

import moment from "moment";
import { Tooltip } from "@mui/material";

import {
  MdLocalShipping,
  MdSubdirectoryArrowLeft,
  MdLocationPin,
  MdAirplanemodeActive,
  MdTimelapse,
} from "react-icons/md";
import { BsBoxSeamFill } from "react-icons/bs";
import { FaWeightHanging } from "react-icons/fa6";
import { BiSolidShip } from "react-icons/bi";
import { useMoney } from "../../../helper/useMoney";
import { FaCheckCircle } from "react-icons/fa";

import QuotationModal from "../../Orders/ShipperOffers/QuotationModal/QuotationModal";
import ShipperCalendarOrderTooltip from "../ShipperCalendarOrderTooltip/ShipperCalendarOrderTooltip";

const ShipperCalendarOrder = ({ order, index, startDate }) => {
  const [openModal, setOpenModal] = useState(false);

  const offer = useMoney(order?.revenue || 0, order?.revenue_currency?.symbol || "$");
  const route =
    order?.cargos?.[0]?.locations?.[0]?.country +
    " - " +
    order?.cargos?.[0]?.locations?.[order?.cargos?.[0]?.locations?.length - 1]?.country;
  const productType = order?.cargos?.[0]?.type?.name;
  const weight = order?.weight;

  const orderStart =
    moment(moment(order?.pickup_date).format("YYYY-MM-DD")).diff(
      moment(startDate).format("YYYY-MM-DD"),
      "days"
    ) >= 0
      ? moment(moment(order?.pickup_date).format("YYYY-MM-DD")).diff(
          moment(startDate).format("YYYY-MM-DD"),
          "days"
        )
      : -0.07;

  const orderEnd =
    moment(moment(order?.dropoff_date).format("YYYY-MM-DD")).diff(
      moment(startDate).format("YYYY-MM-DD"),
      "days"
    ) <= 14
      ? moment(moment(order?.dropoff_date).format("YYYY-MM-DD")).diff(
          moment(startDate).format("YYYY-MM-DD"),
          "days"
        )
      : 14 + 0.1;

  const diff = orderEnd - orderStart;

  return (
    <Fragment>
      <Tooltip
        placement='top'
        arrow
        id='ShipperCalendarOrderTooltip'
        title={<ShipperCalendarOrderTooltip order={order} />}
        classes={{ tooltip: "ShipperCalendarOrderTooltip" }}
      >
        <div
          id='ShipperCalendarOrder'
          style={{
            top: "24px",
            left: "calc(100% /14 * " + orderStart + ")",
            width: "calc(100% / 14 * " + (orderEnd - orderStart + 1) + ")",
          }}
          className={
            order?.status?.id < 3
              ? "quotation"
              : order?.type_group?.id === 1
              ? "road"
              : order?.type_group?.id === 2
              ? "sea"
              : order?.type_group?.id === 3
              ? "air"
              : ""
          }
        >
          <div className='icon'>
            {order?.type_group?.id === 1 && <MdLocalShipping />}
            {order?.type_group?.id === 2 && <BiSolidShip />}
            {order?.type_group?.id === 3 && <MdAirplanemodeActive />}
          </div>
          <div className='separator' />
          {diff >= 2 && order?.revenue > 0 && order?.status?.id < 3 && (
            <div
              className={`offer ${order?.sale_confirmation_date ? "accepted" : ""}`}
              onClick={() => {
                !order?.sale_confirmation_date && setOpenModal(true);
              }}
            >
              <div className='title'>{offer}</div>
              <div className='icon'>
                {order?.sale_confirmation_date ? (
                  <FaCheckCircle style={{ fontSize: "13px" }} />
                ) : (
                  <MdSubdirectoryArrowLeft />
                )}
              </div>
            </div>
          )}
          {order?.status?.id < 3 && order?.revenue === 0 && (
            <div className='orderItem'>
              <div className='icon'>
                <MdTimelapse color='#B95000' />
              </div>
            </div>
          )}
          {route && (
            <div className='orderItem'>
              <div className='icon'>
                <MdLocationPin />
              </div>
              <div className='text'>{route}</div>
            </div>
          )}
          {diff >= 4 && productType && (
            <div className='orderItem'>
              <div className='icon'>
                <BsBoxSeamFill />
              </div>
              <div className='text'>{productType}</div>
            </div>
          )}
          {diff >= 5 && weight && (
            <div className='orderItem'>
              <div className='icon'>
                <FaWeightHanging />
              </div>
              <div className='text'>{weight}KG</div>
            </div>
          )}
        </div>
      </Tooltip>
      {openModal && (
        <QuotationModal isOpen={openModal} isClose={() => setOpenModal(false)} order={order} />
      )}
    </Fragment>
  );
};

export default ShipperCalendarOrder;
