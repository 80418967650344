import React from "react";
import "./Dropdown.scss";

import { FiChevronDown } from "react-icons/fi";
import { NavLink } from "react-router-dom";

const Dropdown = ({ title, menu }) => {
  return (
    <li id='DropdownHeader'>
      <div className='button'>
        <div>
          <p>{title}</p>
        </div>
        <div className='arrowDown'>
          <FiChevronDown />
        </div>
      </div>
      <ul className='list'>
        {menu &&
          menu.map((i, index) => (
            <li key={index} className='item'>
              <NavLink to={i.to}>
                <div>
                  <p>{i.title}</p>
                </div>
              </NavLink>
            </li>
          ))}
      </ul>
    </li>
  );
};

export default Dropdown;
