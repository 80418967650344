import React, { Fragment, useState, useEffect } from "react";
import "./EditCustomerModal.scss";

import { useTranslation } from "react-i18next";
import { FiEdit2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import { updateCustomer, resetUpdateCustomer } from "../../../../store/customer/customerSlice";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";

const EditCustomerModal = ({ item, isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateCustomerLoading, updateCustomerResult, updateCustomerError } = useSelector(
    (state) => state.customer
  );

  const [customerID, setCustomerID] = useState(item?.code || "");
  const [customerName, setCustomerName] = useState(item?.name || "");
  const [email, setEmail] = useState(item?.email || "");
  const [phone, setPhone] = useState(item?.phone || "");
  const [address, setAddress] = useState(item?.address || "");

  const submitHandler = () => {
    const data = {
      id: item.id,

      code: customerID,
      name: customerName,
      email: email,
      phone: phone,
      address: address,
    };
    dispatch(updateCustomer(data));
  };

  useEffect(() => {
    if (updateCustomerResult) {
      dispatch(resetUpdateCustomer());
      isClose();
    }
  }, [dispatch, isClose, updateCustomerResult]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateCustomer());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={isClose}
        title={t("tms.modals.titles.Edit Customer")}
        icon={<FiEdit2 />}
        status='success'
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        loading={updateCustomerLoading}
      >
        <div id='EditCustomerModal'>
          <div className='EditCustomerModalgroup'>
            <DInputs
              value={customerName}
              setValue={setCustomerName}
              placeholder={t("tms.modals.placeholder.Enter customer name")}
              label={t("tms.modals.labels.Customer name")}
              required
              id='customerName'
              error={updateCustomerError?.errors?.name?.[0] ?? null}
            />
            <DInputs
              value={customerID}
              setValue={setCustomerID}
              placeholder={t("tms.modals.placeholder.Enter customer ID")}
              label={t("tms.modals.labels.Customer ID")}
              required
              id='CustomerID'
              error={updateCustomerError?.errors?.code?.[0] ?? null}
            />

            <DInputs
              value={email}
              setValue={setEmail}
              placeholder={t("tms.modals.placeholder.Enter email")}
              label={t("tms.modals.labels.Email")}
              required
              id='CustomerEmail'
              error={updateCustomerError?.errors?.email?.[0] ?? null}
            />
            <DInputs
              id='CustomerPhone'
              value={phone}
              setValue={setPhone}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              error={updateCustomerError?.errors?.phone?.[0] ?? null}
            />
            <DInputs
              value={address}
              setValue={setAddress}
              placeholder={t("tms.modals.placeholder.Enter address")}
              label={t("tms.modals.labels.Address")}
              id='CustomerAddress'
              error={updateCustomerError?.errors?.address?.[0] ?? null}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditCustomerModal;
