import React from "react";
import "./FilterShippingTypeItem.scss";

const FilterShippingTypeItem = ({ icon, title, active, onClick }) => {
  return (
    <div
      id='FilterShippingTypeItem'
      className={active ? "active" : ""}
      onClick={onClick}
    >
      {icon && <div className='icon'>{icon}</div>}
      <div className='title'>{title}</div>
    </div>
  );
};

export default FilterShippingTypeItem;
