import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import manageOrderService from "./manageOrderService";

const initialState = {
  assignCarrierLoading: false,
  assignCarrierResult: null,
  assignCarrierError: null,

  unassignCarrierLoading: false,
  unassignCarrierResult: null,
  unassignCarrierError: null,

  createTransportLoading: false,
  createTransportResult: null,
  createTransportError: null,

  updateTransportLoading: false,
  updateTransportResult: null,
  updateTransportError: null,

  bidsRequestLoading: false,
  bidsRequestResult: null,
  bidsRequestError: null,
};

export const assignCarrier = createAsyncThunk(
  "manageOrder/assignCarrier",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await manageOrderService.assignCarrier(ISO, TOKEN, data && data.orderId, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const unassignCarrier = createAsyncThunk(
  "manageOrder/unassignCarrier",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await manageOrderService.unassignCarrier(ISO, TOKEN, orderId);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createTransport = createAsyncThunk(
  "manageOrder/createTransport",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await manageOrderService.createTransport(ISO, TOKEN, data.orderId, data.data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateTransport = createAsyncThunk(
  "manageOrder/updateTransport",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await manageOrderService.updateTransport(
        ISO,
        TOKEN,
        data.orderId,
        data.transportId,
        data
      );
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const bidsRequest = createAsyncThunk("manageOrder/bidsRequest", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await manageOrderService.bidsRequest(ISO, TOKEN, data && data.orderId, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const manageOrderSlice = createSlice({
  name: "manageOrder",
  initialState,
  reducers: {
    resetAssignCarrier: (state) => {
      state.assignCarrierLoading = false;
      state.assignCarrierResult = null;
      state.assignCarrierError = null;
    },
    resetUnassignCarrier: (state) => {
      state.unassignCarrierLoading = false;
      state.unassignCarrierResult = null;
      state.unassignCarrierError = null;
    },
    resetCreateTransport: (state) => {
      state.createTransportLoading = false;
      state.createTransportResult = null;
      state.createTransportError = null;
    },
    resetUpdateTransport: (state) => {
      state.updateTransportLoading = false;
      state.updateTransportResult = null;
      state.updateTransportError = null;
    },
    resetBidsRequest: (state) => {
      state.bidsRequestLoading = false;
      state.bidsRequestResult = null;
      state.bidsRequestError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(assignCarrier.pending, (state) => {
        state.assignCarrierLoading = true;
        state.assignCarrierResult = null;
        state.assignCarrierError = null;
      })
      .addCase(assignCarrier.fulfilled, (state, action) => {
        state.assignCarrierLoading = false;
        state.assignCarrierResult = action.payload;
        state.assignCarrierError = null;
      })
      .addCase(assignCarrier.rejected, (state, action) => {
        state.assignCarrierLoading = false;
        state.assignCarrierResult = null;
        state.assignCarrierError = action.payload;
      })

      .addCase(unassignCarrier.pending, (state) => {
        state.unassignCarrierLoading = true;
        state.unassignCarrierResult = null;
        state.unassignCarrierError = null;
      })
      .addCase(unassignCarrier.fulfilled, (state, action) => {
        state.unassignCarrierLoading = false;
        state.unassignCarrierResult = action.payload;
        state.unassignCarrierError = null;
      })
      .addCase(unassignCarrier.rejected, (state, action) => {
        state.unassignCarrierLoading = false;
        state.unassignCarrierResult = null;
        state.unassignCarrierError = action.payload;
      })

      .addCase(createTransport.pending, (state) => {
        state.createTransportLoading = true;
        state.createTransportResult = null;
        state.createTransportError = null;
      })
      .addCase(createTransport.fulfilled, (state, action) => {
        state.createTransportLoading = false;
        state.createTransportResult = action.payload;
        state.createTransportError = null;
      })
      .addCase(createTransport.rejected, (state, action) => {
        state.createTransportLoading = false;
        state.createTransportResult = null;
        state.createTransportError = action.payload;
      })

      .addCase(updateTransport.pending, (state) => {
        state.updateTransportLoading = true;
        state.updateTransportResult = null;
        state.updateTransportError = null;
      })
      .addCase(updateTransport.fulfilled, (state, action) => {
        state.updateTransportLoading = false;
        state.updateTransportResult = action.payload;
        state.updateTransportError = null;
      })
      .addCase(updateTransport.rejected, (state, action) => {
        state.updateTransportLoading = false;
        state.updateTransportResult = null;
        state.updateTransportError = action.payload;
      })

      .addCase(bidsRequest.pending, (state) => {
        state.bidsRequestLoading = true;
        state.bidsRequestResult = null;
        state.bidsRequestError = null;
      })
      .addCase(bidsRequest.fulfilled, (state, action) => {
        state.bidsRequestLoading = false;
        state.bidsRequestResult = action.payload;
        state.bidsRequestError = null;
      })
      .addCase(bidsRequest.rejected, (state, action) => {
        state.bidsRequestLoading = false;
        state.bidsRequestResult = null;
        state.bidsRequestError = action.payload;
      });
  },
});

export const {
  resetAssignCarrier,
  resetUnassignCarrier,
  resetCreateTransport,
  resetUpdateTransport,
  resetBidsRequest,
} = manageOrderSlice.actions;
export default manageOrderSlice.reducer;
