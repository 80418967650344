import React from "react";
import "./AirPackageDetails.scss";

import { useTranslation } from "react-i18next";

import { CiBoxes } from "react-icons/ci";
import { BsBoxes } from "react-icons/bs";

const AirPackageDetails = ({ getOrderResult }) => {
  const { t } = useTranslation();
  return (
    <div id='AirPackageDetails'>
      <div className='titleContainer'>
        <div className='icon'>
          <CiBoxes />
        </div>
        <h1 className='title'>{t("shipper.forms.titles.Package")}</h1>
      </div>
      <div className='AirPackageDetailsShipper'>
        {getOrderResult?.cargos?.map((cargo, index) => {
          return (
            <div key={index} className='AirPackageDetailsShipperContainer'>
              <div className='AirPackageDetailsShipperCover'>
                <div className='AirPackageDetailsShipperIndex'>
                  {getOrderResult?.cargos.length > 1 && index + 1}
                </div>
                <div className='AirPackageDetailsShipperImg'>
                  <BsBoxes />
                </div>
              </div>
              <div className='AirPackageDetailsShippercontent'>
                <p>
                  {t("shipper.forms.content.Package type")}:{" "}
                  <span>{cargo?.dimensions?.[0]?.packing_type?.name || "-"}</span>
                </p>
                <p>
                  {t("shipper.forms.content.Per weight")}:{" "}
                  <span>{cargo?.dimensions?.[0]?.weight || "-"} KG</span>
                </p>
                <p>
                  {t("shipper.forms.content.Quantity")}:{" "}
                  <span>{cargo?.dimensions?.[0]?.count || "-"}</span>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AirPackageDetails;
