import { useTranslation } from "react-i18next";

export function useOrderPaymentSelectSubOptions() {
  const { t } = useTranslation();
  const paymentSubOptions = [
    {
      label: t("tms.forms.options.After Pick Up"),
      value: {
        label: t("tms.forms.options.After Pick Up"),
        id: "AFTER_PICK_UP",
      },
    },
    {
      label: t("tms.forms.options.On Drop Off"),
      value: {
        label: t("tms.forms.options.On Drop Off"),
        id: "ON_DROP_OFF",
      },
    },
    {
      label: t("tms.forms.options.After Drop Off"),
      value: {
        label: t("tms.forms.options.After Drop Off"),
        id: "AFTER_DROP_OFF",
      },
    },
  ];

  return paymentSubOptions;
}
