import React, { Fragment, useState } from "react";
import "./AccountantEmailCreateButton.scss";

import { MdOutlineGroupAdd } from "react-icons/md";
import { useTranslation } from "react-i18next";

import DButton from "./../../../../../Form/DButtons/DButton/DButton";
import AccountantEmailCreateModal from "./../AccountantEmailCreateModal/AccountantEmailCreateModal";

const AccountantEmailCreateButton = () => {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  return (
    <Fragment>
      <div id='AccountantEmailCreateButton'>
        <DButton
          title={t("shipper.buttons.Add accountant")}
          onClick={() => setShowCreateModal(true)}
          leftIcon={<MdOutlineGroupAdd />}
        />
      </div>
      {showCreateModal && (
        <AccountantEmailCreateModal
          isOpen={showCreateModal}
          isClose={() => setShowCreateModal(false)}
        />
      )}
    </Fragment>
  );
};

export default AccountantEmailCreateButton;
