import React from "react";
import "./Footer.scss";

import Copyright from "./components/CopyRight/Copyright";
import MainFooter from "./components/MainFooter/MainFooter";
import Socials from "./components/Socials/Socials";

const Footer = () => {
  return (
    <footer id='Footer' className='Footer'>
      <div className='backgroundColor'>
        <div
          className='backgroundImage'
          style={{
            backgroundImage: `url(${`/assets/images/map/WorldMap.svg`})`,
          }}
        >
          <div
            className='backgroundImage two'
            style={{
              backgroundImage: `url(${`/assets/images/map/WorldMap.svg`})`,
            }}
          >
            <MainFooter />
            <Socials />
            <div className='borderLine'></div>
            <Copyright />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
