import React, { Fragment, useEffect, useState } from "react";
import "./ShipperCards.scss";

import { useDispatch, useSelector } from "react-redux";

import {
  getShipperAnalytics,
  resetGetShipperAnalytics,
} from "../../../store/analytics/analyticsSlice";
import moment from "moment";

import { Skeleton } from "@mui/material";

import ShipperCardsContainer from "./ShipperCardsContainer/ShipperCardsContainer";
import ShipperCardsCalendar from "./ShipperCardsCalendar/ShipperCardsCalendar";
import ShipperCardsSwitcher from "./ShipperCardsSwitcher/ShipperCardsSwitcher";

const ShipperCards = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const { getShipperAnalyticsLoading, getShipperAnalyticsResult } = useSelector(
    (state) => state.analytics
  );

  useEffect(() => {
    dispatch(getShipperAnalytics(moment(date).format("YYYY-MM")));
    return () => dispatch(resetGetShipperAnalytics());
  }, [date, dispatch]);

  return (
    <div id='ShipperCards'>
      <ShipperCardsCalendar date={date} setDate={setDate} />
      {getShipperAnalyticsLoading && <Skeleton variant='rounded' className='skeleton' />}
      {getShipperAnalyticsResult && (
        <Fragment>
          <ShipperCardsContainer analyticsData={getShipperAnalyticsResult} />
          <ShipperCardsSwitcher analyticsData={getShipperAnalyticsResult} />
        </Fragment>
      )}
    </div>
  );
};

export default ShipperCards;
