import React, { Fragment } from "react";
import "./Character.scss";

import Tooltip from "@mui/material/Tooltip";

const Character = ({ title, max }) => {
  const truncateText = (title, max) => {
    if (title?.length <= max) {
      return title;
    }
    return title?.substring(0, max) + "...";
  };
  const truncated = truncateText(title?.toString(), max);

  return (
    <Fragment>
      {title?.length > max ? (
        <Tooltip
          placement='bottom-start'
          title={title}
          classes={{ tooltip: "characterTooltip" }}
        >
          <span>{truncated}</span>
        </Tooltip>
      ) : (
        <Fragment>{truncated}</Fragment>
      )}
    </Fragment>
  );
};

export default Character;
