import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({ position }) => {
  return (
    <div id='ProgressBar'>
      <div
        className='progress'
        style={{ width: position === 2 ? "75%" : "25%" }}
      ></div>

      <div className='dot'></div>
      <div
        className='dot'
        style={{ left: position === 2 ? "50%" : "0%" }}
      ></div>
    </div>
  );
};

export default ProgressBar;
