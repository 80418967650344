import React from "react";
import "./OrderMapImage.scss";

import NoTruck from "./NoTruck/NoTruck";
import FilledPartnerCarrier from "./FilledPartnerCarrier/FilledPartnerCarrier";
import FilledOwnCarrier from "./FilledOwnCarrier/FilledOwnCarrier";

const OrderMapImage = ({ order }) => {
  return (
    <div
      id='OrderMapImage'
      style={{
        backgroundImage: `url(${order.map})`,
      }}
    >
      {!order?.transports?.find((i) => i?.truck) && !order.partner ? (
        <NoTruck order={order} />
      ) : order?.partner ? (
        <FilledPartnerCarrier order={order} />
      ) : (
        <FilledOwnCarrier order={order} />
      )}
    </div>
  );
};

export default OrderMapImage;
