import React from "react";

import { MdLocationPin, MdCategory } from "react-icons/md";
import { FaWeightHanging, FaRegCalendar } from "react-icons/fa";
import { RiShipFill } from "react-icons/ri";
import { GiCargoCrate } from "react-icons/gi";
import moment from "moment";

import CreateOrderTopMenu from "../../Components/CreateOrderTopMenu/CreateOrderTopMenu";

const OceanTopMenu = ({ ORDERTYPES, orderTypeId, group, locations, time }) => {
  const locationsDescA =
    locations.find((i) => i.direction === "A" && i.inputted_address)?.inputted_address || "";
  const locationsDescB =
    locations.find((i) => i.direction === "B" && i.inputted_address)?.inputted_address || "";

  const transfer_type = ORDERTYPES.find((i) => i?.id === orderTypeId)?.name || "-";

  const calculateTotalWeight = () => {
    return group.reduce((total, item) => {
      const weight = parseFloat(item.weight) || 0;
      return total + weight * item.count;
    }, 0);
  };
  const totalWeight = calculateTotalWeight();

  const trailerTypeLabel = group[0]?.trailerType?.label || "";

  const ARRAY = [
    {
      icon: <RiShipFill />,
      title: transfer_type,
    },
    {
      icon: <MdLocationPin />,
      title: locationsDescA + " - " + locationsDescB,
    },
    {
      icon: <MdCategory />,
      title: group[0]?.cargoType?.label || "-",
    },
    {
      icon: <GiCargoCrate />,
      title: trailerTypeLabel ? trailerTypeLabel : "-",
    },
    {
      icon: <FaWeightHanging />,
      title: totalWeight,
    },
    {
      icon: <FaRegCalendar />,
      title: time ? moment(time).format("DD/MM/YYYY") : "-",
    },
  ];

  return <CreateOrderTopMenu items={ARRAY} />;
};

export default OceanTopMenu;
