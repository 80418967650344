import React from "react";
import "./DetailsFreightAir.scss";

import { useTranslation } from "react-i18next";

import DetailsFreightItem from "../Components/DetailsFreightItem/DetailsFreightItem";

const DetailsFreightAir = () => {
  const { t } = useTranslation();

  return (
    <section id='DetailsFreightAir' className='DetailsFreightAir'>
      <div className='wrapper'>
        <div className='DetailsFreightAirContainer'>
          <DetailsFreightItem
            cover='/assets/images/static/cover31.svg'
            title={t("landing.titles.landingDetailsFreightTitle.Global reach")}
            content={t(
              "landing.bodyText.landingDetailsFreightBody.Instant quotes for premium air freight carriers."
            )}
            delay={400}
          />
          <DetailsFreightItem
            cover='/assets/images/static/cover32.svg'
            title={t(
              "landing.titles.landingDetailsFreightTitle.Real-time exception handling"
            )}
            content={t(
              "landing.bodyText.landingDetailsFreightBody.Cargon alerts and resolves exceptions swiftly, preventing delays."
            )}
            delay={250}
          />
          <DetailsFreightItem
            cover='/assets/images/static/cover33.svg'
            title={t(
              "landing.titles.landingDetailsFreightTitle.Strong network"
            )}
            content={t(
              "landing.bodyText.landingDetailsFreightBody.A reliable partner network for capacity on leading carriers."
            )}
            delay={100}
          />
        </div>
      </div>
    </section>
  );
};

export default DetailsFreightAir;
