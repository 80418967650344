import React from "react";
import "./TrailersOrdersTableHeader.scss";

import { useTranslation } from "react-i18next";
import moment from "moment";

import FiltersContainer from "../../../../../FilterForms/FiltersContainer/FiltersContainer";
import FilterStatusSelect from "../../../../../FilterForms/FilterStatusSelect/FilterStatusSelect";
import FilterCustomerSelect from "../../../../../FilterForms/FilterCustomerSelect/FilterCustomerSelect";
import FilterDateRangeForm from "../../../../../FilterForms/FilterDateRangeForm/FilterDateRangeForm";
import FilterPriceRangeForm from "../../../../../FilterForms/FilterPriceRangeForm/FilterPriceRangeForm";

const TrailersOrdersTableHeader = ({
  status,
  setStatus,
  customer,
  setCustomer,
  date,
  setDate,
  price,
  setPrice,
  onClear,
}) => {
  const { t } = useTranslation();
  return (
    <div id='TrailersOrdersTableHeader'>
      <h1>{t("tms.titles.Orders")}</h1>
      <FiltersContainer onClear={onClear}>
        <FilterStatusSelect value={status} setValue={setStatus} />
        <FilterCustomerSelect value={customer} setValue={setCustomer} />
        <FilterDateRangeForm
          label={t("tms.filters.placeholders.Date range")}
          value={
            Object.keys(date).length > 0
              ? `${
                  date.startDate
                    ? moment(date.startDate).format("DD/MM/YYYY")
                    : ""
                } - ${
                  date.endDate ? moment(date.endDate).format("DD/MM/YYYY") : ""
                }`
              : ""
          }
          setValue={setDate}
        />
        <FilterPriceRangeForm
          value={
            Object.keys(price).length > 0
              ? `${price?.minPrice} - ${price?.maxPrice}`
              : ""
          }
          setValue={setPrice}
        />
      </FiltersContainer>
    </div>
  );
};

export default TrailersOrdersTableHeader;
