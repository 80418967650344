import React from "react";
import "./FilterLinkedSelect.scss";

import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";

const FilterLinkedSelect = ({ value, setValue }) => {
  const { t } = useTranslation();

  const OPTIONS = [
    { value: "true", label: t("tms.filters.labels.Linked to") },
    { value: "false", label: t("tms.filters.labels.Not linked to") },
  ];

  const ControlComponent = ({ children, ...props }) => {
    return (
      <components.Control {...props} className={value ? "selected" : ""}>
        {children}
      </components.Control>
    );
  };

  return (
    <div id='FilterLinkedSelect'>
      <Select
        classNamePrefix='select'
        name='filterLinkedSelect'
        className='selectComponent'
        options={OPTIONS || []}
        placeholder={t("tms.filters.placeholders.Link")}
        value={value}
        onChange={(state) => setValue(state)}
        isClearable={true}
        isSearchable={false}
        components={
          value
            ? {
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
                Control: ControlComponent,
              }
            : {
                IndicatorSeparator: () => null,
                Control: ControlComponent,
              }
        }
      />
    </div>
  );
};

export default FilterLinkedSelect;
