import React, { Fragment, useEffect } from "react";
import "./PartnerBids.scss";

import { useTranslation } from "react-i18next";
import { IoAlertCircle } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { useMoney } from "../../../helper/useMoney";

import { getOrderData } from "../../../store/bidding/biddingSlice";

import Logo from "../../../componentsAdditional/SVG/Cargon/Logo";
import OfferForm from "../../../componentsBids/OfferForm/OfferForm";
import AddressDetails from "../../../componentsDashboard/Orders/AddressDetails/AddressDetails";
import TrailerNeedDetails from "../../../componentsBids/TrailerNeedDetails/TrailerNeedDetails";
import OfferTitle from "../../../componentsBids/OfferTitle/OfferTitle";
import BidsHeader from "../../../componentsBids/BidsHeader/BidsHeader";
import LoadingPage from "../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import ProductDetails from "../../../componentsBids/ProductDetails/ProductDetails";
import PageNotFound from "../../../componentsBids/PageNotFound/PageNotFound";
import BidsMessage from "../../../componentsBids/BidsMessage/BidsMessage";

const PartnerBids = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const dispatch = useDispatch();
  const { getOrderDataLoading, getOrderDataResponce, getOrderDataError } = useSelector(
    (state) => state.bidding
  );
  const { sendBidResult } = useSelector((state) => state.bidding);

  const order = getOrderDataResponce?.order;

  const lastOffer = useMoney(
    getOrderDataResponce?.bid?.amount || 0,
    getOrderDataResponce?.bid?.currency?.symbol || "-"
  );

  // const refused =
  //   getOrderDataResponce?.order_bid_responses?.[
  //     getOrderDataResponce?.order_bid_responses?.length - 1
  //   ]?.refused;

  useEffect(() => {
    dispatch(getOrderData(token));
  }, [dispatch, token, sendBidResult]);

  return (
    <Fragment>
      {getOrderDataLoading ? (
        <LoadingPage />
      ) : (
        <div id='PartnerBids'>
          <BidsHeader />
          {getOrderDataError && <PageNotFound />}
          {getOrderDataResponce && (
            <main id='B-Layout'>
              <div className='bidsLeftContainer'>
                <div className='bidsLeft'>
                  <Logo />
                  <OfferTitle company={getOrderDataResponce?.company} />
                  {/* {refused ? (
                    <BidsMessage
                      icon={<IoAlertCircle />}
                      text={<p>You have refused this offer!</p>}
                      color='#da1414'
                      backgroundColor='#feefef'
                    />
                  ) : */}
                  {getOrderDataResponce?.bid ? (
                    <BidsMessage
                      icon={<IoAlertCircle />}
                      text={
                        <p>
                          {t("partnerBid.You have already offered")} <span>{lastOffer}</span>{" "}
                          {t("partnerBid.for this order.")}
                        </p>
                      }
                      color='#2e5aac'
                      backgroundColor='#eef2fa'
                    />
                  ) : (
                    <OfferForm
                      order={order}
                      token={token}
                      currencies={getOrderDataResponce?.currencies?.map((item) => ({
                        value: item,
                        label: item.code,
                      }))}
                      loading={getOrderDataLoading}
                    />
                  )}
                </div>
              </div>
              <div className='bidsRight'>
                <AddressDetails getOrderResult={order} />
                <TrailerNeedDetails order={order} />
                <ProductDetails order={order} />
              </div>
            </main>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default PartnerBids;
