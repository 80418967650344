import React from "react";
import "./DFile.scss";

const DFile = ({ title, onChange }) => {
  return (
    <div id='DFile'>
      <div className='noDocument'>
        <div
          className='cover'
          style={{
            backgroundImage: `url(${`/assets/images/dashboard/cover5.svg`})`,
          }}
        ></div>
        <h3>{title}</h3>
        <input type='file' onChange={onChange} />
      </div>
    </div>
  );
};

export default DFile;
