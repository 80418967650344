import React from "react";
import "./MobileTable.scss";

import { useTranslation } from "react-i18next";

import TableWrapper from "../../../Form/TableFields/TableWrapper/TableWrapper";
import TableHeader from "../../../Form/TableFields/TableHeader/TableHeader";
import MobileTableItem from "./MobileTableItem/MobileTableItem";

const MobileTable = ({ drivers }) => {
  const { t } = useTranslation();
  return (
    <TableWrapper
      emptyText={t("tms.tables.noInfo.no drivers")}
      total={drivers?.length || 0}
    >
      <TableHeader>
        <th>{t("tms.tables.titles.Driver list")}</th>
        <th>{t("tms.tables.titles.Linked")}</th>
        <th>{t("tms.tables.titles.Connect date")}</th>
        <th style={{ textAlign: "center" }}>
          {t("tms.tables.titles.GPS connect")}
        </th>
      </TableHeader>
      <tbody>
        {drivers.map((driver) => (
          <MobileTableItem key={driver.id} driver={driver} />
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default MobileTable;
