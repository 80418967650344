import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import DSelect from "../DSelect";

const DTruckBrandsSelect = ({ value, setValue, error, required }) => {
  const { t } = useTranslation();
  const { getTruckBrandsLoading, getTruckBrandsSelect } = useSelector(
    (state) => state.trucks
  );

  const filterOption = (option, inputValue) => {
    const { value } = option;
    return value.name.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <DSelect
      id='DTruckBrandsSelect'
      label={t("tms.modals.labels.Manufacturer")}
      placeholder={t("tms.modals.placeholder.Choose manufacturer")}
      required={required}
      value={value}
      setValue={setValue}
      loading={getTruckBrandsLoading}
      search={true}
      error={error}
      filterOption={filterOption}
      options={getTruckBrandsSelect}
    />
  );
};

export default DTruckBrandsSelect;
