import React from "react";
import "./DWarning.scss";

const DWarning = ({ text, icon }) => {
  return (
    <div id='DWarning' className='DWarning'>
      <div className='icon'>
        {icon}
        <div className='DWarningContent'>{text}</div>
      </div>
    </div>
  );
};

export default DWarning;
