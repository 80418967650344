import React, { Fragment, useEffect, useState } from "react";
import "./ShipperCharts.scss";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  getShipperChartAnalytics,
  resetGetShipperChartAnalytics,
} from "../../../store/analytics/analyticsSlice";

import { Skeleton } from "@mui/material";

import ShipperChartHeader from "./ShipperChartHeader/ShipperChartHeader";
import CostsChart from "./CostsChart/CostsChart";
import ShipmentsChart from "./ShipmentsChart/ShipmentsChart";
import DistanceChart from "./DistanceChart/DistanceChart";
import WeightCharts from "./WeightCharts/WeightCharts";

const ShipperCharts = () => {
  const dispatch = useDispatch();
  const { getShipperChartAnalyticsLoading, getShipperChartAnalyticsResult } = useSelector(
    (state) => state.analytics
  );

  const [activeChart, setActiveChart] = useState("costs");
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    dispatch(getShipperChartAnalytics(moment(date).format("YYYY-MM")));
    return () => dispatch(resetGetShipperChartAnalytics());
  }, [date, dispatch]);

  const costsDataWithMonths = getShipperChartAnalyticsResult?.map((month) => {
    const monthStr = Object.keys(month)?.[0];
    const data = month?.[monthStr];
    return {
      name: moment(monthStr, "YYYY-MM").format("MMM"),
      value: data?.revenue,
    };
  });

  const shipmentsWithMonths = getShipperChartAnalyticsResult?.map((month) => {
    const monthStr = Object.keys(month)?.[0];
    const data = month?.[monthStr];
    return {
      name: moment(monthStr, "YYYY-MM").format("MMM"),
      value: data?.order_count,
    };
  });

  const distanceWithMonths = getShipperChartAnalyticsResult?.map((month) => {
    const monthStr = Object.keys(month)?.[0];
    const data = month?.[monthStr];
    return {
      name: moment(monthStr, "YYYY-MM").format("MMM"),
      value: data?.distance?.toFixed(2),
    };
  });

  const weightWithMonths = getShipperChartAnalyticsResult?.map((month) => {
    const monthStr = Object.keys(month)?.[0];
    const data = month?.[monthStr];
    return {
      name: moment(monthStr, "YYYY-MM").format("MMM"),
      value: data?.shipped_wight,
    };
  });

  return (
    <div id='ShipperCharts'>
      <ShipperChartHeader
        activeChart={activeChart}
        setActiveChart={setActiveChart}
        date={date}
        setDate={setDate}
      />
      {getShipperChartAnalyticsLoading && <Skeleton variant='rounded' className='skeleton' />}
      {getShipperChartAnalyticsResult && (
        <Fragment>
          {activeChart === "costs" && <CostsChart data={costsDataWithMonths} />}
          {activeChart === "numberOfShipments" && <ShipmentsChart data={shipmentsWithMonths} />}
          {activeChart === "distance" && <DistanceChart data={distanceWithMonths} />}
          {activeChart === "weight" && <WeightCharts data={weightWithMonths} />}
        </Fragment>
      )}
    </div>
  );
};

export default ShipperCharts;
