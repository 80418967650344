import React, { Fragment, useState } from "react";
import "./FinancialDetailsHeader.scss";

import { useTranslation } from "react-i18next";

import { BsCreditCard } from "react-icons/bs";
import { PiCheckCircleFill } from "react-icons/pi";

import { Tooltip } from "@mui/material";

import InvoiceModal from "../../../InvoiceModal/InvoiceModal";
import InvoicesMenu from "../../../../FinancialManagement/InvoicesMenu/InvoicesMenu";

const FinancialDetailsHeader = ({ order }) => {
  const { t } = useTranslation();

  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  return (
    <Fragment>
      <div id='FinancialDetailsHeader'>
        <div className='FinancialDetailsHeaderLeft'>
          <div className='icon'>
            <BsCreditCard />
          </div>
          <div className='title'>{t("tms.titles.Financial details")}</div>

          {order.client_paid_at && (
            <Tooltip
              placement='top'
              title={
                <div className='StatusFieldContent'>
                  <h3>{t("tms.desc.Paid order")}</h3>
                </div>
              }
              classes={{ tooltip: "FinancialDetailsHeaderTooltip" }}
            >
              <div className='icon done'>
                <PiCheckCircleFill />
              </div>
            </Tooltip>
          )}
        </div>
        <InvoicesMenu item={order} />
      </div>
      {showInvoiceModal && (
        <InvoiceModal
          order={order}
          isOpen={showInvoiceModal}
          isClose={() => setShowInvoiceModal(false)}
        />
      )}
    </Fragment>
  );
};

export default FinancialDetailsHeader;
