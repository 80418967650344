import React, { Fragment, useState } from "react";
import "./DFileFormLarge.scss";

import { MdUpload } from "react-icons/md";

import DAttachemntModal from "../DAttachemntModal";

const DFileFormLarge = ({ value, setValue, title, subTitle, type_id }) => {
  const [fileModal, setFileModal] = useState(false);

  return (
    <Fragment>
      <div id='DFileFormLarge' onClick={() => setFileModal(true)}>
        <div className='noDocument'>
          <div className='icon'>
            <MdUpload />
          </div>
          <div className='content'>
            <h3>{title}</h3>
            <h4>{subTitle}</h4>
          </div>
        </div>
      </div>
      {fileModal && (
        <DAttachemntModal
          isOpen={fileModal}
          isClose={() => setFileModal(false)}
          title={title}
          subTitle={subTitle}
          type_id={type_id}
          value={value}
          setValue={setValue}
        />
      )}
    </Fragment>
  );
};

export default DFileFormLarge;
