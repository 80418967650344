import React from "react";
import "./OceanPackage.scss";

import { useTranslation } from "react-i18next";
import { FiPlusCircle } from "react-icons/fi";

import OceanPackageItem from "./OceanPackageItem/OceanPackageItem";
import CreateOrderSectionTitle from "./../../Components/CreateOrderSectionTitle/CreateOrderSectionTitle";
import DTextButton from "../../../../Form/DButtons/DTextButton/DTextButton";

const OceanPackage = ({ edit, group, setGroup }) => {
  const { t } = useTranslation();

  const createNewItemHandler = () => {
    const newGroup = [
      ...group,
      {
        cargoType: null,
        cargoTypeError: null,
        packageType: null,
        packageTypeError: null,
        HSCode: false,
        HSCodeValue: "",
        HSCodeValueError: null,
        //////
        adr: false,
        adrError: null,
        adrNumber: "",
        adrNumberError: null,
        adrClass: "",
        adrClassError: null,
        adrFile: [],
        //////
        oversized: false,
        oversizedError: null,
        oversizedFile: [],
        oversizedCall: false,
        ////
        trailerType: null,
        trailerTypeError: null,
        trailerSize: null,
        trailerSizeError: null,
        temperature: "",
        temperatureError: null,
        weight: "",
        weightError: null,
        count: 1,
        countError: null,
      },
    ];
    setGroup(newGroup);
  };
  return (
    <div id='ShipperOceanPackage'>
      <CreateOrderSectionTitle title={t("shipper.forms.titles.Product & Container requirements")} />
      <div className='packagesContainer'>
        {group.map((item, index) => (
          <OceanPackageItem
            edit={edit}
            key={index}
            index={index}
            item={item}
            group={group}
            setGroup={setGroup}
          />
        ))}
      </div>

      <div className='ShipperOceanPackageNewItemBtn'>
        <DTextButton
          title={t("shipper.buttons.Add new Product type")}
          leftIcon={<FiPlusCircle />}
          onClick={() => createNewItemHandler()}
        />
      </div>
    </div>
  );
};

export default OceanPackage;
