import React from "react";
import RowField from "../../../Form/TableFields/RowField/RowField";
import TrackingWBorderField from "../../../Form/TableFields/TrackingWBorderField/TrackingWBorderField";
import OrderAddressDateField from "../../../Form/TableFields/OrderAddressDateField/OrderAddressDateField";
import OrderProductWeightField from "../../../Form/TableFields/OrderProductWeightField/OrderProductWeightField";
import ShippingTypeIconFIeld from "../../../Form/TableFields/ShippingTypeIconFIeld/ShippingTypeIconFIeld";
import QuotationField from "./../../../Form/TableFields/QuotationField/QuotationField";

const QuotesTableItem = ({ order }) => {
  return (
    <RowField style={{ height: "60.8px" }}>
      <TrackingWBorderField
        text={order?.tracking || "-"}
        to={`/dashboard/shipper/orders/${order?.id}`}
      />
      <OrderAddressDateField order={order} />
      <OrderProductWeightField order={order} />
      <ShippingTypeIconFIeld type={order?.type} />
      <QuotationField order={order} />
    </RowField>
  );
};

export default QuotesTableItem;
