import React from "react";
import "./SeaFreight.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import CoverSeaFreight from "./../../../../componentsLanding/Landing/Covers/CoverSeaFreight/CoverSeaFreight";
import DigitalFreightSea from "./../../../../componentsLanding/Landing/DigitalFreight/DigitalFreightSea/DigitalFreightSea";
import ContactSeaFreight from "./../../../../componentsLanding/Landing/Contacts/ContactSeaFreight/ContactSeaFreight";
import DetailsFreightSea from "../../../../componentsLanding/Landing/DetailsFreight/DetailsFreightSea/DetailsFreightSea";

const SeaFreight = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Sea freight")}>
      <CoverSeaFreight />
      <DigitalFreightSea />
      <DetailsFreightSea />
      <ContactSeaFreight />
    </Wrapper>
  );
};

export default SeaFreight;
