import React, { Fragment } from "react";
import "./ErrorText.scss";

const ErrorText = ({ error }) => {
  return (
    <Fragment>
      {error && (
        <div id='ErrorText'>
          <p>{error}</p>
        </div>
      )}
    </Fragment>
  );
};

export default ErrorText;
