import React, { Fragment, useState, useEffect } from "react";
import "./GPS.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import {
  getTrucks,
  resetGetTrucks,
  getProviders,
  resetGetProviders,
  getConnections,
  resetGetConnections,
  getDevices,
  resetGetDevices,
} from "../../../../../store/GPSTraker/GPSTrakerSlice";
import Wrapper from "../../../../../componentsAdditional/Wrapper";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

// import DBackButton from "./../../../../../componentsDashboard/Form/DButtons/DBackButton/DBackButton";
import GPSCompanies from "./../../../../../componentsDashboard/TrackerGPS/GPSCompanies/GPSCompanies";
import GPSTruckTable from "./../../../../../componentsDashboard/TrackerGPS/GPSTruckTable/GPSTruckTable";
import GPSTruckFilter from "./../../../../../componentsDashboard/TrackerGPS/GPSTruckFilter/GPSTruckFilter";
import PageSwitcher from "./../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import EmptyList from "./../../../../../componentsDashboard/UI/EmptyList/EmptyList";
import ConnectCompanyModal from "./../../../../../componentsDashboard/TrackerGPS/ConnectCompanyModal/ConnectCompanyModal";

const GPS = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    getTrucksLoading,
    getConnectionsLoading,
    getDevicesLoading,

    getTrucksResult,
    getConnectionsResponce,
    getDevicesResponce,

    connectProviderResult,
    connectTruckToDeviceResponce,

    disconnectProviderResult,
  } = useSelector((state) => state.GPSTraker);

  const [matchSelect, setMatchSelect] = useState({
    label: t("tms.filters.labels.All Trucks"),
    value: 1,
  });

  const [showConnectModal, setShowConnectModal] = useState(false);

  useEffect(() => {
    dispatch(getTrucks());
    dispatch(getProviders());
    dispatch(getConnections());
    dispatch(getDevices());
    return () => {
      dispatch(resetGetTrucks());
      dispatch(resetGetProviders());
      dispatch(resetGetConnections());
      dispatch(resetGetDevices());
    };
  }, [dispatch, connectProviderResult, connectTruckToDeviceResponce, disconnectProviderResult]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.GPS Tracking")}>
      <section id='TrackerGPS'>
        {/* <DBackButton alt='/dashboard/tms/tracking' /> */}
        <PageSwitcher name='GPS' />

        {(getTrucksLoading || getConnectionsLoading || getDevicesLoading) && <LoadingPage />}

        {getConnectionsResponce && getTrucksResult && getDevicesResponce && (
          <Fragment>
            {getConnectionsResponce.length === 0 ? (
              <EmptyList
                title={t("tms.titles.No GPS Company")}
                // description='Add truck, make it active and take order whenever you want'
                buttonText={t("tms.forms.buttons.Add GPS Company")}
                image='/assets/images/dashboard/cover11.svg'
                onClick={() => {
                  setShowConnectModal(true);
                }}
              />
            ) : (
              <Fragment>
                <GPSCompanies companies={getConnectionsResponce} />
                <GPSTruckFilter value={matchSelect} setValue={setMatchSelect} />
                <GPSTruckTable trucks={getTrucksResult} matchSelect={matchSelect} />
              </Fragment>
            )}
          </Fragment>
        )}

        {showConnectModal && (
          <ConnectCompanyModal
            isOpen={showConnectModal}
            isClose={() => setShowConnectModal(false)}
          />
        )}
      </section>
    </Wrapper>
  );
};

export default GPS;
