import React from "react";
import "./CreateOrderSubmit.scss";

import { FiPlus } from "react-icons/fi";

import DButton from "./../../../../Form/DButtons/DButton/DButton";

const CreateOrderSubmit = ({ onClick, loading, title, icon }) => {
  return (
    <div id='CreateOrderSubmit'>
      <DButton
        rightIcon={icon ? icon : <FiPlus />}
        onClick={onClick}
        title={title}
        loading={loading}
      />
    </div>
  );
};

export default CreateOrderSubmit;
