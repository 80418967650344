// import { useSelector } from "react-redux";
// const { user } = useSelector((state) => state.auth);

// const formattedMoney = useMoney(
//   item.revenue_sum || 0,
//   user?.currency?.symbol || "-"
// );

export function useMoney(number, symbol, decimal = 2) {
  const formattedNumber = number
    ?.toFixed(decimal)
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (symbol === "$") {
    return `${symbol} ${formattedNumber}`;
  } else {
    return `${formattedNumber} ${symbol}`;
  }
}
