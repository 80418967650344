import React from "react";
import "./SelectCountry.scss";

import { useSelector, useDispatch } from "react-redux";
import { setCountryId } from "../../../../../store/additional/settings/settingsSlice";

import SelectForm from "../../SelectForm";

const SelectCountry = ({ error, placeholder }) => {
  const dispatch = useDispatch();
  const { getCountriesResult, getCountriesLoading, countryId } = useSelector(
    (state) => state.settings
  );

  const options =
  getCountriesResult &&
  getCountriesResult.map((item) => ({
      value: item,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "15px",
              height: "15px",
              backgroundImage: `url(${item.country.flag})`,
              backgroundPosition: "cover",
              backgroundRepeat: "no-repeat",
              marginRight: "5px",
            }}
          ></div>

          <p>{item.name}</p>
        </div>
      ),
    }));

  const newValue =
    options && options.find((item) => item.value.id === countryId);

  const filterOption = (option, inputValue) => {
    const { value } = option;
    return value.name.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <div id='SelectCountry'>
      <SelectForm
        options={options}
        name='selectCountry'
        search={true}
        value={newValue}
        setValue={(state) => {
          dispatch(setCountryId(state.value.id));
        }}
        placeholder={placeholder}
        loading={getCountriesLoading}
        clear={false}
        loadingText=''
        error={error}
        filterOption={filterOption}
      />
    </div>
  );
};

export default SelectCountry;
