import React from "react";
import "./StatisticCard.scss";

import { useTranslation } from "react-i18next";

import { FiArrowUpRight } from "react-icons/fi";

const StatisticCard = ({ number, title, thisMonth, active, text }) => {
  const { t } = useTranslation();

  return (
    <div id='StatisticCard' className={active ? "active" : ""}>
      <FiArrowUpRight />
      <div className='content'>
        <h2>{number}</h2>
        <h3>{title}</h3>
      </div>
      <div>
        <h4 className='bold'>
          {t("tms.desc.This month")}: <span className='green'>{thisMonth}</span>
        </h4>
        <p>
          <span className='bold'>{text}</span>{" "}
          {t("tms.desc.increase from last month")}
        </p>
      </div>
    </div>
  );
};

export default StatisticCard;
