import React, { useEffect, useState } from "react";
import "./TrackingContainerModal.scss";

import { useTranslation } from "react-i18next";
import { FiX } from "react-icons/fi";

import Overlay from "../../../../componentsLanding/Popup/Overlay/Overlay";
import ContainerTracking from "./ContainerTracking/ContainerTracking";

const TrackingContainerModal = ({ order, isOpen, isClose }) => {
  const { t } = useTranslation();

  const [activeContainer, setActiveContainer] = useState(null);

  useEffect(() => {
    if (order) {
      setActiveContainer(order?.transports?.[0]?.id);
    }
  }, [order]);

  return (
    <Overlay isOpen={isOpen} isClose={isClose}>
      <div id='TrackingContainerModal'>
        <div className='trackingContainerHeaderContainer'>
          <div className='headerTitle'>{t("shipper.modal.Container full tracking")}</div>
          <div className='icon'>
            <FiX onClick={isClose} />
          </div>
        </div>
        <div className='containersContainer'>
          {order?.transports?.map((container, index) => (
            <div
              className={`containerItem ${activeContainer === container?.id ? "active" : ""}`}
              key={index}
              onClick={() => setActiveContainer(container?.id)}
            >
              <div className='icon'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                >
                  <g clipPath='url(#clip0_9987_11150)'>
                    <path
                      d='M14.222 13.3337H1.77756C1.54181 13.3337 1.31572 13.24 1.14902 13.0733C0.982322 12.9066 0.888672 12.6805 0.888672 12.4448V3.55588C0.888672 3.32013 0.982322 3.09404 1.14902 2.92734C1.31572 2.76064 1.54181 2.66699 1.77756 2.66699H14.222C14.4578 2.66699 14.6838 2.76064 14.8505 2.92734C15.0172 3.09404 15.1109 3.32013 15.1109 3.55588V12.4448C15.1109 12.6805 15.0172 12.9066 14.8505 13.0733C14.6838 13.24 14.4578 13.3337 14.222 13.3337ZM1.77756 3.55588V12.4448H14.222V3.55588H1.77756Z'
                      fill='#878A99'
                    />
                    <path
                      d='M4.00009 11.2447C3.90579 11.2447 3.81535 11.2073 3.74867 11.1406C3.68199 11.0739 3.64453 10.9835 3.64453 10.8892V5.11142C3.64453 5.01712 3.68199 4.92668 3.74867 4.86C3.81535 4.79332 3.90579 4.75586 4.00009 4.75586C4.09439 4.75586 4.18482 4.79332 4.2515 4.86C4.31818 4.92668 4.35564 5.01712 4.35564 5.11142V10.8892C4.35564 10.9835 4.31818 11.0739 4.2515 11.1406C4.18482 11.2073 4.09439 11.2447 4.00009 11.2447Z'
                      fill='#878A99'
                    />
                    <path
                      d='M6.63095 11.2447C6.53665 11.2447 6.44621 11.2073 6.37953 11.1406C6.31285 11.0739 6.27539 10.9835 6.27539 10.8892V5.11142C6.27539 5.01712 6.31285 4.92668 6.37953 4.86C6.44621 4.79332 6.53665 4.75586 6.63095 4.75586C6.72525 4.75586 6.81568 4.79332 6.88236 4.86C6.94904 4.92668 6.9865 5.01712 6.9865 5.11142V10.8892C6.9865 10.9835 6.94904 11.0739 6.88236 11.1406C6.81568 11.2073 6.72525 11.2447 6.63095 11.2447Z'
                      fill='#878A99'
                    />
                    <path
                      d='M9.33309 11.2447C9.2388 11.2447 9.14836 11.2073 9.08168 11.1406C9.015 11.0739 8.97754 10.9835 8.97754 10.8892V5.11142C8.97754 5.01712 9.015 4.92668 9.08168 4.86C9.14836 4.79332 9.2388 4.75586 9.33309 4.75586C9.42739 4.75586 9.51783 4.79332 9.58451 4.86C9.65119 4.92668 9.68865 5.01712 9.68865 5.11142V10.8892C9.68865 10.9835 9.65119 11.0739 9.58451 11.1406C9.51783 11.2073 9.42739 11.2447 9.33309 11.2447Z'
                      fill='#878A99'
                    />
                    <path
                      d='M12.0001 11.2447C11.9058 11.2447 11.8154 11.2073 11.7487 11.1406C11.682 11.0739 11.6445 10.9835 11.6445 10.8892V5.11142C11.6445 5.01712 11.682 4.92668 11.7487 4.86C11.8154 4.79332 11.9058 4.75586 12.0001 4.75586C12.0944 4.75586 12.1848 4.79332 12.2515 4.86C12.3182 4.92668 12.3556 5.01712 12.3556 5.11142V10.8892C12.3556 10.9835 12.3182 11.0739 12.2515 11.1406C12.1848 11.2073 12.0944 11.2447 12.0001 11.2447Z'
                      fill='#878A99'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_9987_11150'>
                      <rect width='16' height='16' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                className={`containerNumber ${activeContainer === container?.id ? "active" : ""}`}
              >
                {container?.number}
              </div>
            </div>
          ))}
        </div>
        <ContainerTracking order={order} container={activeContainer} />
      </div>
    </Overlay>
  );
};

export default TrackingContainerModal;
