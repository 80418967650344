import React, { Fragment } from "react";

import Bids from "../routes/Bids";

const AppBids = () => {
  return (
    <Fragment>
      <Bids />
    </Fragment>
  );
};

export default AppBids;
