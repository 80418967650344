import React from "react";
import "./ProfileHeader.scss";

import ProfileInfo from "./ProfileInfo/ProfileInfo";
import ProfilePicture from "./ProfilePicture/ProfilePicture";

const ProfileHeader = () => {
  return (
    <div id='profileHeader'>
      <div className='profilePicture'>
        <ProfilePicture />
      </div>
      <ProfileInfo />
    </div>
  );
};

export default ProfileHeader;
