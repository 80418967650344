import React, { Fragment } from "react";
import "./SideBar.scss";

import { useSelector } from "react-redux";

import SideBarSticky from "./components/SideBarSticky/SideBarSticky";
import Header from "./components/Header/Header";
import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";
import MobileMenu from "./components/MobileMenu/MobileMenu";

const SideBar = () => {
  const { openSidebar } = useSelector((state) => state.userUI);

  return (
    <Fragment>
      <SideBarSticky />
      <MobileMenu />
      <div id='SideBar' className={openSidebar ? "max" : "min"}>
        <div className='SidebarWrapper'>
          <Header />
          <Nav />
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default SideBar;
