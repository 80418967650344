import React from "react";
import "./AuthCover.scss";

const AuthCover = ({ url }) => {
  return (
    <div
      id='AuthCover'
      style={{
        backgroundImage: `url(${url})`,
      }}
    ></div>
  );
};

export default AuthCover;
