import React from "react";
import "./ActivityTableSwitcher.scss";

import { useTranslation } from "react-i18next";

const ActivityTableSwitcher = ({
  feedBadge,
  shipmentsBadge,
  active,
  setActive,
}) => {
  const { t } = useTranslation();
  return (
    <div id='ActivityTableSwitcher'>
      <ul className='list'>
        {/* <li className={active === "activityFeed" ? "active" : "false"}>
          <div
            className='ActivityTableSwitcherItem'
            onClick={() => setActive("activityFeed")}
          >
            <p id={feedBadge && "BadgePadding"}>Activity feed</p>
            {feedBadge > 0 && (
              <div className='PageSwitcherBadge'>
                <h3>{feedBadge > 99 ? "99+" : feedBadge}</h3>
              </div>
            )}
          </div>
        </li> */}
        <li
        //  className={active === "activeShipments" ? "active" : "false"}
        >
          <div
            className='ActivityTableSwitcherItem'
            onClick={() => setActive("activeShipments")}
          >
            <p id={shipmentsBadge && "BadgePadding"}>
              {t("shipper.titles.Active shipments")}
            </p>
            {shipmentsBadge > 0 && (
              <div className='PageSwitcherBadge'>
                <h3>{shipmentsBadge > 99 ? "99+" : shipmentsBadge}</h3>
              </div>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ActivityTableSwitcher;
