import React from "react";
import "./DTextButton.scss";

import ButtonLoader from "../ButtonLoader/ButtonLoader";

const DTextButton = ({
  title,
  onClick,
  rightIcon,
  leftIcon,
  loading,
  disabled,
  danger,
  primary,
  info
}) => {
  let styleName;

  if (danger) {
    styleName = "danger";
  } else if (primary) {
    styleName = "primary";
  } else if (info) {
    styleName = "info";
  } else {
    styleName = "";
  }

  return (
    <div id='DTextButton' className={styleName}>
      <button
        disabled={disabled}
        type='button'
        onClick={() => {
          !disabled && onClick();
        }}
      >
        {!loading && leftIcon && (
          <div className='buttonIcon leftIconButton'>{leftIcon}</div>
        )}
        {loading ? <ButtonLoader /> : title}
        {!loading && rightIcon && (
          <div className='buttonIcon rightIconButton'>{rightIcon}</div>
        )}
      </button>
    </div>
  );
};

export default DTextButton;
