export const googleMapsSvg = () => {
  const baseSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <!-- Background -->
  <g filter="url(#filter0_d_5208_43004)">
    <circle cx="16" cy="16" r="16" fill="#2B353A"/>
  </g>
  <svg x="5" y="8" width="22" height="16" viewBox="0 0 22 16" fill="none">
    <path d="M19 4H16V0H0V13H2C2 14.66 3.34 16 5 16C6.66 16 8 14.66 8 13H14C14 14.66 15.34 16 17 16C18.66 16 20 14.66 20 13H22V8L19 4ZM5 14.5C4.17 14.5 3.5 13.83 3.5 13C3.5 12.17 4.17 11.5 5 11.5C5.83 11.5 6.5 12.17 6.5 13C6.5 13.83 5.83 14.5 5 14.5ZM14 3H12V10H10V3H8V10H6V3H4V10H2V2H14V3ZM17 14.5C16.17 14.5 15.5 13.83 15.5 13C15.5 12.17 16.17 11.5 17 11.5C17.83 11.5 18.5 12.17 18.5 13C18.5 13.83 17.83 14.5 17 14.5ZM16 8V5.5H18.5L20.46 8H16Z" fill="white"/>
  </svg>
</svg>
`;

  const encodedSvg = encodeURIComponent(baseSvg);

  return {
    url: `data:image/svg+xml;utf8,${encodedSvg}`,
  };
};
