import React, { useState } from "react";
import "./ProductDetails2.scss";

import { useTranslation } from "react-i18next";

import { BsBoxSeam } from "react-icons/bs";

import Character from "../../../UI/Character/Character";

const ProductItem2 = ({ title, text }) => {
  return (
    <div className='item'>
      <div className='itemTitle'>{title}</div>
      <div className='itemText'>
        <Character title={text} max={30} />
      </div>
    </div>
  );
};
const ProductDetails2 = ({ getOrderResult }) => {
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);

  const shippingType = getOrderResult?.type?.name || "";
  const productCategory = getOrderResult?.cargos[0]?.type?.name || "";
  const totalWeight = getOrderResult?.weight || 0;
  const adr = getOrderResult?.cargos[0]?.adr > 0 ? "Yes" : "";
  const unNumber = getOrderResult?.cargos[0]?.un_number || "";
  const adrClass = getOrderResult?.cargos[0]?.adr_class || "";

  return (
    <div id='ProductDetails2'>
      <div className='titleContainer'>
        <div className='icon'>
          <BsBoxSeam />
        </div>
        <h1 className='title'>{t("tms.titles.Product")}</h1>
      </div>

      <div className='items'>
        <ProductItem2 title={`${t("tms.titles.Shipping type")}:`} text={shippingType} />
        <ProductItem2 title={`${t("tms.titles.Product category")}:`} text={productCategory} />
        <ProductItem2
          title={`${t("tms.titles.Total weight")}:`}
          text={totalWeight > 0 ? `${totalWeight} ${t("tms.desc.KG")}` : " - "}
        />
        {adr && (
          <div className='adr'>
            <div className='title'>
              <div className='text'>{t("tms.titles.ADR")}</div>
              <div
                className='icon'
                style={{
                  transform: showMore && "rotate(180deg)",
                  transitionDuration: "0.3s",
                }}
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                <svg
                  height='20'
                  width='20'
                  viewBox='0 0 20 20'
                  aria-hidden='true'
                  focusable='false'
                >
                  <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
                </svg>
              </div>
            </div>
            <div
              className='content'
              style={{
                display: showMore ? "flex" : "none",
              }}
            >
              <ProductItem2 title={`${t("tms.titles.UN Number")}:`} text={unNumber || "-"} />
              <ProductItem2 title={`${t("tms.titles.ADR Class")}:`} text={adrClass || "-"} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetails2;
