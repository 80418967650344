import React from "react";
import "./ShipperNavItem.scss";

import { NavLink } from "react-router-dom";

const ShipperNavItem = ({ to, title }) => {
  return (
    <li id='ShipperNavItem'>
      <NavLink to={to}>{title}</NavLink>
    </li>
  );
};

export default ShipperNavItem;
