import React from "react";
import "./SubscribeCarrier.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "../../Form/Button/Button";

const SubscribeCarrier = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <section
      className='SubscribeCarrier'
      id='SubscribeCarrier'
      style={{
        backgroundImage: `url(${`/assets/images/static/cover40.webp`})`,
      }}
    >
      <div className='wrapper'>
        <div className='SubscribeCarrierContainer'>
          <div className='SubscribeCarrierContent'>
            <h1>
              {t(
                "landing.titles.landingSubscribeNowTitle.Are you an international carrier from outside the Central Asia and Caucasus region?"
              )}
            </h1>
            <p>
              {t(
                "landing.bodyText.landingSubscribeNowBody.Subscribe to our daily load discovery email list to instantly book orders from/to your preferred locations."
              )}
            </p>
            <Button
              title={t("landing.form.button.Subscribe now")}
              onClick={() => {
                navigate("/carrier/subscribe");
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscribeCarrier;
