import React from "react";
import "./ShipperCardsContainerItem.scss";

import { useTranslation } from "react-i18next";

const ShipperCardsContainerItem = ({ title, number, progress }) => {
  const { t } = useTranslation();
  return (
    <div id='ShipperCardsContainerItem'>
      <h1>{title}</h1>
      <div className='desc'>
        <h3>{number}</h3>
        {progress && (
          <p className={progress < 0 ? "dec" : "inc"}>
            <span>{progress}%</span> {t("shipper.desc.than last month")}
          </p>
        )}
      </div>
    </div>
  );
};

export default ShipperCardsContainerItem;
