import _request from "../../_request";

const getInvoice = async (ISO, TOKEN, orderId, lang) => {
  const config = {
    url: `/order/${orderId}/invoice/${lang}/?type=tms`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createInvoice = async (ISO, TOKEN, orderId, data, lang) => {
  const config = {
    url: `order/${orderId}/tms-invoice/${lang}`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const invoicesService = {
  getInvoice,
  createInvoice,
};

export default invoicesService;
