import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FiEdit2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  updateOrderCost,
  resetUpdateOrderCost,
} from "../../../../../../store/orders/costs/orderCostsSlice";

import DModal from "../../../../../Modal/DModal/DModal";
import DCurrencyInput from "../../../../../Form/DCurrencyInput/DCurrencyInput";
import DSelect from "../../../../../Form/DSelect/DSelect";
import DTextarea from "../../../../../Form/DTextarea/DTextarea";

const UpdateCostModal = ({ isOpen, isClose, orderId, cost, operation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    updateOrderCostLoading,
    updateOrderCostResult,
    updateOrderCostError,
    getOrderCostTypesLoading,
    getOrderCostTypesSelect,
  } = useSelector((state) => state.orderCosts);

  const [amountName, setAmountName] = useState(
    cost?.type
      ? {
          label: cost?.type?.name,
          value: cost?.type,
        }
      : null
  );
  const [amountValue, setAmountValue] = useState(cost?.amount || "");
  const [currencyValue, setCurrencyValue] = useState(
    cost?.currency
      ? {
          label: cost?.currency?.code,
          value: cost?.currency,
        }
      : null
  );
  const [commentValue, setCommentValue] = useState(cost?.comment || "");

  const submithandler = () => {
    const data = {
      orderId: orderId,
      costId: cost && cost.id,

      type_id: amountName?.value?.id || null,
      amount: amountValue || null,
      currency_id: currencyValue?.value?.id || null,
      comment: commentValue || null,
    };
    dispatch(updateOrderCost(data));
  };

  useEffect(() => {
    if (updateOrderCostResult) {
      dispatch(resetUpdateOrderCost());
    }
  }, [dispatch, updateOrderCostResult]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateOrderCost());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose()}
        onClick={() => submithandler()}
        title={
          operation === "IN"
            ? t("tms.modals.titles.Update charge")
            : t("tms.modals.titles.Update cost")
        }
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiEdit2 />}
        status='success'
        loading={updateOrderCostLoading}
      >
        <div id='orderAddNewCost'>
          <form className='formGroup'>
            <DSelect
              id='OrderAdditionalCostAmountName'
              value={amountName}
              setValue={setAmountName}
              placeholder={t("tms.modals.placeholder.Select cost name")}
              label={t("tms.modals.labels.Cost name")}
              options={getOrderCostTypesSelect || []}
              required
              loading={getOrderCostTypesLoading}
              error={updateOrderCostError?.errors?.type_id?.[0] ?? null}
            />
            <DCurrencyInput
              id='OrderAdditionalCostAmount'
              value={amountValue}
              setValue={setAmountValue}
              currencyValue={currencyValue}
              setCurrencyValue={setCurrencyValue}
              required
              placeholder={t("tms.modals.placeholder.Enter cost")}
              label={t("tms.modals.labels.Cost")}
              error={
                (updateOrderCostError?.errors?.amount?.[0] ?? null) ||
                (updateOrderCostError?.errors?.currency_id?.[0] ?? null)
              }
            />
            <DTextarea
              id='OrderAdditionalCostComment'
              value={commentValue}
              setValue={setCommentValue}
              required
              placeholder={t("tms.modals.placeholder.Your comment...")}
              label={t("tms.modals.labels.Comment")}
              error={updateOrderCostError?.errors?.comment?.[0] ?? null}
            />
          </form>
        </div>
      </DModal>
    </Fragment>
  );
};

export default UpdateCostModal;
