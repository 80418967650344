import React from "react";
import "./MainTitle.scss";

const MainTitle = ({ title, description }) => {
  return (
    <div id='MainTitle'>
      <h1>{title}</h1>
      {description && <p>{description}</p>}
    </div>
  );
};

export default MainTitle;
