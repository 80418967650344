import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "./dashboardService";

const initialState = {
  getOrdersLoading: true,
  getOrdersResponce: null,
  getOrdersError: null,

  getChartsDataLoading: true,
  getChartsDataResponce: null,
  getChartsDataError: null,

  getInfoDataLoading: true,
  getInfoDataResponce: null,
  getInfoDataError: null,
};

export const getOrders = createAsyncThunk(
  "dashboard/getOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await dashboardService.getOrders(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getChartsData = createAsyncThunk(
  "dashboard/getChartsData",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await dashboardService.getChartsData(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInfoData = createAsyncThunk(
  "dashboard/getInfoData",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await dashboardService.getInfoData(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.getOrdersLoading = true;
        state.getOrdersResponce = null;
        state.getOrdersError = null;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.getOrdersLoading = false;
        state.getOrdersResponce = action.payload;
        state.getOrdersError = null;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.getOrdersLoading = false;
        state.getOrdersResponce = null;
        state.getOrdersError = action.payload;
      })

      .addCase(getChartsData.pending, (state) => {
        state.getChartsDataLoading = true;
        state.getChartsDataResponce = null;
        state.getChartsDataError = null;
      })
      .addCase(getChartsData.fulfilled, (state, action) => {
        state.getChartsDataLoading = false;
        state.getChartsDataResponce = action.payload;
        state.getChartsDataError = null;
      })
      .addCase(getChartsData.rejected, (state, action) => {
        state.getChartsDataLoading = false;
        state.getChartsDataResponce = null;
        state.getChartsDataError = action.payload;
      })

      .addCase(getInfoData.pending, (state) => {
        state.getInfoDataLoading = true;
        state.getInfoDataResponce = null;
        state.getInfoDataError = null;
      })
      .addCase(getInfoData.fulfilled, (state, action) => {
        state.getInfoDataLoading = false;
        state.getInfoDataResponce = action.payload;
        state.getInfoDataError = null;
      })
      .addCase(getInfoData.rejected, (state, action) => {
        state.getInfoDataLoading = false;
        state.getInfoDataResponce = null;
        state.getInfoDataError = action.payload;
      });
  },
});

export default dashboardSlice.reducer;
