import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import { store } from "./store/store";
import { Provider } from "react-redux";

import "./styles/reset.scss";
import "./store/additional/lang/i18n.js";

import App from "./App";
import BrowserRouterBasename from "./componentsAdditional/BrowserRouterBasename.jsx";
import Splash from "./componentsAdditional/Splash/Splash";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (typeof Node === "function" && Node.prototype) {
  const { removeChild: originalRemoveChild, insertBefore: originalInsertBefore } = Node.prototype;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      return child;
    }
    return originalRemoveChild.call(this, child);
  };
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      return newNode;
    }
    return originalInsertBefore.call(this, newNode, referenceNode);
  };
}

root.render(
  <Suspense fallback={Splash}>
    {/* <React.StrictMode> */}
    <Provider store={store}>
      <BrowserRouterBasename>
        <App />
      </BrowserRouterBasename>
    </Provider>
    {/* </React.StrictMode> */}
  </Suspense>
);
