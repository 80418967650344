import React from "react";
import "./SignUpCompanyAuth.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import SignUpCompanyAuthForm from "./../../../../componentsLanding/Auth&Password/SignUpForms/SignUpCompanyAuthForm/SignUpCompanyAuthForm";

const SignUpCompanyAuth = () => {
  const { t } = useTranslation();

  return (
    <Wrapper
      documentTitle={t("landing.documentTitles.Sign Up carrier company")}
    >
      <section id='SignUpCompanyAuth'>
        <AuthCover url='/assets/images/static/cover24.jpg' />
        <SignUpCompanyAuthForm />
      </section>
    </Wrapper>
  );
};

export default SignUpCompanyAuth;
