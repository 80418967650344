import React, { Fragment, useState, useEffect } from "react";
import "./Password.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { forgotPassword, resetForgotPassword } from "./../../../../store/auth/authSlice";

import { containsOnlyNumbers } from "../../../../helper/useContainNum";

import ConvertInput from "../../../Form/Inputs/ConvertInput/ConvertInput";
import Button from "../../../Form/Button/Button";
import Label from "../../../Form/Label/Label";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import NotHaveAccount from "../../../Form/NotHaveAccount/NotHaveAccount";

const Password = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { forgotPasswordLoading, forgotPasswordResult, forgotPasswordError } = useSelector(
    (state) => state.auth
  );
  const { phoneIndexValueId } = useSelector((state) => state.settings);

  const [value, setValue] = useState("");
  const [method, setMethod] = useState("EMAIL");

  useEffect(() => {
    if (forgotPasswordResult) {
      const url = `/forgot-password/verify?method=${method}&value=${value}`;
      navigate(url);
      dispatch(resetForgotPassword());
      setValue("");
      setMethod("EMAIL");
    }
  }, [dispatch, navigate, forgotPasswordResult, method, value]);

  useEffect(() => {
    return () => {
      dispatch(resetForgotPassword());
    };
  }, [dispatch]);

  useEffect(() => {
    const onlyNumber = containsOnlyNumbers(value);
    setMethod(value.length > 2 && onlyNumber ? "PHONE" : "EMAIL");
  }, [value]);

  const submitHandler = () => {
    const data =
      method === "EMAIL" ? { email: value } : { phone_index_id: phoneIndexValueId, phone: value };
    dispatch(forgotPassword(data));
  };

  return (
    <AuthFormWrapper>
      <form id='Password' className='Password'>
        <FormTitle title={t("landing.auth.titles.Did you forget your password?")}>
          {t(
            "landing.auth.titles.Enter your email or phone number that you’re using in your account to send you a link"
          )}
        </FormTitle>

        <div className='convertContainer'>
          <Label text={t("landing.auth.form.Email or phone number")} id='convertContainer' />
          <ConvertInput
            inputStatus={method}
            value={value}
            setValue={setValue}
            id='convertContainer'
            autoFocus={value.length > 2 ? true : false}
            error={
              method === "EMAIL"
                ? forgotPasswordError?.errors?.email?.[0] ?? null
                : (forgotPasswordError?.errors?.phone?.[0] ?? null) ||
                  (forgotPasswordError?.errors?.phone_index_id?.[0] ?? null)
            }
          />
          {method === "EMAIL" ? (
            <ErrorText error={forgotPasswordError?.errors?.email?.[0] ?? null} />
          ) : (
            <Fragment>
              <ErrorText error={forgotPasswordError?.errors?.phone_index_id?.[0] ?? null} />
              <ErrorText error={forgotPasswordError?.errors?.phone?.[0] ?? null} />
            </Fragment>
          )}
        </div>
        <Button
          loading={forgotPasswordLoading}
          title={t("landing.auth.form.Next")}
          onClick={(e) => {
            e.preventDefault();
            submitHandler();
          }}
          type='submit'
        />
        <NotHaveAccount link='/login' text={t("landing.auth.form.Back to sign in")} />
      </form>
    </AuthFormWrapper>
  );
};

export default Password;
