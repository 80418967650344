import React, { useState, Fragment } from "react";
import "./LoadsTableItem.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";

import RowField from "../../../Form/TableFields/RowField/RowField";
import TextField from "../../../Form/TableFields/TextField/TextField";
import TrailerField from "../../../Form/TableFields/TrailerField/TrailerField";
import DateField from "./../../../Form/TableFields/DateField/DateField";
import LocationFieldLoadBoards from "../../../Form/TableFields/LocationFieldLoadBoards/LocationFieldLoadBoards";
import MakeBidModal from "./../../Modals/MakeBidModal/MakeBidModal";
import CenterField from "../../../Form/TableFields/CenterField/CenterField";
import PriceField from "./../../../Form/TableFields/PriceField/PriceField";
import DButton from "../../../Form/DButtons/DButton/DButton";
import AddVatModal from "../../Modals/AddVatModal/AddVatModal";

const LoadsTableItem = ({ item }) => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);

  const [openBidModal, setOpenBidModal] = useState(false);
  const [showVatModal, setShowVatModal] = useState(false);

  const hasVat = user?.company?.legal_id;

  return (
    <Fragment>
      <RowField>
        <LocationFieldLoadBoards item={item} />
        <DateField date={item?.cargos[0]?.pickup_date || null} />
        <TextField label={item?.weight ? item?.weight : "-"} />
        <TrailerField
          icon={item?.cargos[0]?.trailerType?.icon}
          trailerType={item?.cargos[0]?.trailerType?.name || "-"}
          volume={item?.cargos[0]?.trailersize?.label || "-"}
        />
        {item.bid ? (
          <PriceField
            icon={item?.taken && <FaCheckCircle />}
            color={!item?.taken && "#2E5AAC"}
            number={item?.bid?.amount || 0}
            symbol={item?.bid?.currency?.symbol ?? ""}
          />
        ) : (
          <CenterField>
            <div id='BidePriceFieldLoadBoard'>
              <p className='price'>{t("tms.tables.noInfo.No bid")}</p>
            </div>
          </CenterField>
        )}
        <CenterField>
          <div id='AllLoadsMakeBid'>
            {item.bid ? (
              <DButton
                title={t("tms.tables.buttons.New bid")}
                onClick={() => setOpenBidModal(true)}
              />
            ) : (
              <DButton
                title={t("tms.tables.buttons.Make bid")}
                onClick={() => (hasVat ? setOpenBidModal(true) : setShowVatModal(true))}
              />
            )}
          </div>
        </CenterField>
      </RowField>
      {openBidModal && (
        <MakeBidModal item={item} isOpen={openBidModal} isClose={() => setOpenBidModal(false)} />
      )}
      {showVatModal && <AddVatModal isOpen={showVatModal} isClose={() => setShowVatModal(false)} />}
    </Fragment>
  );
};

export default LoadsTableItem;
