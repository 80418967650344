import React, { Fragment } from "react";
import "./RateField.scss";

import { useTranslation } from "react-i18next";
import Rating from "@mui/material/Rating";

const RateField = ({ rate }) => {
  const { t } = useTranslation();

  return (
    <td id='RateField'>
      <div className='RateFieldContainer'>
        {parseFloat(rate) === 0 ? (
          <p className='noRate'>{t("tms.tables.noInfo.no rate")}</p>
        ) : (
          <Fragment>
            <Rating
              name='read-only'
              size='small'
              value={parseFloat(rate)}
              readOnly
              precision={0.5}
            />
            <p className='text'>({rate})</p>
          </Fragment>
        )}
      </div>
    </td>
  );
};

export default RateField;
