import React from "react";
import "./PartnerOrdersTableHeader.scss";

import { useTranslation } from "react-i18next";

// import { useNavigate } from "react-router-dom";
// import { FiPlus } from "react-icons/fi";

// import DTextButton from "../../../../Form/DButtons/DTextButton/DTextButton";
import FiltersContainer from "../../../../FilterForms/FiltersContainer/FiltersContainer";
import FilterStatusSelect from "../../../../FilterForms/FilterStatusSelect/FilterStatusSelect";
import FilterDateRangeForm from "../../../../FilterForms/FilterDateRangeForm/FilterDateRangeForm";
import moment from "moment";
import FilterPriceRangeForm from "../../../../FilterForms/FilterPriceRangeForm/FilterPriceRangeForm";
import FilterCustomerSelect from "../../../../FilterForms/FilterCustomerSelect/FilterCustomerSelect";

const PartnerOrdersTableHeader = ({
  partner,
  onClear,
  status,
  setStatus,
  customer,
  setCustomer,
  date,
  setDate,
  price,
  setPrice,
}) => {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  return (
    <div id='PartnerOrdersTableHeader'>
      <div className='PartnerOrdersTableHeaderTitle'>
        <h2>{t("tms.titles.Orders")}</h2>
        {/* <DTextButton
          title='Assign to order'
          rightIcon={<FiPlus />}
          onClick={() => navigate("/dashboard/tms/orders")}
        /> */}
      </div>

      <FiltersContainer onClear={onClear}>
        <FilterStatusSelect value={status} setValue={setStatus} />
        <FilterCustomerSelect value={customer} setValue={setCustomer} />
        <FilterDateRangeForm
          label={t("tms.filters.placeholders.Date range")}
          value={
            Object.keys(date).length > 0
              ? `${
                  date.startDate
                    ? moment(date.startDate).format("DD/MM/YYYY")
                    : ""
                } - ${
                  date.endDate ? moment(date.endDate).format("DD/MM/YYYY") : ""
                }`
              : ""
          }
          setValue={setDate}
        />
        <FilterPriceRangeForm
          value={
            Object.keys(price).length > 0
              ? `${price?.minPrice} - ${price?.maxPrice}`
              : ""
          }
          setValue={setPrice}
        />
      </FiltersContainer>
    </div>
  );
};

export default PartnerOrdersTableHeader;
