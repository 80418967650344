import React, { useState, useEffect } from "react";
import "./FinishedList.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getFinished,
  resetGetFinished,
} from "../../../../../store/financial/financialSlice";

import Wrapper from "../../../../../componentsAdditional/Wrapper";
import PageSwitcher from "../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import FinishedFilter from "../../../../../componentsDashboard/FinancialManagement/FinishedFilter/FinishedFilter";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import FinishedTable from "../../../../../componentsDashboard/FinancialManagement/FinishedTable/FinishedTable";
import MainTitle from "../../../../../componentsDashboard/MainTitle/MainTitle";

const FinishedList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { getFinishedLoading, getFinishedResult, getFinishedMeta } =
    useSelector((state) => state.financial);
  const { createInvoiceResult } = useSelector(
    (state) => state.invoices
  );

  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [keyword, setKeyword] = useState("");
  const [customer, setCustomer] = useState(null);
  const [route, setRoute] = useState({});
  const [status, setStatus] = useState(null);
  // const [paymentType, setPaymentType] = useState(null);

  const handleFiltersClear = () => {
    if (keyword) setKeyword("");
    if (customer) setCustomer(null);
    if (Object.keys(route).length > 0) setRoute({});
    if (status) setStatus(null);
    // if (paymentType) setPaymentType(null);
  };

  useEffect(() => {
    dispatch(
      getFinished({
        page,
        keyword,
        customer_id: customer?.value?.id,
        pickup_address: route?.fromCountry?.label,
        dropoff_address: route?.toCountry?.label,
        status_id: status?.value,
      })
    );
    return () => {
      dispatch(resetGetFinished());
    };
  }, [
    customer?.value?.id,
    dispatch,
    keyword,
    page,
    route?.fromCountry?.label,
    route?.toCountry?.label,
    createInvoiceResult,
    status,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Payment Management")}>
      <section id='FinishedList'>
        <MainTitle title={t("tms.titles.Payment Management")} />
        <PageSwitcher
          name='financialinformationFinished'
          // badge={getActiveMeta ? getActiveMeta.total : 0}
        />
        <FinishedFilter
          clear={handleFiltersClear}
          keyword={keyword}
          setKeyword={setKeyword}
          customer={customer}
          setCustomer={setCustomer}
          route={route}
          setRoute={setRoute}
          status={status}
          setStatus={setStatus}
          // paymentType={paymentType}
          // setPaymentType={setPaymentType}
        />

        {getFinishedLoading && <LoadingPage />}
        {getFinishedResult && !getFinishedLoading && (
          <FinishedTable
            items={getFinishedResult}
            page={page}
            setPage={setPage}
            pageCount={getFinishedMeta.last_page}
            total={getFinishedMeta.total}
          />
        )}
      </section>
    </Wrapper>
  );
};

export default FinishedList;
