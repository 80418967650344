import React from "react";
import "./CustomersItem.scss";

import LinkTransparent from "../Links/LinkTransparent/LinkTransparent";

const CustomersItem = ({ url, title, content, to, buttonTitle }) => {
  const blur = {
    backgroundImage: `url(${url})`,
  };

  return (
    <div id='CustomersItem' className='CustomersItem'>
      <div className='item'>
        <div className='img' style={blur}></div>
        <div className='transparent'></div>

        <div className='title'>
          <h2>{title}</h2>
        </div>

        <div className='body'>
          <h2>{title}</h2>
          <p>{content}</p>
          <LinkTransparent title={buttonTitle} to={to} />
        </div>
      </div>
    </div>
  );
};

export default CustomersItem;
