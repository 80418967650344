import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { MdMonetizationOn } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import {
  deletePayment,
  resetDeletePayment,
} from "../../../../../../store/orders/orderFinance/orderFinanceSlice";

import DModal from "../../../../../Modal/DModal/DModal";

const DeletePrePaymentModal = ({ isOpen, isClose, order, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { deletePaymentLoading, deletePaymentResult } = useSelector((state) => state.orderFinance);

  const submitHandler = () => {
    const data = {
      orderId: order.id,
      paymentId: item.id,
    };
    dispatch(deletePayment(data));
  };

  useEffect(() => {
    if (deletePaymentResult) {
      dispatch(resetDeletePayment());
    }
  }, [dispatch, deletePaymentResult]);

  useEffect(() => {
    return () => {
      dispatch(resetDeletePayment());
    };
  }, [dispatch]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.Delete pre payment")}
      submitText={t("tms.modals.buttons.Delete")}
      status='danger'
      onClick={() => submitHandler()}
      icon={<MdMonetizationOn />}
      loading={deletePaymentLoading}
    >
      <p>{t("tms.modals.desc.Are you sure you want to delete this pre payment?")}</p>
    </DModal>
  );
};

export default DeletePrePaymentModal;
