import React from "react";
import "./PriceField.scss";

import { useMoney } from "./../../../../helper/useMoney";

const PriceField = ({ number, symbol, icon, color }) => {
  const formattedMoney = useMoney(number || 0, symbol || "");

  return (
    <td id='PriceField' style={{ color: color ? color : "#00b56c" }}>
      {icon && <div className='icon'>{icon}</div>}
      <p>{formattedMoney}</p>
    </td>
  );
};

export default PriceField;
