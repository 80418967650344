import React from "react";
import "./FinishedFilter.scss";

import DSearch from "../../FilterForms/DSearch/DSearch";
import FiltersContainer from "../../FilterForms/FiltersContainer/FiltersContainer";
import FilterCustomerSelect from "../../FilterForms/FilterCustomerSelect/FilterCustomerSelect";
import FilterRouteSelectForm from "../../FilterForms/FilterRouteSelectForm/FilterRouteSelectForm";
import FilterStatusSelect from "../../FilterForms/FilterStatusSelect/FilterStatusSelect";
// import FilterPaymentTypeSelect from "../../FilterForms/FilterPaymentTypeSelect/FilterPaymentTypeSelect";

const FinishedFilter = ({
  keyword,
  setKeyword,
  customer,
  setCustomer,
  route,
  setRoute,
  status,
  setStatus,
  // paymentType,
  // setPaymentType,
  clear,
}) => {
  return (
    <div id='FinishedFilter'>
      <DSearch value={keyword} setValue={setKeyword} />
      <div className='filtersContainer'>
        <FiltersContainer onClear={clear}>
          <FilterCustomerSelect value={customer} setValue={setCustomer} />
          <FilterRouteSelectForm
            value={
              Object.keys(route).length > 0
                ? `${route?.fromCountry?.value?.code || ""} - ${
                    route?.toCountry?.value?.code || ""
                  }`
                : ""
            }
            setValue={setRoute}
          />
          <FilterStatusSelect value={status} setValue={setStatus} />
          {/* <FilterPaymentTypeSelect
            value={paymentType}
            setValue={setPaymentType}
          /> */}
        </FiltersContainer>
      </div>
    </div>
  );
};

export default FinishedFilter;
