import React from "react";
import "./LinkUnderline.scss";

import { Link } from "react-router-dom";

const LinkUnderline = ({ to, title, icon }) => {
  return (
    <div id='LinkUnderline'>
      <Link to={to}>
        <div className='line'></div>
        {title} {icon && icon}
      </Link>
    </div>
  );
};

export default LinkUnderline;
