import React from "react";
import "./Profile.scss";

import { useTranslation } from "react-i18next";
import Wrapper from "./../../../../componentsAdditional/Wrapper";
import ProfileHeader from "../../../../componentsDashboard/Profile/ProfileHeader/ProfileHeader";
import ProfileSettings from "../../../../componentsDashboard/Profile/ProfileSettings/ProfileSettings";
import ProfileCompanyInfo from "./../../../../componentsDashboard/Profile/ProfileCompanyInfo/ProfileCompanyInfo";

const Profile = () => {
  const { t } = useTranslation();
  return (
    <Wrapper documentTitle={t("tms.documentTitles.Profile")}>
      <section id='userProfile'>
        <div className='profileHeaderBackground' />
        <ProfileHeader />
        <ProfileCompanyInfo />
        <ProfileSettings />
      </section>
    </Wrapper>
  );
};

export default Profile;
