import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const openSidebarStorageValue = localStorage.getItem("openSidebar");
const openSidebar = openSidebarStorageValue
  ? JSON.parse(openSidebarStorageValue)
  : true;

const initialState = {
  openSidebar: openSidebar,
};

export const setOpenSidebar = createAsyncThunk(
  "sidebar/setOpenSidebar",
  async (_, thunkAPI) => {
    const openSidebar = thunkAPI.getState().userUI.openSidebar;
    localStorage.setItem("openSidebar", JSON.stringify(!openSidebar));
    return !openSidebar;
  }
);

export const userUISlice = createSlice({
  name: "userUI",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setOpenSidebar.fulfilled, (state, action) => {
      state.openSidebar = action.payload;
    });
  },
});

export default userUISlice.reducer;
