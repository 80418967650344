import _request from "./../../_request";

const getPartnerContacts = async (ISO, TOKEN, id) => {
  const config = {
    url: `/tms-partner/${id}/contact`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createPartnerContact = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `/tms-partner/${id}/contact`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updatePartnerContact = async (ISO, TOKEN, id, contactId, data) => {
  const config = {
    url: `/tms-partner/${id}/contact/${contactId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deletePartnerContact = async (ISO, TOKEN, id, contactId) => {
  const config = {
    url: `/tms-partner/${id}/contact/${contactId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getPartnerContactPositions = async (ISO, TOKEN) => {
  const config = {
    url: `/tms-partner/contact-positions`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const partnerContactsService = {
  getPartnerContacts,
  createPartnerContact,
  updatePartnerContact,
  deletePartnerContact,
  getPartnerContactPositions,
};

export default partnerContactsService;
