import React from "react";
import "./DOverlay.scss";

import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

const DOverlay = ({ isOpen, isClose, children, transparent }) => {
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "visible";
  //   }
  // }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={isClose}
      className='ModalComponent'
      overlayClassName={
        transparent ? "OverlayComponent transparent" : "OverlayComponent"
      }
    >
      {children}
    </ReactModal>
  );
};

export default DOverlay;
