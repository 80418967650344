import React, { useState, useEffect } from "react";
import "./ContactForm.scss";

import { useTranslation } from "react-i18next";
import { CgMailForward } from "react-icons/cg";
import { useSelector, useDispatch } from "react-redux";

import { createLead, resetCreateLead } from "./../../../../../store/additional/lead/leadSlice";

import Input from "./../../../../Form/Inputs/Input";
import ErrorText from "./../../../../Form/ErrorText/ErrorText";
import PhoneInput from "./../../../../Form/Inputs/PhoneInput/PhoneInput";
import Button from "./../../../../Form/Button/Button";
import NotHaveAccount from "./../../../../Form/NotHaveAccount/NotHaveAccount";
import SnackBar from "../../../../../componentsDashboard/Form/SnackBar/SnackBar";

const ContactForm = ({ role }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createLeadLoading, createLeadResult, createLeadError } = useSelector(
    (state) => state.lead
  );
  const { phoneIndexValueId, countryId } = useSelector((state) => state.settings);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const reset = () => {
    setName("");
    setEmail("");
    setPhone("");
  };

  const submitHandler = () => {
    const data = {
      branch_id: countryId,
      name: name,
      type: role,
      email: email,
      phone_index_id: phoneIndexValueId,
      phone: phone,
      message: "No message",
      agreement: true,
    };
    dispatch(createLead(data));
  };

  useEffect(() => {
    if (createLeadResult) {
      reset();
      dispatch(resetCreateLead());
      setSnackBarOpen(true);
    }
  }, [dispatch, createLeadResult]);

  return (
    <div id='ContactFormLanding'>
      <SnackBar
        status={"success"}
        text={"Successfully submited"}
        isOpen={snackBarOpen}
        isClose={() => setSnackBarOpen(false)}
      />
      <div className='form'>
        <div>
          <Input
            id='FirstName'
            placeholder={t("landing.form.placeholder.Tell us your name")}
            value={name}
            setValue={setName}
            error={createLeadError?.errors?.name?.[0]}
          />
          <ErrorText error={createLeadError?.errors?.name?.[0]} />
        </div>
        <div>
          <Input
            id='Email'
            placeholder={t("landing.form.placeholder.Your email")}
            value={email}
            setValue={setEmail}
            error={createLeadError?.errors?.email?.[0]}
          />

          <ErrorText error={createLeadError?.errors?.email?.[0]} />
        </div>

        <div>
          <PhoneInput
            id='PhoneNumber'
            value={phone}
            setValue={setPhone}
            placeholder={t("landing.form.placeholder.Your phone number")}
            error={
              createLeadError?.errors?.phone_index_id?.[0] || createLeadError?.errors?.phone?.[0]
            }
          />
          <ErrorText error={createLeadError?.errors?.phone_index_id?.[0]} />
          <ErrorText error={createLeadError?.errors?.phone?.[0]} />
          <ErrorText error={createLeadError?.errors?.branch_id?.[0]} />
          <ErrorText error={createLeadError?.errors?.type?.[0]} />
          <ErrorText error={createLeadError?.errors?.message?.[0]} />
          <ErrorText error={createLeadError?.errors?.agreement?.[0]} />
        </div>

        <div className='buttonContainer'>
          <Button
            loading={createLeadLoading}
            title={t("landing.form.button.Submit")}
            onClick={() => submitHandler()}
            rightIcon={<CgMailForward />}
          />
        </div>
        <NotHaveAccount
          desc={t("landing.form.label.Still don’t have an account?")}
          link='/register'
          text={t("landing.auth.form.Sign up here")}
        />
      </div>
    </div>
  );
};

export default ContactForm;
