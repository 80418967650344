import React from "react";
import "./AirFreight.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import CoverAirFreight from "./../../../../componentsLanding/Landing/Covers/CoverAirFreight/CoverAirFreight";
import DigitalFreightAir from "./../../../../componentsLanding/Landing/DigitalFreight/DigitalFreightAir/DigitalFreightAir";
import ContactAirFreight from "./../../../../componentsLanding/Landing/Contacts/ContactAirFreight/ContactAirFreight";
import DetailsFreightAir from "../../../../componentsLanding/Landing/DetailsFreight/DetailsFreightAir/DetailsFreightAir";

const AirFreight = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Air freight")}>
      <CoverAirFreight />
      <DigitalFreightAir />
      <DetailsFreightAir />
      <ContactAirFreight />
    </Wrapper>
  );
};

export default AirFreight;
