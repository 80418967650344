import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shipperAcceptedService from "./shipperAcceptedService";

const shipperOrderViewStorageValue = localStorage.getItem("shipperOrderView");
const shipperAcceptedOrderView = shipperOrderViewStorageValue
  ? JSON.parse(shipperOrderViewStorageValue)
  : true;

const shipperOrderactiveOrderId = localStorage.getItem("activeOrderId");
const shipperactiveOrderId = shipperOrderactiveOrderId
  ? JSON.parse(shipperOrderactiveOrderId)
  : null;

const shipperOrderactiveOrderIndex = localStorage.getItem("activeOrderIndex");
const shipperactiveOrderIndex = shipperOrderactiveOrderIndex
  ? JSON.parse(shipperOrderactiveOrderIndex)
  : 0;

const initialState = {
  shipperAcceptedOrderView: shipperAcceptedOrderView,
  activeOrderId: shipperactiveOrderId || null,
  activeOrderIndex: shipperactiveOrderIndex || 0,

  getShipperAcceptedOrdersLoading: true,
  getShipperAcceptedOrdersResult: [],
  getShipperAcceptedOrdersMeta: null,
  getShipperAcceptedOrdersError: null,

  getShipperAcceptedOrderLoading: true,
  getShipperAcceptedOrderResult: null,
  getShipperAcceptedOrderError: null,
};

export const getShipperAcceptedOrders = createAsyncThunk(
  "shipperAccepted/getShipperAcceptedOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperAcceptedService.getShipperAcceptedOrders(ISO, TOKEN, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShipperAcceptedOrder = createAsyncThunk(
  "shipperAccepted/getShipperAcceptedOrder",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperAcceptedService.getShipperAcceptedOrder(ISO, TOKEN, orderId);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setShipperAcceptedOrderView = createAsyncThunk(
  "shipperAccepted/setShipperAcceptedOrderView",
  async (data, thunkAPI) => {
    localStorage.setItem("shipperOrderView", JSON.stringify(data));
    return data;
  }
);

export const shipperAcceptedSlice = createSlice({
  name: "shipperAccepted",
  initialState,
  reducers: {
    setActiveOrderId: (state, action) => {
      state.activeOrderId = action.payload;
      localStorage.setItem("activeOrderId", JSON.stringify(action.payload));
    },
    setActiveOrderIndex: (state, action) => {
      state.activeOrderIndex = action.payload;
      localStorage.setItem("activeOrderIndex", JSON.stringify(action.payload));
    },
    resetGetShipperAcceptedOrders: (state) => {
      state.getShipperAcceptedOrdersLoading = true;
      state.getShipperAcceptedOrdersResult = [];
      state.getShipperAcceptedOrdersMeta = null;
      state.getShipperAcceptedOrdersError = null;
    },
    resetGetShipperAcceptedOrder: (state) => {
      state.orderLoading = true;
      state.orderResponce = null;
      state.orderError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setShipperAcceptedOrderView.fulfilled, (state, action) => {
        state.shipperAcceptedOrderView = action.payload;
      })

      .addCase(getShipperAcceptedOrders.pending, (state) => {
        state.getShipperAcceptedOrdersLoading = true;
        state.getShipperAcceptedOrdersError = null;
      })
      .addCase(getShipperAcceptedOrders.fulfilled, (state, action) => {
        state.getShipperAcceptedOrdersLoading = false;
        state.getShipperAcceptedOrdersResult = [
          ...state.getShipperAcceptedOrdersResult,
          ...action.payload.data,
        ];
        state.getShipperAcceptedOrdersMeta = action.payload.meta;
        state.getShipperAcceptedOrdersError = null;
      })
      .addCase(getShipperAcceptedOrders.rejected, (state, action) => {
        state.getShipperAcceptedOrdersLoading = false;
        state.getShipperAcceptedOrdersResult = [];
        state.getShipperAcceptedOrdersMeta = null;
        state.getShipperAcceptedOrdersError = action.payload;
      })

      .addCase(getShipperAcceptedOrder.pending, (state) => {
        state.getShipperAcceptedOrderLoading = true;
        state.getShipperAcceptedOrderResult = null;
        state.getShipperAcceptedOrderError = null;
      })
      .addCase(getShipperAcceptedOrder.fulfilled, (state, action) => {
        state.getShipperAcceptedOrderLoading = false;
        state.getShipperAcceptedOrderResult = action.payload;
        state.getShipperAcceptedOrderError = null;
      })
      .addCase(getShipperAcceptedOrder.rejected, (state, action) => {
        state.getShipperAcceptedOrderLoading = false;
        state.getShipperAcceptedOrderResult = null;
        state.getShipperAcceptedOrderError = action.payload;
      });
  },
});

export const {
  setActiveOrderId,
  setActiveOrderIndex,
  resetGetShipperAcceptedOrders,
  resetGetShipperAcceptedOrder,
} = shipperAcceptedSlice.actions;
export default shipperAcceptedSlice.reducer;
