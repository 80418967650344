import React from "react";
import "./Dashboard.scss";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import CarrierApp from "../../../../componentsLanding/Auth&Password/SignUpForms/CarrierApp/CarrierApp";
import DButton from "./../../../../componentsDashboard/Form/DButtons/DButton/DButton";
import { logout } from "../../../../store/auth/authSlice";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const submitHandler = () => {
    dispatch(logout());
  };
  return (
    <Wrapper documentTitle='Carrier'>
      <section id='CDashboard'>
        <div className='CDashboardHeader'>
          <DButton
            onClick={() => submitHandler()}
            title={t("tms.modals.titles.Logout")}
          />
        </div>
        <CarrierApp />
      </section>
    </Wrapper>
  );
};

export default Dashboard;
