import React from "react";
import "./ConvertInput.scss";

import { useTranslation } from "react-i18next";

import PhoneInput from "../PhoneInput/PhoneInput";
import Input from "../Input";

const ConvertInput = ({
  inputStatus,
  value,
  setValue,
  id,
  error,
  autoFocus,
  placeholder,
}) => {
  const { t } = useTranslation();

  return (
    <div className='ConvertInput'>
      {inputStatus === "EMAIL" ? (
        <div>
          <Input
            id={id}
            value={value}
            setValue={setValue}
            autoFocus={autoFocus}
            error={error}
            type='email'
            placeholder={
              placeholder
                ? placeholder
                : t("landing.auth.form.Enter email or phone number")
            }
          />
        </div>
      ) : (
        <div>
          <PhoneInput
            value={value}
            setValue={setValue}
            autoFocus={autoFocus}
            error={error}
            type='email'
          />
        </div>
      )}
    </div>
  );
};

export default ConvertInput;
