import React from "react";
import "./ShippingTypeField.scss";

import { Tooltip } from "@mui/material";

import { useConvertShippingType } from "../../../../helper/useConvertShippingType";

const ShippingTypeField = ({ type }) => {
  const { shippingTypeText, shippingTypeIcon } = useConvertShippingType(
    type || null
  );

  return (
    <td id='ShippingTypeField'>
      <Tooltip
        placement='top'
        title={type?.name || ""}
        classes={{ tooltip: "ShippingTypeFieldTooltip" }}
      >
        <div className='ShippingTypeFieldContainer'>
          <div className='icon'>{shippingTypeIcon}</div>
          <p>{shippingTypeText}</p>
        </div>
      </Tooltip>
    </td>
  );
};

export default ShippingTypeField;
