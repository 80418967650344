import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MdLocationPin, MdAnchor } from "react-icons/md";
import {
  getDropoffLOCALITY,
  getDropoffPORT,
  getPickupLOCALITY,
  getPickupPORT,
  resetGetDropoffLOCALITY,
  resetGetDropoffPORT,
  resetGetPickupLOCALITY,
  resetGetPickupPORT,
} from "../../../../../store/locationSuggestion/locationSuggestionSlice";

import CreateOrderLocation from "../../Components/CreateOrderLocation/CreateOrderLocation";

const OceanLocation = ({ locations, setLocations, edit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user?.client_id) {
      dispatch(getPickupLOCALITY(user?.client_id));
      dispatch(getDropoffLOCALITY(user?.client_id));
      dispatch(getPickupPORT(user?.client_id));
      dispatch(getDropoffPORT(user?.client_id));
    }
    return () => {
      dispatch(resetGetPickupLOCALITY());
      dispatch(resetGetDropoffLOCALITY());
      dispatch(resetGetPickupPORT());
      dispatch(resetGetDropoffPORT());
    };
  }, [dispatch, user?.client_id]);

  const locationADesc = [
    {
      type: "PORT",
      name: t("shipper.forms.content.Pick up the container at the port"),
      icon: <MdAnchor />,
    },

    {
      type: "LOCALITY",
      name: t("shipper.forms.content.Pick up the container at a facility"),
      icon: <MdLocationPin />,
    },
  ];

  const locationBDesc = [
    {
      type: "PORT",
      name: t("shipper.forms.content.Deliver the container to the port"),
      icon: <MdAnchor />,
    },

    {
      type: "LOCALITY",
      name: t("shipper.forms.content.Deliver the container to a facility (door)"),
      icon: <MdLocationPin />,
    },
  ];

  return (
    <CreateOrderLocation
      edit={edit}
      type='PORT'
      locations={locations}
      setLocations={setLocations}
      locationADesc={locationADesc}
      locationBDesc={locationBDesc}
      title={t("shipper.forms.titles.Location details")}
    />
  );
};

export default OceanLocation;
