import React from "react";
import "./Trailer.scss";

import { useTranslation } from "react-i18next";
import { BsBoxSeam } from "react-icons/bs";
import Character from "../../../../UI/Character/Character";

const Trailer = ({ item }) => {
  const { t } = useTranslation();
  const title =
    item.trailer && `${item.trailer.type && item.trailer.type.name}`;
  return (
    <div id='InfoWindowTrailer'>
      <div className='title'>
        <h3>{t("tms.titles.Trailer")}</h3>
      </div>
      <div className='desc'>
        <div className='name'>
          <div
            className='cover'
            style={{
              backgroundImage: `url(${`/assets/images/dashboard/cover7.svg`})`,
            }}
          ></div>
          {item.trailer ? (
            <h3>
              <Character title={title} max={9} />
            </h3>
          ) : (
            <div id='noTrailer'>{t("tms.desc.no trailer")}</div>
          )}
        </div>

        <div className='tel'>
          <div className='icon'>
            <BsBoxSeam />
          </div>
          <h3>
            <Character
              title={`${item.trailer.volume_capacity} - ${t("tms.desc.M³")}`}
              max={12}
            />
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Trailer;
