import React, { Fragment, useState } from "react";
import "./LoadBidTableItem.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "@mui/material";
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";

import RowField from "../../../Form/TableFields/RowField/RowField";
import TextField from "../../../Form/TableFields/TextField/TextField";
import TrailerField from "../../../Form/TableFields/TrailerField/TrailerField";
import DateField from "./../../../Form/TableFields/DateField/DateField";
import LocationFieldLoadBoards from "./../../../Form/TableFields/LocationFieldLoadBoards/LocationFieldLoadBoards";
import PriceField from "./../../../Form/TableFields/PriceField/PriceField";
import CenterField from "../../../Form/TableFields/CenterField/CenterField";
import DButton from "../../../Form/DButtons/DButton/DButton";
import MakeBidModal from "./../../Modals/MakeBidModal/MakeBidModal";
import AddVatModal from "../../Modals/AddVatModal/AddVatModal";

const LoadBidTableItem = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const hasVat = user?.company?.legal_id;

  const [showVatModal, setShowVatModal] = useState(false);
  const [openBidModal, setOpenBidModal] = useState(false);
  const TooltipContent = (
    <div className='LoadBidsTableDisabledItemTooltipContent'>
      {t("tms.tables.others.This order has been deleted from your bookings")}
    </div>
  );

  return (
    <Fragment>
      <RowField>
        <LocationFieldLoadBoards item={item} />
        <DateField date={item.cargos[0]?.pickup_date || null} />
        <TextField label={item?.weight || "-"} />
        <TrailerField
          icon={item?.cargos[0]?.trailerType?.icon}
          trailerType={item?.cargos[0]?.trailerType?.name || "-"}
          volume={item?.cargos[0]?.trailersize?.label || "-"}
        />
        {item.bid ? (
          <PriceField
            icon={
              item?.taken ? (
                <FaCheckCircle />
              ) : (
                !item?.taken && item?.status?.id >= 3 && <FaCircleXmark />
              )
            }
            color={
              !item?.taken && item?.status?.id < 3
                ? "#2E5AAC"
                : !item?.taken && item?.status?.id >= 3 && "#da1414"
            }
            number={item?.bid?.amount || 0}
            symbol={item?.bid?.currency?.symbol ?? ""}
          />
        ) : (
          <CenterField>
            <div id='BidePriceFieldLoadBoard'>
              <p className='price'>{t("tms.tables.noInfo.No bid")}</p>
            </div>
          </CenterField>
        )}

        <CenterField>
          {item?.taken ? (
            <div id='AllLoadsTakeOrder'>
              {item?.child_order_id ? (
                <DButton
                  title={t("tms.tables.buttons.See order")}
                  onClick={() => navigate(`/dashboard/tms/orders/${item?.child_order_id}`)}
                />
              ) : (
                <Tooltip
                  placement='left'
                  title={TooltipContent}
                  classes={{ tooltip: "LoadBidsTableDisabledItemTooltip" }}
                  arrow
                >
                  <div className='disabled'>
                    <DButton
                      disabled
                      title={t("tms.tables.buttons.See order")}
                      onClick={() => navigate(`/dashboard/tms/orders/${item?.id}`)}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          ) : (
            item?.status?.id < 3 && (
              <div id='AllLoadsMakeBid'>
                {item.bid ? (
                  <DButton
                    title={t("tms.tables.buttons.New bid")}
                    onClick={() => setOpenBidModal(true)}
                  />
                ) : (
                  <DButton
                    title={t("tms.tables.buttons.Make bid")}
                    onClick={() => (hasVat ? setOpenBidModal(true) : setShowVatModal(true))}
                  />
                )}
              </div>
            )
          )}
        </CenterField>
      </RowField>
      {openBidModal && (
        <MakeBidModal item={item} isOpen={openBidModal} isClose={() => setOpenBidModal(false)} />
      )}
      {showVatModal && <AddVatModal isOpen={showVatModal} isClose={() => setShowVatModal(false)} />}
    </Fragment>
  );
};

export default LoadBidTableItem;
