import React from "react";
import "./ShipperCardsContainer.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useMoney } from "../../../../helper/useMoney";
import { useNumberFormatter } from "../../../../helper/useNumberFormatter";

import ShipperCardsContainerItem from "./ShipperCardsContainerItem/ShipperCardsContainerItem";

const ShipperCardsContainer = ({ analyticsData }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const userCurrency = user?.currency?.symbol || "$";

  const FormattedTotalCharges = useMoney(analyticsData?.revenue || 0, userCurrency, 0);
  const FormattedAverageCharge = useMoney(analyticsData?.average_revenue || 0, userCurrency, 0);

  const FormattedTotalWeight = useNumberFormatter(analyticsData?.shipped_wight || 0);
  const FormattedAverageWeight = useNumberFormatter(analyticsData?.average_shipped_wight || 0);

  const FormattedTotalDistance = useNumberFormatter(analyticsData?.distance || 0);
  const FormattedAverageDistance = useNumberFormatter(analyticsData?.average_distance || 0);

  return (
    <div id='ShipperCardsContainer'>
      <div className='ShipperCardsContainerRow'>
        <ShipperCardsContainerItem
          title={t("shipper.titles.Total charges")}
          number={FormattedTotalCharges}
          progress={analyticsData?.revenue_increase_percent || "0"}
        />
        <ShipperCardsContainerItem
          title={t("shipper.titles.Average charge per shipment")}
          number={FormattedAverageCharge}
        />
      </div>
      <div className='ShipperCardsContainerRow'>
        <ShipperCardsContainerItem
          title={t("shipper.titles.Total shipped weight")}
          number={`${FormattedTotalWeight} ${t("shipper.units.KG")}`}
          progress={analyticsData?.shipped_wight_increase_percent || "0"}
        />
        <ShipperCardsContainerItem
          title={t("shipper.titles.Average weight per shipment")}
          number={`${FormattedAverageWeight} ${t("shipper.units.KG")}`}
        />
      </div>
      <div className='ShipperCardsContainerRow'>
        <ShipperCardsContainerItem
          title={t("shipper.titles.Total distance")}
          number={`${FormattedTotalDistance} ${t("shipper.units.KM")}`}
          progress={analyticsData?.distance_increase_percent || "0"}
        />
        <ShipperCardsContainerItem
          title={t("shipper.titles.Average distance")}
          number={`${FormattedAverageDistance} ${t("shipper.units.KM")}`}
        />
      </div>
    </div>
  );
};

export default ShipperCardsContainer;
