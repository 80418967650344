import React from "react";
import "./MainFooter.scss";

import { useNavigate } from "react-router-dom";

import Service from "./../Service/Service";
import Company from "./../Company/Company";
import Products from "../Products/Products";
import Logo from "../../../../componentsAdditional/SVG/Cargon/Logo";

const MainFooter = () => {
  const navigate = useNavigate();

  return (
    <div id='MainFooter' className='MainFooter'>
      <div className='wrapper'>
        <div className='container'>
          <div className='logo'>
            <div
              className='logoWrapper'
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              <Logo color='#fff' />
            </div>
          </div>
          <div className='footerMenu'>
            <Service />
            <Products />
            <Company />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
