import React from "react";
import "./NoNotifications.scss";

import { useTranslation } from "react-i18next";
import { CgInbox } from "react-icons/cg";

const NoNotifications = () => {
  const { t } = useTranslation();
  return (
    <div id='NoNotifications'>
      <div className='icon'>
        <CgInbox />
      </div>
      <h1>{t("tms.notifications.Headline")}</h1>
      <p>
        {/* The passage experienced a surge in popularity during the 1960s when
        Letraset used it on their dry-transfer sheets */}
        {t("tms.notifications.No new notifications found")}
      </p>
    </div>
  );
};

export default NoNotifications;
