import React, { useEffect } from "react";
import "./MarkAsReadButton.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import {
  markAllNotifications,
  resetMarkAllNotifications,
} from "../../../store/notifications/notificationsSlice";

import DButton from "./../../Form/DButtons/DButton/DButton";

const MarkAsReadButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { markAllNotiLoading, markAllNotiResult } = useSelector(
    (state) => state.notifications
  );

  const markAsReadHandler = () => {
    dispatch(markAllNotifications());
  };

  useEffect(() => {
    if (markAllNotiResult) {
      dispatch(resetMarkAllNotifications());
    }
  }, [dispatch, markAllNotiResult]);

  return (
    <div id='MarkAsReadButton'>
      <DButton
        disabled={markAllNotiLoading ? true : false}
        title={t("tms.notifications.Mark as read")}
        onClick={markAsReadHandler}
        loading={markAllNotiLoading}
      />
    </div>
  );
};

export default MarkAsReadButton;
