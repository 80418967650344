import React from "react";
import "./LinkItem.scss";

import { Link } from "react-router-dom";

const LinkItem = ({ title, to, rel }) => {
  return (
    <li id='LinkItem' className='LinkItem'>
      <Link to={to} rel={rel ? rel : "noopener noreferrer"}>
        <div className='line'></div>
        {title}
      </Link>
    </li>
  );
};

export default LinkItem;
