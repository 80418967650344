import React, { useState } from "react";
import "./Service.scss";

import { useTranslation } from "react-i18next";

import FooterTitle from "../FooterTitle/FooterTitle";
import LinkItem from "../LinkItem/LinkItem";

const Service = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  return (
    <div id='Service' className={open ? "open" : ""}>
      <FooterTitle
        title={t("landing.navigation.Service")}
        value={open}
        setValue={() => setOpen((state) => !state)}
      />
      <ul className='list'>
        {/* <LinkItem title={t("landing.navigation.Finance")} to='/services/finance' /> */}
        <LinkItem title={t("landing.navigation.Road freight")} to='/services/land' />
        <LinkItem title={t("landing.navigation.Sea freight")} to='/services/sea' />
        <LinkItem title={t("landing.navigation.Air freight")} to='/services/air' />
      </ul>
    </div>
  );
};

export default Service;
