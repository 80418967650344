import React, { Fragment, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getShipperSummary } from "../store/summary/summarySlice";

import ShipperHeader from "../componentsDashboard/ShipperHeader/ShipperHeader";
import Shipper from "../routes/Shipper";

const AppShipper = () => {
  const dispatch = useDispatch();

  const { rejectOrderResult } = useSelector((state) => state.shipperOrders);

  const { createOrderResult, deleteOrderResult } = useSelector((state) => state.orders);

  useEffect(() => {
    dispatch(getShipperSummary());
  }, [dispatch, rejectOrderResult, createOrderResult, deleteOrderResult]);

  return (
    <Fragment>
      <ShipperHeader />
      <main>
        <Shipper />
      </main>
    </Fragment>
  );
};

export default AppShipper;
