import React from "react";
import "./ActiveTable.scss";

import { useTranslation } from "react-i18next";

import TableWrapper from "../../Form/TableFields/TableWrapper/TableWrapper";
import TableHeader from "../../Form/TableFields/TableHeader/TableHeader";
import ActiveTableItem from "./ActiveTableItem/ActiveTableItem";

const ActiveTable = ({ items, page, setPage, pageCount, total }) => {
  const { t } = useTranslation();

  return (
    <div id='FinancialActiveTable'>
      <h2 className='FinancialSmalltitle'>{t("tms.titles.No Paid Orders")}</h2>
      {items && (
        <TableWrapper
          total={total}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          emptyText={t("tms.tables.noInfo.No orders")}
        >
          <TableHeader>
            <th>{t("tms.tables.titles.Order number")}</th>
            <th>{t("tms.tables.titles.Order status")}</th>
            <th>{t("tms.tables.titles.Customer")}</th>
            <th>{t("tms.tables.titles.Route")}</th>
            <th>{t("tms.tables.titles.Total price")}</th>
            <th>{t("tms.tables.titles.Already paid money")}</th>
            <th style={{ textAlign: "center" }}>
              {t("tms.tables.titles.Status")}
            </th>
            <th style={{ textAlign: "center" }}>
              {t("tms.tables.titles.Actions")}
            </th>
          </TableHeader>
          <tbody>
            {items.map((item) => (
              <ActiveTableItem key={item.id} item={item} />
            ))}
          </tbody>
        </TableWrapper>
      )}
    </div>
  );
};

export default ActiveTable;
