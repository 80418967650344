import _request from "../../_request";

const getMaintenances = async (ISO, TOKEN, data) => {
  let url = "maintenance?truck&trailer";

  const { start_date, end_date, keyword } = data || {};

  url += start_date ? `&start_date=${start_date}` : "";
  url += end_date ? `&end_date=${end_date}` : "";
  url += keyword ? `&keyword=${keyword}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getTruckMaintenances = async (ISO, TOKEN, data) => {
  const { truck_id, keyword } = data || {};

  let url = `maintenance?truck=${truck_id}`;

  url += keyword ? `&keyword=${keyword}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getTrailerMaintenances = async (ISO, TOKEN, data) => {
  const { trailer_id, keyword } = data || {};

  let url = `maintenance?trailer=${trailer_id}`;

  url += keyword ? `&keyword=${keyword}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getMaintenance = async (ISO, TOKEN, id) => {
  const config = {
    url: `maintenance/${id}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createMaintenance = async (ISO, TOKEN, data) => {
  const config = {
    url: "maintenance",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateMaintenance = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `maintenance/${id}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteMaintenance = async (ISO, TOKEN, id) => {
  const config = {
    url: `maintenance/${id}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const maintenanceService = {
  getMaintenances,
  getTrailerMaintenances,
  getTruckMaintenances,
  getMaintenance,
  createMaintenance,
  updateMaintenance,
  deleteMaintenance,
};

export default maintenanceService;
