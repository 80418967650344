import _request from "./../_request";

const getOrders = async (ISO, TOKEN, data) => {
  let url = "order?";
  const {
    page,
    keyword,
    status_id,
    trailer_type_id,
    customer_id,
    partner_id,
    start_date,
    end_date,
    min_price,
    max_price,
    revenue_currency_id,
    trailer_id,
    truck_id,
    cargo_type_id,
    pickup_address,
    dropoff_address,
    sort_by,
    direction,
  } = data || {};

  url += page ? `&page=${page}` : "";
  if (status_id) {
    if (typeof status_id === "object") {
      for (let i = 0; i < status_id?.length; i++) {
        url += `&tms_statuses[]=${status_id[i]}`;
      }
    } else {
      url += `&tms_statuses[]=${status_id}`;
    }
  }
  url += keyword ? `&keyword=${keyword}` : "";
  url += trailer_type_id ? `&trailer_type_id=${trailer_type_id}` : "";
  url += customer_id ? `&client=${customer_id}` : "";
  url += partner_id ? `&partner=${partner_id}` : "";
  url += start_date ? `&pickup_date[min]=${start_date}` : "";
  url += end_date ? `&pickup_date[max]=${end_date}` : "";
  url += min_price ? `&revenue[min]=${min_price}` : "";
  url += max_price ? `&revenue[max]=${max_price}` : "";
  url += revenue_currency_id ? `&revenue_currency_id=${revenue_currency_id}` : "";
  url += trailer_id ? `&trailer_id=${trailer_id}` : "";
  url += truck_id ? `&truck_id=${truck_id}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += cargo_type_id ? `&cargo_type_id=${cargo_type_id}` : "";
  url += sort_by ? `&sortby=${sort_by}` : "";
  url += direction ? `&direction=${direction}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getOrder = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `/order/${orderId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createOrder = async (ISO, TOKEN, data) => {
  const config = {
    url: "/order",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateOrder = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `/order/${id}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteOrder = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `/order/${orderId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getShipperActiveOrders = async (ISO, TOKEN, data) => {
  let url = "order?&statuses[]=1&statuses[]=2";
  const {
    page,
    keyword,
    pickup_date_min,
    pickup_date_max,
    pickup_address,
    dropoff_address,
    trailer_type_id,
    cargo_type_id,
    type_groups,
  } = data || {};

  url += page ? `&page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += pickup_date_min ? `&pickup_date[min]=${pickup_date_min}` : "";
  url += pickup_date_max ? `&pickup_date[max]=${pickup_date_max}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += trailer_type_id ? `&trailer_type_id=${trailer_type_id}` : "";
  url += cargo_type_id ? `&cargo_type_id=${cargo_type_id}` : "";
  if (type_groups && type_groups?.length) {
    type_groups?.forEach((i) => {
      url += `&type_groups[]=${i}`;
    });
  }

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getShipperRejectOrders = async (ISO, TOKEN, data) => {
  let url = "order?&statuses[]=7";
  const {
    page,
    keyword,
    pickup_date_min,
    pickup_date_max,
    pickup_address,
    dropoff_address,
    trailer_type_id,
    cargo_type_id,
    type_groups,
  } = data || {};

  url += page ? `&page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += pickup_date_min ? `&pickup_date[min]=${pickup_date_min}` : "";
  url += pickup_date_max ? `&pickup_date[max]=${pickup_date_max}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += trailer_type_id ? `&trailer_type_id=${trailer_type_id}` : "";
  url += cargo_type_id ? `&cargo_type_id=${cargo_type_id}` : "";
  if (type_groups && type_groups?.length) {
    type_groups?.forEach((i) => {
      url += `&type_groups[]=${i}`;
    });
  }

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getOrderFilters = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `order/${orderId}/filters`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const ordersService = {
  getOrder,
  createOrder,
  updateOrder,
  getOrders,
  deleteOrder,
  getShipperActiveOrders,
  getShipperRejectOrders,
  getOrderFilters,
};

export default ordersService;
