import React, { Fragment, useState } from "react";
import "./BoardDetailAction.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PiWarningCircleFill } from "react-icons/pi";
import { FaCheckCircle } from "react-icons/fa";

import { useMoney } from "../../../helper/useMoney";

import DButton from "../../Form/DButtons/DButton/DButton";
import MakeBidModal from "../Modals/MakeBidModal/MakeBidModal";
import DWarning from "./../../Form/DWarning/DWarning";
import AddVatModal from "../Modals/AddVatModal/AddVatModal";

const BoardDetailAction = ({ order }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const hasVat = user?.company?.legal_id;

  const [showVatModal, setShowVatModal] = useState(false);
  const [openBidModal, setOpenBidModal] = useState(false);

  const bidMoney = useMoney(order?.bid?.amount || 0, order?.bid?.currency?.symbol || "");

  return (
    <Fragment>
      {order && order?.status?.id < 3 && (
        <div id='BoardDetailAction'>
          {order.bid ? (
            order?.taken ? (
              <div className='TakeBidInformation'>
                <div className='icon'>
                  <FaCheckCircle />
                </div>
                <h4>{`${t("tms.desc.Take an order with a price")} ${bidMoney}`}</h4>
              </div>
            ) : (
              <div className='newBidInformation'>
                <div className='icon'>
                  <PiWarningCircleFill />
                </div>
                <h4>{`${t("tms.desc.You already offer")} ${bidMoney} ${t(
                  "tms.desc.for this order"
                )}.`}</h4>
              </div>
            )
          ) : (
            <div className='Noinformation'>
              <h3>{t("tms.desc.Bidding Available")}</h3>
              <DWarning
                icon={<PiWarningCircleFill />}
                text={t("tms.desc.You can offer us your acceptable price for this order")}
              />
            </div>
          )}

          <div className='action'>
            {order.bid ? (
              <div className='newBid'>
                <DButton
                  title={t("tms.tables.buttons.New bid")}
                  onClick={() => setOpenBidModal(true)}
                />
              </div>
            ) : (
              <DButton
                title={t("tms.tables.buttons.Make bid")}
                onClick={() => (hasVat ? setOpenBidModal(true) : setShowVatModal(true))}
              />
            )}
          </div>
        </div>
      )}
      {openBidModal && (
        <MakeBidModal item={order} isOpen={openBidModal} isClose={() => setOpenBidModal(false)} />
      )}
      {showVatModal && <AddVatModal isOpen={showVatModal} isClose={() => setShowVatModal(false)} />}
    </Fragment>
  );
};

export default BoardDetailAction;
