import React from "react";
import "./StatusHistoryModal.scss";

import { useTranslation } from "react-i18next";
import { MdLocationOn, MdOutlineUpdate, MdTimelapse } from "react-icons/md";

import DModal from "../../Modal/DModal/DModal";
import moment from "moment";
import { useSelector } from "react-redux";

const StatusHistoryModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();

  const { getOrderStatusUpdatesResult } = useSelector((state) => state.statusUpdates);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.Status history")}
      icon={<MdOutlineUpdate />}
      status={"noSubmit"}
    >
      <div id='StatusHistoryModal'>
        {getOrderStatusUpdatesResult?.length > 0 ? (
          getOrderStatusUpdatesResult?.map((item, index) => (
            <div className='StatusHistoryModalContent' key={index}>
              <div className='topContainer'>
                <div className='titleDetailsContainer'>
                  <div className='title'>{item?.status}</div>
                </div>
              </div>

              <div className='bottomContainer'>
                <div className='leftContainer'>
                  <div className='itemsContainer'>
                    <div className='icon'>{<MdLocationOn />}</div>
                    <div className='text'>{item?.location?.formatted_address}</div>
                  </div>
                  <div className='itemsContainer'>
                    <div className='icon'>
                      <MdTimelapse />
                    </div>
                    <div className='text'>{item?.est}</div>
                  </div>
                </div>
                <div className='date'>{moment(item?.create_date).format("YYYY-MM-DD HH:mm")}</div>
              </div>
              <div className='comment'>{item?.comment}</div>
            </div>
          ))
        ) : (
          <div className='noUpdates'>{t("tms.modals.others.There are no status updates")}</div>
        )}
      </div>
    </DModal>
  );
};

export default StatusHistoryModal;
