import _request from "./../_request";

const getNotifications = async (ISO, TOKEN, data) => {
  let url = "/user/notifications";
  const { page, read_at } = data || {};
  url += page ? `?page=${page}` : "";
  url += read_at ? `&read_at=false` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const markAllNotifications = async (ISO, TOKEN) => {
  let url = "/user/notifications/mark-all-read";
  const config = {
    url,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const markNotification = async (ISO, TOKEN, data) => {
  let url = "/user/notifications/mark-read";
  const config = {
    url,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const notificationsService = {
  getNotifications,
  markAllNotifications,
  markNotification,
};

export default notificationsService;
