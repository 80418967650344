import React from "react";
import "./TransportationField.scss";

import { useTranslation } from "react-i18next";

import { BsBoxes } from "react-icons/bs";
import { Tooltip } from "@mui/material";

const TransportationField = ({ order }) => {
  const { t } = useTranslation();
  return (
    <td id='TransportationField'>
      <Tooltip
        placement='top'
        title={
          <div className='TransportationFieldTooltipContent'>
            {order?.cargos.map((item, index) => (
              <p key={index}>
                <span>{index + 1}. </span>
                {item?.trailerType
                  ? item?.trailerType?.name || ""
                  : item?.dimensions[0]?.packing_type?.name || ""}
              </p>
            ))}
          </div>
        }
        classes={{ tooltip: "TransportationFieldTooltip" }}
      >
        <div className='TransportationFieldContainer'>
          {!order?.cargos[0]?.trailerType ? (
            <div className='icon'>
              <BsBoxes />
            </div>
          ) : (
            <img src='/assets/images/dashboard/cover7.svg' alt='Box' className='icon' />
          )}

          <div className='info'>
            <div className='title'>
              {order?.cargos[0]?.trailerType
                ? order?.cargos[0]?.trailerType?.name || ""
                : order?.cargos[0]?.dimensions[0]?.packing_type?.name || ""}
              {order?.cargos?.length > 1 && <span> (+{order?.cargos.length - 1 || ""})</span>}
            </div>
            <div className='value'>
              {order?.cargos[0]?.trailerType
                ? order?.cargos[0]?.trailersize?.label
                : `${order?.volume} ${t("shipper.units.M3")} / ${order?.weight} ${t(
                    "shipper.units.KG"
                  )}`}
            </div>
          </div>
        </div>
      </Tooltip>
    </td>
  );
};

export default TransportationField;
