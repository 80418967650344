import React, { useState } from "react";
import "./ShipperCardsSwitcher.scss";

import { useTranslation } from "react-i18next";

import ByMode from "./ByMode/ByMode";
import ByRoute from "./ByRoute/ByRoute";

const ShipperCardsSwitcher = ({ analyticsData }) => {
  const { t } = useTranslation();

  const [active, setActive] = useState("byMode");

  return (
    <div id='ShipperCardsSwitcher'>
      <div className='ShipperCardsSwitcherHeader'>
        <div
          className={`shipperCardsSwitcherItem ${active === "byMode" ? "active" : ""} `}
          onClick={() => setActive("byMode")}
        >
          {t("shipper.titles.By mode of transport")}
        </div>
        <div
          className={`shipperCardsSwitcherItem ${active === "byRoute" ? "active" : ""}`}
          onClick={() => setActive("byRoute")}
        >
          {t("shipper.titles.By route")}
        </div>
      </div>

      {active === "byMode" ? (
        <ByMode analyticsData={analyticsData} />
      ) : (
        <ByRoute analyticsData={analyticsData} />
      )}
    </div>
  );
};

export default ShipperCardsSwitcher;
