import React, { Fragment, useState, useEffect } from "react";
import "./CreatePartnerModal.scss";

import { useTranslation } from "react-i18next";
import { FaPlusCircle } from "react-icons/fa";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import * as amplitude from "@amplitude/analytics-browser";

import { createPartner, resetCreatePartner } from "./../../../../store/partner/partnerSlice";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";
import SelectableButton from "../../Components/SelectableButton/SelectableButton";

const CreatePartnerModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createPartnerLoading, createPartnerResult, createPartnerError } = useSelector(
    (state) => state.partner
  );

  const { getTrailerTypesRoadResult } = useSelector((state) => state.trailerType);
  const { getAllRoutesRoadListResult } = useSelector((state) => state.routes);

  const [partnerID, setPartnerID] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [showSecondaryPhone, setShowSecondaryPhone] = useState(false);
  const [selectedTrailerTypes, setSelectedTrailerTypes] = useState([]);
  const [selectedRoutes, setSelectedRoutes] = useState([]);

  const submitHandler = () => {
    const data = {
      code: partnerID,
      name: partnerName,
      email: email,
      phone: phone,
      phone2: phone2,
      trailer_types: selectedTrailerTypes,
      route_types: selectedRoutes,
    };
    dispatch(createPartner(data));
  };

  useEffect(() => {
    if (createPartnerResult) {
      amplitude.track("TMS: Create Partner");
      dispatch(resetCreatePartner());
      isClose();
    }
  }, [dispatch, createPartnerResult, isClose]);

  useEffect(() => {
    return () => {
      dispatch(resetCreatePartner());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={isClose}
        title={t("tms.modals.titles.Add Partner")}
        icon={<FaPlusCircle />}
        status='success'
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        loading={createPartnerLoading}
      >
        <div id='CreatePartnerModal'>
          <div className='CreatePartnerModalgroup'>
            <DInputs
              value={partnerName}
              setValue={setPartnerName}
              placeholder={t("tms.modals.placeholder.Enter partner name")}
              label={t("tms.modals.labels.Partner name")}
              required
              id='partnerName'
              error={createPartnerError?.errors?.name?.[0] ?? null}
            />
            <DInputs
              value={partnerID}
              setValue={setPartnerID}
              placeholder={t("tms.modals.placeholder.Enter partner ID")}
              label={t("tms.modals.labels.Partner ID")}
              required
              id='partnerIDID'
              error={createPartnerError?.errors?.code?.[0] ?? null}
            />
            <DInputs
              id='PartnerPhone'
              value={phone}
              setValue={setPhone}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              status={t("tms.modals.labels.Primary")}
              error={createPartnerError?.errors?.phone?.[0] ?? null}
              required
            />

            {!showSecondaryPhone ? (
              <div className='showSecondaryPhoneButtonContainer'>
                <div
                  className='showSecondaryPhoneButton'
                  onClick={() => setShowSecondaryPhone(true)}
                >
                  <div className='icon'>
                    <FiPlus />
                  </div>
                  <div className='text'>{t("tms.modals.buttons.Add secondary phone number")}</div>
                </div>
              </div>
            ) : (
              <Fragment>
                <DInputs
                  id='PartnerPhoneSecondary'
                  value={phone2}
                  setValue={setPhone2}
                  placeholder={t("tms.modals.placeholder.Enter phone number")}
                  label={t("tms.modals.labels.Phone number")}
                  status={t("tms.modals.labels.Secondary")}
                  error={createPartnerError?.errors?.phone2?.[0] ?? null}
                  required
                />
                <div className='hideSecondaryPhoneButtonContainer'>
                  <div
                    className='hideSecondaryPhoneButton'
                    onClick={() => setShowSecondaryPhone(false)}
                  >
                    <div className='icon'>
                      <FiMinus />
                    </div>
                    <div className='text'>
                      {t("tms.modals.buttons.Remove secondary phone number")}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}

            <DInputs
              value={email}
              setValue={setEmail}
              placeholder={t("tms.modals.placeholder.Enter email")}
              label={t("tms.modals.labels.Email")}
              id='PartnerEmail'
              error={createPartnerError?.errors?.email?.[0] ?? null}
            />
            <div className='prefferedTrailerTypesContainer'>
              <div className='prefferedTrailerTypesTitle'>
                {t("tms.modals.titles.Preffered trailer types")}
              </div>
              <div className='prefferedTrailerTypesList'>
                {getTrailerTypesRoadResult &&
                  getTrailerTypesRoadResult.map((prefferedTrilerType) => (
                    <SelectableButton
                      key={prefferedTrilerType?.id}
                      item={prefferedTrilerType}
                      selectedTypes={selectedTrailerTypes}
                      setSelectedTypes={setSelectedTrailerTypes}
                    />
                  ))}
              </div>
            </div>

            <div className='prefferedTrailerTypesContainer'>
              <div className='prefferedRoutesTitle'>{t("tms.modals.titles.Preffered routes")}</div>
              <div className='prefferedTrailerTypesList'>
                {getAllRoutesRoadListResult &&
                  getAllRoutesRoadListResult?.map((item) => (
                    <SelectableButton
                      key={item?.id}
                      item={item}
                      selectedTypes={selectedRoutes}
                      setSelectedTypes={setSelectedRoutes}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default CreatePartnerModal;
