import React, { Fragment, useState, useEffect } from "react";
import "./FaqSection.scss";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { getFAQ } from "../../../../store/additional/rules/rulesSlice";

import FaqItem from "./../Components/FaqItem/FaqItem";
import FAQCover from "./../Components/FAQCover/FAQCover";
import HomeTitle from "../../Components/HomeTitle/HomeTitle";

const FaqSection = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { getFAQResult } = useSelector((state) => state.rules);

  useEffect(() => {
    dispatch(getFAQ());
  }, [dispatch]);

  const [openIndex, setOpenIndex] = useState();

  const changedHandler = (index) =>
    setOpenIndex(index === openIndex ? null : index);

  return (
    <Fragment>
      {getFAQResult && (
        <section id='FaqSection' className='FaqSection'>
          <div className='wrapper'>
            <HomeTitle>{t("landing.titles.landingMainTitles.FAQ")}</HomeTitle>
            <div className='container'>
              <div className='questions'>
                {getFAQResult?.filter((FAQitem) => FAQitem.general).map((item, index) => (
                  <FaqItem
                    key={index}
                    item={item}
                    index={index}
                    onChange={changedHandler}
                    openIndex={openIndex}
                  />
                ))}
              </div>
              <FAQCover url={"/assets/images/static/cover6.jpg"} />
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default FaqSection;
