import _request from "../../_request";

const uploadFile = async (ISO, token, data) => {
  const config = {
    url: "/profile/avatar",
    method: "post",
    ISO,
    token,
    file: true,
  };
  return _request(config, data);
};

const uploadCompanyLogo = async (ISO, token, data) => {
  const config = {
    url: "/profile/company/logo",
    method: "post",
    ISO,
    token,
    file: true,
  };
  return _request(config, data);
};

const uploadService = { uploadFile, uploadCompanyLogo };

export default uploadService;
