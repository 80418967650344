import React from "react";
import "./AirPackage.scss";

import { useTranslation } from "react-i18next";
import { FiPlusCircle } from "react-icons/fi";

import AirPackageItem from "./AirPackageItem/AirPackageItem";
import CreateOrderSectionTitle from "./../../Components/CreateOrderSectionTitle/CreateOrderSectionTitle";
import DTextButton from "../../../../Form/DButtons/DTextButton/DTextButton";

const AirPackage = ({ edit, group, setGroup }) => {
  const { t } = useTranslation();
  const createNewItemHandler = () => {
    const newGroup = [
      ...group,
      {
        cargoType: null,
        cargoTypeError: null,
        packageType: null,
        packageTypeError: null,
        HSCode: false,
        HSCodeValue: "",
        HSCodeValueError: null,
        //////
        adr: false,
        adrError: null,
        adrNumber: "",
        adrNumberError: null,
        adrClass: "",
        adrClassError: null,
        adrFile: [],
        //////
        dimensions: [
          {
            count: 1,
            countError: null,
            weight: "",
            weightError: null,
            length: "",
            lengthError: null,
            width: "",
            widthError: null,
            height: "",
            heightError: null,
          },
        ],
      },
    ];
    setGroup(newGroup);
  };

  return (
    <div id='ShipperAirPackage'>
      <CreateOrderSectionTitle title={t("shipper.forms.titles.Package")} />
      <div className='packagesContainer'>
        {group.map((item, index) => (
          <AirPackageItem
            edit={edit}
            key={index}
            index={index}
            item={item}
            group={group}
            setGroup={setGroup}
          />
        ))}
      </div>

      <div className='ShipperOceanPackageNewItemBtn'>
        <DTextButton
          title={t("shipper.buttons.Add new Product type")}
          leftIcon={<FiPlusCircle />}
          onClick={() => createNewItemHandler()}
        />
      </div>
    </div>
  );
};

export default AirPackage;
