import React, { Fragment, useState } from "react";
import "./SentUpdatesDetails.scss";

import { useTranslation } from "react-i18next";
import { RxUpdate } from "react-icons/rx";

import SentUpdatesDetailsItem from "./SentUpdatesDetailsItem/SentUpdatesDetailsItem";
import DButton from "./../../Form/DButtons/DButton/DButton";

const SentUpdatesDetails = ({ order }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <Fragment>
      {order &&
        order.cargos[0]?.statusHistory &&
        order.cargos[0]?.statusHistory.length > 0 && (
          <div id='SentUpdatesDetails'>
            <div className='titleContainer'>
              <div className='titleContainerLeft'>
                <div className='icon'>
                  <RxUpdate />
                </div>
                <h1 className='title'>{t("tms.titles.Sent updates")}</h1>
              </div>
            </div>
            <ul>
              {order.cargos[0]?.statusHistory.length === 0 ? (
                <div className='NoSentUpdates'>
                  <p>{t("tms.desc.No status updates")}</p>
                </div>
              ) : (
                <Fragment>
                  {order.cargos[0]?.statusHistory
                    .slice()
                    .reverse()
                    .map((item, index) => (
                      <div
                        key={item.id}
                        className={
                          index === 0
                            ? "latestUpdate"
                            : show
                            ? "moreSentUpdates show"
                            : "moreSentUpdates"
                        }
                      >
                        <SentUpdatesDetailsItem item={item} order={order} />
                      </div>
                    ))}
                </Fragment>
              )}
            </ul>
            {order.cargos[0]?.statusHistory.length > 1 && (
              <DButton
                title={
                  show
                    ? t("tms.forms.buttons.See last update")
                    : t("tms.forms.buttons.See all updates")
                }
                onClick={() => setShow(!show)}
              />
            )}
          </div>
        )}
    </Fragment>
  );
};

export default SentUpdatesDetails;
