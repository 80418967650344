import React from "react";

import { MarkerF, InfoWindowF } from "@react-google-maps/api";
import { Fragment } from "react";

import { googleMapsSvg } from "./googleMapsSvg";
import InfoWindow from "./InfoWindow/InfoWindow";

const Marker = ({ item, activeItem, setActiveItem }) => {
  return (
    <Fragment>
      {item && (
        <MarkerF
          key={item.id}
          // animation={2}
          icon={googleMapsSvg()}
          position={{
            lat: item?.location?.coordinate?.coordinates[1] || 0,
            lng: item?.location?.coordinate?.coordinates[0] || 0,
          }}
          onClick={() => setActiveItem(item)}
        >
          {activeItem && activeItem.id === item.id && (
            <InfoWindowF
              position={{
                lat: item?.location?.coordinate?.coordinates[1] || 0,
                lng: item?.location?.coordinate?.coordinates[0] || 0,
              }}
              onCloseClick={() => setActiveItem(null)}
            >
              <InfoWindow item={item} />
            </InfoWindowF>
          )}
        </MarkerF>
      )}
    </Fragment>
  );
};

export default Marker;
