import React from "react";
import "./TruckStatusField.scss";

import { useTranslation } from "react-i18next";

const TruckStatusField = ({ status }) => {
  const { t } = useTranslation();

  return (
    <td id='TruckStatusField'>
      {status ? (
        <div className='text'>
          <p>{t("tms.tables.others.Active")}</p>
        </div>
      ) : (
        <div className='text inActive'>
          <p>{t("tms.tables.others.Inactive")}</p>
        </div>
      )}
    </td>
  );
};

export default TruckStatusField;
