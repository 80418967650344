import React from "react";
import "./SideView.scss";

import SideDetails from "../SideDetails/SideDetails";
import OrdersSidebar from "../Components/OrdersSidebar/OrdersSidebar";
import SideViewHeader from "../Components/SideViewHeader/SideViewHeader";

const SideView = ({
  page,
  setPage,
  activeOrderId,
  setActiveOrderId,
  activeOrderIndex,
  setActiveOrderIndex,
  // filters
  keyword,
  setKeyword,
  status,
  setStatus,
  shippingType,
  setShippingType,
  dateRange,
  setDateRange,
  route,
  setRoute,
  trailerType,
  setTrailerType,
  productCategory,
  setProductCategory,
  onClear,
}) => {
  return (
    <section id='shipperOrderSideView'>
      <div id='shipperOrdersSidebar'>
        <SideViewHeader
          keyword={keyword}
          setKeyword={setKeyword}
          status={status}
          setStatus={setStatus}
          shippingType={shippingType}
          setShippingType={setShippingType}
          dateRange={dateRange}
          setDateRange={setDateRange}
          route={route}
          setRoute={setRoute}
          trailerType={trailerType}
          setTrailerType={setTrailerType}
          productCategory={productCategory}
          setProductCategory={setProductCategory}
          onClear={onClear}
        />
        <OrdersSidebar
          page={page}
          setPage={setPage}
          activeOrderId={activeOrderId}
          setActiveOrderId={setActiveOrderId}
          activeOrderIndex={activeOrderIndex}
          setActiveOrderIndex={setActiveOrderIndex}
        />
      </div>

      <SideDetails
        activeOrderId={activeOrderId}
        setActiveOrderId={setActiveOrderId}
      />
    </section>
  );
};

export default SideView;
