import _request from "../../_request";

const getTMSStatuses = async (ISO, TOKEN) => {
  const config = {
    url: "/order/tms-statuses",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getClientStatuses = async (ISO, TOKEN) => {
  const config = {
    url: "/order/client-statuses",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const updateOrderStatuses = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `order/${orderId}/change-tms-status`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const orderStatusesService = {
  getTMSStatuses,
  getClientStatuses,
  updateOrderStatuses,
};

export default orderStatusesService;
