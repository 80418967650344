import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import trailerTypeService from "./trailerTypeService";

const initialState = {
  getTrailerTypesRoadLoading: true,
  getTrailerTypesRoadResult: null,
  getTrailerTypesRoadGroupSelectResult: null,
  getTrailerTypesRoadSelectResult: null,
  getTrailerTypesRoadError: null,

  getTrailerTypesOceanLoading: true,
  getTrailerTypesOceanResult: null,
  getTrailerTypesOceanSelectResult: null,
  getTrailerTypesOceanError: null,
};

export const getTrailerTypesRoad = createAsyncThunk(
  "trailerType/getTrailerTypesRoad",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trailerTypeService.getTrailerTypes(ISO, TOKEN, 1);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTrailerTypesOcean = createAsyncThunk(
  "trailerType/getTrailerTypesOcean",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trailerTypeService.getTrailerTypes(ISO, TOKEN, 4);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const trailerTypeSlice = createSlice({
  name: "trailerType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getTrailerTypesRoad.pending, (state) => {
        state.getTrailerTypesRoadLoading = true;
        state.getTrailerTypesRoadResult = null;
        state.getTrailerTypesRoadGroupSelectResult = null;
        state.getTrailerTypesRoadSelectResult = null;
        state.getTrailerTypesRoadError = null;
      })
      .addCase(getTrailerTypesRoad.fulfilled, (state, action) => {
        state.getTrailerTypesRoadLoading = false;
        state.getTrailerTypesRoadResult = action.payload;
        state.getTrailerTypesRoadGroupSelectResult = action.payload.map((item) => ({
          label: item.name,
          value: item,
        }));
        state.getTrailerTypesRoadSelectResult = action.payload.map((item) => ({
          label: item.name,
          options: item.types.map((i) => ({
            label: i.name,
            value: i,
          })),
        }));
        state.getTrailerTypesRoadError = null;
      })
      .addCase(getTrailerTypesRoad.rejected, (state, action) => {
        state.getTrailerTypesRoadLoading = false;
        state.getTrailerTypesRoadResult = null;
        state.getTrailerTypesRoadGroupSelectResult = null;
        state.getTrailerTypesRoadSelectResult = null;
        state.getTrailerTypesRoadError = action.payload;
      })

      .addCase(getTrailerTypesOcean.pending, (state) => {
        state.getTrailerTypesOceanLoading = true;
        state.getTrailerTypesOceanResult = null;
        state.getTrailerTypesOceanSelectResult = null;
        state.getTrailerTypesOceanError = null;
      })
      .addCase(getTrailerTypesOcean.fulfilled, (state, action) => {
        state.getTrailerTypesOceanLoading = false;
        state.getTrailerTypesOceanResult = action.payload;
        state.getTrailerTypesOceanSelectResult = action.payload.map((item) => ({
          label: item.name,
          options: item.types.map((i) => ({
            label: i.name,
            value: i,
          })),
        }));
        state.getTrailerTypesOceanError = null;
      })
      .addCase(getTrailerTypesOcean.rejected, (state, action) => {
        state.getTrailerTypesOceanLoading = false;
        state.getTrailerTypesOceanResult = null;
        state.getTrailerTypesOceanSelectResult = null;
        state.getTrailerTypesOceanError = action.payload;
      });
  },
});

export default trailerTypeSlice.reducer;
