import React from 'react';
import './RoleRadio.scss';

import { useTranslation } from 'react-i18next';

import { FiCheck } from 'react-icons/fi';

const RoleRadio = ({ value, setValue, labelOne, labelTwo }) => {
  const { t } = useTranslation();

  return (
    <div id='RoleRadio'>
      <div className={value === 'CLIENT' ? 'active item' : 'item'}>
        <div
          className='icon'
          onClick={() => {
            setValue('CLIENT');
          }}
        >
          <FiCheck />
        </div>
        <p
          onClick={() => {
            setValue('CLIENT');
          }}
        >
          {labelOne ? labelOne : t('landing.form.label.I’m a shipper')}
        </p>
      </div>
      <div className={value === 'PARTNER' ? 'active item' : 'item'}>
        <div
          className='icon'
          onClick={() => {
            setValue('PARTNER');
          }}
        >
          <FiCheck />
        </div>
        <p
          onClick={() => {
            setValue('PARTNER');
          }}
        >
          {labelTwo ? labelTwo : t('landing.form.label.I’m a carrier')}
        </p>
      </div>
    </div>
  );
};

export default RoleRadio;
