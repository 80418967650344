import React, { Fragment, useState } from "react";
import "./FilledOwnCarrier.scss";

import { useTranslation } from "react-i18next";
import { FaUser } from "react-icons/fa";
import { MdLinkOff } from "react-icons/md";

import Character from "../../../UI/Character/Character";
import DButton from "../../../Form/DButtons/DButton/DButton";
import LinkOffCarrierModal from "./../../ManageOrder/LinkOffCarrierModal/LinkOffCarrierModal";

const FilledOwnCarrier = ({ order }) => {
  const { t } = useTranslation();
  const [showLinkOfModal, setShowLinkOfModal] = useState(false);

  return (
    <Fragment>
      <div id='FilledOwnCarrier'>
        <div className='FilledOwnCarrierItem'>
          <div className='icon'>
            <FaUser />
          </div>
          <div className='text'>
            <h3>
              <Character title={order?.transports?.[0]?.driver} max={30} />
            </h3>
            <p>{order?.transports?.[0]?.phone}</p>
          </div>
        </div>
        <div className='FilledOwnCarrierItem'>
          <div
            className='cover'
            style={{
              backgroundImage: `url(${`/assets/images/dashboard/cover9.svg`})`,
            }}
          ></div>
          <div className='text'>
            <h3>
              <Character
                title={order?.transports?.[0]?.number || t("tms.desc.No truck")}
                max={30}
              />
            </h3>
          </div>
        </div>
        <div className='FilledOwnCarrierItem'>
          <DButton
            onClick={() => setShowLinkOfModal(true)}
            title={t("tms.forms.buttons.Unlink")}
            rightIcon={<MdLinkOff />}
          />
        </div>
      </div>
      {showLinkOfModal && (
        <LinkOffCarrierModal
          ownCarrier
          isOpen={showLinkOfModal}
          isClose={() => setShowLinkOfModal(false)}
          order={order}
        />
      )}
    </Fragment>
  );
};

export default FilledOwnCarrier;
