import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DelayedRedirect = ({ to, delay = 0 }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(to, { replace: true });
    }, delay);

    return () => clearTimeout(timer);
  }, [to, delay, navigate]);

  return null;
};

export default DelayedRedirect;
