import React from "react";
import "./SelectTrailerModalTable.scss";

import { useTranslation } from "react-i18next";

import SelectTrailerModalTableItem from "./SelectTrailerModalTableItem/SelectTrailerModalTableItem";
import TableHeader from "../../../../Form/TableFields/TableHeader/TableHeader";
import NoInformation from "./../../../../Form/TableFields/NoInformation/NoInformation";

const SelectTrailerModalTable = ({ trailers, truckId }) => {
  const { t } = useTranslation();
  return (
    <div id='SelectTrailerModalTable'>
      <div className='tableWrapper'>
        <table>
          <TableHeader>
            <th>{t("tms.tables.titles.Trailer")}</th>
            <th>{t("tms.tables.titles.Plate number")}</th>
            <th>{t("tms.tables.titles.Max volume (M³)")}</th>
            <th>{t("tms.tables.titles.Max weight (KG)")}</th>
            <th>{t("tms.tables.titles.Linked to")}</th>
            <th>{t("tms.tables.titles.Action button")}</th>
          </TableHeader>
          <tbody>
            {trailers.map((trailer) => (
              <SelectTrailerModalTableItem
                key={trailer.id}
                trailer={trailer}
                truckId={truckId}
              />
            ))}
          </tbody>
        </table>
        {trailers.length === 0 && (
          <NoInformation title={t("tms.tables.noInfo.Trailers not found")} />
        )}
      </div>
    </div>
  );
};

export default SelectTrailerModalTable;
