import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import invoicesService from "./invoicesService";

const initialState = {
  getInvoiceLoading: true,
  getInvoiceResult: null,
  getInvoiceError: null,

  createInvoiceLoading: false,
  createInvoiceResult: null,
  createInvoiceError: null,
};

export const getInvoice = createAsyncThunk(
  "invoices/getInvoice",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await invoicesService.getInvoice(ISO, TOKEN, data.orderId, ISO);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createInvoice = createAsyncThunk("invoices/createInvoice", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    const result = await invoicesService.createInvoice(ISO, TOKEN, data.orderId, data, ISO);
    return result;
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState, //
  reducers: {
    resetGetInvoice: (state) => {
      state.getInvoiceLoading = true;
      state.getInvoiceResult = null;
      state.getInvoiceError = null;
    },
    resetCreateInvoice: (state) => {
      state.createInvoiceLoading = false;
      state.createInvoiceResult = null;
      state.createInvoiceError = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getInvoice.pending, (state) => {
        state.getInvoiceLoading = true;
        state.getInvoiceResult = null;
        state.getInvoiceError = null;
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.getInvoiceLoading = false;
        state.getInvoiceResult = action.payload;
        state.getInvoiceError = null;
      })
      .addCase(getInvoice.rejected, (state, action) => {
        state.getInvoiceLoading = false;
        state.getInvoiceResult = null;
        state.getInvoiceError = action.payload;
      })

      .addCase(createInvoice.pending, (state) => {
        state.createInvoiceLoading = true;
        state.createInvoiceResult = null;
        state.createInvoiceError = null;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.createInvoiceLoading = false;
        state.createInvoiceResult = action.payload;
        state.createInvoiceError = null;
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.createInvoiceLoading = false;
        state.createInvoiceResult = null;
        state.createInvoiceError = action.payload;
      });
  },
});

export const { resetDeleteOrderDocument, resetGetInvoice, resetCreateInvoice } =
  invoicesSlice.actions;
export default invoicesSlice.reducer;
