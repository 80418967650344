import React from "react";
import "./ShipperProfile.scss";

import { useSelector } from "react-redux";

import { NavLink } from "react-router-dom";

const ShipperProfile = ({ to }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <NavLink id='ShipperProfile' to={to}>
      <div
        className='ShipperProfileAvatar'
        style={{ backgroundImage: `url(${user?.avatar})` }}
      ></div>
    </NavLink>
  );
};

export default ShipperProfile;
