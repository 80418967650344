import React, { Fragment, useState, useEffect } from "react";
import "./SendBidDetails.scss";

import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import { MdClose } from "react-icons/md";
import { FiArrowLeft } from "react-icons/fi";
import { IoMdSend } from "react-icons/io";

import {
  bidsRequest,
  resetBidsRequest,
} from "../../../../../../store/orders/manageOrder/manageOrderSlice";

import DTextButton from "../../../../../Form/DButtons/DTextButton/DTextButton";
import DButton from "../../../../../Form/DButtons/DButton/DButton";
import DTextarea from "../../../../../Form/DTextarea/DTextarea";
import PartnersGroup from "./PartnersGroup/PartnersGroup";
import DescriptionGroup from "./DescriptionGroup/DescriptionGroup";

const SendBidDetails = ({
  setBidScreen,
  isOpen,
  isClose,
  order,
  tab,
  setTab,
  partnerTab,
  setPartnerTab,
  partnersBids,
  setPartnersBids,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { bidsRequestLoading, bidsRequestResult } = useSelector(
    (state) => state.manageOrder
  );
  const [bidAdditionalText, setBidAdditionalText] = useState("");

  // const pathname = window?.location?.pathname;
  // const urlCountry = pathname?.split("/")?.[1]?.split("_")?.[0];

  const submitHandler = () => {
    const data = {
      orderId: order.id,
      partner_ids: partnersBids.map((i) => i.id),
      message: bidAdditionalText.length > 1 ? bidAdditionalText : null,
      // url: `${window.location.origin}/${urlCountry}_en/bids/partner/:token`,
    };
    dispatch(bidsRequest(data));
  };

  useEffect(() => {
    if (bidsRequestResult) {
      dispatch(resetBidsRequest());
      // isClose();
      // setTab("ownFleet");
      setPartnerTab("assign");
      setBidScreen(false);
      setPartnersBids([]);
    }
  }, [
    dispatch,
    // isClose,
    bidsRequestResult,
    setBidScreen,
    // setTab,
    setPartnerTab,
    setPartnersBids,
  ]);

  return (
    <Fragment>
      <div id='SendBidDetails'>
        <div className='SendBidDetailsContent'>
          <div className='SendBidDetailsContainer'>
            <div className='SendBidDetailsHeader'>
              <div
                className='SendBidDetailsHeaderBackButton'
                onClick={() => setBidScreen(false)}
              >
                <FiArrowLeft />
                {t("tms.forms.buttons.Back")}
              </div>
              <MdClose
                onClick={() => {
                  isClose();
                  setTab("ownFleet");
                  setPartnerTab("assign");
                  setBidScreen(false);
                  setPartnersBids([]);
                }}
              />
            </div>

            <PartnersGroup
              partnersBids={partnersBids}
              setPartnersBids={setPartnersBids}
            />

            <DTextarea
              value={bidAdditionalText}
              setValue={setBidAdditionalText}
              label={t("tms.forms.labels.Additional text")}
              placeholder={t("tms.forms.placeholder.Additional text")}
              // required
              id='bidAdditionalText'
              error={null}
            />
            <DescriptionGroup
              order={order}
              bidAdditionalText={bidAdditionalText}
            />

            <div className='sendBidActions'>
              <DTextButton
                title={t("tms.forms.buttons.Cancel")}
                onClick={() => {
                  setBidScreen(false);
                }}
              />
              {partnersBids.length === 0 ? (
                <div className='all'>
                  <DButton
                    loading={bidsRequestLoading}
                    title={t("tms.forms.buttons.Send bid all partner")}
                    rightIcon={<IoMdSend />}
                    onClick={() => submitHandler()}
                  />
                </div>
              ) : (
                <DButton
                  loading={bidsRequestLoading}
                  title={t("tms.forms.buttons.Send bid select partners")}
                  rightIcon={<IoMdSend />}
                  onClick={() => submitHandler()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SendBidDetails;
