import React, { Fragment } from "react";

import RowField from "../../Form/TableFields/RowField/RowField";
import ShippingTypeField from "../../Form/TableFields/ShippingTypeField/ShippingTypeField";
import RouteField from "../../Form/TableFields/RouteField/RouteField";
import ProductCategoryField from "../../Form/TableFields/ProductCategoryField/ProductCategoryField";
import DateField from "../../Form/TableFields/DateField/DateField";
import RejectQuotationField from "../../Form/TableFields/QuotationField/RejectQuotationField/RejectQuotationField";
import NameField from "../../Form/TableFields/NameField/NameField";
import TransportationField from "../../Form/TableFields/TransportationField/TransportationField";

const ShipperRejectOrdersTableItem = ({ order }) => {
  return (
    <Fragment>
      <RowField
        style={{
          borderLeft: `4px solid ${order?.color ? order?.color : "transparent"}`,
        }}
      >
        <NameField label={order?.tracking || "-"} to={`/dashboard/shipper/orders/${order?.id}`} />
        <ShippingTypeField type={order?.type} />
        <RouteField order={order} />
        <ProductCategoryField order={order} />
        <TransportationField order={order} />
        <DateField date={order?.cargos?.[0]?.pickup_date} />
        <RejectQuotationField order={order} />
      </RowField>
    </Fragment>
  );
};

export default ShipperRejectOrdersTableItem;
