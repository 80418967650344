import React from "react";
import "./SmallHomeTitle.scss";

const SmallHomeTitle = ({ title }) => {
  return (
    <div id='SmallHomeTitle' className='SmallHomeTitle'>
      <h1>{title}</h1>
    </div>
  );
};

export default SmallHomeTitle;
