import React from "react";
import "./DigitalContent.scss";

import { BsArrowUpRight } from "react-icons/bs";
import parse from "html-react-parser";

import HomeTitle from "../../../Components/HomeTitle/HomeTitle";
import LinkTransparent from "./../../../Components/Links/LinkTransparent/LinkTransparent";

const DigitalContent = ({
  link,
  title,
  description,
  smallTitle,
  buttonTitle,
}) => {
  return (
    <div id='DigitalContent'>
      <div
        data-aos='fade-right'
        data-aos-duration='1500'
        data-aos-delay='100'
        data-aos-once='true'
      >
        <div className='content'>
          <div className='desc'>
            <h6>{smallTitle}</h6>
          </div>

          <HomeTitle description={description}>{parse(title)}</HomeTitle>
          <LinkTransparent
            title={buttonTitle}
            to={link}
            icon={<BsArrowUpRight />}
          />
        </div>
      </div>
    </div>
  );
};

export default DigitalContent;
