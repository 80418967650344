import React from "react";
import "./SentUpdatesDetailsItem.scss";

import { useTranslation } from "react-i18next";

import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdCalendarToday } from "react-icons/md";

const SentUpdatesDetailsItem = ({ item, order }) => {
  const { t } = useTranslation();

  return (
    <li id='SentUpdateSentUpdatesDetailsItem'>
      <div className='SentUpdatesDetailsContainerHeader'>
        <div className='SentUpdatesDetailsContainerHeaderLeft'>
          {t("tms.desc.update To")}:
          {order.customer && (
            <h3>
              {order?.ownership
                ? order?.customer?.company?.name
                : order?.company?.name || ""}
            </h3>
          )}
        </div>

        <div
          className='SentUpdatesDetailsContainerHeaderRight'
          style={{
            backgroundColor: `${item.status.color_2}`,
          }}
        >
          <div className='icon' style={{ color: `${item.status.color_1}` }}>
            <IoIosCheckmarkCircle />
          </div>
          <h3 style={{ color: `${item.status.color_1}` }}>
            {item.status.name}
          </h3>
        </div>
      </div>
      <p>
        {item.message ? item.message : <span>{t("tms.desc.No message")}</span>}
      </p>
      <div className='SentUpdatesDetailsDate'>
        <div className='icon'>
          <MdCalendarToday />
        </div>
        <p>{item.created_at || ""}</p>
      </div>
    </li>
  );
};

export default SentUpdatesDetailsItem;
