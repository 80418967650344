import _request from "../_request";

const getRoutesList = async (ISO, token, orderTypeGroupId) => {
  const config = {
    url: `/routes?order_type_group=${orderTypeGroupId || ""}`,
    token,
    ISO,
  };
  return _request(config);
};

const routesService = {
  getRoutesList,
};

export default routesService;
