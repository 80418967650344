import React from "react";
import "./RoadLocationRoute.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { MdLocationPin, MdLocationSearching, MdPinDrop } from "react-icons/md";
import { FiPlus, FiMinus } from "react-icons/fi";

import DInputs from "../../../../../Form/DInputs/DInputs";
import Character from "../../../../../UI/Character/Character";

const RoadLocationRoute = ({ locations, setLocations, edit }) => {
  const { t } = useTranslation();
  const { getPickupLOCALITYResult, getDropoffLOCALITYResult } = useSelector(
    (state) => state.locationSuggestion
  );
  const handleChangeA = (onChangeValue, i) => {
    const inputdataA = [...locations.filter((i) => i.direction === "A")];
    const inputdataB = [...locations.filter((i) => i.direction === "B")];

    inputdataA[i] = {
      ...inputdataA[i],
      inputted_address: onChangeValue,
    };
    setLocations([...inputdataA, ...inputdataB]);
  };

  const handleChangeB = (onChangeValue, i) => {
    const inputdataA = [...locations.filter((i) => i.direction === "A")];
    const inputdataB = [...locations.filter((i) => i.direction === "B")];

    inputdataB[i] = {
      ...inputdataB[i],
      inputted_address: onChangeValue,
    };
    setLocations([...inputdataA, ...inputdataB]);
  };

  return (
    <div id='RoadLocationRouteShipper' className={edit ? "edit" : ""}>
      <div className='lineVertical'></div>

      {locations
        .filter((i) => i.direction === "A")
        .map((data, i) => {
          const handleAdd = () => {
            const abc = [
              ...locations.filter((i) => i.direction === "A"),
              { direction: "A", inputted_address: "", error: null },
              ...locations.filter((i) => i.direction === "B"),
            ];
            setLocations(abc);
          };
          const handleRemove = () => {
            const inputdataA = [...locations.filter((i) => i.direction === "A")];
            const inputdataB = [...locations.filter((i) => i.direction === "B")];
            inputdataA.splice(i, 1);
            setLocations([...inputdataA, ...inputdataB]);
          };
          return (
            <div className='managementItem' key={i}>
              <div className='locationManagementItemIcon pickUp'>
                <MdLocationSearching />
              </div>
              {!edit && locations.filter((i) => i.direction === "A").length - 1 === i && (
                <div
                  className='managementItemIcon add'
                  onClick={() => {
                    handleAdd();
                  }}
                >
                  <FiPlus />
                </div>
              )}
              {!edit &&
                locations.filter((i) => i.direction === "A").length !== i &&
                locations.filter((i) => i.direction === "A").length !== 1 && (
                  <div
                    className='managementItemIcon remove'
                    onClick={() => {
                      handleRemove();
                    }}
                  >
                    <FiMinus />
                  </div>
                )}
              <DInputs
                required
                id={`FromOrderLocation${i}`}
                label={t("shipper.forms.content.From")}
                placeholder={t("shipper.forms.content.Where to pick up")}
                value={data.inputted_address}
                setValue={(e) => handleChangeA(e, i)}
                icon={<MdPinDrop />}
                error={data.error}
              />
              {!edit && (
                <div className='suggestionsContainer'>
                  {getPickupLOCALITYResult?.map((item, index) => (
                    <div
                      className='suggestionItem'
                      key={index}
                      onClick={() => {
                        handleChangeA(item.address, i);
                      }}
                    >
                      <Character max={11} title={item?.address} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}

      {locations
        .filter((i) => i.direction === "B")
        .map((data, i) => {
          const handleAdd = () => {
            const abc = [
              ...locations.filter((i) => i.direction === "A"),
              ...locations.filter((i) => i.direction === "B"),
              { direction: "B", inputted_address: "", error: null },
            ];
            setLocations(abc);
          };
          const handleRemove = () => {
            const inputdataA = [...locations.filter((i) => i.direction === "A")];
            const inputdataB = [...locations.filter((i) => i.direction === "B")];
            inputdataB.splice(i, 1);
            setLocations([...inputdataA, ...inputdataB]);
          };

          return (
            <div className='managementItem' key={i}>
              <div className='locationManagementItemIcon dropOff'>
                <MdLocationPin />
              </div>
              {!edit && locations.filter((i) => i.direction === "B").length - 1 === i && (
                <div
                  className='managementItemIcon add'
                  onClick={() => {
                    handleAdd();
                  }}
                >
                  <FiPlus />
                </div>
              )}
              {!edit &&
                locations.filter((i) => i.direction === "B").length !== i &&
                locations.filter((i) => i.direction === "B").length !== 1 && (
                  <div
                    className='managementItemIcon remove'
                    onClick={() => {
                      handleRemove();
                    }}
                  >
                    <FiMinus />
                  </div>
                )}
              <DInputs
                required
                id={`ToOrderLocation${i}`}
                label={t("shipper.forms.content.To")}
                placeholder={t("shipper.forms.content.Where to drop off")}
                value={data.inputted_address}
                setValue={(e) => handleChangeB(e, i)}
                icon={<MdPinDrop />}
                error={data.error}
              />
              {!edit && (
                <div className='suggestionsContainer'>
                  {getDropoffLOCALITYResult?.map((item, index) => (
                    <div
                      className='suggestionItem'
                      key={index}
                      onClick={() => {
                        handleChangeB(item.address, i);
                      }}
                    >
                      <Character max={11} title={item?.address} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default RoadLocationRoute;
