import React from "react";
import "./SignUpCompany.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import SignUpCompanyForm from "./../../../../componentsLanding/Auth&Password/SignUpForms/SignUpCompanyForm/SignUpCompanyForm";

const SignUpCompany = () => {
  const { t } = useTranslation();

  return (
    <Wrapper
      documentTitle={t("landing.documentTitles.Sign Up carrier company")}
    >
      <section id='SignUpCompany'>
        <AuthCover url='/assets/images/static/cover24.jpg' />
        <SignUpCompanyForm />
      </section>
    </Wrapper>
  );
};

export default SignUpCompany;
