import React, { useState } from "react";
import "./Products.scss";

import { useTranslation } from "react-i18next";

import FooterTitle from "../FooterTitle/FooterTitle";
import LinkItem from "../LinkItem/LinkItem";

const Products = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  return (
    <div id='Products' className={open ? "open" : ""}>
      <FooterTitle
        title={t("landing.navigation.Products to use")}
        value={open}
        setValue={() => setOpen((state) => !state)}
      />
      <ul className='list'>
        <LinkItem title={t("landing.navigation.Shippers platform")} to='/register/shipper' />
        <LinkItem title={t("landing.navigation.TMS platform")} to='/register/tms' />
        <LinkItem
          title={t("landing.navigation.Daily shipment subscribe")}
          to='/carrier/subscribe'
        />
        <LinkItem title={t("landing.navigation.Cargon App")} to='/register/carrier' />
      </ul>
    </div>
  );
};

export default Products;
