import React from "react";
import "./DescriptionGroup.scss";

import { useTranslation } from "react-i18next";

const DescriptionGroup = ({ bidAdditionalText, order }) => {
  const { t } = useTranslation();

  const locationsA = order
    ? order.cargos[0].locations.filter((i) => i.direction === "A")
    : [];

  const locationsB = order
    ? order.cargos[0].locations.filter((i) => i.direction === "B")
    : [];

  return (
    <div id='SendBidDetailsDescriptionGroup'>
      <div className='SendBidDetailsPreview'>
        <h3>{t("tms.forms.labels.Preview")}</h3>
        <div className='SendBidDetailsPreviewContainer'>
          <h4>{t("tms.desc.Subject we got a Job for You!")}</h4>
          <div className='text'>
            <h4 className='note'>{t("tms.desc.Note")}:</h4>
            {bidAdditionalText.length === 0 ? (
              <p className='placeholder'>
                {t("tms.desc.Additional text place")}
              </p>
            ) : (
              <p className='typography'>{bidAdditionalText}</p>
            )}

            <h5>{t("tms.desc.Hey there")},</h5>
            <h5>
              {t(
                "tms.desc.We've got a delivery and we think you might be the perfect fit."
              )}
            </h5>
            <h5>
              {t("tms.desc.We need to move some stuff from")} [
              {locationsA &&
                locationsA.map(
                  (i, index) =>
                    ` ${locationsA.length > 1 ? `${index + 1}. ` : ""}${
                      i.city
                    } `
                )}
              ] {t("tms.desc.to")} [
              {locationsB &&
                locationsB.map(
                  (i, index) =>
                    ` ${locationsB.length > 1 ? `${index + 1}. ` : ""}${
                      i.city
                    } `
                )}
              ].
            </h5>
          </div>
          <h5>
            {t(
              "tms.desc.You can see full details on a link below and let us know how much it'd cost to make this happen."
            )}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default DescriptionGroup;
