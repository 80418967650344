import React from "react";
import DSelect from "../DSelect";

import { useTranslation } from "react-i18next";
const DTruckModelsSelect = ({
  value,
  setValue,
  error,
  required,
  options,
  disabled,
}) => {
  const { t } = useTranslation();
  const filterOption = (option, inputValue) => {
    const { value } = option;
    return value.name.toLowerCase().includes(inputValue.toLowerCase());
  };
  return (
    <DSelect
      id='DTruckModelsSelect'
      label={t("tms.modals.labels.Model")}
      placeholder={t("tms.modals.placeholder.Choose model")}
      required={required}
      value={value}
      setValue={setValue}
      loading={false}
      search={true}
      error={error}
      filterOption={filterOption}
      options={options}
      disabled={disabled}
    />
  );
};

export default DTruckModelsSelect;
