import React from "react";
import "./TrackingTruckListItem.scss";

import { useTranslation } from "react-i18next";

import StatusBadge from "../../../Components/CardComponents/TruckLocationBadge/TruckLocationBadge";

const TrackingTruckListItem = ({ item, activeItem, setActiveItem }) => {
  const { t } = useTranslation();
  const title =
    item &&
    `${item.model && item.model.brand.name} - 
  ${item.model && item.model.name}`;

  return (
    <li
      id='TrackingTruckListItem'
      onClick={() =>
        setActiveItem(activeItem && activeItem.id === item.id ? null : item)
      }
      className={activeItem && activeItem.id === item.id ? "active" : ""}
    >
      <div className='TrackingTruckListItemLeft'>
        <div
          className='cover'
          style={{
            backgroundImage: `url(${`/assets/images/dashboard/cover10.svg`})`,
          }}
        >
          <StatusBadge color={item.location?.color} />
        </div>
        <div>
          <h3>{title}</h3>
          <p>{item?.registration_number || "-"}</p>
        </div>
      </div>
      {item.has_connected_device ? (
        <div className='TrackingTruckListItemGPS'>{t("tms.desc.GPS")}</div>
      ) : (
        <div className='TrackingTruckListItemApp'>{t("tms.desc.App")}</div>
      )}
    </li>
  );
};

export default TrackingTruckListItem;
