import _request from "./../../_request";

const getOrderCosts = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `/order/${orderId}/extra-financial`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createOrderCost = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `/order/${orderId}/extra-financial`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateOrderCost = async (ISO, TOKEN, orderId, costId, data) => {
  const config = {
    url: `/order/${orderId}/extra-financial/${costId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteOrderCost = async (ISO, TOKEN, orderId, costId) => {
  const config = {
    url: `/order/${orderId}/extra-financial/${costId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getOrderCostTypes = async (ISO, TOKEN) => {
  const config = {
    url: "/order/extra-financial/types",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const orderCostsService = {
  getOrderCosts,
  createOrderCost,
  updateOrderCost,
  deleteOrderCost,
  getOrderCostTypes,
};

export default orderCostsService;
