import _request from "../_request";

const getStatusesList = async (ISO, token) => {
  const config = {
    url: "/shipment-status-updates/statuses",
    token,
    ISO,
  };
  return _request(config);
};
const storeStatusUpdate = async (ISO, token, data) => {
  const config = {
    url: "/shipment-status-updates",
    method: "post",
    token,
    ISO,
  };
  return _request(config, data);
};

const getOrderStatusUpdates = async (ISO, token, orderId) => {
  const config = {
    url: `/shipment-status-updates/${orderId}/history`,
    token,
    ISO,
  };
  return _request(config);
};


const statusUpdatesService = {
  getStatusesList,
  storeStatusUpdate,
  getOrderStatusUpdates,
};

export default statusUpdatesService;
