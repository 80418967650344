import React from "react";
import "./ShipperOverviewHeader.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import MainTitle from "../../../MainTitle/MainTitle";
// import ShipperPersonalManager from "./ShipperPersonalManager/ShipperPersonalManager";

const ShipperOverviewHeader = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  return (
    <div id='ShipperOverviewHeader'>
      <MainTitle
        title={t("shipper.titles.Overview")}
        description={t("shipper.desc.Hi, welcome back !", {
          name: user?.first_name,
        })}
      />
      {/* <ShipperPersonalManager
        item={{
          name: "Tamar",
          lastName: "gvelesiani",
          label: "Personal manager",
          phone: "+995 587 323 221",
          email: "tamar@cargon.com",
        }}
      /> */}
    </div>
  );
};

export default ShipperOverviewHeader;
