import React, { Fragment } from "react";
import "./CostItemShipper.scss";

import { useTranslation } from "react-i18next";
import { useMoney } from "../../../../../../helper/useMoney";

const CostItemShipper = ({ cost }) => {
  const { t } = useTranslation();
  const formattedMoney = useMoney(
    cost?.amount || 0,
    (cost && cost.currency && cost.currency.symbol) || "-"
  );
  return (
    <Fragment>
      {cost && (
        <li id='CostItemShipper'>
          <div className='costName'>{cost?.type && cost?.type?.name}</div>
          <div className='costComment'>
            {cost.comment ? (
              cost.comment
            ) : (
              <p className='noComment'>{t("shipper.modal.No comment")}</p>
            )}
          </div>
          <div className='contentRight'>
            <h2 className={cost?.operation === "EX" ? "ex" : ""}>{formattedMoney}</h2>
          </div>
        </li>
      )}
    </Fragment>
  );
};

export default CostItemShipper;
