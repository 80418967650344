import React from "react";
import "./TmsSolutionCarrierItem.scss";
const TmsSolutionCarrierItem = ({ active, delay, onClick, item }) => {
  return (
    <div
      data-aos='fade-right'
      data-aos-duration='1500'
      data-aos-once='true'
      data-aos-delay={delay ? delay : "0"}
    >
      <div
        id='TmsSolutionCarrierItem'
        className={item.id === active ? "active" : ""}
        onClick={() => onClick(item.id)}
      >
        <div className='TmsSolutionCarrierItemLeft'>{item.number}</div>
        <div className='TmsSolutionCarrierItemRight'>
          <h1>{item.title}</h1>
          <p>{item.desc}</p>
        </div>
      </div>
    </div>
  );
};

export default TmsSolutionCarrierItem;
