import React from "react";
import "./RowField.scss";

const RowField = ({ children, style }) => {
  return (
    <tr style={style ? style : {}} id='tableRowField'>
      {children}
    </tr>
  );
};

export default RowField;
