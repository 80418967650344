import React, { useEffect, useState } from "react";
import "./CarrierApp.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  resetSubscribeWithEmail,
  subscribeWithEmail,
} from "../../../../store/subscribe/subscribeSlice";

import CarrierAppCover from "./CarrierAppCover/CarrierAppCover";
// import CarrierAppSocial from "./CarrierAppSocial/CarrierAppSocial";
import CarrierAppTitle from "./CarrierAppTitle/CarrierAppTitle";
import Input from "../../../Form/Inputs/Input";
import Button from "../../../Form/Button/Button";
import ErrorText from "../../../Form/ErrorText/ErrorText";

const CarrierApp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { subscribeWithEmailResult, subscribeWithEmailError, subscribeWithEmailLoading } =
    useSelector((state) => state.subscribe);

  const [email, setEmail] = useState("");

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(subscribeWithEmail({ email }));
  };

  useEffect(() => {
    if (subscribeWithEmailResult) {
      dispatch(resetSubscribeWithEmail());
      setEmail("");
    }
  }, [dispatch, subscribeWithEmailResult]);

  return (
    <div className='wrapper'>
      <div id='CarrierApp'>
        <div className='CarrierAppLeft'>
          <CarrierAppTitle />
          <form className='subscribeContainer'>
            <div style={{ width: "100%" }}>
              <Input
                value={email}
                setValue={setEmail}
                placeholder={t("landing.form.placeholder.Enter your email")}
                type='email'
                error={subscribeWithEmailError?.errors?.email?.[0]}
              />
              <ErrorText error={subscribeWithEmailError?.errors?.email?.[0]} />
            </div>
            <Button
              title={t("landing.form.button.Keep me informed")}
              loading={subscribeWithEmailLoading}
              onClick={(e) => handleClick(e)}
              type='submit'
            />
          </form>
          {/* <CarrierAppSocial /> */}
        </div>
        <CarrierAppCover />
      </div>
    </div>
  );
};

export default CarrierApp;
