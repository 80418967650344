import React, { Fragment, useState } from "react";
import "./ActiveTableItem.scss";

import { useTranslation } from "react-i18next";
import { FiCheck } from "react-icons/fi";

import AlreadyPaidMoneyField from "./../../../Form/TableFields/AlreadyPaidMoneyField/AlreadyPaidMoneyField";
import RowField from "../../../Form/TableFields/RowField/RowField";
import NameField from "../../../Form/TableFields/NameField/NameField";
import TextField from "../../../Form/TableFields/TextField/TextField";
import RouteField from "../../../Form/TableFields/RouteField/RouteField";
import PaymentStatusField from "../../../Form/TableFields/PaymentStatusField/PaymentStatusField";
import TotalPaymentField from "./../../../Form/TableFields/TotalPaymentField/TotalPaymentField";
import InvoiceModal from "./../../../Orders/InvoiceModal/InvoiceModal";
import DButton from "../../../Form/DButtons/DButton/DButton";
import CenterField from "./../../../Form/TableFields/CenterField/CenterField";
import InvoicesMenu from "../../InvoicesMenu/InvoicesMenu";
import MarkAsPaidModal from "../../../Orders/FinancialDetails/Components/MarkAsPaidModal/MarkAsPaidModal";
import OrderStatusField from "../../../Form/TableFields/OrderStatusField/OrderStatusField";
import AddPrePaymentModal from "./../../../Orders/FinancialDetails/Components/PrePaymentComponents/AddPrePaymentModal/AddPrePaymentModal";

const ActiveTableItem = ({ item }) => {
  const { t } = useTranslation();

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showMarkAsPaidModal, setShowMarkAsPaidModal] = useState(false);

  return (
    <Fragment>
      <RowField
        style={{
          borderLeft: `4px solid ${item.color ? item.color : "transparent"}`,
        }}
      >
        <NameField label={item.tracking} to={`/dashboard/tms/orders/${item.id}`} />
        <OrderStatusField status={item.statusTms ?? null} />
        <TextField max={30} label={item.client?.name ?? "Cargon"} />
        <RouteField order={item} />
        <TotalPaymentField item={item} />
        <AlreadyPaidMoneyField item={item} onClick={() => setShowPaymentModal(true)} />
        <PaymentStatusField item={item} />
        <CenterField>
          <div id='ActiveTableItemActionsRow'>
            <InvoicesMenu item={item} />
            {!item.paid && (
              <DButton
                title={t("tms.tables.buttons.Mark As Paid")}
                rightIcon={<FiCheck />}
                loading={null}
                onClick={() => {
                  setShowMarkAsPaidModal(true);
                }}
              />
            )}
          </div>
        </CenterField>
      </RowField>
      {showPaymentModal && (
        <AddPrePaymentModal order={item} isOpen={showPaymentModal} isClose={setShowPaymentModal} />
      )}
      {showInvoiceModal && (
        <InvoiceModal isOpen={showInvoiceModal} isClose={setShowInvoiceModal} order={item} />
      )}
      {showMarkAsPaidModal && (
        <MarkAsPaidModal
          isOpen={showMarkAsPaidModal}
          isClose={setShowMarkAsPaidModal}
          item={item}
        />
      )}
    </Fragment>
  );
};

export default ActiveTableItem;
