import React, { Fragment, useState, useEffect } from "react";
import "./AddNewCostModal.scss";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  createOrderCost,
  resetCreateOrderCost,
} from "../../../../../../store/orders/costs/orderCostsSlice";

import DModal from "../../../../../Modal/DModal/DModal";
import DCurrencyInput from "../../../../../Form/DCurrencyInput/DCurrencyInput";
import DSelect from "../../../../../Form/DSelect/DSelect";
import DTextarea from "../../../../../Form/DTextarea/DTextarea";

const AddNewCostModal = ({ isOpen, isClose, orderId, operation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    createOrderCostLoading,
    createOrderCostResult,
    createOrderCostError,
    getOrderCostTypesLoading,
    getOrderCostTypesSelect,
  } = useSelector((state) => state.orderCosts);

  const { user } = useSelector((state) => state.auth);

  const [amountName, setAmountName] = useState(null);
  const [amountValue, setAmountValue] = useState("");
  const [currencyValue, setCurrencyValue] = useState({
    label: user.currency.code,
    value: user.currency,
  });
  const [commentValue, setCommentValue] = useState("");

  const submithandler = () => {
    const data = {
      orderId: orderId,

      operation: operation ? operation : "IN", // IN, EX
      type_id: amountName?.value?.id || null,
      amount: amountValue || null,
      currency_id: currencyValue?.value?.id || null,
      comment: commentValue || null,
    };
    dispatch(createOrderCost(data));
  };

  useEffect(() => {
    if (createOrderCostResult) {
      dispatch(resetCreateOrderCost());
    }
  }, [dispatch, createOrderCostResult]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateOrderCost());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose()}
        onClick={() => submithandler()}
        title={
          operation === "IN"
            ? t("tms.modals.titles.Create charge")
            : t("tms.modals.titles.Create cost")
        }
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiPlus />}
        status='success'
        loading={createOrderCostLoading}
      >
        <div id='orderAddNewCost'>
          <form className='formGroup'>
            <DSelect
              id='OrderAdditionalCostAmountName'
              value={amountName}
              setValue={setAmountName}
              placeholder={t("tms.modals.placeholder.Select cost name")}
              label={t("tms.modals.labels.Cost name")}
              options={getOrderCostTypesSelect || []}
              required
              loading={getOrderCostTypesLoading}
              error={createOrderCostError?.errors?.type_id?.[0] ?? null}
            />
            <DCurrencyInput
              id='OrderAdditionalCostAmount'
              value={amountValue}
              setValue={setAmountValue}
              currencyValue={currencyValue}
              setCurrencyValue={setCurrencyValue}
              required
              placeholder={t("tms.modals.placeholder.Enter cost")}
              label={t("tms.modals.labels.Cost")}
              error={
                (createOrderCostError?.errors?.amount?.[0] ?? null) ||
                (createOrderCostError?.errors?.currency_id?.[0] ?? null)
              }
            />
            <DTextarea
              id='OrderAdditionalCostComment'
              value={commentValue}
              setValue={setCommentValue}
              placeholder={t("tms.modals.placeholder.Your comment...")}
              label={t("tms.modals.labels.Comment")}
              error={createOrderCostError?.errors?.comment?.[0] ?? null}
            />
          </form>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddNewCostModal;
