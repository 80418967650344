import React from "react";
import "./ForWorkflow.scss";

import {
  TbDeviceDesktopAnalytics,
  TbDeviceAnalytics,
  TbSettings,
} from "react-icons/tb";
import { FiChevronRight } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import HomeTitle from "../../Components/HomeTitle/HomeTitle";
import WorkflowItem from "./../Components/WorkflowItem/WorkflowItem";
import LinkTransparent from "../../Components/Links/LinkTransparent/LinkTransparent";

const ForWorkflow = () => {
  const { t } = useTranslation();

  return (
    <section id='ForWorkflow' className='ForWorkflow'>
      <div className='wrapper'>
        <HomeTitle
          description={t(
            "landing.titles.landingMainTitlesDesc.Experience the future of freight with Cargon – where intelligent logistics drives extraordinary business outcomes"
          )}
        >
          {parse(
            t(
              "landing.titles.landingMainTitles.The leading digital freight <span>solution</span>"
            )
          )}
        </HomeTitle>

        <div className='container'>
          <div className='items'>
            <WorkflowItem
              title={t(
                "landing.titles.landingWorkFlowTitle.Unified digital workflow"
              )}
              content={t(
                "landing.bodyText.landingWorkFlowBody.Say goodbye to the chaos of scattered emails and paper trails. With Cargon, all your freight needs are managed in a single, intuitive platform. Streamlined, efficient, and hassle-free."
              )}
              icon={<TbSettings />}
              delay='0'
            />

            <WorkflowItem
              title={t(
                "landing.titles.landingWorkFlowTitle.Real-time visibility with analytics"
              )}
              content={t(
                "landing.bodyText.landingWorkFlowBody.Experience the power of predictive analytics. Get transparent, real-time tracking of your shipments. Anticipate and navigate disruptions, ensuring smooth deliveries every time."
              )}
              icon={<TbDeviceDesktopAnalytics />}
              delay='100'
            />

            <WorkflowItem
              title={t(
                "landing.titles.landingWorkFlowTitle.Data-driven decisions"
              )}
              content={t(
                "landing.bodyText.landingWorkFlowBody.Transform your decision-making with tailored insights. Whether you're a shipper or a carrier, our analytics put the right information at your fingertips. Strategize, optimize, and succeed with Cargon."
              )}
              icon={<TbDeviceAnalytics />}
              delay='200'
            />
          </div>

          <div className='img'>
            <img src='/assets/images/static/devices5.png' alt='Computer' />
          </div>
        </div>

        <LinkTransparent
          title={t("landing.form.button.Get started now")}
          to='/register'
          icon={<FiChevronRight />}
        />
      </div>
    </section>
  );
};

export default ForWorkflow;
