import React, { Fragment } from "react";
import "../styles/landing/landing.scss";

import Landing from "../routes/Landing";
import Footer from "../componentsLanding/Footer/Footer";
import ScrollToTop from "../componentsAdditional/ScrollToTop";
import Header from "../componentsLanding/Header/Header";

// AOS init
import AOS from "aos";
import "aos/dist/aos.css";
import MetaPixel from "../componentsAdditional/Meta/MetaPixel";

// Hotjar
import Hotjar from "@hotjar/browser";

Hotjar.init(3817020, 6);

AOS.init();

const AppLanding = () => {
  return (
    <Fragment>
      <MetaPixel />
      <ScrollToTop />
      <div id='L-Layout'>
        <Header />
        <main>
          <Landing />
        </main>
        <Footer />
      </div>
    </Fragment>
  );
};

export default AppLanding;
