import React from "react";
import "./LinkTransparent.scss";

import { Link } from "react-router-dom";

const LinkTransparent = ({ title, to, icon }) => {
  return (
    <div id='LinkTransparent'>
      <Link to={to}>
        {title}
        {icon && icon}
      </Link>
    </div>
  );
};

export default LinkTransparent;
