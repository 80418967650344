import React from "react";
import "./FormTitle.scss";

const FormTitle = ({ title, children }) => {
  return (
    <div id='FormTitle'>
      <h1>{title}</h1>
      {children && <p>{children}</p>}
    </div>
  );
};

export default FormTitle;
