import React from "react";
import "./DShowButton.scss";

import { BiSolidShow } from "react-icons/bi";

const DShowButton = ({ onClick }) => {
  return (
    <div id='DShowButton' onClick={onClick}>
      <BiSolidShow />
    </div>
  );
};

export default DShowButton;
