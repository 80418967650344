import React, { useState } from "react";
import "./LoadBoardFilters.scss";

import moment from "moment";
import { useTranslation } from "react-i18next";

import { MdFilterAlt } from "react-icons/md";

import LoadBoardAddressSearchForm from "../LoadBoardAddressSearchForm/LoadBoardAddressSearchForm";
import FilterTrailerSelect from "../../FilterForms/FilterTrailerSelect/FilterTrailerSelect";
import FilterMaxWeightSelect from "../../FilterForms/FilterMaxWeightSelect/FilterMaxWeightSelect";
import FiltersContainer from "../../FilterForms/FiltersContainer/FiltersContainer";
import FilterDateRangeForm from "../../FilterForms/FilterDateRangeForm/FilterDateRangeForm";

const LoadBoardFilters = ({
  setPickUp,
  setDropOff,
  trailerType,
  setTrailerType,
  maxWeight,
  setMaxWeight,
  date,
  setDate,
  clearFilters,
  pickUpInput,
  setPickUpInput,
  dropOffInput,
  setDropOffInput,
}) => {
  const { t } = useTranslation();

  const [showFilters, setShowFilters] = useState(false);

  return (
    <div id='LoadBoardFilters'>
      <div className='topContainer'>
        <LoadBoardAddressSearchForm
          setPickUp={setPickUp}
          setDropOff={setDropOff}
          pickUpInput={pickUpInput}
          setPickUpInput={setPickUpInput}
          dropOffInput={dropOffInput}
          setDropOffInput={setDropOffInput}
        />
        <div className='filtersButtonContainer'>
          <div className='showFiltersButton' onClick={() => setShowFilters(!showFilters)}>
            <div className='icon'>
              <MdFilterAlt />
            </div>
            <div className='text'>
              {showFilters
                ? t("tms.filters.buttons.Hide filters")
                : t("tms.filters.buttons.Show more filters")}
            </div>
          </div>
        </div>
      </div>

      <FiltersContainer onClear={clearFilters} hidden={!showFilters}>
        <FilterDateRangeForm
          label={t("tms.filters.placeholders.Date range")}
          value={
            Object.keys(date).length > 0
              ? `${date.startDate ? moment(date.startDate).format("DD/MM/YYYY") : ""} - ${
                  date.endDate ? moment(date.endDate).format("DD/MM/YYYY") : ""
                }`
              : ""
          }
          setValue={setDate}
        />
        <FilterTrailerSelect value={trailerType} setValue={setTrailerType} />
        <FilterMaxWeightSelect setMaxWeight={setMaxWeight} maxWeight={maxWeight} />
      </FiltersContainer>
    </div>
  );
};

export default LoadBoardFilters;
