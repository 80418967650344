import React from "react";
import "./PartnersGroup.scss";

import { useTranslation } from "react-i18next";

import { FiXCircle } from "react-icons/fi";

const PartnersGroup = ({ partnersBids, setPartnersBids }) => {
  const { t } = useTranslation();

  const RenderItem = ({ item }) => {
    const removeHandler = () => {
      setPartnersBids((state) => state.filter((i) => i.id !== item.id));
    };

    return (
      <div className='item'>
        <p>{item?.name}</p>
        <div className='icon' onClick={() => removeHandler()}>
          <FiXCircle />
        </div>
      </div>
    );
  };

  return (
    <div id='PartnersGroupModal'>
      <div className='PartnersGroupModalWrapper'>
        <p className='to'>{t("tms.forms.placeholder.To")}:</p>

        {partnersBids.length === 0 ? (
          <p className='Allpartners'>{t("tms.forms.placeholder.Send bid all partner")}</p>
        ) : (
          partnersBids.map((item) => <RenderItem key={item.id} item={item} />)
        )}
      </div>
    </div>
  );
};

export default PartnersGroup;
