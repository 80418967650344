import React, { Fragment } from "react";

import DocumentTitle from "./DocumentTitle";

const Wrapper = ({ children, documentTitle }) => {
  return (
    <Fragment>
      <DocumentTitle title={documentTitle} />
      {children}
    </Fragment>
  );
};

export default Wrapper;
