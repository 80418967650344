import React from "react";
import "./BidsMessage.scss";

const BidsMessage = ({ icon, text, color, backgroundColor }) => {
  return (
    <div id='BidsMessage' style={{ backgroundColor: backgroundColor }}>
      <div className='info' style={{ color }}>
        <div className='icon' style={{ color }}>
          {icon}
        </div>
        {text}
      </div>
    </div>
  );
};

export default BidsMessage;
