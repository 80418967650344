import React, { Fragment } from "react";

import Carrier from "../routes/Carrier";

const AppCarrier = () => {
  return (
    <Fragment>
      <main>{<Carrier />}</main>
    </Fragment>
  );
};

export default AppCarrier;
