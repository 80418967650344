import React, { Fragment, useState } from "react";
import "./TrailerManagementItem.scss";

import { useTranslation } from "react-i18next";

import { FiMinus } from "react-icons/fi";
import { useSelector } from "react-redux";
import { MdControlPointDuplicate } from "react-icons/md";

import DSelect from "../../../../../../Form/DSelect/DSelect";
import DInputs from "../../../../../../Form/DInputs/DInputs";
import DTextButton from "../../../../../../Form/DButtons/DTextButton/DTextButton";
import DModal from "../../../../../../Modal/DModal/DModal";
import DCurrencyInput from "./../../../../../../Form/DCurrencyInput/DCurrencyInput";

const TrailerManagementItem = ({ edit, index, item, group, setGroup }) => {
  const { t } = useTranslation();

  const { getTrailerTypesRoadLoading, getTrailerTypesRoadSelectResult } = useSelector(
    (state) => state.trailerType
  );

  const [showDublicateModal, setShowDublicateModal] = useState(false);
  const [dublicateCount, setDublicateCount] = useState(1);

  const dublicateHandler = () => {
    const newItem = {
      trailerType: item.trailerType,
      trailerTypeError: null,
      trailerSize: item.trailerSize,
      trailerSizeError: null,
      temperatureRange: item.temperatureRange,
      temperatureRangeError: null,
      weight: item.weight,
      weightError: null,
      price: item.price,
      priceError: null,
      currency: item.currency,
      currencyError: null,
    };

    const copyGroup = [...group];
    for (let i = 0; i < dublicateCount; i++) {
      copyGroup.splice(index + i + 1, 0, { ...newItem });
    }
    setGroup(copyGroup);
    setShowDublicateModal(false);
    setDublicateCount(1);
  };

  const changeDublicateHandler = (val) => {
    const inputValue = val;
    if (
      inputValue === "" ||
      (parseInt(inputValue) + parseInt(group.length) <= 40 && parseInt(inputValue) >= 0)
    ) {
      setDublicateCount(inputValue);
    }
  };

  const deleteHandler = () => {
    const newGroup = [...group];
    newGroup.splice(index, 1);
    setGroup(newGroup);
  };

  const setTrailerTypeHandler = (value) => {
    const newGroup = [...group];
    newGroup[index].trailerType = value;
    newGroup[index].trailerSize = null;
    newGroup[index].temperatureRange = null;
    setGroup(newGroup);
  };

  const setTrailerSizeHandler = (value) => {
    const newGroup = [...group];
    newGroup[index].trailerSize = value;
    newGroup[index].temperatureRange = null;
    setGroup(newGroup);
  };

  const setTemperatureRangeHandler = (value) => {
    const newGroup = [...group];
    newGroup[index].temperatureRange = value;
    setGroup(newGroup);
  };

  const setWeightHandler = (value) => {
    const newGroup = [...group];
    newGroup[index].weight = value;
    setGroup(newGroup);
  };

  const setPriceHandler = (value) => {
    const newGroup = [...group];
    newGroup[index].price = value;
    setGroup(newGroup);
  };

  const seCurrencyHandler = (value) => {
    const newGroup = [...group];
    newGroup[index].currency = value;
    setGroup(newGroup);
  };

  const trailerSizeOptions = item?.trailerType?.value?.sizes?.map((size) => {
    return {
      label: size.label,
      value: size,
    };
  });

  return (
    <Fragment>
      <div className={edit ? "TrailerManagementItem edit" : "TrailerManagementItem"}>
        {!edit && (
          <div className='id'>
            <h1>{index + 1}</h1>
          </div>
        )}
        <div className='trailerType'>
          <DSelect
            required
            value={item.trailerType}
            setValue={(value) => setTrailerTypeHandler(value)}
            id={`AddOrdertrailerSelect${index}`}
            label={t("tms.forms.labels.Trailer")}
            placeholder={t("tms.forms.placeholder.Choose trailer type")}
            options={getTrailerTypesRoadSelectResult}
            loading={getTrailerTypesRoadLoading}
            error={item.trailerTypeError}
          />
        </div>
        <div className='trailerVolume'>
          <DSelect
            required
            value={item.trailerSize}
            setValue={(value) => setTrailerSizeHandler(value)}
            id={`AddOrderTrailerVolumeSelect${index}`}
            loading={false}
            label={t("tms.forms.labels.Trailer volume")}
            placeholder={t("tms.forms.placeholder.Choose trailer volume")}
            options={trailerSizeOptions || []}
            disabled={!item.trailerType}
            error={item.trailerSizeError}
          />
        </div>
        {item && item.trailerType && item.trailerType.value.temperature && (
          <div className='temperature'>
            <DInputs
              required
              value={item.temperatureRange}
              setValue={(value) => setTemperatureRangeHandler(value)}
              id={`AddOrderTrailertemperatureSelect${index}`}
              label={t("tms.forms.labels.Temperature")}
              placeholder={t("tms.forms.placeholder.Temperature")}
              error={item.temperatureRangeError}
            />
          </div>
        )}
        <div className='weight'>
          <DInputs
            type='number'
            required
            value={item.weight}
            setValue={(value) => setWeightHandler(value)}
            id={`AddOrderTrailerSelect${index}`}
            label={t("tms.forms.labels.Weight(KG)")}
            placeholder={t("tms.forms.placeholder.Enter weight")}
            error={item.weightError}
          />
        </div>
        <div className='priceForCustomerInput'>
          <DCurrencyInput
            id='AddOrderTotalpricefororder'
            label={t("tms.forms.labels.Price for customer")}
            placeholder={t("tms.forms.placeholder.Enter price")}
            value={item.price}
            setValue={(value) => setPriceHandler(value)}
            currencyValue={item.currency}
            setCurrencyValue={(value) => seCurrencyHandler(value)}
            error={item.priceError || item.currencyError}
            disabled={edit ? true : false}
          />
        </div>
        {!edit && (
          <div className={group.length > 1 ? "action" : "action single"}>
            <DTextButton
              title={t("tms.forms.buttons.Duplicate")}
              onClick={() => {
                setShowDublicateModal(true);
              }}
              leftIcon={<MdControlPointDuplicate />}
            />
            {group.length > 1 && (
              <DTextButton
                danger
                title={t("tms.forms.buttons.Remove")}
                onClick={() => {
                  deleteHandler();
                }}
                leftIcon={<FiMinus />}
              />
            )}
          </div>
        )}
      </div>
      {showDublicateModal && (
        <DModal
          title={t("tms.modals.titles.Enter quantity")}
          onClick={() => dublicateHandler()}
          submitText={t("tms.modals.buttons.Duplicate")}
          isOpen={showDublicateModal}
          isClose={() => setShowDublicateModal(false)}
          icon={<MdControlPointDuplicate />}
          disabled={40 - parseInt(group.length) === 0 ? true : false}
        >
          <div id='dublicateCountquantityContainer'>
            <div className='dublicateCountquantityContainerForm'>
              <DInputs
                label={`${t("tms.modals.labels.Quantity")} (${t("tms.modals.labels.Max")} ${
                  40 - parseInt(group.length)
                })`}
                id='dublicateCountquantity'
                value={dublicateCount}
                setValue={(state) => changeDublicateHandler(state)}
                placeholder={t("tms.modals.placeholder.Enter quantity")}
                type='number'
              />
            </div>
          </div>
        </DModal>
      )}
    </Fragment>
  );
};

export default TrailerManagementItem;
