import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderFinanceService from "./orderFinanceService";

const initialState = {
  updateOrderRevenueLoading: false,
  updateOrderRevenueResult: null,
  updateOrderRevenueError: null,

  updateOrderExpenseLoading: false,
  updateOrderExpenseResult: null,
  updateOrderExpenseError: null,

  getPaymentsLoading: true,
  getPaymentsResult: null,
  getPaymentsError: null,

  createPaymentLoading: false,
  createPaymentResult: null,
  createPaymentError: null,

  updatePaymentLoading: false,
  updatePaymentResult: null,
  updatePaymentError: null,

  deletePaymentLoading: false,
  deletePaymentResult: null,
  deletePaymentError: null,

  updateClientPaidLoading: false,
  updateClientPaidResult: null,
  updateClientPaidError: null,
};

export const updateOrderRevenue = createAsyncThunk(
  "orderFinance/updateOrderRevenue",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderFinanceService.updateOrderRevenue(ISO, TOKEN, data.id, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateOrderExpense = createAsyncThunk(
  "orderFinance/updateOrderExpense",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderFinanceService.updateOrderExpense(ISO, TOKEN, data.id, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPayments = createAsyncThunk(
  "orderFinance/getPayments",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderFinanceService.getPayments(ISO, TOKEN, orderId);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createPayment = createAsyncThunk(
  "orderFinance/createPayment",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderFinanceService.createPayment(ISO, TOKEN, data.orderId, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePayment = createAsyncThunk(
  "orderFinance/updatePayment",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderFinanceService.updatePayment(
        ISO,
        TOKEN,
        data.orderId,
        data.paymentId,
        data
      );
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePayment = createAsyncThunk(
  "orderFinance/deletePayment",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderFinanceService.deletePayment(ISO, TOKEN, data.orderId, data.paymentId);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateClientPaid = createAsyncThunk(
  "orderFinance/updateClientPaid",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderFinanceService.updateClientPaid(ISO, TOKEN, data.orderId, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orderFinanceSlice = createSlice({
  name: "orderFinance",
  initialState,
  reducers: {
    resetUpdateOrderRevenue: (state) => {
      state.updateOrderRevenueLoading = false;
      state.updateOrderRevenueResult = null;
      state.updateOrderRevenueError = null;
    },
    resetUpdateOrderExpense: (state) => {
      state.updateOrderExpenseLoading = false;
      state.updateOrderExpenseResult = null;
      state.updateOrderExpenseError = null;
    },
    resetGetPayments: (state) => {
      state.getPaymentsLoading = true;
      state.getPaymentsResult = null;
      state.getPaymentsError = null;
    },
    resetCreatePayment: (state) => {
      state.createPaymentLoading = false;
      state.createPaymentResult = null;
      state.createPaymentError = null;
    },
    resetUpdatePayment: (state) => {
      state.updatePaymentLoading = false;
      state.updatePaymentResult = null;
      state.updatePaymentError = null;
    },
    resetDeletePayment: (state) => {
      state.deletePaymentLoading = false;
      state.deletePaymentResult = null;
      state.deletePaymentError = null;
    },
    resetUpdateClientPaid: (state) => {
      state.updateClientPaidLoading = false;
      state.updateClientPaidResult = null;
      state.updateClientPaidError = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(updateOrderRevenue.pending, (state) => {
        state.updateOrderRevenueLoading = true;
        state.updateOrderRevenueResult = null;
        state.updateOrderRevenueError = null;
      })
      .addCase(updateOrderRevenue.fulfilled, (state, action) => {
        state.updateOrderRevenueLoading = false;
        state.updateOrderRevenueResult = action.payload;
        state.updateOrderRevenueError = null;
      })
      .addCase(updateOrderRevenue.rejected, (state, action) => {
        state.updateOrderRevenueLoading = false;
        state.updateOrderRevenueResult = null;
        state.updateOrderRevenueError = action.payload;
      })

      .addCase(updateOrderExpense.pending, (state) => {
        state.updateOrderExpenseLoading = true;
        state.updateOrderExpenseResult = null;
        state.updateOrderExpenseError = null;
      })
      .addCase(updateOrderExpense.fulfilled, (state, action) => {
        state.updateOrderExpenseLoading = false;
        state.updateOrderExpenseResult = action.payload;
        state.updateOrderExpenseError = null;
      })
      .addCase(updateOrderExpense.rejected, (state, action) => {
        state.updateOrderExpenseLoading = false;
        state.updateOrderExpenseResult = null;
        state.updateOrderExpenseError = action.payload;
      })

      .addCase(getPayments.pending, (state) => {
        state.getPaymentsLoading = true;
        state.getPaymentsResult = null;
        state.getPaymentsError = null;
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.getPaymentsLoading = false;
        state.getPaymentsResult = action.payload;
        state.getPaymentsError = null;
      })
      .addCase(getPayments.rejected, (state, action) => {
        state.getPaymentsLoading = false;
        state.getPaymentsResult = null;
        state.getPaymentsError = action.payload;
      })

      .addCase(createPayment.pending, (state) => {
        state.createPaymentLoading = true;
        state.createPaymentResult = null;
        state.createPaymentError = null;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.createPaymentLoading = false;
        state.createPaymentResult = action.payload;
        state.createPaymentError = null;
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.createPaymentLoading = false;
        state.createPaymentResult = null;
        state.createPaymentError = action.payload;
      })

      .addCase(updatePayment.pending, (state) => {
        state.updatePaymentLoading = true;
        state.updatePaymentResult = null;
        state.updatePaymentError = null;
      })
      .addCase(updatePayment.fulfilled, (state, action) => {
        state.updatePaymentLoading = false;
        state.updatePaymentResult = action.payload;
        state.updatePaymentError = null;
      })
      .addCase(updatePayment.rejected, (state, action) => {
        state.updatePaymentLoading = false;
        state.updatePaymentResult = null;
        state.updatePaymentError = action.payload;
      })

      .addCase(deletePayment.pending, (state) => {
        state.deletePaymentLoading = true;
        state.deletePaymentResult = null;
        state.deletePaymentError = null;
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        state.deletePaymentLoading = false;
        state.deletePaymentResult = action.payload;
        state.deletePaymentError = null;
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.deletePaymentLoading = false;
        state.deletePaymentResult = null;
        state.deletePaymentError = action.payload;
      })

      .addCase(updateClientPaid.pending, (state) => {
        state.updateClientPaidLoading = true;
        state.updateClientPaidResult = null;
        state.updateClientPaidError = null;
      })
      .addCase(updateClientPaid.fulfilled, (state, action) => {
        state.updateClientPaidLoading = false;
        state.updateClientPaidResult = action.payload;
        state.updateClientPaidError = null;
      })
      .addCase(updateClientPaid.rejected, (state, action) => {
        state.updateClientPaidLoading = false;
        state.updateClientPaidResult = null;
        state.updateClientPaidError = action.payload;
      });
  },
});

export const {
  resetUpdateOrderRevenue,
  resetUpdateOrderExpense,
  resetGetPayments,
  resetCreatePayment,
  resetUpdatePayment,
  resetDeletePayment,
  resetUpdateClientPaid,
} = orderFinanceSlice.actions;
export default orderFinanceSlice.reducer;
