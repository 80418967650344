import React, { Fragment, useState } from "react";
import "./QuotationField.scss";

import { useTranslation } from "react-i18next";
import { PiArrowElbowDownLeftBold } from "react-icons/pi";
import { FaCheckCircle } from "react-icons/fa";

import { useMoney } from "../../../../helper/useMoney";

import QuotationModal from "../../../Orders/ShipperOffers/QuotationModal/QuotationModal";

const QuotationField = ({ order }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const orderRevenue = useMoney(order?.revenue || 0, order?.revenue_currency?.symbol || "$");

  return (
    <Fragment>
      <td id='QuotationField'>
        <div className='QuotationFieldContainer'>
          {order?.revenue > 0 ? (
            <Fragment>
              {order?.sale_confirmation_date ? (
                <div className='containerAcceptOffer'>
                  <div className='text'>
                    {orderRevenue}
                    <FaCheckCircle />
                  </div>
                </div>
              ) : (
                <div className='container' onClick={() => setOpenModal(true)}>
                  <div className='text'>{orderRevenue}</div>
                  <div className='icon'>
                    <PiArrowElbowDownLeftBold />
                  </div>
                </div>
              )}
            </Fragment>
          ) : (
            <div className='waitingOffer'>{t("shipper.table.desc.Waiting for offer")}</div>
          )}
        </div>
      </td>
      {openModal && (
        <QuotationModal isOpen={openModal} isClose={() => setOpenModal(false)} order={order} />
      )}
    </Fragment>
  );
};

export default QuotationField;
