import React from "react";
import "./NavItem.scss";

import { NavLink } from "react-router-dom";

const NavItem = ({ title, to }) => {
  return (
    <li id='NavItem'>
      <NavLink to={to}>
        <div>
          <p>{title}</p>
          <div className='line'></div>
        </div>
      </NavLink>
    </li>
  );
};

export default NavItem;
