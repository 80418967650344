import React from "react";
import "./ProfitRatioChart.scss";

import { useTranslation } from "react-i18next";

import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y + 20})`}>
      <text x={0} y={0} dy={0} textAnchor='middle' fill='#888888'>
        {payload.value}
      </text>
    </g>
  );
};

const RenderLegend = (props) => {
  const { t } = useTranslation();

  const { payload } = props;

  return (
    <ul
      style={{
        paddingTop: "22px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "12px",
            height: "12px",
            background: payload[0].color,
            border: "1px solid #54CD9D",
          }}
        ></div>
        <li style={{ listStyle: "none" }}>{t("tms.desc.Profit Ratio")}</li>
      </div>
    </ul>
  );
};

const ProfitRatioChart = ({ data }) => {
  return (
    <div id='ProfitRatioChart'>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart data={data} barSize={30}>
          <CartesianGrid strokeDasharray='1 0' vertical={false} />
          <Legend content={RenderLegend} />
          <XAxis
            dataKey='name'
            axisLine={{ display: "none" }}
            tickLine={false}
            tick={<CustomizedAxisTick />}
          />
          <YAxis
            domain={["auto", "auto"]}
            axisLine={{ display: "none" }}
            tickLine={false}
            tickFormatter={(value) => {
              return `${value}%`;
            }}
            tick={{ fill: "#888888" }}
          />
          <Bar stackId={"a"} dataKey={"profitRatio"} fill='#00B56C'>
            <LabelList
              valueAccessor={(data) => parseFloat(data?.profitRatio).toFixed(0)}
              position='insideTop'
              fill='#FFFFFF'
              fontWeight={"bold"}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProfitRatioChart;
