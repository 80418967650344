import React, { Fragment } from "react";
import "./OrderStatusShipperField.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import LoadingStatus from "./LoadingStatus/LoadingStatus";

const OrderStatusShipperField = ({ status }) => {
  const { t } = useTranslation();

  const { getClientStatusesLoading } = useSelector((state) => state.orderStatuses);

  return (
    <Fragment>
      {getClientStatusesLoading ? (
        <LoadingStatus />
      ) : (
        <td id='OrderStatusShipperField'>
          <div className='OrderStatusFieldTablecontent'>
            <div
              className='OrderStatusFieldTablecontentwrap'
              style={{
                backgroundColor: `${status?.color2 ? status.color2 : "#b0e8d1"}`,
              }}
            >
              <div
                className='dot'
                style={{
                  backgroundColor: `${status?.color1 ? status.color1 : "#004c2d"}`,
                }}
              ></div>
              <p
                style={{
                  color: `${status?.color1 ? status.color1 : "#004c2d"}`,
                }}
              >
                {status?.name ? status.name : t("tms.tables.noInfo.no information")}
              </p>
            </div>
          </div>
        </td>
      )}
    </Fragment>
  );
};

export default OrderStatusShipperField;
