import React from "react";
import "./ContactOffice.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FiMapPin, FiPhone, FiMail } from "react-icons/fi";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { useSelector } from "react-redux";

const ContactOffice = () => {
  const { t } = useTranslation();
  const { currentOffice } = useSelector((state) => state.contact);

  return (
    <div className='ContactOffice'>
      <div className='desc'>
        <h1>{t("landing.titles.landingMainTitles.Get in touch with us")}</h1>
        <p>
          {t(
            "landing.titles.landingMainTitlesDesc.Fill out the form to talk to our team about how we can help."
          )}
        </p>

        <h3>{t("landing.titles.landingMainTitles.We are here to help")}</h3>

        <div className='row'>
          <div className='icon'>
            <BsArrowUpRightCircle />
          </div>
          <p>
            {t(
              "landing.titles.landingMainTitlesDesc.shippers to find a high-performance carrier to deliver a product to their customers"
            )}
          </p>
        </div>
        <div className='row'>
          <div className='icon'>
            <BsArrowUpRightCircle />
          </div>
          <p>
            {t(
              "landing.titles.landingMainTitlesDesc.carriers to expand their connections and make good results for themselves"
            )}
          </p>
        </div>

        <div className='line'></div>
      </div>

      {currentOffice && (
        <div className='card'>
          <div className='container'>
            <div className='title'>{currentOffice?.value?.city}</div>
            <div className='content'>
              <div className='item'>
                <div className='icon'>
                  <FiPhone />
                </div>
                <div>
                  <p>{t("landing.form.label.Have any question?")}</p>
                  <ul className='list'>
                    {currentOffice?.value?.phone?.map((item, index) => (
                      <li key={index}>
                        <Link to={`tel:${item}`}>{item}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='item'>
                <div className='icon'>
                  <FiMail />
                </div>
                <div>
                  <p>{t("landing.form.label.Write email")}</p>
                  <ul className='list'>
                    <li>
                      <Link to={`mailto:${currentOffice?.value?.email}`}>
                        {currentOffice?.value?.email}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='item'>
                <div className='icon'>
                  <FiMapPin />
                </div>
                <div>
                  <p>{t("landing.form.label.Visit anytime")}</p>
                  <ul className='list'>
                    <li>
                      {currentOffice?.value?.location ? (
                        <Link
                          target='_blank'
                          to={`https://maps.google.com/?q=${currentOffice?.value?.location?.lat},${currentOffice?.value?.location?.lng}`}
                        >
                          {currentOffice?.value?.address}
                        </Link>
                      ) : (
                        currentOffice?.value?.address
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactOffice;
