import React from "react";
import "./ConnectedToAPPButton.scss";

import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa6";

const ConnectedToAPPButton = ({ driver }) => {
  const { t } = useTranslation();
  return (
    <td id='ConnectedToAPPButton'>
      <div className='ConnectedToAPPButton'>
        <div className='APPButtonContainer'>
          <div className='icon'>
            <FaCheck />
          </div>
          <h3>{t("tms.modals.others.connected to APP")}</h3>
        </div>
      </div>
    </td>
  );
};

export default ConnectedToAPPButton;
