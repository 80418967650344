import React, { Fragment, useState } from "react";
import "./TotalPaymentField.scss";

import { IoMdEye } from "react-icons/io";

import { useMoney } from "../../../../helper/useMoney";

import PaymentModal from "./PaymentModal/PaymentModal";

const TotalPaymentField = ({ item }) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const formatedMoney = useMoney(
    item?.total_revenue || 0,
    item?.revenue_currency?.symbol || ""
  );

  return (
    <Fragment>
      <td
        id='TotalPaymentField'
        onClick={() => {
          setOpenPaymentModal(true);
        }}
      >
        <div className='TotalPaymentFieldWrapper'>
          <div className='leftSideContainer'>
            <div className='leftSideTop'>{formatedMoney}</div>
            <div className='leftSideBottom'>
              {item?.paymentType?.type?.name || ""}
            </div>
          </div>
          <div className='rightSideContainer'>
            <IoMdEye />
          </div>
        </div>
      </td>
      {openPaymentModal && (
        <PaymentModal
          isOpen={openPaymentModal}
          isClose={() => setOpenPaymentModal(false)}
          item={item}
        />
      )}
    </Fragment>
  );
};

export default TotalPaymentField;
