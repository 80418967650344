import React, { useState } from "react";
import "./Company.scss";

import { useTranslation } from "react-i18next";

import FooterTitle from "../FooterTitle/FooterTitle";
import LinkItem from "../LinkItem/LinkItem";

const Company = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  return (
    <div id='Company' className={open ? "open" : ""}>
      <FooterTitle
        title={t("landing.navigation.Company")}
        value={open}
        setValue={() => setOpen((state) => !state)}
      />
      <ul className='list'>
        <LinkItem title={t("landing.navigation.Contact")} to='/contact' />
        <LinkItem
          title={t("landing.navigation.Insurance policy")}
          to='/insurancePolicy'
        />
        <LinkItem title={t("landing.navigation.F.A.Q")} to='/qa' />
        <LinkItem title={t("landing.navigation.Terms and conditions")} to='/terms' />
        <LinkItem title={t("landing.navigation.Privacy policy")} to='/confidentiality' />
      </ul>
    </div>
  );
};

export default Company;
