import React from "react";

import { useTranslation } from "react-i18next";

import PartnersTableItem from "./PartnersTableItem/PartnersTableItem";
import TableHeader from "../../../Form/TableFields/TableHeader/TableHeader";
import TableWrapper from "../../../Form/TableFields/TableWrapper/TableWrapper";

const PartnersTable = ({ partners, page, setPage, pageCount, total }) => {
  const { t } = useTranslation();
  return (
    <TableWrapper
      total={total}
      emptyText={t("tms.tables.noInfo.no partner")}
      page={page}
      setPage={setPage}
      pageCount={pageCount}
    >
      <TableHeader>
        <th>{t("tms.tables.titles.Partner name")}</th>
        <th>{t("tms.tables.titles.Partner ID")}</th>
        <th>{t("tms.tables.titles.Email")}</th>
        <th>{t("tms.tables.titles.Service quality")}</th>
        <th>{t("tms.tables.titles.Phone number")}</th>
        <th colSpan='2'>{t("tms.tables.titles.Date of last shipment")}</th>
      </TableHeader>
      <tbody>
        {partners.map((item) => (
          <PartnersTableItem key={item.id} item={item} />
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default PartnersTable;
