import React, { Fragment } from "react";
import "./ShipperHeader.scss";

import LogoContainer from "./Components/LogoContainer/LogoContainer";
import ShipperNav from "./Components/ShipperNav/ShipperNav";

const ShipperHeader = () => {
  return (
    <Fragment>
      <header id='ShipperHeader'>
        <div className='ShipperHeaderContainer'>
          <LogoContainer />
          <ShipperNav />
        </div>
      </header>
      <div style={{ height: "80px", width: "100%" }}></div>
    </Fragment>
  );
};

export default ShipperHeader;
