import React from "react";
import "./CoverFinance.scss";

import { useTranslation } from "react-i18next";

import FadeTextAnimation from "./../../../UI/Animations/FadeTextAnimation/FadeTextAnimation";
import LinkBackground from "./../../Components/Links/LinkBackground/LinkBackground";

const CoverFinance = () => {
  const { t } = useTranslation();

  return (
    <section
      id='CoverFinance'
      className='CoverFinance'
      style={{
        backgroundImage: `url(${`/assets/images/static/cover9.jpg`})`,
      }}
    >
      <div className='blar'>
        <div className='wrapper'>
          <div className='container'>
            <div className='text'>
              <FadeTextAnimation
                text={t("landing.titles.landingMainTitles.Coming soon")}
              />
            </div>
            <div
              data-aos='fade'
              data-aos-delay='500'
              data-aos-duration='1500'
              data-aos-easing='ease-in-out'
            >
              <LinkBackground
                title={t("landing.form.button.Back to home")}
                to='/'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoverFinance;
