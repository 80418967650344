import React from "react";
import "./DetailsFreightLand.scss";

import { useTranslation } from "react-i18next";

import DetailsFreightItem from "../Components/DetailsFreightItem/DetailsFreightItem";

const DetailsFreightLand = () => {
  const { t } = useTranslation();

  return (
    <section id='DetailsFreightLand' className='DetailsFreightLand'>
      <div className='wrapper'>
        <div className='DetailsFreightLandContainer'>
          <DetailsFreightItem
            cover='/assets/images/static/cover28.svg'
            title={t(
              "landing.titles.landingDetailsFreightTitle.Operational efficiency"
            )}
            content={t(
              "landing.bodyText.landingDetailsFreightBody.With instant quotes we eliminate end-less phone calls and emails, while significantly saving your transportation costs."
            )}
            delay={400}
          />
          <DetailsFreightItem
            cover='/assets/images/static/cover29.svg'
            title={t("landing.titles.landingDetailsFreightTitle.Transparency")}
            content={t(
              "landing.bodyText.landingDetailsFreightBody.With guaranteed pricing and real-time tracking, you always have the information needed to transform your business."
            )}
            delay={250}
          />
          <DetailsFreightItem
            cover='/assets/images/static/cover30.svg'
            title={t(
              "landing.titles.landingDetailsFreightTitle.Reliability & flexible capacity"
            )}
            content={t(
              "landing.bodyText.landingDetailsFreightBody.Our trusted network of carriers is reliable, always growing and always there for you, 24/7."
            )}
            delay={100}
          />
        </div>
      </div>
    </section>
  );
};

export default DetailsFreightLand;
