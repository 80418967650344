import { MdLocationPin, MdLocalShipping, MdDirectionsRailway, MdFlight } from "react-icons/md";
import { BsAirplane } from "react-icons/bs";
import { BiSolidShip } from "react-icons/bi";
import { IoIosWarning } from "react-icons/io";
import { FaAnchor } from "react-icons/fa";

export function useConvertShippingType(type) {
  const typeMapping = {
    1: {
      id: 1,
      text: "LAND",
      url: "road",
      icon: <MdLocalShipping />,
      secondaryIcon: <MdLocationPin />,
    },
    2: {
      id: 1,
      text: "LAND",
      url: "road",
      icon: <MdLocalShipping />,
      secondaryIcon: <MdLocationPin />,
    },
    3: { id: 2, text: "AIR", url: "air", icon: <MdFlight />, secondaryIcon: <BsAirplane /> },

    4: {
      id: 3,
      text: "OCEAN",
      url: "ocean",
      icon: <BiSolidShip />,
      secondaryIcon: <FaAnchor />,
    },
    5: {
      id: 3,
      text: "OCEAN",
      url: "ocean",
      icon: <BiSolidShip />,
      secondaryIcon: <FaAnchor />,
    },

    7: {
      id: 4,
      text: "RAIL",
      url: "rail",
      icon: <MdDirectionsRailway />,
      secondaryIcon: <MdDirectionsRailway />,
    },
    8: {
      id: 4,
      text: "RAIL",
      url: "rail",
      icon: <MdDirectionsRailway />,
      secondaryIcon: <MdDirectionsRailway />,
    },
  };

  const defaultType = {
    text: "No information",
    icon: <IoIosWarning />,
    secondaryIcon: <IoIosWarning />,
  };

  const { text, icon, secondaryIcon, id, url } = typeMapping[type.id] || defaultType;

  return {
    shippingTypeId: id,
    shippingTypeText: text,
    shippingTypeUrl: url,
    shippingTypeIcon: icon,
    shippingTypeSecondaryIcon: secondaryIcon,
  };
}
