import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import contactService from "./contactService";

const initialState = {
  // get contact by country
  getContactLoading: false,
  getContactResult: null,
  getContactError: null,

  // get contacts
  getContactsLoading: false,
  getContactsResult: null,
  getContactsError: null,

  // active contact
  currentOffice: null,
};

// get contact by country
export const getContact = createAsyncThunk("contact/getContact", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const BranchID = thunkAPI.getState().settings.countryId;

    return await contactService.getContact(ISO, BranchID);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// get contacts
export const getContacts = createAsyncThunk("contact/getContacts", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await contactService.getContacts(ISO);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get contact by country
      .addCase(getContact.pending, (state) => {
        state.getContactLoading = true;
        state.getContactResult = null;
        state.getContactError = null;
      })
      .addCase(getContact.fulfilled, (state, action) => {
        state.getContactLoading = false;
        state.getContactResult = action.payload;
        state.currentOffice = {
          value: action.payload,
          label: action.payload?.branch?.name,
        };
        state.getContactError = null;
      })
      .addCase(getContact.rejected, (state, action) => {
        state.getContactLoading = false;
        state.getContactResult = null;
        state.getContactError = action.payload;
      })

      // get contacts
      .addCase(getContacts.pending, (state) => {
        state.getContactsLoading = true;
        state.getContactsResult = null;
        state.getContactsError = null;
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        state.getContactsLoading = false;
        state.getContactsResult = action.payload?.map((i) => ({
          value: i,
          label: i.branch.name,
        }));
        state.getContactsError = null;
      })
      .addCase(getContacts.rejected, (state, action) => {
        state.getContactsLoading = false;
        state.getContactsResult = null;
        state.getContactsError = action.payload;
      });
  },
});

export default contactSlice.reducer;
