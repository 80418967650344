import React from "react";
import "./FinancialDetailsShipperHeader.scss";

import { useTranslation } from "react-i18next";
import { BsCreditCard } from "react-icons/bs";

const FinancialDetailsShipperHeader = ({ order }) => {
  const { t } = useTranslation();
  return (
    <div id='FinancialDetailsShipperHeader'>
      <div className='BlockA'>
        <div className='icon'>
          <BsCreditCard />
        </div>
        <div className='title'>
          {t("shipper.forms.titles.Financial details")}
        </div>
      </div>
    </div>
  );
};

export default FinancialDetailsShipperHeader;
