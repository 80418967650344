import React from "react";
import "./SelectDriverModalHeader.scss";

import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

const SelectDriverModalHeader = ({ truckName, isClose }) => {
  const { t } = useTranslation();

  return (
    <div id='SelectDriverModalHeader'>
      <div className='title'>
        <h1>{t("tms.desc.Select suitable driver to link the truck")}</h1>
        <h1>
          <span>{truckName}</span>
        </h1>
      </div>

      <div className='SelectDriverModalHeaderIcon' onClick={isClose}>
        <MdClose />
      </div>
    </div>
  );
};

export default SelectDriverModalHeader;
