import React from "react";
import "./OrdersTableItem.scss";

import { useDispatch } from "react-redux";

import { setShipperAcceptedOrderView } from "../../../../../store/orders/shipperAccepted/shipperAcceptedSlice";

import RouteField from "./../../../../Form/TableFields/RouteField/RouteField";
import RowField from "./../../../../Form/TableFields/RowField/RowField";
import ProductCategoryField from "../../../../Form/TableFields/ProductCategoryField/ProductCategoryField";
import ShippingTypeField from "./../../../../Form/TableFields/ShippingTypeField/ShippingTypeField";
import DateField from "./../../../../Form/TableFields/DateField/DateField";
import ProgressField from "../../../../Form/TableFields/ProgressField/ProgressField";
import PriceField from "../../../../Form/TableFields/PriceField/PriceField";
import NameField from "../../../../Form/TableFields/NameField/NameField";
import TransportationField from "./../../../../Form/TableFields/TransportationField/TransportationField";
import OrderStatusShipperField from "../../../../Form/TableFields/OrderStatusField/OrderStatusShipperField";

const OrdersTableItem = ({
  index,
  order,
  activeOrderId,
  setActiveOrderId,
  activeOrderIndex,
  setActiveOrderIndex,
}) => {
  const dispatch = useDispatch();

  const clickFieldHandler = () => {
    dispatch(setShipperAcceptedOrderView(false));
    setActiveOrderId(order.id);
    setActiveOrderIndex(index);
  };

  const progress =
    order?.last_shipment_status_update?.distance_covered_percent || order?.statusClient?.id === 4
      ? 100
      : null;

  return (
    <RowField
      style={{
        borderLeft: `4px solid ${order.color ? order.color : "transparent"}`,
      }}
    >
      <NameField label={order?.tracking || "-"} onClick={() => clickFieldHandler()} />
      <OrderStatusShipperField status={order?.statusClient || null} />
      <RouteField order={order} />
      <ProductCategoryField order={order} />
      <ShippingTypeField type={order?.type} />
      <TransportationField order={order} />
      <ProgressField progress={progress} order={order} />
      <DateField date={order?.cargos?.[0]?.pickup_date ?? null} />
      <PriceField
        color='#2b353a'
        number={order?.total_revenue || 0}
        symbol={order?.revenue_currency?.symbol || "$"}
      />
    </RowField>
  );
};

export default OrdersTableItem;
