import React from "react";
import "./InfiniteLoading.scss";

import SyncLoader from "react-spinners/SyncLoader";

const InfiniteLoading = () => {
  return (
    <div id='ShipperOrderListCardsLoading'>
      <SyncLoader color='#54cd9d' margin={5} speedMultiplier={0.5} />
    </div>
  );
};

export default InfiniteLoading;
