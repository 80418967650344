import React from "react";
import "./RoadTopMenu.scss";

import { useTranslation } from "react-i18next";
import moment from "moment";

import { MdLocationPin, MdCategory } from "react-icons/md";
import { FaWeightHanging, FaRegCalendar } from "react-icons/fa";
import { GiCargoCrate } from "react-icons/gi";
import { FaTruck } from "react-icons/fa6";

import CreateOrderTopMenu from "../../Components/CreateOrderTopMenu/CreateOrderTopMenu";

const RoadTopMenu = ({
  ORDERTYPES,
  orderTypeId,
  pickUpDate,
  totalWeight,
  cargoType,
  group,
  locations,
}) => {
const {t} = useTranslation()
  const transfer_type =
    ORDERTYPES.find((i) => i?.id === orderTypeId)?.name || "-";

  const trailerCount = group?.length || 0;
  const trailerCountText =
  group && group[0] && group[0].trailerType && trailerCount > 1
      ? ` (+${trailerCount - 1})`
      : "";
  const trailerType = group?.[0]?.trailerType?.label || "-";

  const locationsA = locations.filter((location) => location.direction === "A");
  const locationsAlength = locationsA.length;

  const locationsAfirstElementName =
    locationsA.length > 0 && locationsA[0].inputted_address.length > 0
      ? locationsA[0].inputted_address
      : "";

  const locationAstring = locationsAfirstElementName
    ? `${locationsAfirstElementName}${
        locationsAlength > 1 ? ` (+${locationsAlength - 1})` : ""
      }`
    : "";

  const locationsB = locations.filter((location) => location.direction === "B");
  const locationsBlength = locationsB.length;

  const locationsBfirstElementName =
    locationsB.length > 0 && locationsB[0].inputted_address.length > 0
      ? locationsB[0].inputted_address
      : "";

  const locationBstring = locationsBfirstElementName
    ? `${locationsBfirstElementName}${
        locationsBlength > 1 ? ` (+${locationsBlength - 1})` : ""
      }`
    : "";

  const ARRAY = [
    {
      icon: <FaTruck />,
      title: transfer_type,
    },
    {
      icon: <MdLocationPin />,
      title: `${locationAstring} - ${locationBstring}`,
    },
    {
      icon: <MdCategory />,
      title: cargoType ? cargoType.label : "-",
    },
    {
      icon: <GiCargoCrate />,
      title: `${trailerType} ${trailerCountText}`,
    },
    {
      icon: <FaWeightHanging />,
      title: totalWeight ? totalWeight + ` (${t("shipper.units.KG")})` : "-",
    },
    {
      icon: <FaRegCalendar />,
      title: pickUpDate ? moment(pickUpDate).format("DD/MM/YYYY") : "-",
    },
  ];

  return <CreateOrderTopMenu items={ARRAY} />;
};

export default RoadTopMenu;
