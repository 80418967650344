import React, { Fragment, useState, useEffect } from "react";
import "./TrailerDetails.scss";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import { getTrailer, resetGetTrailer } from "../../../../../../store/fleet/trailers/trailersSlice";

import Wrapper from "../../../../../../componentsAdditional/Wrapper";
import TrailerAdditionalDetails from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrailers/FleetManagementTrailerDetails/TrailerAdditionalDetails/TrailerAdditionalDetails";
import TrailerMaintenance from "../../../../../../componentsDashboard/FleetManagement/FleetManagementTrailers/FleetManagementTrailerDetails/TrailerMaintenance/TrailerMaintenance";
import DBackButton from "./../../../../../../componentsDashboard/Form/DButtons/DBackButton/DBackButton";
import DTextButton from "./../../../../../../componentsDashboard/Form/DButtons/DTextButton/DTextButton";
import DeleteTrailersModal from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrailers/DeleteTrailersModal/DeleteTrailersModal";
import EditTrailersModal from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrailers/EditTrailersModal/EditTrailersModal";
import TrailerOrdersTable from "../../../../../../componentsDashboard/FleetManagement/FleetManagementTrailers/FleetManagementTrailerDetails/TrailerOrdersTable/TrailerOrdersTable";
import PageNotFound from "./../../../../../../componentsBids/PageNotFound/PageNotFound";

const TrailerDetails = () => {
  const { t } = useTranslation();
  const { trailerId } = useParams();
  const dispatch = useDispatch();

  const { getTrailerResult, updateTrailerResult, getTrailerError } = useSelector(
    (state) => state.trailers
  );

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    dispatch(getTrailer(trailerId));
    return () => {
      dispatch(resetGetTrailer());
    };
  }, [dispatch, trailerId, updateTrailerResult]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Trailer details")}>
      {getTrailerError && <PageNotFound />}
      {getTrailerResult && (
        <Fragment>
          <div id='DTrailerDetails'>
            <div className='header'>
              <DBackButton alt='/dashboard/tms/fleet_management/trailers' />
              <div className='action'>
                <DTextButton
                  title={t("tms.forms.buttons.edit")}
                  onClick={() => {
                    setOpenEditModal(true);
                  }}
                  leftIcon={<FiEdit2 />}
                />
                <DTextButton
                  danger
                  title={t("tms.forms.buttons.delete")}
                  onClick={() => {
                    setOpenDeleteModal(true);
                  }}
                  leftIcon={<FiTrash2 />}
                />
              </div>
            </div>
            <div className='DTrailerDetailsContainer'>
              <div className='DTrailerDetailsContainerItem'>
                <TrailerAdditionalDetails trailer={getTrailerResult} />
                <TrailerMaintenance trailer={getTrailerResult} />
              </div>
              <TrailerOrdersTable trailerId={getTrailerResult.id} />
            </div>
          </div>
          <DeleteTrailersModal
            trailerId={getTrailerResult.id}
            isOpen={openDeleteModal}
            isClose={setOpenDeleteModal}
          />
          <EditTrailersModal
            trailer={getTrailerResult}
            isOpen={openEditModal}
            isClose={setOpenEditModal}
          />
        </Fragment>
      )}
    </Wrapper>
  );
};

export default TrailerDetails;
