import React from "react";
import "./LoadingPage.scss";

const LoadingPage = () => {
  return (
    <div id='LoadingPage'>
      <div className='spinner-box'>
        <div className='circle-border'>
          <div className='circle-core'></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
