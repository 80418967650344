import React from "react";
import "./SignUpCarrier.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import CarrierApp from "../../../../componentsLanding/Auth&Password/SignUpForms/CarrierApp/CarrierApp";

const SignUpCarrier = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Sign Up carrier")}>
      <section id='SignUpCarrier'>
        <CarrierApp />
      </section>
    </Wrapper>
  );
};

export default SignUpCarrier;
