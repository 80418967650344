import React from "react";
import "./ShipperChartSwitcher.scss";

import { useTranslation } from "react-i18next";

const ShipperChartSwitcher = ({ activeChart, setActiveChart }) => {
  const { t } = useTranslation();
  return (
    <div id='ShipperChartSwitcher'>
      <div
        className={`chartsSwitcherItem ${
          activeChart === "costs" ? "active" : ""
        } `}
        onClick={() => setActiveChart("costs")}
      >
        {t("shipper.titles.Costs")}
      </div>
      <div
        className={`chartsSwitcherItem ${
          activeChart === "numberOfShipments" ? "active" : ""
        }`}
        onClick={() => setActiveChart("numberOfShipments")}
      >
        {t("shipper.titles.Number of shipments")}
      </div>
      <div
        className={`chartsSwitcherItem ${
          activeChart === "distance" ? "active" : ""
        }`}
        onClick={() => setActiveChart("distance")}
      >
        {t("shipper.titles.Distance")}
      </div>
      <div
        className={`chartsSwitcherItem ${
          activeChart === "weight" ? "active" : ""
        }`}
        onClick={() => setActiveChart("weight")}
      >
        {t("shipper.titles.Weight")}
      </div>
    </div>
  );
};

export default ShipperChartSwitcher;
