import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DocumentTitle = ({ title = "Cargon" }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    document.title = `${title} - ${t("landing.documentTitles.Cargon")} `;
  }, [title, pathname, t]);

  return null;
};

export default DocumentTitle;
