import React, { useEffect, useState } from "react";
import "./CarrierSubscribeForm.scss";

import { useDispatch, useSelector } from "react-redux";

import {
  resetCarrierSubscribe,
  carrierSubscribe,
} from "../../../../store/subscribe/subscribeSlice";

import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";

const CarrierSubscribeForm = () => {
  const dispatch = useDispatch();

  const { carrierSubscribeResult, carrierSubscribeError, carrierSubscribeLoading } = useSelector(
    (state) => state.subscribe
  );

  const [step, setStep] = useState(1);

  const [switcherValue, setSwitcherValue] = useState("fleetOwner");
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [selectedTrailerTypes, setSelectedTrailerTypes] = useState([]);
  const [country, setCountry] = useState(null);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmitStep1 = () => {
    const data = {
      fleet_owner: switcherValue === "fleetOwner",
      trailer_types: selectedTrailerTypes || [],
      routes: selectedRoutes || [],

      registration_country: country?.value,
      fullname: fullName,
      email: email,
    };
    dispatch(carrierSubscribe(data));
  };

  useEffect(() => {
    if (carrierSubscribeResult) {
      setStep(2);
      dispatch(resetCarrierSubscribe());
    }
  }, [carrierSubscribeResult, dispatch]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            fullName={fullName}
            setFullName={setFullName}
            email={email}
            setEmail={setEmail}
            country={country}
            setCountry={setCountry}
            switcherValue={switcherValue}
            setSwitcherValue={setSwitcherValue}
            selectedRoutes={selectedRoutes}
            setSelectedRoutes={setSelectedRoutes}
            selectedTrailerTypes={selectedTrailerTypes}
            setSelectedTrailerTypes={setSelectedTrailerTypes}
            handleSubmit={handleSubmitStep1}
            loading={carrierSubscribeLoading}
            error={carrierSubscribeError}
          />
        );
      case 2:
        return (
          <Step2
            step={step}
            setStep={setStep}
            email={email}
            fleet_owner={switcherValue}
            selectedTrailerTypes={selectedTrailerTypes}
            selectedRoutes={selectedRoutes}
            fullName={fullName}
            country={country}
          />
        );
      case 3:
        return <Step3 />;
      default:
        break;
    }
  };

  return <div id='CarrierSubscribeForm'>{renderStep()}</div>;
};

export default CarrierSubscribeForm;
