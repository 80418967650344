import _request from "../../_request";

const takeOrder = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `/order/${orderId}/client-confirmation`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const rejectOrder = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `/order/${orderId}/cancell`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const shipperOrdersService = {
  takeOrder,
  rejectOrder,
};

export default shipperOrdersService;
