import React from "react";
import "./SelectDriverModalTable.scss";

import { useTranslation } from "react-i18next";

import TableHeader from "./../../../../Form/TableFields/TableHeader/TableHeader";
import SelectDriverModalTableItem from "./SelectDriverModalTableItem/SelectDriverModalTableItem";
import NoInformation from "./../../../../Form/TableFields/NoInformation/NoInformation";

const SelectDriverModalTable = ({ drivers, truckId }) => {
  const { t } = useTranslation();

  return (
    <div id='SelectDriverModalTable'>
      <div className='tableWrapper'>
        <table>
          <TableHeader>
            <th>{t("tms.tables.titles.Driver")}</th>
            <th>{t("tms.tables.titles.Phone number")}</th>
            <th>{t("tms.tables.titles.Total rides")}</th>
            <th>{t("tms.tables.titles.Linked to")}</th>
            <th>{t("tms.tables.titles.Action button")}</th>
          </TableHeader>
          <tbody>
            {drivers.map((driver) => (
              <SelectDriverModalTableItem
                key={driver.id}
                driver={driver}
                truckId={truckId}
              />
            ))}
          </tbody>
        </table>
        {drivers.length === 0 && (
          <NoInformation title={t("tms.tables.noInfo.Drivers not found")} />
        )}
      </div>
    </div>
  );
};

export default SelectDriverModalTable;
