import React from "react";

const PlanningAssistantTableDay = ({ x }) => {
  return (
    <div
      id='PlanningAssistantTableDay'
      style={{ backgroundColor: x === 1 ? "#EEF2FA" : "transparent" }}
    />
  );
};

export default PlanningAssistantTableDay;
