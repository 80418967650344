import React from "react";
import "./TrailerDetailsShipper.scss";

import LandTrailerDetails from "./LandTrailerDetails/LandTrailerDetails";
import OceanTrailerDetails from "./OceanTrailerDetails/OceanTrailerDetails";
import AirPackageDetails from "./AirPackageDetails/AirPackageDetails";
import { useConvertShippingType } from "../../../../helper/useConvertShippingType";

const TrailerDetailsShipper = ({ getOrderResult }) => {
  const shippingType = useConvertShippingType(getOrderResult?.type || null);

  return (
    <div id='TrailerDetailsShipper'>
      {shippingType.shippingTypeId === 1 && <LandTrailerDetails getOrderResult={getOrderResult} />}
      {shippingType.shippingTypeId === 2 && <AirPackageDetails getOrderResult={getOrderResult} />}
      {shippingType.shippingTypeId === 3 && <OceanTrailerDetails getOrderResult={getOrderResult} />}
    </div>
  );
};

export default TrailerDetailsShipper;
