import React, { Fragment, useState } from "react";
import "./OrderDetailsWrapper.scss";

import { useTranslation } from "react-i18next";
import { useConvertShippingType } from "../../../helper/useConvertShippingType";

import { IoIosArrowDown } from "react-icons/io";
import { MdCalendarToday, MdLocationPin } from "react-icons/md";
import { GiWeight } from "react-icons/gi";
import { BsBoxSeamFill } from "react-icons/bs";

import HeaderItem from "./components/HeaderItem";
import AdditionalInformationDetails from "../AdditionalInformationDetails/AdditionalInformationDetails";
import TrailerDetailsShipper from "../TrailerDetails/TrailerDetailsShipper/TrailerDetailsShipper";
import AddressDetailsShipper from "../AddressDetails/AddressDetailsShipper/AddressDetailsShipper";
import ProductDetailsShipper from "../ProductDetails/ProductDetailsShipper/ProductDetailsShipper";

const OrderDetailsWrapper = ({ opened, order }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(opened);

  const route =
    order?.cargos?.[0]?.locations?.[0]?.country +
    " - " +
    order?.cargos?.[0]?.locations?.[1]?.country;
  const productType = order?.cargos?.[0]?.type?.name;
  const weight = order?.cargos?.[0]?.weight + " " + t("shipper.units.KG");
  const pickupDate = order?.cargos?.[0]?.pickup_date;

  const shippingType = useConvertShippingType(order?.type || null);

  return (
    <div id='OrderDetailsWrapper'>
      <div className='header'>
        <div className='headerItems'>
          <HeaderItem icon={<MdLocationPin />} title={route} hidden={open} />
          <HeaderItem
            icon={shippingType?.shippingTypeIcon}
            title={shippingType?.shippingTypeText}
            hidden={open}
          />
          <HeaderItem
            icon={<BsBoxSeamFill />}
            title={productType}
            hidden={open}
          />
          <HeaderItem icon={<GiWeight />} title={weight} hidden={open} />
          {pickupDate && (
            <HeaderItem
              icon={<MdCalendarToday />}
              title={pickupDate}
              hidden={open}
            />
          )}
        </div>

        <div className='moreDetails' onClick={() => setOpen(!open)}>
          <div className='title'>
            {open
              ? t("shipper.buttons.Hide details")
              : t("shipper.buttons.Show details")}
          </div>
          <div
            className='icon'
            style={{ transform: `rotate(${open ? 180 : 0}deg)` }}
          >
            <IoIosArrowDown />
          </div>
        </div>
      </div>
      {open && (
        <Fragment>
          <div className='orderDetailsContainer'>
            <div className='orderDetails'>
              <AddressDetailsShipper getOrderResult={order} />
              <TrailerDetailsShipper getOrderResult={order} />
              <ProductDetailsShipper getOrderResult={order} />
            </div>
          </div>
          <AdditionalInformationDetails getOrderResult={order} />
        </Fragment>
      )}
    </div>
  );
};

export default OrderDetailsWrapper;
