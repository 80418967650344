import React from "react";
import "./ShippingTypeIconFIeld.scss";

import { useConvertShippingType } from "../../../../helper/useConvertShippingType";

const ShippingTypeIconFIeld = ({ type }) => {
  const { shippingTypeIcon } = useConvertShippingType(type || null);
  return (
    <td id='ShippingTypeIconFIeld'>
      <div className='ShippingTypeIconFIeldContainer'>{shippingTypeIcon}</div>
    </td>
  );
};

export default ShippingTypeIconFIeld;
