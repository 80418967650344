import React from "react";
import "./GPSTruckFilter.scss";

import { useTranslation } from "react-i18next";
import DSelect from "../../Form/DSelect/DSelect";

const GPSTruckFilter = ({ value, setValue }) => {
  const { t } = useTranslation();

  const options = [
    {
      label: t("tms.filters.labels.All Trucks"),
      value: 1,
    },
    {
      label: t("tms.filters.labels.Matched"),
      value: 2,
    },
    {
      label: t("tms.filters.labels.Not Matched"),
      value: 3,
    },
  ];

  return (
    <div id='GPSTruckFilter'>
      <h2>{t("tms.filters.labels.Truck List")}</h2>

      <div className='GPSTruckFilterWrapper'>
        <div className='matchedselect'>
          <DSelect
            id='matchedselectGPS'
            value={value}
            setValue={setValue}
            options={options}
            placeholder=''
          />
        </div>
      </div>
    </div>
  );
};

export default GPSTruckFilter;
