import React, { Fragment } from "react";
import "./AssignCarrierModalHeader.scss";

import { useTranslation } from "react-i18next";

import { MdClose } from "react-icons/md";

const AssignCarrierModalHeader = ({ isClose, assignCarrierTab, onClick }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div id='AssignCarrierModalHeader'>
        <div className='AssignCarrierPageSwitcher'>
          <ul className='list'>
            <li
              className={assignCarrierTab === "ownFleet" ? "active" : ""}
              onClick={() => onClick("ownFleet")}
            >
              <div>
                <p>{t("tms.menu.Own fleet")}</p>
              </div>
              <div className='line'></div>
            </li>
            <li
              className={assignCarrierTab === "partnerFleet" ? "active" : ""}
              onClick={() => onClick("partnerFleet")}
            >
              <div>
                <p>{t("tms.menu.Partner fleet")}</p>
              </div>
              <div className='line'></div>
            </li>
          </ul>
        </div>
        <div className='closeIcon' onClick={isClose}>
          <MdClose />
        </div>
      </div>
    </Fragment>
  );
};

export default AssignCarrierModalHeader;
