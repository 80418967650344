import React, { Fragment } from "react";
import "./TrackingWBorderField.scss";
import { Link } from "react-router-dom";
const TrackingWBorderField = ({ text, to }) => {
  return (
    <Fragment>
      <td id='TrackingWBorderField'>
        {to ? (
          <Link to={to}>
            <div className='TrackingWBorderFieldContainer' id='link'>
              {text}
            </div>
          </Link>
        ) : (
          <div className='TrackingWBorderFieldContainer'>{text}</div>
        )}
      </td>
    </Fragment>
  );
};

export default TrackingWBorderField;
