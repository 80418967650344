import React from "react";
import "./Overlay.scss";

import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

const Overlay = ({ isOpen, isClose, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={isClose}
      className='ModalComponent'
      overlayClassName='OverlayComponent'
    >
      {children}
    </ReactModal>
  );
};

export default Overlay;
