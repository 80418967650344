import React, { useState, useEffect, Fragment } from "react";
import "./TruckDetails.scss";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  getTruck,
  resetGetTruck,
} from "../../../../../../store/fleet/trucks/trucksSlice";

import Wrapper from "../../../../../../componentsAdditional/Wrapper";
import TruckAdditionalDetails from "../../../../../../componentsDashboard/FleetManagement/FleetManagementTrucks/FleetManagementTruckDetails/TruckAdditionalDetails/TruckAdditionalDetails";
import TruckMaintenance from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrucks/FleetManagementTruckDetails/TruckMaintenance/TruckMaintenance";
import TruckOrdersTable from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrucks/FleetManagementTruckDetails/TruckOrdersTable/TruckOrdersTable";
import DBackButton from "./../../../../../../componentsDashboard/Form/DButtons/DBackButton/DBackButton";
import DTextButton from "./../../../../../../componentsDashboard/Form/DButtons/DTextButton/DTextButton";
import DeleteTrucksModal from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrucks/DeleteTrucksModal/DeleteTrucksModal";
import EditTrucksModal from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementTrucks/EditTrucksModal/EditTrucksModal";
import TruckGpsTracking from "../../../../../../componentsDashboard/FleetManagement/FleetManagementTrucks/FleetManagementTruckDetails/TruckGpsTracking/TruckGpsTracking";
import PageNotFound from "../../../../../../componentsBids/PageNotFound/PageNotFound";

const TruckDetails = () => {
  const { t } = useTranslation();
  const { truckId } = useParams();
  const dispatch = useDispatch();

  const {
    getTruckResult,
    updateTruckResult,
    manageTruckResult,
    getTruckError,
  } = useSelector((state) => state.trucks);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    dispatch(getTruck(truckId));
    return () => {
      dispatch(resetGetTruck());
    };
  }, [dispatch, truckId, updateTruckResult, manageTruckResult]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Truck details")}>
      {getTruckError && <PageNotFound />}
      {getTruckResult && (
        <Fragment>
          <section id='TruckDetails'>
            <div className='header'>
              <DBackButton alt='/dashboard/tms/fleet_management/trucks' />
              <div className='actions'>
                <DTextButton
                  title={t("tms.forms.buttons.edit")}
                  onClick={() => {
                    setOpenEditModal(true);
                  }}
                  leftIcon={<FiEdit2 />}
                />

                <DTextButton
                  danger
                  title={t("tms.forms.buttons.delete")}
                  onClick={() => {
                    setOpenDeleteModal(true);
                  }}
                  leftIcon={<FiTrash2 />}
                />
              </div>
            </div>
            <div className='TruckDetailsContainer'>
              <div className='TruckDetailsContainerItems'>
                <div className='TruckDetailsContainerItemLeft'>
                  <TruckAdditionalDetails truck={getTruckResult} />
                  {getTruckResult.has_connected_device && (
                    <TruckGpsTracking />
                  )}
                </div>
                <TruckMaintenance truck={getTruckResult} />
              </div>
              <TruckOrdersTable truckId={getTruckResult.id} />
            </div>
          </section>

          <DeleteTrucksModal
            truckId={getTruckResult.id}
            isOpen={openDeleteModal}
            isClose={setOpenDeleteModal}
          />
          <EditTrucksModal
            truck={getTruckResult}
            isOpen={openEditModal}
            isClose={setOpenEditModal}
          />
        </Fragment>
      )}
    </Wrapper>
  );
};

export default TruckDetails;
