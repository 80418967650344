import React, { Fragment } from "react";
import "./TrackingShipperHeader.scss";

import { MdClose } from "react-icons/md";
import { IoMdPin } from "react-icons/io";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

const TrackingShipperHeader = ({ order, isClose }) => {
  const cargos = order?.cargos?.[0]?.locations ?? [];
  const filterA = cargos.filter((i) => i.direction === "A");
  const filterB = cargos.filter((i) => i.direction === "B");

  return (
    <Fragment>
      <div id='TrackingShipperHeader'>
        <div className='BlockA'>
          {filterA &&
            filterA.map((i, index) => (
              <Fragment key={index}>
                <div className='item from'>
                  <div className='icon'>
                    <IoMdPin />
                  </div>
                  <h4>{i?.formatted_address || "-"}</h4>
                </div>
                <div className='betweenIcon'>
                  <MdOutlineKeyboardDoubleArrowRight />
                </div>
              </Fragment>
            ))}

          {filterB &&
            filterB.map((i, index) => (
              <Fragment key={index}>
                <div className='item to'>
                  <div className='icon'>
                    <IoMdPin />
                  </div>
                  <h4>{i?.formatted_address || "-"}</h4>
                </div>
                <div className='betweenIcon'>
                  <MdOutlineKeyboardDoubleArrowRight />
                </div>
              </Fragment>
            ))}
        </div>
        <div className='BlockB'>
          <div className='icon' onClick={isClose}>
            <MdClose />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TrackingShipperHeader;
