import React from "react";
import "./FAQCover.scss";

const FAQCover = ({ url, icon }) => {
  return (
    <div
      className='FAQCover'
      style={{
        backgroundImage: `url(${url})`,
      }}
    >
      <div className='corner'>
        <div className='icon'>
          {icon ? (
            icon
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='48'
              height='48'
              viewBox='0 0 48 48'
              fill='none'
            >
              <path
                d='M18 18C18 11 29 11 29 18C29 23 24 22 24 28M24 36.02L24.02 35.998'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M24 44C35.046 44 44 35.046 44 24C44 12.954 35.046 4 24 4C12.954 4 4 12.954 4 24C4 27.642 4.974 31.06 6.676 34L5 43L14 41.324C17.0391 43.0821 20.489 44.0053 24 44Z'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQCover;
