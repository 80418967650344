import React from "react";
import "./DetailsHeader.scss";

import { useTranslation } from "react-i18next";
import { FiChevronLeft } from "react-icons/fi";
import { useDispatch } from "react-redux";

import { setShipperAcceptedOrderView } from "../../../../store/orders/shipperAccepted/shipperAcceptedSlice";

const DetailsHeader = ({ order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div id='DetailsHeaderActiveOrder'>
      <div className='DetailsHeaderActiveOrderLeft'>
        <div
          className='backBtn'
          onClick={() => {
            dispatch(setShipperAcceptedOrderView(true));
          }}
        >
          <div className='icon'>
            <FiChevronLeft />
          </div>
          <h3>{t("shipper.titles.Shipment view")}</h3>
        </div>
        <div className='trackingNumber'>
          <div className='line'></div>
          <h4>{order.tracking}</h4>
        </div>
      </div>
      <div className='DetailsHeaderActiveOrderRight'>
        <div className='line'></div>
        <h5>
          {t("shipper.titles.Create date:")} {order?.created_at ?? "-"}
        </h5>
      </div>
    </div>
  );
};

export default DetailsHeader;
