import React from "react";
import "./SignIn.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import LoginForm from "./../../../../componentsLanding/Auth&Password/LoginForm/LoginForm";

const SignIn = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Sign In")}>
      <section id='SignIn'>
        <AuthCover url='/assets/images/static/cover23.jpg' />
        <LoginForm />
      </section>
    </Wrapper>
  );
};

export default SignIn;
