import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import financialService from "./financialService";

const initialState = {
  getFinishedLoading: true,
  getFinishedResult: null,
  getFinishedMeta: null,
  getFinishedError: null,

  getActiveLoading: true,
  getActiveResult: null,
  getActiveMeta: null,
  getActiveError: null,
};

export const getFinished = createAsyncThunk(
  "financial/getFinished",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await financialService.getFinished(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getActive = createAsyncThunk(
  "financial/getActive",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await financialService.getActive(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const financialSlice = createSlice({
  name: "financial",
  initialState,
  reducers: {
    resetGetFinished: (state) => {
      state.getFinishedLoading = true;
      state.getFinishedResult = null;
      state.getFinishedMeta = null;
      state.getFinishedError = null;
    },
    resetGetActive: (state) => {
      state.getActiveLoading = true;
      state.getActiveResult = null;
      state.getActiveMeta = null;
      state.getActiveError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFinished.pending, (state) => {
        state.getFinishedLoading = true;
        state.getFinishedResult = null;
        state.getFinishedMeta = null;
        state.getFinishedError = null;
      })
      .addCase(getFinished.fulfilled, (state, action) => {
        state.getFinishedLoading = false;
        state.getFinishedResult = action.payload.data;
        state.getFinishedMeta = action.payload.meta;
        state.getFinishedError = null;
      })
      .addCase(getFinished.rejected, (state, action) => {
        state.getFinishedLoading = false;
        state.getFinishedResult = null;
        state.getFinishedMeta = null;
        state.getFinishedError = action.payload;
      })

      .addCase(getActive.pending, (state) => {
        state.getActiveLoading = true;
        state.getActiveResult = null;
        state.getActiveMeta = null;
        state.getActiveError = null;
      })
      .addCase(getActive.fulfilled, (state, action) => {
        state.getActiveLoading = false;
        state.getActiveResult = action.payload.data;
        state.getActiveMeta = action.payload.meta;
        state.getActiveError = null;
      })
      .addCase(getActive.rejected, (state, action) => {
        state.getActiveLoading = false;
        state.getActiveResult = null;
        state.getActiveMeta = null;
        state.getActiveError = action.payload;
      });
  },
});

export const { resetGetFinished, resetGetActive } = financialSlice.actions;

export default financialSlice.reducer;
