import _request from "./../../_request";

const getFinancialOrders = async (ISO, TOKEN, data) => {
  let url = "/order/billing?";
  const {
    page,
    keyword,
    pickup_address,
    dropoff_address,
    min_price,
    max_price,
    start_date,
    end_date,
    payment_status,
  } = data || {};

  url += page ? `page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += min_price ? `&revenue[min]=${min_price}` : "";
  url += max_price ? `&revenue[max]=${max_price}` : "";
  url += start_date ? `&deadline[min]=${start_date}` : "";
  url += end_date ? `&deadline[max]=${end_date}` : "";
  url += payment_status ? `&payment_status=${payment_status}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const shipperFinancialService = {
  getFinancialOrders,
};

export default shipperFinancialService;
