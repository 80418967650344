import React from "react";
import "./DTextarea.scss";

import DLabel from "./../DLabel/DLabel";
import DErrorText from "../DErrorText/DErrorText";

const DTextarea = ({
  id,
  value,
  setValue,
  placeholder,
  label,
  error,
  required,
  disabled,
}) => {
  return (
    <div id='DTextarea' className={error ? "error" : ""}>
      {label && <DLabel label={label} htmlFor={id} required={required} />}
      <textarea
        id={id}
        placeholder={placeholder ? placeholder : ""}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
      />
      {error && <DErrorText error={error} />}
    </div>
  );
};

export default DTextarea;
