import React, { Fragment } from "react";

import { useTranslation } from "react-i18next";

import TableHeader from "../../Form/TableFields/TableHeader/TableHeader";
import TableWrapper from "../../Form/TableFields/TableWrapper/TableWrapper";
import LoadsTableItem from "./LoadsTableItem/LoadsTableItem";

const LoadsTable = ({ items, page, setPage, pageCount, total }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {items && (
        <TableWrapper
          total={total}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          emptyText={t("tms.tables.noInfo.No orders")}
        >
          <TableHeader>
            <th>{t("tms.tables.titles.Route")}</th>
            <th>{t("tms.tables.titles.Pick up date")}</th>
            <th>{t("tms.tables.titles.Weight (KG)")}</th>
            <th>{t("tms.tables.titles.Trailer need")}</th>
            <th>{t("tms.tables.titles.Your bid")}</th>
            <th>{t("tms.tables.titles.Action button")}</th>
          </TableHeader>
          <tbody>
            {items.map((item) => (
              <LoadsTableItem key={item.id} item={item} />
            ))}
          </tbody>
        </TableWrapper>
      )}
    </Fragment>
  );
};

export default LoadsTable;
