import React, { useState, useEffect } from "react";
import "./Form.scss";

import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { CgMailForward } from "react-icons/cg";
import { useSelector, useDispatch } from "react-redux";

import { containsOnlyNumbers } from "./../../../helper/useContainNum";
import { createLead, resetCreateLead } from "../../../store/additional/lead/leadSlice";

import Button from "./../../Form/Button/Button";
import Label from "./../../Form/Label/Label";
import TextArea from "./../../Form/TextArea/TextArea";
import RoleRadio from "./../../Form/Radio/RoleRadio/RoleRadio";
import ConvertInput from "./../../Form/Inputs/ConvertInput/ConvertInput";
import ErrorText from "./../../Form/ErrorText/ErrorText";
import Input from "../../Form/Inputs/Input";
import SnackBar from "../../../componentsDashboard/Form/SnackBar/SnackBar";

const Form = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { createLeadLoading, createLeadResult, createLeadError } = useSelector(
    (state) => state.lead
  );

  const { phoneIndexValueId, countryId } = useSelector((state) => state.settings);

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [convertValue, setConvertValue] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [method, setMethod] = useState("EMAIL");
  const [role, setRole] = useState();

  const reset = () => {
    setConvertValue("");
    setName("");
    setMessage("");
    setMethod("EMAIL");
    setRole("");
  };

  const submitHandler = () => {
    let data;
    if (method === "EMAIL") {
      data = {
        branch_id: countryId,
        name: name,
        type: role,
        email: convertValue,
        message: message,
        agreement: true,
      };
    } else {
      data = {
        branch_id: countryId,
        name: name,
        type: role,
        phone_index_id: phoneIndexValueId,
        phone: convertValue,
        message: message,
        agreement: true,
      };
    }
    dispatch(createLead(data));
  };

  useEffect(() => {
    if (createLeadResult) {
      reset();
      dispatch(resetCreateLead());
      setSnackBarOpen(true);
    }
  }, [dispatch, createLeadError, createLeadResult]);

  useEffect(() => {
    const onlyNumber = containsOnlyNumbers(convertValue);
    if (convertValue.length > 2 && onlyNumber) {
      setMethod("PHONE");
    } else {
      setMethod("EMAIL");
    }
  }, [convertValue]);

  return (
    <div id='Form' className='Form'>
      <SnackBar
        status={"success"}
        text={"Successfully submited"}
        isOpen={snackBarOpen}
        isClose={() => setSnackBarOpen(false)}
      />
      <div className='animation' data-aos='fade-left' data-aos-duration='1500' data-aos-once='true'>
        <div className='formContainer'>
          <div>
            <h1>{parse(t("landing.titles.landingMainTitles.Request a call back !"))}</h1>
          </div>
          <div className='inputContainer'>
            <Label text={t("landing.form.label.*Tell us your name")} id='name' />
            <Input
              id='name'
              value={name}
              setValue={setName}
              placeholder={t("landing.form.placeholder.Enter your name")}
              error={createLeadError?.errors?.name?.[0]}
            />
            <ErrorText error={createLeadError?.errors?.name?.[0]} />
          </div>
          <div className='convertContainer'>
            <Label text={t("landing.form.label.*Work email or phone number")} id='emailorphone' />
            <ConvertInput
              id='emailorphone'
              inputStatus={method}
              value={convertValue}
              setValue={setConvertValue}
              error={
                createLeadError?.errors?.email?.[0] ||
                createLeadError?.errors?.phone_index_id?.[0] ||
                createLeadError?.errors?.phone?.[0]
              }
              placeholder={t("landing.form.placeholder.Enter email or phone number")}
            />
            {method === "EMAIL" && <ErrorText error={createLeadError?.errors?.email?.[0]} />}
            {method !== "EMAIL" &&
              (<ErrorText error={createLeadError?.errors?.phone_index_id?.[0]} /> ||
                (method !== "EMAIL" && <ErrorText error={createLeadError?.errors?.phone?.[0]} />))}
          </div>
          <div className='checkBoxContainer'>
            <Label text={t("landing.form.label.*Area of interest")} />
            <RoleRadio value={role} setValue={setRole} />
            <ErrorText error={createLeadError?.errors?.type?.[0]} />
          </div>
          <div className='textAreaContainer'>
            <Label text={t("landing.form.label.*How can we help you?")} id='Message' />
            <TextArea
              placeholder={t("landing.form.placeholder.Your message...")}
              value={message}
              setValue={setMessage}
              id='Message'
              name='Message'
              error={createLeadError?.errors?.message?.[0]}
            />
            <ErrorText error={createLeadError?.errors?.message?.[0]} />
            <ErrorText error={createLeadError?.errors?.branch_id?.[0]} />
            <ErrorText error={createLeadError?.errors?.agreement?.[0]} />
          </div>

          <div className='buttonContainer'>
            <Button
              loading={createLeadLoading}
              title={t("landing.form.button.Submit")}
              rightIcon={<CgMailForward />}
              onClick={() => {
                submitHandler();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
