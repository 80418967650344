import * as React from "react";

const CircularLogoSmall = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={44}
    height={44}
    fill='none'
    {...props}
  >
    <path
      fill='#00B56C'
      d='M0 22.019v-.788C.45 9.415 10.128 0 22.019 0 33.909 0 43.587 9.415 44 21.194v.787c0 12.154-9.865 21.981-21.981 21.981C9.865 44 0 34.136 0 22.02ZM21.944 7.165c-8.103 0-14.667 6.564-14.667 14.666s6.564 14.667 14.667 14.667c8.102 0 14.666-6.564 14.666-14.667.038-8.065-6.564-14.666-14.666-14.666Z'
    />
    <path
      fill='#00B56C'
      d='M24.816 17.959v4.74c0 .088-.087.088-.087.088l-5.307-3.523a1.066 1.066 0 0 0-.522-.174.919.919 0 0 0-.913.913v6.525c0 .74-.174 1.435-.522 2.088-.827 1.392-2.262 2.305-3.958 2.349h-.131V13.957c0-.261.043-.479.13-.653.392-1.087 1.784-1.653 2.871-.913l.566.348 7.873 5.22Z'
    />
    <path
      fill='#00B56C'
      d='M30.536 12.496v16.987c0 .26-.043.476-.13.693-.39 1.083-1.777 1.647-2.86.91l-1.777-1.17-6.673-4.507v-4.636s0-.044.043-.044l.044-.043h.043l5.243 3.51c.13.087.347.173.52.173a.956.956 0 0 0 .65-.26c.174-.173.304-.433.304-.65v-6.5c0-.736.173-1.43.52-2.08.823-1.386 2.253-2.296 3.943-2.34.043-.043.087-.043.13-.043Z'
    />
  </svg>
);
export default CircularLogoSmall;
