import React, { Fragment, useState, useEffect } from "react";
import "./OwnFleetTable.scss";

import { useTranslation } from "react-i18next";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getTrucks, resetGetTrucks } from "./../../../../../../store/fleet/trucks/trucksSlice";

import OwnFleetTableItem from "./OwnFleetTableItem/OwnFleetTableItem";
import TableHeader from "./../../../../../Form/TableFields/TableHeader/TableHeader";
import NoInformation from "./../../../../../Form/TableFields/NoInformation/NoInformation";
import LoadingPage from "../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import DSearch from "./../../../../../FilterForms/DSearch/DSearch";
import DButton from "../../../../../Form/DButtons/DButton/DButton";

const OwnFleetTable = ({ order, isOpen, isClose, tab }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getTrucksLoading, getTrucksResult } = useSelector((state) => state.trucks);

  const [keyword, setKeyword] = useState("");
  const [keywordValue, setKeywordValue] = useState("");
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setKeyword(keywordValue);
    }, 500);
    return () => clearInterval(timeout);
  }, [keywordValue, setKeyword]);

  useEffect(() => {
    if (isOpen && tab === "ownFleet") {
      const data = { active: true, keyword };
      dispatch(getTrucks(data));
    }
    return () => {
      dispatch(resetGetTrucks());
    };
  }, [dispatch, isOpen, keyword, tab]);

  return (
    <Fragment>
      <div id='OwnFleetTable'>
        <p className='OwnFleetTableDescription'>
          {t(
            "tms.desc.Assign your own carrier by selecting the specific truck that you want and click assign carrier."
          )}
        </p>

        <div className='OwnFleetTableContainer'>
          <div className='OwnFleetTableHeader'>
            <DSearch value={keywordValue} setValue={(e) => setKeywordValue(e)} />

            <DButton
              title={t("tms.forms.buttons.Create vehicle")}
              rightIcon={<FaLongArrowAltRight />}
              onClick={() => {
                navigate("/dashboard/tms/fleet_management/trucks");
              }}
            />
          </div>
          {getTrucksLoading && <LoadingPage />}
          {getTrucksResult && !getTrucksLoading && (
            <div className='tableWrapper'>
              <table>
                <TableHeader>
                  <th>{t("tms.tables.titles.Truck")}</th>
                  <th>{t("tms.tables.titles.Trailer type")}</th>
                  <th>{t("tms.tables.titles.Trailer volume")}</th>
                  <th>{t("tms.tables.titles.Driver")}</th>
                  <th style={{ textAlign: "center" }}>{t("tms.tables.titles.Action button")}</th>
                </TableHeader>
                <tbody>
                  {getTrucksResult.map((truck) => (
                    <OwnFleetTableItem
                      isClose={isClose}
                      order={order}
                      key={truck.id}
                      truck={truck}
                    />
                  ))}
                </tbody>
              </table>
              {getTrucksResult.length === 0 && (
                <NoInformation title={t("tms.tables.noInfo.Truck not found")} />
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default OwnFleetTable;
