import React from "react";
import "./LogoContainer.scss";

import { useNavigate } from "react-router-dom";

import Logo from "../../../../componentsAdditional/SVG/Cargon/Logo";
import CircularLogoSmall from "./../../../../componentsAdditional/SVG/Cargon/CircularLogoSmall";

const LogoContainer = () => {
  const navigate = useNavigate();

  return (
    <div
      className='HeaderLogoContainer'
      onClick={() => {
        navigate("/");
        window.scrollTo(0, 0);
      }}
    >
      <div to='/'>
        <div className='fullLogo'>
          <Logo />
        </div>
        <div className='circularLogo'>
          <CircularLogoSmall />
        </div>
      </div>
    </div>
  );
};

export default LogoContainer;
