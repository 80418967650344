import React, { Fragment, useState } from "react";
import "./AccountListItem.scss";

import { useTranslation } from "react-i18next";
import { FiEdit2, FiTrash2, FiMoreVertical } from "react-icons/fi";
import { IoMdWallet } from "react-icons/io";
import { Menu, MenuItem } from "@mui/material";

import EditBankRequisitesModal from "./../../EditBankRequisitesModal/EditBankRequisitesModal";
import DeleteBankRequisitesModal from "../../DeleteBankRequisitesModal/DeleteBankRequisitesModal";

const AccountListItem = ({ item }) => {
  const { t } = useTranslation();
  const [showEditModal, setSHowEdiTmodal] = useState(false);
  const [showDeleteModal, setShowDeletemodal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <li id='AccountListItem'>
        <div className='content'>
          <div className='accountIcon'>
            <IoMdWallet />
          </div>
          <div className='text'>
            <h3>
              {item?.name || t("tms.desc.Bank name")} (
              {item?.code || t("tms.desc.code")})
            </h3>
            <h4>{item?.account || t("tms.desc.Bank account")}</h4>
          </div>
        </div>
        <div className='Moreicon' onClick={handleClick}>
          <FiMoreVertical />
        </div>
        <Menu
          id='OrdersTableItemMore'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              setSHowEdiTmodal(true);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <FiEdit2 />
              {t("tms.tables.buttons.Edit")}
            </div>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose();
              setShowDeletemodal(true);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                color: "red",
              }}
            >
              <FiTrash2 />
              {t("tms.tables.buttons.Delete")}
            </div>
          </MenuItem>
        </Menu>
      </li>
      {showDeleteModal && (
        <DeleteBankRequisitesModal
          item={item}
          isOpen={showDeleteModal}
          isClose={() => setShowDeletemodal(false)}
        />
      )}
      {showEditModal && (
        <EditBankRequisitesModal
          item={item}
          isOpen={showEditModal}
          isClose={() => setSHowEdiTmodal(false)}
        />
      )}
    </Fragment>
  );
};

export default AccountListItem;
