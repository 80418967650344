import React from "react";
import "./NoInformation.scss";

const NoInformation = ({ title }) => {
  return (
    <div id='tableNoInformation'>
      <h2 className='description'>{title}</h2>
    </div>
  );
};

export default NoInformation;
