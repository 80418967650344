import React, { Fragment } from "react";
import "./AssignCarrierModal.scss";

import DOverlay from "../../../Modal/DOverlay/DOverlay";
import AssignCarrierModalHeader from "./Components/AssignCarrierModalHeader/AssignCarrierModalHeader";
import OwnFleetTable from "./OwnFleet/OwnFleetTable/OwnFleetTable";
import PartnerFleetTable from "./PartnerFleet/PartnerFleetTable";
import SendBidDetails from "./PartnerFleet/SendBidDetails/SendBidDetails";

const AssignCarrierModal = ({
  bidScreen,
  setBidScreen,
  order,
  isOpen,
  isClose,
  tab,
  setTab,
  partnerTab,
  setPartnerTab,
  partnersBids,
  setPartnersBids,
}) => {
  return (
    <DOverlay
      isOpen={isOpen}
      isClose={() => {
        isClose();
        setTab("ownFleet");
        setPartnerTab("assign");
        setBidScreen(false);
        setPartnersBids([]);
      }}
    >
      {bidScreen ? (
        <SendBidDetails
          setBidScreen={setBidScreen}
          isOpen={isOpen}
          isClose={isClose}
          order={order}
          tab={tab}
          setTab={setTab}
          partnerTab={partnerTab}
          setPartnerTab={setPartnerTab}
          partnersBids={partnersBids}
          setPartnersBids={setPartnersBids}
        />
      ) : (
        <div id='AssignCarrierModal'>
          <div className='AssignCarrierModalContent'>
            <Fragment>
              <AssignCarrierModalHeader
                isClose={() => {
                  isClose();
                  setTab("ownFleet");
                  setPartnerTab("assign");
                  setBidScreen(false);
                  setPartnersBids([]);
                }}
                assignCarrierTab={tab}
                onClick={(item) => setTab(item)}
              />
              {tab === "ownFleet" ? (
                <OwnFleetTable
                  isOpen={isOpen}
                  isClose={isClose}
                  order={order}
                  tab={tab}
                />
              ) : (
                <PartnerFleetTable
                  setBidScreen={setBidScreen}
                  isOpen={isOpen}
                  isClose={isClose}
                  order={order}
                  tab={tab}
                  setTab={setTab}
                  partnerTab={partnerTab}
                  setPartnerTab={setPartnerTab}
                  partnersBids={partnersBids}
                  setPartnersBids={setPartnersBids}
                />
              )}
            </Fragment>
          </div>
        </div>
      )}
    </DOverlay>
  );
};

export default AssignCarrierModal;
