import React from "react";
import "./OfferTitle.scss";

import { useTranslation } from "react-i18next";

const OfferTitle = ({ company }) => {
  const { t } = useTranslation();
  return (
    <div id='BidsOfferTitle'>
      <div className='offerFormTitle'>
        {t("partnerBid.Give us your best price")}
      </div>
      <div className='offerFormText'>
        {t("partnerBid.Your offer will send")}: <span>{company}</span>
      </div>
    </div>
  );
};

export default OfferTitle;
