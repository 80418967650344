import React from "react";
import "./CreateOrderDimentionGroup.scss";

import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";

import DInputs from "./../../../../Form/DInputs/DInputs";

const CreateOrderDimentionGroup = ({
  index,
  length,
  setLength,
  lengthError,
  width,
  setWidth,
  widthError,
  height,
  setHeigth,
  heightError,
}) => {
  const { t } = useTranslation();
  return (
    <div id='CreateOrderDimentionGroup'>
      <div className='dimentionGroupItem'>
        <DInputs
          required
          id={`dimentionGrouplength${index}`}
          type={"number"}
          value={length}
          setValue={(value) => setLength(value)}
          placeholder={t("shipper.forms.content.Length")}
          label={t("shipper.forms.content.Package length (CM)")}
          error={lengthError}
        />
      </div>
      <div className='dimentionGroupIconClose'>
        <IoClose />
      </div>
      <div className='dimentionGroupItem'>
        <DInputs
          required
          id={`dimentionGroupWidth${index}`}
          type={"number"}
          value={width}
          setValue={(value) => setWidth(value)}
          placeholder={t("shipper.forms.content.Width")}
          label={t("shipper.forms.content.Package width (CM)")}
          error={widthError}
        />
      </div>
      <div className='dimentionGroupIconClose'>
        <IoClose />
      </div>
      <div className='dimentionGroupItem'>
        <DInputs
          required
          id={`dimentionGroupHeight${index}`}
          type={"number"}
          value={height}
          setValue={(value) => setHeigth(value)}
          placeholder={t("shipper.forms.content.Height")}
          label={t("shipper.forms.content.Package height (CM)")}
          error={heightError}
        />
      </div>
    </div>
  );
};

export default CreateOrderDimentionGroup;
