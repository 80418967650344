import React, { useState, useEffect } from "react";
import "./SignUpCompanyVerifyForm.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { verify, resetVerify, sendOtp, resetSendOtp } from "../../../../store/auth/authSlice";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import Button from "../../../Form/Button/Button";
import SendAgain from "../../Components/SendAgain/SendAgain";
import OTP from "../../../Form/OTP/OTP";

const SignUpCompanyVerifyForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");

  const { verifyLoading, verifyResult, verifyError, sendOtpResult, sendOtpLoading } = useSelector(
    (state) => state.auth
  );

  const [otp, setOtp] = useState();

  useEffect(() => {
    if (verifyResult) {
      const url = "/register/tms/success";
      navigate(url);
      dispatch(resetVerify());
    }
  }, [dispatch, navigate, verifyResult]);

  useEffect(() => {
    return () => {
      dispatch(resetVerify());
      resetSendOtp();
    };
  }, [dispatch]);

  const submitHandler = () => {
    const data = {
      type: "email",
      code: otp,
    };
    dispatch(verify(data));
  };

  const againHandler = () => {
    const data = {
      type: "email",
    };
    dispatch(sendOtp(data));
    setOtp();
  };

  return (
    <AuthFormWrapper>
      <form id='SignUpCompanyVerifyForm' className='SignUpCompanyVerifyForm'>
        <FormTitle center title={t("landing.auth.titles.Verification code")}>
          {t("landing.auth.titles.Please enter the verification code you received in your email")}
          <span> {email}</span>
        </FormTitle>
        <OTP
          value={otp}
          setValue={setOtp}
          count={6}
          error={(verifyError?.errors?.code?.[0] ?? null) || (verifyError?.error ?? null)}
        />
        <Button
          loading={verifyLoading}
          title={t("landing.auth.form.Verify account")}
          onClick={() => {
            submitHandler();
          }}
        />
        <SendAgain
          title={t("landing.auth.form.Send it again")}
          onClick={() => againHandler()}
          loading={sendOtpLoading}
          result={sendOtpResult}
          reset={() => dispatch(resetSendOtp())}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default SignUpCompanyVerifyForm;
