import React from "react";
import "./TableWrapper.scss";

import NoInformation from "./../NoInformation/NoInformation";
import Pagination from "../../Pagination/Pagination";

const TableWrapper = ({
  children,
  total,
  emptyText,
  page,
  setPage,
  pageCount,
}) => {
  return (
    <div id='TableWrapperComponent'>
      <div id='TableWrapperContainer'>
        <table>{children}</table>
        {total === 0 && (
          <NoInformation title={emptyText ? emptyText : "No information"} />
        )}
      </div>
      {pageCount && (
        <Pagination value={page} setValue={setPage} pageCount={pageCount} />
      )}
    </div>
  );
};

export default TableWrapper;
