import React, { Fragment, useState } from "react";
import "./TrailersTableItem.scss";

import { useTranslation } from "react-i18next";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import TrailerField from "./../../../../Form/TableFields/TrailerField/TrailerField";
import DeleteTrailersModal from "./../../DeleteTrailersModal/DeleteTrailersModal";
import EditTrailersModal from "./../../EditTrailersModal/EditTrailersModal";
import RowField from "../../../../Form/TableFields/RowField/RowField";
import TextField from "../../../../Form/TableFields/TextField/TextField";
import MoreField from "../../../../Form/TableFields/MoreField/MoreField";
import TruckField from "../../../../Form/TableFields/TruckField/TruckField";

const TrailersTableItem = ({ trailer }) => {
  const { t } = useTranslation();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <RowField>
        <TrailerField
          icon={trailer?.type?.icon2 || null}
          trailerType={trailer?.type?.name || "-"}
          to={`/dashboard/tms/fleet_management/trailers/${trailer.id}`}
        />
        <TextField label={trailer?.registration_number || "-"} />
        <TextField label={trailer?.volume_capacity || "-"} />
        <TextField label={trailer?.weight_capacity || "-"} />
        <TruckField max={40} truck={trailer.truck ? trailer.truck : null} />
        <MoreField>
          <div className='icon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='OrdersTableItemMore'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenEditModal(true);
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </MoreField>
      </RowField>

      <EditTrailersModal isOpen={openEditModal} isClose={setOpenEditModal} trailer={trailer} />
      <DeleteTrailersModal
        trailerId={trailer.id}
        isOpen={openDeleteModal}
        isClose={setOpenDeleteModal}
      />
    </Fragment>
  );
};

export default TrailersTableItem;
