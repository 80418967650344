import React, { Fragment, useState, useEffect } from "react";
import "./AddTrucksModal.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import * as amplitude from "@amplitude/analytics-browser";

import { createTruck, resetCreateTruck } from "./../../../../store/fleet/trucks/trucksSlice";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";
import DTruckBrandsSelect from "../../../Form/DSelect/DTruckBrandsSelect/DTruckBrandsSelect";
import DTruckModelsSelect from "../../../Form/DSelect/DTruckModelsSelect/DTruckModelsSelect";

const AddTrucksModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createTruckLoading, createTruckResult, createTruckError } = useSelector(
    (state) => state.trucks
  );

  const [manufacture, setManufacture] = useState(null);
  const [model, setModel] = useState(null);
  const [plateNumber, setPlateNumber] = useState("");
  const [vinNumber, setVinNumber] = useState("");

  useEffect(() => {
    setModel(null);
  }, [manufacture]);

  const resetState = () => {
    setManufacture(null);
    setModel(null);
    setPlateNumber("");
    setVinNumber("");
  };

  const submitHandler = () => {
    const data = {
      model_id: model && model.value.id,
      registration_number: plateNumber,
      passport_number: vinNumber,
    };
    dispatch(createTruck(data));
  };

  useEffect(() => {
    if (createTruckResult) {
      amplitude.track("TMS: Create Truck");
      dispatch(resetCreateTruck());
      resetState();
      isClose();
    }
  }, [dispatch, isClose, createTruckResult]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Create Truck")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiPlus />}
        status='success'
        onClick={() => submitHandler()}
        loading={createTruckLoading}
      >
        <div id='AddTrucksModal'>
          <div className='AddTrucksModalGroup'>
            <DTruckBrandsSelect
              value={manufacture}
              setValue={setManufacture}
              required
              error={createTruckError?.errors?.model_id?.[0] ?? null}
            />
            <DTruckModelsSelect
              value={model}
              setValue={setModel}
              required
              options={manufacture ? manufacture.value.models : []}
              disabled={!manufacture}
              error={createTruckError?.errors?.model_id?.[0] ?? null}
            />

            <DInputs
              id='truckRegistrationPlate'
              value={plateNumber}
              setValue={setPlateNumber}
              label={t("tms.modals.labels.Registration plate")}
              placeholder={t("tms.modals.placeholder.Enter registration plate")}
              error={createTruckError?.errors?.registration_number?.[0] ?? null}
              required
            />
            <DInputs
              id='truckVinNumber'
              label={t("tms.modals.labels.VIN number")}
              placeholder={t("tms.modals.placeholder.Enter VIN number")}
              value={vinNumber}
              setValue={setVinNumber}
              error={createTruckError?.errors?.passport_number?.[0] ?? null}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddTrucksModal;
