import React from "react";

import { useSelector } from "react-redux";

import DSelect from "../DSelect";

const DCurrencyCode = ({
  id,
  label,
  required,
  error,
  placeholder,
  value,
  setValue,
}) => {
  const { getCurrenciesLoading, getCurrenciesSelect } = useSelector(
    (state) => state.settings
  );

  return (
    <div id='DCurrencyCodeSelect' style={{ width: "100%" }}>
      <DSelect
        required={required}
        id={id}
        label={label}
        placeholder={placeholder}
        value={value}
        setValue={(state) => setValue(state)}
        error={error}
        options={getCurrenciesSelect}
        loading={getCurrenciesLoading}
        search={false}
      />
    </div>
  );
};

export default DCurrencyCode;
