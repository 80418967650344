import React from "react";

import { useTranslation } from "react-i18next";

import TableHeader from "../../../Form/TableFields/TableHeader/TableHeader";
import TrucksTableItem from "./TrucksTableItem/TrucksTableItem";
import TableWrapper from "../../../Form/TableFields/TableWrapper/TableWrapper";

const TrucksTable = ({ trucks }) => {
  const { t } = useTranslation();

  return (
    <TableWrapper
      emptyText={t("tms.tables.noInfo.no trucks")}
      total={trucks?.length}
    >
      <TableHeader>
        <th>{t("tms.tables.titles.Truck")}</th>
        <th>{t("tms.tables.titles.Pick up date")}</th>
        <th>{t("tms.tables.titles.Trailer")}</th>
        <th>{t("tms.tables.titles.Driver")}</th>
        <th colSpan='2'>{t("tms.tables.titles.Status")}</th>
      </TableHeader>

      <tbody>
        {trucks &&
          trucks.map((truck) => (
            <TrucksTableItem key={truck.id} truck={truck} />
          ))}
      </tbody>
    </TableWrapper>
  );
};

export default TrucksTable;
