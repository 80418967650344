import React, { useState } from "react";
import "./QuotationModal.scss";

import { useTranslation } from "react-i18next";
import { FiX } from "react-icons/fi";
import { BsChatLeftTextFill } from "react-icons/bs";

import { useOrderPaymentSelectSubOptions } from "../../../../helper/useOrderPaymentSelectSubOptions";
import { useMoney } from "../../../../helper/useMoney";

import DOverlay from "../../../Modal/DOverlay/DOverlay";
import DButton from "../../../Form/DButtons/DButton/DButton";
import ShipperOfferRejectModal from "../ShipperOfferRejectModal/ShipperOfferRejectModal";
import ShipperOfferConfirmModal from "../ShipperOfferConfirmModal/ShipperOfferConfirmModal";

const QuotationModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const paymentSubOptions = useOrderPaymentSelectSubOptions();

  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const orderRevenue = useMoney(order?.revenue || 0, order?.revenue_currency?.code || "$");

  const paymentAmount = useMoney(
    order?.client_payment?.amount || 0,
    order?.client_payment?.currency?.symbol || "$"
  );

  const paymentOptionType = paymentSubOptions?.find((option) => {
    return option?.value?.id === order?.client_payment?.option_type;
  });

  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <div id='QuotationModal'>
        <div className='header'>
          <div className='title'>
            <div className='titleIcon'>
              <BsChatLeftTextFill />
            </div>
            <h2>{t("shipper.modal.Offer from cargon")}</h2>
          </div>
          <div className='closeIcon' onClick={isClose}>
            <FiX />
          </div>
        </div>
        <div className='content'>
          <div className='paymentContainer'>
            {order?.client_payment && (
              <div className='payment'>
                <h3>{order?.client_payment?.type?.name || ""}</h3>
                <div className='paymentOptions'>
                  {order?.client_payment?.amount && (
                    <div className='paymentOption'>
                      <div className='title'>{t("shipper.modal.Pre payment")}</div>
                      <div className='value'>{paymentAmount}</div>
                    </div>
                  )}
                  {paymentOptionType && (
                    <div className='paymentOption'>
                      <div className='title'>{t("shipper.modal.Post payment condition")}</div>
                      <div className='value'>{paymentOptionType?.label || ""}</div>
                    </div>
                  )}
                  {order?.client_payment?.days && (
                    <div className='paymentOption'>
                      <div className='title'>{t("shipper.modal.Consignation")}</div>
                      <div className='value'>
                        {order?.client_payment?.days || ""} {t("shipper.modal.days")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className='price'>
              <h3>{t("shipper.modal.Price")}</h3>
              <div className='priceValue'>{orderRevenue}</div>
            </div>
          </div>

          <div className='comment'>
            <div className='title'>{t("shipper.modal.Comment")}</div>
            <div className='text'>
              {t(
                "shipper.desc.While we strive to meet all scheduled pickup times, please aware that the pickup date might be delayed by one day due potential scheduling adjustments. We'll keep you updated every step of the way to ensure a smooth process"
              )}
            </div>
          </div>
        </div>
        <div className='footer'>
          <div className='refuseButton'>
            <DButton title={t("shipper.buttons.Reject")} onClick={() => setOpenRejectModal(true)} />
          </div>
          <DButton
            title={`${t("shipper.buttons.Confirm")} ${orderRevenue}`}
            onClick={() => setOpenConfirmModal(true)}
          />
        </div>
      </div>

      {openRejectModal && (
        <ShipperOfferRejectModal
          isOpen={openRejectModal}
          isClose={() => setOpenRejectModal(false)}
          order={order}
        />
      )}
      {openConfirmModal && (
        <ShipperOfferConfirmModal
          isOpen={openConfirmModal}
          isClose={() => setOpenConfirmModal(false)}
          order={order}
        />
      )}
    </DOverlay>
  );
};

export default QuotationModal;
