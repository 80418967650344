import React from "react";
import "./WebPlatformCarrier.scss";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import WebPlatformCarrierItem from "./WebPlatformCarrierItem/WebPlatformCarrierItem";

const WebPlatformCarrier = () => {
  const { t } = useTranslation();

  return (
    <section className='WebPlatformCarrier' id='WebPlatformCarrier'>
      <WebPlatformCarrierItem
        mainTitle={t(
          "landing.titles.landingMainTitles.Transport management system"
        )}
        minTitle={parse(
          t(
            "landing.titles.landingMainTitles.A web platform for <span>Fleet owners</span> and <span>Forwarders</span>"
          )
        )}
        desc={t(
          "landing.titles.landingMainTitlesDesc.Industry-leading order management and load discovery platform. Forever FREE."
        )}
        cover={"/assets/images/static/cover41.png"}
        scrollItem={"TmsSolutionCarrier"}
      />
      <div
        className='animation'
        data-aos='fade-left'
        data-aos-duration='1500'
        data-aos-once='true'
      >
        <WebPlatformCarrierItem
          mainTitle={t(
            "landing.titles.landingMainTitles.Load discovery solution"
          )}
          minTitle={parse(
            t(
              "landing.titles.landingMainTitles.A mobile app for <span>Independent truck owners</span>"
            )
          )}
          desc={t(
            "landing.titles.landingMainTitlesDesc.Mobile job discovery platform for independent truck owners."
          )}
          cover={"/assets/images/static/cover42.png"}
          scrollItem={"ForWorkflowCarrier"}
          withBackground
        />
      </div>
    </section>
  );
};

export default WebPlatformCarrier;
