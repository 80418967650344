import React, { useEffect, useRef } from "react";
import "./ProfilePicture.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  resetUploadProfileAvatar,
  uploadProfileAvatar,
} from "../../../../store/user/fileUpload/uploadSlice";

import { MdModeEditOutline } from "react-icons/md";
import DErrorText from "../../../Form/DErrorText/DErrorText";

const ProfilePicture = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { uploadProfileAvatarResult, uploadProfileAvatarError } = useSelector(
    (state) => state.upload
  );

  const fileUploadButtonRef = useRef(null);

  const handleProfileAvatarSelect = (e) => {
    const data = {
      file: e?.target?.files[0],
    };

    dispatch(uploadProfileAvatar(data));
  };

  useEffect(() => {
    if (uploadProfileAvatarResult) {
      dispatch(resetUploadProfileAvatar());
    }
  }, [dispatch, uploadProfileAvatarResult]);

  return (
    <div id='profilePicture'>
      <div className='profilePictureAvatar' style={{ backgroundImage: `url(${user?.avatar})` }}>
        <div className='editPictureAvatar' onClick={() => fileUploadButtonRef.current.click()}>
          <input
            type='file'
            onChange={handleProfileAvatarSelect}
            accept='image/*'
            ref={fileUploadButtonRef}
          />
          <div className='icon'>
            <MdModeEditOutline />
          </div>
        </div>
      </div>
      <div className='uploadError'>
        <DErrorText
          error={
            uploadProfileAvatarError?.errors?.file?.[0] ||
            (uploadProfileAvatarError &&
              uploadProfileAvatarError === "Network Error" &&
              t("tms.desc.Reduce file size"))
          }
        />
      </div>
    </div>
  );
};

export default ProfilePicture;
