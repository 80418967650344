import React from "react";
import "./Header.scss";

import { BiFirstPage } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";

import { setOpenSidebar } from "../../../../store/additional/userUI/userUISlice";

import LogoContainer from "../LogoContainer/LogoContainer";
import NotificationsButton from "../NotificationsButton/NotificationsButton";

const Header = () => {
  const dispatch = useDispatch();
  const { openSidebar } = useSelector((state) => state.userUI);

  return (
    <header id='DHeader' className={openSidebar ? "max" : "min"}>
      <LogoContainer />
      <NotificationsButton open={openSidebar} />
      <div
        className='closeIcon'
        onClick={() => {
          dispatch(setOpenSidebar());
        }}
      >
        <BiFirstPage />
      </div>
    </header>
  );
};

export default Header;
