import React, { Fragment } from "react";
import "./TruckField.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Character from "../../../UI/Character/Character";

const TruckField = ({ truck, to, max, icon }) => {
  const { t } = useTranslation();

  const title =
    truck &&
    `${truck.model && truck.model.brand.name} - 
    ${truck.model && truck.model.name}`;
  return (
    <Fragment>
      {to ? (
        <td id='TruckField' className='link'>
          <Link to={to} className='TruckFieldContainer'>
            <div
              className='cover'
              style={{
                backgroundImage: icon
                  ? `url(${icon})`
                  : `url(${`/assets/images/dashboard/cover8.svg`})`,
              }}
            ></div>
            <div>
              <h3>
                <Character title={title} max={max ? max : 40} />
              </h3>
              <p>{truck.registration_number && truck.registration_number}</p>
            </div>
          </Link>
        </td>
      ) : (
        <td id='TruckField'>
          {truck ? (
            <div className='TruckFieldContainer'>
              <div
                className='cover'
                style={{
                  backgroundImage: `url(${`/assets/images/dashboard/cover8.svg`})`,
                }}
              ></div>
              <div>
                <h3>
                  <Character title={title} max={max ? max : 40} />
                </h3>
                <p>{truck.registration_number && truck.registration_number}</p>
              </div>
            </div>
          ) : (
            <div className='NoTruck'>
              {t("tms.tables.noInfo.no link to truck")}
            </div>
          )}
        </td>
      )}
    </Fragment>
  );
};

export default TruckField;
