import React from "react";
import { Routes, Route } from "react-router-dom";

import { Dashboard } from "../pages/Dashboard/Carrier/index";
import DelayedRedirect from "../componentsAdditional/DelayedRedirect";

const Carrier = () => {
  return (
    <Routes>
      <Route path='/dashboard/carrier/home' element={<Dashboard />} />
      <Route
        path='*'
        element={<DelayedRedirect to='/dashboard/carrier/home' delay={0} />}
      />
    </Routes>
  );
};

export default Carrier;
