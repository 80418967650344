import React from "react";
import "./CreateOrderSectionSecondaryTitle.scss";

const CreateOrderSectionSecondaryTitle = ({ title }) => {
  return (
    <div id='CreateOrderSectionSecondaryTitle'>
      <h5>{title}</h5>
      <div className='line'></div>
    </div>
  );
};

export default CreateOrderSectionSecondaryTitle;
