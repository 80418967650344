import React from "react";
import "./JoinUs.scss";

import { useTranslation } from "react-i18next";

import HomeTitle from "../../Components/HomeTitle/HomeTitle";
import LinkBackground from "./../../Components/Links/LinkBackground/LinkBackground";

const JoinUs = () => {
  const { t } = useTranslation();

  return (
    <section
      id='JoinUs'
      className='JoinUs'
      style={{
        backgroundImage: 'url("/assets/images/static/cover10.png")',
      }}
    >
      <div className='blar'>
        <div className='wrapper'>
          <div className='container'>
            <HomeTitle
              description={t(
                "landing.titles.landingMainTitlesDesc.We're here to help you make the most of your logistics."
              )}
            >
              {t(
                "landing.titles.landingMainTitles.Sign up and get started with Cargon"
              )}
            </HomeTitle>
            <LinkBackground
              title={t("landing.form.button.Join us now")}
              to='/register'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinUs;
