import React from "react";
import "./SignUpShipper.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import SignUpShipperForm from "./../../../../componentsLanding/Auth&Password/SignUpForms/SignUpShipperForm/SignUpShipperForm";

const SignUpShipper = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Sign Up shipper")}>
      <section id='SignUpShipper'>
        <AuthCover url='/assets/images/static/cover12.jpg' />
        <SignUpShipperForm />
      </section>
    </Wrapper>
  );
};

export default SignUpShipper;
