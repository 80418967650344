import React from "react";

import { useTranslation } from "react-i18next";

import MaintenanceTableItem from "./MaintenanceTableItem/MaintenanceTableItem";
import TableHeader from "../../../Form/TableFields/TableHeader/TableHeader";
import TableWrapper from "../../../Form/TableFields/TableWrapper/TableWrapper";

const MaintenanceTable = ({ maintenances }) => {
  const { t } = useTranslation();
  return (
    <TableWrapper
      emptyText={t("tms.tables.noInfo.no maintenance")}
      total={maintenances?.length}
    >
      <TableHeader>
        <th>{t("tms.tables.titles.Vehicle")}</th>
        <th>{t("tms.tables.titles.Service")}</th>
        <th>{t("tms.tables.titles.Service provider")}</th>
        <th>{t("tms.tables.titles.Date")}</th>
        <th colSpan='2'>{t("tms.tables.titles.Cost (Include VAT)")}</th>
      </TableHeader>
      <tbody>
        {maintenances &&
          maintenances.map((item) => (
            <MaintenanceTableItem key={item.id} item={item} />
          ))}
      </tbody>
    </TableWrapper>
  );
};

export default MaintenanceTable;
