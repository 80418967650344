import React, { useRef, useEffect } from "react";
import "./ShipperCardSelectDate.scss";

import moment from "moment";

import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const ShipperCardSelectDate = ({
  date,
  setDate,
  placeholder,
  children,
  isOpen,
  setIsOpen,
}) => {
  const calendarRef = useRef(null);

  const handlePrevious = () => {
    setDate(moment(date)?.subtract(1, "month")?.toDate());
  };

  const handleNext = () => {
    if (moment(date)?.add(1, "month") <= moment()) {
      setDate(moment(date)?.add(1, "month")?.toDate());
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isOpen &&
        calendarRef.current &&
        !calendarRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    setTimeout(() => {
      document.addEventListener("mouseup", handleOutsideClick);
    }, 0);

    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [isOpen, setIsOpen]);

  return (
    <div className='ShipperCardSelectDate' ref={calendarRef}>
      <div onClick={handlePrevious} className='nav-button'>
        <IoIosArrowBack />
      </div>
      <span
        className='month-label'
        onClick={() => {
          setIsOpen((prevState) => !prevState);
        }}
      >
        {placeholder}
      </span>
      <div
        onClick={handleNext}
        className={`nav-button ${
          moment(date).add(1, "month") > moment() ? "disabled" : ""
        }`}
      >
        <IoIosArrowForward />
      </div>
      {isOpen && <div className='calendar-popup'>{children}</div>}
    </div>
  );
};

export default ShipperCardSelectDate;
