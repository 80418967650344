import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import partnerService from "./partnerService";

const initialState = {
  getPartnersLoading: true,
  getPartnersResult: null,
  getPartnersMeta: null,
  getPartnersError: null,

  getPartnerLoading: true,
  getPartnerResult: null,
  getPartnerError: null,

  createPartnerLoading: false,
  createPartnerResult: null,
  createPartnerError: null,

  updatePartnerLoading: false,
  updatePartnerResult: null,
  updatePartnerError: null,

  deletePartnerLoading: false,
  deletePartnerResult: null,
  deletePartnerError: null,

  ratePartnerLoading: false,
  ratePartnerResult: null,
  ratePartnerError: null,
};

export const getPartners = createAsyncThunk("partner/getPartners", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await partnerService.getPartners(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getPartner = createAsyncThunk("partner/getPartner", async (id, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await partnerService.getPartner(ISO, TOKEN, id);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createPartner = createAsyncThunk("partner/createPartner", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await partnerService.createPartner(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updatePartner = createAsyncThunk("partner/updatePartner", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await partnerService.updatePartner(ISO, TOKEN, data.id, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deletePartner = createAsyncThunk("partner/deletePartner", async (id, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await partnerService.deletePartner(ISO, TOKEN, id);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const ratePartner = createAsyncThunk("partner/ratePartner", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await partnerService.ratePartner(ISO, TOKEN, data.partnerId, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    resetGetPartners: (state) => {
      state.getPartnersLoading = true;
      state.getPartnersResult = null;
      state.getPartnersMeta = null;
      state.getPartnersError = null;
    },
    resetGetPartner: (state) => {
      state.getPartnerLoading = true;
      state.getPartnerResult = null;
      state.getPartnerError = null;
    },
    resetCreatePartner: (state) => {
      state.createPartnerLoading = false;
      state.createPartnerResult = null;
      state.createPartnerError = null;
    },
    resetUpdatePartner: (state) => {
      state.updatePartnerLoading = false;
      state.updatePartnerResult = null;
      state.updatePartnerError = null;
    },
    resetDeletePartner: (state) => {
      state.deletePartnerLoading = false;
      state.deletePartnerResult = null;
      state.deletePartnerError = null;
    },
    resetRatePartner: (state) => {
      state.ratePartnerLoading = false;
      state.ratePartnerResult = null;
      state.ratePartnerError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPartners.pending, (state) => {
        state.getPartnersLoading = true;
        state.getPartnersResult = null;
        state.getPartnersMeta = null;
        state.getPartnersError = null;
      })
      .addCase(getPartners.fulfilled, (state, action) => {
        state.getPartnersLoading = false;
        state.getPartnersResult = action.payload.data;
        state.getPartnersMeta = action.payload.meta;
        state.getPartnersError = null;
      })
      .addCase(getPartners.rejected, (state, action) => {
        state.getPartnersLoading = false;
        state.getPartnersResult = null;
        state.getPartnersMeta = null;
        state.getPartnersError = action.payload;
      })

      .addCase(getPartner.pending, (state) => {
        state.getPartnerLoading = true;
        state.getPartnerResult = null;
        state.getPartnerError = null;
      })
      .addCase(getPartner.fulfilled, (state, action) => {
        state.getPartnerLoading = false;
        state.getPartnerResult = action.payload;
        state.getPartnerError = null;
      })
      .addCase(getPartner.rejected, (state, action) => {
        state.getPartnerLoading = false;
        state.getPartnerResult = null;
        state.getPartnerError = action.payload;
      })

      .addCase(createPartner.pending, (state) => {
        state.createPartnerLoading = true;
        state.createPartnerResult = null;
        state.createPartnerError = null;
      })
      .addCase(createPartner.fulfilled, (state, action) => {
        state.createPartnerLoading = false;
        state.createPartnerResult = action.payload;
        state.createPartnerError = null;
      })
      .addCase(createPartner.rejected, (state, action) => {
        state.createPartnerLoading = false;
        state.createPartnerResult = null;
        state.createPartnerError = action.payload;
      })

      .addCase(updatePartner.pending, (state) => {
        state.updatePartnerLoading = true;
        state.updatePartnerResult = null;
        state.updatePartnerError = null;
      })
      .addCase(updatePartner.fulfilled, (state, action) => {
        state.updatePartnerLoading = false;
        state.updatePartnerResult = action.payload;
        state.updatePartnerError = null;
      })
      .addCase(updatePartner.rejected, (state, action) => {
        state.updatePartnerLoading = false;
        state.updatePartnerResult = null;
        state.updatePartnerError = action.payload;
      })

      .addCase(deletePartner.pending, (state) => {
        state.deletePartnerLoading = true;
        state.deletePartnerResult = null;
        state.deletePartnerError = null;
      })
      .addCase(deletePartner.fulfilled, (state, action) => {
        state.deletePartnerLoading = false;
        state.deletePartnerResult = action.payload;
        state.deletePartnerError = null;
      })
      .addCase(deletePartner.rejected, (state, action) => {
        state.deletePartnerLoading = false;
        state.deletePartnerResult = null;
        state.deletePartnerError = action.payload;
      })

      .addCase(ratePartner.pending, (state) => {
        state.ratePartnerLoading = true;
        state.ratePartnerResult = null;
        state.ratePartnerError = null;
      })
      .addCase(ratePartner.fulfilled, (state, action) => {
        state.ratePartnerLoading = false;
        state.ratePartnerResult = action.payload;
        state.ratePartnerError = null;
      })
      .addCase(ratePartner.rejected, (state, action) => {
        state.ratePartnerLoading = false;
        state.ratePartnerResult = null;
        state.ratePartnerError = action.payload;
      });
  },
});

export const {
  resetGetPartners,
  resetGetPartner,
  resetCreatePartner,
  resetUpdatePartner,
  resetDeletePartner,
  resetRatePartner,
} = partnerSlice.actions;
export default partnerSlice.reducer;
