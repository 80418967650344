import React, { Fragment } from "react";
import "./LinkOffTrailerModal.scss";

import { useTranslation } from "react-i18next";
import { MdLinkOff } from "react-icons/md";

import DModal from "../../../Modal/DModal/DModal";

const LinkOffTrailerModal = ({ isOpen, isClose, onClick, loading }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Unlink Trailer")}
        submitText={t("tms.modals.buttons.Unlink")}
        icon={<MdLinkOff />}
        status='info'
        onClick={onClick}
        loading={loading}
      >
        <div id='LinkOffTrailerModal'>
          <p>
            {t("tms.modals.desc.Are you sure you want to unlink this trailer?")}
          </p>
        </div>
      </DModal>
    </Fragment>
  );
};

export default LinkOffTrailerModal;
