import _request from "../../_request";

// get countries
const getCountries = async (ISO) => {
  const config = {
    url: "/branch",
    ISO,
  };
  return _request(config);
};

const getAllCountries = async (ISO) => {
  const config = {
    url: "/country",
    ISO,
  };
  return _request(config);
};

// get phone Indexes
const getPhoneIndexes = async (ISO) => {
  const config = {
    url: "/phone-indexes",
    ISO,
  };
  return _request(config);
};

// get currency
const getCurrencies = async (ISO, TOKEN, BranchId) => {
  const config = {
    url: `/currency/${BranchId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

// set currency
const setCurrency = async (ISO, TOKEN, data) => {
  const config = {
    url: "/profile",
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

// get Geolocation Country
const getGeolocationCountry = async () => {
  const config = {
    url: "/geolocation/country",
  };
  return _request(config);
};

const otherService = {
  getCountries,
  getAllCountries,
  getPhoneIndexes,
  getCurrencies,
  setCurrency,
  getGeolocationCountry,
};

export default otherService;
