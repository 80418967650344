import React, { useState } from "react";
import "./RememberMe.scss";

import { useTranslation } from "react-i18next";

import { FiCheck } from "react-icons/fi";

const RememberMe = () => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);
  return (
    <div
      id='RememberMe'
      className={checked ? "checked" : ""}
      onClick={() => setChecked((state) => !state)}
    >
      <div className='icon'>{checked && <FiCheck />}</div>
      <p>{t("landing.auth.titles.Remember me")}</p>
    </div>
  );
};

export default RememberMe;
