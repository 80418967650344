import React from "react";
import "./FinancialTable.scss";

import { useTranslation } from "react-i18next";

import TableHeader from "../../Form/TableFields/TableHeader/TableHeader";
import TableWrapper from "../../Form/TableFields/TableWrapper/TableWrapper";
import FinancialTableItem from "./FinancialTableItem/FinancialTableItem";

const FinancialTable = ({ items, page, setPage, pageCount, total }) => {
  const { t } = useTranslation();

  return (
    <div id='ShipperFinancialTable'>
      {items && (
        <TableWrapper
          total={total}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          emptyText={t("tms.tables.noInfo.No orders")}
        >
          <TableHeader>
            <th>{t("shipper.table.labels.Related order")}</th>
            <th>{t("shipper.table.labels.Shipping type")}</th>
            <th>{t("shipper.table.labels.Route")}</th>
            <th>{t("shipper.table.labels.Invoice amount")}</th>
            <th style={{ textAlign: "center" }}>{t("shipper.table.labels.Payment status")}</th>
            <th style={{ textAlign: "center" }}>{t("shipper.table.labels.Payment deadline")}</th>
            <th style={{ textAlign: "center" }}>{t("shipper.table.labels.Invoice")}</th>
          </TableHeader>
          <tbody>
            {items.map((item, index) => (
              <FinancialTableItem key={item.id} item={item} index={index} />
            ))}
          </tbody>
        </TableWrapper>
      )}
    </div>
  );
};

export default FinancialTable;
