import "./ProfileSettingsItem.scss";

const ProfileSettingsItem = ({ title, text, button, danger }) => {
  return (
    <div id='profileSettingsItem'>
      <div className='info'>
        <div
          style={{ color: danger ? "#da1414" : "#2B353A" }}
          className='infoTitle'
        >
          {title}
        </div>
        <div className='infoText'>{text}</div>
      </div>
      <div className='button'>{button}</div>
    </div>
  );
};

export default ProfileSettingsItem;
