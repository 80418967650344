import React from "react";
import "./OceanProgressBar.scss";

import { useSelector } from "react-redux";

import { RiShipLine } from "react-icons/ri";

const OceanProgressBar = ({ order }) => {
  const { getOrderTrackingResult, getOrderTrackingLoading } = useSelector(
    (state) => state.trackerManager
  );

  const DATA = getOrderTrackingResult?.locations;

  const findDeparture = DATA?.find((i) => i.actual && i.transport_event_type_code === "DEPA");

  const progressWidth =
    (100 / (DATA?.length - 1)) * DATA?.findIndex((i) => i.actual) +
    (findDeparture ? 100 / (DATA?.length - 1) / 2 : 0);

  const A = DATA?.filter((i) => i?.type === "LOCALITY" && i?.direction === "A");
  const P = DATA?.filter((i) => i?.type === "PORT");
  const B = DATA?.filter((i) => i?.type === "LOCALITY" && i?.direction === "B");

  return getOrderTrackingLoading ? (
    <div></div>
  ) : (
    <div id='OceanProgressBar'>
      {DATA?.map((item, index, arr) => {
        const leftValue = (100 / (arr?.length - 1)) * index;
        const isFirst = index === 0;
        const isLast = index === arr?.length - 1;
        return (
          <div
            key={index}
            style={{
              left: `${isFirst ? leftValue + 1 : isLast ? leftValue - 1 : leftValue}%`,
            }}
            className='dotContainer'
          >
            <div className='dot' />
          </div>
        );
      })}
      <div className='progressBar' />
      <div
        className='progressLine'
        style={{ width: order?.statusClient?.id === 4 ? "100%" : `${progressWidth}%` }}
      />
      {order?.statusClient?.id !== 4 && progressWidth >= 0 && progressWidth <= 100 && (
        <div
          style={{
            left: `${progressWidth}%`,
          }}
          className='progressIconContainer'
        >
          <div className='progressIcon'>
            <div className='icon'>
              <RiShipLine />
            </div>
          </div>
        </div>
      )}
      <div style={{ display: "flex", gap: "4px" }}>
        {A?.length > 0 && (
          <div style={{ width: `${(100 / (DATA?.length - 1)) * A?.length}%` }}>
            <div className='shippingTypeInfoContainer'>
              <h6 className='shippingTypeInfo'>LAND</h6>
            </div>
          </div>
        )}
        {P?.length > 0 && (
          <div style={{ width: `${(100 / (DATA?.length - 1)) * (P?.length - 1)}%` }}>
            <div className='shippingTypeInfoContainer'>
              <h6 className='shippingTypeInfo'>PORT</h6>
            </div>
          </div>
        )}
        {B?.length > 0 && (
          <div style={{ width: `${(100 / (DATA?.length - 1)) * B?.length}%` }}>
            <div className='shippingTypeInfoContainer'>
              <h6 className='shippingTypeInfo'>LAND</h6>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default OceanProgressBar;
