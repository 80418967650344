import React, { Fragment } from "react";
import "./AdrClassDetails.scss";

import { useTranslation } from "react-i18next";
import { IoWarning } from "react-icons/io5";

const AdrClassDetails = ({ order }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {order?.cargos[0]?.adr > 0 && (
        <div id='AdrClassDetails'>
          <div className='AdrClassDetailsHeader'>
            <div className='icon'>
              <IoWarning />
            </div>
            <h3>{t("tms.titles.ADR")}</h3>
          </div>
          <h4>
            {t("tms.titles.UN Number")}:{" "}
            <span>{order?.cargos[0]?.un_number}</span>
          </h4>
          <h4>
            {t("tms.titles.ADR Class")}:{" "}
            <span>{order?.cargos[0]?.adr_class}</span>
          </h4>
        </div>
      )}
    </Fragment>
  );
};

export default AdrClassDetails;
