import React from "react";
import "./FormFooter.scss";

import { useTranslation } from "react-i18next";
import DButton from "./../../../../../Form/DButtons/DButton/DButton";

const FormFooter = ({ submit, loading }) => {
  const { t } = useTranslation();
  return (
    <div id='InvoiceFormFooter'>
      <DButton
        loading={loading}
        title={t("tms.invoice.Generate Invoice")}
        onClick={() => {
          submit();
        }}
      />
    </div>
  );
};

export default FormFooter;
