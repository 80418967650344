import React, { Fragment, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { FiPlus } from "react-icons/fi";

import {
  setActiveOrderId,
  setActiveOrderIndex,
  getShipperAcceptedOrders,
  resetGetShipperAcceptedOrders,
} from "../../../../store/orders/shipperAccepted/shipperAcceptedSlice";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import ListView from "../../../../componentsDashboard/ShipperAcceptedOrders/ListView/ListView";
import SideView from "../../../../componentsDashboard/ShipperAcceptedOrders/SideView/SideView";
import LoadingPage from "./../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import EmptyList from "./../../../../componentsDashboard/UI/EmptyList/EmptyList";
import BookModal from "../../../../componentsDashboard/ShipperHeader/Components/BookShipment/BookModal/BookModal";
import AddVatModal from "../../../../componentsDashboard/LoadBoards/Modals/AddVatModal/AddVatModal";

const AcceptedOrders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const {
    shipperAcceptedOrderView,
    activeOrderId,
    activeOrderIndex,
    getShipperAcceptedOrdersResult,
  } = useSelector((state) => state.shipperAccepted);

  const { getShipperSummaryLoading, getShipperSummaryResult } = useSelector(
    (state) => state.summary
  );
  const { user } = useSelector((state) => state.auth);

  const hasVat = user?.company?.legal_id;

  const [showVatModal, setShowVatModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [status, setStatus] = useState(null);
  const [shippingType, setShippingType] = useState(null);
  const [dateRange, setDateRange] = useState({});
  const [route, setRoute] = useState({});
  const [trailerType, setTrailerType] = useState(null);
  const [productCategory, setProductCategory] = useState(null);

  const clearFilters = () => {
    if (keyword) setKeyword("");
    if (shippingType) setShippingType(null);
    if (Object.keys(dateRange).length > 0) setDateRange({});
    if (Object.keys(route).length > 0) setRoute({});
    if (trailerType) setTrailerType(null);
    if (status) setStatus(null);
    if (productCategory) setProductCategory(null);
  };

  useEffect(() => {
    const data = {
      page,
      keyword,
      pickup_date_min:
        Object.keys(dateRange).length > 0 && dateRange?.startDate
          ? moment(dateRange.startDate).format("YYYY/MM/DD")
          : null,
      pickup_date_max:
        Object.keys(dateRange).length > 0 && dateRange?.endDate
          ? moment(dateRange.endDate).format("YYYY/MM/DD")
          : null,
      pickup_address: route?.fromCountry?.label || null,
      dropoff_address: route?.toCountry?.label || null,
      trailer_type_id: trailerType?.value?.id || null,
      cargo_type_id: productCategory?.value?.id || null,
      type_groups: shippingType ? [shippingType] : null,
      client_statuses: status ? [status] : null,
    };
    dispatch(getShipperAcceptedOrders(data));
  }, [
    dispatch,
    page,
    keyword,
    dateRange,
    trailerType,
    route,
    productCategory,
    status,
    shippingType,
  ]);

  // clear state function
  useEffect(() => {
    return () => {
      dispatch(resetGetShipperAcceptedOrders());
      setPage(1);
    };
  }, [
    dispatch,
    pathname,
    keyword,
    dateRange,
    trailerType,
    route,
    productCategory,
    status,
    shippingType,
  ]);

  useEffect(() => {
    if (
      !activeOrderId &&
      getShipperAcceptedOrdersResult &&
      getShipperAcceptedOrdersResult.length > 0
    ) {
      dispatch(setActiveOrderId(getShipperAcceptedOrdersResult[0].id));
    }
  }, [dispatch, getShipperAcceptedOrdersResult, activeOrderId]);

  return (
    <Wrapper
      documentTitle={`${t("shipper.titles.Active shipments")} ${
        activeOrderId && !shipperAcceptedOrderView ? `(ID: ${activeOrderId})` : ""
      }`}
    >
      {getShipperSummaryLoading && <LoadingPage />}
      {getShipperSummaryResult?.orders_count === 0 ? (
        <EmptyList
          image={"/assets/images/dashboard/cover1.svg"}
          title={t("shipper.titles.No bookings")}
          description={t("shipper.desc.Add booking and take full control of your orders")}
          onClick={() => (hasVat ? setShowCreateModal(true) : setShowVatModal(true))}
          buttonText={t("shipper.buttons.New request")}
          icon={<FiPlus />}
        />
      ) : (
        <Fragment>
          {shipperAcceptedOrderView ? (
            <ListView
              page={page}
              setPage={setPage}
              activeOrderId={activeOrderId}
              setActiveOrderId={(value) => dispatch(setActiveOrderId(value))}
              activeOrderIndex={activeOrderIndex}
              setActiveOrderIndex={(value) => dispatch(setActiveOrderIndex(value))}
              // filters
              keyword={keyword}
              setKeyword={setKeyword}
              status={status}
              setStatus={setStatus}
              shippingType={shippingType}
              setShippingType={setShippingType}
              dateRange={dateRange}
              setDateRange={setDateRange}
              route={route}
              setRoute={setRoute}
              trailerType={trailerType}
              setTrailerType={setTrailerType}
              productCategory={productCategory}
              setProductCategory={setProductCategory}
              onClear={clearFilters}
            />
          ) : (
            <SideView
              page={page}
              setPage={setPage}
              activeOrderId={activeOrderId}
              setActiveOrderId={(value) => dispatch(setActiveOrderId(value))}
              activeOrderIndex={activeOrderIndex}
              setActiveOrderIndex={(value) => dispatch(setActiveOrderIndex(value))}
              // filters
              keyword={keyword}
              setKeyword={setKeyword}
              status={status}
              setStatus={setStatus}
              shippingType={shippingType}
              setShippingType={setShippingType}
              dateRange={dateRange}
              setDateRange={setDateRange}
              route={route}
              setRoute={setRoute}
              trailerType={trailerType}
              setTrailerType={setTrailerType}
              productCategory={productCategory}
              setProductCategory={setProductCategory}
              onClear={clearFilters}
            />
          )}
        </Fragment>
      )}

      {showCreateModal && (
        <BookModal isOpen={showCreateModal} isClose={() => setShowCreateModal(false)} />
      )}
      {showVatModal && <AddVatModal isOpen={showVatModal} isClose={() => setShowVatModal(false)} />}
    </Wrapper>
  );
};

export default AcceptedOrders;
