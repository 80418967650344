import React, { Fragment } from "react";
import "./UpdateItem.scss";

import { useTranslation } from "react-i18next";

const UpdateItem = ({ order }) => {
  const { t } = useTranslation();

  const updateDate = order?.last_shipment_status_update?.created_at || null;
  const updateName =
    order?.last_shipment_status_update?.status?.name || t("shipper.forms.content.No message");
  const location = order?.last_shipment_status_update?.location?.formatted_address || null;

  return (
    <Fragment>
      {order?.last_shipment_status_update && (
        <div id='ShipperTrackingSectionMessage'>
          <div className='title'>
            {updateName && <h2>{updateName}</h2>}
            {order?.statusClient && (
              <div
                className='status'
                style={{ background: order?.statusClient?.color2 || "#b0e8d1" }}
              >
                <h6 style={{ color: order?.statusClient?.color1 || "#b0e8d1" }}>
                  {order?.statusClient?.name || ""}
                </h6>
              </div>
            )}
          </div>
          {location && (
            <h4>
              {t("shipper.forms.content.Location")}: <span>{location}</span>
            </h4>
          )}
          {updateDate && <h5>{updateDate}</h5>}
        </div>
      )}
    </Fragment>
  );
};

export default UpdateItem;
