import _request from './../../_request';

// get languages
const getLanguages = async () => {
  const config = {
    url: '/locales',
  };
  return _request(config);
};

const langService = {
  getLanguages,
};

export default langService;
