import React from "react";
import "./OrderInformationDetails.scss";
import { useTranslation } from "react-i18next";

import { useFormatedDistance } from "../../../helper/useFormatedDistance";

import { FaRegClock } from "react-icons/fa6";

import {
  MdCalendarToday,
  MdLocationPin,
  MdLocationSearching,
  MdInfo,
  MdLocalShipping,
} from "react-icons/md";

import Character from "../../UI/Character/Character";
import ProductInformationDetails from "./ProductInformationDetails/ProductInformationDetails";
import OrderComment from "./OrderComment/OrderComment";

const OrderInformationDetails = ({ order }) => {
  const { t } = useTranslation();
  const locations = order?.cargos[0]?.locations || [];
  const pickUpDate = order?.cargos[0]?.pickup_date || "";
  const dropOffDate = order?.cargos[0]?.dropoff_date || "";
  const directionA = locations?.filter((location) => {
    return location.direction === "A";
  });
  const directionB = locations?.filter((location) => {
    return location.direction === "B";
  });
  const pickUpTimeRange = order?.cargos[0]?.pickup_time_range
    ? order?.cargos[0]?.pickup_time_range?.start + "-" + order?.cargos[0]?.pickup_time_range?.end
    : "";
  const dropOffTimeRange = order?.cargos[0]?.dropoff_time_range
    ? order?.cargos[0]?.dropoff_time_range?.start + "-" + order?.cargos[0]?.dropoff_time_range?.end
    : "";

  const AddressDetailsItem = ({ content, icon, from }) => {
    return (
      <div className='AddressDetailsItem'>
        <div className={from ? "icon from" : "icon to"}>{icon}</div>
        <div className='content'>{content ? <Character title={content} max={45} /> : "-"}</div>
      </div>
    );
  };

  return (
    <div id='OrderInformationDetails'>
      <div className='titleContainer'>
        <div className='titleContainerLeft'>
          <div className='icon'>
            <MdInfo />
          </div>
          <div className='title'>
            <h1>{t("tms.titles.Order information")}</h1>
          </div>
        </div>
        {order.distance ? (
          <div className='titleContainerRight'>
            {t("tms.titles.Distance")}:{" "}
            <span>
              {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useFormatedDistance(order.distance, t("tms.desc.KM"))
              }
            </span>
          </div>
        ) : null}
      </div>
      <div className='contentContainer'>
        <div>
          <div className='shippingType'>
            <div className='icon'>
              <MdLocalShipping />
            </div>
            <div>{order.type?.name}</div>
          </div>
          <div className='contentContainerLeft'>
            <div className='from'>
              <div className='title'>
                <h3>{t("tms.desc.From")}</h3>
                {pickUpDate && (
                  <div className='timeContainer'>
                    <div className='dateCont'>
                      <div className='icon'>
                        <MdCalendarToday />
                      </div>
                      <div className='time'>{pickUpDate}</div>
                    </div>
                    {pickUpTimeRange && (
                      <div className='timeCont'>
                        <div className='icon'>
                          <FaRegClock />
                        </div>
                        <div className='time'>{pickUpTimeRange}</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {directionA && directionA.length > 0 ? (
                directionA.map((item, i) => (
                  <AddressDetailsItem
                    content={item.formatted_address}
                    key={i}
                    icon={<MdLocationSearching />}
                    from
                  />
                ))
              ) : (
                <AddressDetailsItem content='' icon={<MdLocationSearching />} />
              )}
            </div>
            <div className='to'>
              <div className='title'>
                <h3>{t("tms.desc.To")}</h3>
                {dropOffDate && (
                  <div className='timeContainer'>
                    <div className='dateCont'>
                      <div className='icon'>
                        <MdCalendarToday />
                      </div>
                      <div className='time'>{dropOffDate}</div>
                    </div>
                    {dropOffTimeRange && (
                      <div className='timeCont'>
                        <div className='icon'>
                          <FaRegClock />
                        </div>
                        <div className='time'>{dropOffTimeRange}</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {directionB && directionB.length > 0 ? (
                directionB.map((item, i) => (
                  <AddressDetailsItem
                    content={item.formatted_address}
                    key={i}
                    icon={<MdLocationPin />}
                  />
                ))
              ) : (
                <AddressDetailsItem content='-' icon={<MdLocationPin />} />
              )}
            </div>
          </div>
        </div>
        <ProductInformationDetails order={order} />
      </div>

      <OrderComment order={order} />
    </div>
  );
};

export default OrderInformationDetails;
