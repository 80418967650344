import React from "react";
import "./ChargeFormItem.scss";

import { useTranslation } from "react-i18next";
import { IoIosRemoveCircleOutline } from "react-icons/io";

import DInputs from "../../../../../../Form/DInputs/DInputs";

const ChargeFormItem = ({ charges, setCharges, item, index }) => {
  const { t } = useTranslation();

  const handleChangeName = (value) => {
    const data = [...charges];
    data[index].name = value;
    setCharges(data);
  };
  const handleChangeAmount = (value) => {
    const data = [...charges];
    data[index].amount = value;
    data[index].total = (
      Number(data[index].amount) +
      Number(data[index].amount) * (Number(data[index].vat) / 100)
    )?.toFixed(2);
    setCharges(data);
  };
  const handleChangeTotal = (value) => {
    const data = [...charges];
    data[index].total = value;
    data[index].amount = (
      (Number(data[index].total) * 100) /
      (100 + Number(data[index].vat))
    )?.toFixed(2);
    setCharges(data);
  };
  const handleChangeVat = (value) => {
    const data = [...charges];
    data[index].vat = value;
    data[index].amount = (
      (Number(data[index].total) * 100) /
      (100 + Number(data[index].vat))
    )?.toFixed(2);
    setCharges(data);
  };
  const handleRemove = () => {
    const data = [...charges];
    data.splice(index, 1);
    setCharges(data);
  };

  return (
    <div id='ChargeFormItem'>
      <div className='name'>
        <DInputs
          placeholder={t("tms.invoice.Enter charge name")}
          value={item.name}
          setValue={(event) => handleChangeName(event)}
        />
      </div>
      <div className='total'>
        <DInputs
          type='number'
          value={item.amount}
          setValue={(event) => handleChangeAmount(event)}
        />
      </div>
      <div className='VAT'>
        <DInputs type='number' value={item.vat} setValue={(event) => handleChangeVat(event)} />
      </div>
      <div className='total'>
        <DInputs type='number' value={item.total} setValue={(event) => handleChangeTotal(event)} />
      </div>
      <div className='remove'>
        <div className='icon' onClick={() => handleRemove()}>
          <IoIosRemoveCircleOutline />
        </div>
      </div>
    </div>
  );
};

export default ChargeFormItem;
