import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import favoriteLocationsService from "./favoriteLocationsService";

const initialState = {
  getLocationsLoading: true,
  getLocationsResult: null,
  getLocationsError: null,

  createLocationLoading: false,
  createLocationResult: null,
  createLocationError: null,

  deleteLocationLoading: false,
  deleteLocationResult: null,
  deleteLocationError: null,
};

export const getLocations = createAsyncThunk(
  "favoriteLocations/getLocations",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await favoriteLocationsService.getLocations(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createLocation = createAsyncThunk(
  "favoriteLocations/createLocation",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await favoriteLocationsService.createLocation(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteLocation = createAsyncThunk(
  "favoriteLocations/deleteLocation",
  async (id, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await favoriteLocationsService.deleteLocation(ISO, TOKEN, id);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const favoriteLocationsSlice = createSlice({
  name: "favoriteLocations",
  initialState,
  reducers: {
    resetGetLocations: (state) => {
      state.getLocationsLoading = false;
      state.getLocationsResult = null;
      state.getLocationsError = null;
    },
    resetCreateLocation: (state) => {
      state.createLocationLoading = false;
      state.createLocationResult = null;
      state.createLocationError = null;
    },
    resetDeleteLocation: (state) => {
      state.deleteLocationLoading = false;
      state.deleteLocationResult = null;
      state.deleteLocationError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state) => {
        state.getLocationsLoading = true;
        state.getLocationsResult = null;
        state.getLocationsError = null;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.getLocationsLoading = false;
        state.getLocationsResult = action.payload;
        state.getLocationsError = null;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.getLocationsLoading = false;
        state.getLocationsResult = null;
        state.getLocationsError = action.payload;
      })

      .addCase(createLocation.pending, (state) => {
        state.createLocationLoading = true;
        state.createLocationResult = null;
        state.createLocationError = null;
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        state.createLocationLoading = false;
        state.createLocationResult = action.payload.id;
        state.createLocationError = null;
      })
      .addCase(createLocation.rejected, (state, action) => {
        state.createLocationLoading = false;
        state.createLocationResult = null;
        state.createLocationError = action.payload;
      })

      .addCase(deleteLocation.pending, (state) => {
        state.deleteLocationLoading = true;
        state.deleteLocationResult = null;
        state.deleteLocationError = null;
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        state.deleteLocationLoading = false;
        state.deleteLocationResult = action.payload.id;
        state.deleteLocationError = null;
      })
      .addCase(deleteLocation.rejected, (state, action) => {
        state.deleteLocationLoading = false;
        state.deleteLocationResult = null;
        state.deleteLocationError = action.payload;
      });
  },
});

export const {
  resetGetLocations,
  resetCreateLocation,
  resetDeleteLocation,
} = favoriteLocationsSlice.actions;

export default favoriteLocationsSlice.reducer;
