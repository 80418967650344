import React, { Fragment, useEffect, useRef, useState } from "react";
import "./ProfileCompanyInfo.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IoEarthOutline, IoLocationOutline } from "react-icons/io5";
import { MdAddPhotoAlternate, MdModeEditOutline } from "react-icons/md";

import AccountList from "./Components/AccountList/AccountList";
import ProfileInfoItem from "../ProfileHeader/ProfileInfo/ProfileInfoItem/ProfileInfoItem";
import DTextButton from "../../Form/DButtons/DTextButton/DTextButton";

import {
  resetUploadCompanyLogo,
  uploadCompanyLogo,
} from "../../../store/user/fileUpload/uploadSlice";
import DErrorText from "../../Form/DErrorText/DErrorText";
import AccountantEmail from "./Components/AccountantEmail/AccountantEmail";
import EditProfileModal from "../ProfileHeader/ProfileInfo/components/EditProfileModal/EditProfileModal";

const ProfileCompanyInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { uploadCompanyLogoResult, uploadCompanyLogoError } = useSelector((state) => state.upload);
  const [showEditModal, setShowEditModal] = useState(false);

  const fileUploadButtonRef = useRef(null);

  const handleCompanyPhotoSelect = (e) => {
    const data = {
      file: e?.target?.files[0],
    };

    dispatch(uploadCompanyLogo(data));
  };

  useEffect(() => {
    if (uploadCompanyLogoResult) {
      dispatch(resetUploadCompanyLogo());
    }
  }, [dispatch, uploadCompanyLogoResult]);

  return (
    <Fragment>
      <div id='ProfileCompanyInfo'>
        <div className='companyContainer'>
          <div className='title'>
            {user?.company?.logo ? (
              <div
                className='companyLogo'
                style={{ backgroundImage: `url(${user?.company?.logo})` }}
                src={user?.company?.logo}
                alt='company logo'
              >
                <div
                  className='editCompanyLogo'
                  onClick={() => fileUploadButtonRef.current.click()}
                >
                  <input
                    type='file'
                    onChange={handleCompanyPhotoSelect}
                    accept='image/*'
                    ref={fileUploadButtonRef}
                  />
                  <div className='icon'>
                    <MdModeEditOutline />
                  </div>
                </div>
              </div>
            ) : (
              <div
                className='companyPhotoContainer'
                onClick={() => fileUploadButtonRef.current.click()}
              >
                <input
                  type='file'
                  onChange={handleCompanyPhotoSelect}
                  accept='image/*'
                  ref={fileUploadButtonRef}
                />
                <MdAddPhotoAlternate />
              </div>
            )}
            <h2>{user?.company?.name || ""}</h2>
          </div>
          <DErrorText
            error={
              uploadCompanyLogoError?.errors?.file?.[0] ||
              (uploadCompanyLogoError &&
                uploadCompanyLogoError === "Network Error" &&
                t("tms.desc.Reduce file size"))
            }
          />
          <div className='desc'>
            <h4>
              {t("tms.titles.ID (VAT)")}: {user?.company?.legal_id || ""}
            </h4>

            <div className='branch'>
              <div className='icon'>
                <IoEarthOutline />
              </div>
              <h4>{user?.active_branch?.name || ""}</h4>
            </div>
            <ProfileInfoItem
              icon={<IoLocationOutline />}
              text={user?.company?.address || ""}
              button={
                <DTextButton
                  title={t("tms.forms.buttons.Add address")}
                  onClick={() => setShowEditModal(true)}
                />
              }
              max={30}
            />
          </div>
        </div>
        <AccountList />
        <AccountantEmail />
      </div>
      {showEditModal && (
        <EditProfileModal isOpen={showEditModal} isClose={() => setShowEditModal(false)} />
      )}
    </Fragment>
  );
};

export default ProfileCompanyInfo;
