import React, { Fragment } from "react";
import "./CreateOrderDimentionDescription.scss";

import { useTranslation } from "react-i18next";

const CreateOrderDimentionDescription = ({ volume, weight }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {weight || volume ? (
        <div id='CreateOrderDimentionDescription'>
          {volume ? (
            <div className='dimentionDesctiptionItem'>
              <h6>{t("shipper.forms.content.Gross volume")}:</h6>
              <div className='valueNumber'>
                <p>
                  {volume} {t("shipper.units.M3")}
                </p>
              </div>
            </div>
          ) : null}
          {weight ? (
            <div className='dimentionDesctiptionItem'>
              <h6>{t("shipper.forms.content.Gross weight")}:</h6>
              <div className='valueNumber'>
                <p>
                  {weight} {t("shipper.units.KG")}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </Fragment>
  );
};

export default CreateOrderDimentionDescription;
