import React from "react";
import "./Label.scss";

import Warning from "../Warning/Warning";

const Label = ({ text, id, warningText, warningTitle, warning }) => {
  return (
    <div id='Label'>
      <div className='labelContainerWarning'>
        <label htmlFor={id}>{text}</label>
      </div>
      {warning && (
        <Warning text={warningText} title={warningTitle ? warningTitle : "!"} />
      )}
    </div>
  );
};

export default Label;
