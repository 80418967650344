import React from "react";
import "./AssignPartnerButton.scss";

import { MdDone } from "react-icons/md";

import DButton from "./../../../../../Form/DButtons/DButton/DButton";

const AssignPartnerButton = ({ onClick, title }) => {
  return (
    <div id='AssignPartnerButton'>
      <DButton title={title} rightIcon={<MdDone />} onClick={onClick} />
    </div>
  );
};

export default AssignPartnerButton;
