import React, { Fragment, useState } from "react";
import "./Cover.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import TalkExpert from "../../Components/TalkExpert/TalkExpert";
import FadeTextAnimation from "../../../UI/Animations/FadeTextAnimation/FadeTextAnimation";

const Cover = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ofenTalkExpert, setOfenTalkExpert] = useState(false);

  const partners = [
    { name: "Pepsico", iconUrl: "/assets/images/partners/pepsico.svg" },
    { name: "Ashley", iconUrl: "/assets/images/partners/ashley.svg" },
    { name: "Nestle", iconUrl: "/assets/images/partners/nestle.svg" },
    { name: "Mondelez", iconUrl: "/assets/images/partners/mondelez.svg" },
    { name: "Jacobs", iconUrl: "/assets/images/partners/jacobs.svg" },
    { name: "Borjomi", iconUrl: "/assets/images/partners/borjomi.svg" },
  ];

  return (
    <Fragment>
      <TalkExpert open={ofenTalkExpert} setOpen={setOfenTalkExpert} />
      <section id='Cover' className='Cover'>
        <video
          poster='/assets/video/CargonVideoCoverPoster.jpg'
          src='/assets/video/CargonVideoCover.mp4'
          type='video/mp4'
          autoPlay={true}
          loop
          playsInline
          muted='muted'
        ></video>
        <div className='blar'>
          <div className='container'>
            <div className='textContainer'>
              <FadeTextAnimation
                text={t("landing.titles.landingMainTitles.Elevate your supply chain")}
              />
            </div>

            <div
              data-aos='fade'
              data-aos-delay='500'
              data-aos-duration='1500'
              data-aos-easing='ease-in-out'
            >
              <div className='submit' onClick={() => navigate("/register")}>
                {t("landing.form.button.Get started")}
              </div>
            </div>
          </div>
        </div>
        <div
          className='partnersContainer'
          style={{
            backgroundImage: `url(${`/assets/images/map/WorldMap.svg`})`,
          }}
        >
          {partners?.map((partner, index) => (
            <div
              key={index}
              className='partner'
              style={{ backgroundImage: `url(${partner?.iconUrl})` }}
            ></div>
          ))}
        </div>
      </section>
    </Fragment>
  );
};

export default Cover;
