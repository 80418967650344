import _request from "../../_request";

// get FAQ
const getFAQ = async (ISO) => {
  const config = {
    url: "/faq",
    ISO,
  };
  return _request(config);
};

// get getInsurance
const getInsurance = async (ISO) => {
  const config = {
    url: "/pages/insurance-policy",
    ISO,
  };
  return _request(config);
};

// get getPrivacy
const getPrivacy = async (ISO) => {
  const config = {
    url: "/pages/privacy-policy",
    ISO,
  };
  return _request(config);
};

// get getTerms
const getTerms = async (ISO) => {
  const config = {
    url: "/pages/terms",
    ISO,
  };
  return _request(config);
};

const rulesService = {
  getFAQ,
  getInsurance,
  getPrivacy,
  getTerms,
};

export default rulesService;
