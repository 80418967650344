import React, { Fragment } from "react";
import "./PartnerField.scss";

import { IoMdBusiness } from "react-icons/io";
import { Link } from "react-router-dom";

import Character from "../../../UI/Character/Character";

const PartnerField = ({ label, to, max }) => {
  return (
    <Fragment>
      {to ? (
        <td id='PartnerField' className='link'>
          <Link to={to && to} className='PartnerFieldContainer'>
            <div className='PartnerFieldContainerIcon'>
              <IoMdBusiness />
            </div>

            <h3>
              <Character title={label} max={max ? max : 20} />
            </h3>
          </Link>
        </td>
      ) : (
        <td id='PartnerField'>
          <div className='PartnerFieldContainer'>
            <div className='PartnerFieldContainerIcon'>
              <IoMdBusiness />
            </div>

            <h3>
              <Character title={label} max={max ? max : 20} />
            </h3>
          </div>
        </td>
      )}
    </Fragment>
  );
};

export default PartnerField;
