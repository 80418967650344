import React, { useState, useEffect } from "react";
import "./SignInCompanyVerifyForm.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  verify,
  resetVerify,
  sendOtp,
  resetSendOtp,
  removeToken,
  resetRemoveToken,
} from "../../../../store/auth/authSlice";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import Button from "../../../Form/Button/Button";
import SendAgain from "../../Components/SendAgain/SendAgain";
import OTP from "../../../Form/OTP/OTP";

const SignInCompanyVerifyForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const {
    verifyLoading,
    verifyResult,
    verifyError,
    user,
    removeTokenResult,
    sendOtpResult,
    sendOtpLoading,
  } = useSelector((state) => state.auth);

  const [otp, setOtp] = useState();

  useEffect(() => {
    if (verifyResult) {
      const url = "/login/tms/success";
      navigate(url);
      dispatch(resetVerify());
    }
  }, [dispatch, navigate, verifyResult]);

  useEffect(() => {
    return () => {
      dispatch(resetVerify());
      resetSendOtp();
    };
  }, [dispatch]);

  useEffect(() => {
    if (removeTokenResult) {
      dispatch(resetRemoveToken());
      navigate("/login");
    }
  }, [removeTokenResult, dispatch, navigate]);

  const submitHandler = () => {
    const data = {
      type: "email",
      userId: user.id,
      code: otp,
    };
    dispatch(verify(data));
  };

  const handleRemoveToken = () => {
    dispatch(removeToken());
  };

  const againHandler = () => {
    const data = {
      type: "email",
    };
    dispatch(sendOtp(data));
    setOtp();
  };

  return (
    <AuthFormWrapper>
      <form id='SignInCompanyVerifyForm' className='SignInCompanyVerifyForm'>
        <FormTitle center title={t("landing.auth.titles.Verification code")}>
          {t("landing.auth.titles.Please enter the verification code you received in your email")}
          <span> {email}</span>
        </FormTitle>
        <OTP
          value={otp}
          setValue={setOtp}
          count={6}
          error={(verifyError?.errors?.code?.[0] ?? null) || (verifyError?.error ?? null)}
        />
        <Button
          loading={verifyLoading}
          title={t("landing.auth.form.Verify account")}
          onClick={() => {
            submitHandler();
          }}
        />
        <SendAgain
          title={t("landing.auth.form.Send it again")}
          onClick={() => againHandler()}
          result={sendOtpResult}
          loading={sendOtpLoading}
          reset={() => dispatch(resetSendOtp())}
        />
        <div className='anotherAccountButton' onClick={handleRemoveToken}>
          {t("landing.auth.form.Try with another account")}
        </div>
      </form>
    </AuthFormWrapper>
  );
};

export default SignInCompanyVerifyForm;
