import React from "react";
import "./AuthFormWrapper.scss";

const AuthFormWrapper = ({ children }) => {
  return (
    <div id='AuthFormWrapper'>
      <div
        className='animation'
        data-aos='fade-left'
        data-aos-duration='1500'
        data-aos-once='true'
      >
        <div className='AuthFormWrapperContent'>{children}</div>
      </div>
    </div>
  );
};

export default AuthFormWrapper;
