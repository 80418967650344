import React from "react";
import "./ProductShipper.scss";

import { BsArrowUpRight } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import HomeTitle from "../Components/HomeTitle/HomeTitle";
import LinkUnderline from "./../Components/Links/LinkUnderline/LinkUnderline";

const ProductShipper = () => {
  const { t } = useTranslation();

  const ProductShipperItem = ({ img, title, desc, delay }) => {
    return (
      <div
        data-aos='fade-up'
        data-aos-duration='400'
        data-aos-delay={delay ? delay : "0"}
        data-aos-once='true'
      >
        <div className='item'>
          <div
            className='img'
            style={{
              backgroundImage: `url(${img})`,
            }}
          ></div>
          <div className='desc'>
            <h1>{title}</h1>
            <p>{desc}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id='ProductShipper' className='ProductShipper'>
      <div className='wrapper'>
        <HomeTitle
          description={t(
            "landing.titles.landingMainTitlesDesc.Unlock the power of data with our dashboard. Gain real-time visibility into your supply chain, analyze key performance metrics, and make informed decisions for enhanced logistics efficiency"
          )}
        >
          {parse(
            t(
              "landing.titles.landingMainTitles.<span>Control</span> your full supply chain"
            )
          )}
        </HomeTitle>
        <div className='items'>
          <ProductShipperItem
            title={t("landing.titles.landingProductTitle.Road freight")}
            desc={t(
              "landing.bodyText.landingProductBody.Access to our connected fleet of thousands of trucks."
            )}
            img='/assets/images/static/statistics1.png'
            delay='400'
          />
          <ProductShipperItem
            title={t("landing.titles.landingProductTitle.Ocean freight")}
            desc={t(
              "landing.bodyText.landingProductBody.Cross the ocean reliably, thanks for the oceans of data."
            )}
            img='/assets/images/static/statistics2.png'
            delay='250'
          />
          <ProductShipperItem
            title={t("landing.titles.landingProductTitle.Air freight")}
            desc={t(
              "landing.bodyText.landingProductBody.A reliable partner network for capacity on premium carriers."
            )}
            img='/assets/images/static/statistics3.png'
            delay='100'
          />
        </div>
        <LinkUnderline
          to='/register'
          title={t("landing.form.button.Experience the future of logistics")}
          icon={<BsArrowUpRight />}
        />
      </div>
    </section>
  );
};

export default ProductShipper;
