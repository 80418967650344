import React from "react";
import "./Header.scss";

import { useTranslation } from "react-i18next";
import TruckLocationBadge from "./../TruckLocationBadge/TruckLocationBadge";
import RoadIcon from "./../RoadIcon/RoadIcon";

const Header = ({ item }) => {
  const { t } = useTranslation();
  return (
    <div id='InfoWindowHeader'>
      <div className='truck'>
        <div
          className='cover'
          style={{
            backgroundImage: `url(${`/assets/images/dashboard/cover10.svg`})`,
          }}
        >
          {item.location && <TruckLocationBadge color={item.location.color} />}
        </div>
        <div className='name'>
          <h2>{item?.model?.brand?.name || t("tms.titles.Truck")}</h2>
          <h3>{item?.registration_number || "-"}</h3>
        </div>
      </div>
      <RoadIcon check={item.orders?.length > 0 ? true : false} />
    </div>
  );
};

export default Header;
