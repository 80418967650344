import React from "react";
import "./TextArea.scss";

const TextArea = ({ name, id, placeholder, value, setValue, error }) => {
  return (
    <div id='TextArea' className={error ? "error" : ""}>
      <textarea
        name={name}
        id={id}
        placeholder={placeholder ? placeholder : ""}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

export default TextArea;
