import React from "react";
import "./DModal.scss";

import { FiX } from "react-icons/fi";

import { useTranslation } from "react-i18next";

import DOverlay from "./../DOverlay/DOverlay";
import ButtonLoader from "./../../Form/DButtons/ButtonLoader/ButtonLoader";

const DModal = ({
  isOpen,
  isClose,
  title,
  icon,
  status,
  children,
  onClick,
  submitText,
  loading,
  orderStatus,
  onOrderStatusClick,
  disabled,
  noSubmit,
  btnBackground,
}) => {
  const { t } = useTranslation();

  let color;
  let bgColor;

  switch (status) {
    case "danger":
      color = "#DA1414";
      bgColor = "#FEEFEF";
      break;
    case "warning":
      color = "#B95000";
      bgColor = "#FFF4EC";
      break;
    case "success":
      color = "#00814d";
      bgColor = "#f0fcf7";
      break;
    case "info":
      color = "#1A2023";
      bgColor = "#F4F6F9";
      break;
    case "aditionalInfo":
      color = "#2e5aac";
      bgColor = "#F4F6F9";
      break;
    default:
      color = "#00814d";
      bgColor = "#ffffff";
      break;
  }

  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <div id='DModalComponent'>
        <div
          className='header'
          style={{
            borderBottom: `2px solid ${color}`,
            backgroundColor: `${bgColor}`,
          }}
        >
          <div className='title'>
            <div className='titleIcon' style={{ color: `${color}` }}>
              {icon}
            </div>
            <h2 style={{ color: `${color}` }}>{title}</h2>
          </div>
          <div className='closeIcon' onClick={isClose}>
            <FiX />
          </div>
        </div>
        <div className='content'>{children}</div>
        <div className='footer'>
          {orderStatus ? (
            <div className='cancel' onClick={onOrderStatusClick}>
              {t("tms.modals.buttons.Move without update")}
            </div>
          ) : (
            <div className='cancel' onClick={isClose}>
              {t("tms.modals.buttons.Cancel")}
            </div>
          )}

          {status !== "noSubmit" && !noSubmit && (
            <div
              className='submit'
              onClick={loading && disabled ? () => {} : onClick}
              style={{
                backgroundColor: `${disabled ? "#c8e5f3" : color}`,
                cursor: `${disabled ? "not-allowed" : "pointer"}`,
                borderColor: `${disabled ? "#c8e5f3" : color}`,
                background: btnBackground || `${color}`,
              }}
            >
              {loading ? <ButtonLoader /> : submitText}
            </div>
          )}
        </div>
      </div>
    </DOverlay>
  );
};

export default DModal;
