import React from "react";
import "./Button.scss";

import ButtonLoader from "../../UI/ButtonLoader/ButtonLoader";

const Button = ({ title, onClick, loading, rightIcon, leftIcon, type }) => {
  return (
    <div id='Button'>
      <button
        type={type ? type : "button"}
        onClick={(e) => {
          onClick(e);
        }}
      >
        {!loading && leftIcon && (
          <div className='buttonIcon left'>{leftIcon}</div>
        )}
        {loading ? <ButtonLoader /> : title}
        {!loading && rightIcon && (
          <div className='buttonIcon right'>{rightIcon}</div>
        )}
      </button>
    </div>
  );
};

export default Button;
