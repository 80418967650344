import React from "react";
import "./Time.scss";

import { useTranslation } from "react-i18next";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { MdOutlineUpdate } from "react-icons/md";
import Character from "../../../../UI/Character/Character";

const Time = ({ item }) => {
  const { t } = useTranslation();
  return (
    <div id='InfoWindowTime'>
      <div className='route'>
        <div className='icon'>
          <FaLocationCrosshairs />
        </div>
        <h3>
          <Character
            title={`${
              item?.location?.coordinate?.coordinates[0]?.toFixed(4) || 0
            }, ${item?.location?.coordinate?.coordinates[1]?.toFixed(4) || 0}`}
          
            max={13}
          />
        </h3>
      </div>
      <div className='ago'>
        <div className='icon'>
          <MdOutlineUpdate />
        </div>
        <h3 style={{ color: item?.location?.color }}>
          <Character
            title={item?.location?.location_at_human || t("tms.desc.No data")}
            max={13}
          />
        </h3>
      </div>
    </div>
  );
};

export default Time;
