import React, { useEffect, Fragment } from "react";
import "./ShowPrePaymentModal.scss";

import { useTranslation } from "react-i18next";

import { MdMonetizationOn } from "react-icons/md";
import { FiX } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  getPayments,
  resetGetPayments,
} from "../../../../../../store/orders/orderFinance/orderFinanceSlice";

import DOverlay from "../../../../../Modal/DOverlay/DOverlay";
import ShowPrePaymentModalItem from "./ShowPrePaymentModalItem/ShowPrePaymentModalItem";
import LoadingPage from "./../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const ShowPrePaymentModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getPaymentsLoading, getPaymentsResult } = useSelector((state) => state.orderFinance);

  useEffect(() => {
    dispatch(getPayments(order.id));
    return () => {
      dispatch(resetGetPayments());
    };
  }, [dispatch, order.id]);

  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <div id='ShowPrePaymentModal'>
        <div className='ShowPrePaymentModalHeader'>
          <div className='title'>
            <div className='titleIcon'>
              <MdMonetizationOn />
            </div>
            <h2>{t("tms.modals.titles.Advances")}</h2>
          </div>
          <div className='closeIcon' onClick={isClose}>
            <FiX />
          </div>
        </div>
        <div className='ShowPrePaymentModalContainer'>
          {getPaymentsLoading && <LoadingPage />}
          {getPaymentsResult && (
            <Fragment>
              {getPaymentsResult.length === 0 ? (
                <p className='noPrepayment'>{t("tms.modals.others.No advances")}</p>
              ) : (
                <ul className='ShowPrePaymentModalList'>
                  {getPaymentsResult.map((item) => (
                    <ShowPrePaymentModalItem item={item} key={item.id} order={order} />
                  ))}
                </ul>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </DOverlay>
  );
};

export default ShowPrePaymentModal;
