import React, { useState, Fragment } from "react";

import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";

import DeleteCustomerModal from "../../DeleteCustomerModal/DeleteCustomerModal";
import EditCustomerModal from "../../EditCustomerModal/EditCustomerModal";
import NameField from "../../../../Form/TableFields/NameField/NameField";
import RowField from "../../../../Form/TableFields/RowField/RowField";
import TextField from "../../../../Form/TableFields/TextField/TextField";
import MoreField from "../../../../Form/TableFields/MoreField/MoreField";
import DateField from "../../../../Form/TableFields/DateField/DateField";

const CustomersTableItem = ({ item }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <RowField>
        <NameField
          max={50}
          to={`/dashboard/tms/customers_partners/customers/${item.id}`}
          label={item?.name || "-"}
        />
        <TextField max={30} label={item?.code || "-"} />
        <TextField max={30} label={item?.email || "-"} />
        <TextField label={item?.total_number_of_orders?.toString() || "-"} />
        <DateField date={item?.last_order_date} />
        <MoreField>
          <div className='icon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='OrdersTableItemMorecustomers'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenEditModal(true);
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </MoreField>
      </RowField>
      {openEditModal && (
        <EditCustomerModal
          item={item}
          isOpen={openEditModal}
          isClose={() => setOpenEditModal(false)}
        />
      )}
      {openDeleteModal && (
        <DeleteCustomerModal
          id={item.id}
          isOpen={openDeleteModal}
          isClose={() => setOpenDeleteModal(false)}
        />
      )}
    </Fragment>
  );
};

export default CustomersTableItem;
