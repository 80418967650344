import React, { Fragment, useState, useEffect } from "react";
import "./EditAirOrder.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { FiCheck } from "react-icons/fi";

import {
  getOrder,
  resetGetOrder,
  updateOrder,
  resetUpdateOrder,
} from "../../../../../../store/orders/ordersSlice";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import AirTopMenu from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirTopMenu/AirTopMenu";
import AirLocation from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirLocation/AirLocation";
import AirPackage from "../../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderShipperAir/AirPackage/AirPackage";
import CreateOrderSubmit from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderSubmit/CreateOrderSubmit";
import CreateOrderHeader from "../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderHeader/CreateOrderHeader";
import CreateOrderAditionalInformation from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalInformation/CreateOrderAditionalInformation";
import CreateOrderAditionalServices from "./../../../../../../componentsDashboard/Orders/CreateOrder/Components/CreateOrderAditionalServices/CreateOrderAditionalServices";
import LoadingPage from "../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import PageNotFound from "../../../../../../componentsBids/PageNotFound/PageNotFound";

const EditAirOrder = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const {
    getOrderLoading,
    getOrderResult,
    getOrderError,
    updateOrderLoading,
    updateOrderResult,
    updateOrderError,
  } = useSelector((state) => state.orders);

  const [locations, setLocations] = useState([
    {
      direction: "A",
      inputted_address: "",
      type: "AIRPORT",
      error: null,
    },
    {
      direction: "B",
      inputted_address: "",
      type: "AIRPORT",
      error: null,
    },
  ]);
  //////// group
  const [group, setGroup] = useState([
    {
      cargoType: null,
      cargoTypeError: null,
      packageType: null,
      packageTypeError: null,
      HSCode: false,
      HSCodeValue: "",
      HSCodeValueError: null,
      //////
      adr: false,
      adrError: null,
      adrNumber: "",
      adrNumberError: null,
      adrClass: "",
      adrClassError: null,
      //////
      dimensions: [
        {
          count: 1,
          countError: null,
          weight: "",
          weightError: null,
          length: "",
          lengthError: null,
          width: "",
          widthError: null,
          height: "",
          heightError: null,
        },
      ],
    },
  ]);
  ///// Additional Services
  const [selectedServices, setSelectedServices] = useState([]);
  ///// Additional information
  const [cargoPrice, setCargoPrice] = useState("");
  const [cargoPriceCurrency, setCargoPriceCurrency] = useState(
    user
      ? {
          label: user?.currency?.code || "",
          value: user?.currency || null,
        }
      : null
  );
  const [readyCargoTime, setReadyCargoTime] = useState(null);
  const [comment, setComment] = useState("");

  // get detail order
  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => {
      dispatch(resetGetOrder());
    };
  }, [dispatch, orderId]);

  // fill order data
  useEffect(() => {
    if (getOrderResult) {
      setLocations(
        getOrderResult?.cargos?.[0]?.locations
          ? getOrderResult?.cargos?.[0]?.locations.map((i) => ({
              id: i.id,
              direction: i?.direction || "A",
              inputted_address: i?.inputted_address || "",
              type: i?.type || "AIRPORT",
              error: null,
            }))
          : [
              {
                direction: "A",
                inputted_address: "",
                type: "AIRPORT",
                error: null,
              },
              {
                direction: "B",
                inputted_address: "",
                type: "AIRPORT",
                error: null,
              },
            ]
      );
      ///////////////
      setGroup(
        getOrderResult?.cargos?.map((item) => ({
          id: item.id,
          cargoType: item?.type
            ? {
                label: item?.type?.name,
                value: item?.type,
              }
            : null,
          cargoTypeError: null,
          packageType: {
            label: item?.packing_type?.name || "",
            value: item?.packing_type || null,
          },
          packageTypeError: null,
          HSCode: item?.hs_code ? true : false,
          HSCodeValue: item?.hs_code || "",
          HSCodeValueError: null,
          //////
          adr: item?.adr ? true : false,
          adrError: null,
          adrNumber: item?.un_number || "",
          adrNumberError: null,
          adrClass: item?.adr_class || "",
          adrClassError: null,

          dimensions: item.dimensions
            ? [
                {
                  id: item?.dimensions?.[0]?.id,
                  packingType: {
                    label: item?.dimensions?.[0]?.packing_type?.name || "",
                    value: item?.dimensions?.[0]?.packing_type || null,
                  },
                  packingTypeError: null,
                  count: item?.dimensions?.[0]?.count || 1,
                  countError: null,
                  weight: item?.dimensions?.[0]?.weight || "",
                  weightError: null,
                  length: item?.dimensions?.[0]?.length || "",
                  lengthError: null,
                  width: item?.dimensions?.[0]?.width || "",
                  widthError: null,
                  height: item?.dimensions?.[0]?.height || "",
                  heightError: null,
                },
              ]
            : [
                {
                  packingType: null,
                  packingTypeError: null,
                  count: 1,
                  countError: null,
                  weight: "",
                  weightError: null,
                  length: "",
                  lengthError: null,
                  width: "",
                  widthError: null,
                  height: "",
                  heightError: null,
                },
              ],
        }))
      );
      ////////////
      setSelectedServices(getOrderResult?.services || []);
      setCargoPrice(getOrderResult?.cargos[0]?.price || "");
      setCargoPriceCurrency(
        getOrderResult?.cargos[0]?.price_currency
          ? {
              label: getOrderResult?.cargos[0]?.price_currency?.code,
              value: getOrderResult?.cargos[0]?.price_currency,
            }
          : {
              label: user?.currency?.code || "",
              value: user?.currency || null,
            }
      );
      setReadyCargoTime(
        getOrderResult?.cargos?.[0]?.cargo_ready_date
          ? new Date(getOrderResult?.cargos?.[0]?.cargo_ready_date)
          : null
      );
      setSelectedServices(getOrderResult?.services || []);
      setCargoPrice(getOrderResult?.cargos[0]?.price || "");
      setCargoPriceCurrency(
        getOrderResult?.cargos[0]?.price_currency
          ? {
              label: getOrderResult?.cargos[0]?.price_currency?.code,
              value: getOrderResult?.cargos[0]?.price_currency,
            }
          : {
              label: user?.currency?.code || "",
              value: user?.currency || null,
            }
      );
      setReadyCargoTime(
        getOrderResult?.cargos?.[0]?.cargo_ready_date
          ? new Date(getOrderResult?.cargos?.[0]?.cargo_ready_date)
          : null
      );
      setComment(getOrderResult?.about || "");
    }
  }, [getOrderResult, user]);

  // catch locations error
  useEffect(() => {
    if (updateOrderError?.errors) {
      const updatedLocations = locations.map((location, i) => ({
        ...location,
        error: updateOrderError.errors[`cargos.0.locations.${i}.inputted_address`]?.[0] ?? null,
      }));
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrderError]);

  // catch group errors
  useEffect(() => {
    if (updateOrderError?.errors) {
      const updatedGroup = group.map((item, i) => ({
        ...item,
        packageTypeError: updateOrderError?.errors?.[`cargos.${i}.packing_type_id`]?.[0] || null,
        cargoTypeError: updateOrderError?.errors?.[`cargos.${i}.type_id`]?.[0] || null,
        HSCodeValueError: updateOrderError?.errors?.[`cargos.${i}.hs_code`]?.[0] || null,
        adrError: updateOrderError?.errors?.[`cargos.${i}.adr`]?.[0] || null,
        adrNumberError: updateOrderError?.errors?.[`cargos.${i}.un_number`]?.[0] || null,
        adrClassError: updateOrderError?.errors?.[`cargos.${i}.adr_class`]?.[0] || null,

        dimensions: item.dimensions.map((dim, ind) => ({
          ...dim,
          packingTypeError:
            updateOrderError?.errors?.[`cargos.${i}.dimensions.0.packing_type_id`]?.[0] || null,
          countError: updateOrderError?.errors?.[`cargos.${i}.dimensions.0.count`]?.[0] || null,
          weightError: updateOrderError?.errors?.[`cargos.${i}.weight`]?.[0] || null,
          lengthError: updateOrderError?.errors?.[`cargos.${i}.dimensions.0.length`]?.[0] || null,
          widthError: updateOrderError?.errors?.[`cargos.${i}.dimensions.0.width`]?.[0] || null,
          heightError: updateOrderError?.errors?.[`cargos.${i}.dimensions.0.height`]?.[0] || null,
        })),
      }));
      setGroup(updatedGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrderError]);

  // reset
  useEffect(() => {
    if (updateOrderResult) {
      dispatch(resetUpdateOrder());
      navigate(`/dashboard/shipper/orders/${orderId}`);
    }
  }, [dispatch, navigate, orderId, updateOrderResult]);

  // reset all time
  useEffect(() => {
    return () => {
      dispatch(resetUpdateOrder());
    };
  }, [dispatch]);

  const getNextDate = () => {
    const today = new Date();
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + 7);
    const formattedDate = nextDate.toISOString().split("T")[0];
    return formattedDate;
  };

  // submit
  const submitHandler = () => {
    const data = {
      orderId: orderId,
      priority_id: 1,
      transfer_type_id: 1,
      type_id: 3,
      revenue: getOrderResult?.revenue || 0,
      revenue_currency_id: getOrderResult?.revenue_currency?.id || null,
      client_payment: null,
      about: comment || null,
      services: selectedServices.map((i) => i.id),
      cargos: group.map((item) => {
        let totalWeight = 0;
        let totalVolume = 0;
        item.dimensions.forEach((i) => {
          const volume = parseFloat(
            (
              ((i?.length || 0) * (i?.width || 0) * (i?.height || 0) * (i?.count || 0)) /
              1000000
            ).toFixed(5)
          );
          const weight = parseFloat(((i?.weight || 0) * (i?.count || 0)).toFixed(2));
          totalWeight += weight;
          totalVolume += volume;
        });

        return {
          id: item.id,
          trailer_type_id: null,
          trailer_size_id: null,
          temperature: null,
          type_id: item?.cargoType?.value?.id || null,
          count: 1,
          weight: totalWeight || null,
          volume: totalVolume || null,
          packing_type_id: item?.packageType?.value?.id || null,
          adr: item?.adr ? true : false,
          adr_class: item?.adrClass || null,
          un_number: item?.adrNumber || null,
          hs_code: item?.HSCodeValue || null,
          oversized: false,
          oversized_call: null,
          price: cargoPrice || null,
          price_currency_id: cargoPriceCurrency?.value?.id || null,
          cargo_ready_date: readyCargoTime ? moment(readyCargoTime).format("YYYY-MM-DD") : null,
          pickup_date: getOrderResult?.cargos?.[0]?.pickup_date
            ? moment(getOrderResult?.cargos[0].pickup_date).format("YYYY-MM-DD")
            : getNextDate(),
          pickup_time_start: null,
          pickup_time_end: null,
          dropoff_date: null,
          dropoff_time_start: null,
          dropoff_time_end: null,
          locations: locations.map((loc) => ({
            id: loc?.id || null,
            direction: loc?.direction || null,
            type: loc?.type || "LOCALITY",
            inputted_address: loc?.inputted_address || null,
          })),
          dimensions: item.dimensions.map((i) => {
            const volume = parseFloat(
              (((i?.length || 0) * (i?.width || 0) * (i?.height || 0)) / 1000000).toFixed(5)
            );
            return {
              id: i?.id || null,
              count: i?.count || 1,
              weight: i?.weight || 0,
              volume: volume || null,
              length: i?.length || null,
              width: i?.width || null,
              height: i?.height || null,
            };
          }),
        };
      }),
    };

    dispatch(updateOrder(data));
  };

  return (
    <Wrapper documentTitle={t("shipper.titles.Air freight")}>
      {getOrderLoading && <LoadingPage />}
      {getOrderError && <PageNotFound />}
      {getOrderResult && (
        <Fragment>
          <AirTopMenu locations={locations} group={group} time={readyCargoTime} />
          <section id='ShipperEditOrderAir'>
            <div className='ShipperEditOrderAirWrapper'>
              <CreateOrderHeader />
              <AirLocation edit={true} locations={locations} setLocations={setLocations} />
              <AirPackage edit={true} group={group} setGroup={setGroup} />
              <CreateOrderAditionalServices
                title={t("shipper.forms.titles.Additional Services")}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
              />
              <CreateOrderAditionalInformation
                edit={true}
                title={t("shipper.forms.titles.Additional information")}
                /////
                readyCargoTime={readyCargoTime}
                setReadyCargoTime={setReadyCargoTime}
                readyCargoTimeError={
                  updateOrderError?.errors?.["cargos.0.cargo_ready_date"]?.[0] ?? null
                }
                /////
                cargoPrice={cargoPrice}
                setCargoPrice={setCargoPrice}
                cargoPriceError={updateOrderError?.errors?.["cargos.0.price"]?.[0] ?? null}
                /////
                cargoPriceCurrency={cargoPriceCurrency}
                setCargoPriceCurrency={setCargoPriceCurrency}
                cargoPriceCurrencyError={
                  updateOrderError?.errors?.["cargos.0.price_currency_id"]?.[0] ?? null
                }
                //////
                comment={comment}
                setComment={setComment}
                commentError={updateOrderError?.errors?.["about"]?.[0] ?? null}
              />
              <CreateOrderSubmit
                icon={<FiCheck />}
                title={t("shipper.buttons.Save")}
                loading={updateOrderLoading}
                onClick={() => submitHandler()}
              />
            </div>
          </section>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default EditAirOrder;
