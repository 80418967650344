import React, { Fragment } from "react";
import "./FormHeader.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useMoney } from "./../../../../../../helper/useMoney";

const FormHeader = ({ order }) => {
  const { t } = useTranslation();
  const { getInvoiceResult } = useSelector((state) => state.invoices);

  const charges = useMoney(order.total_revenue || 0, order.revenue_currency.symbol || "");

  const payd = useMoney(order.total_client_paid || 0, order.revenue_currency.symbol || "");

  const remaining = useMoney(
    order?.total_revenue - order?.total_client_paid || 0,
    order.revenue_currency.symbol || ""
  );

  return (
    <Fragment>
      {getInvoiceResult && (
        <div id='invoiceFormHeader'>
          <div className='title'>
            <h1>{t("tms.invoice.Invoice information")}</h1>
          </div>
          <div className='card'>
            <div className='top'>
              <div className='item'>
                <h4>{t("tms.invoice.Total Charge")}</h4>
                <h3>{charges}</h3>
              </div>
              <div className='item'>
                <h4>{t("tms.invoice.Already paid")}</h4>
                <h3>
                  <span>{payd}</span>
                </h3>
              </div>
              <div className='item'>
                <h4>{t("tms.invoice.Remaining amount")}</h4>
                <h3>{remaining}</h3>
              </div>
            </div>
            <div className='payment'>
              <h4>
                {t("tms.invoice.Payment condition")}:{" "}
                <span>{getInvoiceResult?.paymentType?.type?.name || ""}</span>
              </h4>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default FormHeader;
