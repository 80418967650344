import React from "react";
import "./CreateOrderAditionalServicesItem.scss";

import { FiPlus, FiCheck } from "react-icons/fi";

const CreateOrderAditionalServicesItem = ({
  item,
  selectedServices,
  setSelectedServices,
}) => {
  const submitHandler = () => {
    const isSelected = selectedServices.find(
      (service) => service.id === item.id
    );
    if (isSelected) {
      const updatedServices = selectedServices.filter(
        (service) => service.id !== item.id
      );
      setSelectedServices(updatedServices);
    } else {
      setSelectedServices([...selectedServices, item]);
    }
  };
  const isSelected = selectedServices.some((service) => service.id === item.id);

  return (
    <div
      id='CreateOrderAditionalServicesItem'
      className={isSelected ? "active" : ""}
      onClick={() => submitHandler()}
    >
      <h6>{item.name}</h6>
      <div className='icon'>{isSelected ? <FiCheck /> : <FiPlus />}</div>
    </div>
  );
};

export default CreateOrderAditionalServicesItem;
