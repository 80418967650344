import React, { useEffect } from "react";
import "./OrderDetails.scss";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getOrder, resetGetOrder } from "../../../../../store/orders/ordersSlice";

import Wrapper from "../../../../../componentsAdditional/Wrapper";
import ShipperOrdersDetailsHeader from "../../../../../componentsDashboard/Orders/ShipperOrdersDetailsHeader/ShipperOrdersDetailsHeader";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import PageNotFound from "../../../../../componentsBids/PageNotFound/PageNotFound";
import OrderDetailsWrapper from "../../../../../componentsDashboard/Orders/OrderDetailsWrapper/OrderDetailsWrapper";
import ShipperOrderDetailsOffer from "./../../../../../componentsDashboard/Orders/ShipperOffers/ShipperOrderDetailsOffer/ShipperOrderDetailsOffer";

const OrderDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const { getOrderLoading, getOrderResult, getOrderError } = useSelector((state) => state.orders);
  const { rejectOrderResult, takeOrderResult } = useSelector((state) => state.shipperOrders);

  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => dispatch(resetGetOrder());
  }, [dispatch, orderId, rejectOrderResult, takeOrderResult]);

  return (
    <Wrapper documentTitle={t("shipper.titles.Order Details")}>
      {getOrderLoading && <LoadingPage />}
      {getOrderError && <PageNotFound />}
      {getOrderResult && (
        <section id='OrderDetails'>
          <ShipperOrdersDetailsHeader order={getOrderResult} />
          <ShipperOrderDetailsOffer order={getOrderResult} />
          <OrderDetailsWrapper opened order={getOrderResult} />
        </section>
      )}
    </Wrapper>
  );
};

export default OrderDetails;
