import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import partnerContactsService from "./partnerContactsService";

const initialState = {
  getPartnerContactsLoading: false,
  getPartnerContactsResult: null,
  getPartnerContactsError: null,

  createPartnerContactLoading: false,
  createPartnerContactResult: null,
  createPartnerContactError: null,

  updatePartnerContactLoading: false,
  updatePartnerContactResult: null,
  updatePartnerContactError: null,

  deletePartnerContactLoading: false,
  deletePartnerContactResult: null,
  deletePartnerContactError: null,

  getPartnerContactPositionsLoading: true,
  getPartnerContactPositionsResult: null,
  getPartnerContactPositionsSelect: null,
  getPartnerContactPositionsError: null,
};

export const getPartnerContacts = createAsyncThunk(
  "partnerContacts/getPartnerContacts",
  async (id, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await partnerContactsService.getPartnerContacts(ISO, TOKEN, id);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createPartnerContact = createAsyncThunk(
  "partnerContacts/createPartnerContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await partnerContactsService.createPartnerContact(ISO, TOKEN, data.id, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePartnerContact = createAsyncThunk(
  "partnerContacts/updatePartnerContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await partnerContactsService.updatePartnerContact(
        ISO,
        TOKEN,
        data.id,
        data.contactId,
        data
      );
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePartnerContact = createAsyncThunk(
  "partnerContacts/deletePartnerContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await partnerContactsService.deletePartnerContact(ISO, TOKEN, data.id, data.contactId);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPartnerContactPositions = createAsyncThunk(
  "partnerContacts/getPartnerContactPositions",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await partnerContactsService.getPartnerContactPositions(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const partnerContactsSlice = createSlice({
  name: "partnerContacts",
  initialState,
  reducers: {
    resetGetPartnerContacts: (state) => {
      state.getPartnerContactsLoading = false;
      state.getPartnerContactsResult = null;
      state.getPartnerContactsError = null;
    },
    resetCreatePartnerContact: (state) => {
      state.createPartnerContactLoading = false;
      state.createPartnerContactResult = null;
      state.createPartnerContactError = null;
    },
    resetUpdatePartnerContact: (state) => {
      state.updatePartnerContactLoading = false;
      state.updatePartnerContactResult = null;
      state.updatePartnerContactError = null;
    },
    resetDeletePartnerContact: (state) => {
      state.deletePartnerContactLoading = false;
      state.deletePartnerContactResult = null;
      state.deletePartnerContactError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPartnerContacts.pending, (state) => {
        state.getPartnerContactsLoading = true;
        state.getPartnerContactsResult = null;
        state.getPartnerContactsError = null;
      })
      .addCase(getPartnerContacts.fulfilled, (state, action) => {
        state.getPartnerContactsLoading = false;
        state.getPartnerContactsResult = action.payload;
        state.getPartnerContactsError = null;
      })
      .addCase(getPartnerContacts.rejected, (state, action) => {
        state.getPartnerContactsLoading = false;
        state.getPartnerContactsResult = null;
        state.getPartnerContactsError = action.payload;
      })

      .addCase(createPartnerContact.pending, (state) => {
        state.createPartnerContactLoading = true;
        state.createPartnerContactResult = null;
        state.createPartnerContactError = null;
      })
      .addCase(createPartnerContact.fulfilled, (state, action) => {
        state.createPartnerContactLoading = false;
        state.createPartnerContactResult = action.payload;
        state.createPartnerContactError = null;
      })
      .addCase(createPartnerContact.rejected, (state, action) => {
        state.createPartnerContactLoading = false;
        state.createPartnerContactResult = null;
        state.createPartnerContactError = action.payload;
      })

      .addCase(updatePartnerContact.pending, (state) => {
        state.updatePartnerContactLoading = true;
        state.updatePartnerContactResult = null;
        state.updatePartnerContactError = null;
      })
      .addCase(updatePartnerContact.fulfilled, (state, action) => {
        state.updatePartnerContactLoading = false;
        state.updatePartnerContactResult = action.payload;
        state.updatePartnerContactError = null;
      })
      .addCase(updatePartnerContact.rejected, (state, action) => {
        state.updatePartnerContactLoading = false;
        state.updatePartnerContactResult = null;
        state.updatePartnerContactError = action.payload;
      })

      .addCase(deletePartnerContact.pending, (state) => {
        state.deletePartnerContactLoading = true;
        state.deletePartnerContactResult = null;
        state.deletePartnerContactError = null;
      })
      .addCase(deletePartnerContact.fulfilled, (state, action) => {
        state.deletePartnerContactLoading = false;
        state.deletePartnerContactResult = action.payload;
        state.deletePartnerContactError = null;
      })
      .addCase(deletePartnerContact.rejected, (state, action) => {
        state.deletePartnerContactLoading = false;
        state.deletePartnerContactResult = null;
        state.deletePartnerContactError = action.payload;
      })

      .addCase(getPartnerContactPositions.pending, (state) => {
        state.getPartnerContactPositionsLoading = true;
        state.getPartnerContactPositionsResult = null;
        state.getPartnerContactPositionsSelect = null;
        state.getPartnerContactPositionsError = null;
      })
      .addCase(getPartnerContactPositions.fulfilled, (state, action) => {
        state.getPartnerContactPositionsLoading = false;
        state.getPartnerContactPositionsResult = action.payload;
        state.getPartnerContactPositionsSelect = action.payload.map((item) => ({
          label: item.name,
          value: item,
        }));
        state.getPartnerContactPositionsError = null;
      })
      .addCase(getPartnerContactPositions.rejected, (state, action) => {
        state.getPartnerContactPositionsLoading = false;
        state.getPartnerContactPositionsResult = null;
        state.getPartnerContactPositionsSelect = null;
        state.getPartnerContactPositionsError = action.payload;
      });
  },
});

export const {
  resetGetPartnerContacts,
  resetCreatePartnerContact,
  resetUpdatePartnerContact,
  resetDeletePartnerContact,
} = partnerContactsSlice.actions;
export default partnerContactsSlice.reducer;
