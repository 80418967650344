import React from "react";

import { useMoney } from "./../../../../helper/useMoney";

const PartnerListItem = ({ item, onClick }) => {
  const formattedMoney = useMoney(
    item?.amount || 0,
    item?.currency?.symbol || "-"
  );

  return (
    <li onClick={onClick} className='partnerListItem'>
      <h1>{item?.partner?.company?.name || ""}</h1>
      <h3>{formattedMoney}</h3>
    </li>
  );
};

export default PartnerListItem;
