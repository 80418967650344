import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteOrderCost,
  resetDeleteOrderCost,
} from "../../../../../../store/orders/costs/orderCostsSlice";

import DModal from "../../../../../Modal/DModal/DModal";

const DeleteCostModal = ({ isOpen, isClose, costId, orderId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { deleteOrderCostLoading, deleteOrderCostResult } = useSelector(
    (state) => state.orderCosts
  );

  const submitHandler = () => {
    const data = {
      orderId,
      costId,
    };
    dispatch(deleteOrderCost(data));
  };

  useEffect(() => {
    if (deleteOrderCostResult) {
      dispatch(resetDeleteOrderCost());
    }
  }, [dispatch, deleteOrderCostResult]);

  useEffect(() => {
    return () => {
      dispatch(resetDeleteOrderCost());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        onClick={() => submitHandler()}
        title={t("tms.modals.titles.Delete cost")}
        submitText={t("tms.modals.buttons.Delete")}
        icon={<FiTrash2 />}
        status='danger'
        loading={deleteOrderCostLoading}
      >
        <p>{t("tms.modals.desc.Are you sure you want to delete your cost?")}</p>
      </DModal>
    </Fragment>
  );
};

export default DeleteCostModal;
