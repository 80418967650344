import React, { Fragment, useState } from "react";
import "./ViewInstructionButton.scss";

import { useTranslation } from "react-i18next";
import { IoMdEye } from "react-icons/io";
import ViewInstructionModal from "./ViewInstructionModal/ViewInstructionModal";

const ViewInstructionButton = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <div id='ViewInstructionButton' onClick={() => setOpen(true)}>
        <h3>{t("tms.forms.buttons.View Instruction")}</h3>
        <div className='icon'>
          <IoMdEye />
        </div>
      </div>
      <ViewInstructionModal isOpen={open} isClose={() => setOpen(false)} />
    </Fragment>
  );
};

export default ViewInstructionButton;
