import React from "react";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import DSelect from "../DSelect";

const DCountrySelect = ({ value, setValue, label, error }) => {
  const { t } = useTranslation();

  const { getAllCountriesLoading, getAllCountriesResult } = useSelector(
    (state) => state.settings
  );

  const countriesArray = getAllCountriesResult?.map((country) => {
    return {
      label: country.name,
      value: country,
    };
  });

  return (
    <DSelect
      id='DCountrySelect'
      label={label}
      options={countriesArray || []}
      placeholder={t("tms.filters.placeholders.Choose country")}
      value={value}
      setValue={setValue}
      loading={getAllCountriesLoading}
      error={error}
      search={true}
      emptyText={t("tms.filters.others.Country not found")}
    />
  );
};

export default DCountrySelect;
