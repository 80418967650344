import React, { useState, useEffect } from "react";
import "./TrailersListHeader.scss";

import { useTranslation } from "react-i18next";

import { FiPlus } from "react-icons/fi";

import DSearch from "../../../FilterForms/DSearch/DSearch";
import DButton from "../../../Form/DButtons/DButton/DButton";
import FilterLinkedSelect from "../../../FilterForms/FilterLinkedSelect/FilterLinkedSelect";
import FilterTrailerGroupSelect from "./../../../FilterForms/FilterTrailerGroupSelect/FilterTrailerGroupSelect";

const TrailersListHeader = ({
  setKeyword,
  linkedTo,
  setLinkedTo,
  setOpenAddModal,
  trailerType,
  setTrailerType,
}) => {
  const { t } = useTranslation();

  const [keywordValue, setKeywordValue] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setKeyword(keywordValue);
    }, 500);
    return () => clearInterval(timeout);
  }, [keywordValue, setKeyword]);

  return (
    <div id='TrailersListHeader'>
      <div className='TrailersListHeaderLeft'>
        <DSearch value={keywordValue} setValue={(e) => setKeywordValue(e)} />
        <FilterLinkedSelect value={linkedTo} setValue={setLinkedTo} />
        <FilterTrailerGroupSelect value={trailerType} setValue={setTrailerType} />
      </div>
      <div>
        <DButton
          title={t("tms.forms.buttons.Add trailer")}
          rightIcon={<FiPlus />}
          onClick={() => {
            setOpenAddModal(true);
          }}
        />
      </div>
    </div>
  );
};

export default TrailersListHeader;
