import React, { Fragment } from "react";
import "./LandTrailerDetails.scss";

import { useTranslation } from "react-i18next";

const LandTrailerDetails = ({ getOrderResult }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div id='LandTrailerDetails'>
        <div className='titleContainer'>
          <div className='icon'>
            <img src='/assets/images/dashboard/cover7.svg' alt='container' />
          </div>
          <h1 className='title'>{t("shipper.forms.titles.Trailer need")}</h1>
        </div>
        {getOrderResult?.cargos?.length > 0 &&
          getOrderResult?.cargos?.map((cargo, index) => (
            <div className='LandTrailerDetailsShipperContainer' key={cargo?.id}>
              <div className='LandTrailerDetailsShipperCover'>
                <div className='LandTrailerDetailsShipperIndex'>
                  {getOrderResult?.cargos.length > 1 && index + 1}
                </div>
                <div
                  className='LandTrailerDetailsShipperImg'
                  style={{
                    backgroundImage: `url(${cargo?.trailerType?.trailer_icon})`,
                  }}
                />
              </div>
              <div className='LandTrailerDetailsShippercontent'>
                <p>
                  {t("shipper.forms.content.Trailer type")}:{" "}
                  <span>{cargo?.trailerType?.name || "-"}</span>
                </p>
                <p>
                  {t("shipper.forms.content.Trailer volume")}:{" "}
                  <span>{cargo.trailersize?.label || "-"}</span>
                </p>
                {cargo?.trailerType?.temperature && cargo?.temperature && (
                  <p>
                    {t("shipper.forms.content.Temperature")}:{" "}
                    <span>{cargo?.trailerType?.temperature && cargo?.temperature}</span>
                  </p>
                )}
              </div>
            </div>
          ))}
      </div>
    </Fragment>
  );
};

export default LandTrailerDetails;
