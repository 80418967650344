import React from "react";
import "./LogoutModal.scss";

import { useTranslation } from "react-i18next";
import { CiLogout } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../../../../store/auth/authSlice";

import DModal from "../../../../Modal/DModal/DModal";

const LogoutModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const submitHandler = () => {
    dispatch(logout());
  };

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => submitHandler()}
      title={t("tms.modals.titles.Logout")}
      submitText={t("tms.modals.buttons.Logout")}
      status='danger'
      icon={<CiLogout />}
      loading={loading}
    >
      <p>{t("tms.modals.desc.Are you sure you want to logout?")}</p>
    </DModal>
  );
};

export default LogoutModal;
