import React from "react";
import "./SignInShipperVerify.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import SignInShipperVerifyForm from "../../../../componentsLanding/Auth&Password/LoginForm/SignInShipperVerifyForm/SignInShipperVerifyForm";

const SignInShipperVerify = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Sign Up shipper")}>
      <section id='SignInShipperVerify'>
        <AuthCover url='/assets/images/static/cover12.jpg' />
        <SignInShipperVerifyForm />
      </section>
    </Wrapper>
  );
};

export default SignInShipperVerify;
