import React, { useState, useEffect } from "react";
import "./AccountantEmailCreateModal.scss";

import { MdOutlineGroupAdd, MdOutlineEmail } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  createContact,
  resetCreateContact,
} from "./../../../../../../store/user/userContacts/userContactsSlice";

import DModal from "./../../../../../Modal/DModal/DModal";
import DInputs from "./../../../../../Form/DInputs/DInputs";

const AccountantEmailCreateModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { createContactLoading, createContactResult, createContactError } = useSelector(
    (state) => state.userContacts
  );

  const [email, setEmail] = useState("");

  useEffect(() => {
    if (createContactResult) {
      dispatch(resetCreateContact());
      isClose();
    }
  }, [dispatch, isClose, createContactResult]);

  const submitHandler = () => {
    const data = {
      type_id: 1,
      email: email,
    };
    dispatch(createContact(data));
  };

  return (
    <DModal
      status={"aditionalInfo"}
      isOpen={isOpen}
      isClose={isClose}
      icon={<MdOutlineGroupAdd />}
      title={t("shipper.modal.Add accountant email")}
      submitText={t("shipper.modal.Add accountant")}
      onClick={() => submitHandler()}
      loading={createContactLoading}
    >
      <div id='AccountantEmailCreateModal'>
        <div className='AccountantEmailCreateModalWrapper'>
          <DInputs
            id='AccountantEmailCreateModalEmail'
            placeholder={t("shipper.modal.Enter email")}
            type='email'
            label={t("shipper.modal.Email")}
            value={email}
            setValue={setEmail}
            icon={<MdOutlineEmail />}
            error={createContactError?.errors?.email?.[0] ?? null}
            required
          />
          <p className='description'>
            {t("shipper.modal.We will automatically send invoices to your accountant as well")}
          </p>
        </div>
      </div>
    </DModal>
  );
};

export default AccountantEmailCreateModal;
