import React, { useState, useEffect } from "react";
import "./CustomersListsHeader.scss";

import { useTranslation } from "react-i18next";
import { FaPlusCircle } from "react-icons/fa";

import DButton from "../../../Form/DButtons/DButton/DButton";
import DSearch from "../../../FilterForms/DSearch/DSearch";

const CustomersListsHeader = ({ setKeyword, setAddModalShow }) => {
  const { t } = useTranslation();

  const [keywordValue, setKeywordValue] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setKeyword(keywordValue);
    }, 500);
    return () => clearInterval(timeout);
  }, [keywordValue, setKeyword]);

  return (
    <div id='CustomersListsHeader'>
      <DSearch value={keywordValue} setValue={(e) => setKeywordValue(e)} />
      <DButton
        onClick={() => setAddModalShow(true)}
        title={t("tms.forms.buttons.Add customer")}
        rightIcon={<FaPlusCircle />}
      />
    </div>
  );
};

export default CustomersListsHeader;
