import React from "react";
import "./CreateOrderAditionalInformation.scss";

import { useTranslation } from "react-i18next";

import DDatepicker from "./../../../../Form/DDatepicker/DDatepicker";
import DCurrencyInput from "../../../../Form/DCurrencyInput/DCurrencyInput";
import DTextarea from "./../../../../Form/DTextarea/DTextarea";
import DFileFormLarge from "../../../../Form/DFile/DFileFormLarge/DFileFormLarge";
import CreateOrderSectionTitle from "../CreateOrderSectionTitle/CreateOrderSectionTitle";
import DFileFormLargeItem from "../../../../Form/DFile/DFileFormLarge/DFileFormLargeItem";

const CreateOrderAditionalInformation = ({
  edit,
  title,
  /////
  readyCargoTime,
  setReadyCargoTime,
  readyCargoTimeError,
  ////
  cargoPrice,
  setCargoPrice,
  cargoPriceError,
  /////
  cargoPriceCurrency,
  setCargoPriceCurrency,
  cargoPriceCurrencyError,
  ////
  comment,
  setComment,
  commentError,
  ////
  aditionalDocument,
  setAditionalDocument,
}) => {
  const { t } = useTranslation();

  const removeFileHandler = (id) => {
    const copyArray = [...aditionalDocument];
    const filterredCopyArrayy = copyArray.filter((i) => i.id !== id);
    setAditionalDocument(filterredCopyArrayy);
  };

  return (
    <div id='CreateOrderAditionalInformation'>
      <CreateOrderSectionTitle title={title} />
      <div className='selectContainers'>
        <div className='dateContainer'>
          <DDatepicker
            value={readyCargoTime}
            setValue={setReadyCargoTime}
            placeholder='DD/MM/YYYY'
            label={t("shipper.forms.content.When will cargo be ready?")}
            error={readyCargoTimeError}
          />
        </div>

        <div className='moneyContainer'>
          <DCurrencyInput
            id='AirShipperCreateCargoprice'
            value={cargoPrice}
            setValue={setCargoPrice}
            currencyValue={cargoPriceCurrency}
            setCurrencyValue={setCargoPriceCurrency}
            label={t("shipper.forms.content.Cargo price")}
            placeholder={t("shipper.forms.content.Cargo price")}
            error={cargoPriceError || cargoPriceCurrencyError}
          />
        </div>
      </div>

      {setAditionalDocument && !edit && (
        <div className='docsContainer'>
          {setAditionalDocument &&
            !edit &&
            aditionalDocument.map((item, index) => (
              <DFileFormLargeItem
                key={index}
                item={item}
                removeItem={(id) => removeFileHandler(id)}
              />
            ))}
          <DFileFormLarge
            value={aditionalDocument}
            setValue={setAditionalDocument}
            title={t("shipper.forms.content.Attach document")}
            subTitle={t("shipper.forms.content.Optional")}
            type_id={12}
          />
        </div>
      )}

      {setComment && (
        <div className='commentContainer'>
          <DTextarea
            id='AirShipperCreateComment'
            value={comment}
            setValue={setComment}
            placeholder={t("shipper.forms.content.Additional comment")}
            error={commentError}
          />
        </div>
      )}
    </div>
  );
};

export default CreateOrderAditionalInformation;
