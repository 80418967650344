import React, { useState } from "react";
import "./FilterRouteSelectForm.scss";

import { useTranslation } from "react-i18next";

import DDropdown from "../DDropdown/DDropdown";
import DButton from "../../Form/DButtons/DButton/DButton";
import DCountrySelect from "../../Form/DSelect/DCountrySelect/DCountrySelect";

const FilterRouteSelectForm = ({ value, setValue }) => {
  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fromCountry, setFromCountry] = useState("");
  const [toCountry, setToCountry] = useState("");

  return (
    <div id='FilterRouteSelectForm'>
      <DDropdown
        value={value}
        setValue={setValue}
        placeholder={t("tms.filters.placeholders.Route")}
        dropdownOpen={dropdownOpen}
        setDropdownOpen={setDropdownOpen}
      >
        <div className='dropdownContentContainer'>
          <DCountrySelect
            label={t("tms.filters.labels.From")}
            value={fromCountry}
            setValue={setFromCountry}
          />
          <DCountrySelect
            label={t("tms.filters.labels.To")}
            value={toCountry}
            setValue={setToCountry}
          />
          <div className='saveButton'>
            <DButton
              title={t("tms.filters.buttons.Save")}
              disabled={!fromCountry && !toCountry}
              onClick={() => {
                setValue({
                  fromCountry,
                  toCountry,
                });
                setDropdownOpen(false);
              }}
            />
          </div>
        </div>
      </DDropdown>
    </div>
  );
};

export default FilterRouteSelectForm;
