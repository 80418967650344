import React, { Fragment, useEffect } from "react";
import "./DeleteTrucksModal.scss";

import { useTranslation } from "react-i18next";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteTruck,
  resetDeleteTruck,
} from "../../../../store/fleet/trucks/trucksSlice";

import DModal from "../../../Modal/DModal/DModal";

const DeleteTrucksModal = ({ isOpen, isClose, truckId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deleteTruckLoading, deleteTruckResult } = useSelector(
    (state) => state.trucks
  );

  const submitHandler = () => {
    dispatch(deleteTruck(truckId));
  };

  useEffect(() => {
    if (deleteTruckResult) {
      dispatch(resetDeleteTruck());
      isClose();
      navigate("/dashboard/tms/fleet_management/trucks");
    }
  }, [dispatch, navigate, isClose, deleteTruckResult]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Delete Truck")}
        submitText={t("tms.modals.buttons.Delete")}
        icon={<FiTrash2 />}
        status='danger'
        onClick={() => submitHandler()}
        loading={deleteTruckLoading}
      >
        <div id='DeleteTrucksModal'>
          <p>
            {t("tms.modals.desc.Are you sure you want to delete this truck?")}
          </p>
        </div>
      </DModal>
    </Fragment>
  );
};

export default DeleteTrucksModal;
