import React, { Fragment } from "react";
import "./UploadDocumentItem.scss";

import { FiTrash2 } from "react-icons/fi";
import { LuFolder } from "react-icons/lu";

import Character from "../../../../UI/Character/Character";

const UploadDocumentItem = ({ fileName, onClick }) => {
  return (
    <Fragment>
      <div id='UploadDocumentItem'>
        <div className='UploadDocumentItemContainer'>
          <div className='item'>
            <div className='icon'>
              <LuFolder />
            </div>
            <h1>
              <Character title={(fileName && fileName) || "-"} max={30} />
            </h1>
          </div>
          <div className='action'>
            <div className='icon delete' onClick={onClick}>
              <FiTrash2 />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UploadDocumentItem;
