import React from "react";

import { useTranslation } from "react-i18next";

import OrdersTableItem from "./OrdersTableItem/OrdersTableItem";
import TableHeader from "../../Form/TableFields/TableHeader/TableHeader";
import TableWrapper from "./../../Form/TableFields/TableWrapper/TableWrapper";

const OrdersTable = ({ orders, page, setPage, pageCount, total }) => {
  const { t } = useTranslation();

  return (
    <TableWrapper
      total={total}
      emptyText={t("tms.tables.noInfo.No orders")}
      page={page}
      setPage={setPage}
      pageCount={pageCount}
    >
      <TableHeader>
        <th>{t("tms.tables.titles.Order number")}</th>
        <th>{t("tms.tables.titles.Status")}</th>
        <th>{t("tms.tables.titles.Customer")}</th>
        <th>{t("tms.tables.titles.Route")}</th>
        <th>{t("tms.tables.titles.Driver")}</th>
        <th>{t("tms.tables.titles.Trailer type")}</th>
        <th>{t("tms.tables.titles.Pick up date")}</th>
        <th colSpan='2'>{t("tms.tables.titles.Price")}</th>
      </TableHeader>
      <tbody>
        {orders.map((order) => (
          <OrdersTableItem key={order.id} order={order} />
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default OrdersTable;
