import React, { useEffect } from "react";
import "./Terms.scss";

import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import { getTerms } from "./../../../../../store/additional/rules/rulesSlice";

import Wrapper from "./../../../../../componentsAdditional/Wrapper";

const Terms = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { getTermsResult } = useSelector((state) => state.rules);

  useEffect(() => {
    dispatch(getTerms());
  }, [dispatch]);

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Terms & Conditions")}>
      <section id='Terms'>
        <div className='wrapper'>
          {getTermsResult && (
            <div className='content'>
              <div className='title'>
                <h1>{getTermsResult?.title && getTermsResult?.title}</h1>
              </div>
              <div className='body'>{parse(getTermsResult?.body && getTermsResult?.body)}</div>
            </div>
          )}
        </div>
      </section>
    </Wrapper>
  );
};

export default Terms;
