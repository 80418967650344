import React from "react";
import "./WhyUs.scss";

import { useTranslation } from "react-i18next";

import { PiArrowBendRightDownBold } from "react-icons/pi";
import { MdOutlineUpdate, MdSupportAgent } from "react-icons/md";
import { TbClick } from "react-icons/tb";

import parse from "html-react-parser";

const Answer = ({ icon, text }) => {
  return (
    <div className='answer'>
      <div className='icon'>{icon}</div>
      <div className='text'>{text}</div>
    </div>
  );
};

const WhyUs = () => {
  const { t } = useTranslation();
  return (
    <section id='CarrierSubscribeNowWhyUs' className='CarrierSubscribeNowWhyUs'>
      <div className='wrapper'>
        <div className='title'>
          {parse(
            t(
              "landing.landingCarrierSubscribe.High Value shipments to <span>Caucasus</span> and <span>Central Asia</span>"
            )
          )}
        </div>
        <div className='whyUsContainer'>
          <div className='whyUs'>
            <div className='text'>{t("landing.landingCarrierSubscribe.Why us?")}</div>
            <div className='icon'>
              <PiArrowBendRightDownBold />
            </div>
          </div>
        </div>
        <div className='linker'>
          <div className='answersContainer'>
            <Answer
              icon={<MdOutlineUpdate />}
              text={t(
                "landing.landingCarrierSubscribe.Daily morning updates with available shipments"
              )}
            />
            <Answer
              icon={<TbClick />}
              text={t("landing.landingCarrierSubscribe.Simple 1-click bidding process")}
            />
            <Answer
              icon={<MdSupportAgent />}
              text={t("landing.landingCarrierSubscribe.Dedicated support team")}
            />
          </div>
          <div className='arrow'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='222'
              height='227'
              viewBox='0 0 222 227'
              fill='none'
            >
              <path
                d='M1.99988 142.77C11.3684 150.869 19.2649 153.461 32.4356 155.163C47.5521 157.116 61.4244 151.213 72.1644 139.641C84.9502 125.866 96.9514 106.591 96.5214 88.6456C96.3938 83.3195 93.4575 74.2021 86.7395 75.1697C55.6667 79.6447 82.1819 109.146 92.458 116.729C120.57 137.475 162.782 115.603 191.294 91.139'
                stroke='#848A8D'
                strokeWidth='3'
                strokeLinecap='round'
              />
              <path
                d='M181.82 83.0604L206.696 81.8923C208.321 81.816 209.137 83.9012 208.034 85.31L192.121 105.635'
                stroke='#848A8D'
                strokeWidth='3'
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
