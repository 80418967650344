import React, { Fragment, useState, useEffect } from "react";
import "./Header.scss";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import LogoContainer from "./Components/LogoContainer/LogoContainer";
import RegisterButton from "./Components/RegisterButton/RegisterButton";
import BurgerIcon from "./Components/BurgerIcon/BurgerIcon";
import NavItem from "./Components/NavItem/NavItem";
import Lang from "./../Controls/Lang/Lang";
import LangMobileMenu from "./../Controls/LangMobileButton/LangMobileButton";
import Sticky from "./Components/Sticky/Sticky";
import NavItemMobile from "./Components/NavItemMobile/NavItemMobile";
import Dropdown from "./Components/Dropdown/Dropdown";
import LangMobileList from "./../Controls/LangMobileList/LangMobileList";
import Logo from "./../../componentsAdditional/SVG/Cargon/Logo";
import CountrySwitcher from "./Components/CountrySwitcher/CountrySwitcher";
import CountryMobileList from "../Controls/CountryMobileList/CountryMobileList";
import CountryMobileMenu from "../Controls/CountryMobileMenu/CountryMobileMenu";

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const [openLang, setOpenLang] = useState(false);
  const [openCountry, setOpenCountry] = useState(false);
  const [isCountrySwitcherOpen, setIsCountrySwitcherOpen] = useState(true);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [pathname, setOpen]);

  return (
    <Fragment>
      <CountrySwitcher
        isOpen={isCountrySwitcherOpen}
        setIsOpen={setIsCountrySwitcherOpen}
      />
      <header id='Header' className={isCountrySwitcherOpen ? "open" : ""}>
        <div className='wrapper'>
          <div className='headerContainer'>
            <LogoContainer />

            <nav className='nav'>
              <ul className='navLeft'>
                <NavItem
                  title={t("landing.navigation.Shipper")}
                  to='/shipper'
                />
                <NavItem
                  title={t("landing.navigation.Carrier")}
                  to='/carrier'
                />
                <Dropdown
                  title={t("landing.navigation.Service")}
                  menu={[
                    {
                      title: t("landing.navigation.Road freight"),
                      to: "/services/land",
                    },
                    {
                      title: t("landing.navigation.Sea freight"),
                      to: "/services/sea",
                    },
                    {
                      title: t("landing.navigation.Air freight"),
                      to: "/services/air",
                    },
                  ]}
                />
              </ul>
              <div className='navRight'>
                <ul className='menu'>
                  <NavItem
                    title={t("landing.navigation.Sign In")}
                    to='/login'
                  />
                </ul>
                <div className='additional'>
                  <RegisterButton />
                  <Lang />
                  <BurgerIcon value={open} setValue={setOpen} />
                </div>
              </div>

              <div className={open ? "mobileMenu open" : "mobileMenu"}>
                <div className='mobileMenuContainer'>
                  <ul className='list'>
                    <NavItemMobile
                      title={t("landing.navigation.Sign In")}
                      to='/login'
                    />
                    <NavItemMobile
                      title={t("landing.navigation.Shipper")}
                      to='/shipper'
                    />
                    <NavItemMobile
                      title={t("landing.navigation.Carrier")}
                      to='/carrier'
                    />
                    <NavItemMobile
                      title={t("landing.navigation.Road freight")}
                      to='/services/land'
                    />
                    <NavItemMobile
                      title={t("landing.navigation.Sea freight")}
                      to='/services/sea'
                    />
                    <NavItemMobile
                      title={t("landing.navigation.Air freight")}
                      to='/services/air'
                    />
                  </ul>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <LangMobileMenu value={openLang} setValue={setOpenLang} />
                    <CountryMobileMenu
                      value={openCountry}
                      setValue={setOpenCountry}
                    />
                  </div>

                  <div className='mobileMenuLogo'>
                    <Logo color='rgba(0, 181, 108, 0.02)' />
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <LangMobileList value={openLang} setValue={setOpenLang} />
        <CountryMobileList value={openCountry} setValue={setOpenCountry} />
      </header>
      <Sticky isCountrySwitcherOpen={isCountrySwitcherOpen} />
    </Fragment>
  );
};

export default Header;
