import React, { Fragment, useState } from "react";
import "./ShowCustomerDetails.scss";

import { useTranslation } from "react-i18next";

import Character from "./../../../UI/Character/Character";
import ShowCustomerModal from "../ShowCustomerModal/ShowCustomerModal";
import DShowButton from "./../../../Form/DButtons/DShowButton/DShowButton";

const ShowCustomerDetails = ({ order }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  return (
    <Fragment>
      <div id='ShowCustomerDetails' onClick={() => setOpenModal(true)}>
        <div className='ShowCustomerDetailsLeft'>
          {t("tms.titles.Customer")}:{" "}
          <span>
            <Character
              title={order?.client?.name || t("tms.desc.No customer")}
              max={20}
            />
          </span>
        </div>
        <div className='ShowCustomerDetailsRight'>
          <DShowButton />
        </div>
      </div>

      <ShowCustomerModal isOpen={openModal} isClose={() => setOpenModal(false)} order={order} />
    </Fragment>
  );
};

export default ShowCustomerDetails;
