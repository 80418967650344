import React, { Fragment } from "react";
import "./TrailerAdditionalDetails.scss";

import { useTranslation } from "react-i18next";
import Character from "../../../../UI/Character/Character";

const TrailerAdditionalDetails = ({ trailer }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div id='TrailerAdditionalDetails'>
        <div className='TrailerAdditionalDetailsHeader'>
          <div className='TrailerAdditionalDetailsHeaderContainer'>
            <div
              className='cover'
              style={{
                backgroundImage: trailer?.type
                  ? `url(${trailer?.type?.trailer_icon})`
                  : `url(${`/assets/images/dashboard/cover7.svg`})`,
              }}
            ></div>
            <div className='TrailerAdditionalDetailsHeaderContent'>
              <h3>
                <Character title={(trailer?.type?.name && trailer?.type?.name) || "-"} max={30} />
              </h3>
              <h4>
                {trailer?.weight_capacity && trailer?.weight_capacity + `(${t("tms.desc.KG")})`}
                {trailer?.weight_capacity && trailer?.volume_capacity && " - "}
                {trailer?.volume_capacity && trailer?.volume_capacity + `(${t("tms.desc.M³")})`}
              </h4>
              <p>{trailer.registration_number}</p>
              {/* <div className='AddDowntime'>Add downtime...</div> */}
            </div>
          </div>
          {trailer.truck ? (
            <div className='TrailerAdditionalDetailsStatus'>{t("tms.desc.Linked to truck")}</div>
          ) : (
            <div className='NotLinkedTruck'>{t("tms.desc.Unlinked to truck")}</div>
          )}
        </div>
        <div className='LinkedTruck'>
          <h1>{t("tms.titles.Linked truck")}</h1>
          <div className='LinkedTruckContainer'>
            {trailer.truck ? (
              <div className='LinkedTruckContainerItem'>
                <div
                  className='cover'
                  style={{
                    backgroundImage: `url(${`/assets/images/dashboard/cover8.svg`})`,
                  }}
                ></div>
                <div>
                  <h3>{trailer.truck.model.name}</h3>
                  <p>{trailer.truck.registration_number}</p>
                </div>
              </div>
            ) : (
              <p className='noLinkToTruck'>{t("tms.desc.No link to truck")}</p>
            )}

            <div className='LinkedTruckContainerAction'>
              {/* <div className='actionChange'>change...</div> */}
              {/* <DLinkOffButton onClick={() => setOpenTruckLinkOffModal(true)} /> */}
            </div>
          </div>
        </div>
      </div>

      {/* <LinkOffTrailerModal
        isOpen={openTruckLinkOffModal}
        isClose={setOpenTruckLinkOffModal}
      /> */}
    </Fragment>
  );
};

export default TrailerAdditionalDetails;
