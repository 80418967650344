import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customerContactsService from "./customerContactsService";

const initialState = {
  getClientContactsLoading: false,
  getClientContactsResult: null,
  getClientContactsError: null,

  createClientContactLoading: false,
  createClientContactResult: null,
  createClientContactError: null,

  updateClientContactLoading: false,
  updateClientContactResult: null,
  updateClientContactError: null,

  deleteClientContactLoading: false,
  deleteClientContactResult: null,
  deleteClientContactError: null,

  getClientContactPositionsLoading: true,
  getClientContactPositionsResult: null,
  getClientContactPositionsSelect: null,
  getClientContactPositionsError: null,
};

export const getClientContacts = createAsyncThunk(
  "customerContacts/getClientContacts",
  async (id, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerContactsService.getClientContacts(ISO, TOKEN, id);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createClientContact = createAsyncThunk(
  "customerContacts/createClientContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerContactsService.createClientContact(ISO, TOKEN, data.id, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateClientContact = createAsyncThunk(
  "customerContacts/updateClientContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerContactsService.updateClientContact(
        ISO,
        TOKEN,
        data.id,
        data.contactId,
        data
      );
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteClientContact = createAsyncThunk(
  "customerContacts/deleteClientContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerContactsService.deleteClientContact(ISO, TOKEN, data.id, data.contactId);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getClientContactPositions = createAsyncThunk(
  "customerContacts/getClientContactPositions",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerContactsService.getClientContactPositions(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const customerContactsSlice = createSlice({
  name: "customerContacts",
  initialState,
  reducers: {
    resetGetClientContacts: (state) => {
      state.getClientContactsLoading = false;
      state.getClientContactsResult = null;
      state.getClientContactsError = null;
    },
    resetCreateClientContact: (state) => {
      state.createClientContactLoading = false;
      state.createClientContactResult = null;
      state.createClientContactError = null;
    },
    resetUpdateClientContact: (state) => {
      state.updateClientContactLoading = false;
      state.updateClientContactResult = null;
      state.updateClientContactError = null;
    },
    resetDeleteClientContact: (state) => {
      state.deleteClientContactLoading = false;
      state.deleteClientContactResult = null;
      state.deleteClientContactError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientContacts.pending, (state) => {
        state.getClientContactsLoading = true;
        state.getClientContactsResult = null;
        state.getClientContactsError = null;
      })
      .addCase(getClientContacts.fulfilled, (state, action) => {
        state.getClientContactsLoading = false;
        state.getClientContactsResult = action.payload;
        state.getClientContactsError = null;
      })
      .addCase(getClientContacts.rejected, (state, action) => {
        state.getClientContactsLoading = false;
        state.getClientContactsResult = null;
        state.getClientContactsError = action.payload;
      })

      .addCase(createClientContact.pending, (state) => {
        state.createClientContactLoading = true;
        state.createClientContactResult = null;
        state.createClientContactError = null;
      })
      .addCase(createClientContact.fulfilled, (state, action) => {
        state.createClientContactLoading = false;
        state.createClientContactResult = action.payload;
        state.createClientContactError = null;
      })
      .addCase(createClientContact.rejected, (state, action) => {
        state.createClientContactLoading = false;
        state.createClientContactResult = null;
        state.createClientContactError = action.payload;
      })

      .addCase(updateClientContact.pending, (state) => {
        state.updateClientContactLoading = true;
        state.updateClientContactResult = null;
        state.updateClientContactError = null;
      })
      .addCase(updateClientContact.fulfilled, (state, action) => {
        state.updateClientContactLoading = false;
        state.updateClientContactResult = action.payload;
        state.updateClientContactError = null;
      })
      .addCase(updateClientContact.rejected, (state, action) => {
        state.updateClientContactLoading = false;
        state.updateClientContactResult = null;
        state.updateClientContactError = action.payload;
      })

      .addCase(deleteClientContact.pending, (state) => {
        state.deleteClientContactLoading = true;
        state.deleteClientContactResult = null;
        state.deleteClientContactError = null;
      })
      .addCase(deleteClientContact.fulfilled, (state, action) => {
        state.deleteClientContactLoading = false;
        state.deleteClientContactResult = action.payload;
        state.deleteClientContactError = null;
      })
      .addCase(deleteClientContact.rejected, (state, action) => {
        state.deleteClientContactLoading = false;
        state.deleteClientContactResult = null;
        state.deleteClientContactError = action.payload;
      })

      .addCase(getClientContactPositions.pending, (state) => {
        state.getClientContactPositionsLoading = true;
        state.getClientContactPositionsResult = null;
        state.getClientContactPositionsSelect = null;
        state.getClientContactPositionsError = null;
      })
      .addCase(getClientContactPositions.fulfilled, (state, action) => {
        state.getClientContactPositionsLoading = false;
        state.getClientContactPositionsResult = action.payload;
        state.getClientContactPositionsSelect = action.payload.map((item) => ({
          label: item.name,
          value: item,
        }));
        state.getClientContactPositionsError = null;
      })
      .addCase(getClientContactPositions.rejected, (state, action) => {
        state.getClientContactPositionsLoading = false;
        state.getClientContactPositionsResult = null;
        state.getClientContactPositionsSelect = null;
        state.getClientContactPositionsError = action.payload;
      });
  },
});

export const {
  resetGetClientContacts,
  resetCreateClientContact,
  resetUpdateClientContact,
  resetDeleteClientContact,
} = customerContactsSlice.actions;
export default customerContactsSlice.reducer;
