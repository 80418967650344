import React from "react";
import "./ForWorkflowShipper.scss";

import { BsArrowUpRight } from "react-icons/bs";
import { FiChevronRight } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import HomeTitle from "../../Components/HomeTitle/HomeTitle";
import WorkflowItem from "./../Components/WorkflowItem/WorkflowItem";
import LinkTransparent from "./../../Components/Links/LinkTransparent/LinkTransparent";

const ForWorkflowShipper = () => {
  const { t } = useTranslation();

  return (
    <section id='ForWorkflowShipper' className='ForWorkflowShipper'>
      <div className='wrapper'>
        <HomeTitle
          description={t(
            "landing.titles.landingMainTitlesDesc.Experience logistics like never before. Discover a world of innovation, efficiency, and personalized service with our cutting-edge logistics solutions"
          )}
        >
          {parse(
            t(
              "landing.titles.landingMainTitles.<span>One-stop</span> shop for all your shipments"
            )
          )}
        </HomeTitle>
        <div className='container'>
          <div className='items'>
            <WorkflowItem
              title={t(
                "landing.titles.landingWorkFlowTitle.Global reach, local touch"
              )}
              content={t(
                "landing.bodyText.landingWorkFlowBody.With a global network and local expertise, we seamlessly bridge international and domestic logistics. Our in-depth knowledge of regional markets and regulations ensures smooth cross-border operations while providing personalized, local support."
              )}
              icon={<BsArrowUpRight />}
              delay='100'
            />

            <WorkflowItem
              title={t(
                "landing.titles.landingWorkFlowTitle.Innovative cost optimization"
              )}
              content={t(
                "landing.bodyText.landingWorkFlowBody.We go beyond traditional cost-cutting methods. Our data-driven approach identifies cost-saving opportunities specific to your supply chain, helping you maximize efficiency without compromising service quality."
              )}
              icon={<BsArrowUpRight />}
              delay='200'
            />

            <WorkflowItem
              title={t("landing.titles.landingWorkFlowTitle.24/7 resilience")}
              content={t(
                "landing.bodyText.landingWorkFlowBody.Our operations run around the clock, ensuring resilience in the face of unexpected challenges. We offer 24/7 support and proactive risk management to keep your supply chain robust and responsive in any situation."
              )}
              icon={<BsArrowUpRight />}
              delay='300'
            />
          </div>

          <div
            className='coverResponsive'
            style={{
              backgroundImage: "url('/assets/images/static/cover9.jpg')",
            }}
          ></div>
          <div className='cover'>
            <div className='background left'>
              <div
                className='img '
                style={{
                  backgroundImage: "url('/assets/images/static/cover9.jpg')",
                }}
              ></div>
            </div>
            <div className='background right'>
              <div
                className='img '
                style={{
                  backgroundImage: "url('/assets/images/static/cover8.jpg')",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <LinkTransparent
        title={t("landing.form.button.Get started now")}
        to='/register'
        icon={<FiChevronRight />}
      />
    </section>
  );
};

export default ForWorkflowShipper;
