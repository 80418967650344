import React, { Fragment } from "react";
import "./NoTrackingTruckListItem.scss";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Driver from "../../../Components/CardComponents/Driver/Driver";
import Trailer from "../../../Components/CardComponents/Trailer/Trailer";
import Address from "../../../Components/CardComponents/Address/Address";
import Header from "../../../Components/CardComponents/Header/Header";
import DButton from "../../../../Form/DButtons/DButton/DButton";
import Character from "../../../../UI/Character/Character";

const NoTrackingTruckListItem = ({
  item,
  activeItemNoTracking,
  setActiveItemNoTracking,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <li
      id='NoTrackingTruckListItem'
      onClick={() =>
        setActiveItemNoTracking(
          activeItemNoTracking && activeItemNoTracking.id === item.id
            ? null
            : item
        )
      }
      className={
        activeItemNoTracking && activeItemNoTracking.id === item.id
          ? "active"
          : ""
      }
    >
      <Header item={item} />
      <div className='actions'>
        <div className='connectToGps'>
          <DButton
            title={t("tms.forms.buttons.Connect to GPS")}
            onClick={() => navigate("/dashboard/tms/tracker/GPS_tracking")}
          />
        </div>

        <div className='trackWithMobile'>
          <DButton
            title={
              <Character
                title={t("tms.forms.buttons.Track with mobile")}
                max={30}
              />
            }
            onClick={() => navigate("/dashboard/tms/tracker/mobile_tracking")}
          />
        </div>
      </div>
      {activeItemNoTracking && activeItemNoTracking.id === item.id && (
        <Fragment>
          {item.driver && (
            <Fragment>
              <Driver item={item} />
            </Fragment>
          )}
          {item.trailer && (
            <Fragment>
              <div className='line'></div>
              <Trailer item={item} />
            </Fragment>
          )}
          {item.orders.length > 0 && (
            <Fragment>
              <div className='line'></div>
              <Address item={item} />
              <div className='link'>
                <Link
                  to={`/dashboard/tms/orders/${item.orders[0].id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t("tms.forms.buttons.See full order")}
                </Link>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </li>
  );
};

export default NoTrackingTruckListItem;
