import React from "react";
import "./SmallDescription.scss";

import { MdOutlinePhoneIphone, MdMailOutline } from "react-icons/md";
import { RiMapPinLine } from "react-icons/ri";
import Rating from "@mui/material/Rating";

import Character from "../../../UI/Character/Character";

const SmallDescription = ({ item }) => {
  const name = item?.name || null;
  const rate = item?.rating || null;
  const id = item?.code || null;

  const phone_index = item?.phone_index?.name ? item?.phone_index?.name : null;
  const phone = item?.phone ? item?.phone : null;

  const phone_index2 = item?.phone2_index?.name ? item?.phone2_index?.name : null;
  const phone2 = item?.phone2 ? item?.phone2 : null;

  const email = item?.email || null;
  const address = item?.address || null;

  return (
    <div id='SmallDescription'>
      {name && (
        <h2>
          <Character title={name} max={30} />
        </h2>
      )}
      {rate && (
        <div className='SmallDescriptionRating'>
          <Rating name='read-only' size='small' value={parseFloat(rate)} readOnly precision={0.5} />
          <p>({rate})</p>
        </div>
      )}
      {id && (
        <h3>
          ID: <Character title={id} max={30} />
        </h3>
      )}
      {phone && phone_index && (
        <h4>
          <div className='icon'>
            <MdOutlinePhoneIphone />
          </div>
          <Character title={phone_index + phone} max={30} />
        </h4>
      )}
      {phone2 && (
        <h4>
          <div className='icon'>
            <MdOutlinePhoneIphone />
          </div>
          <Character title={phone_index2 + phone2} max={30} />
        </h4>
      )}
      {email && (
        <h4>
          <div className='icon'>
            <MdMailOutline />
          </div>
          <Character title={email} max={30} />
        </h4>
      )}

      {address && (
        <h4>
          <div className='icon'>
            <RiMapPinLine />
          </div>
          <Character title={address} max={30} />
        </h4>
      )}

      <div className='tagsContainer'>
        {item?.trailerTypes?.map((item) => (
          <div className='trailerTypeTag' key={item?.id}>
            {item?.name}
          </div>
        ))}
        {item?.routeTypes?.map((item) => (
          <div className='routeTypesTag' key={item?.id}>
            {item?.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SmallDescription;
