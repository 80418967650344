import React from "react";
import "./Marker.scss";

import { MarkerF } from "@react-google-maps/api";

const MarkerC = ({ position }) => {
  return (
    <MarkerF
      position={position}
      icon={{
        url: "/assets/images/static/redTruck.png", // Custom icon URL
        // url: "/assets/images/static/greenTruck.png", // Custom icon URL
        // Optionally adjust the size and origin
        scaledSize: new window.google.maps.Size(42, 42), // Size of the icon
        origin: new window.google.maps.Point(0, 0), // Origin of the icon
        anchor: new window.google.maps.Point(20, 40), // Anchor point of the icon
      }}
    />
  );
};

export default MarkerC;
