import React, { useState } from "react";
import "./FilterMaxWeightSelect.scss";

import { useTranslation } from "react-i18next";

import DDropdown from "../DDropdown/DDropdown";
import DInputs from "../../Form/DInputs/DInputs";
import DButton from "../../Form/DButtons/DButton/DButton";

const FilterMaxWeightSelect = ({ maxWeight, setMaxWeight }) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState("");
  const [dropDownOpen, setDropDownOpen] = useState(false);

  return (
    <div id='FilterMaxWeightSelect'>
      <DDropdown
        value={maxWeight}
        setValue={setMaxWeight}
        placeholder={t("tms.filters.placeholders.Max weight")}
        dropdownOpen={dropDownOpen}
        setDropdownOpen={setDropDownOpen}
      >
        <div className='dropdownContentContainer'>
          <DInputs
            id={"maxWeight"}
            label={t("tms.filters.labels.Max weight (KG)")}
            type='number'
            value={inputValue}
            setValue={setInputValue}
            placeholder={t("tms.filters.placeholders.Enter max weight")}
          />
          <div className='saveButton'>
            <DButton
              title={t("tms.filters.buttons.Save")}
              disabled={!inputValue}
              onClick={() => {
                setMaxWeight(inputValue);
                setDropDownOpen(false);
              }}
            />
          </div>
        </div>
      </DDropdown>
    </div>
  );
};

export default FilterMaxWeightSelect;
