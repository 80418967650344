import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderStatusesService from "./orderStatusesService";

const initialState = {
  getTMSStatusesLoading: true,
  getTMSStatusesResult: null,
  getTMSStatusesError: null,

  getClientStatusesLoading: true,
  getClientStatusesResult: null,
  getClientStatusesError: null,

  updateOrderStatusesLoading: false,
  updateOrderStatusesResult: null,
  updateOrderStatusesError: null,
};

export const getTMSStatuses = createAsyncThunk(
  "orderStatuses/getTMSStatuses",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderStatusesService.getTMSStatuses(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getClientStatuses = createAsyncThunk(
  "orderStatuses/getClientStatuses",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderStatusesService.getClientStatuses(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateOrderStatuses = createAsyncThunk(
  "orderStatuses/updateOrderStatuses",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderStatusesService.updateOrderStatuses(ISO, TOKEN, data && data.orderId, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orderStatusesSlice = createSlice({
  name: "orderStatuses",
  initialState,
  reducers: {
    resetUpdateOrderStatuses: (state) => {
      state.updateOrderStatusesLoading = false;
      state.updateOrderStatusesResult = null;
      state.updateOrderStatusesError = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getTMSStatuses.pending, (state) => {
        state.getTMSStatusesLoading = true;
        state.getTMSStatusesResult = null;
        state.getTMSStatusesError = null;
      })
      .addCase(getTMSStatuses.fulfilled, (state, action) => {
        state.getTMSStatusesLoading = false;
        state.getTMSStatusesResult = action.payload;
        state.getTMSStatusesError = null;
      })
      .addCase(getTMSStatuses.rejected, (state, action) => {
        state.getTMSStatusesLoading = false;
        state.getTMSStatusesResult = null;
        state.getTMSStatusesError = action.payload;
      })

      .addCase(getClientStatuses.pending, (state) => {
        state.getClientStatusesLoading = true;
        state.getClientStatusesResult = null;
        state.getClientStatusesError = null;
      })
      .addCase(getClientStatuses.fulfilled, (state, action) => {
        state.getClientStatusesLoading = false;
        state.getClientStatusesResult = action.payload;
        state.getClientStatusesError = null;
      })
      .addCase(getClientStatuses.rejected, (state, action) => {
        state.getClientStatusesLoading = false;
        state.getClientStatusesResult = null;
        state.getClientStatusesError = action.payload;
      })

      .addCase(updateOrderStatuses.pending, (state) => {
        state.updateOrderStatusesLoading = true;
        state.updateOrderStatusesResult = null;
        state.updateOrderStatusesError = null;
      })
      .addCase(updateOrderStatuses.fulfilled, (state, action) => {
        state.updateOrderStatusesLoading = false;
        state.updateOrderStatusesResult = action.payload;
        state.updateOrderStatusesError = null;
      })
      .addCase(updateOrderStatuses.rejected, (state, action) => {
        state.updateOrderStatusesLoading = false;
        state.updateOrderStatusesResult = null;
        state.updateOrderStatusesError = action.payload;
      });
  },
});

export const { resetUpdateOrderStatuses } = orderStatusesSlice.actions;
export default orderStatusesSlice.reducer;
