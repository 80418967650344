import React, { Fragment, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  getOrders,
  resetGetOrders,
} from "../../../../store/orders/ordersSlice";

import TableHeader from "../../../Form/TableFields/TableHeader/TableHeader";
import LoadingPage from "../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import TableWrapper from "../../../Form/TableFields/TableWrapper/TableWrapper";
import CustomersOrdersHeader from "./CustomersOrdersHeader/CustomersOrdersHeader";
import CustomerOrdersTableItem from "./CustomerOrdersTableItem/CustomerOrdersTableItem";

const CustomerOrdersTable = ({ customer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getOrdersLoading, getOrdersResult, getOrdersMeta } = useSelector(
    (state) => state.orders
  );

  const [status, setStatus] = useState(null);
  const [date, setDate] = useState({});
  const [price, setPrice] = useState({});

  const customerId = customer?.id;

  const onClear = () => {
    if (status) setStatus(null);
    if (Object.keys(date).length > 0) setDate({});
    if (Object.keys(price).length > 0) setPrice({});
  };

  useEffect(() => {
    dispatch(
      getOrders({
        page: 1,
        status_id: status?.value,
        customer_id: customerId,
        start_date:
          Object.keys(date).length > 0 && date?.startDate
            ? moment(date.startDate).format("YYYY-MM-DD")
            : "",
        end_date:
          Object.keys(date).length > 0 && date?.endDate
            ? moment(date.endDate).format("YYYY-MM-DD")
            : "",
        min_price: price?.minPrice,
        max_price: price?.maxPrice,
        revenue_currency_id: price?.revenueCurrencyId,
      })
    );
    return () => {
      dispatch(resetGetOrders());
    };
  }, [
    dispatch,
    customerId,
    date,
    price?.maxPrice,
    price?.minPrice,
    price?.revenueCurrencyId,
    status,
  ]);

  return (
    <Fragment>
      <div id='CustomerOrdersTable'>
        <CustomersOrdersHeader
          customer={customer}
          status={status}
          setStatus={setStatus}
          date={date}
          setDate={setDate}
          price={price}
          setPrice={setPrice}
          onClear={onClear}
        />
        {getOrdersLoading && <LoadingPage />}
        {getOrdersResult && (
          <TableWrapper
            total={getOrdersMeta.total}
            emptyText={t("tms.tables.noInfo.No orders")}
          >
            <TableHeader>
              <th>{t("tms.tables.titles.Order number")}</th>
              <th>{t("tms.tables.titles.Status")}</th>
              <th>{t("tms.tables.titles.Customer")}</th>
              <th>{t("tms.tables.titles.Route")}</th>
              <th>{t("tms.tables.titles.Driver")}</th>
              <th>{t("tms.tables.titles.Trailer type")}</th>
              <th>{t("tms.tables.titles.Pick up date")}</th>
              <th>{t("tms.tables.titles.Price")}</th>
            </TableHeader>
            <tbody>
              {getOrdersResult.map((order) => (
                <CustomerOrdersTableItem key={order.id} order={order} />
              ))}
            </tbody>
          </TableWrapper>
        )}
      </div>
    </Fragment>
  );
};

export default CustomerOrdersTable;
