import _request from "./../../_request";

const getShipperAcceptedOrders = async (ISO, TOKEN, data) => {
  let url = "order?&statuses[]=3&statuses[]=4&statuses[]=5&statuses[]=6";
  const {
    page,
    keyword,
    pickup_date_min,
    pickup_date_max,
    pickup_address,
    dropoff_address,
    trailer_type_id,
    cargo_type_id,
    type_groups,
    client_statuses,
  } = data || {};

  url += page ? `&page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += pickup_date_min ? `&pickup_date[min]=${pickup_date_min}` : "";
  url += pickup_date_max ? `&pickup_date[max]=${pickup_date_max}` : "";
  url += pickup_address ? `&pickup_address=${pickup_address}` : "";
  url += dropoff_address ? `&dropoff_address=${dropoff_address}` : "";
  url += trailer_type_id ? `&trailer_type_id=${trailer_type_id}` : "";
  url += cargo_type_id ? `&cargo_type_id=${cargo_type_id}` : "";

  if (type_groups && type_groups?.length) {
    type_groups?.forEach((i) => {
      url += `&type_groups[]=${i}`;
    });
  }

  if (client_statuses && client_statuses?.length) {
    client_statuses?.forEach((i) => {
      url += `&client_statuses[]=${i}`;
    });
  }

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getShipperAcceptedOrder = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `order/${orderId}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const shipperAcceptedService = {
  getShipperAcceptedOrders,
  getShipperAcceptedOrder,
};

export default shipperAcceptedService;
