import React, { Fragment, useState, useEffect } from "react";
import "./CreatePartnerContactModal.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { FiPlus } from "react-icons/fi";

import {
  createPartnerContact,
  resetCreatePartnerContact,
} from "../../../../../store/partner/partnerContacts/partnerContactsSlice";

import DModal from "../../../../Modal/DModal/DModal";
import DInputs from "./../../../../Form/DInputs/DInputs";
import DSelect from "./../../../../Form/DSelect/DSelect";

const CreateParticipantContactModal = ({ id, isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    createPartnerContactLoading,
    createPartnerContactResult,
    createPartnerContactError,
    getPartnerContactPositionsLoading,
    getPartnerContactPositionsSelect,
  } = useSelector((state) => state.partnerContacts);

  const [fullname, setFullname] = useState("");
  const [position, setPosition] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const submitHandler = () => {
    const data = {
      id: id,
      position_id: position?.value?.id || null,
      name: fullname,
      email: email,
      phone: phone,
    };
    dispatch(createPartnerContact(data));
  };

  useEffect(() => {
    if (createPartnerContactResult) {
      dispatch(resetCreatePartnerContact());
      isClose();
    }
  }, [dispatch, isClose, createPartnerContactResult]);

  useEffect(() => {
    return () => {
      dispatch(resetCreatePartnerContact());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose()}
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        title={t("tms.modals.titles.Add contact")}
        status='success'
        icon={<FiPlus />}
        loading={createPartnerContactLoading}
      >
        <div id='CreateParticipantContactModal'>
          <div className='group'>
            <DInputs
              value={fullname}
              setValue={setFullname}
              placeholder={t("tms.modals.placeholder.Enter full name")}
              label={t("tms.modals.labels.Full name")}
              required
              id='fullname'
              error={createPartnerContactError?.errors?.name?.[0] ?? null}
            />
            <DSelect
              id='position'
              value={position}
              setValue={setPosition}
              placeholder={t("tms.modals.placeholder.Enter position")}
              label={t("tms.modals.labels.Position")}
              options={getPartnerContactPositionsSelect}
              loading={getPartnerContactPositionsLoading}
              required
              search
              error={createPartnerContactError?.errors?.position_id?.[0] ?? null}
            />
            <DInputs
              value={phone}
              setValue={setPhone}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              id='Phone'
              required
              error={createPartnerContactError?.errors?.phone?.[0] ?? null}
            />
            <DInputs
              value={email}
              setValue={setEmail}
              placeholder={t("tms.modals.placeholder.Enter email")}
              label={t("tms.modals.labels.Email")}
              id='Email'
              error={createPartnerContactError?.errors?.email?.[0] ?? null}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default CreateParticipantContactModal;
