import React from "react";
import "./DigitalFreightLand.scss";

import { useTranslation } from "react-i18next";

import DigitalCover from "./../Components/DigitalCover/DigitalCover";
import DigitalContent from "./../Components/DigitalContent/DigitalContent";

const DigitalFreightLand = () => {
  const { t } = useTranslation();

  return (
    <section id='DigitalFreightLand' className='DigitalFreightLand'>
      <div className='wrapper'>
        <div className='container'>
          <DigitalContent
            link='/register'
            title={t(
              "landing.titles.landingMainTitles.Road freight <span>solution</span>"
            )}
            description={t(
              "landing.titles.landingMainTitlesDesc.Cargon empowers you to efficiently track goods, manage documents, and collaborate across teams. Choose from flexible transport modes and value-added services to meet your needs. With advanced technology and dedicated support, we provide reliable service. Join us in transforming your logistics for a more efficient, eco-conscious future."
            )}
            smallTitle={t(
              "landing.titles.landingMainTitlesDesc.Innovative solutions for a seamless future"
            )}
            buttonTitle={t("landing.form.button.Get started")}
          />
          <DigitalCover url='/assets/images/static/cover27.jfif' />
        </div>
      </div>
    </section>
  );
};

export default DigitalFreightLand;
