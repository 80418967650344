import React, { Fragment, useState, useEffect } from "react";
import "./CreateCustomerModal.scss";

import { useTranslation } from "react-i18next";
import * as amplitude from "@amplitude/analytics-browser";

import { FaPlusCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

import { createCustomer, resetCreateCustomer } from "./../../../../store/customer/customerSlice";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";

const CreateCustomerModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createCustomerLoading, createCustomerResult, createCustomerError } = useSelector(
    (state) => state.customer
  );

  const [customerID, setCustomerID] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const submitHandler = () => {
    const data = {
      code: customerID,
      name: customerName,
      email: email,
      phone: phone,
      address: address,
    };
    dispatch(createCustomer(data));
  };

  useEffect(() => {
    if (createCustomerResult) {
      amplitude.track("TMS: Create Customer");
      dispatch(resetCreateCustomer());
      isClose();
    }
  }, [dispatch, isClose, createCustomerResult]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateCustomer());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={isClose}
        title={t("tms.modals.titles.Add Customer")}
        icon={<FaPlusCircle />}
        status='success'
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        loading={createCustomerLoading}
      >
        <div id='CreateCustomerModal'>
          <div className='CreateCustomerModalgroup'>
            <DInputs
              value={customerName}
              setValue={setCustomerName}
              placeholder={t("tms.modals.placeholder.Enter customer name")}
              label={t("tms.modals.labels.Customer name")}
              required
              id='customerName'
              error={createCustomerError?.errors?.name?.[0] ?? null}
            />
            <DInputs
              value={customerID}
              setValue={setCustomerID}
              placeholder={t("tms.modals.placeholder.Enter customer ID")}
              label={t("tms.modals.labels.Customer ID")}
              required
              id='CustomerID'
              error={createCustomerError?.errors?.code?.[0] ?? null}
            />

            <DInputs
              value={email}
              setValue={setEmail}
              placeholder={t("tms.modals.placeholder.Enter email")}
              label={t("tms.modals.labels.Email")}
              required
              id='CustomerEmail'
              error={createCustomerError?.errors?.email?.[0] ?? null}
            />
            <DInputs
              id='CustomerPhone'
              value={phone}
              setValue={setPhone}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              error={createCustomerError?.errors?.phone?.[0] ?? null}
            />
            <DInputs
              value={address}
              setValue={setAddress}
              placeholder={t("tms.modals.placeholder.Enter address")}
              label={t("tms.modals.labels.Address")}
              id='CustomerAddress'
              error={createCustomerError?.errors?.address?.[0] ?? null}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default CreateCustomerModal;
