import React from "react";
import "./AboutItem.scss";

import LinkUnderline from "../../../Components/Links/LinkUnderline/LinkUnderline";

const AboutItem = ({ url, title, content, buttonTitle, link, delay }) => {
  const blur = {
    backgroundImage: `linear-gradient(180deg, rgba(43, 53, 58, 0.00) 0%, rgba(43, 53, 58, 0.50) 100%), url(${url})`,
  };

  return (
    <div
      data-aos='fade-up'
      data-aos-duration='400'
      data-aos-delay={delay ? delay : "0"}
      data-aos-once='true'
    >
      <div id='AboutItem' className='AboutItem'>
        <div className='item'>
          <div className='img' style={blur}>
            <div className='transparent'></div>

            <div className='title'>
              <h2>{title}</h2>
            </div>

            <div className='body'>
              <h2>{title}</h2>
              <p>{content}</p>
              <LinkUnderline to={link} title={buttonTitle} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutItem;
