import React, { Fragment, useState } from "react";
import "./TrailerMaintenanceTableItem.scss";

import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@mui/material";
import { FiEdit2, FiMoreVertical, FiTrash2 } from "react-icons/fi";

import TextField from "../../../../../../Form/TableFields/TextField/TextField";
import RowField from "../../../../../../Form/TableFields/RowField/RowField";
import PriceField from "../../../../../../Form/TableFields/PriceField/PriceField";
import DateField from "../../../../../../Form/TableFields/DateField/DateField";
import MoreField from "../../../../../../Form/TableFields/MoreField/MoreField";
import EditExpensesModal from "../../../../../FleetManagementMaintenance/EditExpensesModal/EditExpensesModal";
import DeleteExpensesModal from "../../../../../FleetManagementMaintenance/DeleteExpensesModal/DeleteExpensesModal";
import moment from "moment";

const TrailerMaintenanceTableItem = ({ trailer }) => {
  const { t } = useTranslation();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <RowField>
        <TextField label={trailer.service} />
        <TextField label={trailer.provider} />
        <DateField
          date={moment(trailer?.created_at).format("YYYY-MM-DD")}
          noDate={t("tms.tables.noInfo.no date")}
        />
        <PriceField number={trailer.cost} symbol={trailer.currency.symbol} />
        <MoreField>
          <div className='icon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='TrailerMaintenanceTableItemMore'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenEditModal(true);
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </MoreField>
      </RowField>
      <EditExpensesModal isOpen={openEditModal} isClose={setOpenEditModal} item={trailer} />
      <DeleteExpensesModal id={trailer.id} isOpen={openDeleteModal} isClose={setOpenDeleteModal} />
    </Fragment>
  );
};

export default TrailerMaintenanceTableItem;
