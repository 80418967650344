import React, { Fragment, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  deleteTrailer,
  resetDeleteTrailer,
} from "../../../../store/fleet/trailers/trailersSlice";

import DModal from "./../../../Modal/DModal/DModal";

const DeleteTrailersModal = ({ isOpen, isClose, trailerId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { deleteTrailerLoading, deleteTrailerResult } = useSelector(
    (state) => state.trailers
  );

  const submitHandler = () => {
    dispatch(deleteTrailer(trailerId));
  };

  useEffect(() => {
    if (deleteTrailerResult) {
      dispatch(resetDeleteTrailer());
      isClose();
      navigate("/dashboard/tms/fleet_management/trailers");
    }
  }, [dispatch, navigate, isClose, deleteTrailerResult]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Delete Trailer")}
        submitText={t("tms.modals.buttons.Delete")}
        icon={<FiTrash2 />}
        status='danger'
        onClick={() => submitHandler()}
        loading={deleteTrailerLoading}
      >
        <p>
          {t("tms.modals.desc.Are you sure you want to delete this trailer?")}
        </p>
      </DModal>
    </Fragment>
  );
};

export default DeleteTrailersModal;
