import React, { useState } from "react";
import "./Input.scss";

import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

const Input = ({
  value,
  setValue,
  placeholder,
  autoFocus,
  id,
  password,
  error,
  type,
  name,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div id='input' className={error ? "error" : ""}>
      <div className='group'>
        <input
          id={id}
          autoComplete='false'
          name={id}
          type={
            password
              ? showPassword
                ? "text"
                : "password"
              : type
              ? type
              : "text"
          }
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          autoFocus={autoFocus ? autoFocus : false}
        />
        {password && value && value.length > 2 && (
          <div
            className='showPassIcon'
            onClick={(e) => {
              e.stopPropagation();
              setShowPassword((state) => !state);
            }}
          >
            {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
