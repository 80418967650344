import React from "react";
import "./DLinkToButton.scss";

import { useTranslation } from "react-i18next";
import { MdInsertLink } from "react-icons/md";

import DButton from "../../../Form/DButtons/DButton/DButton";

const DLinkToButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div id='DLinkToButton'>
      <DButton
        title={t("tms.tables.buttons.Link To Truck")}
        rightIcon={<MdInsertLink />}
        onClick={onClick}
      />
    </div>
  );
};

export default DLinkToButton;
