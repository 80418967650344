import React, { Fragment, useState, useEffect } from "react";
import "./AddPrePaymentModal.scss";

import { useTranslation } from "react-i18next";

import moment from "moment";
import { BiSolidDollarCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import {
  createPayment,
  resetCreatePayment,
} from "../../../../../../store/orders/orderFinance/orderFinanceSlice";

import DCurrencyInput from "../../../../../Form/DCurrencyInput/DCurrencyInput";
import DDatepicker from "./../../../../../Form/DDatepicker/DDatepicker";
import DModal from "../../../../../Modal/DModal/DModal";
import DTextarea from "./../../../../../Form/DTextarea/DTextarea";

const AddPrePaymentModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { createPaymentLoading, createPaymentResult, createPaymentError } = useSelector(
    (state) => state.orderFinance
  );

  const [amount, setAmount] = useState("");
  const [currencyValue, setCurrencyValue] = useState(
    order?.revenue_currency
      ? { label: order?.revenue_currency?.code, value: order?.revenue_currency }
      : null
  );
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [comment, setComment] = useState("");

  const submitHandler = () => {
    const data = {
      orderId: order.id,
      operation: "IN",
      currency_id: currencyValue?.value?.id || null,
      amount: amount || null,
      payment_date: paymentDate ? moment(paymentDate).format("YYYY/MM/DD") : null,
      comment: comment || null,
    };
    dispatch(createPayment(data));
  };

  useEffect(() => {
    if (createPaymentResult) {
      dispatch(resetCreatePayment());
    }
  }, [dispatch, createPaymentResult]);

  useEffect(() => {
    return () => {
      dispatch(resetCreatePayment());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Add payment")}
        submitText={t("tms.modals.buttons.Add payment")}
        icon={<BiSolidDollarCircle />}
        status='success'
        onClick={() => submitHandler()}
        loading={createPaymentLoading}
      >
        <div id='AddPaymentModal'>
          <div className='AddPaymentModalGroup'>
            <DCurrencyInput
              id={"AddPaymentAmount"}
              placeholder={t("tms.modals.placeholder.Enter paid money")}
              label={t("tms.modals.labels.Amount")}
              value={amount}
              setValue={setAmount}
              currencyValue={currencyValue}
              setCurrencyValue={setCurrencyValue}
              required
              error={
                (createPaymentError?.errors?.amount?.[0] ?? null) ||
                (createPaymentError?.errors?.currency_id?.[0] ?? null)
              }
            />
            <DDatepicker
              placeholder={t("tms.modals.placeholder.Current date")}
              label={t("tms.modals.labels.Payment date")}
              value={paymentDate}
              setValue={setPaymentDate}
              required
              error={createPaymentError?.errors?.payment_date?.[0] ?? null}
            />
            <DTextarea
              id={"AddPaymentModalTextAreaAdvances"}
              value={comment}
              placeholder={t("tms.modals.placeholder.Enter comment")}
              label={t("tms.modals.labels.Comment")}
              setValue={setComment}
              error={createPaymentError?.errors?.comment?.[0] ?? null}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddPrePaymentModal;
