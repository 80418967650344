import React from "react";
import "./TextField.scss";

import Character from "../../../UI/Character/Character";

const TextField = ({ label, max }) => {
  return (
    <td id='tableTextField'>
      <p>
        <Character title={label ? label.toString() : ""} max={max ? max : 20} />
      </p>
    </td>
  );
};

export default TextField;
