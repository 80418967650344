import React from "react";

import { useTranslation } from "react-i18next";
import { IoMdEye } from "react-icons/io";

// import { Link } from "react-router-dom";

import DModal from "../../../../Modal/DModal/DModal";

const ViewInstructionModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.View Instruction")}
      submitText={t("tms.modals.buttons.Dismiss")}
      icon={<IoMdEye />}
      status='noSubmit'
    >
      <p>
        {t(
          "tms.modals.desc.Hello. We already sent you application credentials, please log in."
        )}
        {/* Here is application Link:{" "} 
        <Link to={"https://onelink.to/tw95at"}>https://onelink.to/tw95at</Link> */}
      </p>
    </DModal>
  );
};

export default ViewInstructionModal;
