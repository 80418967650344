import React from "react";
import "./CreateOrderHeader.scss";

import { useTranslation } from "react-i18next";

import DBackButton from "../../../../Form/DButtons/DBackButton/DBackButton";

const CreateOrderHeader = () => {
  const { t } = useTranslation();
  return (
    <div id='CreateOrderHeader'>
      <DBackButton alt={"/dashboard/shipper/orders?page=1"} />
      <h2>{t("shipper.titles.Get a quote")}</h2>
      <div className='empty'></div>
    </div>
  );
};

export default CreateOrderHeader;
