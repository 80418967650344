import React, { useEffect, useState } from "react";
import "./OrdersListHeader.scss";

import moment from "moment";

import { useTranslation } from "react-i18next";

import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import DButton from "../../../Form/DButtons/DButton/DButton";
import DSearch from "../../../FilterForms/DSearch/DSearch";
import FiltersContainer from "../../../FilterForms/FiltersContainer/FiltersContainer";
import FilterTrailerSelect from "../../../FilterForms/FilterTrailerSelect/FilterTrailerSelect";
import FilterStatusSelect from "../../../FilterForms/FilterStatusSelect/FilterStatusSelect";
import FilterPriceRangeForm from "../../../FilterForms/FilterPriceRangeForm/FilterPriceRangeForm";
import FilterDateRangeForm from "../../../FilterForms/FilterDateRangeForm/FilterDateRangeForm";
import FilterCustomerSelect from "../../../FilterForms/FilterCustomerSelect/FilterCustomerSelect";
import FilterRouteSelectForm from "../../../FilterForms/FilterRouteSelectForm/FilterRouteSelectForm";
import SortSelect from "../../../FilterForms/SortSelect/SortSelect";
import MainTitle from "../../../MainTitle/MainTitle";

const OrdersListHeader = ({
  total,
  setKeyword,
  status,
  setStatus,
  customer,
  setCustomer,
  trailerType,
  setTrailerType,
  price,
  setPrice,
  date,
  setDate,
  route,
  setRoute,
  sort,
  setSort,
  onClear,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [keywordValue, setKeywordValue] = useState("");

  const SORT_OPTIONS = [
    {
      label: t("tms.filters.labels.Type"),
      options: [
        {
          value: "1",
          label: t("tms.filters.labels.Newest"),
          data: { sortBy: "creation_date_desc" },
        },
        {
          value: "2",
          label: t("tms.filters.labels.Oldest"),
          data: { sortBy: "creation_date_asc" },
        },
      ],
    },
    {
      label: t("tms.filters.labels.Price"),
      options: [
        {
          value: "3",
          label: t("tms.filters.labels.Low to high"),
          data: { sortBy: "revenue_asc" },
        },
        {
          value: "4",
          label: t("tms.filters.labels.High to low"),
          data: { sortBy: "revenue_desc" },
        },
      ],
    },
    {
      label: t("tms.filters.labels.Pick up date"),

      options: [
        {
          value: "5",
          label: t("tms.filters.labels.Earliest"),
          data: { sortBy: "pickup_date_asc" },
        },
        {
          value: "6",
          label: t("tms.filters.labels.Latest"),
          data: { sortBy: "pickup_date_desc" },
        },
      ],
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setKeyword(keywordValue);
    }, 500);
    return () => clearInterval(timeout);
  }, [keywordValue, setKeyword]);

  return (
    <div id='OrdersListHeader'>
      <MainTitle title={t("tms.titles.My Bookings")} />
      <div className='bar'>
        <div className='searchContainer'>
          <DSearch value={keywordValue} setValue={(e) => setKeywordValue(e)} />
        </div>

        <DButton
          title={t("tms.forms.buttons.Add Booking")}
          rightIcon={<FaPlusCircle />}
          onClick={() => navigate("/dashboard/tms/orders/new")}
        />
      </div>
      <div className='filtersContainer'>
        <FiltersContainer onClear={onClear}>
          <FilterStatusSelect value={status} setValue={setStatus} />
          <FilterCustomerSelect value={customer} setValue={setCustomer} />
          <FilterTrailerSelect value={trailerType} setValue={setTrailerType} />
          <FilterDateRangeForm
            label={t("tms.filters.placeholders.Date range")}
            value={
              Object.keys(date).length > 0
                ? `${date.startDate ? moment(date.startDate).format("DD/MM/YYYY") : ""} - ${
                    date.endDate ? moment(date.endDate).format("DD/MM/YYYY") : ""
                  }`
                : ""
            }
            setValue={setDate}
          />
          <FilterPriceRangeForm
            value={Object.keys(price).length > 0 ? `${price?.minPrice} - ${price?.maxPrice}` : ""}
            setValue={setPrice}
          />
          <FilterRouteSelectForm
            value={
              Object.keys(route).length > 0
                ? `${route?.fromCountry?.value?.code || ""} - ${
                    route?.toCountry?.value?.code || ""
                  }`
                : ""
            }
            setValue={setRoute}
          />
        </FiltersContainer>
      </div>
      <div className='sortContainer'>
        <div className='result'>
          {t("tms.desc.Result")} {total} {t("tms.desc.order")}
        </div>
        <div className='sortButton'>
          <SortSelect
            value={sort}
            setValue={(state) => {
              setSort(state);
            }}
            options={SORT_OPTIONS}
          />
        </div>
      </div>
    </div>
  );
};

export default OrdersListHeader;
