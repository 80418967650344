import React from "react";
import "./AssignCarrierButton.scss";

import { MdDone } from "react-icons/md";

import DButton from "../../../../../Form/DButtons/DButton/DButton";

const AssignCarrierButton = ({ onClick, title }) => {
  return (
    <div id='AssignCarrierButton'>
      <DButton title={title} rightIcon={<MdDone />} onClick={onClick} />
    </div>
  );
};

export default AssignCarrierButton;
