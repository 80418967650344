import _request from "../../_request";

const getDrivers = async (ISO, TOKEN, data) => {
  let url = "drivers?";

  const { has_truck, keyword } = data || {};

  url += keyword ? `&keyword=${keyword}` : "";
  url += has_truck ? `&has_truck=${has_truck}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getDriver = async (ISO, TOKEN, id) => {
  const config = {
    url: `drivers/${id}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createDriver = async (ISO, TOKEN, data) => {
  const config = {
    url: "drivers",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateDriver = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `drivers/${id}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteDriver = async (ISO, TOKEN, id) => {
  const config = {
    url: `drivers/${id}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const driversService = {
  getDrivers,
  getDriver,
  createDriver,
  updateDriver,
  deleteDriver,
};

export default driversService;
