import React from "react";
import "./BookModal.scss";

import { useTranslation } from "react-i18next";

import { LiaShippingFastSolid } from "react-icons/lia";
import { FiX } from "react-icons/fi";
import { IoMdArrowRoundDown } from "react-icons/io";
import { BsAirplane } from "react-icons/bs";
// import { MdOutlineDirectionsRailway } from "react-icons/md";
import { RiShipLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import DOverlay from "./../../../../Modal/DOverlay/DOverlay";

const BookModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const BookModalItem = ({ icon, title, link }) => {
    return (
      <li
        onClick={() => {
          navigate(link);
          isClose();
        }}
        className='BookModalItem'
      >
        <div className='icon'>{icon}</div>
        <h3>{title}</h3>
      </li>
    );
  };
  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <div id='ShipperBookModal'>
        <div className='header'>
          <div className='title'>
            <div className='icon'>
              <IoMdArrowRoundDown />
            </div>
            <h2>{t("shipper.modal.Choose Shipping type")}</h2>
          </div>

          <div className='closeIcon' onClick={isClose}>
            <FiX />
          </div>
        </div>
        <div className='items'>
          <BookModalItem
            title={t("shipper.modal.Road freight")}
            icon={<LiaShippingFastSolid />}
            link='/dashboard/shipper/orders/new/road'
          />
          <BookModalItem
            title={t("shipper.modal.Ocean freight")}
            icon={<RiShipLine />}
            link='/dashboard/shipper/orders/new/ocean'
          />
          <BookModalItem
            title={t("shipper.modal.Air freight")}
            icon={<BsAirplane />}
            link='/dashboard/shipper/orders/new/air'
          />
          {/* <BookModalItem
            title={t("shipper.modal.Rail freight")}
            icon={<MdOutlineDirectionsRailway />}
            link='/dashboard/shipper/orders/new/rail'
          /> */}
        </div>
      </div>
    </DOverlay>
  );
};

export default BookModal;
