import React, { useState, useEffect } from "react";
import "./TrackerMap.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import {
  getTracking,
  resetGetTracking,
} from "../../../../store/trackerManager/trackerManagerSlice";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import Listing from "./../../../../componentsDashboard/TrackerMap/Listing/Listing";
import MapView from "./../../../../componentsDashboard/TrackerMap/MapView/MapView";

const TrackerMap = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getTrackingResponce } = useSelector((state) => state.trackerManager);

  const [tab, setTab] = useState("tracking");
  const [activeItem, setActiveItem] = useState(null);
  const [activeItemNoTracking, setActiveItemNoTracking] = useState(null);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    const data = {
      keyword: keyword,
    };
    dispatch(getTracking(data));
    return () => {
      dispatch(resetGetTracking());
    };
  }, [dispatch, keyword]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Tracking manager")}>
      <section id='GoogleMapDeviceTraking'>
        <Listing
          tab={tab}
          setTab={setTab}
          keyword={keyword}
          setKeyword={setKeyword}
          items={getTrackingResponce}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          activeItemNoTracking={activeItemNoTracking}
          setActiveItemNoTracking={setActiveItemNoTracking}
        />
        <MapView
          tab={tab}
          items={getTrackingResponce}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      </section>
    </Wrapper>
  );
};

export default TrackerMap;
