import React, { Fragment, useState } from "react";
import "./RouteNewOrder.scss";

import { useTranslation } from "react-i18next";

import { AiFillPlusSquare, AiFillMinusSquare } from "react-icons/ai";
import { FaRegClock } from "react-icons/fa6";

import NewOrderTitle from "../Components/NewOrderTitle/NewOrderTitle";
import DDatepicker from "../../../../Form/DDatepicker/DDatepicker";
import DTextButton from "../../../../Form/DButtons/DTextButton/DTextButton";
import TimeSelectDropdown from "../Components/TimeSelectDropdown/TimeSelectDropdown";
import LocationsManagement from "./../Components/LocationsManagement/LocationsManagement";

const RouteNewOrder = ({
  locations,
  setLocations,
  ///////
  pickUpDate,
  setPickUpDate,
  pickUpDateError,
  /////
  pickUpTime,
  setPickUpTime,
  pickUpTimeError,
  /////
  dropOffShow,
  setDropOffShow,
  dropOffDate,
  setDropOffDate,
  dropOffDateError,
  /////
  dropOffTime,
  setDropOffTime,
  dropOffTimeError,
}) => {
  const { t } = useTranslation();

  const [showPickUpTimeSelectDropdown, setShowPickUpTimeSelectDropdown] = useState(false);
  const [showDropOffTimeSelectDropdown, setShowDropOffTimeSelectDropdown] = useState(false);

  return (
    <Fragment>
      <div id='RouteNewOrder'>
        <NewOrderTitle title={t("tms.titles.Route")} />
        <div className='RouteNewOrderWrapper'>
          <div className='locationsWrapper'>
            <LocationsManagement locations={locations} setLocations={setLocations} />
          </div>
          <div className='dateWrapper'>
            <div className='pickUpContainer'>
              <DDatepicker
                required
                label={t("tms.forms.labels.Pick up date")}
                placeholder={t("tms.forms.placeholder.Choose pick up date")}
                value={pickUpDate}
                setValue={setPickUpDate}
                error={pickUpDateError || pickUpTimeError}
              />
              {showPickUpTimeSelectDropdown || pickUpTimeError ? (
                <div className='timeSelectContainer'>
                  <TimeSelectDropdown time={pickUpTime} setTime={setPickUpTime} />
                  <div className='removeButton'>
                    <DTextButton
                      title={t("tms.forms.buttons.Remove")}
                      danger
                      onClick={() => {
                        setShowPickUpTimeSelectDropdown(false);
                        setPickUpTime(null);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className='addTimeRangeButton'>
                  <DTextButton
                    title={t("tms.forms.buttons.Add time range")}
                    rightIcon={<FaRegClock />}
                    info
                    onClick={() => setShowPickUpTimeSelectDropdown(true)}
                  />
                </div>
              )}
            </div>
            <div className='dropOffDate'>
              <DTextButton
                leftIcon={dropOffShow ? <AiFillMinusSquare /> : <AiFillPlusSquare />}
                title={t("tms.forms.buttons.Add drop off date")}
                onClick={() => {
                  setDropOffShow((state) => !state);
                }}
              />
              {(dropOffShow || dropOffDateError || dropOffTimeError) && (
                <div className='dropOffContainer'>
                  <DDatepicker
                    label={t("tms.forms.labels.Drop off date")}
                    placeholder={t("tms.forms.placeholder.Choose drop off date")}
                    value={dropOffDate}
                    setValue={setDropOffDate}
                    error={dropOffDateError || dropOffTimeError}
                  />
                  {showDropOffTimeSelectDropdown || dropOffTimeError ? (
                    <div className='dropOffTimeSelectContainer'>
                      <TimeSelectDropdown time={dropOffTime} setTime={setDropOffTime} />
                      <div className='dropOffRemoveButton'>
                        <DTextButton
                          title={t("tms.forms.buttons.Remove")}
                          danger
                          onClick={() => {
                            setShowDropOffTimeSelectDropdown(false);
                            setDropOffTime(null);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className='dropOffAddTimeRangeButton'>
                      <DTextButton
                        title={t("tms.forms.buttons.Add time range")}
                        rightIcon={<FaRegClock />}
                        info
                        onClick={() => setShowDropOffTimeSelectDropdown(true)}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RouteNewOrder;
