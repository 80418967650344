import React from "react";
import "./DLabel.scss";

const DLabel = ({ label, required, htmlFor, onClick, status }) => {
  return (
    <div id='DLabel'>
      <label htmlFor={htmlFor && htmlFor} onClick={onClick}>
        {label}
        {status && (
          <span style={{ marginLeft: "5px", color: "#848A8D" }}>
            ({status})
          </span>
        )}
        {required && <span>*</span>}
      </label>
    </div>
  );
};

export default DLabel;
