import React from "react";
import "./LandFreight.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import CoverLandFreight from "./../../../../componentsLanding/Landing/Covers/CoverLandFreight/CoverLandFreight";
import DigitalFreightLand from "../../../../componentsLanding/Landing/DigitalFreight/DigitalFreightLand/DigitalFreightLand";
import ContactLandFreight from "../../../../componentsLanding/Landing/Contacts/ContactLandFreight/ContactLandFreight";
import DetailsFreightLand from "../../../../componentsLanding/Landing/DetailsFreight/DetailsFreightLand/DetailsFreightLand";

const LandFreight = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Road freight")}>
      <CoverLandFreight />
      <DigitalFreightLand />
      <DetailsFreightLand />
      <ContactLandFreight />
    </Wrapper>
  );
};

export default LandFreight;
