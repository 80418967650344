import React, { Fragment, useState, useEffect } from "react";
import "./InvoiceModal.scss";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { FiX } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  getInvoice,
  resetGetInvoice,
  createInvoice,
  resetCreateInvoice,
} from "./../../../store/orders/invoices/invoicesSlice";

import DOverlay from "../../Modal/DOverlay/DOverlay";
import InvoiceView from "./components/InvoiceView/InvoiceView";
import Form from "./components/Form/Form";
import LoadingPage from "./../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const InvoiceModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getInvoiceLoading, getInvoiceResult, createInvoiceLoading, createInvoiceResult } =
    useSelector((state) => state.invoices);

  const { getPhoneIndexesResult, phoneIndexValueId } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const [currrencyCode, setCurrrencyCode] = useState(null);

  const [name, setName] = useState("");
  const [date, setDate] = useState(new Date());
  const [bankRequisites, setBankRequisites] = useState([
    {
      bankName: "",
      bankCode: "",
      bankACCN: "",
    },
  ]);
  const [transaction, setTransaction] = useState(false);
  const [invoiceWarning, setInvoiceWarning] = useState(false);
  const [invoiceDayNumber, setInvoiceDayNumber] = useState("1");
  const [email, setEmail] = useState(user.email ? user.email : "");
  const [phone, setPhone] = useState(user.phone ? user.phone : "");
  const [address, setAddress] = useState(user?.company?.address ? user?.company?.address : "");
  const [comment, setComment] = useState("");
  const [charges, setCharges] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const data = {
        orderId: order.id,
      };
      dispatch(getInvoice(data));
      return () => {
        dispatch(resetGetInvoice());
      };
    }
  }, [dispatch, isOpen, order]);

  useEffect(() => {
    if (getInvoiceResult) {
      setName(getInvoiceResult?.tracking || "");
      const costs = getInvoiceResult?.extraRevenue
        ? getInvoiceResult?.extraRevenue?.map((cost) => ({
            name: cost?.type?.name || "",
            total: cost?.amount || "0",
            vat: "0",
            amount: cost?.amount || "0",
          }))
        : [];
      setCharges([
        {
          name: t("tms.invoice.Transportation service (advance)"),
          total: getInvoiceResult.revenue,
          vat: "0",
          amount: getInvoiceResult.revenue,
        },
        ...costs,
      ]);
      setCurrrencyCode(
        getInvoiceResult?.revenue_currency
          ? {
              label: getInvoiceResult?.revenue_currency?.code,
              value: getInvoiceResult?.revenue_currency,
            }
          : null
      );
    }
  }, [getInvoiceResult, t]);

  const submitHanlder = () => {
    const validCharges =
      charges &&
      charges
        .filter((charge) => charge.total !== "")
        .map((charge) => ({ ...charge, total: parseFloat(charge.total) }));
    const totalSum = validCharges && validCharges.reduce((acc, charge) => acc + charge.total, 0);

    const data = {
      orderId: order.id,
      invoice: {
        number: name,
        date: moment(date).format("DD/MM/YYYY"),
        service: t("tms.invoice.Transportation"),
        currency: currrencyCode.label,
        amount: totalSum ? totalSum : 0,
        comment: invoiceWarning
          ? `*${t("tms.invoice.Please pay within")} ${invoiceDayNumber} ${t(
              "tms.invoice.days of receiving this invoice."
            )}`
          : null,
        numbers: {
          truck: order?.transports?.[0]?.number || null,
          trailer: null,
          container: null,
        },
      },
      shipto: {
        name: user?.company?.name || null,
        code: user?.company?.legal_id || null,
        phone: phone
          ? getPhoneIndexesResult &&
            getPhoneIndexesResult.find((i) => i.id === phoneIndexValueId).phone_index + phone
          : null,
        mail: email || null,
        address: address || null,
      },
      billto: {
        name: order.ownership
          ? getInvoiceResult?.customer?.company?.name || null
          : getInvoiceResult?.client?.name || null,
        code: order.ownership
          ? getInvoiceResult?.customer?.company?.legal_id || null
          : getInvoiceResult?.client?.code || null,
        phone: order.ownership
          ? getInvoiceResult?.customer?.company?.phone || null
          : getInvoiceResult?.client?.phone || null,
        mail: order.ownership
          ? getInvoiceResult?.customer?.company?.email || null
          : getInvoiceResult?.client?.email || null,
        address: order.ownership
          ? getInvoiceResult?.customer?.company?.address || null
          : getInvoiceResult?.client?.address || null,
      },
      bank: {
        data: bankRequisites?.map((bank) => ({
          name: bank?.bankName || null,
          account: bank?.bankACCN || null,
          code: bank?.bankCode || null,
        })),
        comment: transaction
          ? t("tms.invoice.Please include the invoice number in the transaction")
          : null,
      },
      charges: charges.map((charg) => ({
        name: charg.name,
        comment:
          charg.vat > 0
            ? `${t("tms.invoice.Including")} ${charg.vat}% ${t("tms.invoice.VAT")}`
            : null,
        amount: charg.amount,
        total: charg.total,
        vat: (+charg.amount * +charg.vat) / 100,
      })),
      comment: comment || null,
    };
    dispatch(createInvoice(data));
  };
  useEffect(() => {
    if (createInvoiceResult) {
      dispatch(resetCreateInvoice());
      isClose();
    }
  }, [dispatch, isClose, createInvoiceResult]);
  return (
    <DOverlay
      isOpen={isOpen}
      isClose={() => {
        isClose();
      }}
    >
      {getInvoiceLoading ? (
        <div id='GenerateInvoiceModalLoadingView'>
          <LoadingPage />
        </div>
      ) : (
        <Fragment>
          {getInvoiceResult && (
            <div id='GenerateInvoiceModal'>
              <div className='closeButton' onClick={() => isClose()}>
                <FiX />
              </div>
              <InvoiceView
                order={order}
                name={name}
                date={date}
                currrencyCode={currrencyCode}
                bankRequisites={bankRequisites}
                transaction={transaction}
                invoiceWarning={invoiceWarning}
                invoiceDayNumber={invoiceDayNumber}
                email={email}
                phone={phone}
                address={address}
                comment={comment}
                charges={charges}
              />

              <Form
                order={order}
                isOpen={isOpen}
                isClose={isClose}
                name={name}
                setName={setName}
                date={date}
                setDate={setDate}
                currrencyCode={currrencyCode}
                setCurrrencyCode={setCurrrencyCode}
                bankRequisites={bankRequisites}
                setBankRequisites={setBankRequisites}
                transaction={transaction}
                setTransaction={setTransaction}
                invoiceWarning={invoiceWarning}
                setInvoiceWarning={setInvoiceWarning}
                invoiceDayNumber={invoiceDayNumber}
                setInvoiceDayNumber={setInvoiceDayNumber}
                email={email}
                setEmail={setEmail}
                phone={phone}
                setPhone={setPhone}
                address={address}
                setAddress={setAddress}
                comment={comment}
                setComment={setComment}
                charges={charges}
                setCharges={setCharges}
                submit={submitHanlder}
                loading={createInvoiceLoading}
              />
            </div>
          )}
        </Fragment>
      )}
    </DOverlay>
  );
};

export default InvoiceModal;
