import React from "react";
import "./Dashboard.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import DashboardOrdersTable from "../../../../componentsDashboard/Dashboard/DashboardOrdersTable/DashboardOrdersTable";
import Cards from "../../../../componentsDashboard/Dashboard/Cards/Cards";
import Charts from "../../../../componentsDashboard/Dashboard/Charts/Charts";
import DashboardHeader from "../../../../componentsDashboard/Dashboard/DashboardHeader/DashboardHeader";

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Company")}>
      <section id='CDashboardCompany'>
        <DashboardHeader />
        <div className='DashboardContainer'>
          <Cards />
          <Charts />
        </div>
        <DashboardOrdersTable />
      </section>
    </Wrapper>
  );
};

export default Dashboard;
