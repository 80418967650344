import React, { useState, Fragment } from "react";

import { useTranslation } from "react-i18next";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";
import moment from "moment";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import TrailerField from "./../../../../Form/TableFields/TrailerField/TrailerField";
import EditExpensesModal from "../../EditExpensesModal/EditExpensesModal";
import DeleteExpensesModal from "./../../DeleteExpensesModal/DeleteExpensesModal";
import RowField from "../../../../Form/TableFields/RowField/RowField";
import TextField from "./../../../../Form/TableFields/TextField/TextField";
import MoreField from "../../../../Form/TableFields/MoreField/MoreField";
import TruckField from "../../../../Form/TableFields/TruckField/TruckField";
import DateField from "../../../../Form/TableFields/DateField/DateField";
import PriceField from "./../../../../Form/TableFields/PriceField/PriceField";

const MaintenanceTableItem = ({ item }) => {
  const { t } = useTranslation();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <RowField>
        {item?.truck ? (
          <TruckField max={40} truck={item.truck} />
        ) : item?.trailer ? (
          <TrailerField
            icon={(item.trailer && item.trailer?.type?.icon2) || null}
            trailerType={(item.trailer && item.trailer?.type?.name) || "-"}
            volume={(item.trailer && item.trailer?.volume_capacity + "m³") || "-"}
          />
        ) : (
          <TextField label={t("tms.tables.noInfo.no information")} />
        )}
        <TextField max={30} label={item.service || "-"} />
        <TextField max={30} label={item.provider || "-"} />
        <DateField
          date={moment(item.created_at).format("YYYY-MM-DD")}
          noDate={t("tms.tables.noInfo.no date")}
        />
        <PriceField number={item.cost} symbol={item?.currency?.symbol} color={"#DA1414"} />
        <MoreField>
          <div className='icon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='OrdersTableItemMore'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenEditModal(true);
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </MoreField>
      </RowField>
      {openEditModal && (
        <EditExpensesModal isOpen={openEditModal} isClose={setOpenEditModal} item={item} />
      )}
      {openDeleteModal && (
        <DeleteExpensesModal id={item.id} isOpen={openDeleteModal} isClose={setOpenDeleteModal} />
      )}
    </Fragment>
  );
};

export default MaintenanceTableItem;
