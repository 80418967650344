import React from "react";
import "./OceanTrailerDetails.scss";

import { useTranslation } from "react-i18next";

import { GiCargoCrate } from "react-icons/gi";

const OceanTrailerDetails = ({ getOrderResult }) => {
  const { t } = useTranslation();

  return (
    <div id='OceanTrailerDetails'>
      <div className='titleContainer'>
        <div className='icon'>
          <img src='/assets/images/dashboard/cover7.svg' alt='container' />
        </div>
        <h1 className='title'>{t("shipper.forms.titles.Container Requirements")}</h1>
      </div>
      {getOrderResult.cargos.length > 0 &&
        getOrderResult.cargos.map((cargo, index) => (
          <div className='OceanTrailerDetailsShipperContainer' key={cargo?.id}>
            <div className='OceanTrailerDetailsShipperCover'>
              <div className='OceanTrailerDetailsShipperIndex'>
                {getOrderResult?.cargos.length > 1 && index + 1}
              </div>
              <div className='OceanTrailerDetailsShipperImg'>
                <GiCargoCrate />
              </div>
            </div>
            <div className='OceanTrailerDetailsShippercontent'>
              <p>
                {t("shipper.forms.content.Container")}:{" "}
                <span>{cargo?.trailerType?.name || "-"}</span>
              </p>
              {cargo?.temperature && (
                <p>
                  {t("shipper.forms.content.Temperature")}: <span>{cargo?.temperature}</span>
                </p>
              )}
              <p>
                {t("shipper.forms.content.Per weight")}: <span>{cargo?.weight || "-"}</span>
              </p>

              <p>
                {t("shipper.forms.content.Quantity")}: <span>{cargo?.count || "-"}</span>
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default OceanTrailerDetails;
