import React, { forwardRef, useEffect, useRef } from "react";
import "./DDatepicker.scss";

import { useSelector } from "react-redux";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdCalendarToday } from "react-icons/md";
import ka from "date-fns/locale/ka";
import en from "date-fns/locale/en-GB";
import ru from "date-fns/locale/ru";

import DLabel from "../DLabel/DLabel";
import DErrorText from "../DErrorText/DErrorText";

const DDatepicker = ({
  value,
  setValue,
  placeholder,
  label,
  error,
  required,
}) => {
  const datePickerRef = useRef(null);

  const { ISO } = useSelector((state) => state.lang);

  const openDatePicker = () => {
    if (datePickerRef.current !== null) {
      datePickerRef.current.setOpen(true);
    }
  };

  const DDatepickerButton = forwardRef(({ value, onClick }, ref) => (
    <div id='DDatepickerButton' onClick={onClick} ref={ref}>
      <div className='text'>
        {value ? (
          <p className='valuetext'>{value}</p>
        ) : (
          <p className='placeholder'>{placeholder}</p>
        )}
      </div>
      <div className='icon'>
        <MdCalendarToday />
      </div>
    </div>
  ));

  useEffect(() => {
    if (ISO === "en") {
      registerLocale("en", en);
    } else if (ISO === "ka") {
      registerLocale("ka", ka);
    } else if (ISO === "ru") {
      registerLocale("ru", ru);
    }
  }, [ISO]);

  return (
    <div id='DDatepicker' className={error ? "error" : ""}>
      {label && (
        <DLabel label={label} required={required} onClick={openDatePicker} />
      )}
      <DatePicker
        ref={datePickerRef}
        selected={value}
        locale={ISO}
        onChange={(date) => setValue(date)}
        customInput={<DDatepickerButton />}
        dateFormat='dd/MM/yyyy'
      />
      {error && <DErrorText error={error} />}
    </div>
  );
};

export default DDatepicker;
