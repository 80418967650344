import React from "react";
import "./DSearch.scss";

import { useTranslation } from "react-i18next";

import { FiSearch } from "react-icons/fi";

const DSearch = ({ value, setValue }) => {
  const { t } = useTranslation();

  return (
    <div id='DSearch'>
      <label className='DInputsIcon' htmlFor='DSearchFor'>
        <FiSearch />
      </label>
      <input
        id='DSearchFor'
        type='text'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t("tms.filters.placeholders.Search")}
      />
    </div>
  );
};

export default DSearch;
