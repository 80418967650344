import React from "react";
import "./CardSummary.scss";

import { useTranslation } from "react-i18next";

import { MdOutlineShowChart } from "react-icons/md";

const CardSummary = ({ title, number, lastMonth }) => {
  const { t } = useTranslation();

  return (
    <div id='CardSummary'>
      <div className='header'>
        <h1>{title}</h1>
        <p>{number}</p>
      </div>
      <div className='desc'>
        <h1>{t("tms.desc.Compare last month")}</h1>
        <div className={lastMonth < 0 ? "dec" : "inc"}>
          <p>{lastMonth}%</p>
          <div className='icon'>
            <MdOutlineShowChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSummary;
