import React from "react";
import "./ProductCategoryField.scss";

import { BsBoxSeam } from "react-icons/bs";
import { Tooltip } from "@mui/material";

import Character from "./../../../UI/Character/Character";

const ProductCategoryField = ({ order }) => {
  const TooltipContent = (
    <div className='ProductCategoryFieldTooltipContent'>
      {order?.cargos.map((item, index) => (
        <p key={index}>
          <span>{index + 1}. </span>
          {item?.type?.name || ""}
        </p>
      ))}
    </div>
  );

  return (
    <td id='ProductCategoryField'>
      {order?.cargos.length > 1 ? (
        <Tooltip
          placement='top'
          title={TooltipContent}
          classes={{ tooltip: "ProductCategoryFieldTooltip" }}
        >
          <div className='ProductCategoryFieldContainer many'>
            <div className='icon'>
              <BsBoxSeam />
            </div>
            <p>
              <Character max={25} title={order?.cargos[0]?.type?.name || ""} />
              <span> (+{order?.cargos.length - 1 || ""})</span>
            </p>
          </div>
        </Tooltip>
      ) : (
        <div className='ProductCategoryFieldContainer'>
          <div className='icon'>
            <BsBoxSeam />
          </div>
          <p>
            <Character max={25} title={order?.cargos[0]?.type?.name || ""} />
          </p>
        </div>
      )}
    </td>
  );
};

export default ProductCategoryField;
