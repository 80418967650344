import React, { Fragment, useState, useEffect } from "react";
import "./CustomersList.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getCustomers, resetGetCustomers } from "./../../../../../../store/customer/customerSlice";

import Wrapper from "./../../../../../../componentsAdditional/Wrapper";
import EmptyList from "../../../../../../componentsDashboard/UI/EmptyList/EmptyList";
import CustomersTable from "../../../../../../componentsDashboard/CustomersPartners/Customers/CustomersTable/CustomersTable";
import LoadingPage from "./../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import PageSwitcher from "../../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import CreateCustomerModal from "../../../../../../componentsDashboard/CustomersPartners/Customers/CreateCustomerModal/CreateCustomerModal";
import CustomersListsHeader from "../../../../../../componentsDashboard/CustomersPartners/Customers/CustomersListsHeader/CustomersListsHeader";

const CustomersList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { getCustomerscountResponce, getCustomerscountLoading } = useSelector(
    (state) => state.summary
  );

  const {
    getCustomersLoading,
    getCustomersResult,
    getCustomersMeta,
    createCustomerResult,
    updateCustomerResult,
    deleteCustomerResult,
  } = useSelector((state) => state.customer);

  const [page, setPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1);
  const [keyword, setKeyword] = useState("");
  const [addModalShow, setAddModalShow] = useState(false);

  useEffect(() => {
    dispatch(getCustomers({ page, keyword }));
    return () => {
      dispatch(resetGetCustomers());
    };
  }, [dispatch, page, keyword, createCustomerResult, updateCustomerResult, deleteCustomerResult]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Customers")}>
      <section id='CustomersList'>
        <PageSwitcher name='customer' />
        {getCustomerscountLoading && <LoadingPage />}

        {getCustomerscountResponce && (
          <Fragment>
            {getCustomerscountResponce.clients_count === 0 ? (
              <EmptyList
                image='/assets/images/dashboard/Empty-girl.svg'
                title={t("tms.titles.No Customer")}
                description={t("tms.desc.Add customers and take full data on your hand")}
                onClick={() => setAddModalShow(true)}
                buttonText={t("tms.forms.buttons.Add customer")}
              />
            ) : (
              <Fragment>
                <CustomersListsHeader setKeyword={setKeyword} setAddModalShow={setAddModalShow} />
                {getCustomersLoading && <LoadingPage />}
                {getCustomersResult && (
                  <CustomersTable
                    customers={getCustomersResult}
                    page={page}
                    setPage={setPage}
                    pageCount={getCustomersMeta.last_page}
                    total={getCustomersMeta.total}
                  />
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </section>
      {addModalShow && (
        <CreateCustomerModal isOpen={addModalShow} isClose={() => setAddModalShow(false)} />
      )}
    </Wrapper>
  );
};

export default CustomersList;
