import React from "react";
import "./ConnectAppTimeField.scss";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MdUpdate } from "react-icons/md";
import { PiWarningCircleBold } from "react-icons/pi";
import { useFromNowDate } from "../../../../helper/useFromNowDate";

const ConnectAppTimeField = ({ time }) => {
  const { t } = useTranslation();
  const { ISO } = useSelector((state) => state.lang);

  const formattedDate = useFromNowDate(
    moment(time, "DD/MM/YYYY HH:mm:ss"),
    ISO
  );
  const isMoreThan3DaysAgo =
    moment().diff(moment(time, "DD/MM/YYYY HH:mm:ss"), "days") > 3;

  return (
    <td id='ConnectAppTimeField'>
      {time ? (
        <div className='TimeFieldContainer'>
          {isMoreThan3DaysAgo ? (
            <div className='icon warning'>
              <PiWarningCircleBold />
            </div>
          ) : (
            <div className='icon'>
              <MdUpdate />
            </div>
          )}
          <h3>{formattedDate}</h3>
        </div>
      ) : (
        <div className='TimeFieldContainer notSend'>
          <div className='icon warning'>
            <PiWarningCircleBold />
          </div>
          <h3>{t("tms.tables.noInfo.Driver hasn’t logged in to app yet")}</h3>
        </div>
      )}
    </td>
  );
};

export default ConnectAppTimeField;
