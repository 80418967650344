import React from "react";
import "./ShipperRejectOrdersTable.scss";

import { useTranslation } from "react-i18next";

import TableHeader from "../Form/TableFields/TableHeader/TableHeader";
import TableWrapper from "../Form/TableFields/TableWrapper/TableWrapper";
import ShipperRejectOrdersTableItem from "./ShipperRejectOrdersTableItem/ShipperRejectOrdersTableItem";

const ShipperRejectOrdersTable = ({
  orders,
  page,
  setPage,
  pageCount,
  total,
}) => {
  const { t } = useTranslation();
  return (
    <div id='ShipperRejectOrdersTable'>
      <TableWrapper
        total={total}
        emptyText='No order'
        page={page}
        setPage={setPage}
        pageCount={pageCount}
      >
        <TableHeader>
          <th>{t("shipper.table.labels.Booking number")}</th>
          <th>{t("shipper.table.labels.Shipping type")}</th>
          <th>{t("shipper.table.labels.Route")}</th>
          <th>{t("shipper.table.labels.Product category")}</th>
          <th>{t("shipper.table.labels.Transportation request")}</th>
          <th>{t("shipper.table.labels.Pick up date")}</th>
          <th style={{ textAlign: "center" }}>
            {t("shipper.table.labels.Quotation")}
          </th>
        </TableHeader>
        <tbody>
          {orders?.map((order) => (
            <ShipperRejectOrdersTableItem key={order.id} order={order} />
          ))}
        </tbody>
      </TableWrapper>
    </div>
  );
};

export default ShipperRejectOrdersTable;
