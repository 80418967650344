import React from "react";
import "./ForAboutCarrier.scss";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import AboutItem from "./../Components/AboutItem/AboutItem";
import HomeTitle from "../../Components/HomeTitle/HomeTitle";

const ForAboutCarrier = () => {
  const { t } = useTranslation();

  return (
    <section id='ForAboutCarrier' className='ForAboutCarrier'>
      <div className='wrapper'>
        <HomeTitle
          description={t(
            "landing.titles.landingMainTitlesDesc.Access your carrier tools and information on the go with our user-friendly mobile app"
          )}
        >
          {parse(
            t(
              "landing.titles.landingMainTitles.Carry your <span>business</span> in your pocket"
            )
          )}
        </HomeTitle>

        <div className='container'>
          <AboutItem
            url='/assets/images/static/cover18.jpg'
            title={t("landing.titles.landingForAboutTitle.Effortless booking")}
            content={t(
              "landing.bodyText.landingForAboutBody.Simplify your carrier operations with our app's seamless booking process. Find and secure shipments with just a few taps, saving you time and effort."
            )}
            buttonTitle={t("landing.form.button.Join now")}
            link='/register'
            delay='400'
          />

          <AboutItem
            url='/assets/images/static/cover19.jpg'
            title={t(
              "landing.titles.landingForAboutTitle.Stay informed, every mile of the way"
            )}
            content={t(
              "landing.bodyText.landingForAboutBody.Track your shipments in real-time with precision. Our app keeps you updated on delivery progress, ensuring you're always in control."
            )}
            buttonTitle={t("landing.form.button.Join now")}
            link='/register'
            delay='250'
          />

          <AboutItem
            url='/assets/images/static/cover20.jpg'
            title={t(
              "landing.titles.landingForAboutTitle.Instant documentation"
            )}
            content={t(
              "landing.bodyText.landingForAboutBody.Say goodbye to paperwork hassles. Our app automates documentation, making it a breeze to manage and organize your shipping records."
            )}
            buttonTitle={t("landing.form.button.Join now")}
            link='/register'
            delay='100'
          />
        </div>
      </div>
    </section>
  );
};

export default ForAboutCarrier;
