import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notificationsService from "./notificationsService";

const initialState = {
  getNewNotiLoading: true,
  getNewNotiResult: null,
  getNewNotiMeta: null,
  getNewNotifiError: null,

  getNotiLoading: true,
  getNotiResult: null,
  getNotiMeta: null,
  getNotifiError: null,

  markAllNotiLoading: false,
  markAllNotiResult: null,
  markAllNotiError: null,

  markNotiLoading: false,
  markNotiResult: null,
  markNotiError: null,
};

export const getNewNotifications = createAsyncThunk(
  "notifications/getNewNotifications",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await notificationsService.getNotifications(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await notificationsService.getNotifications(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const markAllNotifications = createAsyncThunk(
  "notifications/markAllNotifications",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await notificationsService.markAllNotifications(ISO, TOKEN);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const markNotification = createAsyncThunk(
  "notifications/markNotification",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await notificationsService.markNotification(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    resetGetNewNotifications: (state) => {
      // state.getNewNotiLoading = true;
      // state.getNewNotiResult = null;
      // state.getNewNotiMeta = null;
      // state.getNewNotifiError = null;
    },
    resetGetNotifications: (state) => {
      state.getNotiLoading = true;
      state.getNotiResult = null;
      state.getNotiMeta = null;
      state.getNotifiError = null;
    },
    resetMarkAllNotifications: (state) => {
      state.markAllNotiLoading = false;
      state.markAllNotiResult = null;
      state.markAllNotiError = null;
    },
    resetMarkNotification: (state) => {
      state.markNotiLoading = false;
      state.markNotiResult = null;
      state.markNotiError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewNotifications.pending, (state) => {
        // state.getNewNotiLoading = true;
        // state.getNewNotiResult = null;
        // state.getNewNotiMeta = null;
        // state.getNewNotifiError = null;
      })
      .addCase(getNewNotifications.fulfilled, (state, action) => {
        state.getNewNotiLoading = false;
        state.getNewNotiResult = action.payload.data;
        state.getNewNotiMeta = action.payload.meta;
        state.getNewNotifiError = null;
      })
      .addCase(getNewNotifications.rejected, (state, action) => {
        // state.getNewNotiLoading = false;
        // state.getNewNotiResult = null;
        // state.getNewNotiMeta = null;
        // state.getNewNotifiError = action.payload;
      })
      .addCase(getNotifications.pending, (state) => {
        state.getNotiLoading = true;
        state.getNotiResult = null;
        state.getNotiMeta = null;
        state.getNotifiError = null;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.getNotiLoading = false;
        state.getNotiResult = action.payload.data;
        state.getNotiMeta = action.payload.meta;
        state.getNotifiError = null;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.getNotiLoading = false;
        state.getNotiResult = null;
        state.getNotiMeta = null;
        state.getNotifiError = action.payload;
      })

      .addCase(markAllNotifications.pending, (state) => {
        state.markAllNotiLoading = true;
        state.markAllNotiResult = null;
        state.markAllNotiError = null;
      })
      .addCase(markAllNotifications.fulfilled, (state, action) => {
        state.markAllNotiLoading = false;
        state.markAllNotiResult = action.payload;
        state.markAllNotiError = null;
      })
      .addCase(markAllNotifications.rejected, (state, action) => {
        state.markAllNotiLoading = false;
        state.markAllNotiResult = null;
        state.markAllNotiError = action.payload;
      })

      .addCase(markNotification.pending, (state) => {
        state.markNotiLoading = true;
        state.markNotiResult = null;
        state.markNotiError = null;
      })
      .addCase(markNotification.fulfilled, (state, action) => {
        state.markNotiLoading = false;
        state.markNotiResult = action.payload;
        state.markNotiError = null;
      })
      .addCase(markNotification.rejected, (state, action) => {
        state.markNotiLoading = false;
        state.markNotiResult = null;
        state.markNotiError = action.payload;
      });
  },
});

export const {
  resetGetNewNotifications,
  resetGetNotifications,
  resetMarkAllNotifications,
  resetMarkNotification,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
