import React, { useState } from "react";
import "./Marker.scss";

import { MarkerF, InfoWindowF } from "@react-google-maps/api";

import { iconA } from "./iconA";

const MarkerA = ({ item }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <MarkerF
      key={item.id}
      icon={iconA()}
      position={{
        lat: parseFloat(item?.lat) || 0,
        lng: parseFloat(item?.lng) || 0,
      }}
      onClick={() => setShowInfo(true)}
    >
      {showInfo && (
        <InfoWindowF
          position={{
            lat: parseFloat(item?.lat) || 0,
            lng: parseFloat(item?.lng) || 0,
          }}
          onCloseClick={() => setShowInfo(false)}
        >
          <div id='MarkerAInfoWindowF'>
            <h3>{item.formatted_address}</h3>
          </div>
        </InfoWindowF>
      )}
    </MarkerF>
  );
};

export default MarkerA;
