import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import locationSuggestionService from "./locationSuggestionService";

const initialState = {
  getPickupLOCALITYLoading: true,
  getPickupLOCALITYResult: null,
  getPickupLOCALITYError: null,

  getPickupAIRPORTLoading: true,
  getPickupAIRPORTResult: null,
  getPickupAIRPORTError: null,

  getPickupPORTLoading: true,
  getPickupPORTResult: null,
  getPickupPORTError: null,

  getPickupSTATIONLoading: true,
  getPickupSTATIONResult: null,
  getPickupSTATIONError: null,

  getDropoffLOCALITYLoading: true,
  getDropoffLOCALITYResult: null,
  getDropoffLOCALITYError: null,

  getDropoffAIRPORTLoading: true,
  getDropoffAIRPORTResult: null,
  getDropoffAIRPORTError: null,

  getDropoffPORTLoading: true,
  getDropoffPORTResult: null,
  getDropoffPORTError: null,

  getDropoffSTATIONLoading: true,
  getDropoffSTATIONResult: null,
  getDropoffSTATIONError: null,
};

export const getPickupLOCALITY = createAsyncThunk(
  "locationSuggestion/getPickupLOCALITY",
  async (clientId, thunkAPI) => {
    try {
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await locationSuggestionService.getPickupSuggestion(TOKEN, clientId, "LOCALITY");
    } catch (error) {
      if (error.response && error.response && error.response.status === 422) {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      } else {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        alert(message.message);
      }
    }
  }
);

export const getPickupAIRPORT = createAsyncThunk(
  "locationSuggestion/getPickupAIRPORT",
  async (clientId, thunkAPI) => {
    try {
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await locationSuggestionService.getPickupSuggestion(TOKEN, clientId, "AIRPORT");
    } catch (error) {
      if (error.response && error.response && error.response.status === 422) {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      } else {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        alert(message.message);
      }
    }
  }
);

export const getPickupPORT = createAsyncThunk(
  "locationSuggestion/getPickupPORT",
  async (clientId, thunkAPI) => {
    try {
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await locationSuggestionService.getPickupSuggestion(TOKEN, clientId, "PORT");
    } catch (error) {
      if (error.response && error.response && error.response.status === 422) {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      } else {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        alert(message.message);
      }
    }
  }
);

export const getPickupSTATION = createAsyncThunk(
  "locationSuggestion/getPickupSTATION",
  async (clientId, thunkAPI) => {
    try {
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await locationSuggestionService.getPickupSuggestion(TOKEN, clientId, "STATION");
    } catch (error) {
      if (error.response && error.response && error.response.status === 422) {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      } else {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        alert(message.message);
      }
    }
  }
);

export const getDropoffLOCALITY = createAsyncThunk(
  "locationSuggestion/getDropoffLOCALITY",
  async (clientId, thunkAPI) => {
    try {
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await locationSuggestionService.getDropoffSuggestion(TOKEN, clientId, "LOCALITY");
    } catch (error) {
      if (error.response && error.response && error.response.status === 422) {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      } else {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        alert(message.message);
      }
    }
  }
);

export const getDropoffAIRPORT = createAsyncThunk(
  "locationSuggestion/getDropoffAIRPORT",
  async (clientId, thunkAPI) => {
    try {
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await locationSuggestionService.getDropoffSuggestion(TOKEN, clientId, "AIRPORT");
    } catch (error) {
      if (error.response && error.response && error.response.status === 422) {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      } else {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        alert(message.message);
      }
    }
  }
);

export const getDropoffPORT = createAsyncThunk(
  "locationSuggestion/getDropoffPORT",
  async (clientId, thunkAPI) => {
    try {
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await locationSuggestionService.getDropoffSuggestion(TOKEN, clientId, "PORT");
    } catch (error) {
      if (error.response && error.response && error.response.status === 422) {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      } else {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        alert(message.message);
      }
    }
  }
);

export const getDropoffSTATION = createAsyncThunk(
  "locationSuggestion/getDropoffSTATION",
  async (clientId, thunkAPI) => {
    try {
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await locationSuggestionService.getDropoffSuggestion(TOKEN, clientId, "STATION");
    } catch (error) {
      if (error.response && error.response && error.response.status === 422) {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      } else {
        const message =
          (error.response && error.response.data) || error.message || error.toString();
        alert(message.message);
      }
    }
  }
);

export const locationSuggestionSlice = createSlice({
  name: "locationSuggestion",
  initialState,
  reducers: {
    resetGetPickupLOCALITY: (state) => {
      state.getPickupLOCALITYLoading = true;
      state.getPickupLOCALITYResult = null;
      state.getPickupLOCALITYError = null;
    },
    resetGetPickupAIRPORT: (state) => {
      state.getPickupAIRPORTLoading = true;
      state.getPickupAIRPORTResult = null;
      state.getPickupAIRPORTError = null;
    },
    resetGetPickupPORT: (state) => {
      state.getPickupPORTLoading = true;
      state.getPickupPORTResult = null;
      state.getPickupPORTError = null;
    },
    resetGetPickupSTATION: (state) => {
      state.getPickupSTATIONLoading = true;
      state.getPickupSTATIONResult = null;
      state.getPickupSTATIONError = null;
    },
    resetGetDropoffLOCALITY: (state) => {
      state.getDropoffLOCALITYLoading = true;
      state.getDropoffLOCALITYResult = null;
      state.getDropoffLOCALITYError = null;
    },
    resetGetDropoffAIRPORT: (state) => {
      state.getDropoffAIRPORTLoading = true;
      state.getDropoffAIRPORTResult = null;
      state.getDropoffAIRPORTError = null;
    },
    resetGetDropoffPORT: (state) => {
      state.getDropoffPORTLoading = true;
      state.getDropoffPORTResult = null;
      state.getDropoffPORTError = null;
    },
    resetGetDropoffSTATION: (state) => {
      state.getDropoffSTATIONLoading = true;
      state.getDropoffSTATIONResult = null;
      state.getDropoffSTATIONError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPickupLOCALITY.pending, (state) => {
        state.getPickupLOCALITYLoading = true;
        state.getPickupLOCALITYResult = null;
        state.getPickupLOCALITYError = null;
      })
      .addCase(getPickupLOCALITY.fulfilled, (state, action) => {
        state.getPickupLOCALITYLoading = false;
        state.getPickupLOCALITYResult = action.payload;
        state.getPickupLOCALITYError = null;
      })
      .addCase(getPickupLOCALITY.rejected, (state, action) => {
        state.getPickupLOCALITYLoading = false;
        state.getPickupLOCALITYResult = null;
        state.getPickupLOCALITYError = action.payload;
      })

      .addCase(getPickupAIRPORT.pending, (state) => {
        state.getPickupAIRPORTLoading = true;
        state.getPickupAIRPORTResult = null;
        state.getPickupAIRPORTError = null;
      })
      .addCase(getPickupAIRPORT.fulfilled, (state, action) => {
        state.getPickupAIRPORTLoading = false;
        state.getPickupAIRPORTResult = action.payload;
        state.getPickupAIRPORTError = null;
      })
      .addCase(getPickupAIRPORT.rejected, (state, action) => {
        state.getPickupAIRPORTLoading = false;
        state.getPickupAIRPORTResult = null;
        state.getPickupAIRPORTError = action.payload;
      })

      .addCase(getPickupPORT.pending, (state) => {
        state.getPickupPORTLoading = true;
        state.getPickupPORTResult = null;
        state.getPickupPORTError = null;
      })
      .addCase(getPickupPORT.fulfilled, (state, action) => {
        state.getPickupPORTLoading = false;
        state.getPickupPORTResult = action.payload;
        state.getPickupPORTError = null;
      })
      .addCase(getPickupPORT.rejected, (state, action) => {
        state.getPickupPORTLoading = false;
        state.getPickupPORTResult = null;
        state.getPickupPORTError = action.payload;
      })

      .addCase(getPickupSTATION.pending, (state) => {
        state.getPickupSTATIONLoading = true;
        state.getPickupSTATIONResult = null;
        state.getPickupSTATIONError = null;
      })
      .addCase(getPickupSTATION.fulfilled, (state, action) => {
        state.getPickupSTATIONLoading = false;
        state.getPickupSTATIONResult = action.payload;
        state.getPickupSTATIONError = null;
      })
      .addCase(getPickupSTATION.rejected, (state, action) => {
        state.getPickupSTATIONLoading = false;
        state.getPickupSTATIONResult = null;
        state.getPickupSTATIONError = action.payload;
      })

      .addCase(getDropoffLOCALITY.pending, (state) => {
        state.getDropoffLOCALITYLoading = true;
        state.getDropoffLOCALITYResult = null;
        state.getDropoffLOCALITYError = null;
      })
      .addCase(getDropoffLOCALITY.fulfilled, (state, action) => {
        state.getDropoffLOCALITYLoading = false;
        state.getDropoffLOCALITYResult = action.payload;
        state.getDropoffLOCALITYError = null;
      })
      .addCase(getDropoffLOCALITY.rejected, (state, action) => {
        state.getDropoffLOCALITYLoading = false;
        state.getDropoffLOCALITYResult = null;
        state.getDropoffLOCALITYError = action.payload;
      })

      .addCase(getDropoffAIRPORT.pending, (state) => {
        state.getDropoffAIRPORTLoading = true;
        state.getDropoffAIRPORTResult = null;
        state.getDropoffAIRPORTError = null;
      })
      .addCase(getDropoffAIRPORT.fulfilled, (state, action) => {
        state.getDropoffAIRPORTLoading = false;
        state.getDropoffAIRPORTResult = action.payload;
        state.getDropoffAIRPORTError = null;
      })
      .addCase(getDropoffAIRPORT.rejected, (state, action) => {
        state.getDropoffAIRPORTLoading = false;
        state.getDropoffAIRPORTResult = null;
        state.getDropoffAIRPORTError = action.payload;
      })

      .addCase(getDropoffPORT.pending, (state) => {
        state.getDropoffPORTLoading = true;
        state.getDropoffPORTResult = null;
        state.getDropoffPORTError = null;
      })
      .addCase(getDropoffPORT.fulfilled, (state, action) => {
        state.getDropoffPORTLoading = false;
        state.getDropoffPORTResult = action.payload;
        state.getDropoffPORTError = null;
      })
      .addCase(getDropoffPORT.rejected, (state, action) => {
        state.getDropoffPORTLoading = false;
        state.getDropoffPORTResult = null;
        state.getDropoffPORTError = action.payload;
      })

      .addCase(getDropoffSTATION.pending, (state) => {
        state.getDropoffSTATIONLoading = true;
        state.getDropoffSTATIONResult = null;
        state.getDropoffSTATIONError = null;
      })
      .addCase(getDropoffSTATION.fulfilled, (state, action) => {
        state.getDropoffSTATIONLoading = false;
        state.getDropoffSTATIONResult = action.payload;
        state.getDropoffSTATIONError = null;
      })
      .addCase(getDropoffSTATION.rejected, (state, action) => {
        state.getDropoffSTATIONLoading = false;
        state.getDropoffSTATIONResult = null;
        state.getDropoffSTATIONError = action.payload;
      });
  },
});

export const {
  resetGetPickupLOCALITY,
  resetGetPickupAIRPORT,
  resetGetPickupPORT,
  resetGetPickupSTATION,
  resetGetDropoffLOCALITY,
  resetGetDropoffAIRPORT,
  resetGetDropoffPORT,
  resetGetDropoffSTATION,
} = locationSuggestionSlice.actions;
export default locationSuggestionSlice.reducer;
