import React from "react";
import "./Footer.scss";

import { useTranslation } from "react-i18next";

import Button from "../../../../../Form/Button/Button";

const Footer = ({ submitHandler, loading }) => {
  const { t } = useTranslation();
  return (
    <div id='CarrierSubscribeFormFooter'>
      <Button
        title={t("landing.landingCarrierSubscribe.Subscribe")}
        onClick={submitHandler}
        loading={loading}
      />
    </div>
  );
};

export default Footer;
