import React, { Fragment, useEffect } from "react";
import "./FinancialDetails.scss";
import { useDispatch } from "react-redux";

import { getOrderCosts, resetGetOrderCosts } from "./../../../store/orders/costs/orderCostsSlice";

import OrderProfit from "./Components/OrderProfit/OrderProfit";
import FinancialDetailsHeader from "./Components/FinancialDetailsHeader/FinancialDetailsHeader";
import AddAlreadyPaidMoney from "./Components/AddAlreadyPaidMoney/AddAlreadyPaidMoney";
import FinancialDetailPayment from "./Components/FinancialDetailPayment/FinancialDetailPayment";
import CustomerPrice from "./Components/CustomerPrice/CustomerPrice";
import PartnerPrice from "./Components/PartnerPrice/PartnerPrice";
import OwnCarPrice from "./Components/OwnCarPrice/OwnCarPrice";

const FinancialDetails = ({ order }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (order) {
      dispatch(getOrderCosts(order.id));
    }
    return () => {
      dispatch(resetGetOrderCosts());
    };
  }, [dispatch, order]);

  return (
    <Fragment>
      {order && (
        <div id='FinancialDetails'>
          <FinancialDetailsHeader order={order} />
          <div className='FinancialDetailsContent'>
            <FinancialDetailPayment order={order} />
            <div className='FinancialDetailslist'>
              <CustomerPrice order={order} />
              {!order.partner ? <OwnCarPrice order={order} /> : <PartnerPrice order={order} />}
              {order?.total_revenue !== 0 && <OrderProfit order={order} />}
            </div>
          </div>
          {!order.client_paid_at && <AddAlreadyPaidMoney order={order} />}
        </div>
      )}
    </Fragment>
  );
};

export default FinancialDetails;
