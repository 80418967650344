import React from "react";
import "./TruckMaintenanceTable.scss";

import { useTranslation } from "react-i18next";

import TruckMaintenanceTableItem from "./TruckMaintenanceTableItem/TruckMaintenanceTableItem";
import TableHeader from "../../../../../Form/TableFields/TableHeader/TableHeader";
import NoInformation from "../../../../../Form/TableFields/NoInformation/NoInformation";

const TruckMaintenanceTable = ({ maintenances }) => {
  const { t } = useTranslation();
  return (
    <div id='TruckMaintenanceTable'>
      <div className='tableWrapper'>
        <table>
          <TableHeader>
            <th>{t("tms.tables.titles.Service")}</th>
            <th>{t("tms.tables.titles.Service provider")}</th>
            <th>{t("tms.tables.titles.Date")}</th>
            <th colSpan='2'>{t("tms.tables.titles.Cost (Include VAT)")}</th>
          </TableHeader>
          <tbody>
            {maintenances.map((expense, index) => (
              <TruckMaintenanceTableItem key={index} truck={expense} />
            ))}
          </tbody>
        </table>
        {maintenances.length === 0 && (
          <NoInformation title={t("tms.tables.noInfo.no maintenance")} />
        )}
      </div>
    </div>
  );
};

export default TruckMaintenanceTable;
