import React from "react";
import "./PaymentStatusField.scss";

import { useTranslation } from "react-i18next";
import { PiWarningCircleFill, PiCheckCircleFill } from "react-icons/pi";
import { Tooltip } from "@mui/material";

const PaymentStatusField = ({ item }) => {
  const { t } = useTranslation();

  return (
    <td id='PaymentStatusField'>
      <div className='PaymentStatusFieldContainer'>
        <Tooltip
          placement='top'
          title={
            <div className='StatusFieldContent'>
              <h3>
                {item.client_paid_at
                  ? t("tms.tables.noInfo.Paid order")
                  : t("tms.tables.noInfo.No paid order")}
              </h3>
            </div>
          }
          classes={{ tooltip: "PaymentStatusFieldWarningTooltip" }}
        >
          {item.client_paid_at ? (
            <div className='icon done'>
              <PiCheckCircleFill />
            </div>
          ) : (
            <div className='icon'>
              <PiWarningCircleFill />
            </div>
          )}
        </Tooltip>
      </div>
    </td>
  );
};

export default PaymentStatusField;
