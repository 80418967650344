import React from "react";
import "./Footer.scss";

import LanguageSidebar from "../LanguageSidebar/LanguageSidebar";
import Profile from "../Profile/Profile";

const Footer = () => {
  return (
    <div id='D-Footer'>
      <LanguageSidebar />
      <Profile />
    </div>
  );
};

export default Footer;
