import React, { Fragment } from "react";
import "./DErrorText.scss";

import Character from "../../UI/Character/Character";

const DErrorText = ({ error, max }) => {
  return (
    <Fragment>
      {error && (
        <div id='DErrorText'>
          <p className='DErrorTextContent'>
            <Character title={error} max={max ? max : "50"} />
          </p>
        </div>
      )}
    </Fragment>
  );
};

export default DErrorText;
