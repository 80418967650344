import React from "react";
import "./DateField.scss";

import { useTranslation } from "react-i18next";

import { FiCalendar } from "react-icons/fi";

const DateField = ({ date, noDate }) => {
  const { t } = useTranslation();

  return (
    <td id='DateField'>
      {date ? (
        <div className='active'>
          <div className='icon'>
            <FiCalendar />
          </div>
          <p>{date}</p>
        </div>
      ) : (
        <div className='inActive'>
          <p>{noDate ? noDate : t("tms.tables.noInfo.no date")}</p>
        </div>
      )}
    </td>
  );
};

export default DateField;
