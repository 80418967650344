import React, { Fragment, useEffect } from "react";
import "./OrderDetails.scss";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getOrder, resetGetOrder } from "../../../../../store/orders/ordersSlice";

import Wrapper from "../../../../../componentsAdditional/Wrapper";
import OrderDetailsHeader from "../../../../../componentsDashboard/Orders/OrderDetailsHeader/OrderDetailsHeader";
import FinancialDetails from "../../../../../componentsDashboard/Orders/FinancialDetails/FinancialDetails";
import SimilarOrdersNavbar from "../../../../../componentsDashboard/Orders/SimilarOrdersNavbar/SimilarOrdersNavbar";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import OrderMapImage from "../../../../../componentsDashboard/Orders/OrderMapImage/OrderMapImage";
import SentUpdatesDetails from "../../../../../componentsDashboard/Orders/SentUpdatesDetails/SentUpdatesDetails";
import OrderStatusBar from "../../../../../componentsDashboard/Orders/OrderStatusBar/OrderStatusBar";
import DocumentsDetails from "../../../../../componentsDashboard/Orders/DocumentsDetails/DocumentsDetails";
import FilledAssignCarrier from "./../../../../../componentsDashboard/Orders/ManageOrder/FilledAssignCarrier/FilledAssignCarrier";
import AssignCarrier from "./../../../../../componentsDashboard/Orders/ManageOrder/AssignCarrier/AssignCarrier";
import PageNotFound from "./../../../../../componentsBids/PageNotFound/PageNotFound";
import PartnerRate from "../../../../../componentsDashboard/Orders/PartnerRate/PartnerRate";
import OrderInformationDetails from "../../../../../componentsDashboard/Orders/OrderInformationDetails/OrderInformationDetails";
import ShipmentUpdates from "../../../../../componentsDashboard/Orders/ShipmentUpdates/ShipmentUpdates";

const OrderDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const { getOrderLoading, getOrderResult, getOrderError } = useSelector((state) => state.orders);
  const {
    unassignCarrierResult,
    assignCarrierResult,
    createTransportResult,
    updateTransportResult,
  } = useSelector((state) => state.manageOrder);
  const { updateOrderStatusesResult } = useSelector((state) => state.orderStatuses);
  const { createOrderCostResult, updateOrderCostResult, deleteOrderCostResult } = useSelector(
    (state) => state.orderCosts
  );
  const { createInvoiceResult } = useSelector((state) => state.invoices);

  const { ratePartnerResult } = useSelector((state) => state.partner);
  const { createAttachmentResult, deleteAttachmentResult } = useSelector(
    (state) => state.attachments
  );
  const {
    updateOrderRevenueResult,
    updateOrderExpenseResult,
    createPaymentResult,
    updatePaymentResult,
    deletePaymentResult,
    updateClientPaidResult,
  } = useSelector((state) => state.orderFinance);

  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => {
      dispatch(resetGetOrder());
    };
  }, [
    dispatch,
    orderId,
    updateOrderStatusesResult,
    assignCarrierResult,
    unassignCarrierResult,
    createTransportResult,
    updateTransportResult,
    createOrderCostResult,
    updateOrderCostResult,
    deleteOrderCostResult,
    createPaymentResult,
    updatePaymentResult,
    deletePaymentResult,
    updateClientPaidResult,
    createInvoiceResult,
    ratePartnerResult,
    createAttachmentResult,
    deleteAttachmentResult,
    updateOrderRevenueResult,
    updateOrderExpenseResult,
  ]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Manage tracking")}>
      {getOrderLoading && <LoadingPage />}
      {getOrderError && <PageNotFound />}
      {getOrderResult && !getOrderLoading && (
        <section id='OrderDetailsTMC'>
          <OrderDetailsHeader order={getOrderResult} Delete Edit />
          <SimilarOrdersNavbar groups={getOrderResult.groups} />
          <OrderStatusBar order={getOrderResult} />
          <Fragment>
            <div className='trakingContainer'>
              <div className='left'>
                <PartnerRate order={getOrderResult} />
                <AssignCarrier order={getOrderResult} />
                <FilledAssignCarrier order={getOrderResult} />
                <FinancialDetails order={getOrderResult} />
                <OrderInformationDetails order={getOrderResult} />
              </div>
              <div className='right'>
                <OrderMapImage order={getOrderResult} />
                <ShipmentUpdates order={getOrderResult} />
                <DocumentsDetails order={getOrderResult} />
                <SentUpdatesDetails order={getOrderResult} />
              </div>
            </div>
          </Fragment>
        </section>
      )}
    </Wrapper>
  );
};

export default OrderDetails;
