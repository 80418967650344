import React, { useEffect, useState } from "react";
import "./EditBankRequisitesModal.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IoMdWallet } from "react-icons/io";

import {
  updateBankAccount,
  resetUpdateBankAccount,
} from "../../../../../store/user/bankAccounts/bankAccountsSlice";

import DModal from "../../../../Modal/DModal/DModal";
import DInputs from "../../../../Form/DInputs/DInputs";

const EditBankRequisitesModal = ({ isOpen, isClose, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updateBankAccountLoading, updateBankAccountResult, updateBankAccountError } = useSelector(
    (state) => state.bankAccounts
  );

  const [bankName, setBankName] = useState(item?.name || "");
  const [bankCode, setBankCode] = useState(item?.code || "");
  const [bankAccount, setBankAccount] = useState(item?.account || "");

  const submitHandler = () => {
    const data = {
      id: item.id,
      name: bankName,
      code: bankCode,
      account: bankAccount,
    };
    dispatch(updateBankAccount(data));
  };

  useEffect(() => {
    if (updateBankAccountResult) {
      dispatch(resetUpdateBankAccount());
      isClose();
    }
  }, [dispatch, isClose, updateBankAccountResult]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.Edit bank requisites")}
      icon={<IoMdWallet />}
      submitText={t("tms.modals.buttons.Save")}
      loading={updateBankAccountLoading}
      onClick={submitHandler}
      status='info'
    >
      <div id='EditBankRequisitesModal'>
        <div className='fromGorup'>
          <DInputs
            id='EditEnterbankname'
            label={t("tms.modals.labels.Bank name")}
            placeholder={t("tms.modals.placeholder.Enter bank name")}
            value={bankName}
            setValue={setBankName}
            error={updateBankAccountError?.errors?.name?.[0]}
            required
          />
          <DInputs
            id='EditEnterbankcode'
            label={t("tms.modals.labels.Bank code")}
            placeholder={t("tms.modals.placeholder.Enter bank code")}
            value={bankCode}
            setValue={setBankCode}
            error={updateBankAccountError?.errors?.code?.[0]}
            required
          />
          <DInputs
            id='EditEnterbankaccount'
            label={t("tms.modals.labels.Bank account")}
            placeholder={t("tms.modals.placeholder.Enter bank account")}
            value={bankAccount}
            setValue={setBankAccount}
            error={updateBankAccountError?.errors?.account?.[0]}
            required
          />
        </div>
      </div>
    </DModal>
  );
};

export default EditBankRequisitesModal;
