import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import otherService from "./settingsService";

// get phoneIndexValue from localStorage
const phoneIndexValueId = JSON.parse(localStorage.getItem("phoneIndexValueId"));

// get country id from localStorage
const countryId = JSON.parse(localStorage.getItem("countryId"));

const initialState = {
  // country id
  countryId: countryId,

  // get countries
  getCountriesLoading: false,
  getCountriesResult: null,
  getCountriesSelect: null,
  getCountriesError: null,

  // get all countries
  getAllCountriesLoading: false,
  getAllCountriesResult: null,
  getAllCountriesError: null,

  // phone index id
  phoneIndexValueId: phoneIndexValueId,

  // get phone Indexes
  getPhoneIndexesLoading: false,
  getPhoneIndexesResult: null,
  getPhoneIndexesError: null,

  // get currency
  getCurrenciesLoading: false,
  getCurrenciesResult: null,
  getCurrenciesSelect: null,
  getCurrenciesError: null,

  // set currency
  setCurrencyLoading: false,
  setCurrencyResult: null,
  setCurrencyError: null,

  // get Geolocation Country
  getGeolocationCountryLoading: true,
  getGeolocationCountryResult: null,
  getGeolocationCountryError: null,
};

// get countries
export const getCountries = createAsyncThunk("settings/getCountries", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await otherService.getCountries(ISO);
  } catch (error) {
    const message = (error.response && error.response.data) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// get all countries
export const getAllCountries = createAsyncThunk("settings/getAllCountries", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await otherService.getAllCountries(ISO);
  } catch (error) {
    const message = (error.response && error.response.data) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// changed phone index value
export const setPhoneIndexValue = createAsyncThunk("settings/setPhoneIndexValue", async (data) => {
  if (data) {
    localStorage.setItem("phoneIndexValueId", JSON.stringify(data));
  }
  return data;
});

// get phone Indexes
export const getPhoneIndexes = createAsyncThunk("settings/getPhoneIndexes", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const response = await otherService.getPhoneIndexes(ISO);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// get currency
export const getCurrencies = createAsyncThunk("settings/getCurrencies", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const branchId = thunkAPI.getState().auth.user.branch_id;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await otherService.getCurrencies(ISO, TOKEN, branchId);
  } catch (error) {
    const message = (error.response && error.response.data) || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// set currency
export const setCurrency = createAsyncThunk("settings/setCurrency", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await otherService.setCurrency(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// get Geolocation Country
export const getGeolocationCountry = createAsyncThunk(
  "settings/getGeolocationCountry",
  async (_, thunkAPI) => {
    try {
      const response = await otherService.getGeolocationCountry();
      return response;
    } catch (error) {
      const message = (error.response && error.response.data) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetSetCurrency: (state) => {
      state.setCurrencyLoading = false;
      state.setCurrencyResult = null;
      state.setCurrencyError = null;
    },
    setPhoneIndexValueOnlyState: (state, action) => {
      state.phoneIndexValueId = action.payload;
    },
    setCountryIdOnlyState: (state, action) => {
      state.countryId = action.payload;
    },
    setCountryId: (state, action) => {
      state.countryId = action.payload;
      localStorage.setItem("countryId", JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      // get countries
      .addCase(getCountries.pending, (state) => {
        state.getCountriesLoading = true;
        state.getCountriesResult = null;
        state.getCountriesSelect = null;
        state.getCountriesError = null;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.getCountriesLoading = false;
        state.getCountriesResult = action.payload;
        state.getCountriesSelect = action.payload.map((item) => ({
          label: item.name,
          value: item,
        }));
        state.getCountriesError = null;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.getCountriesLoading = false;
        state.getCountriesResult = null;
        state.getCountriesSelect = null;
        state.getCountriesError = action.payload;
      })

      // get all countries
      .addCase(getAllCountries.pending, (state) => {
        state.getAllCountriesLoading = true;
        state.getAllCountriesResult = null;
        state.getAllCountriesError = null;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.getAllCountriesLoading = false;
        state.getAllCountriesResult = action.payload;
        state.getAllCountriesError = null;
      })
      .addCase(getAllCountries.rejected, (state, action) => {
        state.getAllCountriesLoading = false;
        state.getAllCountriesResult = null;
        state.getAllCountriesError = action.payload;
      })

      // get phone Indexes
      .addCase(getPhoneIndexes.pending, (state) => {
        state.getPhoneIndexesLoading = true;
        state.getPhoneIndexesResult = null;
        state.getPhoneIndexesError = null;
      })
      .addCase(getPhoneIndexes.fulfilled, (state, action) => {
        state.getPhoneIndexesLoading = false;
        state.getPhoneIndexesResult = action.payload;
        state.getPhoneIndexesError = null;
      })
      .addCase(getPhoneIndexes.rejected, (state, action) => {
        state.getPhoneIndexesLoading = false;
        state.getPhoneIndexesResult = null;
        state.getPhoneIndexesError = action.payload;
      })

      // changed phone index value
      .addCase(setPhoneIndexValue.fulfilled, (state, action) => {
        state.phoneIndexValueId = action.payload;
      })

      // get currency
      .addCase(getCurrencies.pending, (state) => {
        state.getCurrenciesLoading = true;
        state.getCurrenciesResult = null;
        state.getCurrenciesSelect = null;
        state.getCurrenciesError = null;
      })
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.getCurrenciesLoading = false;
        state.getCurrenciesResult = action.payload;
        state.getCurrenciesSelect = action.payload.map((item) => ({
          value: item,
          label: item.code,
        }));
        state.getCurrenciesError = null;
      })
      .addCase(getCurrencies.rejected, (state, action) => {
        state.getCurrenciesLoading = false;
        state.getCurrenciesResult = null;
        state.getCurrenciesSelect = null;
        state.getCurrenciesError = action.payload;
      })

      .addCase(setCurrency.pending, (state) => {
        state.setCurrencyLoading = true;
        state.setCurrencyResult = null;
        state.setCurrencyError = null;
      })
      .addCase(setCurrency.fulfilled, (state, action) => {
        state.setCurrencyLoading = false;
        state.setCurrencyResult = action.payload;
        state.setCurrencyError = null;
      })
      .addCase(setCurrency.rejected, (state, action) => {
        state.setCurrencyLoading = false;
        state.setCurrencyResult = null;
        state.setCurrencyError = action.payload;
      })

      .addCase(getGeolocationCountry.pending, (state) => {
        state.getGeolocationCountryLoading = true;
        state.getGeolocationCountryResult = null;
        state.getGeolocationCountryError = null;
      })
      .addCase(getGeolocationCountry.fulfilled, (state, action) => {
        state.getGeolocationCountryLoading = false;
        state.getGeolocationCountryResult = action.payload;
        // state.getGeolocationCountryResult = { country: "UZ" };
        state.getGeolocationCountryError = null;
      })
      .addCase(getGeolocationCountry.rejected, (state, action) => {
        state.getGeolocationCountryLoading = false;
        state.getGeolocationCountryResult = null;
        state.getGeolocationCountryError = action.payload;
      });
  },
});

export const {
  resetSetCurrency,
  setPhoneIndexValueOnlyState,
  setCountryIdOnlyState,
  setCountryId,
} = settingsSlice.actions;

export default settingsSlice.reducer;
