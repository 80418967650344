import React, { Fragment } from "react";
import "./RoadWeightGroup.scss";

import { useTranslation } from "react-i18next";

import DTextButton from "../../../../../../Form/DButtons/DTextButton/DTextButton";
import RoadWeightGroupItem from "./RoadWeightGroupItem/RoadWeightGroupItem";
import CreateOrderSectionSecondaryTitle from "../../../../Components/CreateOrderSectionSecondaryTitle/CreateOrderSectionSecondaryTitle";

const RoadWeightGroup = ({ group, setGroup, totalWeight }) => {
  const { t } = useTranslation();

  const recountHandler = () => {
    const convertedOrders = group.map((item) => ({
      ...item,
      weight:
        totalWeight && totalWeight.length > 0
          ? parseFloat((+totalWeight / group.length).toFixed(0))
          : 0,
    }));
    setGroup(convertedOrders);
  };

  const totalWeightSum = group.reduce((accumulator, currentValue) => {
    return +accumulator + +currentValue.weight;
  }, 0);

  const overSize = (totalWeight && +totalWeight) - (totalWeightSum && +totalWeightSum);

  return (
    <Fragment>
      <CreateOrderSectionSecondaryTitle title={t("shipper.forms.titles.Weights")} />
      {group && group.length > 1 && (
        <div id='RoadWeightGroup'>
          <div className='RoadWeightGroupitems'>
            {group.map((item, index) => (
              <RoadWeightGroupItem
                key={index}
                index={index}
                item={item}
                group={group}
                setGroup={setGroup}
                totalWeight={totalWeight}
              />
            ))}

            <div className='RoadWeightGroupActions'>
              <div className='reCount'>
                <DTextButton
                  title={t("shipper.buttons.Distribute evenly")}
                  onClick={() => recountHandler()}
                />
              </div>
              {overSize !== 0 && totalWeight > 0 && (
                <div className='overSize'>
                  <h5 className={overSize > 0 ? "plus" : "minus"}>
                    {overSize} {t("shipper.units.KG")}
                  </h5>
                  <h6>{t("shipper.forms.content.still needs to move")}</h6>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default RoadWeightGroup;
