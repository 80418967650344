import React, { Fragment, useEffect } from "react";
import "./CostsModalShipper.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FiX } from "react-icons/fi";

import { getOrderCosts } from "../../../../../store/orders/costs/orderCostsSlice";

import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import DOverlay from "../../../../Modal/DOverlay/DOverlay";
import CostItemShipper from "./CostItemShipper/CostItemShipper";

const CostsModalShipper = ({ isOpen, isClose, orderId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getOrderCostsLoading, getOrderCostsResult } = useSelector((state) => state.orderCosts);

  useEffect(() => {
    if (isOpen) {
      dispatch(getOrderCosts(orderId));
    }
  }, [dispatch, isOpen, orderId]);
  return (
    <Fragment>
      <DOverlay isOpen={isOpen} isClose={isClose}>
        <div id='CostsModalShipper'>
          <div className='ShowCostsModalHeader'>
            <div className='title'>
              <div className='titleIcon'>
                <FaMoneyCheckAlt />
              </div>
              <h2>{t("shipper.modal.Additional costs")}</h2>
            </div>
            <div className='closeIcon' onClick={isClose}>
              <FiX />
            </div>
          </div>

          <div className='ShowCostsModalContainer'>
            {getOrderCostsLoading && <LoadingPage />}

            {getOrderCostsResult && (
              <Fragment>
                {getOrderCostsResult.length === 0 ? (
                  <p className='noCosts'>{t("shipper.modal.No additional costs")}</p>
                ) : (
                  <ul className='ShowCostsModalCostList'>
                    {getOrderCostsResult
                      .filter((item) => item.operation === "IN")
                      .map((cost) => (
                        <CostItemShipper key={cost.id} cost={cost} orderId={orderId} />
                      ))}
                  </ul>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </DOverlay>
    </Fragment>
  );
};

export default CostsModalShipper;
