import React, { Fragment, useState, useEffect } from "react";
import "./EditTrucksModal.scss";

import { useTranslation } from "react-i18next";
import { FiEdit2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import { updateTruck, resetUpdateTruck } from "./../../../../store/fleet/trucks/trucksSlice";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";
import DTruckBrandsSelect from "./../../../Form/DSelect/DTruckBrandsSelect/DTruckBrandsSelect";
import DTruckModelsSelect from "./../../../Form/DSelect/DTruckModelsSelect/DTruckModelsSelect";

const EditTrucksModal = ({ isOpen, isClose, truck }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateTruckLoading, updateTruckResult, updateTruckError, getTruckBrandsSelect } =
    useSelector((state) => state.trucks);

  const [manufacture, setManufacture] = useState(null);
  const [model, setModel] = useState(null);

  const [plateNumber, setPlateNumber] = useState(
    truck.registration_number ? truck.registration_number : ""
  );
  const [vinNumber, setVinNumber] = useState(truck.passport_number ? truck.passport_number : "");

  const submitHandler = () => {
    const data = {
      id: truck.id,
      model_id: model && model.value.id,
      registration_number: plateNumber,
      passport_number: vinNumber,
    };
    dispatch(updateTruck(data));
  };

  const brandId = truck && truck.model && truck.model.brand && truck.model.brand.id;

  const modelId = truck && truck.model && truck.model.id;

  const findManufacture =
    getTruckBrandsSelect && getTruckBrandsSelect.find((item) => item.value.id === brandId);

  const findModel =
    findManufacture && findManufacture.value.models.find((model) => model.value.id === modelId);

  useEffect(() => {
    if (getTruckBrandsSelect && findManufacture) {
      setManufacture(findManufacture);
      setModel(findModel);
    }
  }, [getTruckBrandsSelect, findManufacture, findModel]);

  useEffect(() => {
    if (updateTruckResult) {
      dispatch(resetUpdateTruck());
      isClose();
    }
  }, [dispatch, isClose, updateTruckResult]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Edit Truck")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiEdit2 />}
        status='success'
        onClick={() => submitHandler()}
        loading={updateTruckLoading}
      >
        <div id='EditTrucksModal'>
          <div className='EditTrucksModalGroup'>
            <DTruckBrandsSelect
              required
              value={manufacture}
              setValue={(e) => {
                setManufacture(e);
                setModel(null);
              }}
              error={updateTruckError?.errors?.model_id?.[0] ?? null}
            />
            <DTruckModelsSelect
              required
              value={model}
              setValue={setModel}
              options={manufacture ? manufacture.value.models : []}
              disabled={!manufacture}
              error={updateTruckError?.errors?.model_id?.[0] ?? null}
            />
            <DInputs
              id='truckRegistrationPlate'
              value={plateNumber}
              setValue={setPlateNumber}
              label={t("tms.modals.labels.Registration plate")}
              placeholder={t("tms.modals.placeholder.Enter registration plate")}
              error={updateTruckError?.errors?.registration_number?.[0] ?? null}
              required
            />
            <DInputs
              id='truckVinNumber'
              value={vinNumber}
              setValue={setVinNumber}
              label={t("tms.modals.labels.VIN number")}
              placeholder={t("tms.modals.placeholder.Enter VIN number")}
              error={updateTruckError?.errors?.passport_number?.[0] ?? null}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditTrucksModal;
