import React from "react";
import "./ShipperCalendarHeader.scss";

import moment from "moment";
import { useTranslation } from "react-i18next";

const ShipperCalendarHeader = ({ startDate }) => {
  const { t } = useTranslation();
  const next14DaysArray = Array(14)
    .fill(0)
    .map((_, i) => {
      return moment(startDate).add(i, "days").format("DD MMM");
    });

  return (
    <div id='ShipperCalendarHeader'>
      {next14DaysArray.map((day, i) => (
        <div
          key={i}
          className='shipperCalendarHeaderItem'
          style={day === moment().format("DD MMM") ? { color: "#2E5AAC" } : {}}
        >
          {day === moment().format("DD MMM") ? t("tms.titles.Today") : day}
        </div>
      ))}
    </div>
  );
};

export default ShipperCalendarHeader;
