import React, { useState } from "react";
import "./AirProductDetails.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IoWarningOutline } from "react-icons/io5";
import { MdInsertDriveFile } from "react-icons/md";

import Character from "../../../../UI/Character/Character";

const ProductItemShipper = ({ title, text, hsCode }) => {
  const { t } = useTranslation();
  return (
    <div className='item'>
      <div className='itemTitle'>{title}</div>
      <div className='itemText'>
        <Character title={text} max={30} />
        {hsCode && (
          <span className='hsCode'>
            ({t("shipper.forms.content.HS:")}
            {hsCode})
          </span>
        )}
      </div>
    </div>
  );
};

const AirProductDetails = ({ cargo, index }) => {
  const { t } = useTranslation();
  const [adrShowMore, setAdrShowMore] = useState(false);

  const productCategory = cargo?.type?.name || "";
  const totalWeight = cargo?.weight || 0;
  const adr = cargo?.adr > 0 ? "Yes" : "";
  const unNumber = cargo?.un_number || "";
  const adrClass = cargo?.adr_class || "";

  const adrDocuments = cargo?.attachments?.filter((item) => {
    return item.type.id === 51;
  });

  return (
    <div id='AirProductDetails'>
      {index && <div className='index'>{index}.</div>}
      <div className='mainContent'>
        <ProductItemShipper
          title={`${t("shipper.forms.content.Product category")}:`}
          text={productCategory}
          hsCode={cargo?.hs_code}
        />
        <ProductItemShipper
          title={`${t("shipper.forms.content.Total weight")}:`}
          text={`${totalWeight} ${t("shipper.units.KG")}`}
        />
        {adr && (
          <div className='adr'>
            <div className='title'>
              <div className='warningIcon'>
                <IoWarningOutline />
              </div>
              <div className='text'>{t("shipper.forms.content.ADR")}</div>
              <div
                className='icon'
                style={{
                  transform: adrShowMore && "rotate(180deg)",
                  transitionDuration: "0.3s",
                }}
                onClick={() => {
                  setAdrShowMore(!adrShowMore);
                }}
              >
                <svg
                  height='20'
                  width='20'
                  viewBox='0 0 20 20'
                  aria-hidden='true'
                  focusable='false'
                >
                  <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
                </svg>
              </div>
            </div>
            <div
              className='content'
              style={{
                display: adrShowMore ? "grid" : "none",
              }}
            >
              <ProductItemShipper
                title={`${t("shipper.forms.content.UN Number")}:`}
                text={unNumber || "-"}
              />
              <ProductItemShipper
                title={`${t("shipper.forms.content.ADR Class")}:`}
                text={adrClass || "-"}
              />
              {adrDocuments &&
                adrDocuments?.length > 0 &&
                adrDocuments?.map((item, index) => (
                  <Link key={index} to={item?.link} target='_blank'>
                    <div className='documentContainer'>
                      <div className='documentContent'>
                        <div className='documentIcon'>
                          <MdInsertDriveFile />
                        </div>
                        <div className='documentTitle'>{item?.name}</div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AirProductDetails;
