import React, { Fragment, useState, useEffect } from "react";
import "./EditEmailModal.scss";

import { useTranslation } from "react-i18next";
import { CiMail } from "react-icons/ci";
import { useSelector, useDispatch } from "react-redux";

import { verify, resetVerify } from "../../../../../store/user/userSlice";
import { sendOtp, resetSendOtp } from "../../../../../store/auth/authSlice";

import DModal from "../../../../Modal/DModal/DModal";
import DInputs from "../../../../Form/DInputs/DInputs";
import OTP from "../../../../../componentsLanding/Form/OTP/OTP";

const EditEmailModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { verifyLoading, verifyResult, verifyError } = useSelector((state) => state.user);
  const { sendOtpResult, sendOtpLoading, sendOtpError } = useSelector((state) => state.auth);

  const [email, setEmail] = useState(user?.email || "");
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");

  const handleFirstSubmit = () => {
    dispatch(
      sendOtp({
        type: "email",
        new_contact: email,
      })
    );
  };

  const handleSecondSubmit = () => {
    dispatch(
      verify({
        type: "email",
        code: otp,
        new_contact: email,
      })
    );
  };

  useEffect(() => {
    if (sendOtpResult) {
      setShowOtp(true);
      dispatch(resetSendOtp());
    }
  }, [dispatch, sendOtpResult]);

  useEffect(() => {
    if (verifyResult) {
      dispatch(resetVerify());
      setShowOtp(false);
      isClose();
    }
  }, [dispatch, isClose, verifyResult]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={() => {
        setShowOtp(false);
        isClose();
      }}
      title={
        showOtp ? t("tms.modals.titles.Enter verification code") : t("tms.modals.titles.Edit email")
      }
      status={"info"}
      submitText={showOtp ? t("tms.modals.buttons.Verify") : t("tms.modals.buttons.Send code")}
      onClick={() => (showOtp ? handleSecondSubmit() : handleFirstSubmit())}
      loading={verifyLoading || sendOtpLoading}
      icon={<CiMail />}
    >
      <div id='profileEditEmail'>
        <form className='formGroup'>
          {showOtp ? (
            <Fragment>
              <div className='otpTitle'>{t("tms.modals.others.Enter verification code")}</div>
              <OTP
                count={6}
                value={otp}
                setValue={setOtp}
                error={verifyError?.errors?.code?.[0] || verifyError?.error}
              />
            </Fragment>
          ) : (
            <DInputs
              id={"editProfileEmail"}
              type={"email"}
              label={t("tms.modals.labels.Email")}
              placeholder={t("tms.modals.placeholder.Enter email")}
              value={email}
              setValue={setEmail}
              required
              error={sendOtpError?.errors?.new_contact?.[0]}
            />
          )}
        </form>
      </div>
    </DModal>
  );
};

export default EditEmailModal;
