import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import trackerManagerService from "./trackerManagerService";

const initialState = {
  getTrackingLoading: true,
  getTrackingResponce: null,
  getTrackingError: null,

  getOrderTrackingLoading: true,
  getOrderTrackingResult: null,
  getOrderTrackingError: null,

  getContainerTrackingLoading: true,
  getContainerTrackingResult: null,
  getContainerTrackingError: null,
};

export const getTracking = createAsyncThunk(
  "trackerManager/getTracking",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trackerManagerService.getTracking(ISO, TOKEN, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrderTracking = createAsyncThunk(
  "trackerManager/getOrderTracking",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trackerManagerService.getOrderTracking(ISO, TOKEN, orderId);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getContainerTracking = createAsyncThunk(
  "trackerManager/getContainerTracking",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await trackerManagerService.getContainerTracking(
        ISO,
        TOKEN,
        data?.orderId,
        data?.containerId
      );
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const trackerManagerSlice = createSlice({
  name: "trackerManager",
  initialState,
  reducers: {
    resetGetTracking: (state) => {
      state.getTrackingLoading = true;
      state.getTrackingResponce = null;
      state.getTrackingError = null;
    },

    resetGetOrderTracking: (state) => {
      state.getOrderTrackingLoading = true;
      state.getOrderTrackingResult = null;
      state.getOrderTrackingError = null;
    },

    resetGetContainerTracking: (state) => {
      state.getContainerTrackingLoading = true;
      state.getContainerTrackingResult = null;
      state.getContainerTrackingError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTracking.pending, (state) => {
        state.getTrackingLoading = true;
        state.getTrackingResponce = null;
        state.getTrackingError = null;
      })
      .addCase(getTracking.fulfilled, (state, action) => {
        state.getTrackingLoading = false;
        state.getTrackingResponce = action.payload;
        state.getTrackingError = null;
      })
      .addCase(getTracking.rejected, (state, action) => {
        state.getTrackingLoading = false;
        state.getTrackingResponce = null;
        state.getTrackingError = action.payload;
      })

      .addCase(getOrderTracking.pending, (state) => {
        state.getOrderTrackingLoading = true;
        state.getOrderTrackingResult = null;
        state.getOrderTrackingError = null;
      })
      .addCase(getOrderTracking.fulfilled, (state, action) => {
        state.getOrderTrackingLoading = false;
        state.getOrderTrackingResult = action.payload;
        state.getOrderTrackingError = null;
      })
      .addCase(getOrderTracking.rejected, (state, action) => {
        state.getOrderTrackingLoading = false;
        state.getOrderTrackingResult = null;
        state.getOrderTrackingError = action.payload;
      })

      .addCase(getContainerTracking.pending, (state) => {
        state.getContainerTrackingLoading = true;
        state.getContainerTrackingResult = null;
        state.getContainerTrackingError = null;
      })
      .addCase(getContainerTracking.fulfilled, (state, action) => {
        state.getContainerTrackingLoading = false;
        state.getContainerTrackingResult = action.payload;
        state.getContainerTrackingError = null;
      })
      .addCase(getContainerTracking.rejected, (state, action) => {
        state.getContainerTrackingLoading = false;
        state.getContainerTrackingResult = null;
        state.getContainerTrackingError = action.payload;
      });
  },
});

export const { resetGetTracking, resetGetOrderTracking, resetGetContainerTracking } =
  trackerManagerSlice.actions;
export default trackerManagerSlice.reducer;
