import React, { useEffect } from "react";
import "./TrackingDistanceData.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getOrderTracking } from "../../../../store/trackerManager/trackerManagerSlice";
import { FaArrowRight } from "react-icons/fa6";

import OceanProgressBar from "./OceanProgressBar/OceanProgressBar";

const TrackingDistanceData = ({ order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getOrderTrackingResult } = useSelector((state) => state.trackerManager);

  const isSeaSubscribed = order?.transports?.find((i) => i?.subscribed);
  const showRoadAndAirProgress =
    (order?.type_group?.id === 1 || order?.type_group?.id === 3) &&
    order?.last_shipment_status_update;

  useEffect(() => {
    if (isSeaSubscribed) {
      dispatch(getOrderTracking(order?.id));
    }
  }, [dispatch, isSeaSubscribed, order?.id]);

  const progress = order?.last_shipment_status_update?.distance_covered_percent || 0;
  const updateDate = order?.last_shipment_status_update?.created_at || null;
  const estDelivery = order?.last_shipment_status_update?.est || null;
  const etaDelivery = isSeaSubscribed ? getOrderTrackingResult?.estimated_arrival : null;
  const etdDelivery = isSeaSubscribed ? getOrderTrackingResult?.estimated_departure : null;

  const startLocationType =
    getOrderTrackingResult?.locations?.[0]?.type === "LOCALITY"
      ? "Facility"
      : getOrderTrackingResult?.locations?.[0]?.type === "PORT"
      ? "Port"
      : "Airport";
  const finalLocationType =
    getOrderTrackingResult?.locations?.[getOrderTrackingResult?.locations?.length - 1]?.type ===
    "LOCALITY"
      ? "Facility"
      : getOrderTrackingResult?.locations?.[getOrderTrackingResult?.locations?.length - 1]?.type ===
        "PORT"
      ? "Port"
      : "Airport";

  return (
    <div id='ShipperTrackingSectionDistance'>
      <div className='content'>
        {order?.type_group?.id === 2 && isSeaSubscribed ? (
          <h3>
            {startLocationType} <FaArrowRight /> {finalLocationType}
          </h3>
        ) : (
          showRoadAndAirProgress && <h3>{t("shipper.forms.content.Distance covered")}</h3>
        )}
        {/* ) : (
              <h3>{t("shipper.forms.content.Air comming...")}</h3>
            )} */}
        <div>
          {estDelivery && (
            <h4>
              {t("shipper.forms.content.Estimated delivery")}: <span>{estDelivery || "-"}</span>
            </h4>
          )}
          {etaDelivery && ( // Translation needed
            <h4>
              {t("shipper.forms.content.Estimate time of arrival")}:{" "}
              <span>{getOrderTrackingResult?.estimated_arrival || "-"}</span>
            </h4>
          )}
          {etdDelivery && ( // Translation needed
            <h4>
              {t("shipper.forms.content.Estimate time of departure")}:{" "}
              <span>{getOrderTrackingResult?.estimated_departure || "-"}</span>
            </h4>
          )}
          {updateDate && order?.type_group?.id !== 2 && (
            <h5>
              {t("shipper.forms.content.Update")}: <span>{updateDate || "-"}</span>
            </h5>
          )}
        </div>
      </div>

      {order?.type_group?.id === 2 && isSeaSubscribed ? (
        <OceanProgressBar order={order} />
      ) : (
        showRoadAndAirProgress && (
          <div className='progressContainer'>
            <div
              className='color'
              style={{
                width: parseFloat(progress) > 100 ? "100%" : `${parseFloat(progress)}%`,
              }}
            >
              <div className='dot'></div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default TrackingDistanceData;
