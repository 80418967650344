import React, { useState, Fragment, useEffect } from "react";
import "./MobileMenu.scss";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IoMenu, IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";

import Footer from "../Footer/Footer";
import Nav from "../Nav/Nav";
import Logo from "../../../../componentsAdditional/SVG/Cargon/Logo";
import NotificationsButton from "../NotificationsButton/NotificationsButton";

const MobileHeader = ({ open }) => {
  const [openMobile, setOpenMobileMenu] = useState(false);
  const { pathname } = useLocation();
  const { userType } = useSelector((state) => state.auth);

  useEffect(() => {
    if (openMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [openMobile]);

  useEffect(() => {
    setOpenMobileMenu(false);
  }, [pathname, setOpenMobileMenu]);

  return (
    <Fragment>
      <header id='MobileHeader'>
        <Link to='/'>
          <Logo />
        </Link>
        <div className='MobileHeaderButtons'>
          {userType && (userType === 4 || userType === 5) && (
            <NotificationsButton />
          )}
          <div
            className={openMobile ? "open icon" : "icon"}
            onClick={() => {
              setOpenMobileMenu((state) => !state);
            }}
          >
            {openMobile ? <IoClose /> : <IoMenu />}
          </div>
        </div>
      </header>
      <div id='MobileMenu' className={openMobile ? "open" : ""}>
        <Nav open={openMobile} />
        <Footer open={openMobile} />
      </div>
    </Fragment>
  );
};

export default MobileHeader;
