import React from "react";
import "./SignUpCompanySuccess.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import SignUpCompanySuccessForm from "./../../../../componentsLanding/Auth&Password/SignUpForms/SignUpCompanySuccessForm/SignUpCompanySuccessForm";

const SignUpCarrierSuccess = () => {
  const { t } = useTranslation();

  return (
    <Wrapper
      documentTitle={t("landing.documentTitles.Sign Up carrier company")}
    >
      <section id='SignUpCarrierSuccess'>
        <AuthCover url='/assets/images/static/cover24.jpg' />
        <SignUpCompanySuccessForm />
      </section>
    </Wrapper>
  );
};

export default SignUpCarrierSuccess;
