import React, { Fragment } from "react";
import "./Profile.scss";

import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Character from "../../../UI/Character/Character";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { user, userType } = useSelector((state) => state.auth);
  const { openSidebar } = useSelector((state) => state.userUI);

  const onProfilePage =
    pathname?.includes("/dashboard/tms/profile") ||
    pathname?.includes("/dashboard/shipper/profile") ||
    pathname?.includes("/dashboard/carrier/profile");

  let linkAddress;
  let name;

  switch (userType) {
    case 4:
      linkAddress = "/dashboard/tms/profile";
      name = user.name ? user.name : "";
      break;
    case 5:
      linkAddress = "/dashboard/shipper/profile";
      name = user.name ? user.name : "";
      break;
    case 2:
      linkAddress = "/dashboard/carrier/profile";
      name = user.name ? user.name : "";
      break;
    default:
      linkAddress = "/";
      return;
  }

  return (
    <Fragment>
      {user && (
        <div
          id='ProfileSidebar'
          className={`${openSidebar ? "" : "min"} ${onProfilePage && "active"}`}
        >
          <NavLink to={linkAddress}>
            <div
              className={openSidebar ? "cover" : "cover min"}
              style={{
                backgroundImage: `url(${user.avatar ? user.avatar : null} )`,
              }}
            ></div>
            <div className={openSidebar ? "text" : "text min"}>
              <h1>
                <Character title={name} max={30} />
              </h1>
              <h3>
                <Character title={t("tms.desc.Carrier company")} max={30} />
              </h3>
            </div>
          </NavLink>
        </div>
      )}
    </Fragment>
  );
};

export default Profile;
