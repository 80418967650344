import React from "react";
import "./TruckGpsTracking.scss";

import { useTranslation } from "react-i18next";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";

import DTextButton from "../../../../Form/DButtons/DTextButton/DTextButton";

const TruckGpsTracking = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/dashboard/tms/tracking");
  };

  return (
    <div id='TruckGpsTracking'>
      <div className='TruckGpsTrackingStatus'>
        <div className='TruckGpsTrackingStatusOnline'>
          <GoDotFill />
          <div>{t("tms.desc.Online")}</div>
        </div>
      </div>
      <div className='TruckGpsTrackingContent'>
        <div className='TrackingCompany'>
          {t("tms.desc.The truck is connected to GPS")}
          {/* <span className='bold'>GPS Georgia</span> */}
        </div>
        <DTextButton
          title={t("tms.forms.buttons.View on map")}
          primary
          onClick={() => handleClick()}
        />
      </div>
    </div>
  );
};

export default TruckGpsTracking;
