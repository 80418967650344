import React from "react";
import "./DBackButton.scss";

import { useTranslation } from "react-i18next";

import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const DBackButton = ({ title, alt }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const submitHandler = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(alt ? alt : "/");
    }
  };

  return (
    <div id='DBackButton' onClick={() => submitHandler()}>
      <div className='icon'>
        <FiArrowLeft />
      </div>
      <p>{title ? title : `${t("tms.forms.buttons.Back")}`}</p>
    </div>
  );
};

export default DBackButton;
