import React from "react";
import "./PlanningAssistantTableHeader.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import moment from "moment";
import "moment/locale/ka";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uz";

const PlanningAssistantTableHeader = ({ daysQty = 14 }) => {
  const { t } = useTranslation();
  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  return (
    <div id='PlanningAssistantTableHeader'>
      <div className='title'>{t("tms.titles.Trucks")}</div>
      <div className='days'>
        {Array(daysQty)
          .fill(0)
          .map((_, i) => (
            <div key={i} className='day' style={{ color: i === 0 ? "#2E5AAC" : "#848a8d" }}>
              {i === 0 ? t("tms.titles.Today") : moment().add(i, "days").format("DD MMM")}
            </div>
          ))}
      </div>
    </div>
  );
};

export default PlanningAssistantTableHeader;
