import React from "react";
import "./TableHeader.scss";

const TableHeader = ({ children }) => {
  return (
    <thead id='TableHeader'>
      <tr>{children}</tr>
    </thead>
  );
};

export default TableHeader;
