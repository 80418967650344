import React, { Fragment } from "react";

import { useTranslation } from "react-i18next";

import RowField from "../../../../../Form/TableFields/RowField/RowField";
import NameField from "../../../../../Form/TableFields/NameField/NameField";
import PriceField from "../../../../../Form/TableFields/PriceField/PriceField";
import RouteField from "./../../../../../Form/TableFields/RouteField/RouteField";
import TrailerField from "./../../../../../Form/TableFields/TrailerField/TrailerField";
import TextField from "../../../../../Form/TableFields/TextField/TextField";
import DateField from "../../../../../Form/TableFields/DateField/DateField";
import OrderStatusField from "../../../../../Form/TableFields/OrderStatusField/OrderStatusField";
import DriverPhoneField from "../../../../../Form/TableFields/DriverPhoneField/DriverPhoneField";
import PartnerField from "../../../../../Form/TableFields/PartnerField/PartnerField";

const TruckOrdersTableItem = ({ order }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <RowField
        style={{
          borderLeft: `4px solid ${order.color ? order.color : "transparent"}`,
        }}
      >
        <NameField label={order.tracking} to={`/dashboard/tms/orders/${order.id}`} />
        <OrderStatusField status={order.statusTms ?? null} />
        <TextField max={30} label={order?.client?.name ?? "Cargon"} />
        <RouteField order={order} />
        {!order?.transports?.find((i) => i?.truck) && !order.partner ? (
          <DriverPhoneField driver={null} />
        ) : order?.partner ? (
          <PartnerField label={order?.partner?.name || t("tms.tables.noInfo.no partner")} />
        ) : (
          <DriverPhoneField
            driver={{
              name: order?.transports?.[0]?.driver || "",
              phone: order?.transports?.[0]?.phone || "",
            }}
          />
        )}
        <TrailerField
          icon={order?.trailer_type?.icon}
          trailerType={order?.cargos[0]?.trailerType?.name ?? "-"}
          volume={order?.cargos[0]?.trailersize?.label ?? "-"}
        />
        <DateField date={order.cargos[0]?.pickup_date ?? null} />
        <PriceField
          number={order?.total_revenue ?? 0}
          symbol={order?.revenue_currency.symbol ?? "-"}
        />
      </RowField>
    </Fragment>
  );
};

export default TruckOrdersTableItem;
