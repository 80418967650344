import _request from "../_request";

const getPreference = async (ISO, token) => {
  const config = {
    url: "/preference",
    ISO,
    token,
  };
  return _request(config);
};

const storePreference = async (ISO, token, data) => {
  const config = {
    url: "/preference",
    method: "post",
    ISO,
    token,
  };
  return _request(config, data);
};

const updatePreference = async (ISO, token, data) => {
  const config = {
    url: "/preference",
    method: "put",
    ISO,
    token,
  };
  return _request(config, data);
};

const preferencesService = {
  getPreference,
  storePreference,
  updatePreference,
};

export default preferencesService;
