import React from "react";
import "./LinkBackground.scss";

import { Link } from "react-router-dom";

const LinkBackground = ({ title, to }) => {
  return (
    <div id='LinkBackground'>
      <Link to={to}>{title}</Link>
    </div>
  );
};

export default LinkBackground;
