import React from "react";
import "./ViewSwitcher.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { setShipperAcceptedOrderView } from "../../../../../store/orders/shipperAccepted/shipperAcceptedSlice";

const ViewSwitcher = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { shipperAcceptedOrderView } = useSelector(
    (state) => state.shipperAccepted
  );

  return (
    <div id='ViewSwitcherShipperOrders'>
      <div
        className={`item left ${shipperAcceptedOrderView && "active"}`}
        onClick={() => {
          dispatch(setShipperAcceptedOrderView(true));
        }}
      >
        <p>{t("shipper.menu.List view")}</p>
      </div>
      <div
        className={`item right ${!shipperAcceptedOrderView && "active"}`}
        onClick={() => {
          dispatch(setShipperAcceptedOrderView(false));
        }}
      >
        <p>{t("shipper.menu.Side view")}</p>
      </div>
    </div>
  );
};

export default ViewSwitcher;
