import React from "react";
import "./ChargeForm.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

import DButton from "../../../../../Form/DButtons/DButton/DButton";
import ChargeFormItem from "./ChargeFormItem/ChargeFormItem";

const ChargeForm = ({ charges, setCharges }) => {
  const { t } = useTranslation();

  const handleAdd = () => {
    const abc = [
      ...charges,
      {
        name: t("tms.invoice.Charge name"),
        total: "0",
        vat: "0",
        amount: "0",
      },
    ];
    setCharges(abc);
  };

  return (
    <div id='ChargeFormInvoiceModal'>
      <div className='chargeHeader'>
        <div>
          <p>{t("tms.invoice.Charge name")}</p>
        </div>
        <div>
          <p>{t("tms.invoice.Amount")}</p>
        </div>
        <div>
          <p>{t("tms.invoice.VAT")}(%)</p>
        </div>
        <div>
          <p>{t("tms.invoice.Total")}</p>
        </div>
      </div>
      <div className='chargeForm'>
        {charges && charges.length === 0 && <h5>{t("tms.invoice.No charge")}</h5>}
        {charges &&
          charges.map((item, index) => (
            <ChargeFormItem
              key={index}
              index={index}
              item={item}
              setCharges={setCharges}
              charges={charges}
            />
          ))}
      </div>
      <div className='chargeFooter'>
        <DButton rightIcon={<FiPlus />} title={t("tms.invoice.Add charge")} onClick={handleAdd} />
      </div>
    </div>
  );
};

export default ChargeForm;
