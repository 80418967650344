import React, { useState, useEffect } from "react";
import "./EditOrder.scss";

import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  updateOrder,
  resetUpdateOrder,
  getOrder,
  resetGetOrder,
} from "../../../../../store/orders/ordersSlice";
import { useOrderPaymentSelectSubOptions } from "./../../../../../helper/useOrderPaymentSelectSubOptions";

import DButton from "./../../../../../componentsDashboard/Form/DButtons/DButton/DButton";
import DBackButton from "./../../../../../componentsDashboard/Form/DButtons/DBackButton/DBackButton";
import Wrapper from "./../../../../../componentsAdditional/Wrapper";
import LoadingPage from "./../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import AditionalInformation from "./../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/AditionalInformation/AditionalInformation";
import ProductNewOrder from "./../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/ProductNewOrder/ProductNewOrder";
import RouteNewOrder from "./../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/RouteNewOrder/RouteNewOrder";
import TrailersNewOrder from "./../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/TrailersNewOrder/TrailersNewOrder";
import PaymentNewOrder from "./../../../../../componentsDashboard/Orders/CreateOrder/CreateOrderTMC/PaymentNewOrder/PaymentNewOrder";
import PageNotFound from "./../../../../../componentsBids/PageNotFound/PageNotFound";

const EditOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [paymentSubOptions] = useState(useOrderPaymentSelectSubOptions());

  const {
    getOrderLoading,
    getOrderResult,
    getOrderError,
    updateOrderLoading,
    updateOrderResult,
    updateOrderError,
  } = useSelector((state) => state.orders);

  const { user } = useSelector((state) => state.auth);

  const [customer, setCustomer] = useState(null);
  const [productType, setProductType] = useState(null);
  const [packingType, setPackingType] = useState(null);
  const [adr, setAdr] = useState(false);
  const [adrUnNumber, setAdrUnNumber] = useState("");
  const [adrClass, setAdrClass] = useState("");
  ///////
  const [locations, setLocations] = useState([
    { direction: "A", inputted_address: "", error: null },
    { direction: "B", inputted_address: "", error: null },
  ]);
  const [pickUpDate, setPickUpDate] = useState(null);
  const [pickUpTime, setPickUpTime] = useState(null);
  const [dropOffShow, setDropOffShow] = useState(false);
  const [dropOffDate, setDoprOffDate] = useState(null);
  const [dropOffTime, setDropOffTime] = useState(null);
  //////
  const [group, setGroup] = useState([
    {
      trailerType: null,
      trailerTypeError: null,
      trailerSize: null,
      trailerSizeError: null,
      temperatureRange: "",
      temperatureRangeError: null,
      weight: "",
      weightError: null,
      price: "",
      priceError: null,
      currency: null,
      currencyError: null,
    },
  ]);
  //////
  const [payMethod, setPayMethod] = useState(null);
  const [payMethodSub, setPayMethodSub] = useState(null);
  const [payMethodAmount, setPayMethodAmount] = useState("");
  const [payMethodAmountCurrency, setPayMethodAmountCurrency] = useState(null);
  const [payConsignationPeriod, setPayConsignationPeriod] = useState("");
  ////
  const [comment, setComment] = useState("");

  // get order
  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => {
      dispatch(resetGetOrder());
    };
  }, [dispatch, orderId]);

  // catch locations error
  useEffect(() => {
    if (updateOrderError?.errors) {
      const updatedLocations = locations.map((location, index) => ({
        ...location,
        error: updateOrderError.errors[`cargos.0.locations.${index}.inputted_address`]?.[0] ?? null,
      }));
      setLocations(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrderError]);

  // catch group errors
  useEffect(() => {
    if (updateOrderError?.errors) {
      const updatedGroup = group.map((item, index) => ({
        ...item,
        trailerTypeError: updateOrderError?.errors?.[`cargos.0.trailer_type_id`]?.[0] || null,
        trailerSizeError: updateOrderError?.errors?.[`cargos.0.trailer_size_id`]?.[0] || null,
        temperatureRangeError: updateOrderError?.errors?.[`cargos.0.temperature`]?.[0] || null,
        weightError: updateOrderError?.errors?.[`cargos.0.weight`]?.[0] || null,
        priceError: updateOrderError?.errors?.[`revenue`]?.[0] || null,
        currencyError: updateOrderError?.errors?.[`revenue_currency_id`]?.[0] || null,
      }));
      setGroup(updatedGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrderError]);

  useEffect(() => {
    if (getOrderResult) {
      setCustomer(
        getOrderResult?.client
          ? { label: getOrderResult?.client?.name, value: getOrderResult?.client }
          : null
      );
      setProductType(
        getOrderResult?.cargos?.[0]?.type
          ? {
              label: getOrderResult?.cargos?.[0]?.type?.name,
              value: getOrderResult?.cargos?.[0]?.type,
            }
          : null
      );
      setPackingType(
        getOrderResult?.cargos?.[0]?.packing_type
          ? {
              label: getOrderResult?.cargos?.[0]?.packing_type?.name,
              value: getOrderResult?.cargos?.[0]?.packing_type,
            }
          : null
      );
      setAdr(getOrderResult?.cargos?.[0]?.adr ? true : false);
      setAdrUnNumber(getOrderResult?.cargos?.[0]?.un_number || "");
      setAdrClass(getOrderResult?.cargos?.[0]?.adr_class || "");
      setLocations(
        getOrderResult?.cargos?.[0]?.locations
          ? getOrderResult?.cargos?.[0]?.locations.map((i) => ({
              id: i.id,
              direction: i.direction,
              inputted_address: i.inputted_address,
              error: null,
            }))
          : [
              { direction: "A", inputted_address: "", error: null },
              { direction: "B", inputted_address: "", error: null },
            ]
      );
      setPickUpDate(
        getOrderResult?.cargos?.[0]?.pickup_date
          ? new Date(getOrderResult?.cargos?.[0]?.pickup_date)
          : null
      );
      setPickUpTime(
        getOrderResult?.cargos[0]?.pickup_time_start && getOrderResult?.cargos[0]?.pickup_time_end
          ? [
              getOrderResult?.cargos[0]?.pickup_time_start,
              getOrderResult?.cargos[0]?.pickup_time_end,
            ]
          : null
      );
      setDropOffShow(getOrderResult?.cargos?.[0]?.dropoff_date ? true : false);
      setDoprOffDate(
        getOrderResult?.cargos?.[0]?.dropoff_date
          ? new Date(getOrderResult?.cargos?.[0]?.dropoff_date)
          : null
      );
      setDropOffTime(
        getOrderResult?.cargos[0]?.dropoff_time_start && getOrderResult?.cargos[0]?.dropoff_time_end
          ? [
              getOrderResult?.cargos[0]?.dropoff_time_start,
              getOrderResult?.cargos[0]?.dropoff_time_end,
            ]
          : null
      );
      setPayMethod(
        getOrderResult?.client_payment?.type
          ? {
              label: getOrderResult?.client_payment?.type?.name,
              value: getOrderResult?.client_payment?.type,
            }
          : null
      );
      if (paymentSubOptions) {
        const findPaymentSubType = paymentSubOptions.find(
          (i) => i.value.id === getOrderResult?.client_payment?.option_type
        );
        setPayMethodSub(findPaymentSubType || null);
      }
      setPayMethodAmount(getOrderResult?.client_payment?.amount || "");
      setPayMethodAmountCurrency(
        getOrderResult?.client_payment?.currency
          ? {
              label: getOrderResult?.client_payment?.currency?.code,
              value: getOrderResult?.client_payment?.currency,
            }
          : null
      );
      setPayConsignationPeriod(getOrderResult?.client_payment?.days || "");
      setComment(getOrderResult?.about || "");
      setGroup([
        {
          trailerType: getOrderResult?.cargos?.[0]?.trailerType
            ? {
                label: getOrderResult?.cargos?.[0]?.trailerType?.name,
                value: getOrderResult?.cargos?.[0]?.trailerType,
              }
            : null,
          trailerTypeError: null,
          trailerSize: getOrderResult?.cargos?.[0]?.trailersize
            ? {
                label: getOrderResult?.cargos?.[0]?.trailersize?.label,
                value: getOrderResult?.cargos?.[0]?.trailersize,
              }
            : null,
          trailerSizeError: null,
          temperatureRange: getOrderResult?.cargos?.[0]?.temperature || "",
          temperatureRangeError: null,
          weight: getOrderResult?.cargos?.[0]?.weight || "",
          weightError: null,
          price: getOrderResult?.revenue || "",
          priceError: null,
          currency: getOrderResult?.revenue_currency
            ? {
                label: getOrderResult?.revenue_currency?.code,
                value: getOrderResult?.revenue_currency,
              }
            : null,
          currencyError: null,
        },
      ]);
    }
  }, [getOrderResult, paymentSubOptions]);

  // reset
  useEffect(() => {
    if (updateOrderResult) {
      dispatch(resetUpdateOrder());
      navigate(`/dashboard/tms/orders/${orderId}`);
    }
  }, [dispatch, navigate, updateOrderResult, orderId]);

  // reset all time
  useEffect(() => {
    return () => {
      dispatch(resetUpdateOrder());
    };
  }, [dispatch]);

  const submitHandler = () => {
    const data = {
      orderId: orderId,
      branch_id: user?.branch_id || null,
      platform_id: null,
      priority_id: 1,
      transfer_type_id: 1,
      type_id: 2,
      sale_manager_id: user?.id || null,
      logistic_manager_id: null,
      client_id: customer?.value?.id || null,
      revenue: group[0]?.price || null,
      revenue_currency_id: group[0]?.currency?.value?.id || null,
      client_payment: {
        type_id: payMethod?.value?.id || null,
        amount: payMethodAmount || null,
        currency_id: payMethodAmountCurrency?.value?.id || null,
        option_type: payMethodSub?.value?.id || null,
        days: payConsignationPeriod || null,
      },
      about: comment || null,
      comment: null,
      deadline_date: null,
      services: null,
      cargos: [
        {
          id: getOrderResult?.cargos?.[0]?.id || null,
          ///
          trailer_type_id: group[0]?.trailerType?.value?.id || null,
          trailer_size_id: group[0]?.trailerSize?.value?.id || null,
          temperature: group[0]?.temperatureRange || null,
          type_id: productType?.value?.id || null,
          packing_type_id: packingType?.value?.id || null,
          count: 1,
          weight: group[0]?.weight || null,
          volume: null,
          adr: adr || false,
          adr_class: adrClass || null,
          un_number: adrUnNumber || null,
          hs_code: null,
          oversized: false,
          price: null,
          price_currency_id: null,
          cargo_ready_date: null,

          pickup_date: pickUpDate ? moment(pickUpDate).format("YYYY-MM-DD") : null,
          pickup_time_start: pickUpTime ? pickUpTime[0] : null,
          pickup_time_end: pickUpTime ? pickUpTime[1] : null,
          dropoff_date: dropOffDate ? moment(dropOffDate).format("YYYY-MM-DD") : null,
          dropoff_time_start: dropOffTime ? dropOffTime[0] : null,
          dropoff_time_end: dropOffTime ? dropOffTime[1] : null,

          locations: locations.map((loc) => ({
            id: loc.id,
            direction: loc?.direction || null,
            type: "LOCALITY",
            inputted_address: loc?.inputted_address || null,
          })),
        },
      ],
    };
    dispatch(updateOrder(data));
  };

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Edit order")}>
      {getOrderLoading && <LoadingPage />}
      {getOrderError && <PageNotFound />}
      {getOrderResult && (
        <section id='editNewOrderFromCompany'>
          <div className='header'>
            <DBackButton alt='/dashboard/tms/orders?page=1' />
          </div>

          <form>
            <ProductNewOrder
              customer={customer}
              setCustomer={setCustomer}
              customerError={updateOrderError?.errors?.["client_id"]?.[0] ?? null}
              //////
              productType={productType}
              setProductType={setProductType}
              productTypeError={updateOrderError?.errors?.["cargos.0.type_id"]?.[0] ?? null}
              //////
              packingType={packingType}
              setPackingType={setPackingType}
              packingTypeError={updateOrderError?.errors?.["cargos.0.packing_type_id"]?.[0] ?? null}
              /////
              adr={adr}
              setAdr={setAdr}
              adrError={updateOrderError?.errors?.["cargos.0.adr"]?.[0] ?? null}
              ////
              adrUnNumber={adrUnNumber}
              setAdrUnNumber={setAdrUnNumber}
              adrUnNumberError={updateOrderError?.errors?.["cargos.0.un_number"]?.[0] ?? null}
              ////
              adrClass={adrClass}
              setAdrClass={setAdrClass}
              adrClassError={updateOrderError?.errors?.["cargos.0.adr_class"]?.[0] ?? null}
              ////
              hideCustomerSelect={customer === null}
            />
            <RouteNewOrder
              locations={locations}
              setLocations={setLocations}
              /////
              pickUpDate={pickUpDate}
              setPickUpDate={setPickUpDate}
              pickUpDateError={updateOrderError?.errors?.["cargos.0.pickup_date"]?.[0] ?? null}
              /////
              pickUpTime={pickUpTime}
              setPickUpTime={setPickUpTime}
              pickUpTimeError={
                (updateOrderError?.errors?.["cargos.0.pickup_time_start"]?.[0] ?? null) ||
                (updateOrderError?.errors?.["cargos.0.pickup_time_end"]?.[0] ?? null)
              }
              /////
              dropOffShow={dropOffShow}
              setDropOffShow={setDropOffShow}
              dropOffDate={dropOffDate}
              setDropOffDate={setDoprOffDate}
              dropOffDateError={updateOrderError?.errors?.["cargos.0.dropoff_date"]?.[0] ?? null}
              //////
              dropOffTime={dropOffTime}
              setDropOffTime={setDropOffTime}
              dropOffTimeError={
                (updateOrderError?.errors?.["cargos.0.dropoff_time_start"]?.[0] ?? null) ||
                (updateOrderError?.errors?.["cargos.0.dropoff_time_end"]?.[0] ?? null)
              }
            />
            <TrailersNewOrder edit group={group} setGroup={setGroup} />
            <PaymentNewOrder
              payMethod={payMethod}
              setPayMethod={setPayMethod}
              payMethodError={updateOrderError?.errors?.["client_payment.type_id"]?.[0] ?? null}
              ////
              payMethodSub={payMethodSub}
              setPayMethodSub={setPayMethodSub}
              payMethodSubError={
                updateOrderError?.errors?.["client_payment.option_type"]?.[0] ?? null
              }
              /////
              payMethodAmount={payMethodAmount}
              setPayMethodAmount={setPayMethodAmount}
              payMethodAmountError={
                updateOrderError?.errors?.["client_payment.amount"]?.[0] ?? null
              }
              /////
              payMethodAmountCurrency={payMethodAmountCurrency}
              setPayMethodAmountCurrency={setPayMethodAmountCurrency}
              payMethodAmountCurrencyError={
                updateOrderError?.errors?.["client_payment.currency_id"]?.[0] ?? null
              }
              /////
              payConsignationPeriod={payConsignationPeriod}
              setPayConsignationPeriod={setPayConsignationPeriod}
              payConsignationPeriodError={
                updateOrderError?.errors?.["client_payment.days"]?.[0] ?? null
              }
            />
            <AditionalInformation
              comment={comment}
              setComment={setComment}
              commentError={updateOrderError?.errors?.["about"]?.[0] ?? null}
            />
            <div className='submitContainer'>
              <DButton
                loading={updateOrderLoading}
                title={t("tms.forms.buttons.Edit order")}
                onClick={() => submitHandler()}
              />
            </div>
          </form>
        </section>
      )}
    </Wrapper>
  );
};

export default EditOrder;
