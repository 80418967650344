import React from "react";
import "./OTP.scss";

import OtpInput from "react-otp-input";

import ErrorText from "../ErrorText/ErrorText";

const OTP = ({ value, setValue, count, error }) => {
  return (
    <div id='OTPContainer'>
      <div className={error ? "error optWrapper" : "optWrapper"}>
        <OtpInput
          value={value}
          onChange={setValue}
          numInputs={count ? count : 4}
          renderInput={(props) => <input {...props} />}
          inputType='number'
        />
      </div>
      <ErrorText error={error} />
    </div>
  );
};

export default OTP;
