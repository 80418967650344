import React from "react";
import "./WebPlatformCarrierItem.scss";

import { useTranslation } from "react-i18next";

import Button from "./../../../Form/Button/Button";

const WebPlatformCarrierItem = ({
  mainTitle,
  minTitle,
  desc,
  withBackground,
  cover,
  scrollItem,
}) => {
  const { t } = useTranslation();
  const handleClick = () => {
    const element = document.getElementById(scrollItem);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const offset = -80;
      const scrollTop = window.scrollY + elementRect.top + offset;

      window.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <div
      className={
        withBackground
          ? "WebPlatformCarrierItem withBackground"
          : "WebPlatformCarrierItem"
      }
    >
      <h1>{mainTitle}</h1>
      <div
        className='cover'
        style={{
          backgroundImage: `url(${cover})`,
        }}
      ></div>
      <div className='text'>
        <h3>{minTitle}</h3>
        <p>{desc}</p>
      </div>
      <Button
        title={t("landing.form.button.Learn more")}
        onClick={handleClick}
      />
    </div>
  );
};

export default WebPlatformCarrierItem;
