import _request from "./../_request";

const getTrailerTypes = async (ISO, TOKEN, orderTypeId) => {
  let url = `/trailer-types/${orderTypeId ? orderTypeId : ""}`;
  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const trailerTypeService = {
  getTrailerTypes,
};

export default trailerTypeService;
