import React, { Fragment, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { MdLink } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import {
  assignCarrier,
  resetAssignCarrier,
} from "./../../../../../../../store/orders/manageOrder/manageOrderSlice";

import RowField from "./../../../../../../Form/TableFields/RowField/RowField";
import TruckField from "./../../../../../../Form/TableFields/TruckField/TruckField";
import TrailerField from "./../../../../../../Form/TableFields/TrailerField/TrailerField";
import TextField from "./../../../../../../Form/TableFields/TextField/TextField";
import DriverPhoneField from "./../../../../../../Form/TableFields/DriverPhoneField/DriverPhoneField";
import CenterField from "./../../../../../../Form/TableFields/CenterField/CenterField";
import DModal from "./../../../../../../Modal/DModal/DModal";
import AssignCarrierButton from "./../../../Components/AssignCarrierButton/AssignCarrierButton";

const OwnFleetTableItem = ({ truck, order, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { assignCarrierLoading, assignCarrierResult } = useSelector((state) => state.manageOrder);

  const [opsenAssign, setOpsenAssign] = useState(false);

  const submitHanlder = () => {
    const data = {
      orderId: order.id,

      active_truck_id: truck.id,
    };
    dispatch(assignCarrier(data));
  };

  useEffect(() => {
    if (assignCarrierResult) {
      dispatch(resetAssignCarrier());
      // setOpsenAssign(false);
    }
  }, [dispatch, assignCarrierResult]);

  return (
    <Fragment>
      <RowField>
        <TruckField truck={truck ? truck : null} />
        <TrailerField
          icon={truck.trailer && truck.trailer.type.icon2}
          trailerType={truck.trailer ? truck.trailer.type.name : "-"}
          volume={
            truck.trailer && truck.trailer.registration_number
              ? truck.trailer.registration_number
              : null
          }
        />
        <TextField
          label={
            truck.trailer && truck.trailer.volume_capacity
              ? `${truck.trailer.volume_capacity} m³`
              : "0 m³"
          }
        />
        <DriverPhoneField driver={truck.driver ? truck.driver : null} />

        <CenterField>
          <AssignCarrierButton
            title={t("tms.tables.buttons.Assign carrier")}
            onClick={() => setOpsenAssign(true)}
          />
        </CenterField>
      </RowField>

      <DModal
        title={t("tms.modals.titles.Assign own vehicle")}
        submitText={t("tms.modals.buttons.Save")}
        isOpen={opsenAssign}
        isClose={() => setOpsenAssign(false)}
        onClick={() => submitHanlder()}
        loading={assignCarrierLoading}
        icon={<MdLink />}
      >
        <p>{t("tms.modals.desc.Do you want to attach this vehicle to the order?")}</p>
      </DModal>
    </Fragment>
  );
};

export default OwnFleetTableItem;
