import React, { useState } from "react";
import "./BidsLang.scss";

import { useSelector, useDispatch } from "react-redux";

import { setLang } from "../../store/additional/lang/langSlice";

import DSelect from "../../componentsDashboard/Form/DSelect/DSelect";

const BidsLang = () => {
  const dispatch = useDispatch();
  const { getLanguagesSelect, ISO } = useSelector((state) => state.lang);

  const [loading, setLoading] = useState(false);

  const currentValueLang =
    getLanguagesSelect && getLanguagesSelect.find((item) => item.value.key === ISO);

  return (
    <div id='BidsLang'>
      <DSelect
        id='BidsLangSelect'
        value={currentValueLang}
        setValue={(lang) => {
          setLoading(true);
          dispatch(setLang(lang.value.key));
        }}
        placeholder=''
        loading={loading}
        options={getLanguagesSelect ? getLanguagesSelect : []}
      />
    </div>
  );
};

export default BidsLang;
