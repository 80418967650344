import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import rulesService from "./rulesService";

const initialState = {
  // get FAQ
  getFAQLoading: false,
  getFAQResult: null,
  getFAQError: null,

  // get getInsurance
  getInsuranceLoading: false,
  getInsuranceResult: null,
  getInsuranceError: null,

  // get getPrivacy
  getPrivacyLoading: false,
  getPrivacyResult: null,
  getPrivacyError: null,

  // get getTerms
  getTermsLoading: false,
  getTermsResult: null,
  getTermsError: null,
};

// get FAQ
export const getFAQ = createAsyncThunk("rules/getFAQ", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await rulesService.getFAQ(ISO);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// get getInsurance
export const getInsurance = createAsyncThunk("rules/getInsurance", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await rulesService.getInsurance(ISO);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// get getPrivacy
export const getPrivacy = createAsyncThunk("rules/getPrivacy", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await rulesService.getPrivacy(ISO);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// get getTerms
export const getTerms = createAsyncThunk("rules/getTerms", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await rulesService.getTerms(ISO);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const rulesSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get FAQ
      .addCase(getFAQ.pending, (state) => {
        state.getFAQLoading = true;
        state.getFAQResult = null;
        state.getFAQError = null;
      })
      .addCase(getFAQ.fulfilled, (state, action) => {
        state.getFAQLoading = false;
        state.getFAQResult = action.payload;
        state.getFAQError = null;
      })
      .addCase(getFAQ.rejected, (state, action) => {
        state.getFAQLoading = false;
        state.getFAQResult = null;
        state.getFAQError = action.payload;
      })

      // get getInsurance
      .addCase(getInsurance.pending, (state) => {
        state.getInsuranceLoading = true;
        state.getInsuranceResult = null;
        state.getInsuranceError = null;
      })
      .addCase(getInsurance.fulfilled, (state, action) => {
        state.getInsuranceLoading = false;
        state.getInsuranceResult = action.payload;
        state.getInsuranceError = null;
      })
      .addCase(getInsurance.rejected, (state, action) => {
        state.getInsuranceLoading = false;
        state.getInsuranceResult = null;
        state.getInsuranceError = action.payload;
      })

      // get getPrivacy
      .addCase(getPrivacy.pending, (state) => {
        state.getPrivacyLoading = true;
        state.getPrivacyResult = null;
        state.getPrivacyError = null;
      })
      .addCase(getPrivacy.fulfilled, (state, action) => {
        state.getPrivacyLoading = false;
        state.getPrivacyResult = action.payload;
        state.getPrivacyError = null;
      })
      .addCase(getPrivacy.rejected, (state, action) => {
        state.getPrivacyLoading = false;
        state.getPrivacyResult = null;
        state.getPrivacyError = action.payload;
      })

      // get getTerms
      .addCase(getTerms.pending, (state) => {
        state.getTermsLoading = true;
        state.getTermsResult = null;
        state.getTermsError = null;
      })
      .addCase(getTerms.fulfilled, (state, action) => {
        state.getTermsLoading = false;
        state.getTermsResult = action.payload;
        state.getTermsError = null;
      })
      .addCase(getTerms.rejected, (state, action) => {
        state.getTermsLoading = false;
        state.getTermsResult = null;
        state.getTermsError = action.payload;
      });
  },
});

export default rulesSlice.reducer;
