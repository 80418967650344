import React from "react";
import "./TermsAgree.scss";

import { useTranslation } from "react-i18next";

import { FiCheck } from "react-icons/fi";
import { Link } from "react-router-dom";

const TermsAgree = ({ value, setValue }) => {
  const { t } = useTranslation();

  return (
    <div id='TermsAgree' className={value ? "agree" : ""}>
      <div className='button' onClick={() => setValue((state) => !state)}>
        <div className='icon'>{value && <FiCheck />}</div>
        <p>{t("landing.auth.titles.Agree")}</p>
      </div>
      <Link target='_blank' to='/terms' rel='noopener noreferrer'>
        {t("landing.auth.form.terms and conditions")}
        <div className='line'></div>
      </Link>
    </div>
  );
};

export default TermsAgree;
