import React from "react";
import "./FilterShippingType.scss";

import { useTranslation } from "react-i18next";
import { MdLocalShipping, MdDirectionsRailway, MdFlight } from "react-icons/md";
import { BiSolidShip } from "react-icons/bi";

import FilterShippingTypeItem from "./FilterShippingTypeItem/FilterShippingTypeItem";

const FilterShippingType = ({ shippingType, setShippingType }) => {
  const { t } = useTranslation();

  const arr = [
    {
      label: t("shipper.filters.All"),
      id: null,
    },
    {
      label: t("shipper.filters.Land"),
      id: 1,
      icon: <MdLocalShipping />,
    },
    {
      label: t("shipper.filters.Ocean"),
      id: 2,
      icon: <BiSolidShip />,
    },
    {
      label: t("shipper.filters.Air"),
      id: 3,
      icon: <MdFlight />,
    },
    {
      label: t("shipper.filters.Rail"),
      id: 4,
      icon: <MdDirectionsRailway />,
    },
  ];

  return (
    <div id='FilterShippingType'>
      {arr?.map((type, index) => {
        return (
          <FilterShippingTypeItem
            key={index}
            title={type?.label}
            active={shippingType === type?.id}
            onClick={() => {
              setShippingType(type?.id);
            }}
            icon={type?.icon || null}
          />
        );
      })}
    </div>
  );
};

export default FilterShippingType;
