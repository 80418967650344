import React, { Fragment, useState } from "react";
import "./AccountantEmailCard.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { MdLink } from "react-icons/md";
import { FiEdit2, FiTrash2, FiMoreVertical } from "react-icons/fi";
import { Menu, MenuItem } from "@mui/material";

import AccountantEmailEditModal from "./../AccountantEmailEditModal/AccountantEmailEditModal";
import AccountantEmailDeleteModal from "./../AccountantEmailDeleteModal/AccountantEmailDeleteModal";
import Character from "../../../../../UI/Character/Character";

const AccountantEmailCard = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const findAccountant =
    user?.contacts?.find((contact) => contact?.type?.id === 1) || null;

  const [showEditModal, setSHowEdiTmodal] = useState(false);
  const [showDeleteModal, setShowDeletemodal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <div id='AccountantEmailCard'>
        <div className='icon'>
          <MdLink />
        </div>
        <div className='content'>
          <h4>
            <Character max={50} title={findAccountant?.email || ""} />
          </h4>

          <h5>
            <Character
              max={50}
              title={t(
                "shipper.desc.We’ll send invoices also this email address"
              )}
            />
          </h5>
        </div>
        <div className='Moreicon' onClick={handleClick}>
          <FiMoreVertical />
        </div>
        <Menu
          id='OrdersTableItemMore'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              setSHowEdiTmodal(true);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <FiEdit2 />
              {t("shipper.buttons.Edit")}
            </div>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleClose();
              setShowDeletemodal(true);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                color: "red",
              }}
            >
              <FiTrash2 />
              {t("shipper.buttons.Delete")}
            </div>
          </MenuItem>
        </Menu>
      </div>
      {showEditModal && (
        <AccountantEmailEditModal
          isOpen={showEditModal}
          isClose={() => setSHowEdiTmodal(false)}
        />
      )}
      {showDeleteModal && (
        <AccountantEmailDeleteModal
          isOpen={showDeleteModal}
          isClose={() => setShowDeletemodal(false)}
        />
      )}
    </Fragment>
  );
};

export default AccountantEmailCard;
