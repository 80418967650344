import React, { Fragment, useEffect, useState } from "react";
import "./PlanningAssistant.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MdLocalShipping } from "react-icons/md";
import { MdSearch } from "react-icons/md";
import { AiOutlineFileSearch } from "react-icons/ai";

import {
  getPlanningAssistantTrucks,
  resetGetPlanningAssistantTrucks,
} from "../../../../store/fleet/trucks/trucksSlice";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import MainTitle from "../../../../componentsDashboard/MainTitle/MainTitle";
import DSearch from "../../../../componentsDashboard/FilterForms/DSearch/DSearch";
import PlanningAssistantTableHeader from "../../../../componentsDashboard/PlanningAssistant/PlanningAssistantTableHeader/PlanningAssistantTableHeader";
import PlanningAssistantTableRow from "../../../../componentsDashboard/PlanningAssistant/PlanningAssistantTableRow/PlanningAssistantTableRow";
import TableWrapper from "../../../../componentsDashboard/Form/TableFields/TableWrapper/TableWrapper";
import EmptyList from "../../../../componentsDashboard/UI/EmptyList/EmptyList";
import LoadingPage from "../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import PlanningAssistantFilter from "../../../../componentsDashboard/PlanningAssistant/PlanningAssistantFilter/PlanningAssistantFilter";

const PlanningAssistant = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectDaysOptions = [
    {
      label: `2 ${t("tms.filters.labels.weeks")}`,
      value: 14,
    },
    {
      label: `3 ${t("tms.filters.labels.weeks")}`,
      value: 21,
    },
    {
      label: `1 ${t("tms.filters.labels.month")}`,
      value: 30,
    },
  ];

  const { getPlanningAssistantTrucksResult, getPlanningAssistantTrucksLoading } = useSelector(
    (state) => state.trucks
  );

  const { getTruckscountResult, getTruckscountLoading } = useSelector((state) => state.summary);

  const [keyword, setKeyword] = useState("");
  const [daysQtyObj, setDaysQtyObj] = useState(selectDaysOptions[1]);

  const daysQty = daysQtyObj?.value;

  useEffect(() => {
    dispatch(getPlanningAssistantTrucks(keyword));
    return () => {
      dispatch(resetGetPlanningAssistantTrucks());
    };
  }, [dispatch, keyword]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Calendar")}>
      <section id='PlanningAssistant'>
        {getTruckscountLoading ? (
          <LoadingPage />
        ) : getTruckscountResult && getTruckscountResult?.active_trucks_count > 0 ? (
          <Fragment>
            <MainTitle title={t("tms.titles.Calendar")} />
            <div className='noShipmentInfoContainer'>
              <div className='leftContainer'>
                <div className='icon'>
                  <AiOutlineFileSearch />
                </div>
                <div className='text'>
                  {t(
                    "tms.desc.No Active Shipments Yet? To begin, check out the Load Board panel to find new orders and place your bids."
                  )}
                </div>
              </div>
              <div className='rightContainer'>
                <div className='button' onClick={() => navigate("/dashboard/tms/load_boards")}>
                  <div className='text'>{t("tms.forms.buttons.Go to load boards")}</div>
                  <div className='icon'>
                    <MdSearch />
                  </div>
                </div>
              </div>
            </div>
            <div className='filters'>
              <DSearch value={keyword} setValue={setKeyword} />
              <PlanningAssistantFilter
                daysQty={daysQtyObj}
                setDaysQty={setDaysQtyObj}
                selectDaysOptions={selectDaysOptions}
              />
            </div>
            <TableWrapper>
              <tbody>
                <tr>
                  <td>
                    <PlanningAssistantTableHeader daysQty={daysQty} />
                    {getPlanningAssistantTrucksLoading ? (
                      <div className='loadingContainer'>
                        <LoadingPage />
                      </div>
                    ) : getPlanningAssistantTrucksResult?.length < 10 ? (
                      getPlanningAssistantTrucksResult
                        ?.concat(Array(10 - getPlanningAssistantTrucksResult?.length).fill({}))
                        ?.map((d, i) => (
                          <PlanningAssistantTableRow
                            key={i}
                            daysQty={daysQty}
                            truckListId={i + 1}
                            data={d}
                          />
                        ))
                    ) : (
                      getPlanningAssistantTrucksResult?.map((d, i) => (
                        <PlanningAssistantTableRow
                          key={i}
                          daysQty={daysQty}
                          truckListId={i + 1}
                          data={d}
                        />
                      ))
                    )}
                  </td>
                </tr>
              </tbody>
            </TableWrapper>
          </Fragment>
        ) : (
          <EmptyList
            image={"/assets/images/dashboard/calendarCover.svg"}
            title={t("tms.titles.Add an Active Truck to Unlock New Possibilities!")}
            description={t(
              "tms.desc.It looks like you don’t have any active trucks added yet. By adding an active truck, you can easily manage your orders and track their schedules on the calendar. head over to the 'Fleet Management' page, where you can add your trucks and make them active."
            )}
            onClick={() => {
              navigate("/dashboard/tms/fleet_management/trucks");
            }}
            buttonText={t("tms.forms.buttons.Add active truck")}
            icon={<MdLocalShipping />}
          />
        )}
      </section>
    </Wrapper>
  );
};

export default PlanningAssistant;
