import React from "react";
import "./TruckLocationBadge.scss";

const TruckLocationBadge = ({ color }) => {
  return (
    <div id='TruckLocationBadge'>
      <div
        style={{ backgroundColor: color ? color : "red" }}
        className='SmallStatusBadge'
      ></div>
    </div>
  );
};

export default TruckLocationBadge;
