import React, { useEffect, useState } from "react";
import "./DashboardOrdersTable.scss";

import { useSelector, useDispatch } from "react-redux";

import { getOrders, resetGetOrders } from "../../../store/orders/ordersSlice";

import ActiveOrdersTable from "./ActiveOrdersTable/ActiveOrdersTable";
import DashboardOrdersTableHeader from "./Components/DashboardOrdersTableHeader/DashboardOrdersTableHeader";

const DashboardOrdersTable = () => {
  const dispatch = useDispatch();

  const { getOrdersLoading, getOrdersResult, getOrdersMeta } = useSelector((state) => state.orders);

  const [status, setStatus] = useState(null);

  useEffect(() => {
    dispatch(getOrders({ status_id: status?.value || [1, 2, 3, 4], page: 1 }));
    return () => {
      dispatch(resetGetOrders());
    };
  }, [dispatch, status]);

  return (
    <div id='DashboardOrdersTable'>
      <DashboardOrdersTableHeader status={status} setStatus={setStatus} />
      <ActiveOrdersTable orders={getOrdersResult} ordersLoading={getOrdersLoading} ordersMeta={getOrdersMeta} />
    </div>
  );
};

export default DashboardOrdersTable;
