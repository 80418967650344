import React, { Fragment } from "react";
import "./LangMobileList.scss";

import { FiCheck, FiChevronLeft } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { setLang } from "../../../store/additional/lang/langSlice";

const LangMobileList = ({ value, setValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getLanguagesResult, ISO } = useSelector((state) => state.lang);
  const currentValueLang =
    getLanguagesResult && getLanguagesResult.find((item) => item.key === ISO);

  const RenderItem = ({ item }) => {
    return (
      <li
        onClick={() => {
          setValue(false);
          dispatch(setLang(item.key));
        }}
        className={item === currentValueLang ? "active" : ""}
      >
        <div className='left'>
          <div
            className='flag'
            style={{ backgroundImage: `url(${item.flag})` }}
          ></div>
          <p>{item.native}</p>
        </div>
        <div className='check'>
          <FiCheck />
        </div>
      </li>
    );
  };

  return (
    <Fragment>
      {getLanguagesResult && (
        <div id='LangMobileList' className={value ? "open" : ""}>
          <div className='close'>
            <div
              className='icon'
              onClick={() => {
                setValue(false);
              }}
            >
              <FiChevronLeft />
            </div>
            <h3>{t("landing.navigation.Choose your Language")}</h3>
          </div>
          <ul className='list'>
            {getLanguagesResult.map((item) => (
              <RenderItem key={item.key} item={item} />
            ))}
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default LangMobileList;
