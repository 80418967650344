import React from "react";
import "./RoadPackageItem.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MdOutlineRemoveCircleOutline } from "react-icons/md";

import DSelect from "../../../../../Form/DSelect/DSelect";
import DQuantitySelect from "./../../../../../Form/DSelect/DQuantitySelect/DQuantitySelect";
import DInputs from "../../../../../Form/DInputs/DInputs";

const RoadPackageItem = ({ edit, index, group, setGroup, item, uniqueGroup }) => {
  const { t } = useTranslation();
  const { getTrailerTypesRoadLoading, getTrailerTypesRoadSelectResult } = useSelector(
    (state) => state.trailerType
  );

  const removeItemHandler = () => {
    const updatedGroup = [...group];
    const upgradeArray = updatedGroup.filter((i) => i.unique !== index);
    setGroup(upgradeArray);
  };

  const trailerTypeHandler = (value) => {
    const updatedGroup = group.map((item) => ({
      ...item,
      trailerType: item.unique === index ? value : item.trailerType,
      trailerSize: item.unique === index ? null : item.trailerSize,
      temperature: item.unique === index ? "" : item.temperature,
    }));
    setGroup(updatedGroup);
  };

  const traileSizeHandler = (value) => {
    const updatedGroup = group.map((item) => ({
      ...item,
      trailerSize: item.unique === index ? value : item.trailerSize,
    }));
    setGroup(updatedGroup);
  };

  const temperatureHandler = (value) => {
    const updatedGroup = group.map((item) => ({
      ...item,
      temperature: item.unique === index ? value : item.temperature,
    }));
    setGroup(updatedGroup);
  };

  const countDown = (prevCounter) => {
    if (prevCounter > 1) {
      const updatedGroup = [...group];
      const ind = updatedGroup.findIndex((i) => i.unique === index);
      updatedGroup.splice(ind, 1);
      setGroup(updatedGroup);
    }
  };

  const countUp = (prevCounter) => {
    if (prevCounter < 100) {
      const updatedGroup = [...group];
      const newItem = { ...item };
      updatedGroup.push(newItem);
      setGroup(updatedGroup);
    }
  };

  const count = group.filter((i) => i.unique === index).length;

  const trailerSizeOptions = item?.trailerType?.value?.sizes?.map((size) => {
    return {
      label: size.label,
      value: size,
    };
  });

  return (
    <div id='RoadPackageItem'>
      <div className='BlockA'>
        <div className='RoadPackageTrailer'>
          <DSelect
            required
            value={item.trailerType}
            setValue={(value) => trailerTypeHandler(value)}
            id={`CreateOrderContainerGroup${index}`}
            label={t("shipper.forms.content.Trailer type")}
            placeholder={t("shipper.forms.content.Select trailer")}
            loading={getTrailerTypesRoadLoading}
            options={getTrailerTypesRoadSelectResult}
            error={item.trailerTypeError}
          />
        </div>
        {item && item.trailerType && item.trailerType.value.temperature && (
          <div className='RoadPackageTemperature'>
            <DInputs
              required
              value={item.temperature}
              setValue={(value) => temperatureHandler(value)}
              id={`AddOrderTrailertemperatureSelect${index}`}
              label={t("shipper.forms.content.Temperature")}
              placeholder={t("shipper.forms.content.Select temperature")}
              error={item.temperatureError}
            />
          </div>
        )}
      </div>

      <div className='BlockB'>
        <div className='RoadPackageItemVolume'>
          <DSelect
            required
            value={item.trailerSize}
            setValue={(value) => traileSizeHandler(value)}
            id={`AddOrderTrailerVolumeSelect${index}`}
            loading={false}
            label={t("shipper.forms.content.Trailer volume")}
            placeholder={t("shipper.forms.content.Select volume")}
            options={trailerSizeOptions || []}
            disabled={!item.trailerType}
            error={item.trailerSizeError}
          />
        </div>

        {!edit && (
          <div className='RoadPackageQuantity'>
            <DQuantitySelect
              label={t("shipper.forms.content.Number of trailers")}
              value={count}
              setCountIn={!edit && countUp}
              setCountDec={!edit && countDown}
              error={null}
            />

            {uniqueGroup.length > 1 && (
              <div className='removeBtn' onClick={() => removeItemHandler()}>
                <MdOutlineRemoveCircleOutline />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RoadPackageItem;
