import React from "react";
import "./CreateOrderLocation.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { MdOutlineRadioButtonUnchecked, MdOutlineRadioButtonChecked } from "react-icons/md";

import DInputs from "../../../../Form/DInputs/DInputs";
import CreateOrderSectionTitle from "../CreateOrderSectionTitle/CreateOrderSectionTitle";
// import DSelect from "../../../../Form/DSelect/DSelect";
import Character from "../../../../UI/Character/Character";

const CreateOrderLocation = ({
  edit,
  type,
  locations,
  setLocations,
  locationADesc,
  locationBDesc,
  title,
}) => {
  const { t } = useTranslation();

  const {
    getDropoffLOCALITYResult,
    getPickupLOCALITYResult,
    getPickupPORTResult,
    getDropoffPORTResult,
    getPickupAIRPORTResult,
    getDropoffAIRPORTResult,
  } = useSelector((state) => state.locationSuggestion);

  const setLocationAHandler = (value) => {
    const updatedGroup = [...locations];
    updatedGroup[0].inputted_address = value;
    setLocations(updatedGroup);
  };

  const setLocationAdescriptionHandler = (value) => {
    const updatedGroup = [...locations];
    updatedGroup[0].type = value;
    setLocations(updatedGroup);
  };

  const setLocationBHandler = (value) => {
    const updatedGroup = [...locations];
    updatedGroup[1].inputted_address = value;
    setLocations(updatedGroup);
  };

  const setLocationBdescriptionHandler = (value) => {
    const updatedGroup = [...locations];
    updatedGroup[1].type = value;
    setLocations(updatedGroup);
  };

  return (
    <div id='ShipperCreateOrderLocation'>
      <CreateOrderSectionTitle title={title} />
      <div className='addressConatiner'>
        <div className='addressGroup'>
          <h4 className='title'>{t("shipper.forms.content.From")}</h4>
          {locationADesc.map((i, index) => (
            <div
              key={index}
              className={locations[0].type === i.type ? "radioItem active" : "radioItem"}
              onClick={() => setLocationAdescriptionHandler(i.type)}
            >
              <div className='icon'>
                {locations[0].type === i.type ? (
                  <MdOutlineRadioButtonChecked />
                ) : (
                  <MdOutlineRadioButtonUnchecked />
                )}
              </div>
              <p>{i.name}</p>
            </div>
          ))}

          {/* {locations?.[0]?.type === locationADesc[0].type ? (
            <DSelect
              id='ShipperAirLocationFromAddress'
              placeholder={t("shipper.forms.content.Address to pick up")}
              value={locations[0].inputted_address}
              setValue={(value) => setLocationAHandler(value)}
              error={locations[0].error}
            />
          ) : ( */}
          <DInputs
            id='ShipperAirLocationFromAddress'
            value={locations[0].inputted_address}
            setValue={(value) => setLocationAHandler(value)}
            placeholder={t("shipper.forms.content.Address to pick up")}
            icon={locations[0].type === type ? locationADesc[0].icon : locationADesc[1].icon}
            error={locations[0].error}
          />
          {/* )} */}
          {/* {!edit && locations?.[0]?.type !== locationADesc[0].type && ( */}
          {!edit && (
            <div className='suggestionsContainer'>
              {locations[0].type === "LOCALITY"
                ? getPickupLOCALITYResult?.map((item, index) => (
                    <div
                      className='suggestionItem'
                      key={index}
                      onClick={() => {
                        setLocationAHandler(item?.address);
                      }}
                    >
                      <Character max={11} title={item?.address} />
                    </div>
                  ))
                : locations[0].type === "PORT"
                ? getPickupPORTResult?.map((item, index) => (
                    <div
                      className='suggestionItem'
                      key={index}
                      onClick={() => {
                        setLocationAHandler(item?.address);
                      }}
                    >
                      <Character max={11} title={item?.address} />
                    </div>
                  ))
                : locations[0].type === "AIRPORT"
                ? getPickupAIRPORTResult?.map((item, index) => (
                    <div
                      className='suggestionItem'
                      key={index}
                      onClick={() => {
                        setLocationAHandler(item?.address);
                      }}
                    >
                      <Character max={11} title={item?.address} />
                    </div>
                  ))
                : null}
            </div>
          )}
        </div>

        <div className='addressGroup'>
          <h4 className='title'>{t("shipper.forms.content.To")}</h4>
          {locationBDesc.map((i, index) => (
            <div
              key={index}
              className={locations[1].type === i.type ? "radioItem active" : "radioItem"}
              onClick={() => setLocationBdescriptionHandler(i.type)}
            >
              <div className='icon'>
                {locations[1].type === i.type ? (
                  <MdOutlineRadioButtonChecked />
                ) : (
                  <MdOutlineRadioButtonUnchecked />
                )}
              </div>
              <p>{i.name}</p>
            </div>
          ))}

          {/* {locations?.[1]?.type === locationBDesc[0].type ? (
            <DSelect
              id='ShipperAirLocationFromAddress'
              placeholder={t("shipper.forms.content.Address to pick up")}
              value={locations[0].inputted_address}
              setValue={(value) => setLocationAHandler(value)}
              error={locations[0].error}
            />
          ) : ( */}
          <DInputs
            id='ShipperAirLocationFromAddress'
            value={locations[1].inputted_address}
            setValue={(value) => setLocationBHandler(value)}
            placeholder={t("shipper.forms.content.Address to drop off")}
            icon={locations[1].type === type ? locationBDesc[0].icon : locationBDesc[1].icon}
            error={locations[1].error}
          />
          {/* )} */}
          {/* {!edit && locations?.[1]?.type !== locationBDesc[0].type && ( */}
          {!edit && (
            <div className='suggestionsContainer'>
              {locations[1].type === "LOCALITY"
                ? getDropoffLOCALITYResult?.map((item, index) => (
                    <div
                      className='suggestionItem'
                      key={index}
                      onClick={() => {
                        setLocationBHandler(item?.address);
                      }}
                    >
                      <Character max={11} title={item?.address} />
                    </div>
                  ))
                : locations[1].type === "PORT"
                ? getDropoffPORTResult?.map((item, index) => (
                    <div
                      className='suggestionItem'
                      key={index}
                      onClick={() => {
                        setLocationBHandler(item?.address);
                      }}
                    >
                      <Character max={11} title={item?.address} />
                    </div>
                  ))
                : locations[1].type === "AIRPORT"
                ? getDropoffAIRPORTResult?.map((item, index) => (
                    <div
                      className='suggestionItem'
                      key={index}
                      onClick={() => {
                        setLocationBHandler(item?.address);
                      }}
                    >
                      <Character max={11} title={item?.address} />
                    </div>
                  ))
                : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateOrderLocation;
