import React, { Fragment, useEffect } from "react";
import "../styles/dashboard/dashboard.scss";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";

import ScrollToTop from "../componentsAdditional/ScrollToTop";
import AppCompany from "./AppCompany";
import AppShipper from "./AppShipper";

import {
  getPaymentType,
  getCargoTypes,
  getOrderServicesList,
  getCargoPackageList,
} from "../store/orders/orderSettings/orderSettingsSlice";

import { getAttachmentTypes } from "../store/orders/attachments/attachmentsSlice";
import { AMPLITUDE } from "../constants/aplitude";

import {
  getTMSStatuses,
  getClientStatuses,
} from "../store/orders/orderStatuses/orderStatusesSlice";
import { getOrderCostTypes } from "../store/orders/costs/orderCostsSlice";
import { getRoutesList } from "../store/routes/routesSlice";
import { getClientContactPositions } from "../store/customer/customerContacts/customerContactsSlice";
import { getPartnerContactPositions } from "../store/partner/partnerContacts/partnerContactsSlice";
import { getPreference } from "../store/preferences/preferencesSlice";
import { getBankAccounts } from "../store/user/bankAccounts/bankAccountsSlice";

import * as amplitude from "@amplitude/analytics-browser";
import AppCarrier from "./AppCarrier";

const AppDashboard = () => {
  const { user } = useSelector((state) => state.auth);
  amplitude.init(AMPLITUDE.key, { defaultTracking: true, userId: `Cargon user id: ${user?.id}` });

  const dispatch = useDispatch();
  const { openSidebar } = useSelector((state) => state.userUI);
  const { userType } = useSelector((state) => state.auth);
  const { storePreferenceResult, updatePreferenceResult } = useSelector(
    (state) => state.preferences
  );
  const { createBankAccountResult, updateBankAccountResult, deleteBankAccountResult } = useSelector(
    (state) => state.bankAccounts
  );

  useEffect(() => {
    dispatch(getPaymentType());
    dispatch(getCargoTypes());
    dispatch(getTMSStatuses());
    dispatch(getClientStatuses());
    dispatch(getOrderCostTypes());
    dispatch(getRoutesList());
    dispatch(getOrderServicesList());
    dispatch(getCargoPackageList());
    dispatch(getClientContactPositions());
    dispatch(getPartnerContactPositions());
    dispatch(getAttachmentTypes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPreference());
  }, [dispatch, storePreferenceResult, updatePreferenceResult]);

  useEffect(() => {
    dispatch(getBankAccounts());
  }, [dispatch, createBankAccountResult, updateBankAccountResult, deleteBankAccountResult]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#00b56c",
        light: "#33c489",
        dark: "#00814d",
        contrastText: "#ffffff",
      },
    },
  });

  return (
    <Fragment>
      {userType && (
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          {userType === 6 && (
            <div id='C-Layout'>
              <AppCarrier />
            </div>
          )}
          {userType === 4 && (
            <div id='C-Layout' className={openSidebar ? "max" : "min"}>
              <AppCompany />
            </div>
          )}
          {userType === 3 && (
            <div id='S-Layout'>
              <AppShipper />
            </div>
          )}
        </ThemeProvider>
      )}
    </Fragment>
  );
};

export default AppDashboard;
