import React, { Fragment } from "react";
import "./MobileTableItem.scss";

import moment from "moment";

import RowField from "../../../../Form/TableFields/RowField/RowField";
import DriverPhoneField from "./../../../../Form/TableFields/DriverPhoneField/DriverPhoneField";
import TruckField from "../../../../Form/TableFields/TruckField/TruckField";
import SendInvitationButton from "../../GPSConnectButtons/SendInvitationButton/SendInvitationButton";
import ConnectAppTimeField from "../../../../Form/TableFields/ConnectAppTimeField/ConnectAppTimeField";
import ConnectedToAPPButton from "../../GPSConnectButtons/ConnectedToAPPButton/ConnectedToAPPButton";

const MobileTableItem = ({ driver }) => {
  const isMoreThan3DaysAgo =
    moment().diff(moment(driver.last_used_at, "DD/MM/YYYY HH:mm:ss"), "days") >
    3;

  return (
    <Fragment>
      <RowField>
        <DriverPhoneField driver={driver} />
        <TruckField truck={driver.truck} />
        <ConnectAppTimeField time={driver.last_used_at} />
        {isMoreThan3DaysAgo || !driver.last_used_at ? (
          <SendInvitationButton driver={driver} />
        ) : (
          <ConnectedToAPPButton driver={driver} />
        )}
      </RowField>
    </Fragment>
  );
};

export default MobileTableItem;
