import React from "react";
import "./DIconButton.scss";

const DIconButton = ({ icon, danger, onClick }) => {
  return (
    <div
      id='DIconButton'
      className={danger ? "danger" : ""}
      onClick={(e) => {
        onClick(e);
      }}
    >
      {icon}
    </div>
  );
};

export default DIconButton;
