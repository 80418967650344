import React, { useState, useEffect } from "react";
import "./LoginForm.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login, resetLogin, getUser } from "../../../store/auth/authSlice";
import { containsOnlyNumbers } from "./../../../helper/useContainNum";

import ConvertInput from "../../Form/Inputs/ConvertInput/ConvertInput";
import Input from "../../Form/Inputs/Input";
import RememberMe from "./../../Form/Checkbox/RememberMe/RememberMe";
import Button from "../../Form/Button/Button";
import Label from "../../Form/Label/Label";
import ErrorText from "../../Form/ErrorText/ErrorText";
import AuthFormWrapper from "../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../Components/FormTitle/FormTitle";
import NotHaveAccount from "./../../Form/NotHaveAccount/NotHaveAccount";

const LoginForm = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginLoading, loginResult, loginError, verify, access_token, user } = useSelector(
    (state) => state.auth
  );
  const { phoneIndexValueId } = useSelector((state) => state.settings);

  const [method, setMethod] = useState("EMAIL");
  const [value, setValue] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler = () => {
    const data =
      method === "EMAIL"
        ? { email: value, password }
        : { phone_index_id: phoneIndexValueId, phone: value, password };

    dispatch(login(data));
  };

  useEffect(() => {
    if (loginResult) {
      dispatch(resetLogin());
      dispatch(getUser());
    }
  }, [dispatch, loginResult]);

  useEffect(() => {
    return () => {
      dispatch(resetLogin());
    };
  }, [dispatch]);

  useEffect(() => {
    const onlyNumber = containsOnlyNumbers(value);
    setMethod(value.length > 2 && onlyNumber ? "PHONE" : "EMAIL");
  }, [value]);

  useEffect(() => {
    if (access_token && !verify) {
      user?.type?.id === 4 && navigate(`/login/tms/verify?email=${user?.email}`);
      user?.type?.id === 3 && navigate(`/login/shipper/verify?email=${user?.email}`);
      dispatch(resetLogin());
    }
  }, [dispatch, navigate, access_token, user, verify]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const data =
          method === "EMAIL"
            ? { email: value, password }
            : { phone_index_id: phoneIndexValueId, phone: value, password };
        dispatch(login(data));
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [dispatch, method, password, phoneIndexValueId, value]);

  return (
    <AuthFormWrapper>
      <form id='LoginForm' className='LoginForm'>
        <FormTitle title={t("landing.auth.titles.Welcome back !")}>
          {t("landing.auth.titles.Access your account")}
        </FormTitle>

        <div className='group'>
          <div>
            <Label text={t("landing.auth.form.Email or phone number")} id='convertContainer' />
            <ConvertInput
              inputStatus={method}
              value={value}
              setValue={setValue}
              autoFocus={value.length > 2 ? true : false}
              id='convertContainer'
            />
          </div>

          <div className='passwordContainer'>
            <Label text={t("landing.auth.form.Password")} id='LoginPassword' />
            <Input
              value={password}
              setValue={setPassword}
              password
              placeholder={t("landing.auth.form.Enter password")}
              id='LoginPassword'
            />
            <ErrorText error={loginError?.message ?? null} />

            <div className='additional'>
              <RememberMe />
              <NotHaveAccount
                link='/forgot-password'
                text={t("landing.auth.form.Forgot password?")}
              />
            </div>
          </div>
        </div>

        <Button
          loading={loginLoading}
          title={t("landing.auth.form.Log in")}
          onClick={(e) => {
            submitHandler(e);
          }}
        />

        <NotHaveAccount
          desc={t("landing.auth.titles.Don’t have an account?")}
          link='/register'
          text={t("landing.auth.form.Sign up here")}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default LoginForm;
