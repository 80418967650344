import React, { Fragment } from "react";
import "./DriverPhoneField.scss";

import { useTranslation } from "react-i18next";

import { FaUser } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";

import Character from "../../../UI/Character/Character";

const DriverPhoneField = ({ driver, max }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <td id='DriverPhoneField'>
        {driver ? (
          <div className='DriverPhoneFieldUser'>
            <div className='DriverPhoneFieldIcon'>
              <FaUser />
            </div>
            <div className='DriverPhoneFieldInfo'>
              <h4>
                <Character title={driver.name} max={max ? max : 20} />
              </h4>
              <h5>
                <Character title={driver.phone} max={max ? max : 20} />
              </h5>
            </div>
          </div>
        ) : (
          <div className='DriverPhoneFieldNoUser'>
            <div className='DriverPhoneFieldIcon'>
              <IoIosWarning />
            </div>
            <p>{t("tms.tables.noInfo.no driver")}</p>
          </div>
        )}
      </td>
    </Fragment>
  );
};

export default DriverPhoneField;
