import React, { Fragment, useState } from "react";
import "./FinancialDetailsShipper.scss";

import { useTranslation } from "react-i18next";
import { useMoney } from "../../../helper/useMoney";

import FinancialDetailsShipperHeader from "./Components/FinancialDetailsShipperHeader/FinancialDetailsShipperHeader";
import FinancialDetailShipperPayment from "./Components/FinancialDetailShipperPayment/FinancialDetailShipperPayment";
import DShowButton from "../../Form/DButtons/DShowButton/DShowButton";
import CostsModalShipper from "./Components/CostsModalShipper/CostsModalShipper";

const FinancialDetailsShipper = ({ order }) => {
  const { t } = useTranslation();
  const [openShowCostsModal, setOpenShowCostsModal] = useState(false);

  const formatedOrderPrice = useMoney(order?.revenue || 0, order?.revenue_currency?.symbol || "");

  const formatedOrderProfit = useMoney(
    order?.total_revenue || 0,
    order?.revenue_currency?.symbol || ""
  );

  const formatedExtraIncome = useMoney(
    order?.total_extra_revenue || 0,
    order?.revenue_currency?.symbol || ""
  );

  return (
    <Fragment>
      {order && (
        <div id='FinancialDetailsShipper'>
          <FinancialDetailsShipperHeader order={order} />

          <div className='FinancialDetailsContent'>
            <FinancialDetailShipperPayment order={order} />

            <div className='FinancialDetailslist'>
              <div className='orderPrice'>
                <h4>{t("shipper.forms.content.Order price")}</h4>
                <div className='money'>
                  <h5>{formatedOrderPrice}</h5>
                </div>
              </div>
              <div className='costs'>
                <h4>{t("shipper.forms.content.Additional costs")}</h4>
                <div className='money'>
                  <h5>{formatedExtraIncome}</h5>
                  {order.total_extra_revenue && order.total_extra_revenue > 0 ? (
                    <div className='icon' onClick={() => setOpenShowCostsModal(true)}>
                      <DShowButton />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='totalPayment'>
                <h4>{t("shipper.forms.content.Total payment")}</h4>
                <div className='money'>
                  <h5>{formatedOrderProfit}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {openShowCostsModal && (
        <CostsModalShipper
          isOpen={openShowCostsModal}
          isClose={() => setOpenShowCostsModal(false)}
          orderId={order.id}
        />
      )}
    </Fragment>
  );
};

export default FinancialDetailsShipper;
