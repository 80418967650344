import React from "react";
import "./Warning.scss";

const Warning = ({ text, title }) => {
  return (
    <div id='Warning' className='Warning'>
      {title}
      <div className='WarningContent'>{text}</div>
    </div>
  );
};

export default Warning;
