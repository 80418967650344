import React, { Fragment } from "react";
import "./TrailerField.scss";

import { Link } from "react-router-dom";

const TrailerField = ({ trailerType, volume, to, icon, max }) => {
  return (
    <Fragment>
      {to ? (
        <td id='TrailerField' className='link'>
          <Link to={to} className='TrailerFieldContainer'>
            <div
              className='cover'
              style={{
                backgroundImage: icon
                  ? `url(${icon})`
                  : `url(${`/assets/images/dashboard/cover7.svg`})`,
              }}
            ></div>
            <div className='content'>
              <div>
                <h3>{trailerType}</h3>
                <p>{volume && volume}</p>
              </div>
            </div>
          </Link>
        </td>
      ) : (
        <td id='TrailerField'>
          <div className='TrailerFieldContainer'>
            <div
              className='cover'
              style={{
                backgroundImage: icon
                  ? `url(${icon})`
                  : `url(${`/assets/images/dashboard/cover7.svg`})`,
              }}
            ></div>
            <div className='content'>
              <div>
                <h3>{trailerType}</h3>
                <p>{volume && volume}</p>
              </div>
            </div>
          </div>
        </td>
      )}
    </Fragment>
  );
};

export default TrailerField;
