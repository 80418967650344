import React from "react";
import "./ShipperCalendarSelectDate.scss";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const ShipperCalendarSelectDate = ({ startDate, setStartDate }) => {
  const { t } = useTranslation();
  const handlePrevious = () => {
    setStartDate(moment(startDate).subtract(14, "days"));
  };

  const handleNext = () => {
    setStartDate(moment(startDate).add(14, "days"));
  };

  return (
    <div id='ShipperCalendarSelectDate'>
      <div className='icon' onClick={handlePrevious}>
        <IoIosArrowBack />
      </div>
      <div className='content'>
        {moment().format("DD MMM") === moment(startDate).format("DD MMM")
          ? t("tms.titles.Today")
          : moment(startDate).format("MMM DD")}{" "}
        - {moment(startDate).add(13, "days").format("MMM DD")}
      </div>
      <div className='icon' onClick={handleNext}>
        <IoIosArrowForward />
      </div>
    </div>
  );
};

export default ShipperCalendarSelectDate;
