import React from "react";
import "./HeaderItem.scss";

const HeaderItem = ({ icon, title, hidden }) => {
  return (
    <div id='HeaderItem' style={{ display: hidden ? "none" : "flex" }}>
      <div className='icon'>{icon}</div>
      <div className='title'>{title}</div>
    </div>
  );
};

export default HeaderItem;
