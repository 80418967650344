import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import trailersService from "./trailersService";

const initialState = {
  getTrailersLoading: true,
  getTrailersResult: null,
  getTrailersError: null,

  getTrailerLoading: true,
  getTrailerResult: null,
  getTrailerError: null,

  createTrailerLoading: false,
  createTrailerResult: null,
  createTrailerError: null,

  updateTrailerLoading: false,
  updateTrailerResult: null,
  updateTrailerError: null,

  deleteTrailerLoading: false,
  deleteTrailerResult: null,
  deleteTrailerError: null,
};

export const getTrailers = createAsyncThunk("trailers/getTrailers", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trailersService.getTrailers(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getTrailer = createAsyncThunk("trailers/getTrailer", async (id, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trailersService.getTrailer(ISO, TOKEN, id);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createTrailer = createAsyncThunk("trailers/createTrailer", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trailersService.createTrailer(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateTrailer = createAsyncThunk("trailers/updateTrailer", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trailersService.updateTrailer(ISO, TOKEN, data.id, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteTrailer = createAsyncThunk("trailers/deleteTrailer", async (id, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await trailersService.deleteTrailer(ISO, TOKEN, id);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const trailersSlice = createSlice({
  name: "trailers",
  initialState,
  reducers: {
    resetGetTrailers: (state) => {
      state.getTrailersLoading = true;
      state.getTrailersResult = null;
      state.getTrailersError = null;
    },
    resetGetTrailer: (state) => {
      state.getTrailerLoading = true;
      state.getTrailerResult = null;
      state.getTrailerError = null;
    },
    resetCreateTrailer: (state) => {
      state.createTrailerLoading = false;
      state.createTrailerResult = null;
      state.createTrailerError = null;
    },
    resetUpdateTrailer: (state) => {
      state.updateTrailerLoading = false;
      state.updateTrailerResult = null;
      state.updateTrailerError = null;
    },
    resetDeleteTrailer: (state) => {
      state.deleteTrailerLoading = false;
      state.deleteTrailerResult = null;
      state.deleteTrailerError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrailers.pending, (state) => {
        state.getTrailersLoading = true;
        state.getTrailersResult = null;
        state.getTrailersError = null;
      })
      .addCase(getTrailers.fulfilled, (state, action) => {
        state.getTrailersLoading = false;
        state.getTrailersResult = action.payload;
        state.getTrailersError = null;
      })
      .addCase(getTrailers.rejected, (state, action) => {
        state.getTrailersLoading = false;
        state.getTrailersResult = null;
        state.getTrailersError = action.payload;
      })

      .addCase(getTrailer.pending, (state) => {
        state.getTrailerLoading = true;
        state.getTrailerResult = null;
        state.getTrailerError = null;
      })
      .addCase(getTrailer.fulfilled, (state, action) => {
        state.getTrailerLoading = false;
        state.getTrailerResult = action.payload;
        state.getTrailerError = null;
      })
      .addCase(getTrailer.rejected, (state, action) => {
        state.getTrailerLoading = false;
        state.getTrailerResult = null;
        state.getTrailerError = action.payload;
      })

      .addCase(createTrailer.pending, (state) => {
        state.createTrailerLoading = true;
        state.createTrailerResult = null;
        state.createTrailerError = null;
      })
      .addCase(createTrailer.fulfilled, (state, action) => {
        state.createTrailerLoading = false;
        state.createTrailerResult = action.payload;
        state.createTrailerError = null;
      })
      .addCase(createTrailer.rejected, (state, action) => {
        state.createTrailerLoading = false;
        state.createTrailerResult = null;
        state.createTrailerError = action.payload;
      })

      .addCase(updateTrailer.pending, (state) => {
        state.updateTrailerLoading = true;
        state.updateTrailerResult = null;
        state.updateTrailerError = null;
      })
      .addCase(updateTrailer.fulfilled, (state, action) => {
        state.updateTrailerLoading = false;
        state.updateTrailerResult = action.payload;
        state.updateTrailerError = null;
      })
      .addCase(updateTrailer.rejected, (state, action) => {
        state.updateTrailerLoading = false;
        state.updateTrailerResult = null;
        state.updateTrailerError = action.payload;
      })

      .addCase(deleteTrailer.pending, (state) => {
        state.deleteTrailerLoading = true;
        state.deleteTrailerResult = null;
        state.deleteTrailerError = null;
      })
      .addCase(deleteTrailer.fulfilled, (state, action) => {
        state.deleteTrailerLoading = false;
        state.deleteTrailerResult = action.payload;
        state.deleteTrailerError = null;
      })
      .addCase(deleteTrailer.rejected, (state, action) => {
        state.deleteTrailerLoading = false;
        state.deleteTrailerResult = null;
        state.deleteTrailerError = action.payload;
      });
  },
});

export const {
  resetGetTrailers,
  resetGetTrailer,
  resetCreateTrailer,
  resetUpdateTrailer,
  resetDeleteTrailer,
} = trailersSlice.actions;
export default trailersSlice.reducer;
