import React, { Fragment, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  deleteDriver,
  resetDeleteDriver,
} from "./../../../../store/fleet/drivers/driversSlice";

import DModal from "./../../../Modal/DModal/DModal";

const DeleteDriversModal = ({ driverId, isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deleteDriverLoading, deleteDriverResult } = useSelector(
    (state) => state.drivers
  );

  const submitHandler = () => {
    dispatch(deleteDriver(driverId));
  };

  useEffect(() => {
    if (deleteDriverResult) {
      dispatch(resetDeleteDriver());
      isClose();
      navigate("/dashboard/tms/fleet_management/drivers");
    }
  }, [dispatch, navigate, isClose, deleteDriverResult]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Delete Driver")}
        submitText={t("tms.modals.buttons.Delete")}
        icon={<FiTrash2 />}
        status='danger'
        onClick={() => submitHandler()}
        loading={deleteDriverLoading}
      >
        <p>
          {t("tms.modals.desc.Are you sure you want to delete this driver?")}
        </p>
      </DModal>
    </Fragment>
  );
};

export default DeleteDriversModal;
