import React from "react";
import "./InvoiceAmountField.scss";

import { useTranslation } from "react-i18next";
import { useMoney } from "../../../../helper/useMoney";

const InvoiceAmountField = ({ order }) => {
  const { t } = useTranslation();

  const formatedAll = useMoney(order?.total_revenue || 0, order?.revenue_currency?.symbol || "$");
  const formatedPaid = useMoney(order?.total_client_paid || 0, order?.revenue_currency?.symbol || "$");
  const formatedLeft = useMoney(
    order?.total_revenue - order?.total_client_paid || 0,
    order?.revenue_currency?.symbol || "$"
  );

  return (
    <td id='InvoiceAmountField'>
      <div className='container'>
        <h4>{formatedAll}</h4>

        {!order?.payd && order?.total_client_paid > 0 && (
          <div className='paid'>
            <h5>
              {formatedPaid} {t("shipper.table.desc.paid")}
            </h5>
            <h6>
              {formatedLeft} {t("shipper.table.desc.left")}
            </h6>
          </div>
        )}
      </div>
    </td>
  );
};

export default InvoiceAmountField;
