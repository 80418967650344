import React from "react";
import "./OrderTooltip.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { MdLocationPin, MdLocationSearching, MdOutlinePhoneIphone, MdPerson } from "react-icons/md";
import { BsBoxSeam } from "react-icons/bs";

import { useFormatedDistance } from "../../../../helper/useFormatedDistance";

import Character from "../../../UI/Character/Character";
import RoadIcon from "../../../TrackerMap/Components/CardComponents/RoadIcon/RoadIcon";

const OrderTooltip = ({ order }) => {
  const { t } = useTranslation();

  const directionA = order?.closest_order?.cargos?.[0]?.locations?.filter((location) => {
    return location.direction === "A";
  });
  const directionB = order?.closest_order?.cargos?.[0]?.locations?.filter((location) => {
    return location.direction === "B";
  });

  return (
    <div id='OrderTooltip' className='PlanningAssistantTableOrderTooltipContent'>
      <div className='InfoWindowHeader'>
        <div className='truck'>
          <div
            className='cover'
            style={{
              backgroundImage: `url(${`/assets/images/dashboard/cover10.svg`})`,
            }}
          />
          <div className='name'>
            <h2>{order?.model?.brand?.name || t("tms.titles.Truck")}</h2>
            <h3>{order?.number || "-"}</h3>
          </div>
        </div>
        <RoadIcon check={true} />
      </div>
      <div className='InfoWindowDriver'>
        <div className='title'>
          <h3>{t("tms.titles.Driver")}</h3>
        </div>

        <div className='desc'>
          <div className='name'>
            <div className='icon'>
              <MdPerson />
            </div>
            <h3>
              <Character title={order?.driver?.name} max={14} />
            </h3>
          </div>
          <div className='tel'>
            <div className='icon'>
              <MdOutlinePhoneIphone />
            </div>
            <h3>
              <Character title={order?.driver?.phone} max={13} />
            </h3>
          </div>
        </div>
      </div>
      <div className='InfoWindowTrailer'>
        <div className='title'>
          <h3>{t("tms.titles.Trailer")}</h3>
        </div>
        <div className='desc'>
          <div className='name'>
            <div
              className='cover'
              style={{
                backgroundImage: `url(${`/assets/images/dashboard/cover7.svg`})`,
              }}
            ></div>
            {order?.trailer ? (
              <h3>
                <Character title={order?.trailer?.type?.name} max={9} />
              </h3>
            ) : (
              <div id='noTrailer'>{t("tms.desc.no trailer")}</div>
            )}
          </div>

          <div className='tel'>
            <div className='icon'>
              <BsBoxSeam />
            </div>
            <h3>
              <Character title={order?.closest_order?.cargos?.[0]?.type?.name} max={12} />
            </h3>
          </div>
        </div>
      </div>
      <div className='InfoWindowAddress'>
        <div className='titleContainer'>
          <h3>{t("tms.titles.Route")}</h3>
          {order?.distance ? (
            <div className='routeDistance'>
              {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useFormatedDistance(order?.distance, t("tms.desc.KM"))
              }
            </div>
          ) : null}
        </div>
        <ul className='InfoWindowAddressdesc'>
          {directionA &&
            directionA.length > 0 &&
            directionA.map((item, i) => (
              <li className='item' key={i}>
                <div className='icon'>
                  <MdLocationSearching />
                </div>
                <h4>{item?.formatted_address}</h4>
              </li>
            ))}

          {directionB &&
            directionB.length > 0 &&
            directionB.map((item, i) => (
              <li className='item' key={i}>
                <div className='icon'>
                  <MdLocationPin />
                </div>
                <h4>{item?.formatted_address}</h4>
              </li>
            ))}
        </ul>
      </div>
      <div className='link'>
        <Link
          to={`/dashboard/tms/orders/${order?.closest_order?.id}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {t("tms.forms.buttons.See full order")}
        </Link>
      </div>
    </div>
  );
};

export default OrderTooltip;
