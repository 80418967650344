import React from "react";
import "./FadeTextAnimation.scss";

const FadeTextAnimation = ({ text }) => {
  const chars = text.split(" ");

  return (
    <h2 id='FadeTextAnimation'>
      {chars && chars.map((i, index) => <span key={index}>{i} </span>)}
    </h2>
  );
};

export default FadeTextAnimation;
