import React, { useEffect, useRef, useState } from "react";
import "./ShipperChartSelectDate.scss";

import moment from "moment";
import Calendar from "react-calendar";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const ShipperChartSelectDate = ({ date, setDate, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const calendarRef = useRef(null);

  const handlePrev = () => {
    setDate(moment(date).subtract(1, "month"));
  };

  const handleNext = () => {
    if (moment(date).add(1, "month") <= moment()) {
      setDate(moment(date).add(1, "month"));
    }
  };

  const handleDateChange = (date) => {
    setDate(date);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isOpen &&
        calendarRef.current &&
        !calendarRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    setTimeout(() => {
      document.addEventListener("mouseup", handleOutsideClick);
    }, 0);

    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [isOpen, setIsOpen]);

  return (
    <div id='ShipperChartSelectDate' ref={calendarRef}>
      <div className='container'>
        <div className='icon' onClick={handlePrev}>
          <MdKeyboardArrowLeft />
        </div>
        <div className='date' onClick={() => setIsOpen(!isOpen)}>
          {placeholder}
        </div>
        <div
          className={`icon ${
            moment(date).add(1, "month") > moment() ? "disabled" : ""
          }`}
          onClick={handleNext}
        >
          <MdKeyboardArrowRight />
        </div>
      </div>
      {isOpen && (
        <div className='calendar-popup'>
          <Calendar
            className='calendar'
            onChange={handleDateChange}
            value={date}
            minDetail='year'
            maxDetail='year'
            formatMonth={(_, date) => moment(date).format("MMM")}
            tileDisabled={({ date, view }) =>
              view === "year" &&
              (date.getFullYear() > new Date().getFullYear() ||
                (date.getFullYear() === new Date().getFullYear() &&
                  date.getMonth() > new Date().getMonth()))
            }
          />
        </div>
      )}
    </div>
  );
};

export default ShipperChartSelectDate;
