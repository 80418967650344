import React from "react";
import "./ShipperChartHeader.scss";

import ShipperChartSwitcher from "./ShipperChartSwitcher/ShipperChartSwitcher";
import ShipperChartSelectDate from "./ShipperChartSelectDate/ShipperChartSelectDate";
import moment from "moment";
import { useSelector } from "react-redux";

const ShipperChartHeader = ({ activeChart, setActiveChart, date, setDate }) => {
  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const formattedDate = `${moment(date)
    .subtract(5, "month")
    .format("MMMM")} - ${moment(date).format("MMMM")}`;

  return (
    <div id='ShipperChartHeader'>
      <ShipperChartSwitcher
        activeChart={activeChart}
        setActiveChart={setActiveChart}
      />
      <div className='calendarPickerContainer'>
        <ShipperChartSelectDate
          date={date}
          setDate={setDate}
          placeholder={formattedDate}
        />
      </div>
    </div>
  );
};

export default ShipperChartHeader;
