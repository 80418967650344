import React, { Fragment } from "react";

import UpdateItem from "./UpdateItem/UpdateItem";

const TrackingUpdatesData = ({ order }) => {
  return (
    <Fragment>
      <UpdateItem order={order} />
    </Fragment>
  );
};

export default TrackingUpdatesData;
