import React, { Fragment, useState, useEffect } from "react";
import "./DriverList.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import { getDrivers, resetGetDrivers } from "../../../../../../store/fleet/drivers/driversSlice";

import PageSwitcher from "../../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import EmptyList from "../../../../../../componentsDashboard/UI/EmptyList/EmptyList";
import Wrapper from "../../../../../../componentsAdditional/Wrapper";
import AddDriversModal from "../../../../../../componentsDashboard/FleetManagement/FleetManagementDrivers/AddDriversModal/AddDriversModal";
import DriversTable from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementDrivers/DriversTable/DriversTable";
import LoadingPage from "./../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import DriversListHeader from "./../../../../../../componentsDashboard/FleetManagement/FleetManagementDrivers/DriversListHeader/DriversListHeader";

const DriverList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getDriverscountResponce, getDriverscountLoading } = useSelector((state) => state.summary);

  const {
    getDriversLoading,
    getDriversResult,
    createDriverResult,
    updateDriverResult,
    deleteDriverResult,
  } = useSelector((state) => state.drivers);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [linkedTo, setLinkedTo] = useState(null);

  useEffect(() => {
    dispatch(
      getDrivers({
        keyword,
        has_truck: linkedTo?.value,
      })
    );
    return () => {
      dispatch(resetGetDrivers());
    };
  }, [dispatch, createDriverResult, updateDriverResult, deleteDriverResult, keyword, linkedTo]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Drivers")}>
      <section id='DriverList'>
        <PageSwitcher name='drivers' />
        {getDriverscountLoading && <LoadingPage />}
        {getDriverscountResponce && (
          <Fragment>
            {getDriverscountResponce.drivers_count === 0 ? (
              <EmptyList
                title={t("tms.titles.No Driver")}
                description={t(
                  "tms.desc.Add driver, make it active and take order whenever you want"
                )}
                buttonText={t("tms.forms.buttons.Add driver")}
                image='/assets/images/dashboard/cover2.png'
                onClick={() => {
                  setOpenAddModal(true);
                }}
              />
            ) : (
              <Fragment>
                <DriversListHeader
                  setKeyword={setKeyword}
                  linkedTo={linkedTo}
                  setLinkedTo={setLinkedTo}
                  setOpenAddModal={setOpenAddModal}
                />
                {getDriversLoading && <LoadingPage />}
                {getDriversResult && <DriversTable drivers={getDriversResult} />}
              </Fragment>
            )}
          </Fragment>
        )}
      </section>
      <AddDriversModal isOpen={openAddModal} isClose={setOpenAddModal} />
    </Wrapper>
  );
};

export default DriverList;
