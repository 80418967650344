import _request from "./../../_request";

const getAttachmentTypes = async (ISO, TOKEN) => {
  const config = {
    url: "/order/attachment-types",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const temporaryAttachment = async (ISO, TOKEN, data) => {
  const config = {
    url: "/order/temporary-attachment",
    token: TOKEN,
    method: "post",
    file: true,
    ISO,
  };
  return _request(config, data);
};

const createAttachment = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `/order/${id}/attachment`,
    token: TOKEN,
    method: "post",
    file: true,
    ISO,
  };
  return _request(config, data);
};

const deleteAttachment = async (ISO, TOKEN, id, fileId) => {
  const config = {
    url: `/order/${id}/attachment/${fileId}`,
    token: TOKEN,
    method: "delete",
    ISO,
  };
  return _request(config);
};

const attachmentsService = {
  getAttachmentTypes,
  temporaryAttachment,
  createAttachment,
  deleteAttachment,
};

export default attachmentsService;
