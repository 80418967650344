import React, { useEffect, useState } from "react";
import "./AddVatModal.scss";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { MdOutlineSecurity } from "react-icons/md";
import { resetUpdateVat, updateVat } from "../../../../store/user/userSlice";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";

const AddVatModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updateVatLoading, updateVatResult, updateVatError } = useSelector((state) => state.user);

  const [vat, setVat] = useState("");

  const submitHandler = () => {
    dispatch(updateVat({ legal_id: vat }));
  };

  useEffect(() => {
    if (updateVatResult) {
      dispatch(resetUpdateVat());
      isClose();
    }
  }, [dispatch, isClose, updateVatResult]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      title={t("tms.modals.titles.Complete Your verification")}
      submitText={t("tms.modals.buttons.Complete verification")}
      icon={<MdOutlineSecurity />}
      loading={updateVatLoading}
      onClick={submitHandler}
    >
      <div id='AddVatModal'>
        <div
          className='cover'
          style={{ backgroundImage: `url(${`/assets/images/dashboard/vat-modal-cover.svg`})` }}
        />
        <div className='titleContent'>
          <h1 className='title'>{t("tms.modals.desc.Please enter your VAT number to proceed")}</h1>
          <p className='text'>
            {t(
              "tms.modals.desc.Providing your VAT number is necessary for us to identify your company for security purposes. Our primary goal is to ensure safety for all our customers and partners—thank you for helping us achieve this."
            )}
          </p>
        </div>
        <div className='form'>
          <DInputs
            id='vat'
            type='text'
            placeholder={t("tms.modals.placeholder.Enter VAT number")}
            value={vat}
            setValue={setVat}
            label={t("tms.modals.labels.VAT number")}
            required
            onChange={(e) => setVat(e.target.value)}
            error={updateVatError?.errors?.legal_id?.[0]}
          />
        </div>
      </div>
    </DModal>
  );
};

export default AddVatModal;
