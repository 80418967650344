import React, { Fragment, useState, useEffect } from "react";
import "./NotificationList.scss";

import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  getNotifications,
  resetGetNotifications,
} from "../../../store/notifications/notificationsSlice";

import NotificationsPageHeader from "./../NotificationsPageHeader/NotificationsPageHeader";
import LoadingPage from "./../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import NoNotifications from "./../NoNotifications/NoNotifications";
import Pagination from "./../../Form/Pagination/Pagination";
import NotificationItem from "./../NotificationItem/NotificationItem";

const NotificationList = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const {
    getNotiLoading,
    getNotiResult,
    getNotiMeta,
    markAllNotiResult,
    markNotiResult,
  } = useSelector((state) => state.notifications);

  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );

  useEffect(() => {
    dispatch(getNotifications({ page }));
    return () => {
      dispatch(resetGetNotifications());
    };
  }, [dispatch, page, markAllNotiResult, markNotiResult]);

  return (
    <section id='NotificationList'>
      <NotificationsPageHeader />
      {getNotiLoading && <LoadingPage />}
      {getNotiResult && getNotiMeta && (
        <div className='NotificationsContent'>
          {getNotiMeta.total === 0 ? (
            <NoNotifications />
          ) : (
            <Fragment>
              {getNotiResult.map((item) => (
                <NotificationItem key={item.id} notification={item} />
              ))}
              <Pagination
                value={page}
                setValue={setPage}
                pageCount={getNotiMeta.last_page}
              />
            </Fragment>
          )}
        </div>
      )}
    </section>
  );
};

export default NotificationList;
