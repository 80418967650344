import React, { Fragment } from "react";
import "./CreateOrderProductType.scss";

import { FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import DCargoTypesSelect from "./../../../../Form/DSelect/DCargoTypesSelect/DCargoTypesSelect";
import DInputs from "./../../../../Form/DInputs/DInputs";
import DTextButton from "./../../../../Form/DButtons/DTextButton/DTextButton";

const CreateOrderProductType = ({
  cargoType,
  setCargoType,
  cargoTypeError,
  HSCode,
  setHSCode,
  HSCodeValue,
  setHSCodeValue,
  HSCodeValueError,
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div id='CreateOrderProductType'>
        <div className='ProductSelect'>
          <DCargoTypesSelect
            required
            label={t("shipper.forms.content.Product type")}
            placeholder={t("shipper.forms.content.Select type")}
            value={cargoType}
            setValue={(value) => setCargoType(value)}
            error={cargoTypeError}
          />
        </div>

        <div className='hsCodeContainer'>
          {HSCode || HSCodeValueError ? (
            <div className='ShowCode'>
              <div className='CodeInputContainer'>
                <DInputs
                  id='ShipperAirPackageItemHSCode'
                  placeholder={t("shipper.forms.content.HS code")}
                  value={HSCodeValue}
                  setValue={(value) => setHSCodeValue(value)}
                  error={HSCodeValueError}
                />
              </div>

              <DTextButton
                title={t("shipper.buttons.Remove")}
                onClick={() => {
                  setHSCode(false);
                  setHSCodeValue("");
                }}
              />
            </div>
          ) : (
            <div className='hiddenCode'>
              <DTextButton
                leftIcon={<FiPlus />}
                title={t("shipper.buttons.HS code")}
                onClick={() => {
                  setHSCode(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CreateOrderProductType;
