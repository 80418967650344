import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  deletePartnerContact,
  resetDeletePartnerContact,
} from "../../../../../store/partner/partnerContacts/partnerContactsSlice";

import DModal from "../../../../Modal/DModal/DModal";

const DeletePartnerContactModal = ({ isOpen, isClose, id, contactId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { deletePartnerContactLoading, deletePartnerContactResult } = useSelector(
    (state) => state.partnerContacts
  );

  const submitHandler = () => {
    dispatch(deletePartnerContact({ id: id, contactId: contactId }));
  };

  useEffect(() => {
    if (deletePartnerContactResult) {
      dispatch(resetDeletePartnerContact());
      isClose();
    }
  }, [dispatch, isClose, deletePartnerContactResult]);

  useEffect(() => {
    return () => {
      dispatch(resetDeletePartnerContact());
    };
  }, [dispatch]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => submitHandler()}
      submitText={t("tms.modals.buttons.Delete")}
      title={t("tms.modals.titles.Delete contact")}
      icon={<FiTrash2 />}
      status='danger'
      loading={deletePartnerContactLoading}
    >
      <p>{t("tms.modals.desc.Are you sure you want to delete contact?")}</p>
    </DModal>
  );
};

export default DeletePartnerContactModal;
