import React, { Fragment, useEffect, useState } from "react";
import "./SelectTrailerModalTableItem.scss";

import { useSelector, useDispatch } from "react-redux";
import { manageTruck, resetManageTruck } from "./../../../../../../store/fleet/trucks/trucksSlice";

import TrailerField from "../../../../../Form/TableFields/TrailerField/TrailerField";
import LinkedTruckField from "../../../../../Form/TableFields/TruckField/TruckField";
import RowField from "../../../../../Form/TableFields/RowField/RowField";
import TextField from "../../../../../Form/TableFields/TextField/TextField";
import CenterField from "./../../../../../Form/TableFields/CenterField/CenterField";
import DLinkOffButton from "./../../../../Components/DLinkOffButton/DLinkOffButton";
import DLinkToButton from "../../../../Components/DLinkToButton/DLinkToButton";
import LinkOffTrailerModal from "../../../../FleetManagementTrailers/LinkOffTrailerModal/LinkOffTrailerModal";

const SelectTrailerModalTableItem = ({ trailer, truckId }) => {
  const dispatch = useDispatch();
  const [openLinkOffModal, setOpenLinkOffModal] = useState(false);
  const { manageTruckLoading, manageTruckResult } = useSelector((state) => state.trucks);

  const attachHandler = () => {
    const data = {
      truckId: truckId,
      trailer_id: trailer.id,
    };
    dispatch(manageTruck(data));
  };

  const detachHandler = () => {
    const data = {
      truckId: trailer.truck && trailer.truck.id,
      trailer_id: null,
    };
    dispatch(manageTruck(data));
  };

  useEffect(() => {
    if (manageTruckResult) {
      dispatch(resetManageTruck());
      setOpenLinkOffModal(false);
    }
  }, [dispatch, manageTruckResult]);

  const findcurrentTruck = trailer && trailer.truck && trailer.truck.id === truckId;

  return (
    <Fragment>
      <RowField
        style={
          findcurrentTruck && {
            backgroundColor: "#eef2fa",
            borderLeft: "1px solid #2b353a",
          }
        }
      >
        <TrailerField
          icon={trailer?.type?.icon2}
          trailerType={trailer?.type?.name}
          volume={trailer.trailer}
        />
        <TextField label={trailer.registration_number || "-"} />
        <TextField label={trailer.volume_capacity || "-"} />
        <TextField label={trailer.weight_capacity || "-"} />
        <LinkedTruckField truck={trailer.truck} />
        <CenterField>
          {trailer.truck ? (
            <DLinkOffButton onClick={() => setOpenLinkOffModal(true)} />
          ) : (
            <DLinkToButton onClick={() => attachHandler()} />
          )}
        </CenterField>
      </RowField>
      <LinkOffTrailerModal
        loading={manageTruckLoading}
        isOpen={openLinkOffModal}
        isClose={setOpenLinkOffModal}
        onClick={detachHandler}
      />
    </Fragment>
  );
};

export default SelectTrailerModalTableItem;
