import React from "react";
import "./GPSTruckTable.scss";

import { useTranslation } from "react-i18next";

import TableHeader from "./../../Form/TableFields/TableHeader/TableHeader";
import TableWrapper from "./../../Form/TableFields/TableWrapper/TableWrapper";
import GPSTruckTableItem from "./GPSTruckTableItem/GPSTruckTableItem";

const GPSTruckTable = ({ trucks, matchSelect }) => {
  const { t } = useTranslation();
  let filteredValue = trucks;
  if (matchSelect.value === 2) {
    filteredValue = trucks.filter((truck) => truck.has_connected_device);
  }
  if (matchSelect.value === 3) {
    filteredValue = trucks.filter((truck) => !truck.has_connected_device);
  }

  return (
    <div id='GPSTruckTable'>
      <TableWrapper
        emptyText={t("tms.tables.noInfo.no trucks")}
        total={trucks?.length}
      >
        <TableHeader>
          <th>{t("tms.tables.titles.Truck list")}</th>
          <th>{t("tms.tables.titles.Company")}</th>
          <th>{t("tms.tables.titles.Registration number list")}</th>
          <th style={{ textAlign: "center" }}>
            {t("tms.tables.titles.Status")}
          </th>
          <th>{t("tms.tables.titles.Action")}</th>
        </TableHeader>

        <tbody>
          {filteredValue.map((truck) => (
            <GPSTruckTableItem key={truck.id} truck={truck} />
          ))}
        </tbody>
      </TableWrapper>
    </div>
  );
};

export default GPSTruckTable;
