import React from "react";
import "./ListingHeader.scss";

import { useTranslation } from "react-i18next";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import ListingSwitcher from "../ListingSwitcher/ListingSwitcher";
import DButton from "./../../../Form/DButtons/DButton/DButton";

const ListingHeader = ({ tab, setTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div id='ListingHeader'>
      <div className='ListingHeaderTitle'>
        <p>{t("tms.desc.Own Fleet")}</p>
        <h1>{t("tms.titles.Tracking View")}</h1>
      </div>
      <div className='linkToGPSPage'>
        <DButton
          onClick={() => {
            navigate("/dashboard/tms/tracker/GPS_tracking");
          }}
          rightIcon={<FiArrowRight />}
          title={t("tms.forms.buttons.Go to connect page")}
        />
      </div>
      <ListingSwitcher tab={tab} onClick={(item) => setTab(item)} />
    </div>
  );
};

export default ListingHeader;
