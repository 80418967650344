import React, { Fragment } from "react";
import "./HomeTitle.scss";

import { Typewriter, Cursor } from "react-simple-typewriter";

const HomeTitle = ({ children, description, animation }) => {
  return (
    <div id='HomeTitle' className='HomeTitle'>
      <div className='title'>
        {animation ? (
          <Fragment>
            <h1 className='typewritter'>
              {children}{" "}
              <Typewriter
                words={animation}
                loop={true}
                delaySpeed={1000}
                typeSpeed={200}
              />
              <Cursor />
            </h1>
            <div className='animatedTitle'>
              <h1 className='static-text'>{children} </h1>
              <div className='dynamic-text'>
                {animation.map((i, index) => (
                  <h1 key={index} className='item'>
                    {i}
                  </h1>
                ))}
              </div>
            </div>
          </Fragment>
        ) : (
          <h1>{children}</h1>
        )}
        {description && <p>{description}</p>}
      </div>
    </div>
  );
};

export default HomeTitle;
