import React from "react";
import "./JoinUsCarrier.scss";

import { useTranslation } from "react-i18next";

import HomeTitle from "../../Components/HomeTitle/HomeTitle";
import LinkBackground from "./../../Components/Links/LinkBackground/LinkBackground";

const JoinUsCarrier = () => {
  const { t } = useTranslation();

  return (
    <section id='JoinUsCarrier' className='JoinUsCarrier'>
      <div className='backgroundColor'>
        <div
          className='backgroundImage'
          style={{
            backgroundImage: `url(${`/assets/images/map/WorldMap.svg`})`,
          }}
        >
          <div
            className='backgroundImage two'
            style={{
              backgroundImage: `url(${`/assets/images/map/WorldMap.svg`})`,
            }}
          >
            <div className='wrapper'>
              <div className='container'>
                <HomeTitle
                  description={t(
                    "landing.titles.landingMainTitlesDesc.We're here to help you make the most of your logistics."
                  )}
                >
                  {t(
                    "landing.titles.landingMainTitles.Sign up and get started with Cargon"
                  )}
                </HomeTitle>

                <LinkBackground
                  title={t("landing.form.button.Join us now")}
                  to='/register'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinUsCarrier;
