import React from "react";
import "./CoverShipper.scss";

import { useTranslation } from "react-i18next";

import FadeTextAnimation from "../../../UI/Animations/FadeTextAnimation/FadeTextAnimation";

const CoverShipper = () => {
  const { t } = useTranslation();

  return (
    <section
      id='CoverShipper'
      className='CoverShipper'
      style={{
        backgroundImage: "url('/assets/images/static/cover12.jpg')",
      }}
    >
      <div className='blar'>
        <div className='wrapper'>
          <div className='container'>
            <div className='text'>
              <FadeTextAnimation
                text={t(
                  "landing.titles.landingMainTitles.Effortless shipping excellence"
                )}
              />
              {/* <FadeTextAnimation text='Unlock your full potential as a shipper with Cargon' /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoverShipper;
