import React, { useState, useEffect, Fragment } from "react";
import "./OfferForm.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { sendBid, resetSendBid } from "../../store/bidding/biddingSlice";

// import { FiTrash2 } from "react-icons/fi";

import DButton from "../../componentsDashboard/Form/DButtons/DButton/DButton";
// import BButtonDanger from "../Form/BButtonDanger/BButtonDanger";
import BCurrencyInput from "../Form/BCurrencyInput/BCurrencyInput";
// import DModal from "../../componentsDashboard/Modal/DModal/DModal";

const OfferForm = ({ token, order, currencies, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sendBidLoading, sendBidResult } = useSelector((state) => state.bidding);

  const [amount, setAmount] = useState("");
  const [currencyValue, setCurrencyValue] = useState(currencies?.[0] || null);
  // const [isRefuseModalOpen, setIsRefuseModalOpen] = useState(false);

  // const handleRefuse = () => {
  //   dispatch(
  //     sendBid({
  //       token,
  //       data: { refused: true },
  //     })
  //   );
  // };
  const handleSubmit = () => {
    dispatch(
      sendBid({
        orderId: order?.id,
        data: { amount, currency_id: currencyValue?.value.id, token },
        token
      })
    );
  };

  useEffect(() => {
    dispatch(resetSendBid());
    setAmount("");
  }, [dispatch, sendBidResult]);

  return (
    <Fragment>
      <div id='OfferForm'>
        <BCurrencyInput
          id='OfferFormPrice'
          label={t("tms.forms.labels.Your price")}
          value={amount}
          setValue={setAmount}
          placeholder={t("tms.forms.placeholder.Your price")}
          required
          error={null}
          currencyValue={currencyValue}
          setCurrencyValue={setCurrencyValue}
          options={currencies}
          loading={loading}
        />
        <div className='offerFormButtons'>
          {/* <BButtonDanger
            title='Refuse'
            danger
            onClick={() => setIsRefuseModalOpen(true)}
          /> */}
          <DButton
            title={t("tms.forms.buttons.Send your offer")}
            onClick={() => handleSubmit()}
            loading={sendBidLoading}
          />
        </div>
      </div>
      {/* <DModal
        isOpen={isRefuseModalOpen}
        isClose={() => setIsRefuseModalOpen(false)}
        onClick={handleRefuse}
        title='Refuse offer'
        submitText='Refuse'
        icon={<FiTrash2 />}
        status='danger'
        loading={sendBidLoading}
      >
        <p>Are you sure you want to refuse offer?</p>
      </DModal> */}
    </Fragment>
  );
};

export default OfferForm;
