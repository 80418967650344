import React, { Fragment, useState } from "react";
import "./DocumentsDetails.scss";

import { useTranslation } from "react-i18next";

import { MdAttachFile } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";

import DButton from "../../Form/DButtons/DButton/DButton";
import DocumentsDetailsItem from "./components/DocumentsDetailsItem/DocumentsDetailsItem";
import AddNewDocumentModal from "./components/AddNewDocumentModal/AddNewDocumentModal";

const DocumentsDetails = ({ order }) => {
  const { t } = useTranslation();

  const [openCreateModal, setOpenCreateModal] = useState(false);

  return (
    <Fragment>
      <div id='DocumentsDetails'>
        <Fragment>
          <div className='DocumentsDetailsHeader'>
            <div className='icon'>
              <IoDocumentText />
            </div>
            <div className='titleContainer'>
              <h1>{t("tms.titles.Documents")}</h1>
              <DButton
                title={t("tms.forms.buttons.Upload")}
                rightIcon={<MdAttachFile />}
                onClick={() => {
                  setOpenCreateModal(true);
                }}
              />
            </div>
          </div>
          {order.attachments.length === 0 ? (
            <p>{t("tms.desc.No documents")}</p>
          ) : (
            <ul className='documentList'>
              {order.attachments.map((document) => (
                <DocumentsDetailsItem key={document.id} document={document} orderId={order.id} />
              ))}
            </ul>
          )}
        </Fragment>
      </div>

      {openCreateModal && (
        <AddNewDocumentModal
          isOpen={openCreateModal}
          isClose={() => setOpenCreateModal(false)}
          order={order}
        />
      )}
    </Fragment>
  );
};

export default DocumentsDetails;
