import React from "react";
import "./LogoContainer.scss";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Logo from "../../../../componentsAdditional/SVG/Cargon/Logo";

const LogoContainer = () => {
  const { openSidebar } = useSelector((state) => state.userUI);

  return (
    <div id='DLogoContainer' className={openSidebar ? "max" : "min"}>
      <Link to='/dashboard/tms/home'>
        <div className='DLogoContainerWrapper'>
          <Logo />
        </div>
      </Link>
    </div>
  );
};

export default LogoContainer;
