import React, { Fragment, useState } from "react";
import "./PreferencesCover.scss";

import { useTranslation } from "react-i18next";
import { HiSparkles } from "react-icons/hi2";
import { FaArrowRight } from "react-icons/fa6";

import DGradientButton from "../../Form/DButtons/DGradientButton/DGradientButton";
import DButton from "../../Form/DButtons/DButton/DButton";
import PreferencesModal from "../Modals/PreferencesModal/PreferencesModal";

const PreferencesCover = ({ setDoItLater }) => {
  const { t } = useTranslation();
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);

  return (
    <Fragment>
      <div id='PreferencesCover'>
        <div
          className='background'
          style={{ backgroundImage: `url(/assets/images/dashboard/preferencesBackground.svg)` }}
        />
        <div className='cover'>
          <div
            className='coverImage'
            style={{ backgroundImage: `url(/assets/images/dashboard/preferencesCover.svg)` }}
          />
          <div className='desc'>
            <div className='descTitle'>{t("tms.desc.Help Us Match You")}</div>
            <div className='descText'>
              {t("tms.desc.Enter your preferences so we can match you with the right shipments.")}
            </div>
          </div>
          <div className='buttonsContainer'>
            <DButton
              title={t("tms.forms.buttons.Do it later")}
              onClick={() => setDoItLater(true)}
              rightIcon={<FaArrowRight />}
            />
            <DGradientButton
              title={t("tms.forms.buttons.Set preferences")}
              onClick={() => setShowPreferencesModal(true)}
              rightIcon={<HiSparkles />}
            />
          </div>
        </div>
      </div>
      {showPreferencesModal && (
        <PreferencesModal
          isOpen={showPreferencesModal}
          isClose={() => setShowPreferencesModal(false)}
        />
      )}
    </Fragment>
  );
};

export default PreferencesCover;
