import React from "react";

import { useTranslation } from "react-i18next";
import Wrapper from "../../../../componentsAdditional/Wrapper";
import NotificationList from "./../../../../componentsDashboard/Notifications/NotificationList/NotificationList";

const Notifications = () => {
  const { t } = useTranslation();
  return (
    <Wrapper documentTitle={t("tms.documentTitles.Notifications")}>
      <NotificationList />
    </Wrapper>
  );
};

export default Notifications;
