import React from "react";
import "./NotHaveAccount.scss";

import { Link } from "react-router-dom";

const NotHaveAccount = ({ desc, link, text }) => {
  return (
    <div id='NotHaveAccount'>
      {desc && <p>{desc}</p>}
      {link && text && (
        <Link to={link}>
          <div className='line'></div>
          {text}
        </Link>
      )}
    </div>
  );
};

export default NotHaveAccount;
