import React from "react";
import "./RejectQuotationField.scss";

import { useMoney } from "../../../../../helper/useMoney";

import { FiX } from "react-icons/fi";

const RejectQuotationField = ({ order }) => {
  const price = useMoney(order?.revenue || 0, order?.revenue_currency?.symbol || "$");

  return (
    <td id='RejectQuotationField'>
      <div className='container'>
        <div className='text'>{price}</div>
        <div className='icon'>
          <FiX />
        </div>
      </div>
    </td>
  );
};

export default RejectQuotationField;
