import React from "react";
import "./ForCustomers.scss";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import HomeTitle from "../Components/HomeTitle/HomeTitle";
import CustomersItem from "./../Components/CustomersItem/CustomersItem";

const ForCustomers = () => {
  const { t } = useTranslation();

  return (
    <section id='ForCustomers' className='ForCustomers'>
      <div className='wrapper'>
        <HomeTitle>
          {parse(
            t(
              "landing.titles.landingMainTitles.Ship or haul with <span>Cargon</span>"
            )
          )}
        </HomeTitle>
        <div className='container'>
          <div
            data-aos='fade-right'
            data-aos-duration='1500'
            data-aos-delay='100'
            data-aos-once='true'
          >
            <CustomersItem
              url='/assets/images/static/cover12.jpg'
              title={t("landing.titles.landingForCustomersTitle.For shippers")}
              content={t(
                "landing.bodyText.landingForCustomersBody.Transparent pricing, actionable insights, and optimization strategies, Cargon gives you the tools to transform your business."
              )}
              to='/shipper'
              buttonTitle={t("landing.form.button.Learn more")}
            />
          </div>
          <div
            data-aos='fade-left'
            data-aos-duration='1500'
            data-aos-delay='100'
            data-aos-once='true'
          >
            <CustomersItem
              url='/assets/images/static/cover4.jpg'
              title={t("landing.titles.landingForCustomersTitle.For carriers")}
              content={t(
                "landing.bodyText.landingForCustomersBody.With upfront pricing and instant booking, Cargon keeps your trucks full and maximizes your income."
              )}
              to='/carrier'
              buttonTitle={t("landing.form.button.Learn more")}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForCustomers;
