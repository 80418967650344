import React, { useState, useEffect } from "react";
import "./FilterModal.scss";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { IoFilter } from "react-icons/io5";
import { FiX } from "react-icons/fi";

import DOverlay from "./../../../Modal/DOverlay/DOverlay";
import DSearch from "../../../FilterForms/DSearch/DSearch";
import FilterDateRangeForm from "./../../../FilterForms/FilterDateRangeForm/FilterDateRangeForm";
import FilterRouteSelectForm from "../../../FilterForms/FilterRouteSelectForm/FilterRouteSelectForm";
import FilterProductCategorySelect from "./../../../FilterForms/FilterProductCategorySelect/FilterProductCategorySelect";
import FilterTrailerSelect from "./../../../FilterForms/FilterTrailerSelect/FilterTrailerSelect";
import FilterStatusesClips from "../../../FilterForms/FilterStatusesClips/FilterStatusesClips";
import FilterShippingType from "../../../FilterForms/FilterShippingType/FilterShippingType";

const FilterModal = ({
  isOpen,
  isClose,
  keyword,
  setKeyword,
  status,
  setStatus,
  shippingType,
  setShippingType,
  dateRange,
  setDateRange,
  route,
  setRoute,
  trailerType,
  setTrailerType,
  productCategory,
  setProductCategory,
  onClear,
}) => {
  const { t } = useTranslation();

  const [keywordValue, setKeywordValue] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setKeyword(keywordValue);
    }, 500);
    return () => clearInterval(timeout);
  }, [keywordValue, setKeyword]);

  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <div id='ActiveShipmentSideFilter'>
        <div className='ActiveShipmentSideFilterHeader'>
          <div className='title'>
            <div className='titleIcon'>
              <IoFilter />
            </div>
            <h2>{t("shipper.filters.Filter")}:</h2>
          </div>
          <div className='closeIcon' onClick={isClose}>
            <FiX />
          </div>
        </div>
        <div className='ActiveShipmentSideFilterContent'>
          <div className='DSearchGroup'>
            <DSearch value={keywordValue} setValue={(e) => setKeywordValue(e)} />
          </div>
          <div className='selectGroup'>
            <FilterDateRangeForm
              label={t("tms.filters.placeholders.Date range")}
              value={
                Object.keys(dateRange).length > 0
                  ? `${
                      dateRange.startDate ? moment(dateRange.startDate).format("DD/MM/YYYY") : ""
                    } - ${dateRange.endDate ? moment(dateRange.endDate).format("DD/MM/YYYY") : ""}`
                  : ""
              }
              setValue={setDateRange}
            />

            <FilterRouteSelectForm
              value={
                Object.keys(route).length > 0
                  ? `${route?.fromCountry?.value?.code || ""} - ${
                      route?.toCountry?.value?.code || ""
                    }`
                  : ""
              }
              setValue={setRoute}
            />

            <FilterProductCategorySelect value={productCategory} setValue={setProductCategory} />

            <FilterTrailerSelect value={trailerType} setValue={setTrailerType} />
          </div>

          <div className='FilterShippingTypeGroup'>
            <FilterShippingType shippingType={shippingType} setShippingType={setShippingType} />
          </div>
          <div className='FilterStatusesClipsGroup'>
            <FilterStatusesClips status={status} setStatus={setStatus} />
          </div>
        </div>
        <div className='ActiveShipmentSideFilterFooter'>
          <div className='cancelTbn' onClick={isClose}>
            {t("tms.modals.buttons.Cancel")}
          </div>
          <div
            className='clearBtn'
            onClick={() => {
              onClear();
              isClose();
              setKeywordValue("");
            }}
          >
            {t("shipper.filters.Clear all")}
          </div>
        </div>
      </div>
    </DOverlay>
  );
};

export default FilterModal;
