import React from "react";
import "./Finance.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import CoverFinance from "../../../../componentsLanding/Landing/Covers/CoverFinance/CoverFinance";

const Finance = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Finance")}>
      <CoverFinance />
    </Wrapper>
  );
};

export default Finance;
