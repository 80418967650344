import React, { useState } from "react";
import "./FilterDateRangeForm.scss";

import { useTranslation } from "react-i18next";

import DDropdown from "../DDropdown/DDropdown";
import DButton from "../../Form/DButtons/DButton/DButton";
import DDatepicker from "../../Form/DDatepicker/DDatepicker";

const FilterDateRangeForm = ({ value, setValue, label }) => {
  const { t } = useTranslation();

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <div id='FilterDateRangeForm'>
      <DDropdown
        value={value}
        setValue={setValue}
        placeholder={label}
        dropdownOpen={dropDownOpen}
        setDropdownOpen={setDropDownOpen}
      >
        <div className='dropdownContentContainer'>
          <DDatepicker
            value={startDate}
            setValue={setStartDate}
            placeholder={t("tms.filters.placeholders.Choose date")}
            label={t("tms.filters.labels.Start date")}
          />
          <DDatepicker
            label={t("tms.filters.labels.End date")}
            value={endDate}
            setValue={setEndDate}
            placeholder={t("tms.filters.placeholders.Choose date")}
          />
          <div className='saveButton'>
            <DButton
              title={t("tms.filters.buttons.Save")}
              disabled={!startDate || !endDate}
              onClick={() => {
                setValue({ startDate, endDate });
                setDropDownOpen(false);
              }}
            />
          </div>
        </div>
      </DDropdown>
    </div>
  );
};

export default FilterDateRangeForm;
