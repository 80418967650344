import React, { Fragment, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteAttachment,
  resetDeleteAttachment,
} from "../../../../../store/orders/attachments/attachmentsSlice";

import DModal from "../../../../Modal/DModal/DModal";

const DeleteDocumentModal = ({ isOpen, isClose, id, documentId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { deleteAttachmentLoading, deleteAttachmentResult } = useSelector(
    (state) => state.attachments
  );

  const submitHandler = () => {
    const data = {
      id: id,
      fileId: documentId,
    };
    dispatch(deleteAttachment(data));
  };

  useEffect(() => {
    if (deleteAttachmentResult) {
      isClose();
      dispatch(resetDeleteAttachment());
    }
  }, [dispatch, isClose, deleteAttachmentResult]);

  useEffect(() => {
    return () => {
      dispatch(resetDeleteAttachment());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={isClose}
        onClick={submitHandler}
        title={t("tms.modals.titles.Delete document")}
        submitText={t("tms.modals.buttons.Delete")}
        icon={<FiTrash2 />}
        status='danger'
        loading={deleteAttachmentLoading}
      >
        <p>{t("tms.modals.desc.Are you sure you want to delete your document?")}</p>
      </DModal>
    </Fragment>
  );
};

export default DeleteDocumentModal;
