import React, { Fragment } from "react";
import "./FilterStatusesClips.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const FilterStatusesClips = ({ status, setStatus }) => {
  const { t } = useTranslation();
  const { getClientStatusesResult } = useSelector((state) => state.orderStatuses);

  const statuses = getClientStatusesResult && [
    { id: null, name: t("shipper.filters.All") },
    ...getClientStatusesResult,
  ];

  return (
    <Fragment>
      {getClientStatusesResult && (
        <div id='FilterStatusesClips'>
          {statuses.map((type, index) => (
            <div
              key={index}
              className={status === type.id ? "status active" : "status"}
              onClick={() => {
                setStatus(type?.id);
              }}
            >
              <h4>{type?.name || ""}</h4>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default FilterStatusesClips;
