import "./ProfileInfoItem.scss";

import Character from "./../../../../UI/Character/Character";

const ProfileInfoItem = ({ icon, text, max, button }) => {
  return (
    <div id='profileInfoItem'>
      <div className='icon'>{icon}</div>
      <div className='text'>
        {text ? <Character title={text} max={max ? max : 35} /> : button || ""}
      </div>
    </div>
  );
};
export default ProfileInfoItem;
