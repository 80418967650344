import React from "react";
import "./SignUpCompanyVerify.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import SignUpCompanyVerifyForm from "./../../../../componentsLanding/Auth&Password/SignUpForms/SignUpCompanyVerifyForm/SignUpCompanyVerifyForm";

const SignUpCompanyVerify = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Sign Up carrier company")}>
      <section id='SignUpCompanyVerify'>
        <AuthCover url='/assets/images/static/cover24.jpg' />
        <SignUpCompanyVerifyForm />
      </section>
    </Wrapper>
  );
};

export default SignUpCompanyVerify;
