import React from "react";
import "./ProductDetailsShipper.scss";

import { useTranslation } from "react-i18next";

import { useMoney } from "../../../../helper/useMoney";

import { BsBoxSeam } from "react-icons/bs";

import LandProductDetails from "./LandProductDetails/LandProductDetails";
import OceanProductDetails from "./OceanProductDetails/OceanProductDetails";
import AirProductDetails from "./AirProductDetails/AirProductDetails";
import { useConvertShippingType } from "../../../../helper/useConvertShippingType";

const ProductDetailsShipper = ({ getOrderResult }) => {
  const { t } = useTranslation();
  const shippingType = useConvertShippingType(getOrderResult?.type || null);

  const orderPrice =
    getOrderResult?.cargos?.[0]?.price &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMoney(
      getOrderResult?.cargos?.[0]?.price,
      getOrderResult?.cargos?.[0]?.price_currency?.symbol
    );

  return (
    <div id='ProductDetailsShipper'>
      <div className='titleContainer'>
        <div className='leftSide'>
          <div className='icon'>
            <BsBoxSeam />
          </div>
          <h1 className='title'>{t("shipper.forms.titles.Product")}</h1>
        </div>
        <div className='rightSide'>{orderPrice}</div>
      </div>
      <div className='items'>
        {shippingType.shippingTypeId === 1 &&
          getOrderResult?.cargos?.map((cargo, index) => (
            <LandProductDetails
              key={index}
              cargo={cargo}
              index={getOrderResult?.cargos?.length > 1 ? index + 1 : null}
            />
          ))}
        {shippingType.shippingTypeId === 2 &&
          getOrderResult?.cargos?.map((cargo, index) => (
            <AirProductDetails
              key={index}
              cargo={cargo}
              index={getOrderResult?.cargos?.length > 1 ? index + 1 : null}
            />
          ))}
        {shippingType.shippingTypeId === 3 &&
          getOrderResult?.cargos?.map((cargo, index) => (
            <OceanProductDetails
              key={index}
              cargo={cargo}
              index={getOrderResult?.cargos?.length > 1 ? index + 1 : null}
            />
          ))}
      </div>
    </div>
  );
};

export default ProductDetailsShipper;
