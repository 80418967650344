import React from "react";
import "./FaqItem.scss";

import parse from "html-react-parser";
import { FiChevronRight } from "react-icons/fi";
import { BsCircleFill } from "react-icons/bs";

const FaqItem = ({ item, onChange, index, openIndex }) => {
  return (
    <div
      className='animation'
      data-aos='fade-right'
      data-aos-duration='1500'
      data-aos-once='true'
      data-aos-delay='0'
    >
      <div id='FaqItem' className={index === openIndex ? "active" : ""}>
        <div
          className='header'
          onClick={() => {
            onChange(index);
          }}
        >
          <div className='title'>
            <div className='icon'>
              <BsCircleFill />
            </div>

            <h3>{item.title}</h3>
          </div>

          <div className='arrow'>
            <FiChevronRight />
          </div>
        </div>
        <div className='bodydescription'>
          <div className='content'>{parse(item.body)}</div>
        </div>
      </div>
    </div>
  );
};

export default FaqItem;
