import React from "react";
import "./ShipperCalendar.scss";

import { useSelector } from "react-redux";

import ShipperCalendarHeader from "./ShipperCalendarHeader/ShipperCalendarHeader";
import ShipperCalendarTable from "./ShipperCalendarTable/ShipperCalendarTable";
import LoadingPage from "../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const ShipperCalendar = ({ startDate }) => {
  const { getShipperCalendarLoading, getShipperCalendarResult } = useSelector(
    (state) => state.shipperCalendar
  );
  return (
    <div id='ShipperCalendar'>
      <ShipperCalendarHeader startDate={startDate} />
      {getShipperCalendarLoading ? (
        <LoadingPage />
      ) : (
        <ShipperCalendarTable startDate={startDate} orders={getShipperCalendarResult} />
      )}
    </div>
  );
};

export default ShipperCalendar;
