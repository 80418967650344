import React, { Fragment, useState, useEffect } from "react";
import "./ActivityTable.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getShipperActiveOrders,
  resetGetShipperActiveOrders,
} from "../../../store/analytics/analyticsSlice";

import ActivityTableSwitcher from "./ActivityTableSwitcher/ActivityTableSwitcher";
import ActiveShipmentsTable from "./ActiveShipmentsTable/ActiveShipmentsTable";
import LoadingPage from "./../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
// import FeedTable from "./FeedTable/FeedTable";

const ActivityTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getShipperActiveOrdersLoading,
    getShipperActiveOrdersResult,
    getShipperActiveOrdersMeta,
  } = useSelector((state) => state.analytics);

  const [active, setActive] = useState("activityFeed");

  useEffect(() => {
    const data = {
      page: 1,
      status_id: [1, 2, 3],
    };
    dispatch(getShipperActiveOrders(data));
    return () => dispatch(resetGetShipperActiveOrders());
  }, [dispatch]);

  return (
    <div id='ActivityTable'>
      {getShipperActiveOrdersLoading && <LoadingPage />}
      {getShipperActiveOrdersResult && getShipperActiveOrdersMeta && (
        <Fragment>
          <div className='ActivityTableHeader'>
            <ActivityTableSwitcher
              feedBadge={getShipperActiveOrdersMeta?.total || 0}
              shipmentsBadge={getShipperActiveOrdersMeta?.total || 0}
              active={active}
              setActive={setActive}
            />
            <div className='SeeAllButton'>
              <Link to='/dashboard/shipper/accepted'>{t("shipper.buttons.See all")}</Link>
            </div>
          </div>
          <ActiveShipmentsTable
            orders={getShipperActiveOrdersResult}
            total={getShipperActiveOrdersMeta.total}
          />
        </Fragment>
      )}
      {/* {active === "activityFeed" ? (
        <FeedTable orders={orders} />
      ) : ( )}  */}
    </div>
  );
};

export default ActivityTable;
