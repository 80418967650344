import React, { useState, Fragment } from "react";
import "./OrderDetailsHeader.scss";

import { useTranslation } from "react-i18next";

import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import DTextButton from "../../Form/DButtons/DTextButton/DTextButton";
import DBackButton from "../../Form/DButtons/DBackButton/DBackButton";
import DeleteOrder from "../DeleteOrder/DeleteOrder";

const OrderDetailsHeader = ({ order, Edit, Delete }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <Fragment>
      <div id='OrderDetailsHeader'>
        <DBackButton
          title={`${t("tms.forms.buttons.Back")} | ${order?.tracking || ""}`}
          alt='/dashboard/tms/orders?page=1'
        />

        <div className='right'>
          {Edit && (
            <DTextButton
              title={t("tms.forms.buttons.edit")}
              onClick={() => {
                navigate(`/dashboard/tms/orders/edit/${order.id}`);
              }}
              leftIcon={<FiEdit2 />}
            />
          )}
          {Delete && (
            <DTextButton
              danger
              title={t("tms.forms.buttons.delete")}
              onClick={() => {
                setOpenDeleteModal(true);
              }}
              leftIcon={<FiTrash2 />}
            />
          )}
        </div>
      </div>
      <DeleteOrder
        orderId={order.id}
        isOpen={openDeleteModal}
        isClose={() => setOpenDeleteModal(false)}
      />
    </Fragment>
  );
};

export default OrderDetailsHeader;
