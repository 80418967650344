import React from "react";

import { useTranslation } from "react-i18next";

import DSelect from "./../DSelect";
import { useOrderPaymentSelectSubOptions } from "./../../../../helper/useOrderPaymentSelectSubOptions";

const DPaymentSubOptions = ({ value, setValue, error }) => {
  const { t } = useTranslation();

  const paymentSubOptions = useOrderPaymentSelectSubOptions();

  return (
    <DSelect
      id='selectPaymentMethodAddOrderPaymentSubTypes'
      value={value}
      label={t("tms.forms.labels.Condition")}
      placeholder={t("tms.forms.placeholder.Choose Condition")}
      setValue={(e) => {
        setValue(e);
      }}
      options={paymentSubOptions ? paymentSubOptions : []}
      required
      error={error}
    />
  );
};

export default DPaymentSubOptions;
