import React, { Fragment, useState } from "react";
import "./AssignCarrier.scss";

import { useTranslation } from "react-i18next";

import { IoIosWarning } from "react-icons/io";
import { IoSend } from "react-icons/io5";

import DButton from "./../../../Form/DButtons/DButton/DButton";
import AssignCarrierModal from "./../AssignCarrierModal/AssignCarrierModal";
import PartnerListItem from "./PartnerListItem";

const AssignCarrier = ({ order }) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [bidScreen, setBidScreen] = useState(false);

  // "ownFleet" : "partnerFleet"
  const [tab, setTab] = useState("ownFleet");
  // "assign"  "bid"
  const [partnerTab, setPartnerTab] = useState("assign");
  const [partnersBids, setPartnersBids] = useState([]);

  return (
    <Fragment>
      {!order?.transports?.find((i) => i?.truck) && !order.partner && (
        <Fragment>
          <div id='AssignCarrier'>
            <div className='assignCarrierContainer'>
              <div className='buttonContainer'>
                <DButton
                  title={t("tms.forms.buttons.Assign truck / partner")}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                />
                <div className='date'>
                  <div className='icon'>
                    <IoIosWarning />
                  </div>
                  <div className='description'>
                    {t("tms.desc.Assign carrier until")}{" "}
                    <span>{order.cargos[0]?.pickup_date || ""}</span>
                  </div>
                </div>
              </div>

              <div className='sendBidContainer'>
                {order.bids && (
                  <Fragment>
                    {order.bids.filter((bid) => bid.partner !== null).length > 0 && (
                      <div className='partnerListContainer'>
                        <ul className='partnerList'>
                          {order.bids
                            .filter((bid) => bid.partner !== null)
                            .slice(0, 2)
                            .map((item) => {
                              return (
                                <PartnerListItem
                                  key={item.id}
                                  item={item}
                                  onClick={() => {
                                    setOpenModal(true);
                                    setTab("partnerFleet");
                                    setPartnerTab("assign");
                                  }}
                                />
                              );
                            })}
                        </ul>

                        {order.bids.filter((bid) => bid.partner !== null).length > 2 && (
                          <DButton
                            title={`${t("tms.forms.buttons.See more")} (${
                              order.bids.filter((bid) => bid.partner !== null).length - 2
                            })`}
                            onClick={() => {
                              setOpenModal(true);
                              setTab("partnerFleet");
                              setPartnerTab("assign");
                            }}
                          />
                        )}
                      </div>
                    )}
                  </Fragment>
                )}
                <div className='sendBidToPartner'>
                  <DButton
                    title={t("tms.forms.buttons.Auction to partners")}
                    rightIcon={<IoSend />}
                    onClick={() => {
                      setOpenModal(true);
                      setTab("partnerFleet");
                      setPartnerTab("bid");
                    }}
                  />
                  <div className='sendBidText'>
                    <h3>{t("tms.desc.Reach your partners by one click")}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {openModal && (
            <AssignCarrierModal
              bidScreen={bidScreen}
              setBidScreen={setBidScreen}
              order={order}
              isOpen={openModal}
              isClose={() => setOpenModal(false)}
              tab={tab}
              setTab={setTab}
              partnerTab={partnerTab}
              setPartnerTab={setPartnerTab}
              partnersBids={partnersBids}
              setPartnersBids={setPartnersBids}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default AssignCarrier;
