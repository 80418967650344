import React from "react";
import "./SignUp.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import SignUpForm from "./../../../../componentsLanding/Auth&Password/SignUpForms/SignUpForm/SignUpForm";

const SignUp = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Sign Up")}>
      <section id='SignUp'>
        <AuthCover url='/assets/images/static/cover22.jpg' />
        <SignUpForm />
      </section>
    </Wrapper>
  );
};

export default SignUp;
