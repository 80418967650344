import React from "react";
import { Alert, Snackbar } from "@mui/material";

// statuses
// error
// warning
// info
// success

const SnackBar = ({ status, text, isOpen, isClose }) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={5000}
      onClose={() => isClose(false)}
    >
      <Alert severity={status} onClose={() => isClose(false)}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
