import React from "react";
import "./NoMoreData.scss";

import { GoDotFill } from "react-icons/go";

const NoMoreData = () => {
  return (
    <div id='NoMoreDataInfiniteScroll'>
      <div className='icon'>
        <GoDotFill />
      </div>
    </div>
  );
};

export default NoMoreData;
