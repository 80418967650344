import React from "react";
import "./DetailsFreightSea.scss";

import { useTranslation } from "react-i18next";

import DetailsFreightItem from "../Components/DetailsFreightItem/DetailsFreightItem";

const DetailsFreightSea = () => {
  const { t } = useTranslation();

  return (
    <section id='DetailsFreightSea' className='DetailsFreightSea'>
      <div className='wrapper'>
        <div className='DetailsFreightSeaContainer'>
          <DetailsFreightItem
            cover='/assets/images/static/cover35.svg'
            title={t(
              "landing.titles.landingDetailsFreightTitle.Sea freight solution"
            )}
            content={t(
              "landing.bodyText.landingDetailsFreightBody.Explore our full range of freight services, from FCL to LCL shipment to pre- and on-carriage, customs and insurance options."
            )}
            delay={400}
          />
          <DetailsFreightItem
            cover='/assets/images/static/cover36.svg'
            title={t(
              "landing.titles.landingDetailsFreightTitle.Full visibility"
            )}
            content={t(
              "landing.bodyText.landingDetailsFreightBody.Track vessels and containers with Cargon’s platform, offering worldwide visibility and control."
            )}
            delay={250}
          />
          <DetailsFreightItem
            cover='/assets/images/static/cover37.svg'
            title={t(
              "landing.titles.landingDetailsFreightTitle.Powerful data insights"
            )}
            content={t(
              "landing.bodyText.landingDetailsFreightBody.Analyse transit times and costs, optimising container use for efficient shipping. Leverage supply chain data for a competitive edge."
            )}
            delay={100}
          />
        </div>
      </div>
    </section>
  );
};

export default DetailsFreightSea;
