import React, { useEffect, useLayoutEffect, useState } from "react";

import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setLang } from "../store/additional/lang/langSlice";

import { setCountryId } from "../store/additional/settings/settingsSlice";

const BrowserRouterBasename = ({ children }) => {
  const dispatch = useDispatch();
  const { ISO } = useSelector((state) => state.lang);
  const { getCountriesResult } = useSelector((state) => state.settings);
  const [basename, setBasename] = useState(
    window?.location?.pathname?.split("/")?.[1]
  );

  const pathname = window?.location?.pathname;
  const urlLang = pathname?.split("/")?.[1]?.split("_")?.[1];
  const urlCountry = pathname?.split("/")?.[1]?.split("_")?.[0];

  const isLocaleExist =
    pathname?.split("/")?.[1]?.length === 5 && pathname?.includes("_");

  useLayoutEffect(() => {
    if (!isLocaleExist) {
      window.location.replace(`/ge_en${pathname}`);
    }
  }, [isLocaleExist, pathname]);

  useEffect(() => {
    if (getCountriesResult && urlCountry) {
      const countryCode = getCountriesResult?.find(
        (item) => item?.country?.code?.toLowerCase() === urlCountry
      );
      if (countryCode) {
        setBasename(
          (prev) =>
            `${countryCode?.country?.code?.toLowerCase()}_${
              prev?.split("_")?.[1]
            }`
        );
        dispatch(setCountryId(countryCode?.id));
      }
    }
  }, [getCountriesResult, dispatch, urlCountry]);

  useEffect(() => {
    if (ISO && urlLang) {
      if (ISO !== urlLang) {
        dispatch(setLang(urlLang));
        setBasename((prev) => `${prev?.split("_")?.[0]}_${urlLang}`);
      } else {
        setBasename((prev) => `${prev?.split("_")?.[0]}_${ISO}`);
      }
    }
  }, [ISO, dispatch, urlLang]);

  return <BrowserRouter basename={basename}>{children}</BrowserRouter>;
};

export default BrowserRouterBasename;
