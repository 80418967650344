import _request from "./../_request";

const getCustomers = async (ISO, TOKEN, data) => {
  let url = "/tms-client?";
  const { page, keyword } = data || {};
  url += page ? `&page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getCustomer = async (ISO, TOKEN, id) => {
  const config = {
    url: `/tms-client/${id}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createCustomer = async (ISO, TOKEN, data) => {
  const config = {
    url: "/tms-client",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateCustomer = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `/tms-client/${id}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteCustomer = async (ISO, TOKEN, id) => {
  const config = {
    url: `/tms-client/${id}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const customerService = {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer,
  deleteCustomer,
};

export default customerService;
