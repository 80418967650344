import React, { Fragment, useState } from "react";
import "./NeedMoreInfo.scss";

import { useTranslation } from "react-i18next";

import TalkExpert from "../Components/TalkExpert/TalkExpert";

const NeedMoreInfo = () => {
  const { t } = useTranslation();
  const [openTalkExpert, setOpenTalkExpert] = useState(false);

  return (
    <Fragment>
      <div
        id='NeedMoreInfo'
        style={{ backgroundImage: `url(${`/assets/images/map/WorldMap.svg`})` }}
      >
        <div className='textContainer'>
          <h1>{t("landing.titles.landingMainTitles.Need more information?")}</h1>
          <p>
            {t(
              "landing.titles.landingMainTitlesDesc.We’re here to help! Simply provide your contact details, and one of our team members will get in touch with you shortly."
            )}
          </p>
        </div>
        <div className='submit' onClick={() => setOpenTalkExpert(true)}>
          {t("landing.form.button.Talk to an expert")}
        </div>
      </div>
      <TalkExpert open={openTalkExpert} setOpen={setOpenTalkExpert} />
    </Fragment>
  );
};

export default NeedMoreInfo;
