import React, { useEffect, useState } from "react";
import "./FAQ.scss";

import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getFAQ } from "./../../../../../store/additional/rules/rulesSlice";

import Wrapper from "./../../../../../componentsAdditional/Wrapper";
import FaqItem from "./../../../../../componentsLanding/Landing/FAQs/Components/FaqItem/FaqItem";

const FAQ = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getFAQResult } = useSelector((state) => state.rules);

  const searchType = searchParams.get("filter");

  const [type, setType] = useState(searchType ? searchType : "general");
  const [openIndex, setOpenIndex] = useState();

  const changedHandler = (index) =>
    setOpenIndex(index === openIndex ? null : index);

  const submitHandler = (type) => {
    setType(type);
    setSearchParams(`?filter=${type}`);
  };

  useEffect(() => {
    dispatch(getFAQ());
  }, [dispatch]);

  return (
    <Wrapper documentTitle={t("landing.documentTitles.FAQ")}>
      <section id='FAQ'>
        <div className='wrapper'>
          {getFAQResult && (
            <div className='container'>
              <div className='FAQtitle'>
                <h1>{t("landing.titles.landingMainTitles.FAQ")}</h1>
              </div>
              <div className='body'>
                <div className='filter'>
                  <ul className='filterList'>
                    <li
                      onClick={() => submitHandler("general")}
                      className={type === "general" ? "active" : ""}
                    >
                      {t("landing.titles.landingFaqTitle.General")}
                    </li>
                    <li
                      onClick={() => submitHandler("shipper")}
                      className={type === "shipper" ? "active" : ""}
                    >
                      {t("landing.titles.landingFaqTitle.Shipper")}
                    </li>
                    <li
                      onClick={() => submitHandler("carrier")}
                      className={type === "carrier" ? "active" : ""}
                    >
                      {t("landing.titles.landingFaqTitle.Carrier")}
                    </li>
                  </ul>
                </div>
                <div className='FAQcontainer'>
                  {getFAQResult &&
                    getFAQResult?.filter((FAQitem) => FAQitem[type]).map(
                      (item, index) => (
                        <FaqItem
                          key={index}
                          item={item}
                          index={index}
                          onChange={changedHandler}
                          openIndex={openIndex}
                        />
                      )
                    )}

                  <div className='stillQuestion'>
                    <div className='left'>
                      <h4>
                        {t(
                          "landing.titles.landingFaqTitle.Still have questions?"
                        )}
                      </h4>
                      <h5>
                        {t(
                          "landing.bodyText.landingFaqBody.Can’t find the answer you’re looking for? Please chat to our friendly team."
                        )}
                      </h5>
                    </div>
                    <div className='right'>
                      <a href='/contact'>
                        {" "}
                        {t("landing.form.button.Get in touch")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </Wrapper>
  );
};

export default FAQ;
