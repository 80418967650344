import React, { Fragment, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { MdLinkOff } from "react-icons/md";

import {
  unassignCarrier,
  resetUnassignCarrier,
} from "../../../../store/orders/manageOrder/manageOrderSlice";

import DModal from "../../../Modal/DModal/DModal";

const LinkOffCarrierModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { unassignCarrierResult, unassignCarrierLoading } = useSelector(
    (state) => state.manageOrder
  );

  const submitHandler = () => {
    dispatch(unassignCarrier(order.id));
  };

  useEffect(() => {
    if (unassignCarrierResult) {
      dispatch(resetUnassignCarrier());
      isClose();
    }
  }, [dispatch, isClose, unassignCarrierResult]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Unlink carrier")}
        submitText={t("tms.modals.buttons.Unlink")}
        icon={<MdLinkOff />}
        status='info'
        onClick={() => submitHandler()}
        loading={unassignCarrierLoading}
      >
        <p>
          {t("tms.modals.desc.Are you sure you want to unlink this carrier?")}
        </p>
      </DModal>
    </Fragment>
  );
};

export default LinkOffCarrierModal;
