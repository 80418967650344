import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { deleteCustomer, resetDeleteCustomer } from "./../../../../store/customer/customerSlice";

import DModal from "./../../../Modal/DModal/DModal";

const DeleteCustomerModal = ({ isOpen, isClose, id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { deleteCustomerLoading, deleteCustomerResult } = useSelector((state) => state.customer);

  const submitHandler = () => {
    dispatch(deleteCustomer(id));
  };

  useEffect(() => {
    if (deleteCustomerResult) {
      dispatch(resetDeleteCustomer());
      isClose();
      navigate("/dashboard/tms/customers_partners/customers");
    }
  }, [dispatch, navigate, isClose, deleteCustomerResult]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => submitHandler()}
      submitText={t("tms.modals.buttons.Delete")}
      title={t("tms.modals.titles.Delete Customer")}
      icon={<FiTrash2 />}
      status='danger'
      loading={deleteCustomerLoading}
    >
      <p>{t("tms.modals.desc.Are you sure you want to delete your customer?")}</p>
    </DModal>
  );
};

export default DeleteCustomerModal;
