import React from "react";
import "./RoadIcon.scss";

import { useTranslation } from "react-i18next";
import { BsBroadcast } from "react-icons/bs";

const RoadIcon = ({ check }) => {
  const { t } = useTranslation();

  return (
    <div id='truckGPSRoadIcon'>
      {check && (
        <div className='icon'>
          <BsBroadcast />
        </div>
      )}
      {check ? (
        <h5>{t("tms.desc.On road")}</h5>
      ) : (
        <h5>{t("tms.desc.No order")}</h5>
      )}
    </div>
  );
};

export default RoadIcon;
