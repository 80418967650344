import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteClientContact,
  resetDeleteClientContact,
} from "../../../../../store/customer/customerContacts/customerContactsSlice";

import DModal from "../../../../Modal/DModal/DModal";

const DeleteCustomerContactModal = ({ isOpen, isClose, id, contactId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { deleteClientContactLoading, deleteClientContactResult } = useSelector(
    (state) => state.customerContacts
  );

  const submitHandler = () => {
    dispatch(deleteClientContact({ id: id, contactId: contactId }));
  };

  useEffect(() => {
    if (deleteClientContactResult) {
      dispatch(resetDeleteClientContact());
      isClose();
    }
  }, [dispatch, isClose, deleteClientContactResult]);

  useEffect(() => {
    return () => {
      dispatch(resetDeleteClientContact());
    };
  }, [dispatch]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => submitHandler()}
      submitText={t("tms.modals.buttons.Delete")}
      title={t("tms.modals.titles.Delete contact")}
      icon={<FiTrash2 />}
      status='danger'
      loading={deleteClientContactLoading}
    >
      <p>{t("tms.modals.desc.Are you sure you want to delete contact?")}</p>
    </DModal>
  );
};

export default DeleteCustomerContactModal;
