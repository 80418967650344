import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userContactsService from "./userContactsService";

const initialState = {
  createContactLoading: false,
  createContactResult: null,
  createContactError: null,

  updateContactLoading: false,
  updateContactResult: null,
  updateContactError: null,

  deleteContactLoading: false,
  deleteContactResult: null,
  deleteContactError: null,
};

export const createContact = createAsyncThunk(
  "userContacts/createContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await userContactsService.createContact(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateContact = createAsyncThunk(
  "userContacts/updateContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await userContactsService.updateContact(
        ISO,
        TOKEN,
        data.id.toString(),
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteContact = createAsyncThunk(
  "userContacts/deleteContact",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await userContactsService.deleteContact(
        ISO,
        TOKEN,
        data.id.toString()
      );
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userContactsSlice = createSlice({
  name: "userContacts",
  initialState,
  reducers: {
    resetCreateContact: (state) => {
      state.createContactLoading = false;
      state.createContactResult = null;
      state.createContactError = null;
    },
    resetUpdateContact: (state) => {
      state.updateContactLoading = false;
      state.updateContactResult = null;
      state.updateContactError = null;
    },
    resetDeleteContact: (state) => {
      state.deleteContactLoading = false;
      state.deleteContactResult = null;
      state.deleteContactError = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(createContact.pending, (state) => {
        state.createContactLoading = true;
        state.createContactResult = null;
        state.createContactError = null;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.createContactLoading = false;
        state.createContactResult = action.payload;
        state.createContactError = null;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.createContactLoading = false;
        state.createContactResult = null;
        state.createContactError = action.payload;
      })

      .addCase(updateContact.pending, (state) => {
        state.updateContactLoading = true;
        state.updateContactResult = null;
        state.updateContactError = null;
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.updateContactLoading = false;
        state.updateContactResult = action.payload;
        state.updateContactError = null;
      })
      .addCase(updateContact.rejected, (state, action) => {
        state.updateContactLoading = false;
        state.updateContactResult = null;
        state.updateContactError = action.payload;
      })

      .addCase(deleteContact.pending, (state) => {
        state.deleteContactLoading = true;
        state.deleteContactResult = null;
        state.deleteContactError = null;
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.deleteContactLoading = false;
        state.deleteContactResult = action.payload;
        state.deleteContactError = null;
      })
      .addCase(deleteContact.rejected, (state, action) => {
        state.deleteContactLoading = false;
        state.deleteContactResult = null;
        state.deleteContactError = action.payload;
      });
  },
});

export const { resetCreateContact, resetUpdateContact, resetDeleteContact } =
  userContactsSlice.actions;
export default userContactsSlice.reducer;
