import React from "react";
import "./AddressSearchInput.scss";

import DLabel from "../../Form/DLabel/DLabel";
import DErrorText from "../../Form/DErrorText/DErrorText";

const AddressSearchInput = ({
  id,
  label,
  inputValue,
  setInputValue,
  autoComplete,
  placeholder,
  autoFocus,
  icon,
  error,
  onChange,
  clear,
}) => {
  return (
    <div id='AddressSearchInput'>
      {label && <DLabel label={label} htmlFor={id} />}
      <div className='group'>
        <input
          id={id}
          name={id}
          autoComplete={autoComplete ? autoComplete : "false"}
          type='text'
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange && onChange();
          }}
          placeholder={placeholder}
          autoFocus={autoFocus ? autoFocus : false}
        />
        {icon && <div className='AddressSearchInputIcon'>{icon}</div>}
        {inputValue && (
          <div
            className='AddressSearchInputRightIcon'
            onClick={() => {
              clear();
              setInputValue("");
            }}
          >
            <svg
              height='20'
              width='20'
              viewBox='0 0 20 20'
              aria-hidden='true'
              focusable='false'
            >
              <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
            </svg>
          </div>
        )}
      </div>
      {error && <DErrorText error={error} />}
    </div>
  );
};

export default AddressSearchInput;
