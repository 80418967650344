import React, { Fragment, useState } from "react";
import "./GPSCompanies.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

import DButton from "./../../Form/DButtons/DButton/DButton";
import ConnectCompanyModal from "../ConnectCompanyModal/ConnectCompanyModal";

import GPSCompanyItem from "./GPSCompanyItem";

const GPSCompanies = ({ companies }) => {
  const { t } = useTranslation();
  const [showConnectModal, setShowConnectModal] = useState(false);

  return (
    <Fragment>
      <div id='GPSCompaniesSection'>
        <h2>{t("tms.titles.Added GPS Company")}</h2>
        {companies && (
          <ul className='GPSCompaniesList'>
            {companies.map((item) => (
              <GPSCompanyItem item={item} key={item.value.id} />
            ))}
            <li className='createCompanyButton'>
              <DButton
                title={t("tms.forms.buttons.Add GPS Company")}
                leftIcon={<FiPlus />}
                onClick={() => setShowConnectModal(true)}
              />
            </li>
          </ul>
        )}
      </div>
      {showConnectModal && (
        <ConnectCompanyModal
          isOpen={showConnectModal}
          isClose={() => setShowConnectModal(false)}
        />
      )}
    </Fragment>
  );
};

export default GPSCompanies;
