import React, { useEffect } from "react";

import { FaCheckCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import {
  takeOrder,
  resetTakeOrder,
} from "./../../../../store/orders/shipperOrders/shipperOrdersSlice";

import DModal from "../../../Modal/DModal/DModal";

const ShipperOfferConfirmModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { takeOrderLoading, takeOrderResult } = useSelector((state) => state.shipperOrders);

  useEffect(() => {
    if (takeOrderResult) {
      dispatch(resetTakeOrder());
    }
  }, [dispatch, takeOrderResult]);

  const submitHandler = () => {
    const today = new Date().toISOString().slice(0, 10);
    const data = {
      orderId: order.id,
      pickup_date: order?.pickup_date || today,
    };
    dispatch(takeOrder(data));
  };

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      onClick={() => submitHandler()}
      submitText={t("shipper.buttons.Confirm")}
      title={t("shipper.modal.Confirm offer")}
      icon={<FaCheckCircle />}
      status='success'
      loading={takeOrderLoading}
    >
      <p>{t("shipper.modal.Are you sure you want to confirm this offer?")}</p>
    </DModal>
  );
};

export default ShipperOfferConfirmModal;
