import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import summaryService from "./summaryService";

const initialState = {
  getOrderscountLoading: true,
  getOrderscountResponce: null,
  getOrderscountError: null,

  getCustomerscountLoading: true,
  getCustomerscountResponce: null,
  getCustomerscountError: null,

  getPartnerscountLoading: true,
  getPartnerscountResponce: null,
  getPartnerscountError: null,

  getTruckscountLoading: true,
  getTruckscountResult: null,
  getTruckscountError: null,

  getTrailerscountLoading: true,
  getTrailerscountResponce: null,
  getTrailerscountError: null,

  getDriverscountLoading: true,
  getDriverscountResponce: null,
  getDriverscountError: null,

  getMaintenancescountLoading: true,
  getMaintenancescountResult: null,
  getMaintenancescountError: null,

  getShipperSummaryLoading: true,
  getShipperSummaryResult: null,
  getShipperSummaryError: null,
};

export const getOrdersSummary = createAsyncThunk(
  "summary/getOrdersSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCustomersSummary = createAsyncThunk(
  "summary/getCustomersSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPartnersSummary = createAsyncThunk(
  "summary/getPartnersSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTrucksSummary = createAsyncThunk(
  "summary/getTrucksSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getTrailersSummary = createAsyncThunk(
  "summary/getTrailersSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDriversSummary = createAsyncThunk(
  "summary/getDriversSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMaintenancesSummary = createAsyncThunk(
  "summary/getMaintenancesSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShipperSummary = createAsyncThunk(
  "summary/getShipperSummary",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await summaryService.getSummary(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrdersSummary.pending, (state) => {
        state.getOrderscountLoading = true;
        state.getOrderscountResponce = null;
        state.getOrderscountError = null;
      })
      .addCase(getOrdersSummary.fulfilled, (state, action) => {
        state.getOrderscountLoading = false;
        state.getOrderscountResponce = action.payload;
        state.getOrderscountError = null;
      })
      .addCase(getOrdersSummary.rejected, (state, action) => {
        state.getOrderscountLoading = false;
        state.getOrderscountResponce = null;
        state.getOrderscountError = action.payload;
      })

      .addCase(getCustomersSummary.pending, (state) => {
        state.getCustomerscountLoading = true;
        state.getCustomerscountResponce = null;
        state.getCustomerscountError = null;
      })
      .addCase(getCustomersSummary.fulfilled, (state, action) => {
        state.getCustomerscountLoading = false;
        state.getCustomerscountResponce = action.payload;
        state.getCustomerscountError = null;
      })
      .addCase(getCustomersSummary.rejected, (state, action) => {
        state.getCustomerscountLoading = false;
        state.getCustomerscountResponce = null;
        state.getCustomerscountError = action.payload;
      })

      .addCase(getPartnersSummary.pending, (state) => {
        state.getPartnerscountLoading = true;
        state.getPartnerscountResponce = null;
        state.getPartnerscountError = null;
      })
      .addCase(getPartnersSummary.fulfilled, (state, action) => {
        state.getPartnerscountLoading = false;
        state.getPartnerscountResponce = action.payload;
        state.getPartnerscountError = null;
      })
      .addCase(getPartnersSummary.rejected, (state, action) => {
        state.getPartnerscountLoading = false;
        state.getPartnerscountResponce = null;
        state.getPartnerscountError = action.payload;
      })

      .addCase(getTrucksSummary.pending, (state) => {
        state.getTruckscountLoading = true;
        state.getTruckscountResult = null;
        state.getTruckscountError = null;
      })
      .addCase(getTrucksSummary.fulfilled, (state, action) => {
        state.getTruckscountLoading = false;
        state.getTruckscountResult = action.payload;
        state.getTruckscountError = null;
      })
      .addCase(getTrucksSummary.rejected, (state, action) => {
        state.getTruckscountLoading = false;
        state.getTruckscountResult = null;
        state.getTruckscountError = action.payload;
      })

      .addCase(getTrailersSummary.pending, (state) => {
        state.getTrailerscountLoading = true;
        state.getTrailerscountResponce = null;
        state.getTrailerscountError = null;
      })
      .addCase(getTrailersSummary.fulfilled, (state, action) => {
        state.getTrailerscountLoading = false;
        state.getTrailerscountResponce = action.payload;
        state.getTrailerscountError = null;
      })
      .addCase(getTrailersSummary.rejected, (state, action) => {
        state.getTrailerscountLoading = false;
        state.getTrailerscountResponce = null;
        state.getTrailerscountError = action.payload;
      })

      .addCase(getDriversSummary.pending, (state) => {
        state.getDriverscountLoading = true;
        state.getDriverscountResponce = null;
        state.getDriverscountError = null;
      })
      .addCase(getDriversSummary.fulfilled, (state, action) => {
        state.getDriverscountLoading = false;
        state.getDriverscountResponce = action.payload;
        state.getDriverscountError = null;
      })
      .addCase(getDriversSummary.rejected, (state, action) => {
        state.getDriverscountLoading = false;
        state.getDriverscountResponce = null;
        state.getDriverscountError = action.payload;
      })

      .addCase(getMaintenancesSummary.pending, (state) => {
        state.getMaintenancescountLoading = true;
        state.getMaintenancescountResult = null;
        state.getMaintenancescountError = null;
      })
      .addCase(getMaintenancesSummary.fulfilled, (state, action) => {
        state.getMaintenancescountLoading = false;
        state.getMaintenancescountResult = action.payload;
        state.getMaintenancescountError = null;
      })
      .addCase(getMaintenancesSummary.rejected, (state, action) => {
        state.getMaintenancescountLoading = false;
        state.getMaintenancescountResult = null;
        state.getMaintenancescountError = action.payload;
      })

      .addCase(getShipperSummary.pending, (state) => {
        state.getShipperSummaryLoading = true;
        state.getShipperSummaryResult = null;
        state.getShipperSummaryError = null;
      })
      .addCase(getShipperSummary.fulfilled, (state, action) => {
        state.getShipperSummaryLoading = false;
        state.getShipperSummaryResult = action.payload;
        state.getShipperSummaryError = null;
      })
      .addCase(getShipperSummary.rejected, (state, action) => {
        state.getShipperSummaryLoading = false;
        state.getShipperSummaryResult = null;
        state.getShipperSummaryError = action.payload;
      });
  },
});

export default summarySlice.reducer;
