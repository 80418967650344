import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ordersService from "./ordersService";

const initialState = {
  getOrdersLoading: true,
  getOrdersResult: null,
  getOrdersMeta: null,
  getOrdersError: null,

  getOrderLoading: true,
  getOrderResult: null,
  getOrderError: null,

  createOrderLoading: false,
  createOrderResult: null,
  createOrderError: null,

  updateOrderLoading: false,
  updateOrderResult: null,
  updateOrderError: null,

  updateLoading: false,
  updateResponce: null,
  updateError: null,

  deleteOrderLoading: false,
  deleteOrderResult: null,
  deleteOrderError: null,

  getShipperActiveOrdersLoading: true,
  getShipperActiveOrdersResult: null,
  getShipperActiveOrdersMeta: null,
  getShipperActiveOrdersError: null,

  getShipperRejectOrdersLoading: true,
  getShipperRejectOrdersResult: null,
  getShipperRejectOrdersMeta: null,
  getShipperRejectOrdersError: null,

  getOrderFiltersLoading: true,
  getOrderFiltersResponce: null,
  getOrderFiltersError: null,
};

export const getOrders = createAsyncThunk("orders/getOrders", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await ordersService.getOrders(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getOrder = createAsyncThunk("orders/getOrder", async (orderId, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await ordersService.getOrder(ISO, TOKEN, orderId);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createOrder = createAsyncThunk("orders/createOrder", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await ordersService.createOrder(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateOrder = createAsyncThunk("orders/updateOrder", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await ordersService.updateOrder(ISO, TOKEN, data && data.orderId, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteOrder = createAsyncThunk("orders/deleteOrder", async (orderId, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await ordersService.deleteOrder(ISO, TOKEN, orderId);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getShipperActiveOrders = createAsyncThunk(
  "orders/getShipperActiveOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await ordersService.getShipperActiveOrders(ISO, TOKEN, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getShipperRejectOrders = createAsyncThunk(
  "orders/getShipperRejectOrders",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await ordersService.getShipperRejectOrders(ISO, TOKEN, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const getOrderFilters = createAsyncThunk(
  "orders/getOrderFilters",
  async (orderId, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await ordersService.getOrderFilters(ISO, TOKEN, orderId);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetGetOrders: (state) => {
      state.getOrdersLoading = true;
      state.getOrdersResult = null;
      state.getOrdersMeta = null;
      state.getOrdersError = null;
    },
    resetGetOrder: (state) => {
      state.getOrderLoading = true;
      state.getOrderResult = null;
      state.getOrderError = null;
    },
    resetCreateOrder: (state) => {
      state.createOrderLoading = false;
      state.createOrderResult = null;
      state.createOrderError = null;
    },
    resetUpdateOrder: (state) => {
      state.updateOrderLoading = false;
      state.updateOrderResult = null;
      state.updateOrderError = null;
    },
    resetDeleteOrder: (state) => {
      state.deleteOrderLoading = false;
      state.deleteOrderResult = null;
      state.deleteOrderError = null;
    },
    resetGetShipperActiveOrders: (state) => {
      state.getShipperActiveOrdersLoading = true;
      state.getShipperActiveOrdersResult = null;
      state.getShipperActiveOrdersMeta = null;
      state.getShipperActiveOrdersError = null;
    },
    resetGetShipperRejectOrders: (state) => {
      state.getShipperRejectOrdersLoading = true;
      state.getShipperRejectOrdersResult = null;
      state.getShipperRejectOrdersMeta = null;
      state.getShipperRejectOrdersError = null;
    },
    resetGetOrderFilters: (state) => {
      state.getOrderFiltersLoading = true;
      state.getOrderFiltersResponce = null;
      state.getOrderFiltersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.getOrdersLoading = true;
        state.getOrdersResult = null;
        state.getOrdersMeta = null;
        state.getOrdersError = null;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.getOrdersLoading = false;
        state.getOrdersResult = action.payload.data;
        state.getOrdersMeta = action.payload.meta;
        state.getOrdersError = null;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.getOrdersLoading = false;
        state.getOrdersResult = null;
        state.getOrdersMeta = null;
        state.getOrdersError = action.payload;
      })

      .addCase(getOrder.pending, (state) => {
        state.getOrderLoading = true;
        state.getOrderResult = null;
        state.getOrderError = null;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.getOrderLoading = false;
        state.getOrderResult = action.payload;
        state.getOrderError = null;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.getOrderLoading = false;
        state.getOrderResult = null;
        state.getOrderError = action.payload;
      })

      .addCase(createOrder.pending, (state) => {
        state.createOrderLoading = true;
        state.createOrderResult = null;
        state.createOrderError = null;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.createOrderLoading = false;
        state.createOrderResult = action.payload;
        state.createOrderError = null;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.createOrderLoading = false;
        state.createOrderResult = null;
        state.createOrderError = action.payload;
      })

      .addCase(updateOrder.pending, (state) => {
        state.updateOrderLoading = true;
        state.updateOrderResult = null;
        state.updateOrderError = null;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.updateOrderLoading = false;
        state.updateOrderResult = action.payload;
        state.updateOrderError = null;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.updateOrderLoading = false;
        state.updateOrderResult = null;
        state.updateOrderError = action.payload;
      })

      .addCase(deleteOrder.pending, (state) => {
        state.deleteOrderLoading = true;
        state.deleteOrderResult = null;
        state.deleteOrderError = null;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.deleteOrderLoading = false;
        state.deleteOrderResult = action.payload;
        state.deleteOrderError = null;
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.deleteOrderLoading = false;
        state.deleteOrderResult = null;
        state.deleteOrderError = action.payload;
      })

      .addCase(getShipperActiveOrders.pending, (state) => {
        state.getShipperActiveOrdersLoading = true;
        state.getShipperActiveOrdersResult = null;
        state.getShipperActiveOrdersMeta = null;
        state.getShipperActiveOrdersError = null;
      })
      .addCase(getShipperActiveOrders.fulfilled, (state, action) => {
        state.getShipperActiveOrdersLoading = false;
        state.getShipperActiveOrdersResult = action.payload.data;
        state.getShipperActiveOrdersMeta = action.payload.meta;
        state.getShipperActiveOrdersError = null;
      })
      .addCase(getShipperActiveOrders.rejected, (state, action) => {
        state.getShipperActiveOrdersLoading = false;
        state.getShipperActiveOrdersResult = null;
        state.getShipperActiveOrdersMeta = null;
        state.getShipperActiveOrdersError = action.payload;
      })

      .addCase(getShipperRejectOrders.pending, (state) => {
        state.getShipperRejectOrdersLoading = true;
        state.getShipperRejectOrdersResult = null;
        state.getShipperRejectOrdersMeta = null;
        state.getShipperRejectOrdersError = null;
      })
      .addCase(getShipperRejectOrders.fulfilled, (state, action) => {
        state.getShipperRejectOrdersLoading = false;
        state.getShipperRejectOrdersResult = action.payload.data;
        state.getShipperRejectOrdersMeta = action.payload.meta;
        state.getShipperRejectOrdersError = null;
      })
      .addCase(getShipperRejectOrders.rejected, (state, action) => {
        state.getShipperRejectOrdersLoading = false;
        state.getShipperRejectOrdersResult = null;
        state.getShipperRejectOrdersMeta = null;
        state.getShipperRejectOrdersError = action.payload;
      })
     
      .addCase(getOrderFilters.pending, (state) => {
        state.getOrderFiltersLoading = true;
        state.getOrderFiltersResponce = null;
        state.getOrderFiltersError = null;
      })
      .addCase(getOrderFilters.fulfilled, (state, action) => {
        state.getOrderFiltersLoading = false;
        state.getOrderFiltersResponce = action.payload;
        state.getOrderFiltersError = null;
      })
      .addCase(getOrderFilters.rejected, (state, action) => {
        state.getOrderFiltersLoading = false;
        state.getOrderFiltersResponce = null;
        state.getOrderFiltersError = action.payload;
      });
  },
});

export const {
  resetCreateOrder,
  resetUpdateOrder,
  resetGetOrders,
  resetGetOrder,
  resetDeleteOrder,
  resetGetShipperActiveOrders,
  resetGetShipperRejectOrders,
  resetGroupOrder,
  resetGetOrderFilters,
} = ordersSlice.actions;
export default ordersSlice.reducer;
