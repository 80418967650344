import React from "react";
import "./EmptyList.scss";

import { FaPlusCircle } from "react-icons/fa";

import DButton from "../../Form/DButtons/DButton/DButton";

const EmptyList = ({
  image,
  title,
  description,
  onClick,
  buttonText,
  icon,
}) => {
  return (
    <div id='EmptyList'>
      <div
        className='cover'
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>
      <div className='text'>
        {title && <h1>{title}</h1>}
        {description && <p>{description}</p>}
      </div>
      {buttonText && (
        <DButton
          title={buttonText}
          rightIcon={icon ? icon : <FaPlusCircle />}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default EmptyList;
