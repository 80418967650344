import React from "react";

import { useTranslation } from "react-i18next";

import TrailersTableItem from "./TrailersTableItem/TrailersTableItem";
import TableHeader from "../../../Form/TableFields/TableHeader/TableHeader";
import TableWrapper from "../../../Form/TableFields/TableWrapper/TableWrapper";

const TrailersTable = ({ trailers }) => {
  const { t } = useTranslation();
  return (
    <TableWrapper
      emptyText={t("tms.tables.noInfo.no trailers")}
      total={trailers?.length}
    >
      <TableHeader>
        <th>{t("tms.tables.titles.Trailer")}</th>
        <th>{t("tms.tables.titles.Plate number")}</th>
        <th>{t("tms.tables.titles.Max volume (M³)")}</th>
        <th>{t("tms.tables.titles.Max weight (KG)")}</th>
        <th colSpan='2'> {t("tms.tables.titles.Linked to")}</th>
      </TableHeader>
      <tbody>
        {trailers.map((trailer) => (
          <TrailersTableItem key={trailer.id} trailer={trailer} />
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default TrailersTable;
