import React, { Fragment } from "react";
import "./OrderStatusBarWithoutAction.scss";

import { useSelector } from "react-redux";
import OrderStatus from "../components/OrderStatus/OrderStatus";

const OrderStatusBarWithoutAction = ({ order }) => {
  const { getClientStatusesResult } = useSelector((state) => state.orderStatuses);

  const renderStatuses = (statuses) => {
    if (getClientStatusesResult === undefined) {
      return null;
    }
    return (
      <Fragment>
        {getClientStatusesResult?.map((status) => {
          const currentStatus = status?.id === order?.statusClient?.id;
          const doneStatus = status?.id <= order?.statusClient?.id;
          const lastStatus = status?.id === statuses?.length;

          return (
            <li
              className={status?.id < order?.statusClient?.id ? "active" : "passive"}
              key={status?.id}
            >
              <div className='active' />
              <div className='passive' />
              <div className='dots'>
                <div
                  className='smallDot'
                  style={{
                    background: currentStatus
                      ? status?.color1
                      : status?.id > order?.statusClient?.id
                      ? "#f4f6f9"
                      : "",
                  }}
                />
                <div
                  className='largeDot'
                  style={{
                    background: status?.color2,
                    display: !currentStatus && "none",
                  }}
                />
              </div>
              {currentStatus ? (
                <div className='statusContainer'>
                  <OrderStatus
                    status={status?.name}
                    done={lastStatus}
                    currentStatusColors={{
                      background: status?.color2,
                    }}
                  />
                </div>
              ) : (
                <div className='statusContainer'>
                  <OrderStatus
                    status={status?.name}
                    done={doneStatus}
                    current={currentStatus}
                    disabled={!doneStatus}
                  />
                </div>
              )}
            </li>
          );
        })}
      </Fragment>
    );
  };

  return (
    <div id='OrderStatusBarWithoutAction'>
      <ul className='progress-bar'>{renderStatuses(getClientStatusesResult)}</ul>
    </div>
  );
};

export default OrderStatusBarWithoutAction;
