import React from "react";
import "./PasswordSuccess.scss";

import { useTranslation } from "react-i18next";
import { FiChevronRight } from "react-icons/fi";
import { useDispatch } from "react-redux";

import { getUser } from "./../../../../store/auth/authSlice";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import Button from "../../../Form/Button/Button";
import CheckIcon from "../../Components/CheckIcon/CheckIcon";

const PasswordSuccess = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const submitHandler = () => {
    dispatch(getUser());
  };

  return (
    <AuthFormWrapper>
      <div id='PasswordSuccess' className='PasswordSuccess'>
        <FormTitle
          title={t("landing.auth.titles.Password changed")}
        />
        <CheckIcon />
        <Button
          title={t("landing.auth.form.Go to your dashboard")}
          rightIcon={<FiChevronRight />}
          onClick={() => {
            submitHandler();
          }}
        />
      </div>
    </AuthFormWrapper>
  );
};

export default PasswordSuccess;
