import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import leadService from "./leadService";

const initialState = {
  // create lead
  createLeadLoading: false,
  createLeadResult: null,
  createLeadError: null,
};

// create lead
export const createLead = createAsyncThunk("lead/createLead", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.token;
    const result = await leadService.createLead(ISO, TOKEN, data);
    return result;
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    resetCreateLead: (state) => {
      state.createLeadResult = null;
      state.createLeadLoading = false;
      state.createLeadError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // create lead
      .addCase(createLead.pending, (state) => {
        state.createLeadLoading = true;
        state.createLeadResult = null;
        state.createLeadError = null;
      })
      .addCase(createLead.fulfilled, (state, action) => {
        state.createLeadLoading = false;
        state.createLeadResult = action.payload;
        state.createLeadError = null;
      })
      .addCase(createLead.rejected, (state, action) => {
        state.createLeadLoading = false;
        state.createLeadResult = null;
        state.createLeadError = action.payload;
      });
  },
});

export const { resetCreateLead } = leadSlice.actions;

export default leadSlice.reducer;
