import React from "react";
import "./PhoneInput.scss";

import { useSelector, useDispatch } from "react-redux";
import { setPhoneIndexValue } from "../../../../store/additional/settings/settingsSlice";

import Input from "../Input";
import SelectForm from "../../Select/SelectForm";

const PhoneInput = ({
  value,
  setValue,
  placeholder,
  autoFocus,
  error,
  id,
  type,
}) => {
  const dispatch = useDispatch();

  const { getPhoneIndexesResult, getPhoneIndexesLoading, phoneIndexValueId } = useSelector(
    (state) => state.settings
  );

  const options =
    getPhoneIndexesResult &&
    getPhoneIndexesResult.map((item) => ({
      value: item,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "15px",
              height: "15px",
              backgroundImage: `url(${item.flag})`,
              backgroundPosition: "cover",
              backgroundRepeat: "no-repeat",
              marginRight: "5px",
            }}
          ></div>
          <p>{item.phone_index}</p>
        </div>
      ),
    }));

  const newValue =
    options && options.find((item) => item.value.id === phoneIndexValueId);

  const filterOption = (option, inputValue) => {
    const { value } = option;
    return value.phone_index.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <div id='PhoneInput'>
      <div className='phoneInputContainer'>
        <SelectForm
          options={options}
          name='PhoneInput'
          value={newValue}
          setValue={(state) => {
            dispatch(setPhoneIndexValue(state.value.id));
          }}
          loading={getPhoneIndexesLoading}
          error={error}
          search={true}
          filterOption={filterOption}
        />
        <Input
          type={type ? type : "tel"}
          id={id}
          placeholder={placeholder}
          value={value}
          setValue={setValue}
          autoFocus={autoFocus}
          error={error}
        />
      </div>
    </div>
  );
};

export default PhoneInput;
