import React, { Fragment, useState, useEffect } from "react";
import "./AssignPartnerTable.scss";

import { useTranslation } from "react-i18next";

import { FiPlus } from "react-icons/fi";
import { useSelector } from "react-redux";

import TableHeader from "../../../../../Form/TableFields/TableHeader/TableHeader";
import NoInformation from "../../../../../Form/TableFields/NoInformation/NoInformation";
import CreatePartnerModal from "../../../../../CustomersPartners/Partners/CreatePartnerModal/CreatePartnerModal";
import AssignPartnerTableItem from "./AssignPartnerTableItem/AssignPartnerTableItem";
import LoadingPage from "./../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import Pagination from "./../../../../../Form/Pagination/Pagination";
import DSearch from "./../../../../../FilterForms/DSearch/DSearch";
import FilterSelectableButton from "../Components/FilterSelectableButton/FilterSelectableButton";
import DButton from "../../../../../Form/DButtons/DButton/DButton";

const AssignPartnerTable = ({
  setBidScreen,
  isOpen,
  isClose,
  order,
  tab,
  setTab,
  partnerTab,
  setPartnerTab,
  page,
  setPage,
  keyword,
  setKeyword,
  partnersBids,
  setPartnersBids,
  trailerTypeFilter,
  setTrailerTypeFilter,
  routeFilter,
  setRouteFilter,
  regionFilter,
  setRegionFilter,
}) => {
  const { t } = useTranslation();
  const { getPartnersLoading, getPartnersResult, getPartnersMeta } = useSelector(
    (state) => state.partner
  );
  const { getOrderFiltersResponce } = useSelector((state) => state.orders);

  const [openAddPartnerModal, setOpenAddPartnerModal] = useState(false);
  const [keywordValue, setKeywordValue] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setKeyword(keywordValue);
    }, 500);
    return () => clearInterval(timeout);
  }, [keywordValue, setKeyword]);

  return (
    <Fragment>
      <div id='AssignPartnerTable'>
        <div className='AssignPartnerTableHeader'>
          <div className='AssignPartnerTableHeaderLeft'>
            <div className='searchInput'>
              <DSearch value={keywordValue} setValue={(e) => setKeywordValue(e)} />
            </div>
            {getOrderFiltersResponce && (
              <div className='filterContainer'>
                <div className='filterTitle'>{t("tms.filters.others.Filter by interest")}</div>
                {getOrderFiltersResponce?.trailerType &&
                  getOrderFiltersResponce?.trailerType?.count > 0 && (
                    <FilterSelectableButton
                      filterState={trailerTypeFilter}
                      setFilterState={setTrailerTypeFilter}
                      item={getOrderFiltersResponce?.trailerType}
                      selected={trailerTypeFilter ? true : false}
                    />
                  )}
                {getOrderFiltersResponce?.routes?.[0] &&
                  getOrderFiltersResponce?.routes?.[0]?.count > 0 && (
                    <FilterSelectableButton
                      filterState={routeFilter}
                      setFilterState={setRouteFilter}
                      item={getOrderFiltersResponce?.routes?.[0]}
                      selected={routeFilter ? true : false}
                    />
                  )}
                {getOrderFiltersResponce?.routes?.[1] &&
                  getOrderFiltersResponce?.routes?.[1]?.count > 0 && (
                    <FilterSelectableButton
                      filterState={regionFilter}
                      setFilterState={setRegionFilter}
                      item={getOrderFiltersResponce?.routes?.[1]}
                      selected={regionFilter ? true : false}
                    />
                  )}
              </div>
            )}
          </div>

          <DButton
            title={t("tms.forms.buttons.Add partner")}
            rightIcon={<FiPlus />}
            onClick={() => {
              setOpenAddPartnerModal(true);
            }}
          />
        </div>
        {getPartnersLoading && <LoadingPage />}
        {getPartnersResult && !getPartnersLoading && (
          <div className='tableWrapper'>
            <table>
              <TableHeader>
                <th>{t("tms.tables.titles.Partners")}</th>
                <th>{t("tms.tables.titles.Service quality")}</th>
                <th>{t("tms.tables.titles.Phone number")}</th>
                <th>{t("tms.tables.titles.Email")}</th>
                <th style={{ backgroundColor: "#FEEFEF", textAlign: "center" }}>
                  {t("tms.tables.titles.Live bid responses")}
                </th>
                <th style={{ textAlign: "center" }}>{t("tms.tables.titles.Action button")}</th>
              </TableHeader>
              <tbody>
                {getPartnersResult &&
                  getPartnersResult.map((partner) => (
                    <AssignPartnerTableItem
                      setBidScreen={setBidScreen}
                      key={partner.id}
                      partner={partner}
                      isOpen={isOpen}
                      isClose={isClose}
                      order={order}
                      tab={tab}
                      setTab={setTab}
                      partnerTab={partnerTab}
                      setPartnerTab={setPartnerTab}
                      page={page}
                      setPage={setPage}
                      partnersBids={partnersBids}
                      setPartnersBids={setPartnersBids}
                    />
                  ))}
              </tbody>
            </table>
            {getPartnersResult.length === 0 && (
              <NoInformation title={t("tms.tables.noInfo.Partners not found")} />
            )}
          </div>
        )}
        {getPartnersResult && (
          <Pagination value={page} setValue={setPage} pageCount={getPartnersMeta.last_page} />
        )}
      </div>
      {openAddPartnerModal && (
        <CreatePartnerModal
          isOpen={openAddPartnerModal}
          isClose={() => setOpenAddPartnerModal(false)}
        />
      )}
    </Fragment>
  );
};

export default AssignPartnerTable;
