import React from "react";
import "./MobileTitle.scss";

import { useTranslation } from "react-i18next";
import MainTitle from "../../../MainTitle/MainTitle";

const MobileTitle = () => {
  const { t } = useTranslation();
  return (
    <div id='MobileTitle'>
      <MainTitle
        title={t("tms.titles.Drivers Tracking")}
        description={t(
          "tms.desc.Here is a list of drivers that we already sent an account information of Cargon app, if they have log in to the app you can see their location on the map, on this list you can check when they used app and who didn’t log in yet."
        )}
      />
    </div>
  );
};

export default MobileTitle;
