import React from "react";
import "./ForgotPasswordVerify.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import PasswordVerify from "./../../../../componentsLanding/Auth&Password/ForgotPasswordForms/PasswordVerify/PasswordVerify";

const ForgotPasswordVerify = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Forgot password?")}>
      <section id='ForgotPasswordVerify'>
        <AuthCover url='/assets/images/static/cover23.jpg' />
        <PasswordVerify />
      </section>
    </Wrapper>
  );
};

export default ForgotPasswordVerify;
