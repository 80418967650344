import React, { Fragment, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

import {
  updateClientPaid,
  resetUpdateClientPaid,
} from "../../../../../store/orders/orderFinance/orderFinanceSlice";

import DModal from "../../../../Modal/DModal/DModal";

const MarkAsPaidModal = ({ isOpen, isClose, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updateClientPaidLoading, updateClientPaidResult } = useSelector(
    (state) => state.orderFinance
  );

  const submitHandler = () => {
    const data = {
      orderId: item.id,
      status: true,
    };
    dispatch(updateClientPaid(data));
  };

  useEffect(() => {
    if (updateClientPaidResult) {
      dispatch(resetUpdateClientPaid());
    }
  }, [dispatch, updateClientPaidResult]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateClientPaid());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Mark As Paid")}
        submitText={t("tms.modals.buttons.Confirm")}
        icon={<FaCheckCircle />}
        status='success'
        onClick={() => submitHandler()}
        loading={updateClientPaidLoading}
      >
        <p>{t("tms.modals.desc.Are you sure you want to mark this order as paid?")}</p>
      </DModal>
    </Fragment>
  );
};

export default MarkAsPaidModal;
