import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import attachmentsService from "./attachmentsService";

const initialState = {
  getAttachmentTypesLoading: true,
  getAttachmentTypesResult: null,
  getAttachmentTypesSelect: null,
  getAttachmentTypesError: null,

  temporaryAttachmentLoading: false,
  temporaryAttachmentResult: null,
  temporaryAttachmentError: null,

  createAttachmentLoading: false,
  createAttachmentResult: null,
  createAttachmentError: null,

  deleteAttachmentLoading: false,
  deleteAttachmentResult: null,
  deleteAttachmentError: null,
};

export const getAttachmentTypes = createAsyncThunk(
  "attachments/getAttachmentTypes",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await attachmentsService.getAttachmentTypes(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const temporaryAttachment = createAsyncThunk(
  "attachments/temporaryAttachment",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const formData = new FormData();
      if (data.type_id) {
        formData.append("type_id", data?.type_id || "");
      }
      if (data.comment) {
        formData.append("comment", data?.comment || "");
      }
      if (data.document_date) {
        formData.append("document_date", data?.document_date || "");
      }
      if (data.document) {
        formData.append("document", data?.document || "");
      }
      return await attachmentsService.temporaryAttachment(ISO, TOKEN, formData);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createAttachment = createAsyncThunk(
  "attachments/createAttachment",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const formData = new FormData();
      if (data.type_id) {
        formData.append("type_id", data?.type_id || "");
      }
      if (data.document) {
        formData.append("document", data?.document || "");
      }
      if (data.document_date) {
        formData.append("document_date", data?.document_date || "");
      }
      if (data.comment) {
        formData.append("comment", data?.comment || "");
      }
      return await attachmentsService.createAttachment(ISO, TOKEN, data.id, formData);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteAttachment = createAsyncThunk(
  "attachments/deleteAttachment",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await attachmentsService.deleteAttachment(ISO, TOKEN, data.id, data.fileId);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const attachmentsSlice = createSlice({
  name: "attachments",
  initialState,
  reducers: {
    resetGetAttachmentTypes: (state) => {
      state.getAttachmentTypesLoading = true;
      state.getAttachmentTypesResult = null;
      state.getAttachmentTypesSelect = null;
      state.getAttachmentTypesError = null;
    },
    resetTemporaryAttachment: (state) => {
      state.temporaryAttachmentLoading = false;
      state.temporaryAttachmentResult = null;
      state.temporaryAttachmentError = null;
    },
    resetCreateAttachment: (state) => {
      state.createAttachmentLoading = false;
      state.createAttachmentResult = null;
      state.createAttachmentError = null;
    },
    resetDeleteAttachment: (state) => {
      state.deleteAttachmentLoading = false;
      state.deleteAttachmentResult = null;
      state.deleteAttachmentError = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAttachmentTypes.pending, (state) => {
        state.getAttachmentTypesLoading = true;
        state.getAttachmentTypesResult = null;
        state.getAttachmentTypesSelect = null;
        state.getAttachmentTypesError = null;
      })
      .addCase(getAttachmentTypes.fulfilled, (state, action) => {
        state.getAttachmentTypesLoading = false;
        state.getAttachmentTypesResult = action.payload;
        state.getAttachmentTypesSelect = action.payload.map((i) => ({
          label: i.name,
          value: i,
        }));
        state.getAttachmentTypesError = null;
      })
      .addCase(getAttachmentTypes.rejected, (state, action) => {
        state.getAttachmentTypesLoading = false;
        state.getAttachmentTypesResult = null;
        state.getAttachmentTypesSelect = null;
        state.getAttachmentTypesError = action.payload;
      })

      .addCase(temporaryAttachment.pending, (state) => {
        state.temporaryAttachmentLoading = true;
        state.temporaryAttachmentResult = null;
        state.temporaryAttachmentError = null;
      })
      .addCase(temporaryAttachment.fulfilled, (state, action) => {
        state.temporaryAttachmentLoading = false;
        state.temporaryAttachmentResult = action.payload;
        state.temporaryAttachmentError = null;
      })
      .addCase(temporaryAttachment.rejected, (state, action) => {
        state.temporaryAttachmentLoading = false;
        state.temporaryAttachmentResult = null;
        state.temporaryAttachmentError = action.payload;
      })

      .addCase(createAttachment.pending, (state) => {
        state.createAttachmentLoading = true;
        state.createAttachmentResult = null;
        state.createAttachmentError = null;
      })
      .addCase(createAttachment.fulfilled, (state, action) => {
        state.createAttachmentLoading = false;
        state.createAttachmentResult = action.payload;
        state.createAttachmentError = null;
      })
      .addCase(createAttachment.rejected, (state, action) => {
        state.createAttachmentLoading = false;
        state.createAttachmentResult = null;
        state.createAttachmentError = action.payload;
      })

      .addCase(deleteAttachment.pending, (state) => {
        state.deleteAttachmentLoading = true;
        state.deleteAttachmentResult = null;
        state.deleteAttachmentError = null;
      })
      .addCase(deleteAttachment.fulfilled, (state, action) => {
        state.deleteAttachmentLoading = false;
        state.deleteAttachmentResult = action.payload;
        state.deleteAttachmentError = null;
      })
      .addCase(deleteAttachment.rejected, (state, action) => {
        state.deleteAttachmentLoading = false;
        state.deleteAttachmentResult = null;
        state.deleteAttachmentError = action.payload;
      });
  },
});

export const {
  resetGetAttachmentTypes,
  resetTemporaryAttachment,
  resetGetAttachments,
  resetCreateAttachment,
  resetDeleteAttachment,
} = attachmentsSlice.actions;
export default attachmentsSlice.reducer;
