import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import langService from "./langService";

import i18next from "i18next";

import { setLanguageInUrl } from "../../../helper/setLanguageInUrl";
import cookie from "js-cookie";

const urlLang = window?.location?.pathname?.split("/")?.[1]?.split("_")?.[1];

const currentLngIso = localStorage.getItem("i18next") || urlLang;
if (currentLngIso) {
  cookie.set("i18next", currentLngIso);
}

const initialState = {
  // get languages
  getLanguagesLoading: false,
  getLanguagesResult: null,
  getLanguagesSelect: null,
  getLanguagesError: null,
  ISO: currentLngIso,
};

// get languages
export const getLanguages = createAsyncThunk(
  "lang/getLanguages",
  async (_, thunkAPI) => {
    try {
      const result = await langService.getLanguages();
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data) || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// set Language
export const setLang = createAsyncThunk("lang/setLang", async (data) => {
  if (data) {
    await i18next.changeLanguage(data.toLowerCase());
    setLanguageInUrl(data.toLowerCase());
  }
  return data.toLowerCase();
});

export const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get languages
      .addCase(getLanguages.pending, (state) => {
        state.getLanguagesLoading = true;
        state.getLanguagesResult = null;
        state.getLanguagesSelect = null;
        state.getLanguagesError = null;
      })
      .addCase(getLanguages.fulfilled, (state, action) => {
        state.getLanguagesLoading = false;
        state.getLanguagesResult = action.payload;
        state.getLanguagesSelect = action.payload.map((lang) => ({
          label: lang.native,
          value: lang,
        }));
        state.getLanguagesError = null;
      })
      .addCase(getLanguages.rejected, (state, action) => {
        state.getLanguagesLoading = false;
        state.getLanguagesResult = null;
        state.getLanguagesSelect = null;
        state.getLanguagesError = action.payload;
      });
  },
});

export default langSlice.reducer;
