import _request from "./../_request";

const getLocations = async (ISO, TOKEN) => {
  const config = {
    url: "/favorite-locations",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createLocation = async (ISO, TOKEN, data) => {
  const config = {
    url: "/favorite-locations",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteLocation = async (ISO, TOKEN, id) => {
  const config = {
    url: `/favorite-locations/${id}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const favoriteLocationsService = {
  getLocations,
  createLocation,
  deleteLocation,
};

export default favoriteLocationsService;
