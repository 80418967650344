import React, { Fragment } from "react";
import "./OrderStatusField.scss";

import { useSelector } from "react-redux";

import LoadingStatus from "./LoadingStatus/LoadingStatus";

const OrderStatusField = ({ status }) => {
  const { getCargoStatusesLoading } = useSelector((state) => state.orderStatuses);

  return (
    <Fragment>
      {getCargoStatusesLoading ? (
        <LoadingStatus />
      ) : (
        <td id='OrderStatusFieldTable'>
          <div className='OrderStatusFieldTablecontent'>
            <div
              className='OrderStatusFieldTablecontentwrap'
              style={{
                backgroundColor: `${status?.color2 ? status.color2 : "#b0e8d1"}`,
              }}
            >
              <div
                className='dot'
                style={{
                  backgroundColor: `${status?.color1 ? status.color1 : "#004c2d"}`,
                }}
              ></div>
              <p
                style={{
                  color: `${status?.color1 ? status.color1 : "#004c2d"}`,
                }}
              >
                {status?.name ? status.name : "*"}
              </p>
            </div>
          </div>
        </td>
      )}
    </Fragment>
  );
};

export default OrderStatusField;
