import React from "react";
import "./Sticky.scss";

const Sticky = ({ isCountrySwitcherOpen }) => {
  return (
    <section
      id='Sticky'
      className={isCountrySwitcherOpen ? "open" : ""}
    ></section>
  );
};

export default Sticky;
