import React from "react";
import "./PageNotFound.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Error404Svg from "./svg/Error404Svg";
import Logo from "../../componentsAdditional/SVG/Cargon/Logo";
import DButton from "../../componentsDashboard/Form/DButtons/DButton/DButton";

const PageNotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div id='BidsPageNotFound'>
      <div className='bidsPageNotFoundLeft'>
        <div className='bidsPageNotFoundLeftIcon'>
          <Logo className='bidsPageNotFoundLeftLogo' />
        </div>
        <div className='bidsPageNotFoundLeftContent'>
          <div className='bidsPageNotFoundLeftTitle'>
            <div className='bidsPageNotFoundLeftTitleBold'>404.</div>
            <div className='bidsPageNotFoundLeftTitleLight'>{t("tms.desc.Thats an error")}</div>
          </div>
          <div className='bidsPageNotFoundLeftText'>
            {t(
              "tms.desc.The page you requested couldn't be found, but that's okay! Click button to explore our main area."
            )}
          </div>
          <DButton title={t("tms.forms.buttons.Go to home page")} onClick={() => navigate("/")} />
        </div>
      </div>
      <div className='bidsPageNotFoundRight'>
        <Error404Svg />
      </div>
    </div>
  );
};

export default PageNotFound;
