import React from "react";
import "./CheckIcon.scss";

import { FiCheck } from "react-icons/fi";

const CheckIcon = () => {
  return (
    <div id='CheckIcon'>
      <div className='CheckIconContainer'>
        <FiCheck />
      </div>
    </div>
  );
};

export default CheckIcon;
