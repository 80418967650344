import React from 'react';
import './ContactShipper.scss';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import HomeTitle from '../../Components/HomeTitle/HomeTitle';
import ContactForm from './../Components/ContactForm/ContactForm';

const ContactShipper = () => {
  const { t } = useTranslation();

  return (
    <section id='ContactShipper' className='ContactShipper'>
      <div className='wrapper'>
        <HomeTitle
          description={t(
            'landing.titles.landingMainTitlesDesc.Fill out the form to discuss how we can assist you'
          )}
        >
          {parse(
            t('landing.titles.landingMainTitles.Stay <span>in touch</span> with our sales team')
          )}
        </HomeTitle>

        <div className='container'>
          <ContactForm role='CLIENT' />
        </div>
      </div>
    </section>
  );
};

export default ContactShipper;
