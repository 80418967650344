import React, { useState } from "react";
import "./FilterSelectableButton.scss";

import { MdCancel } from "react-icons/md";

const FilterSelectableButton = ({
  item,
  filterState,
  setFilterState,
  selected,
}) => {
  const [isSelected, setIsSelected] = useState(selected ? true : false);

  return (
    <div
      id='FilterSelectableButton'
      style={{
        backgroundColor: isSelected ? "#2B353A" : "#F4F6F9",
      }}
      onClick={() => {
        setIsSelected(!isSelected);
        isSelected && setFilterState(null);
        !isSelected && setFilterState(item?.id);
      }}
    >
      <div
        className='text'
        style={{ color: isSelected ? "#FFFFFF" : "#2B353A" }}
      >
        {item?.title}
      </div>
      {!isSelected && <div className='qty'>{item?.count}</div>}
      {isSelected && (
        <div className='icon'>
          <MdCancel />
        </div>
      )}
    </div>
  );
};

export default FilterSelectableButton;
