import React from "react";
import "./ShipperCalendarOrderTooltip.scss";

import { useMoney } from "../../../helper/useMoney";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { BsBoxSeamFill } from "react-icons/bs";
import { FaWeightHanging } from "react-icons/fa6";
import { MdLocationPin, MdLocationSearching, MdMonetizationOn } from "react-icons/md";

import Character from "../../UI/Character/Character";
import {
  setActiveOrderId,
  setShipperAcceptedOrderView,
} from "../../../store/orders/shipperAccepted/shipperAcceptedSlice";

const AddressDetailsItem = ({ content, icon, from }) => {
  return (
    <div className='AddressDetailsItem'>
      <div className={from ? "icon from" : "icon to"}>{icon}</div>
      <div className='content'>{content ? <Character title={content} max={40} /> : "-"}</div>
    </div>
  );
};

const ShipperCalendarOrderTooltip = ({ order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productType = order?.cargos?.[0]?.type?.name;
  const weight = order?.weight;
  const offer = useMoney(order?.revenue || 0, order?.revenue_currency?.symbol || "$");
  const distance = (order?.distance / 1000).toFixed(0);

  const locations = order?.cargos[0]?.locations || [];
  const directionA = locations?.filter((location) => {
    return location.direction === "A";
  });
  const directionB = locations?.filter((location) => {
    return location.direction === "B";
  });

  const clickFieldHandler = () => {
    dispatch(setShipperAcceptedOrderView(false));
    dispatch(setActiveOrderId(order.id));
    navigate("/dashboard/shipper/accepted");
  };

  return (
    <div className='ShipperCalendarOrderTooltipContent'>
      <div className='headerContainer'>
        <div className='item'>
          <div className='icon'>
            <BsBoxSeamFill />
          </div>
          <div className='text'>{productType}</div>
        </div>
        <div className='item'>
          <div className='icon'>
            <FaWeightHanging />
          </div>
          <div className='text'>{weight}KG</div>
        </div>
        <div className='item'>
          <div className='icon'>
            <MdMonetizationOn />
          </div>
          <div className='text'>{offer}</div>
        </div>
      </div>
      <div className='separator' />
      <div className='routesContainer'>
        <div className='routesHeader'>
          <div className='routeTitle'>{t("shipper.forms.titles.Route")}</div>
          {order?.distance > 0 && (
            <div className='distance'>
              {distance}
              <span> {t("tms.desc.KM")}</span>
            </div>
          )}
        </div>
        <div className='routes'>
          {directionA && directionA.length > 0 ? (
            directionA.map((item, i) => (
              <AddressDetailsItem
                content={item.formatted_address}
                key={i}
                icon={<MdLocationSearching />}
                from
              />
            ))
          ) : (
            <AddressDetailsItem content='' icon={<MdLocationSearching />} />
          )}
          {directionB && directionB.length > 0 ? (
            directionB.map((item, i) => (
              <AddressDetailsItem
                content={item.formatted_address}
                key={i}
                icon={<MdLocationPin />}
              />
            ))
          ) : (
            <AddressDetailsItem content='-' icon={<MdLocationPin />} />
          )}
        </div>
      </div>
      <div
        className='seeOrderButton'
        onClick={() =>
          order?.status?.id >= 3
            ? clickFieldHandler()
            : navigate(`/dashboard/shipper/orders/${order.id}`)
        }
      >
        {t("tms.forms.buttons.See full order")}
      </div>
    </div>
  );
};

export default ShipperCalendarOrderTooltip;
