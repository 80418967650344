import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shipperOrdersService from "./shipperOrdersService";

const initialState = {
  takeOrderLoading: false,
  takeOrderResult: null,
  takeOrderError: null,

  rejectOrderLoading: false,
  rejectOrderResult: null,
  rejectOrderError: null,
};

export const takeOrder = createAsyncThunk("shipperOrders/takeOrder", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await shipperOrdersService.takeOrder(ISO, TOKEN, data && data.orderId, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const rejectOrder = createAsyncThunk("shipperOrders/rejectOrder", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await shipperOrdersService.rejectOrder(ISO, TOKEN, data && data.orderId, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const shipperOrdersSlice = createSlice({
  name: "shipperOrders",
  initialState,
  reducers: {
    resetTakeOrder: (state) => {
      state.takeOrderLoading = false;
      state.takeOrderResult = null;
      state.takeOrderError = null;
    },
    resetRejectOrder: (state) => {
      state.rejectOrderLoading = false;
      state.rejectOrderResult = null;
      state.rejectOrderError = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(takeOrder.pending, (state) => {
        state.takeOrderLoading = true;
        state.takeOrderResult = null;
        state.takeOrderError = null;
      })
      .addCase(takeOrder.fulfilled, (state, action) => {
        state.takeOrderLoading = false;
        state.takeOrderResult = action.payload;
        state.takeOrderError = null;
      })
      .addCase(takeOrder.rejected, (state, action) => {
        state.takeOrderLoading = false;
        state.takeOrderResult = null;
        state.takeOrderError = action.payload;
      })
      .addCase(rejectOrder.pending, (state) => {
        state.rejectOrderLoading = true;
        state.rejectOrderResult = null;
        state.rejectOrderError = null;
      })
      .addCase(rejectOrder.fulfilled, (state, action) => {
        state.rejectOrderLoading = false;
        state.rejectOrderResult = action.payload;
        state.rejectOrderError = null;
      })
      .addCase(rejectOrder.rejected, (state, action) => {
        state.rejectOrderLoading = false;
        state.rejectOrderResult = null;
        state.rejectOrderError = action.payload;
      });
  },
});

export const { resetTakeOrder, resetRejectOrder } = shipperOrdersSlice.actions;
export default shipperOrdersSlice.reducer;
