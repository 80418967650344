import React from "react";
import "./CardProfit.scss";

const CardProfit = ({
  title,
  desc,
  titleNumber,
  profitRatio,
  shipmentNumber,
}) => {
  return (
    <div id='CardProfit'>
      <div className='header'>
        <h1>{title}</h1>
        <p>{titleNumber}</p>
      </div>
      <div className='desc'>
        <h1>{desc}</h1>
        {profitRatio ? (
          <p className={profitRatio < 0 ? "dec" : "inc"}>{profitRatio}%</p>
        ) : (
          <p>{shipmentNumber}</p>
        )}
      </div>
    </div>
  );
};

export default CardProfit;
