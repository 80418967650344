import React, { useEffect, useState } from "react";
import "./Step2.scss";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  resetSendOtp,
  resetVerifyCode,
  sendOtp,
  verifyCode,
} from "../../../../../store/subscribe/subscribeSlice";

import Button from "../../../../Form/Button/Button";
import OTP from "../../../../Form/OTP/OTP";
import SendAgain from "../../../../Auth&Password/Components/SendAgain/SendAgain";

const Step2 = ({
  setStep,
  email,
  switcherValue,
  selectedTrailerTypes,
  selectedRoutes,
  fullName,
  country,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { verifyCodeResult, verifyCodeError, verifyCodeLoading, sendOtpLoading, sendOtpResult } =
    useSelector((state) => state.subscribe);
  const [otp, setOtp] = useState();

  const submitHandler = () => {
    const data = {
      code: otp,
      
      email: email,
      fleet_owner: switcherValue === "fleetOwner",
      trailer_types: selectedTrailerTypes || [],
      routes: selectedRoutes || [],
      registration_country: country?.value,
      fullname: fullName,
    };
    dispatch(verifyCode(data));
  };

  const handleResend = () => {
    const data = { email: email, contact_channel: "email" };
    dispatch(sendOtp(data));
  };

  useEffect(() => {
    if (verifyCodeResult) {
      setStep((prev) => prev + 1);
      navigate("/carrier/subscribe?success");
    }
    return () => {
      dispatch(resetVerifyCode());
    };
  }, [verifyCodeResult, dispatch, setStep, navigate]);

  return (
    <div id='CarrierSubscribeFormStep2'>
      <div className='titleContainer'>
        <div className='title'>{t("landing.landingCarrierSubscribe.Verification")}</div>
        <div className='subTitle'>
          {t(
            "landing.landingCarrierSubscribe.Please enter verification code which you recieved on your {method}.",
            {
              method: t("landing.landingCarrierSubscribe.email"),
            }
          )}
        </div>
      </div>
      <div className='otpContainer'>
        <OTP
          value={otp}
          setValue={setOtp}
          count={6}
          error={verifyCodeError?.errors?.code?.[0] || verifyCodeError?.error}
        />
        <Button
          title={t("landing.landingCarrierSubscribe.Subscribe")}
          onClick={submitHandler}
          loading={verifyCodeLoading}
        />
      </div>
      <div className='secondaryButtonsContainer'>
        <SendAgain
          title={t("landing.landingCarrierSubscribe.Resend")}
          onClick={handleResend}
          loading={sendOtpLoading}
          result={sendOtpResult}
          reset={() => dispatch(resetSendOtp())}
        />
        <div className='changePhoneButton' onClick={() => setStep((prev) => prev - 1)}>
          {t("landing.landingCarrierSubscribe.Change the {method}", {
            method: t("landing.landingCarrierSubscribe.email"),
          })}
        </div>
      </div>
    </div>
  );
};

export default Step2;
