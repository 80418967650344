import React from "react";
import "./CostsChart.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useBigMoney } from "../../../../helper/useBigMoney";
import { useMoney } from "../../../../helper/useMoney";

const CustomizedXAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y + 20})`}>
      <text x={0} y={0} dy={0} textAnchor='middle' fill='#888888'>
        {payload.value}
      </text>
    </g>
  );
};

const ToolTipContent = (props, user) => {
  return (
    <div
      style={{
        boxShadow: "0px 3px 12px 0px #00000033",
        border: "1px solid #F4F6F9",
        background:
          "linear-gradient(0deg, #F4F6F9, #F4F6F9), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
        borderRadius: "8px",
        padding: "8px",
      }}
    >
      {useMoney(props?.payload?.[0]?.value || 0, user?.currency?.symbol)}
    </div>
  );
};

const CostsChart = ({ data }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const RenderLegend = (props) => {
    const { payload } = props;

    return (
      <ul
        style={{
          paddingTop: "22px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "12px",
              height: "12px",
              background: payload[0].color,
              border: "1px solid #54CD9D",
            }}
          ></div>
          <li style={{ listStyle: "none" }}>{t("shipper.desc.Total costs")}</li>
        </div>
      </ul>
    );
  };

  const CustomizedYAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y + 5})`}>
        <text x={0} y={0} dy={0} textAnchor='end' fill='#888888'>
          {useBigMoney(payload.value, user?.currency?.symbol)}
        </text>
      </g>
    );
  };

  return (
    <div id='CostsChart'>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart data={data} barSize={30}>
          <CartesianGrid strokeDasharray='1 0' vertical={false} />
          <Tooltip
            content={(props) => ToolTipContent(props, user)}
            cursor={{ fill: "#F4F6F9" }}
          />
          <Legend content={RenderLegend} />
          <XAxis
            dataKey='name'
            axisLine={{ display: "none" }}
            tickLine={false}
            tick={<CustomizedXAxisTick />}
          />
          <YAxis
            domain={["auto", "auto"]}
            axisLine={{ display: "none" }}
            tickLine={false}
            tick={<CustomizedYAxisTick />}
          />
          <Bar stackId={"a"} dataKey={"value"} fill='#00B56C' />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CostsChart;
