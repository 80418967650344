import React from "react";
import "./ForgotPassword.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import Password from "./../../../../componentsLanding/Auth&Password/ForgotPasswordForms/Password/Password";

const ForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Forgot password?")}>
      <section id='ForgotPassword'>
        <AuthCover url='/assets/images/static/cover23.jpg' />
        <Password />
      </section>
    </Wrapper>
  );
};

export default ForgotPassword;
