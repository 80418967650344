import React from "react";
import "./Info.scss";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const Info = () => {
  const { t } = useTranslation();

  return (
    <div id='VectorMapinfo' className='VectorMapinfo'>
      <div
        className='animation'
        data-aos='fade-right'
        data-aos-duration='1500'
        data-aos-once='true'
      >
        <div className='infoContent'>
          <div className='item'>
            <div className='icon'>
              <div className='white'></div>
            </div>
            <p>{parse(t("landing.titles.landingMapInfo.Cargon office"))}</p>
          </div>
          <div className='item'>
            <div className='icon coverage'></div>
            <p>{parse(t("landing.titles.landingMapInfo.Cargon coverage"))}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
