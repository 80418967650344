import React from "react";
import "./ForgotPasswordSuccess.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import AuthCover from "./../../../../componentsLanding/Auth&Password/Components/AuthCover/AuthCover";
import PasswordSuccess from "./../../../../componentsLanding/Auth&Password/ForgotPasswordForms/PasswordSuccess/PasswordSuccess";

const ForgotPasswordSuccess = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Forgot password?")}>
      <section id='ForgotPasswordSuccess'>
        <AuthCover url='/assets/images/static/cover23.jpg' />
        <PasswordSuccess />
      </section>
    </Wrapper>
  );
};

export default ForgotPasswordSuccess;
