import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subscribeService from "./subscribeService";

const initialState = {
  carrierSubscribeResult: null,
  carrierSubscribeLoading: false,
  carrierSubscribeError: null,

  verifyCodeResult: null,
  verifyCodeLoading: false,
  verifyCodeError: null,

  sendOtpResult: null,
  sendOtpLoading: false,
  sendOtpError: null,

  subscribeWithEmailResult: null,
  subscribeWithEmailLoading: false,
  subscribeWithEmailError: null,
};

export const carrierSubscribe = createAsyncThunk(
  "subscribe/carrierSubscribe",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const response = await subscribeService.carrierSubscribe(ISO, data);
      return response;
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyCode = createAsyncThunk("subscribe/verifyCode", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const response = await subscribeService.verifyCode(ISO, data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const sendOtp = createAsyncThunk("subscribe/sendOtp", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const response = await subscribeService.sendOtp(ISO, data);
    return response;
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const subscribeWithEmail = createAsyncThunk(
  "subscribe/subscribeWithEmail",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const response = await subscribeService.subscribeWithEmail(ISO, data);
      return response;
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const subscribeSlice = createSlice({
  name: "subscribe",
  initialState,
  reducers: {
    resetCarrierSubscribe: (state) => {
      state.carrierSubscribeLoading = false;
      state.carrierSubscribeResult = null;
      state.carrierSubscribeError = null;
    },
    resetVerifyCode: (state) => {
      state.verifyCodeLoading = false;
      state.verifyCodeResult = null;
      state.verifyCodeError = null;
    },
    resetSendOtp: (state) => {
      state.sendOtpLoading = false;
      state.sendOtpResult = null;
      state.sendOtpError = null;
    },
    resetSubscribeWithEmail: (state) => {
      state.subscribeWithEmailLoading = false;
      state.subscribeWithEmailResult = null;
      state.subscribeWithEmailError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(carrierSubscribe.pending, (state) => {
        state.carrierSubscribeLoading = true;
        state.carrierSubscribeResult = null;
        state.carrierSubscribeError = null;
      })
      .addCase(carrierSubscribe.fulfilled, (state, action) => {
        state.carrierSubscribeLoading = false;
        state.carrierSubscribeResult = action.payload;
        state.carrierSubscribeError = null;
      })
      .addCase(carrierSubscribe.rejected, (state, action) => {
        state.carrierSubscribeLoading = false;
        state.carrierSubscribeResult = null;
        state.carrierSubscribeError = action.payload;
      })

      .addCase(verifyCode.pending, (state) => {
        state.verifyCodeLoading = true;
        state.verifyCodeResult = null;
        state.verifyCodeError = null;
      })
      .addCase(verifyCode.fulfilled, (state, action) => {
        state.verifyCodeLoading = false;
        state.verifyCodeResult = action.payload;
        state.verifyCodeError = null;
      })
      .addCase(verifyCode.rejected, (state, action) => {
        state.verifyCodeLoading = false;
        state.verifyCodeResult = null;
        state.verifyCodeError = action.payload;
      })

      .addCase(sendOtp.pending, (state) => {
        state.sendOtpLoading = true;
        state.sendOtpResult = null;
        state.sendOtpError = null;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.sendOtpLoading = false;
        state.sendOtpResult = action.payload;
        state.sendOtpError = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.sendOtpLoading = false;
        state.sendOtpResult = null;
        state.sendOtpError = action.payload;
      })

      .addCase(subscribeWithEmail.pending, (state) => {
        state.subscribeWithEmailLoading = true;
        state.subscribeWithEmailResult = null;
        state.subscribeWithEmailError = null;
      })
      .addCase(subscribeWithEmail.fulfilled, (state, action) => {
        state.subscribeWithEmailLoading = false;
        state.subscribeWithEmailResult = action.payload;
        state.subscribeWithEmailError = null;
      })
      .addCase(subscribeWithEmail.rejected, (state, action) => {
        state.subscribeWithEmailLoading = false;
        state.subscribeWithEmailResult = null;
        state.subscribeWithEmailError = action.payload;
      });
  },
});

export const { resetCarrierSubscribe, resetVerifyCode, resetSendOtp, resetSubscribeWithEmail } =
  subscribeSlice.actions;

export default subscribeSlice.reducer;
