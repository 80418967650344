import React, { Fragment } from "react";
import "./DriverField.scss";

import { FaUser } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";

const DriverField = ({ driver }) => {
  return (
    <Fragment>
      <td id='DriverField'>
        {driver ? (
          <div className='active'>
            <div className='icon'>
              <FaUser />
            </div>
            <h4>{driver.name}</h4>
          </div>
        ) : (
          <div className='inactive'>
            <div className='icon'>
              <IoIosWarning />
            </div>
            <p>no driver</p>
          </div>
        )}
      </td>
    </Fragment>
  );
};

export default DriverField;
