export function useBigMoney(number, symbol) {
  let formattedNumber = "";
  if (number >= 1000000) {
    formattedNumber = number / 1000000 + "M";
  } else if (number >= 1000) {
    formattedNumber = number / 1000 + "K";
  } else {
    formattedNumber = number;
  }

  if (symbol === "$") {
    return `${symbol} ${formattedNumber}`;
  } else {
    return `${formattedNumber} ${symbol}`;
  }
}
