import React from "react";
import "./CarrierAppCover.scss";

const CarrierAppCover = () => {
  return (
    <div
      className='animation'
      data-aos='fade-left'
      data-aos-duration='1500'
      data-aos-once='true'
    >
      <div
        id='CarrierAppCover'
        style={{
          backgroundImage: `url(${`/assets/images/static/cover39.png`})`,
        }}
      ></div>
    </div>
  );
};

export default CarrierAppCover;
