import React from "react";
import "./FooterTitle.scss";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const FooterTitle = ({ title, value, setValue }) => {
  return (
    <div onClick={setValue} id='FooterTitle' className='FooterTitle'>
      <h3>{title}</h3>
      <div className='icon'>{value ? <FiChevronUp /> : <FiChevronDown />}</div>
    </div>
  );
};

export default FooterTitle;
