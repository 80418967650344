import _request from "./../../_request";

// ალკოჰოლი - საკვები და სასმელი
const getCargoTypes = async (ISO, TOKEN) => {
  const config = {
    url: "/order/cargo-types",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

//get Order Payment Type
const getPaymentType = async (ISO, TOKEN) => {
  const config = {
    url: `/payment-type`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

//get order services list
const getOrderServicesList = async (ISO, TOKEN) => {
  const config = {
    url: "/order/services",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

// get cargo package list
const getCargoPackageList = async (ISO, TOKEN) => {
  const config = {
    url: "/order/cargo-packing-types",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const orderSettingsService = {
  getCargoTypes,
  getPaymentType,
  getOrderServicesList,
  getCargoPackageList,
};

export default orderSettingsService;
