import React, { Fragment } from "react";
import "./TrailerManagement.scss";

import { useTranslation } from "react-i18next";

import { FiPlusCircle } from "react-icons/fi";
import { useSelector } from "react-redux";

import DButton from "../../../../../Form/DButtons/DButton/DButton";
import TrailerManagementItem from "./TrailerManagementItem/TrailerManagementItem";

const TrailerManagement = ({ edit, group, setGroup }) => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  const { getCurrenciesSelect } = useSelector((state) => state.settings);

  const addNewTrailerHandler = () => {
    const findCurrency =
      getCurrenciesSelect && getCurrenciesSelect.find((item) => item.value.id === user.currency.id);

    const newGroup = [
      ...group,
      {
        trailerType: null,
        trailerTypeError: null,
        trailerSize: null,
        trailerSizeError: null,
        temperatureRange: "",
        temperatureRangeError: null,
        weight: "",
        weightError: null,
        price: "",
        priceError: null,
        currency: findCurrency,
        currencyError: null,
      },
    ];
    setGroup(newGroup);
  };

  return (
    <Fragment>
      <div id='AddOrderTrailerManagement'>
        <div className='list'>
          {group.map((item, index) => (
            <TrailerManagementItem
              key={index}
              edit={edit}
              index={index}
              item={item}
              group={group}
              setGroup={setGroup}
            />
          ))}
        </div>
        {!edit && (
          <div className='addNewTrailersListButton'>
            <DButton
              disabled={group.length >= 40 ? true : false}
              title={t("tms.forms.buttons.Add new trailer")}
              leftIcon={<FiPlusCircle />}
              onClick={() => {
                addNewTrailerHandler();
              }}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default TrailerManagement;
