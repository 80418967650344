import _request from "./../../_request";

const getClientContacts = async (ISO, TOKEN, id) => {
  const config = {
    url: `/tms-client/${id}/contact`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createClientContact = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `/tms-client/${id}/contact`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateClientContact = async (ISO, TOKEN, id, contactId, data) => {
  const config = {
    url: `/tms-client/${id}/contact/${contactId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deleteClientContact = async (ISO, TOKEN, id, contactId) => {
  const config = {
    url: `/tms-client/${id}/contact/${contactId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getClientContactPositions = async (ISO, TOKEN) => {
  const config = {
    url: `/tms-client/contact-positions`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const customerContactsService = {
  getClientContacts,
  createClientContact,
  updateClientContact,
  deleteClientContact,
  getClientContactPositions,
};

export default customerContactsService;
