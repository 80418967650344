import * as React from "react";

const Logo = ({ color }, props) => {
  const logoColor = color || "#00B56C";

  return (
    <svg
      width='144'
      height='42'
      viewBox='0 0 144 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask
        id='mask0_2473_9024'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='144'
        height='42'
      >
        <path d='M144 0H0V41.7153H144V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_2473_9024)'>
        <path
          d='M0 20.8932C0 20.6441 0 20.3949 0 20.1458C0.428784 8.9339 9.64764 0 20.9747 0C32.3017 0 41.5206 8.9339 41.9137 20.1102C41.9137 20.3593 41.9137 20.6085 41.9137 20.8576C41.9137 32.3898 32.5161 41.7153 20.9747 41.7153C9.39752 41.7508 0 32.3898 0 20.8932ZM20.9032 6.79831C13.1851 6.79831 6.93201 13.0271 6.93201 20.7153C6.93201 28.4034 13.1851 34.6322 20.9032 34.6322C28.6213 34.6322 34.8744 28.4034 34.8744 20.7153C34.9102 13.0627 28.6213 6.79831 20.9032 6.79831Z'
          fill={logoColor}
        />
        <path
          d='M27.9068 12.9559V26.9085C27.9068 27.122 27.871 27.3 27.7996 27.478C27.478 28.3678 26.3346 28.8305 25.4413 28.2254L23.9762 27.2644L18.4735 23.5627V19.7542C18.4735 19.7542 18.4735 19.7186 18.5092 19.7186L18.545 19.6831C18.545 19.6831 18.545 19.6831 18.5807 19.6831L22.9043 22.5661C23.0115 22.6373 23.1901 22.7085 23.3331 22.7085C23.5117 22.7085 23.7261 22.6373 23.869 22.4949C24.012 22.3525 24.1192 22.139 24.1192 21.961V16.622C24.1192 16.0169 24.2621 15.4475 24.548 14.9136C25.2269 13.7746 26.406 13.0271 27.7996 12.9915C27.8353 12.9559 27.871 12.9559 27.9068 12.9559Z'
          fill={logoColor}
        />
        <path
          d='M23.3688 17.8322V21.7119C23.3688 21.7831 23.2973 21.7831 23.2973 21.7831L18.938 18.9C18.8308 18.8288 18.6521 18.7577 18.5092 18.7577C18.0804 18.7577 17.7588 19.1136 17.7588 19.5051V24.8441C17.7588 25.4492 17.6159 26.0187 17.3301 26.5526C16.6512 27.6916 15.472 28.439 14.0784 28.4746C14.0427 28.4746 14.007 28.4746 13.9713 28.4746V14.5577C13.9713 14.3441 14.007 14.1661 14.0784 14.0238C14.4 13.1339 15.5435 12.6712 16.4368 13.2763L16.9013 13.5611L23.3688 17.8322Z'
          fill={logoColor}
        />
        <path
          d='M64.0675 25.2L65.1037 28.9373C63.8174 29.3644 62.0308 29.7915 60.0298 29.7915C55.4561 29.7915 52.3474 27.0509 52.3474 22.5661C52.3474 18.0814 55.4561 15.3407 60.0298 15.3407C62.0308 15.3407 63.7817 15.7678 65.1037 16.1949L64.0675 19.9678C63.3886 19.6831 62.3881 19.4695 61.3876 19.4695C59.1008 19.4695 57.8501 20.6085 57.8501 22.6017C57.8501 24.5949 59.1365 25.7339 61.3876 25.7339C62.4238 25.6983 63.3886 25.4848 64.0675 25.2Z'
          fill={logoColor}
        />
        <path
          d='M81.469 29.5423H76.0735L75.4303 27.2288H71.2854L70.678 29.5423H65.2467L70.142 15.6254H76.5738L81.469 29.5423ZM72.1787 23.9542H74.537L73.3579 19.683L72.1787 23.9542Z'
          fill={logoColor}
        />
        <path
          d='M96.0476 29.5424H90.6878C89.8302 25.1644 89.187 25.2 87.5791 25.2V29.5424H82.3979V15.7678C83.6128 15.5543 85.8997 15.3407 87.7578 15.3407C91.7597 15.3407 95.726 16.3017 95.726 20.1814C95.726 22.3526 94.2967 23.278 93.1533 23.8119C94.3324 24.061 95.4759 25.3068 96.0476 29.5424ZM90.4734 20.5017C90.4734 19.3627 89.0798 19.1136 88.1508 19.1136C87.9007 19.1136 87.6863 19.1492 87.5434 19.1492V21.9254C87.6863 21.961 87.8292 21.961 88.0079 21.961C88.9012 21.961 90.4734 21.7475 90.4734 20.5017Z'
          fill={logoColor}
        />
        <path
          d='M110.662 21.8187V28.7593C109.233 29.3644 107.446 29.7915 104.838 29.7915C100.3 29.7915 96.9053 27.1932 96.9053 22.6017C96.9053 18.117 100.3 15.3407 105.088 15.3407C107.375 15.3407 108.625 15.6254 110.055 16.017L109.376 19.861C108.518 19.6119 107.518 19.3627 106.16 19.3627C103.408 19.3627 102.408 20.6441 102.408 22.6373C102.408 24.8441 104.016 25.8407 105.481 25.8407C105.66 25.8407 105.838 25.8051 105.981 25.8051V21.8187H110.662Z'
          fill={logoColor}
        />
        <path
          d='M127.813 22.6016C127.813 27.122 124.598 29.8271 119.917 29.8271C115.2 29.8271 112.02 27.122 112.02 22.6016C112.02 18.0813 115.236 15.3762 119.917 15.3762C124.633 15.3762 127.813 18.0813 127.813 22.6016ZM122.382 22.6016C122.382 20.5372 121.56 19.3627 119.917 19.3627C118.273 19.3627 117.451 20.5372 117.451 22.6016C117.451 24.6661 118.273 25.8406 119.917 25.8406C121.56 25.805 122.382 24.6305 122.382 22.6016Z'
          fill={logoColor}
        />
        <path
          d='M144 15.6254V29.5423H138.89L134.495 22.3881V29.5423H129.564V15.6254H134.924L139.033 22.744V15.6254H144Z'
          fill={logoColor}
        />
      </g>
    </svg>
  );
};
export default Logo;
