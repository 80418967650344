import _request from "./../_request";

const getPickupSuggestion = async (token, clientId, type) => {
  let url = `/pickup-suggestions/${clientId}/${type}`;
  const config = {
    url,
    token,
  };
  return _request(config);
};

const getDropoffSuggestion = async (token, clientId, type) => {
  let url = `/dropoff-suggestions/${clientId}/${type}`;
  const config = {
    url,
    token,
  };
  return _request(config);
};

const locationSuggestionService = {
  getPickupSuggestion,
  getDropoffSuggestion,
};

export default locationSuggestionService;
