import _request from "./../_request";

const getTrucks = async (ISO, TOKEN, data) => {
  let url = "/tracker/trucks";
  const { active } = data || {};
  url += active ? `&active=${active}` : "";

  const config = {
    url: url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getProviders = async (ISO, TOKEN) => {
  let url = "/tracker/providers";
  const config = {
    url: url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const connectProvider = async (ISO, TOKEN, data) => {
  const config = {
    url: "/tracker/connect",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const disconnectProvider = async (ISO, TOKEN, providerId) => {
  const config = {
    url: `/tracker/disconnect/${providerId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getConnections = async (ISO, TOKEN) => {
  let url = "/tracker/connections";
  const config = {
    url: url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getDevices = async (ISO, TOKEN) => {
  let url = "/tracker/devices";
  const config = {
    url: url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const connectTruckToDevice = async (ISO, TOKEN, data) => {
  const config = {
    url: "/tracker/connect-device",
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const disconnectTruckToDevice = async (ISO, TOKEN, data) => {
  const config = {
    url: "/tracker/disconnect-device",
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const GPSTrakerService = {
  getTrucks,
  getProviders,
  connectProvider,
  getConnections,
  getDevices,
  connectTruckToDevice,
  disconnectTruckToDevice,
  disconnectProvider,
};

export default GPSTrakerService;
