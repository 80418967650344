import React from "react";
import "./RevenueChart.scss";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

import { useBigMoney } from "../../../../helper/useBigMoney";

const RenderLegend = (props) => {
  const { t } = useTranslation();

  const { payload } = props;

  return (
    <ul
      style={{
        paddingTop: "22px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      {payload
        ?.slice()
        ?.reverse()
        ?.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                background: `linear-gradient(to bottom, ${entry.color} 60%, #00b56c 60%, #00b56c 100%)`,
                border: "1px solid #54CD9D",
              }}
            ></div>
            <li style={{ listStyle: "none" }} key={`item-${index}`}>
              {entry.value === "revenue"
                ? t("tms.desc.Revenue")
                : entry.value === "profit"
                ? t("tms.desc.Profit")
                : null}
            </li>
          </div>
        ))}
    </ul>
  );
};

const CustomizedXAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y + 20})`}>
      <text x={0} y={0} dy={0} textAnchor='middle' fill='#888888'>
        {payload.value}
      </text>
    </g>
  );
};

const ToolTipContent = (props, user) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        boxShadow: "0px 3px 12px 0px #00000033",
        border: "1px solid #F4F6F9",
        background:
          "linear-gradient(0deg, #F4F6F9, #F4F6F9), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
        borderRadius: "8px",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {props?.payload
        ?.slice()
        ?.reverse()
        ?.map((d, i) => {
          return d.name === "revenue" ? (
            <div
              key={i}
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <div>
                {t("tms.desc.Revenue")}:{" "}
                <span style={{ color: "#2B353A", fontWeight: "bold" }}>
                  {user?.currency?.symbol}{" "}
                  {parseFloat(d.payload.profit + d.value).toFixed(2)}
                </span>
              </div>
              <div>
                {t("tms.desc.Revenue progress")}:{" "}
                <span
                  style={{
                    color:
                      parseFloat(d?.payload?.revenueProgress) < 0
                        ? "#da1414"
                        : "#00B56C",
                  }}
                >
                  {d?.payload?.revenueProgress}%
                </span>
              </div>
            </div>
          ) : d.name === "profit" ? (
            <div
              key={i}
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <div>
                {t("tms.desc.Profit")}:{" "}
                <span style={{ color: "#2B353A", fontWeight: "bold" }}>
                  {user?.currency?.symbol} {parseFloat(d.value).toFixed(2)}
                </span>
              </div>
              <div>
                {t("tms.desc.Profit Ratio")}:{" "}
                <span style={{ color: "#2B353A", fontWeight: "bold" }}>
                  {d?.payload?.profitRatio}%
                </span>
              </div>
            </div>
          ) : null;
        })}
    </div>
  );
};

const RevenueChart = ({ data }) => {
  const { user } = useSelector((state) => state.auth);

  const CustomizedYAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y + 5})`}>
        <text x={0} y={0} dy={0} textAnchor='end' fill='#888888'>
          {useBigMoney(payload.value, user?.currency?.symbol)}
        </text>
      </g>
    );
  };

  return (
    <div id='RevenueChart'>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          data={data?.map((d) => ({ ...d, revenue: d.revenue - d.profit }))}
          barSize={30}
        >
          <CartesianGrid strokeDasharray='1 0' vertical={false} />
          <Tooltip
            content={(props) => ToolTipContent(props, user)}
            cursor={{ fill: "#F4F6F9" }}
          />
          <Legend content={RenderLegend} />
          <XAxis
            dataKey='name'
            axisLine={{ display: "none" }}
            tick={<CustomizedXAxisTick />}
            tickLine={false}
          />
          <YAxis
            domain={["auto", "auto"]}
            axisLine={{ display: "none" }}
            tickLine={false}
            tick={<CustomizedYAxisTick />}
          />
          <Bar
            isAnimationActive={false}
            stackId={"a"}
            dataKey={"profit"}
            fill='#00B56C'
            stroke='#00B56C'
          />
          <Bar
            isAnimationActive={false}
            stackId={"a"}
            dataKey={"revenue"}
            fill='#F0FCF7'
            stroke='#00B56C'
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RevenueChart;
