export const useUniqueProductCategory = (cargos) => {
  const typeCount = {};

  cargos.forEach((cargo) => {
    const typeId = cargo.type.id;
    const typeName = cargo.type.name;

    if (typeCount[typeId]) {
      typeCount[typeId].count += 1;
    } else {
      typeCount[typeId] = { id: typeId, name: typeName, count: 1 };
    }
  });

  return Object.values(typeCount);
};
