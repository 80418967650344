import React from "react";
import {
  MdLocationPin,
  MdCategory,
  MdAirplanemodeActive,
} from "react-icons/md";
import { FaWeightHanging, FaRegCalendar } from "react-icons/fa";
import { BsBoxSeamFill } from "react-icons/bs";
import moment from "moment";

import CreateOrderTopMenu from "../../Components/CreateOrderTopMenu/CreateOrderTopMenu";

const AirTopMenu = ({ group, locations, time }) => {
  const calculateTotalWeight = () => {
    return group.reduce((acc, cargo) => {
      const count = parseFloat(cargo.dimensions[0].count) || 0;
      const weight = parseFloat(cargo.dimensions[0].weight) || 0;
      return acc + count * weight;
    }, 0);
  };

  const getCategoryLabel = () => {
    const cargoTypeIds = group
      .map((item) => item?.cargoType?.value?.id)
      .filter(Boolean);
    const uniqueCargoTypeIds = [...new Set(cargoTypeIds)];
    const uniqueCargoTypeCount = uniqueCargoTypeIds.length;
    return (
      (group[0]?.cargoType?.label || "-") +
      (uniqueCargoTypeCount > 1 ? ` (+${uniqueCargoTypeCount - 1})` : "")
    );
  };

  const getPackingTypeLabel = () => {
    const packingTypeIds = group
      .map((item) => item?.dimensions[0]?.packingType?.value?.id)
      .filter(Boolean);
    const uniquePackingTypeIds = [...new Set(packingTypeIds)];
    const uniquePackingTypeCount = uniquePackingTypeIds.length;
    return (
      (group[0]?.dimensions[0]?.packingType?.label || "-") +
      (uniquePackingTypeCount > 1 ? ` (+${uniquePackingTypeCount - 1})` : "")
    );
  };

  const getLocationDescription = (direction) => {
    return (
      locations.find((i) => i.direction === direction && i.inputted_address)
        ?.inputted_address || ""
    );
  };
  const locationsDescA = getLocationDescription("A");
  const locationsDescB = getLocationDescription("B");
  const totalWeight = calculateTotalWeight();
  const categoryLabel = getCategoryLabel();
  const packingTypeLabel = getPackingTypeLabel();

  const ARRAY = [
    {
      icon: <MdAirplanemodeActive />,
      title: "AIR",
    },
    {
      icon: <MdLocationPin />,
      title: `${locationsDescA} - ${locationsDescB}`,
    },
    {
      icon: <MdCategory />,
      title: categoryLabel,
    },
    {
      icon: <BsBoxSeamFill />,
      title: packingTypeLabel,
    },
    {
      icon: <FaWeightHanging />,
      title: totalWeight ? totalWeight : "-",
    },
    {
      icon: <FaRegCalendar />,
      title: time ? moment(time).format("DD/MM/YYYY") : "-",
    },
  ];

  return <CreateOrderTopMenu items={ARRAY} />;
};

export default AirTopMenu;
