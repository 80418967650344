import React from "react";
import "./PaymentNewOrder.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import NewOrderTitle from "../Components/NewOrderTitle/NewOrderTitle";
import DSelect from "../../../../Form/DSelect/DSelect";
import DInputs from "../../../../Form/DInputs/DInputs";
import DCurrencyInput from "../../../../Form/DCurrencyInput/DCurrencyInput";
import DPaymentSubOptions from "../../../../Form/DSelect/DPaymentSubOptions/DPaymentSubOptions";

const PaymentNewOrder = ({
  payMethod,
  setPayMethod,
  payMethodError,
  /////////////
  payMethodSub,
  setPayMethodSub,
  payMethodSubError,
  /////////////
  payMethodAmount,
  setPayMethodAmount,
  payMethodAmountError,
  //////////
  payMethodAmountCurrency,
  setPayMethodAmountCurrency,
  payMethodAmountCurrencyError,
  //////////////////
  payConsignationPeriod,
  setPayConsignationPeriod,
  payConsignationPeriodError,
}) => {
  const { t } = useTranslation();

  const { getPaymentTypeLoading, getPaymentTypeSelect } = useSelector(
    (state) => state.orderSettings
  );

  return (
    <div id='PaymentNewOrder'>
      <NewOrderTitle title={t("tms.titles.Payment method")} />

      {getPaymentTypeSelect && (
        <div className='PaymentNewOrderWrapper'>
          <div className='groupItem method'>
            <DSelect
              required
              id='selectPaymentMethodAddOrderPayment'
              placeholder={t("tms.forms.placeholder.Payment method")}
              label={t("tms.forms.labels.Payment method")}
              options={getPaymentTypeSelect ? getPaymentTypeSelect : []}
              value={payMethod}
              setValue={(e) => {
                setPayMethod(e);
                setPayMethodSub(null);
                setPayConsignationPeriod("");
                setPayMethodAmount("");
              }}
              loading={getPaymentTypeLoading}
              error={payMethodError}
            />
          </div>

          {payMethod && payMethod.value.id === 2 && (
            <div className='groupItem'>
              <DCurrencyInput
                id='PaymentMethosAmount'
                label={t("tms.forms.labels.PrepaymentAmount")}
                placeholder={t("tms.forms.placeholder.Enter prepayment amount")}
                value={payMethodAmount}
                setValue={setPayMethodAmount}
                currencyValue={payMethodAmountCurrency}
                setCurrencyValue={setPayMethodAmountCurrency}
                required
                error={payMethodAmountError || payMethodAmountCurrencyError}
              />
            </div>
          )}

          {payMethod && (payMethod.value.id === 2 || payMethod.value.id === 3) && (
            <div className='groupItem SubTypes'>
              <DPaymentSubOptions
                value={payMethodSub}
                setValue={(e) => {
                  setPayMethodSub(e);
                  setPayConsignationPeriod("");
                }}
                error={payMethodSubError}
              />
            </div>
          )}

          {payMethod &&
            (payMethod.value.id === 2 || payMethod.value.id === 3) &&
            payMethodSub &&
            payMethodSub.value.id === "AFTER_DROP_OFF" && (
              <div className='groupItem'>
                <DInputs
                  required
                  id='payConsignationPeriodAddOrderPayment'
                  value={payConsignationPeriod}
                  setValue={setPayConsignationPeriod}
                  type='number'
                  placeholder={t("tms.forms.placeholder.Number of days")}
                  label={t("tms.forms.labels.Consignation period")}
                  error={payConsignationPeriodError}
                />
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default PaymentNewOrder;
