import React, { Fragment } from "react";
import "./CreateOrderOversizedSection.scss";

import { useTranslation } from "react-i18next";
import { FiCheck } from "react-icons/fi";

import DCheckBox from "../../../../Form/DCheckBox/DCheckBox";
import CreateOrderSectionSecondaryTitle from "../CreateOrderSectionSecondaryTitle/CreateOrderSectionSecondaryTitle";
import DFileForm from "../../../../Form/DFile/DFileForm/DFileForm";
import DErrorText from "../../../../Form/DErrorText/DErrorText";
import DButton from "../../../../Form/DButtons/DButton/DButton";
import DFileFormItem from "../../../../Form/DFile/DFileForm/DFileFormItem";

const CreateOrderOversizedSection = ({
  edit,
  oversized,
  setOversized,
  oversizedError,
  oversizedFile,
  setOversizedFile,
  oversizedCall,
  setOversizedCall,
}) => {
  const { t } = useTranslation();

  const removeFileHandler = (id) => {
    const copyArray = [...oversizedFile];
    const filterredCopyArrayy = copyArray.filter((i) => i.id !== id);
    setOversizedFile(filterredCopyArrayy);
  };

  const oversizedHandler = () => {
    setOversized((state) => !state);
    if (oversized) {
      setOversizedFile && setOversizedFile([]);
      setOversizedCall && setOversizedCall(false);
    }
  };

  return (
    <div id='CreateOrderOversizedSection'>
      <DCheckBox
        value={oversized}
        setValue={() => oversizedHandler()}
        label={t("shipper.forms.content.Oversized cargo")}
      />
      <DErrorText error={oversizedError} />

      {oversized && !edit && (
        <Fragment>
          <CreateOrderSectionSecondaryTitle title={t("shipper.forms.titles.Cargo dimensions")} />
          <div className='oversizedGroupContainer'>
            <div className='oversizedGroupContainerFile'>
              {setOversizedFile &&
                !edit &&
                oversizedFile.map((item, index) => (
                  <DFileFormItem
                    key={index}
                    item={item}
                    removeItem={(id) => removeFileHandler(id)}
                  />
                ))}

              {setOversizedFile && (
                <DFileForm
                  value={oversizedFile}
                  setValue={setOversizedFile}
                  title={t("shipper.forms.content.Upload cargo drawings")}
                  subTitle={t("shipper.forms.content.Optional")}
                  type_id={50}
                />
              )}
            </div>

            <div className='orContainer'>
              <span className='or'>{t("shipper.forms.content.OR")}</span>
            </div>
            <div className='requestCallContainer'>
              <div className='title'>
                {t("shipper.forms.content.Request a call from our sales team to specify details")}
              </div>
              {!oversizedCall ? (
                <Fragment>
                  <div className='button'>
                    <DButton
                      title={t("shipper.buttons.Request a call")}
                      onClick={() => setOversizedCall(true)}
                    />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className='requestedContainer'>
                    <div className='requestedContainerLeft'>
                      <div className='icon'>
                        <FiCheck />
                      </div>
                      <div className='text'>
                        {t("shipper.forms.content.We will contact you for the details")}
                      </div>
                    </div>
                    <div className='requestedContainerRight'>
                      <div className='undoButton' onClick={() => setOversizedCall(false)}>
                        {t("shipper.buttons.Undo")}
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default CreateOrderOversizedSection;
