import React from "react";
import "./NotificationsPageHeader.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import MarkAsReadButton from "../MarkAsReadButton/MarkAsReadButton";

const NotificationsPageHeader = () => {
  const { t } = useTranslation();
  const { getNewNotiMeta } = useSelector((state) => state.notifications);

  return (
    <div id='NotificationsPageHeader'>
      <h1>
        {t("tms.notifications.Notifications")}{" "}
        {getNewNotiMeta &&
          getNewNotiMeta.total > 0 &&
          `(${getNewNotiMeta.total})`}
      </h1>
      <MarkAsReadButton />
    </div>
  );
};

export default NotificationsPageHeader;
