import _request from "../_request";

const getOrders = async (ISO, TOKEN, data) => {
  let url = "dashboard/orders";

  const { status_id } = data || {};

  url += status_id ? `?status_id=${status_id}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const getChartsData = async (ISO, TOKEN) => {
  const config = {
    url: "dashboard/charts",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getInfoData = async (ISO, TOKEN) => {
  const config = {
    url: "dashboard/info",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const ordersService = {
  getOrders,
  getChartsData,
  getInfoData,
};

export default ordersService;
