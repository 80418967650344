import React, { useState, Fragment } from "react";
import "./NotificationsButton.scss";

import { IoMdNotificationsOutline } from "react-icons/io";
import { useSelector } from "react-redux";

import NotificationModal from "../../../Notifications/NotificationModal/NotificationModal";

const NotificationsButton = ({ open }) => {
  const { getNewNotiMeta } = useSelector((state) => state.notifications);
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <Fragment>
      <div id='NotificationsButton' className={open ? "max" : "min"}>
        <div
          className={
            isActive ? "NotificationsIcon active" : "NotificationsIcon"
          }
          onClick={() => {
            handleClick();
          }}
        >
          {getNewNotiMeta && getNewNotiMeta.total > 0 && (
            <div className='NotificationsIconBadge'></div>
          )}
          <IoMdNotificationsOutline />
        </div>
      </div>
      <NotificationModal
        isOpen={isActive}
        isClose={() => setIsActive(false)}
        open={open}
      />
    </Fragment>
  );
};

export default NotificationsButton;
