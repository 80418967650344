import React, { Fragment } from "react";
import "./ShowCustomerModal.scss";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { FaUserFriends } from "react-icons/fa";
import { MdOutlinePhoneIphone, MdMailOutline } from "react-icons/md";
import { RiMapPinLine } from "react-icons/ri";
import { FiUser } from "react-icons/fi";
import { AiOutlineFieldNumber } from "react-icons/ai";

import DModal from "../../../Modal/DModal/DModal";

const ShowCustomerModal = ({ isOpen, isClose, order }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const orderData = order?.ownership ? order?.customer : order;
  const submitHandler = () => {
    navigate(`/dashboard/tms/customers_partners/customers/${order?.customer?.id || ""}`);
  };

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Customer details")}
        submitText={t("tms.modals.buttons.Go to customer page")}
        icon={<FaUserFriends />}
        status={order?.ownership ? "success" : "noSubmit"}
        onClick={() => submitHandler()}
        loading={null}
      >
        <div id='ShowCustomerModal'>
          {order?.client?.name && (
            <h4>
              <div className='icon'>
                <FiUser />
              </div>
              {order?.client?.name}
            </h4>
          )}
          {order?.client?.code && (
            <h4>
              <div className='icon'>
                <AiOutlineFieldNumber />
              </div>
              {order?.client?.code}
            </h4>
          )}
          {order?.client?.phone && (
            <h4>
              <div className='icon'>
                <MdOutlinePhoneIphone />
              </div>
              {order?.client?.phone_index?.name || ""}
              {order?.client?.phone || ""}
            </h4>
          )}
          {order?.client?.email && (
            <h4>
              <div className='icon'>
                <MdMailOutline />
              </div>
              {order?.client?.email}
            </h4>
          )}

          {order?.client?.address && (
            <h4>
              <div className='icon'>
                <RiMapPinLine />
              </div>
              {order?.client?.address}
            </h4>
          )}
        </div>
      </DModal>
    </Fragment>
  );
};

export default ShowCustomerModal;
