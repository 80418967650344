import React, { useEffect, useRef, useState } from "react";
import "./PlanningAssistantTableOrder.scss";

import moment from "moment";
import { Tooltip } from "@mui/material";

import { FaUserAlt } from "react-icons/fa";
import { HiOutlineStatusOnline } from "react-icons/hi";

import Character from "../../UI/Character/Character";
import OrderTooltip from "../Tooltips/OrderTooltip/OrderTooltip";

const PlanningAssistantTableOrder = ({ order, daysQty }) => {
  const planningAssistantOrderRef = useRef(null);
  const [orderSize, setOrderSize] = useState(0);

  const paddingX = 8;
  const startDate =
    moment(order?.pickup_date).diff(moment(new Date()).format("YYYY-MM-DD"), "days") >= 0
      ? moment(order?.pickup_date).diff(moment(new Date()).format("YYYY-MM-DD"), "days")
      : -0.1;
  const endDate =
    moment(order?.dropoff_date).diff(moment(new Date()).format("YYYY-MM-DD"), "days") + 1 <= daysQty
      ? moment(order?.dropoff_date).diff(moment(new Date()).format("YYYY-MM-DD"), "days") + 1
      : daysQty + 0.1;

  useEffect(() => {
    setOrderSize(planningAssistantOrderRef.current?.getBoundingClientRect()?.width / 70);
  }, [order]);

  return (
    <Tooltip
      placement='top'
      id='PlanningAssistantTableOrderTooltip'
      title={<OrderTooltip order={order} />}
      classes={{ tooltip: "PlanningAssistantTableOrderTooltip" }}
    >
      <div
        id='PlanningAssistantTableOrder'
        ref={planningAssistantOrderRef}
        style={{
          top: "22px", // 24px - 2px top border
          left: startDate * 70 + "px", // 70px + 1px border
          width: (endDate - startDate) * 70 + "px", // 70px + 1px border and 4px order border
          paddingLeft: paddingX,
          paddingRight: paddingX,
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <div className='leftSide'>
          <div className='orderIcon'>
            <HiOutlineStatusOnline />
          </div>
          <div className='orderRoute'>
            <Character
              title={
                orderSize >= 5
                  ? order?.pickup + " - " + order?.dropoff
                  : order?.pickup_country_code + " - " + order?.dropoff_country_code
              }
              max={30}
            />
          </div>
        </div>
        <div className='rightSide'>
          <div className='driverIcon'>
            <FaUserAlt />
          </div>
          {orderSize >= 5 && (
            <div className='driverName'>{<Character title={order?.driver?.name} max={15} />}</div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default PlanningAssistantTableOrder;
