import React from "react";
import "./TrailersNewOrder.scss";

import { useTranslation } from "react-i18next";

import NewOrderTitle from "../Components/NewOrderTitle/NewOrderTitle";
import TrailerManagement from "./TrailerManagement/TrailerManagement";

const TrailersNewOrder = ({ edit, group, setGroup }) => {
  const { t } = useTranslation();

  return (
    <div id='TrailersNewOrder'>
      <NewOrderTitle title={t("tms.titles.Customer requirements")} />
      <div className='TrailersNewOrderWrapper'>
        <TrailerManagement edit={edit} group={group} setGroup={setGroup} />
      </div>
    </div>
  );
};

export default TrailersNewOrder;
