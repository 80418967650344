import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import preferencesService from "./preferencesService";

const initialState = {
  getPreferenceLoading: false,
  getPreferenceResult: null,
  getPreferenceError: null,

  storePreferenceLoading: false,
  storePreferenceResult: null,
  storePreferenceError: null,

  updatePreferenceLoading: false,
  updatePreferenceResult: null,
  updatePreferenceError: null,
};

export const getPreference = createAsyncThunk("preferences/getPreference", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await preferencesService.getPreference(ISO, TOKEN);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const storePreference = createAsyncThunk(
  "preferences/storePreference",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await preferencesService.storePreference(ISO, TOKEN, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePreference = createAsyncThunk(
  "preferences/updatePreference",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await preferencesService.updatePreference(ISO, TOKEN, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    resetStorePreference: (state) => {
      state.storePreferenceLoading = false;
      state.storePreferenceResult = null;
      state.storePreferenceError = null;
    },
    resetUpdatePreference: (state) => {
      state.updatePreferenceLoading = false;
      state.updatePreferenceResult = null;
      state.updatePreferenceError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPreference.pending, (state) => {
        state.getPreferenceLoading = true;
        state.getPreferenceResult = null;
        state.getPreferenceError = null;
      })
      .addCase(getPreference.fulfilled, (state, action) => {
        state.getPreferenceLoading = false;
        state.getPreferenceResult = action.payload;
        state.getPreferenceError = null;
      })
      .addCase(getPreference.rejected, (state, action) => {
        state.getPreferenceLoading = false;
        state.getPreferenceResult = null;
        state.getPreferenceError = action.payload;
      })

      .addCase(storePreference.pending, (state) => {
        state.storePreferenceLoading = true;
        state.storePreferenceResult = null;
        state.storePreferenceError = null;
      })
      .addCase(storePreference.fulfilled, (state, action) => {
        state.storePreferenceLoading = false;
        state.storePreferenceResult = action.payload;
        state.storePreferenceError = null;
      })
      .addCase(storePreference.rejected, (state, action) => {
        state.storePreferenceLoading = false;
        state.storePreferenceResult = null;
        state.storePreferenceError = action.payload;
      })
      .addCase(updatePreference.pending, (state) => {
        state.updatePreferenceLoading = true;
        state.updatePreferenceResult = null;
        state.updatePreferenceError = null;
      })
      .addCase(updatePreference.fulfilled, (state, action) => {
        state.updatePreferenceLoading = false;
        state.updatePreferenceResult = action.payload;
        state.updatePreferenceError = null;
      })
      .addCase(updatePreference.rejected, (state, action) => {
        state.updatePreferenceLoading = false;
        state.updatePreferenceResult = null;
        state.updatePreferenceError = action.payload;
      });
  },
});

export const { resetStorePreference, resetUpdatePreference } = preferencesSlice.actions;
export default preferencesSlice.reducer;
