import React, { useState, useEffect } from "react";
import "./MaintenanceListHeader.scss";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { FiPlus } from "react-icons/fi";

import DSearch from "../../../FilterForms/DSearch/DSearch";
import DButton from "../../../Form/DButtons/DButton/DButton";
import FilterDateRangeForm from "../../../FilterForms/FilterDateRangeForm/FilterDateRangeForm";

const MaintenanceListHeader = ({ setKeyword, date, setDate, setOpenAddModal }) => {
  const { t } = useTranslation();

  const [keywordValue, setKeywordValue] = useState("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setKeyword(keywordValue);
    }, 500);
    return () => clearInterval(timeout);
  }, [keywordValue, setKeyword]);

  return (
    <div id='MaintenanceListHeader'>
      <div className='MaintenanceListHeaderLeft'>
        <DSearch value={keywordValue} setValue={(e) => setKeywordValue(e)} />
        <FilterDateRangeForm
          label={t("tms.filters.placeholders.Date range")}
          value={
            Object.keys(date).length > 0
              ? `${date.startDate ? moment(date.startDate).format("DD/MM/YYYY") : ""} - ${
                  date.endDate ? moment(date.endDate).format("DD/MM/YYYY") : ""
                }`
              : ""
          }
          setValue={setDate}
        />
      </div>
      <div>
        <DButton
          title={t("tms.forms.buttons.Add expense")}
          rightIcon={<FiPlus />}
          onClick={() => {
            setOpenAddModal(true);
          }}
        />
      </div>
    </div>
  );
};

export default MaintenanceListHeader;
