import React from "react";
import "./OrderStatus.scss";

import { FaCheckCircle } from "react-icons/fa";

const OrderStatus = ({ status, done, disabled, currentStatusColors }) => {
  return (
    <div id='OrderStatus'>
      <div
        className='OrderStatusContainer'
        style={{ background: currentStatusColors?.background }}
      >
        {done && (
          <div className='icon' style={{ color: currentStatusColors?.text }}>
            <FaCheckCircle />
          </div>
        )}
        <div
          className={`OrderStatusName ${disabled && "disabled"}`}
          style={{ color: currentStatusColors?.text }}
        >
          {status}
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;
