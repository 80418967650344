import React from "react";
import "./DetailsFreightItem.scss";

const DetailsFreightItem = ({ cover, title, content, delay }) => {
  return (
    <div id='DetailsFreightItem' className='DetailsFreightItem'>
      <div
        data-aos='fade-up'
        data-aos-duration='400'
        data-aos-delay={delay ? delay : "0"}
        data-aos-once='true'
      >
        <div className='DetailsFreightItemContainer'>
          <div
            className='cover'
            style={{ backgroundImage: `url(${cover})` }}
          ></div>
          <h1> {title}</h1>
          <h3>{content}</h3>
        </div>
      </div>
    </div>
  );
};

export default DetailsFreightItem;
