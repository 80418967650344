import React from "react";
import "./LoadingStatus.scss";

const LoadingStatus = () => {
  return (
    <td id='LoadingStatusOrderField'>
      <div className='LoadingStatusOrderFieldcontent'></div>
    </td>
  );
};

export default LoadingStatus;
