import React from "react";
import "./SignUpShipperSuccessForm.scss";

import { useTranslation } from "react-i18next";
import { FiChevronRight } from "react-icons/fi";
import { useDispatch } from "react-redux";

import { getUser } from "../../../../store/auth/authSlice";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import Button from "../../../Form/Button/Button";
import CheckIcon from "../../Components/CheckIcon/CheckIcon";

const SignUpShipperSuccessForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const submitHandler = () => {
    dispatch(getUser());
  };

  return (
    <AuthFormWrapper>
      <div id='SignUpShipperSuccessForm' className='SignUpShipperSuccessForm'>
        <FormTitle title={t("landing.auth.titles.Account activated")}>
          <span>{t("landing.auth.titles.Welcome to the team")}</span>
        </FormTitle>
        <CheckIcon />
        <Button
          loading={false}
          title={t("landing.auth.form.Go to your dashboard")}
          rightIcon={<FiChevronRight />}
          onClick={() => {
            submitHandler();
          }}
        />
      </div>
    </AuthFormWrapper>
  );
};

export default SignUpShipperSuccessForm;
