import _request from "./../_request";

const getGeneralAnalytics = async (ISO, token, date) => {
  const config = {
    url: `/dashboard/tms-analytics?date=${date}`,
    ISO,
    token,
  };
  return _request(config);
};

const getChartAnalytics = async (ISO, token, date) => {
  const config = {
    url: `/dashboard/tms-chart-analytics?date=${date}`,
    ISO,
    token,
  };
  return _request(config);
};

const getShipperAnalytics = async (ISO, token, date) => {
  const config = {
    url: `/dashboard/shipper-analytics?date=${date}`,
    ISO,
    token,
  };
  return _request(config);
};

const getShipperChartAnalytics = async (ISO, token, date) => {
  const config = {
    url: `/dashboard/shipper-chart-analytics?date=${date}`,
    ISO,
    token,
  };
  return _request(config);
};

const getShipperActiveOrders = async (ISO, TOKEN, data) => {
  let url = "order?&statuses[]=3&statuses[]=4&statuses[]=5&statuses[]=6";
  const { page, status_id } = data || {};

  if (status_id) {
    if (typeof status_id === "object") {
      for (let i = 0; i < status_id?.length; i++) {
        url += `&client_statuses[]=${status_id[i]}`;
      }
    } else {
      url += `&client_statuses[]=${status_id}`;
    }
  }

  url += page ? `&page=${page}` : "";

  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const analyticsService = {
  getGeneralAnalytics,
  getChartAnalytics,
  getShipperAnalytics,
  getShipperChartAnalytics,
  getShipperActiveOrders,
};

export default analyticsService;
