import React, { useEffect, useRef, useState } from "react";
import "./CountrySwitcher.scss";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { FiX } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";

import { setCountryId } from "../../../../store/additional/settings/settingsSlice";
import { setCountryInUrl } from "../../../../helper/setCountryInUrl";

const CountrySwitcher = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { getCountriesResult, countryId } = useSelector((state) => state.settings);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const onChange = (country) => {
    dispatch(setCountryId(country?.id));
    setIsDropdownOpen(false);
    setCountryInUrl(country?.country?.code?.toLowerCase());
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isDropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };
    setTimeout(() => {
      document.addEventListener("mouseup", handleOutsideClick);
    }, 0);

    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [isDropdownOpen, setIsDropdownOpen]);

  return (
    <div id='CountrySwitcher' className={isOpen ? "open" : "none"}>
      <div className='title'>
        {t(
          "landing.titles.landingMainTitlesDesc.Choose office to see content specific to your location."
        )}
      </div>
      <div className='switcherContainer'>
        <div
          className='switcherWrapper'
          onMouseUp={() => setIsDropdownOpen((state) => !state)}
        >
          <div className='switcher'>
            <div className='switcherTitle'>
              {getCountriesResult &&
                getCountriesResult?.find((country) => country?.id === countryId)?.country
                  ?.name}{" "}
            </div>
          </div>
          <div className='switcherIcon'>
            <IoIosArrowDown />
          </div>
          <div
            className='dropdown'
            ref={dropdownRef}
            style={{ display: isDropdownOpen ? "flex" : "none" }}
          >
            {getCountriesResult?.map((option) => (
              <div
                className='item'
                key={option?.id}
                onClick={() => onChange(option)}
              >
                {option?.country?.name}
              </div>
            ))}
          </div>
        </div>
        <div className='close' onClick={() => setIsOpen(false)}>
          <FiX />
        </div>
      </div>
    </div>
  );
};

export default CountrySwitcher;
