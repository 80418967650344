import React, { Fragment } from "react";
import "./PageSwitcher.scss";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

const PageSwitcher = ({ name, id, badge }) => {
  const { t } = useTranslation();

  let NAVIGATIONS;
  switch (name) {
    case "customer":
      NAVIGATIONS = [
        {
          label: t("tms.menu.Customers"),
          to: "/dashboard/tms/customers_partners/customers",
          active: true,
        },
        {
          label: t("tms.menu.Partners"),
          to: "/dashboard/tms/customers_partners/partners",
        },
      ];
      break;
    case "partner":
      NAVIGATIONS = [
        {
          label: t("tms.menu.Customers"),
          to: "/dashboard/tms/customers_partners/customers",
        },
        {
          label: t("tms.menu.Partners"),
          to: "/dashboard/tms/customers_partners/partners",
          active: true,
        },
      ];
      break;
    case "drivers":
      NAVIGATIONS = [
        {
          label: t("tms.menu.Trucks"),
          to: "/dashboard/tms/fleet_management/trucks",
        },
        {
          label: t("tms.menu.Trailers"),
          to: "/dashboard/tms/fleet_management/trailers",
        },
        {
          label: t("tms.menu.Drivers"),
          active: true,
          to: "/dashboard/tms/fleet_management/drivers",
        },
        {
          label: t("tms.menu.Maintenance"),
          to: "/dashboard/tms/fleet_management/maintenance",
        },
      ];
      break;
    case "trailers":
      NAVIGATIONS = [
        {
          label: t("tms.menu.Trucks"),
          to: "/dashboard/tms/fleet_management/trucks",
        },
        {
          label: t("tms.menu.Trailers"),
          active: true,
          to: "/dashboard/tms/fleet_management/trailers",
        },
        {
          label: t("tms.menu.Drivers"),
          to: "/dashboard/tms/fleet_management/drivers",
        },
        {
          label: t("tms.menu.Maintenance"),
          to: "/dashboard/tms/fleet_management/maintenance",
        },
      ];
      break;
    case "trucks":
      NAVIGATIONS = [
        {
          label: t("tms.menu.Trucks"),
          active: true,
          to: "/dashboard/tms/fleet_management/trucks",
        },
        {
          label: t("tms.menu.Trailers"),
          to: "/dashboard/tms/fleet_management/trailers",
        },
        {
          label: t("tms.menu.Drivers"),
          to: "/dashboard/tms/fleet_management/drivers",
        },
        {
          label: t("tms.menu.Maintenance"),
          to: "/dashboard/tms/fleet_management/maintenance",
        },
      ];
      break;
    case "maintenance":
      NAVIGATIONS = [
        {
          label: t("tms.menu.Trucks"),
          to: "/dashboard/tms/fleet_management/trucks",
        },
        {
          label: t("tms.menu.Trailers"),
          to: "/dashboard/tms/fleet_management/trailers",
        },
        {
          label: t("tms.menu.Drivers"),
          to: "/dashboard/tms/fleet_management/drivers",
        },
        {
          label: t("tms.menu.Maintenance"),
          active: true,
          to: "/dashboard/tms/fleet_management/maintenance",
        },
      ];
      break;
    case "partnerOrdersTaken":
      NAVIGATIONS = [
        {
          label: t("tms.menu.Taken Order"),
          to: `/dashboard/tms/customers_partners/partners/${id}?tabe=taken_orders`,
          active: true,
        },
        {
          label: t("tms.menu.Assign Carriers"),
          to: `/dashboard/tms/customers_partners/partners/${id}?tabe=assign_carriers`,
        },
      ];
      break;
    case "partnerOrdersAssign":
      NAVIGATIONS = [
        {
          label: t("tms.menu.Taken Order"),
          to: `/dashboard/tms/customers_partners/partners/${id}?tabe=taken_orders`,
        },
        {
          label: t("tms.menu.Assign Carriers"),
          to: `/dashboard/tms/customers_partners/partners/${id}?tabe=assign_carriers`,
          active: true,
        },
      ];
      break;
    case "boards":
      NAVIGATIONS = [
        {
          label: t("tms.menu.All Loads"),
          to: "/dashboard/tms/load_boards",
          active: true,
        },
        {
          label: t("tms.menu.My Bids"),
          to: "/dashboard/tms/load_boards/bids",
        },
      ];
      break;
    case "bids":
      NAVIGATIONS = [
        {
          label: t("tms.menu.All Loads"),
          to: "/dashboard/tms/load_boards",
        },
        {
          label: t("tms.menu.My Bids"),
          to: "/dashboard/tms/load_boards/bids",
          active: true,
        },
      ];
      break;
    case "financialinformationPending":
      NAVIGATIONS = [
        {
          badge: badge,
          label: t("tms.menu.Pending"),
          to: "/dashboard/tms/financial/active",
          active: true,
        },
        {
          label: t("tms.menu.Paid Orders"),
          to: "/dashboard/tms/financial/finished",
        },
      ];
      break;
    case "financialinformationFinished":
      NAVIGATIONS = [
        {
          badge: badge,
          label: t("tms.menu.Pending"),
          to: "/dashboard/tms/financial/active",
        },
        {
          label: t("tms.menu.Paid Orders"),
          to: "/dashboard/tms/financial/finished",
          active: true,
        },
      ];
      break;
    case "GPS":
      NAVIGATIONS = [
        {
          label: t("tms.menu.Connect to GPS"),
          to: "/dashboard/tms/tracker/GPS_tracking",
          active: true,
        },
        {
          label: t("tms.menu.Connect to APP"),
          to: "/dashboard/tms/tracker/mobile_tracking",
        },
      ];
      break;
    case "Mobile":
      NAVIGATIONS = [
        {
          label: t("tms.menu.Connect to GPS"),
          to: "/dashboard/tms/tracker/GPS_tracking",
        },
        {
          label: t("tms.menu.Connect to APP"),
          to: "/dashboard/tms/tracker/mobile_tracking",
          active: true,
        },
      ];
      break;
    case "shipperOrders":
      NAVIGATIONS = [
        {
          badge: badge,
          label: t("shipper.menu.Active quotations"),
          to: "/dashboard/shipper/orders",
          active: true,
        },
        {
          label: t("shipper.menu.Declined"),
          to: "/dashboard/shipper/orders/reject",
        },
      ];
      break;
    case "shipperOrdersReject":
      NAVIGATIONS = [
        {
          badge: badge,
          label: t("shipper.menu.Active quotations"),
          to: "/dashboard/shipper/orders",
        },
        {
          label: t("shipper.menu.Declined"),
          to: "/dashboard/shipper/orders/reject",
          active: true,
        },
      ];
      break;

    default:
      NAVIGATIONS = [];
      break;
  }

  return (
    <Fragment>
      <div id='PageSwitcher'>
        <ul className='list'>
          {NAVIGATIONS.map((item, index) => (
            <li
              key={index}
              className={item.active && item.active ? "active" : "false"}
            >
              <Link to={item.to}>
                <p id={item.badge && "BadgePadding"}>{item.label}</p>
                {item.badge > 0 && (
                  <div className='PageSwitcherBadge'>
                    <h3>{item.badge > 99 ? "99+" : item.badge}</h3>
                  </div>
                )}
              </Link>
              <div className='line'></div>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

export default PageSwitcher;
