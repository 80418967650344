import React, { Fragment, useState, useEffect } from "react";
import "./OrderStatusBar.scss";

import { useTranslation } from "react-i18next";

import { FaArrowRight } from "react-icons/fa6";
import { PiClockCounterClockwiseBold } from "react-icons/pi";
import { useSelector, useDispatch } from "react-redux";

import {
  updateOrderStatuses,
  resetUpdateOrderStatuses,
} from "../../../store/orders/orderStatuses/orderStatusesSlice";

import Skeleton from "@mui/material/Skeleton";

import OrderStatus from "./components/OrderStatus/OrderStatus";
import DButton from "../../Form/DButtons/DButton/DButton";
import DModal from "../../Modal/DModal/DModal";
import DTextarea from "../../Form/DTextarea/DTextarea";

const OrderStatusBar = ({ order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateOrderStatusesResult, updateOrderStatusesError, updateOrderStatusesLoading } =
    useSelector((state) => state.orderStatuses);
  const { getTMSStatusesLoading, getTMSStatusesResult } = useSelector(
    (state) => state.orderStatuses
  );

  const [openModal, setOpenModal] = useState(false);
  const [customerMessage, setCustomerMessage] = useState("");

  const handleNextStep = () => {
    setOpenModal(true);
  };

  const listedStatuses =
    getTMSStatusesResult && getTMSStatusesResult.filter((status) => status.id !== 6);

  const handleSubmitMessage = (notify) => {
    dispatch(
      updateOrderStatuses({
        orderId: order && order?.id,
        status_id:
          listedStatuses[
            listedStatuses?.findIndex((status) => status.id === order.statusTms.id) + 1
          ].id,
        comment: notify ? customerMessage : null,
        notify,
      })
    );
  };

  useEffect(() => {
    if (updateOrderStatusesResult) {
      setOpenModal(false);
      dispatch(resetUpdateOrderStatuses());
    }
  }, [dispatch, updateOrderStatusesResult]);

  const renderStatuses = (statuses) => {
    if (statuses === undefined) {
      return null;
    }
    return (
      <Fragment>
        {statuses.map((status) => {
          const currentStatus = status.id === order?.statusTms.id;
          const doneStatus = status.id <= order?.statusTms.id;
          const lastStatus = status.id === statuses.length;

          return (
            <li className={status.id < order?.statusTms.id ? "active" : "passive"} key={status.id}>
              <div className='active' />
              <div className='passive' />
              <div className='dots'>
                <div
                  className='smallDot'
                  style={{
                    background: currentStatus
                      ? status?.color1
                      : status.id > order?.statusTms.id
                      ? "#f4f6f9"
                      : "",
                  }}
                />
                <div
                  className='largeDot'
                  style={{
                    background: status?.color2,
                    display: !currentStatus && "none",
                  }}
                />
              </div>
              {currentStatus && lastStatus ? (
                <div className='statusContainer'>
                  <OrderStatus
                    status={status.name}
                    done
                    currentStatusColors={{
                      background: status.color2,
                      text: status.color1,
                    }}
                  />
                </div>
              ) : currentStatus ? (
                <div className='currentStatusContainer'>
                  <OrderStatus
                    status={status.name}
                    done={doneStatus}
                    currentStatusColors={{
                      background: status.color2,
                      text: status.color1,
                    }}
                  />
                  {order?.statusTms.id === 2 && order?.total_revenue <= 0 ? (
                    <span title={t("tms.forms.noInfo.You need to add customer price to continue")}>
                      <DButton
                        title={t("tms.forms.buttons.Go to next step")}
                        rightIcon={<FaArrowRight />}
                        onClick={() => handleNextStep()}
                        disabled={true}
                      />
                    </span>
                  ) : (
                    <DButton
                      title={t("tms.forms.buttons.Go to next step")}
                      rightIcon={<FaArrowRight />}
                      onClick={() => handleNextStep()}
                      disabled={order?.transports?.find((i) => i?.number) ? false : true}
                    />
                  )}
                </div>
              ) : (
                <div className='statusContainer'>
                  <OrderStatus
                    status={status.name}
                    done={doneStatus}
                    current={currentStatus}
                    disabled={!doneStatus}
                  />
                </div>
              )}
            </li>
          );
        })}
      </Fragment>
    );
  };

  useEffect(() => {
    setCustomerMessage("");
    setOpenModal(false);
  }, [dispatch, updateOrderStatusesResult]);

  return (
    <Fragment>
      {getTMSStatusesLoading && (
        <Skeleton variant='rounded' width={"100%"} className='getCargoStatusesLoadingSkeleton' />
      )}

      {getTMSStatusesResult && (
        <div id='OrderStatusBar'>
          <ul className='progress-bar'>{renderStatuses(listedStatuses)}</ul>
        </div>
      )}
      <DModal
        icon={<PiClockCounterClockwiseBold />}
        title={t("tms.modals.titles.Want to send update to your customer?")}
        isClose={() => setOpenModal(false)}
        isOpen={openModal}
        submitText={t("tms.modals.buttons.Send update")}
        orderStatus
        onOrderStatusClick={() => handleSubmitMessage(false)}
        onClick={() => handleSubmitMessage(true)}
        loading={updateOrderStatusesLoading}
        disabled={order?.client?.email ? false : true}
      >
        <DTextarea
          id={"customerMessage"}
          value={customerMessage}
          setValue={setCustomerMessage}
          placeholder={t("tms.modals.placeholder.Message that you want to send customer...")}
          error={updateOrderStatusesError?.errors?.comment ?? null}
          disabled={order?.client?.email ? false : true}
        />
        {!order?.client?.email && (
          <div className='noCustomerMailText'>
            {t(
              "tms.modals.desc.*Unfortunately, it seems that the customer doesn’t have an email address, so if you want to change the order status then simply click on 'Move without update.'"
            )}
          </div>
        )}
      </DModal>
    </Fragment>
  );
};

export default OrderStatusBar;
