import React from "react";
import "./CreateOrderAdrSection.scss";

import { useTranslation } from "react-i18next";

import DCheckBox from "./../../../../Form/DCheckBox/DCheckBox";
import DInputs from "./../../../../Form/DInputs/DInputs";
import DFileForm from "./../../../../Form/DFile/DFileForm/DFileForm";
import DErrorText from "./../../../../Form/DErrorText/DErrorText";
import DFileFormItem from "../../../../Form/DFile/DFileForm/DFileFormItem";

const CreateOrderAdrSection = ({
  edit,
  adr,
  setAdr,
  adrError,
  adrNumber,
  setAdrNumber,
  adrNumberError,
  adrClass,
  setAdrClass,
  adrClassError,
  adrFile,
  setAdrFile,
}) => {
  const { t } = useTranslation();

  const removeFileHandler = (id) => {
    const copyArray = [...adrFile];
    const filterredCopyArrayy = copyArray.filter((i) => i.id !== id);
    setAdrFile(filterredCopyArrayy);
  };

  return (
    <div id='CreateOrderAdrSection'>
      <DCheckBox
        value={adr}
        setValue={() => {
          setAdr((state) => !state);
          setAdrNumber && setAdrNumber("");
          setAdrClass && setAdrClass("");
          setAdrFile && setAdrFile([]);
        }}
        label={t("shipper.forms.content.ADR cargo")}
      />
      <DErrorText error={adrError} />
      {(adr || adrNumberError || adrClassError) && (
        <div className='adrGroupContainer'>
          <DInputs
            value={adrNumber}
            setValue={(value) => setAdrNumber(value)}
            placeholder={t("shipper.forms.content.ADR UN number")}
            error={adrNumberError}
          />

          <DInputs
            value={adrClass}
            setValue={(value) => setAdrClass(value)}
            placeholder={t("shipper.forms.content.ADR class")}
            error={adrClassError}
          />

          {setAdrFile &&
            !edit &&
            adrFile.map((item, index) => (
              <DFileFormItem key={index} item={item} removeItem={(id) => removeFileHandler(id)} />
            ))}

          {setAdrFile && !edit && (
            <DFileForm
              value={adrFile}
              setValue={setAdrFile}
              title={t("shipper.forms.content.Upload cargo MSDS")}
              subTitle={t("shipper.forms.content.Optional")}
              type_id={51}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CreateOrderAdrSection;
