import React, { Fragment, useState, useEffect } from "react";
import "./PartnerRate.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Rating from "@mui/material/Rating";

import { ratePartner, resetRatePartner } from "./../../../store/partner/partnerSlice";

import LoadingPage from "../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const PartnerRate = ({ order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ratePartnerLoading, ratePartnerResult } = useSelector((state) => state.partner);

  const [rating, setRating] = useState(0);

  useEffect(() => {
    const shouldRate = rating > 0 && order?.partner?.id;
    if (shouldRate) {
      const data = {
        partnerId: order?.partner?.id || null,
        order_id: order?.id || null,
        rating: rating,
        comment: null,
      };

      dispatch(ratePartner(data));
    }
  }, [dispatch, rating, order]);

  useEffect(() => {
    if (ratePartnerResult) {
      dispatch(resetRatePartner());
    }
  }, [dispatch, ratePartnerResult]);

  return (
    <Fragment>
      {order?.partner?.id && order?.statusTms?.id === 5 && (
        <div id='PartnerRateSection'>
          {ratePartnerLoading ? (
            <LoadingPage />
          ) : (
            <Fragment>
              {order?.review ? (
                <div className='alreadyRate'>
                  <p>
                    {t("tms.desc.Your rate to the service that")} {order?.partner?.name || ""}{" "}
                    {t("tms.desc.provided for you.")}
                  </p>
                  <div className='rateList'>
                    <Rating
                      name='read-only'
                      value={order?.review?.rating || 0}
                      readOnly
                      precision={0.5}
                    />
                  </div>
                </div>
              ) : (
                <Fragment>
                  <h2>
                    {t("tms.desc.Rate")} {order?.partner?.name || ""}{" "}
                    {t("tms.desc.work on this shipment")}
                  </h2>
                  <p>
                    {t(
                      "tms.desc.The answer to this will only be visible for you to maintain overall working quality information to"
                    )}{" "}
                    {order?.partner?.name || ""}.
                  </p>
                  <div className='rateContainer'>
                    <h3>{t("tms.desc.Quality of work")}</h3>

                    <Rating
                      size='large'
                      classes={{ root: "ratePartner" }}
                      name='simple-controlled'
                      value={rating}
                      onChange={(event, newValue) => {
                        setRating(newValue);
                      }}
                    />
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default PartnerRate;
