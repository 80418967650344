import React from "react";

import { HiSparkles } from "react-icons/hi2";
import NameField from "../NameField/NameField";

const LocationFieldLoadBoards = ({ item }) => {
  const getLocation = (item, direction) => {
    const locationData = item && item.cargos[0].locations.filter((i) => i.direction === direction);

    const country = locationData && locationData[0]?.country;
    const city = locationData && locationData[locationData?.length - 1]?.city;

    return `${city ? city + ", " : ""}${country || ""}`;
  };
  const Alocation = getLocation(item, "A");
  const Blocation = getLocation(item, "B");
  const location = `${Alocation} - ${Blocation}`;

  return item?.taken ? (
    <NameField
      leftIcon={item?.preference && <HiSparkles color='#5e2bff' fontSize={16} />}
      max={60}
      label={location}
      onClick={() => {}}
    />
  ) : (
    <NameField
      leftIcon={item?.preference && <HiSparkles color='#5e2bff' fontSize={16} />}
      max={60}
      label={location}
      to={`/dashboard/tms/load_boards/${item.id}`}
    />
  );
};

export default LocationFieldLoadBoards;
