import React from "react";
import "./CoverSeaFreight.scss";

import { useTranslation } from "react-i18next";

import FadeTextAnimation from "./../../../UI/Animations/FadeTextAnimation/FadeTextAnimation";

const CoverSeaFreight = () => {
  const { t } = useTranslation();

  return (
    <section
      id='CoverSeaFreight'
      className='CoverSeaFreight'
      style={{
        backgroundImage: `url(${`/assets/images/static/cover16.jpg`})`,
      }}
    >
      <div className='blar'>
        <div className='wrapper'>
          <div className='container'>
            <div className='text'>
              <FadeTextAnimation
                text={t(
                  "landing.titles.landingMainTitles.Streamlined sea transportation services"
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoverSeaFreight;
