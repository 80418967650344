import React, { useEffect } from "react";
import "./BoardDetails.scss";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getBoard, resetGetBoard } from "../../../../../store/loadBoards/loadBoardsSlice";

import Wrapper from "./../../../../../componentsAdditional/Wrapper";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import DBackButton from "../../../../../componentsDashboard/Form/DButtons/DBackButton/DBackButton";
import AddressDetails2 from "../../../../../componentsDashboard/Orders/AddressDetails/AddressDetails2/AddressDetails2";
import TrailerDetails2 from "../../../../../componentsDashboard/Orders/TrailerDetails/TrailerDetails2/TrailerDetails2";
import ProductDetails2 from "../../../../../componentsDashboard/Orders/ProductDetails/ProductDetails2/ProductDetails2";
import AdditionalInformationDetails from "../../../../../componentsDashboard/Orders/AdditionalInformationDetails/AdditionalInformationDetails";
import BoardDetailAction from "../../../../../componentsDashboard/LoadBoards/BoardDetailAction/BoardDetailAction";
import PageNotFound from "../../../../../componentsBids/PageNotFound/PageNotFound";

const BoardDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { boardId } = useParams();

  const {
    getBoardLoading,
    getBoardResult,
    getBoardError,
    biddingBoardResult,
    takeBoardResult,
  } = useSelector((state) => state.loadBoards);

  useEffect(() => {
    dispatch(getBoard(boardId));
    return () => {
      dispatch(resetGetBoard());
    };
  }, [dispatch, boardId, biddingBoardResult, takeBoardResult]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Board details")}>
      {getBoardLoading && <LoadingPage />}
      {getBoardError && <PageNotFound />}
      {getBoardResult && !getBoardLoading && (
        <section id='BoardDetails'>
          <DBackButton alt='/dashboard/tms/load_boards?page=1' />
          <BoardDetailAction order={getBoardResult} />
          <div className='BoardDetailsContainer'>
            <AddressDetails2 getOrderResult={getBoardResult} />
            <TrailerDetails2 getOrderResult={getBoardResult} />
            <ProductDetails2 getOrderResult={getBoardResult} />
          </div>
          <AdditionalInformationDetails getOrderResult={getBoardResult} />
        </section>
      )}
    </Wrapper>
  );
};

export default BoardDetails;
