import React from "react";
import "./Listing.scss";

import ListingHeader from "../Components/ListingHeader/ListingHeader";
import TrackingTruckList from "./TrackingTruckList/TrackingTruckList";
import NoTrackingTruckList from "./NoTrackingTruckList/NoTrackingTruckList";

const Listing = ({
  tab,
  setTab,
  keyword,
  setKeyword,
  items,
  activeItem,
  setActiveItem,
  activeItemNoTracking,
  setActiveItemNoTracking,
}) => {
  return (
    <div id='TrackingListing'>
      <ListingHeader tab={tab} setTab={setTab} />

      {tab === "tracking" ? (
        <TrackingTruckList
          keyword={keyword}
          setKeyword={setKeyword}
          items={items && items.filter((item) => item.location !== null)}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      ) : (
        <NoTrackingTruckList
          keyword={keyword}
          setKeyword={setKeyword}
          items={items && items.filter((item) => item.location === null)}
          activeItemNoTracking={activeItemNoTracking}
          setActiveItemNoTracking={setActiveItemNoTracking}
        />
      )}
    </div>
  );
};

export default Listing;
