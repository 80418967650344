import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import shipperCalendarService from "./shipperCalendarService";

const initialState = {
  getShipperCalendarLoading: true,
  getShipperCalendarResult: null,
  getShipperCalendarError: null,
};

export const getShipperCalendar = createAsyncThunk(
  "shipperCalendar/getShipperCalendar",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await shipperCalendarService.getShipperCalendar(ISO, TOKEN, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const shipperCalendarSlice = createSlice({
  name: "shipperCalendar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShipperCalendar.pending, (state) => {
        state.getShipperCalendarLoading = true;
        state.getShipperCalendarResult = null;
        state.getShipperCalendarError = null;
      })
      .addCase(getShipperCalendar.fulfilled, (state, action) => {
        state.getShipperCalendarLoading = false;
        state.getShipperCalendarResult = action.payload;
        state.getShipperCalendarError = null;
      })
      .addCase(getShipperCalendar.rejected, (state, action) => {
        state.getShipperCalendarLoading = false;
        state.getShipperCalendarResult = null;
        state.getShipperCalendarError = action.error;
      });
  },
});

export default shipperCalendarSlice.reducer;
