import React from "react";
import NoMoreData from "../NoMoreData/NoMoreData";
import "./NoData.scss";

const NoData = () => {
  return (
    <div id='OrderSidebarNoData'>
      <p>Active shipments not found.</p>
      <NoMoreData />
    </div>
  );
};

export default NoData;
