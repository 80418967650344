import React from "react";
import "./DigitalCover.scss";

const DigitalCover = ({ url }) => {
  return (
    <div id='DigitalCover'>
      <div
        className='img'
        style={{
          backgroundImage: `url(${url})`,
        }}
      ></div>
    </div>
  );
};

export default DigitalCover;
