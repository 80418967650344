import React from "react";
import "./ForAboutShipper.scss";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import AboutItem from "./../Components/AboutItem/AboutItem";
import HomeTitle from "../../Components/HomeTitle/HomeTitle";

const ForAboutShipper = () => {
  const { t } = useTranslation();

  return (
    <section id='ForAboutShipper' className='ForAboutShipper'>
      <div className='wrapper'>
        <HomeTitle
          description={t(
            "landing.titles.landingMainTitlesDesc.Your trusted partner for tailored logistics excellence. We specialize in custom logistics strategies, provide exceptional customer service, and leverage cutting-edge technology to optimize your supply chain"
          )}
        >
          {parse(
            t(
              "landing.titles.landingMainTitles.<span>Streamline</span> logistics solutions"
            )
          )}
        </HomeTitle>

        <div className='container'>
          <AboutItem
            url='/assets/images/static/cover1.jpg'
            title={t("landing.titles.landingForAboutTitle.Tailored solutions")}
            content={t(
              "landing.bodyText.landingForAboutBody.Cargon specializes in providing highly customized solutions to meet the unique needs of each client. We work closely with businesses to understand their specific logistics challenges and design strategies that optimize their supply chain, ensuring efficient and cost-effective operations."
            )}
            buttonTitle={t("landing.form.button.Join now")}
            link='/register'
            delay='400'
          />

          <AboutItem
            url='/assets/images/static/cover2.jpg'
            title={t(
              "landing.titles.landingForAboutTitle.Exceptional customer service"
            )}
            content={t(
              "landing.bodyText.landingForAboutBody.We pride ourselves on providing exceptional customer service, offering real-time support, transparent communication, and a dedicated team that goes the extra mile to ensure our clients' shipments are handled with care and delivered on time."
            )}
            buttonTitle={t("landing.form.button.Join now")}
            link='/register'
            delay='250'
          />

          <AboutItem
            url='/assets/images/static/cover3.jpg'
            title={t(
              "landing.titles.landingForAboutTitle.Data-driven decisions"
            )}
            content={t(
              "landing.bodyText.landingForAboutBody.Our technology provides our clients with advanced tracking, analytics, and reporting tools. Cargon allows businesses to gain full visibility into their supply chain, make informed decisions, and continuously improve their logistics processes."
            )}
            buttonTitle={t("landing.form.button.Join now")}
            link='/register'
            delay='100'
          />
        </div>
      </div>
    </section>
  );
};

export default ForAboutShipper;
