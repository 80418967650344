import React from "react";
import "./DButton.scss";

import ButtonLoader from "../ButtonLoader/ButtonLoader";

const DButton = ({
  onClick,
  rightIcon,
  leftIcon,
  loading,
  title,
  disabled,
}) => {
  return (
    <div id='DButton' className={disabled ? "disabled" : ""}>
      <button
        disabled={disabled}
        type='button'
        onClick={(e) => {
          !loading && !disabled && onClick(e);
        }}
      >
        {!loading && leftIcon && (
          <div className='buttonIcon leftIconButton'>{leftIcon}</div>
        )}
        {loading ? <ButtonLoader /> : title}
        {!loading && rightIcon && (
          <div className='buttonIcon rightIconButton'>{rightIcon}</div>
        )}
      </button>
    </div>
  );
};

export default DButton;
