import React, { Fragment } from "react";
import "./CreateOrderTopMenu.scss";

import Character from "./../../../../UI/Character/Character";

const CreateOrderTopMenu = ({ items }) => {
  const RenderItem = ({ item }) => {
    return (
      <div className='CreateOrderTopMenuItemWrapper'>
        <div className='icon'>{item.icon}</div>

        <h3>
          <Character max={30} title={item?.title ? item.title : "-"} />
        </h3>
      </div>
    );
  };

  return (
    <Fragment>
      <div id='CreateOrderTopMenu'>
        <div className='CreateOrderTopMenuContainer'>
          {items.map((item, index) => (
            <RenderItem key={index} item={item} />
          ))}
        </div>
      </div>
      <div id='CreateOrderTopMenuBackgdrop'></div>
    </Fragment>
  );
};

export default CreateOrderTopMenu;
