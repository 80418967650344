import React from "react";
import "./ForAbout.scss";

import { useTranslation } from "react-i18next";

import AboutItem from "./../Components/AboutItem/AboutItem";
import HomeTitle from "../../Components/HomeTitle/HomeTitle";

const ForAbout = () => {
  const { t } = useTranslation();

  return (
    <section id='ForAbout' className='ForAbout'>
      <div className='wrapper'>
        <HomeTitle
          animation={[
            t("landing.titles.landingMainTitles.sea"),
            t("landing.titles.landingMainTitles.road"),
            t("landing.titles.landingMainTitles.air"),
          ]}
          description={t(
            "landing.titles.landingMainTitlesDesc.A single platform for all your shipments. Ship with confidence on road, in the sky and across the oceans."
          )}
        >
          {t("landing.titles.landingMainTitles.Move your freight by")}
        </HomeTitle>

        <div className='container'>
          <AboutItem
            url='/assets/images/static/cover17.jpg'
            title={t("landing.titles.landingForAboutTitle.Road")}
            content={t(
              "landing.bodyText.landingForAboutBody.Access to our connected fleet of thousands of trucks."
            )}
            buttonTitle={t("landing.form.button.Learn more")}
            link='/services/land'
            delay='400'
          />

          <AboutItem
            url='/assets/images/static/cover16.jpg'
            title={t("landing.titles.landingForAboutTitle.Sea")}
            content={t(
              "landing.bodyText.landingForAboutBody.Cross the ocean reliable, thanks for oceans of data."
            )}
            buttonTitle={t("landing.form.button.Learn more")}
            link='/services/sea'
            delay='250'
          />

          <AboutItem
            url='/assets/images/static/cover21.jpg'
            title={t("landing.titles.landingForAboutTitle.Air")}
            content={t(
              "landing.bodyText.landingForAboutBody.A reliable partner network for capacity on premium carriers."
            )}
            buttonTitle={t("landing.form.button.Learn more")}
            link='/services/air'
            delay='100'
          />
        </div>
      </div>
    </section>
  );
};

export default ForAbout;
