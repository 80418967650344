import React, { Fragment } from "react";
import "./InfoWindow.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Address from "../../CardComponents/Address/Address";
import Driver from "../../CardComponents/Driver/Driver";
import Trailer from "../../CardComponents/Trailer/Trailer";
import Header from "../../CardComponents/Header/Header";
import Time from "../../CardComponents/Time/Time";

const InfoWindow = ({ item }) => {
  const { t } = useTranslation();
  return (
    <div id='TrackingMarkerContainer'>
      <Header item={item} />
      <Time item={item} />

      {item?.driver && (
        <Fragment>
          <div className='line'></div>
          <Driver item={item} />
          <div className='line'></div>
        </Fragment>
      )}
      {item?.trailer && (
        <Fragment>
          <Trailer item={item} />
          <div className='line'></div>
        </Fragment>
      )}
      {item?.orders?.length > 0 && (
        <Fragment>
          <Address item={item} />
          <div className='link'>
            <Link
              to={`/dashboard/tms/orders/${item?.orders[0]?.id}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {t("tms.forms.buttons.See full order")}
            </Link>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default InfoWindow;
