import React from "react";
import "./SelectTrailerModalHeader.scss";

import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

const SelectTrailerModalHeader = ({ truckName, isClose }) => {
  const { t } = useTranslation();
  return (
    <div id='SelectTrailerModalHeader'>
      <div className='title'>
        <h1>{t("tms.desc.Select suitable trailer to link the truck")}</h1>
        <h1>
          <span>{truckName}</span>
        </h1>
      </div>

      <div className='SelectTrailerModalHeaderIcon' onClick={isClose}>
        <MdClose />
      </div>
    </div>
  );
};

export default SelectTrailerModalHeader;
