import React, { Fragment, useState, useEffect } from "react";
import "./SelectTrailerModal.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  getTrailers,
  resetGetTrailers,
} from "./../../../../store/fleet/trailers/trailersSlice";

import DOverlay from "../../../Modal/DOverlay/DOverlay";
import DSearch from "./../../../FilterForms/DSearch/DSearch";
import SelectTrailerModalTable from "./SelectTrailerModalTable/SelectTrailerModalTable";
import SelectTrailerModalHeader from "./SelectTrailerModalHeader/SelectTrailerModalHeader";
import AddTrailersModal from "./../AddTrailersModal/AddTrailersModal";
import DTextButton from "./../../../Form/DButtons/DTextButton/DTextButton";
import LoadingPage from "./../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import FilterLinkedSelect from "../../../FilterForms/FilterLinkedSelect/FilterLinkedSelect";

const SelectTrailerModal = ({ isOpen, isClose, truck }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getTrailersLoading, getTrailersResult, createTrailerResult } =
    useSelector((state) => state.trailers);
  const { manageTruckResult } = useSelector((state) => state.trucks);

  const [openAddTrailerModal, setOpenAddTrailerModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [linkedTo, setLinkedTo] = useState(null);

  const truckName =
    truck && truck.model && truck.model.brand.name + " " + truck.model.name;

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getTrailers({
          keyword,
          has_truck: linkedTo?.value,
        })
      );
    }
    return () => {
      dispatch(resetGetTrailers());
    };
  }, [
    dispatch,
    isOpen,
    createTrailerResult,
    manageTruckResult,
    keyword,
    linkedTo,
  ]);

  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      {truck && (
        <div id='SelectTrailerModal'>
          <div className='SelectTrailerModalcontent'>
            <SelectTrailerModalHeader isClose={isClose} truckName={truckName} />
            <Fragment>
              <div className='SelectTrailerModalTableHeader'>
                <div className='SelectTrailerModalTableHeaderFilters'>
                  <DSearch value={keyword} setValue={setKeyword} />
                  <FilterLinkedSelect value={linkedTo} setValue={setLinkedTo} />
                </div>

                <DTextButton
                  title={t("tms.forms.buttons.Add trailer")}
                  rightIcon={<FiPlus />}
                  onClick={() => {
                    setOpenAddTrailerModal(true);
                  }}
                />
              </div>
              {getTrailersLoading && <LoadingPage />}
              {getTrailersResult && (
                <SelectTrailerModalTable
                  truckId={truck.id}
                  trailers={getTrailersResult}
                />
              )}
            </Fragment>
          </div>

          <AddTrailersModal
            isOpen={openAddTrailerModal}
            isClose={setOpenAddTrailerModal}
          />
        </div>
      )}
    </DOverlay>
  );
};

export default SelectTrailerModal;
