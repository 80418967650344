import React from "react";
import "./Contact.scss";

import { useTranslation } from "react-i18next";

import ContactOffice from "./../../../../componentsLanding/Contact/ContactOffice/ContactOffice";
import Wrapper from "./../../../../componentsAdditional/Wrapper";
import Form from "./../../../../componentsLanding/Contact/Form/Form";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Contact")}>
      <section id='Contact' className='Contact'>
        <div className='wrapper'>
          <div className='contactContainer'>
            <ContactOffice />
            <Form />
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default Contact;
