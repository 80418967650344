import React, { Fragment } from "react";
import "./ShipperTracking.scss";

import { useTranslation } from "react-i18next";

import TrackingHeader from "./TrackingHeader/TrackingHeader";
import TrackingDriverData from "./TrackingDriverData/TrackingDriverData";
import TrackingDistanceData from "./TrackingDistanceData/TrackingDistanceData";
import TrackingUpdatesData from "./TrackingUpdatesData/TrackingUpdatesData";

const ShipperTracking = ({ order }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {order && (
        <div id='ShipperTrackingSection'>
          <TrackingHeader order={order} />
          <TrackingDriverData order={order} />
          {order?.statusClient?.id <= 2 ? (
            <div className='noTracking'>
              <h2>{t("shipper.desc.Shipment has not started")}</h2>
            </div>
          ) : order?.statusClient?.id >= 3 && order?.last_shipment_status_update ? (
            <Fragment>
              <TrackingDistanceData order={order} />
              <TrackingUpdatesData order={order} />
            </Fragment>
          ) : (
            <div className='noTracking'>
              <h2>{t("shipper.desc.Tracking not available")}</h2>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ShipperTracking;
