import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  Dashboard,
  Profile,
  Notifications,
  OrderList,
  RejectOrderList,
  OrderDetails,
  AcceptedOrders,
  FinancialManagement,
  CreateRoadOrder,
  CreateOceanOrder,
  CreateAirOrder,
  EditRoadOrder,
  EditOceanOrder,
  EditAirOrder,
  Calendar,
} from "../pages/Dashboard/Shipper/index";
import DelayedRedirect from "../componentsAdditional/DelayedRedirect";

const Shipper = () => {
  return (
    <Routes>
      <Route path='/dashboard/shipper/home' element={<Dashboard />} />
      <Route path='/dashboard/shipper/profile' element={<Profile />} />
      <Route path='/dashboard/shipper/orders'>
        <Route index element={<OrderList />} />
        <Route path='reject' element={<RejectOrderList />} />
        <Route path='new/road' element={<CreateRoadOrder />} />
        <Route path='new/ocean' element={<CreateOceanOrder />} />
        <Route path='new/air' element={<CreateAirOrder />} />
        <Route path='edit/road/:orderId' element={<EditRoadOrder />} />
        <Route path='edit/ocean/:orderId' element={<EditOceanOrder />} />
        <Route path='edit/air/:orderId' element={<EditAirOrder />} />
        <Route path=':orderId' element={<OrderDetails />} />
      </Route>
      <Route path='/dashboard/shipper/billing' element={<FinancialManagement />} />
      <Route path='/dashboard/shipper/accepted' element={<AcceptedOrders />} />
      <Route path='/dashboard/shipper/calendar' element={<Calendar />} />
      <Route path='/dashboard/shipper/notifications' element={<Notifications />} />
      <Route path='*' element={<DelayedRedirect to='/dashboard/shipper/home' delay={0} />} />
    </Routes>
  );
};

export default Shipper;
