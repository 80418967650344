import _request from "../_request";

const getOrderData = async (ISO, urlToken) => {
  const config = {
    url: `/bidding/request/${urlToken}`,
    ISO,
  };
  return _request(config);
};

const getBidsCurrency = async (ISO, BranchId) => {
  const config = {
    url: `/currency/${BranchId}`,
    ISO,
  };
  return _request(config);
};

const sendBid = async (ISO, orderId, token,data) => {
  const config = {
    url: `/bidding/${orderId}/make-bid/${token}`,
    method: "post",
    ISO,
  };
  return _request(config, data);
};

const biddingService = { getOrderData, getBidsCurrency, sendBid };

export default biddingService;
