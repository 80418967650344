import React, { Fragment, useState } from "react";
import "./InvoicesMenu.scss";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";

import InvoiceModal from "./../../Orders/InvoiceModal/InvoiceModal";
import DButton from "../../Form/DButtons/DButton/DButton";
import AddVatModal from "../../LoadBoards/Modals/AddVatModal/AddVatModal";

const InvoicesMenu = ({ item }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showVatModal, setShowVatModal] = useState(false);

  const hasVat = user?.company?.legal_id;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const invoiceDocuments = item?.attachments?.filter((doc) => doc.type.id === 15);
  return (
    <Fragment>
      {invoiceDocuments?.length === 0 ? (
        <div id='GenerateInvoiceButton'>
          <DButton
            title={t("tms.invoice.Generate Invoice")}
            onClick={() => {
              hasVat ? setShowInvoiceModal(true) : setShowVatModal(true);
            }}
            rightIcon={<LiaFileInvoiceDollarSolid />}
          />
        </div>
      ) : (
        <div id='InvoicesMenu'>
          <div className='viewInvoicesButton' onClick={handleClick}>
            {t("tms.invoice.View Invoices")}
            <div className='viewInvoicesButtonIcon'>
              <IoIosArrowDown />
            </div>
          </div>
          <Menu
            id='PaymentTableInvoicesMenu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              style={{
                minWidth: "180px",
                borderBottom: "1px solid #eaebeb",
              }}
            >
              <div
                onClick={() => {
                  handleClose();
                  hasVat ? setShowInvoiceModal(true) : setShowVatModal(true);
                }}
                style={{
                  textAlign: "center",
                  width: "100%",
                  color: "white",
                  fontSize: "12px",
                  background: "#1a2023",
                  padding: "4px 8px",
                  borderRadius: "4px",
                }}
              >
                {t("tms.invoice.Generate Invoice")}
              </div>
            </MenuItem>
            {invoiceDocuments?.map((item) => (
              <MenuItem
                key={item.id}
                style={{
                  minWidth: "150px",
                  fontSize: "15px",
                  color: "#2e5aac",
                }}
                onClick={() => {
                  window.open(item.link, "_blank");
                  handleClose();
                }}
              >
                {item.type.name}{" "}
                <span
                  style={{
                    fontWeight: "lighter",
                    marginLeft: "5px",
                  }}
                >
                  ({item.id})
                </span>
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}

      {showInvoiceModal && (
        <InvoiceModal isOpen={showInvoiceModal} isClose={setShowInvoiceModal} order={item} />
      )}
      {showVatModal && <AddVatModal isOpen={showVatModal} isClose={() => setShowVatModal(false)} />}
    </Fragment>
  );
};

export default InvoicesMenu;
