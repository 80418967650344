import React from "react";
import "./FilterProductCategorySelect.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";

const FilterProductCategorySelect = ({ value, setValue }) => {
  const { t } = useTranslation();
  const { getCargoTypesSelect, getCargoTypesLoading } = useSelector((state) => state.orderSettings);

  const ControlComponent = ({ children, ...props }) => {
    return (
      <components.Control {...props} className={value ? "selected" : ""}>
        {children}
      </components.Control>
    );
  };

  return (
    <div id='FilterProductCategorySelect'>
      <Select
        classNamePrefix='select'
        name='filterProductCategorySelect'
        className='selectComponent'
        options={getCargoTypesSelect || []}
        placeholder={t("shipper.filters.Product category")}
        isLoading={getCargoTypesLoading}
        value={value}
        onChange={(state) => setValue(state)}
        isClearable={true}
        isSearchable={true}
        noOptionsMessage={() => <div>{t("shipper.filters.No product category found")}</div>}
        components={
          value
            ? {
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
                Control: ControlComponent,
              }
            : {
                IndicatorSeparator: () => null,
                Control: ControlComponent,
              }
        }
      />
    </div>
  );
};

export default FilterProductCategorySelect;
