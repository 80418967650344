import React, { useState, useEffect } from "react";
import "./TalkExpert.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ReactModal from "react-modal";
import { FiX } from "react-icons/fi";

import { containsOnlyNumbers } from "./../../../../helper/useContainNum";
import { createLead, resetCreateLead } from "../../../../store/additional/lead/leadSlice";

import Input from "../../../Form/Inputs/Input";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import Label from "../../../Form/Label/Label";
import ConvertInput from "../../../Form/Inputs/ConvertInput/ConvertInput";
import RoleRadio from "../../../Form/Radio/RoleRadio/RoleRadio";
import TermsAgree from "../../../Form/Checkbox/TermsAgree/TermsAgree";
import Button from "../../../Form/Button/Button";
import TextArea from "../../../Form/TextArea/TextArea";

ReactModal.setAppElement("#root");

const TalkExpert = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createLeadLoading, createLeadResult, createLeadError } = useSelector(
    (state) => state.lead
  );
  const { phoneIndexValueId, countryId } = useSelector((state) => state.settings);

  const [name, setName] = useState("");
  const [method, setMethod] = useState("EMAIL");
  const [contactValue, setContactValue] = useState("");
  const [role, setRole] = useState("");
  const [message, setMessage] = useState("");
  const [agree, setAgree] = useState(true);

  useEffect(() => {
    const onlyNumber = containsOnlyNumbers(contactValue);
    if (contactValue.length > 2 && onlyNumber) {
      setMethod("PHONE");
    } else {
      setMethod("EMAIL");
    }
  }, [contactValue]);

  const reset = () => {
    setName("");
    setMethod("EMAIL");
    setContactValue("");
    setRole("");
    setMessage("");
    setAgree(true);
  };

  const submitHandler = () => {
    let data;
    if (method === "EMAIL") {
      data = {
        branch_id: countryId,
        name: name,
        type: role,
        email: contactValue,
        message: message.length < 1 ? "No message" : message,
        agreement: agree,
      };
    } else {
      data = {
        branch_id: countryId,
        name: name,
        type: role,
        phone_index_id: phoneIndexValueId,
        phone: contactValue,
        message: message.length < 1 ? "No message" : message,
        agreement: agree,
      };
    }
    dispatch(createLead(data));
  };

  const onClose = () => {
    setOpen(false);
    dispatch(resetCreateLead());
    reset();
  };

  useEffect(() => {
    dispatch(resetCreateLead());
    reset();
  }, [dispatch]);

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={() => {
        onClose();
      }}
      overlayClassName='TalkExpertOverlay'
      className='TalkExpertContainer'
    >
      <div id='TalkExpert'>
        <div
          data-aos='fade-left'
          data-aos-delay='50'
          data-aos-duration='400'
          data-aos-easing='ease-in-out'
        >
          <div
            className='container'
            style={{
              backgroundImage: `url(${`/assets/images/map/WorldMap.svg`})`,
            }}
          >
            <div className='header'>
              <h1>
                {createLeadResult
                  ? t("landing.titles.landingMainTitles.Success")
                  : t("landing.titles.landingMainTitles.Let’s start")}
              </h1>
              <div
                onClick={() => {
                  onClose();
                }}
                className='icon'
              >
                <FiX />
              </div>
            </div>

            {createLeadResult ? (
              <div className='text'>
                <p>
                  {t(
                    "landing.titles.landingMainTitlesDesc.Thank you for sharing your contact information. We'll make sure to get in touch with you as soon as possible."
                  )}
                </p>
              </div>
            ) : (
              <form className='form'>
                <div>
                  <Label text={t("landing.form.label.*Tell us your name")} id='Name' />
                  <Input
                    value={name}
                    setValue={setName}
                    placeholder={t("landing.form.placeholder.Enter your name")}
                    id='Name'
                    error={createLeadError?.errors?.name?.[0]}
                  />
                  <ErrorText error={createLeadError?.errors?.name?.[0]} />
                </div>

                <div>
                  <Label text={t("landing.form.label.*Area of interest")} />
                  <RoleRadio value={role} setValue={setRole} />
                  <ErrorText error={createLeadError?.errors?.type?.[0]} />
                </div>

                <div>
                  <Label
                    text={t("landing.form.label.*Work email or phone number")}
                    id='convertContainer'
                  />
                  <ConvertInput
                    autoFocus={contactValue.length > 2 ? true : false}
                    inputStatus={method}
                    value={contactValue}
                    setValue={setContactValue}
                    id='convertContainer'
                    error={
                      createLeadError?.errors?.email?.[0] ||
                      createLeadError?.errors?.phone_index_id?.[0] ||
                      createLeadError?.errors?.phone?.[0]
                    }
                    placeholder={t("landing.form.placeholder.Enter email or phone number")}
                  />
                  {method === "EMAIL" && <ErrorText error={createLeadError?.errors?.email?.[0]} />}
                  {method !== "EMAIL" &&
                    (<ErrorText error={createLeadError?.errors?.phone_index_id?.[0]} /> ||
                      (method !== "EMAIL" && (
                        <ErrorText error={createLeadError?.errors?.phone?.[0]} />
                      )))}
                </div>

                <div>
                  <Label text={t("landing.form.label.Reasons your contact")} id='Message' />
                  <TextArea
                    placeholder={t("landing.form.placeholder.Your message...")}
                    name='Message'
                    id='Message'
                    value={message}
                    setValue={setMessage}
                    error={createLeadError?.errors?.message?.[0]}
                  />
                  <ErrorText error={createLeadError?.errors?.message?.[0]} />
                </div>

                <div>
                  <TermsAgree value={agree} setValue={setAgree} />
                  <ErrorText error={createLeadError?.errors?.agreement?.[0]} />
                  <ErrorText error={createLeadError?.errors?.branch_id?.[0]} />
                </div>
              </form>
            )}

            <div className='submit'>
              <Button
                title={
                  createLeadResult ? t("landing.form.button.Close") : t("landing.form.button.Send")
                }
                onClick={createLeadResult ? () => onClose() : () => submitHandler()}
                loading={createLeadLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default TalkExpert;
