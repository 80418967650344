import React from "react";

import { useTranslation } from "react-i18next";

import CustomersTableItem from "./CustomersTableItem/CustomersTableItem";
import TableHeader from "../../../Form/TableFields/TableHeader/TableHeader";
import TableWrapper from "../../../Form/TableFields/TableWrapper/TableWrapper";

const CustomersTable = ({ customers, page, setPage, pageCount, total }) => {
  const { t } = useTranslation();

  return (
    <TableWrapper
      total={total}
      emptyText={t("tms.tables.noInfo.no customer")}
      page={page}
      setPage={setPage}
      pageCount={pageCount}
    >
      <TableHeader>
        <th>{t("tms.tables.titles.Company name")}</th>
        <th>{t("tms.tables.titles.Company ID")}</th>
        <th>{t("tms.tables.titles.Email")}</th>
        <th>{t("tms.tables.titles.Number of shipment")}</th>
        <th colSpan='2'>{t("tms.tables.titles.Date of last shipment")}</th>
      </TableHeader>
      <tbody>
        {customers.map((item) => (
          <CustomersTableItem key={item.id} item={item} />
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default CustomersTable;
