import React from "react";
import "./CheckBoxField.scss";

import { FiCheck } from "react-icons/fi";

const CheckBoxField = ({ value, setValue, disabled }) => {
  return (
    <div
      id='CheckBoxField'
      onClick={!disabled ? setValue : null}
      className={disabled ? "disabled" : ""}
    >
      <div className={value ? "checked icon" : "icon"}>
        {value && <FiCheck />}
      </div>
    </div>
  );
};

export default CheckBoxField;
