import React, { Fragment, useState, useEffect } from "react";
import "./PartnerContactTable.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  getPartnerContacts,
  resetGetPartnerContacts,
} from "../../../../../store/partner/partnerContacts/partnerContactsSlice";

import DTextButton from "./../../../../Form/DButtons/DTextButton/DTextButton";
import NoInformation from "./../../../../Form/TableFields/NoInformation/NoInformation";
import TableHeader from "../../../../Form/TableFields/TableHeader/TableHeader";
import PartnerContactTableItem from "./PartnerContactTableItem/PartnerContactTableItem";
import CreatePartnerContactModal from "../CreatePartnerContactModal/CreatePartnerContactModal";

const PartnerContactTable = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    getPartnerContactsResult,
    createPartnerContactResult,
    updatePartnerContactResult,
    deletePartnerContactResult,
  } = useSelector((state) => state.partnerContacts);

  const [openCreateModal, setOpenCreateModal] = useState(false);

  useEffect(() => {
    dispatch(getPartnerContacts(id));
    return () => {
      dispatch(resetGetPartnerContacts());
    };
  }, [
    dispatch,
    id,
    createPartnerContactResult,
    updatePartnerContactResult,
    deletePartnerContactResult,
  ]);

  return (
    <Fragment>
      {getPartnerContactsResult && (
        <div id='PartnerContactTable'>
          <div className='headerContact'>
            <h2>{t("tms.titles.Contact")}</h2>
            <DTextButton
              title={t("tms.forms.buttons.Add contact")}
              rightIcon={<FiPlus />}
              onClick={() => {
                setOpenCreateModal(true);
              }}
            />
          </div>
          <div className='tableWrapper'>
            <table>
              <TableHeader>
                <th>{t("tms.tables.titles.Full name")}</th>
                <th>{t("tms.tables.titles.Position")}</th>
                <th>{t("tms.tables.titles.Phone number")}</th>
                <th colSpan='2'>{t("tms.tables.titles.Email")}</th>
              </TableHeader>
              <tbody>
                {getPartnerContactsResult.map((item) => (
                  <PartnerContactTableItem key={item.id} item={item} id={id} />
                ))}
              </tbody>
            </table>
            {getPartnerContactsResult.length === 0 && (
              <NoInformation title={t("tms.tables.noInfo.Contact not found")} />
            )}
          </div>
        </div>
      )}
      {openCreateModal && (
        <CreatePartnerContactModal
          id={id}
          isOpen={openCreateModal}
          isClose={() => setOpenCreateModal(false)}
        />
      )}
    </Fragment>
  );
};

export default PartnerContactTable;
