import React from "react";
import "./QuotesTableHeader.scss";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const QuotesTableHeader = ({ badge }) => {
  const { t } = useTranslation();
  return (
    <div id='QuotesTableHeader'>
      <div className='QuotesTableHeaderTitle'>
        <p id={badge && "BadgePadding"}>{t("shipper.titles.Quotes")}</p>
        {badge > 0 && (
          <div className='PageSwitcherBadge'>
            <h3>{badge > 99 ? "99+" : badge}</h3>
          </div>
        )}
      </div>
      <div className='SeeAllButton'>
        <Link to='/dashboard/shipper/orders'>
          {t("shipper.buttons.See all")}
        </Link>
      </div>
    </div>
  );
};

export default QuotesTableHeader;
