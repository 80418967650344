import React from "react";
import "./Profile.scss";

import Wrapper from "./../../../../componentsAdditional/Wrapper";
import ProfileHeader from "../../../../componentsDashboard/Profile/ProfileHeader/ProfileHeader";
import ProfileCompanyInfo from "../../../../componentsDashboard/Profile/ProfileCompanyInfo/ProfileCompanyInfo";
import ProfileSettings from "../../../../componentsDashboard/Profile/ProfileSettings/ProfileSettings";

const Profile = () => {
  return (
    <Wrapper documentTitle='Profile'>
      <section id='userProfile'>
        <div className='profileHeaderBackground' />
        <ProfileHeader />
        <ProfileCompanyInfo />
        <ProfileSettings />
      </section>
    </Wrapper>
  );
};

export default Profile;
