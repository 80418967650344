import _request from "./../../_request";

const updateOrderRevenue = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `/order/${id}/revenue`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateOrderExpense = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `/order/${id}/expense`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const getPayments = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `order/${orderId}/payment-management`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createPayment = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `/order/${orderId}/payment-management`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updatePayment = async (ISO, TOKEN, orderId, paymentId, data) => {
  const config = {
    url: `/order/${orderId}/payment-management/${paymentId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deletePayment = async (ISO, TOKEN, orderId, paymentId) => {
  const config = {
    url: `order/${orderId}/payment-management/${paymentId}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const updateClientPaid = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `/order/${orderId}/client-paid`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const orderFinanceService = {
  updateOrderRevenue,
  updateOrderExpense,
  createPayment,
  updatePayment,
  getPayments,
  deletePayment,
  updateClientPaid,
};

export default orderFinanceService;
