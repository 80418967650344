import React from "react";
import "./TrailerNeedDetails.scss";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import Character from "../../componentsDashboard/UI/Character/Character";

const TrailerNeedDetailsItem = ({ title, text }) => {
  const parsedText = parse(text?.toString() || "");
  return (
    <div className='item'>
      <div className='itemTitle'>{title}</div>
      <div className='itemText'>
        <Character title={parsedText} max={50} />
      </div>
    </div>
  );
};
const TrailerNeedDetails = ({ order }) => {
  const { t } = useTranslation();
  const cargo = order?.cargos?.[0];

  return (
    <div id='TrailerNeedDetails'>
      <div className='titleContainer'>
        <div
          className='icon'
          style={{ backgroundImage: `url(${cargo?.trailerType?.trailer_icon})` }}
        ></div>
        <div className='title'>{t("partnerBid.Trailer need")}</div>
      </div>
      <div className='items'>
        <TrailerNeedDetailsItem
          title={`${t("partnerBid.Type")}:`}
          text={cargo?.trailerType?.name || ""}
        />
        <TrailerNeedDetailsItem
          title={`${t("partnerBid.Volume")}:`}
          text={cargo?.trailersize?.label || ""}
        />
        {cargo?.temperature && (
          <TrailerNeedDetailsItem
            title={`${t("partnerBid.Temperature")}:`}
            text={cargo?.temperature + "°C"}
          />
        )}
      </div>

      {order?.about && (
        <div className='additionalInfoitem'>
          <div className='additionalInfoitemTitle'>{t("partnerBid.Additional information")}:</div>
          <div className='additionalInfoitemText'>
            <div>{order?.about}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrailerNeedDetails;
