import React from "react";
import "./Driver.scss";

import { useTranslation } from "react-i18next";
import { MdPerson, MdOutlinePhoneIphone } from "react-icons/md";
import { useSelector } from "react-redux";

import Character from "./../../../../UI/Character/Character";

const Driver = ({ item }) => {
  const { t } = useTranslation();
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const currentIndex =
    getPhoneIndexesResult &&
    getPhoneIndexesResult.find((i) => i.id === item.driver.phone_index_id);

  return (
    <div id='InfoWindowDriver'>
      <div className='title'>
        <h3>{t("tms.titles.Driver")}</h3>
      </div>

      <div className='desc'>
        <div className='name'>
          <div className='icon'>
            <MdPerson />
          </div>
          <h3>
            <Character title={item.driver?.name} max={14} />
          </h3>
        </div>
        <div className='tel'>
          <div className='icon'>
            <MdOutlinePhoneIphone />
          </div>
          <h3>
            <Character
              title={currentIndex.phone_index + item.driver.phone}
              max={13}
            />
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Driver;
