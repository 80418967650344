import React from "react";
import "./ListingSwitcher.scss";

import { useTranslation } from "react-i18next";
import Character from "./../../../UI/Character/Character";

const ListingSwitcher = ({ tab, onClick }) => {
  const { t } = useTranslation();

  return (
    <div id='ListingSwitcher'>
      <ul className='list'>
        <li
          className={tab === "tracking" ? "active" : ""}
          onClick={() => onClick("tracking")}
        >
          <div>
            {/* <p>Tracking (7)</p> */}

            <p>
              <Character title={t("tms.menu.Tracking")} max={13} />
            </p>
          </div>
          <div className='line'></div>
        </li>
        <li
          className={tab === "noTracking" ? "active" : ""}
          onClick={() => onClick("noTracking")}
        >
          <div>
            {/* <p>No tracking (2)</p> */}

            <p>
              {" "}
              <Character title={t("tms.menu.No Tracking")} max={13} />
            </p>
          </div>
          <div className='line'></div>
        </li>
      </ul>
    </div>
  );
};

export default ListingSwitcher;
