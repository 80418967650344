import React, { useRef } from "react";
import "./GPSTruckTableSelect.scss";

import Select from "react-select";
import DLabel from "../../../Form/DLabel/DLabel";
import DErrorText from "../../../Form/DErrorText/DErrorText";

const GPSTruckTableSelect = ({
  id,
  label,
  required,
  options,
  value,
  setValue,
  placeholder,
  disabled,
  loading,
  clear,
  search,
  emptyText,
  loadingText,
  error,
  separator,
  filterOption,
  setKeyword,
}) => {
  // const filterOption = (option, inputValue) => {
  //   const { value } = option;
  //   return value.name.toLowerCase().includes(inputValue.toLowerCase());
  // };

  const selectRef = useRef(null);

  let customClassNames = `${error ? "error" : ""} ${
    disabled ? "disabled" : ""
  } `;
  const openSelect = () => {
    if (selectRef.current !== null) {
      selectRef.current.openAfterFocus = true;
      selectRef.current.focus();
    }
  };

  return (
    <div id='GPSTruckTableSelect' className={customClassNames}>
      {label && (
        <DLabel label={label} required={required} onClick={openSelect} />
      )}
      <Select
        ref={selectRef}
        className={separator ? "selectComponent separator" : "selectComponent"}
        classNamePrefix='select'
        placeholder={placeholder ? placeholder : ""}
        isDisabled={disabled ? disabled : false}
        isLoading={loading ? loading : false}
        isClearable={clear ? clear : false}
        isSearchable={search ? search : false}
        name={id}
        options={options ? options : []}
        value={value}
        onChange={(state) => setValue(state)}
        noOptionsMessage={() => (
          <div>{emptyText ? emptyText : "No information"}</div>
        )}
        loadingMessage={() => <div>{loadingText ? loadingText : ""}</div>}
        // getOptionLabel={(option) => option.value.id}
        filterOption={filterOption ? filterOption : undefined}
        onInputChange={(state) => {
          setKeyword && setKeyword(state);
        }}
        // menuPortalTarget={document.body}

        // menuPosition='fixed'
        // defaultMenuIsOpen={true}
        // menuIsOpen={true}
        // onMenuOpen={() => setMenuPosition(true)}
        // onMenuClose={() => setMenuPosition(false)}
      />
      {/* {menuPosition && <div className='buttton'>buttton</div>} */}
      {error && <DErrorText error={error} />}
    </div>
  );
};

export default GPSTruckTableSelect;
