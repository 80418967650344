import React from "react";
import "./Address.scss";

import { useTranslation } from "react-i18next";
import { useFormatedDistance } from "./../../../../../helper/useFormatedDistance";

import { MdLocationPin, MdLocationSearching } from "react-icons/md";

const Address = ({ item }) => {
  const { t } = useTranslation();

  const locations = item?.orders[0].cargos[0]?.locations || [];
  const directionA = locations?.filter((location) => {
    return location.direction === "A";
  });
  const directionB = locations?.filter((location) => {
    return location.direction === "B";
  });

  return (
    <div id='InfoWindowAddress'>
      <div className='titleContainer'>
        <h3>{t("tms.titles.Route")}</h3>
        {item.orders[0].distance ? (
          <div className='routeDistance'>
            {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useFormatedDistance(item.orders[0].distance, t("tms.desc.KM"))
            }
          </div>
        ) : null}
      </div>
      <ul className='InfoWindowAddressdesc'>
        {directionA &&
          directionA.length > 0 &&
          directionA.map((item, i) => (
            <li className='item' key={i}>
              <div className='icon'>
                <MdLocationSearching />
              </div>
              <h4>{item.formatted_address}</h4>
            </li>
          ))}

        {directionB &&
          directionB.length > 0 &&
          directionB.map((item, i) => (
            <li className='item' key={i}>
              <div className='icon'>
                <MdLocationPin />
              </div>
              <h4>{item.formatted_address}</h4>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Address;
