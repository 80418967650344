import _request from "./../../_request";

const assignCarrier = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `/order/${orderId}/assign-carrier`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const unassignCarrier = async (ISO, TOKEN, orderId) => {
  const config = {
    url: `/order/${orderId}/unassign-carrier`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createTransport = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `/order/${orderId}/transport`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updateTransport = async (ISO, TOKEN, orderId, transportId, data) => {
  const config = {
    url: `/order/${orderId}/transport/${transportId}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const bidsRequest = async (ISO, TOKEN, orderId, data) => {
  const config = {
    url: `/bidding/${orderId}/send-request`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const manageOrderService = {
  assignCarrier,
  unassignCarrier,
  createTransport,
  updateTransport,
  bidsRequest,
};

export default manageOrderService;
