import React, { Fragment, useState, useEffect } from "react";
import "./EditPhoneModal.scss";

import { useTranslation } from "react-i18next";
import { FaMobileAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { verify, resetVerify } from "../../../../../store/user/userSlice";
import { sendOtp, resetSendOtp } from "../../../../../store/auth/authSlice";

import DModal from "../../../../Modal/DModal/DModal";
import DPhoneInput from "../../../../Form/DPhoneInput/DPhoneInput";
import OTP from "../../../../../componentsLanding/Form/OTP/OTP";

const EditPhoneModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { verifyLoading, verifyResult, verifyError } = useSelector((state) => state.user);
  const { sendOtpResult, sendOtpLoading, sendOtpError } = useSelector((state) => state.auth);

  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const [phone, setPhone] = useState(user?.phone || "");
  const [phoneIndex, setPhoneIndex] = useState(null);
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");

  const handleFirstSubmit = () => {
    dispatch(
      sendOtp({
        type: "phone",
        new_contact: phone,
        phone_index_id: phoneIndex?.value,
      })
    );
  };

  const handleSecondSubmit = () => {
    dispatch(
      verify({
        type: "phone",
        code: otp,
        new_contact: phone,
        phone_index_id: phoneIndex?.value,
      })
    );
  };

  useEffect(() => {
    if (user?.phone_index_id) {
      const phoneIndex = getPhoneIndexesResult?.find((item) => item.id === user?.phone_index_id);

      setPhoneIndex({
        value: phoneIndex?.id,
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundImage: `url(${phoneIndex.flag})`,
                backgroundPosition: "cover",
                backgroundRepeat: "no-repeat",
                marginRight: "5px",
              }}
            ></div>
            <p>{phoneIndex.phone_index}</p>
          </div>
        ),
      });
    }
  }, [getPhoneIndexesResult, user?.phone_index_id]);

  useEffect(() => {
    if (sendOtpResult) {
      dispatch(resetSendOtp());
      setShowOtp(true);
    }
  }, [dispatch, sendOtpResult]);

  useEffect(() => {
    if (verifyResult) {
      dispatch(resetVerify());
      setShowOtp(false);
      isClose();
    }
  }, [dispatch, isClose, verifyResult]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={() => {
        setShowOtp(false);
        isClose();
      }}
      title={
        showOtp
          ? t("tms.modals.titles.Enter verification code")
          : t("tms.modals.titles.Edit phone number")
      }
      icon={<FaMobileAlt />}
      status={"info"}
      submitText={showOtp ? t("tms.modals.buttons.Verify") : t("tms.modals.buttons.Send code")}
      loading={verifyLoading || sendOtpLoading}
      onClick={() => (showOtp ? handleSecondSubmit() : handleFirstSubmit())}
    >
      <div id='profileEditPhone'>
        <form className='formGroup'>
          {showOtp ? (
            <Fragment>
              <div className='otpTitle'>{t("tms.modals.others.Enter verification code")}</div>
              <OTP
                count={4}
                value={otp}
                setValue={setOtp}
                error={verifyError?.errors?.code?.[0] || verifyError?.error}
              />
            </Fragment>
          ) : (
            <DPhoneInput
              id={"editProfilePhone"}
              value={phone}
              setValue={setPhone}
              indexValue={phoneIndex}
              setIndexValue={setPhoneIndex}
              label={t("tms.modals.labels.Phone number")}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              required
              error={sendOtpError?.errors?.new_contact?.[0]}
            />
          )}
        </form>
      </div>
    </DModal>
  );
};

export default EditPhoneModal;
