import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FiTrash2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import {
  deleteBankAccount,
  resetDeleteBankAccount,
} from "../../../../../store/user/bankAccounts/bankAccountsSlice";

import DModal from "./../../../../Modal/DModal/DModal";

const DeleteBankRequisitesModal = ({ isOpen, isClose, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { deleteBankAccountLoading, deleteBankAccountResult } = useSelector(
    (state) => state.bankAccounts
  );

  const submitHanlder = () => {
    const data = {
      id: item.id,
    };
    dispatch(deleteBankAccount(data));
  };

  useEffect(() => {
    if (deleteBankAccountResult) {
      dispatch(resetDeleteBankAccount());
      isClose();
    }
  }, [dispatch, isClose, deleteBankAccountResult]);

  return (
    <DModal
      isOpen={isOpen}
      isClose={isClose}
      icon={<FiTrash2 />}
      onClick={() => submitHanlder()}
      title={t("tms.modals.titles.Delete bank requisite")}
      status='danger'
      submitText={t("tms.modals.buttons.Delete")}
      loading={deleteBankAccountLoading}
    >
      <p>{t("tms.modals.desc.Are you sure you want to delete this requisite?")}</p>
    </DModal>
  );
};

export default DeleteBankRequisitesModal;
