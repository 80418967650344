import React from "react";
import "./CoverCarrier.scss";

import { useTranslation } from "react-i18next";

import FadeTextAnimation from "../../../UI/Animations/FadeTextAnimation/FadeTextAnimation";

const CoverCarrier = () => {
  const { t } = useTranslation();

  return (
    <section
      id='CoverCarrier'
      className='CoverCarrier'
      style={{
        backgroundImage: `url(${`/assets/images/static/cover4.jpg`})`,
      }}
    >
      <div className='blar'>
        <div className='wrapper'>
          <div className='container'>
            <div className='text'>
              <div className='desc'>
                <p>{t("landing.titles.landingMainTitlesDesc.For carriers")}</p>
              </div>
              <FadeTextAnimation
                text={t(
                  "landing.titles.landingMainTitles.Unlock your full potential as a carrier with cargon"
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoverCarrier;
