import React from "react";
import "./ProgressField.scss";

import { useTranslation } from "react-i18next";

const ProgressField = ({ progress, order }) => {
  const { t } = useTranslation();
  return (
    <td id='ProgressFieldBar'>
      <div className='ProgressFieldBarWrapper'>
        {progress === 0 && <p>{t("shipper.desc.Not started")}</p>}
        {(progress === null || progress === undefined) && order?.statusClient?.id <= 2 && (
          <p>{t("shipper.desc.Shipment has not started")}</p>
        )}
        {(progress === null || progress === undefined) && order?.statusClient?.id >= 3 && (
          <p>{t("shipper.desc.Tracking not available")}</p>
        )}
        {progress !== null && progress !== undefined && (
          <div className='progressContainer'>
            <div className='color' style={{ width: `${progress}%` }}></div>
          </div>
        )}
      </div>
    </td>
  );
};

export default ProgressField;
