import React from "react";
import "./Home.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "./../../../componentsAdditional/Wrapper";
import Cover from "./../../../componentsLanding/Landing/Covers/Cover/Cover";
import ForAbout from "./../../../componentsLanding/Landing/Abouts/ForAbout/ForAbout";
import ForCustomers from "./../../../componentsLanding/Landing/ForCustomers/ForCustomers";
import ForWorkflow from "./../../../componentsLanding/Landing/Workflows/ForWorkflow/ForWorkflow";
import JoinUs from "./../../../componentsLanding/Landing/Joins/JoinUs/JoinUs";
import LandingMap from "./../../../componentsLanding/Landing/LandingMap/LandingMap";
// import PartnerCompanies from "./../../../componentsLanding/Landing/PartnerCompanies/PartnerCompanies";
import FaqSection from "./../../../componentsLanding/Landing/FAQs/FaqSection/FaqSection";
import NeedMoreInfo from "../../../componentsLanding/Landing/NeedMoreInfo/NeedMoreInfo";

const Home = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Home")}>
      <Cover />
      <ForAbout />
      <ForWorkflow />
      <NeedMoreInfo />
      <ForCustomers />
      <LandingMap />
      <FaqSection />
      <JoinUs />
    </Wrapper>
  );
};

export default Home;
