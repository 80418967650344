import React from "react";
import "./NotificationItem.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFromNowDate } from "../../../helper/useFromNowDate";
import { BiSolidMessageRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import DButton from "./../../Form/DButtons/DButton/DButton";

const NotificationItem = ({ notification }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ISO } = useSelector((state) => state.lang);

  const inputDate = notification.created_at;
  const formattedDate = useFromNowDate(inputDate, ISO);

  return (
    <div
      id='NotificationItem'
      style={{
        background: !notification.read_at && "rgba(238, 242, 250, 0.50)",
      }}
    >
      <div className='NotificationItemLeft'>
        <div className='cover'>
          <div className='icon'>
            <BiSolidMessageRounded />
          </div>
        </div>
      </div>
      <div className='NotificationItemRight'>
        <div className='NotificationItemTitle'>
          {notification.data && notification.data.title && (
            <h1>{notification?.data?.title}</h1>
          )}
          <div className={!notification.read_at ? "dot show" : "dot"}></div>
        </div>

        {notification.data && notification.data.message && (
          <h3>{notification?.data?.message}</h3>
        )}
        <p>{formattedDate}</p>
        {notification.data &&
          notification.data.bid &&
          notification.data.bid.order &&
          notification.data.bid.order.id && (
            <DButton
              title={t("tms.notifications.Show order")}
              onClick={() => {
                navigate(
                  `/dashboard/tms/orders/${notification.data.bid.order.id}`
                );
                window.location.reload(true);
              }}
              loading={false}
            />
          )}
      </div>
    </div>
  );
};

export default NotificationItem;
