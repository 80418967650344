import React, { Fragment } from "react";
import "./NoTrackingTruckList.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import DSearch from "../../../FilterForms/DSearch/DSearch";
import NoTrackingTruckListItem from "./NoTrackingTruckListItem/NoTrackingTruckListItem";
import LoadingPage from "./../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const NoTrackingTruckList = ({
  items,
  keyword,
  setKeyword,
  activeItemNoTracking,
  setActiveItemNoTracking,
}) => {
  const { t } = useTranslation();
  const { getTrackingLoading } = useSelector((state) => state.trackerManager);

  return (
    <div id='NoTrackingTruckList'>
      <div className='NoTrackingTruckListSelect'>
        <DSearch value={keyword} setValue={setKeyword} />
      </div>
      {getTrackingLoading && <LoadingPage />}

      {items && (
        <ul className='NoTrackingTruckListContainer'>
          {items.filter((item) => item.location === null).length > 0 ? (
            <Fragment>
              {items.map((item, index) => (
                <NoTrackingTruckListItem
                  key={index}
                  item={item}
                  activeItemNoTracking={activeItemNoTracking}
                  setActiveItemNoTracking={setActiveItemNoTracking}
                />
              ))}
            </Fragment>
          ) : (
            <h3 className='NoTrucksList'>
              {t("tms.desc.Not available to track")}
            </h3>
          )}
        </ul>
      )}
    </div>
  );
};

export default NoTrackingTruckList;
