import React from "react";
import "./RouteField.scss";

import { Tooltip } from "@mui/material";

import { MdLocationPin, MdLocationSearching } from "react-icons/md";

const RouteField = ({ order }) => {
  const from =
    order?.cargos?.[0]?.locations?.find((A) => A.direction === "A")?.country ??
    "";

  const to =
    order?.cargos?.[0]?.locations?.find((B) => B.direction === "B")?.country ??
    "";

  const renderTooltip = (
    <div className='RouteFieldContent'>
      <div>
        {order?.cargos?.[0]?.locations
          ?.filter((A) => A.direction === "A")
          ?.map((item, index) => (
            <div key={item?.id || index} className='routeContainer'>
              <div className='icon' style={{ color: "#b95000" }}>
                <MdLocationSearching />
              </div>
              <p>{item?.formatted_address}</p>
            </div>
          ))}
      </div>
      <div>
        {order?.cargos?.[0]?.locations
          ?.filter((B) => B.direction === "B")
          ?.map((item, index) => (
            <div key={item?.id || index} className='routeContainer'>
              <div className='icon' style={{ color: "#287d3c" }}>
                <MdLocationPin />
              </div>
              <p>{item?.formatted_address}</p>
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <td id='RouteField'>
      <Tooltip
        placement='top'
        title={renderTooltip}
        classes={{ tooltip: "RouteFieldTooltip" }}
      >
        <div className='RouteFieldContainer'>
          <div className='icon'>
            <MdLocationPin />
          </div>
          <p>
            {from} - {to}
          </p>
        </div>
      </Tooltip>
    </td>
  );
};

export default RouteField;
