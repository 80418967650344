import React, { Fragment } from "react";
import "./CreateOrderRemoveItemBtn.scss";

import { useTranslation } from "react-i18next";
import { FiMinus } from "react-icons/fi";

import DTextButton from "./../../../../Form/DButtons/DTextButton/DTextButton";

const CreateOrderRemoveItemBtn = ({ show, onClick }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {show && (
        <div id='CreateOrderRemoveItemBtn'>
          <DTextButton
            title={t("shipper.buttons.Remove")}
            leftIcon={<FiMinus />}
            onClick={onClick}
          />
        </div>
      )}
    </Fragment>
  );
};

export default CreateOrderRemoveItemBtn;
