import React, { Fragment, useEffect, useState } from "react";
import "./TruckMaintenance.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";

import { getTruckMaintenances } from "../../../../../store/fleet/maintenance/maintenanceSlice";

import DButton from "../../../../Form/DButtons/DButton/DButton";
import TruckMaintenanceTable from "./TruckMaintenanceTable/TruckMaintenanceTable";
import LoadingPage from "../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import AddTruckExpensesModal from "./AddTruckExpensesModal/AddTruckExpensesModal";
import DSearch from "../../../../FilterForms/DSearch/DSearch";

const TruckMaintenance = ({ truck }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getTruckMaintenancesResult,
    getTruckMaintenancesLoading,
    createMaintenanceResult,
    updateMaintenanceResult,
    deleteMaintenanceResult,
  } = useSelector((state) => state.maintenance);

  const [openAddExpenseModal, setOpenAddExpenseModal] = useState(false);
  const [keyword, setKeyword] = useState("");

  const truckId = truck.id;

  useEffect(() => {
    dispatch(
      getTruckMaintenances({
        truck_id: truckId,
        keyword: keyword,
      })
    );
  }, [
    dispatch,
    truckId,
    createMaintenanceResult,
    updateMaintenanceResult,
    deleteMaintenanceResult,
    keyword,
  ]);

  return (
    <Fragment>
      <div id='TruckMaintenance'>
        <h1>{t("tms.titles.Maintenance")}</h1>
        <div className='TruckMaintenanceHeader'>
          <DSearch value={keyword} setValue={setKeyword} />

          <DButton
            title={t("tms.forms.buttons.Add expense")}
            rightIcon={<FiPlus />}
            onClick={() => {
              setOpenAddExpenseModal(true);
            }}
          />
        </div>
        {getTruckMaintenancesLoading && <LoadingPage />}
        {getTruckMaintenancesResult && (
          <TruckMaintenanceTable
            maintenances={getTruckMaintenancesResult || []}
          />
        )}
      </div>
      <AddTruckExpensesModal
        truck={truck}
        isOpen={openAddExpenseModal}
        isClose={setOpenAddExpenseModal}
      />
    </Fragment>
  );
};

export default TruckMaintenance;
