import React from "react";
import "./PlanningAssistantFilter.scss";

import DSelect from "../../Form/DSelect/DSelect";

const PlanningAssistantFilter = ({ daysQty, setDaysQty, selectDaysOptions }) => {
  return (
    <div id='PlanningAssistantFilter'>
      <DSelect value={daysQty} setValue={setDaysQty} options={selectDaysOptions} />
    </div>
  );
};

export default PlanningAssistantFilter;
