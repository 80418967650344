import React, { useEffect } from "react";
import "./SendAgain.scss";

import { AiOutlineReload } from "react-icons/ai";

const SendAgain = ({ title, onClick, loading, result, reset }) => {
  useEffect(() => {
    if (result) {
      setTimeout(() => {
        reset();
      }, 60000);
    }
  }, [result, reset]);

  return (
    <div className='SendAgain' id='SendAgain'>
      {!result ? (
        <div className='content' onClick={!loading ? onClick : () => {}}>
          <div className={`icon ${loading ? "spinning" : ""}`}>
            <AiOutlineReload />
          </div>
          {!loading && !result && <p>{title}</p>}
        </div>
      ) : (
        <svg
          className='check'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 130.2 130.2'
        >
          <circle
            className='path circle'
            fill='none'
            stroke='#00b56c'
            strokeWidth='8'
            strokeMiterlimit='10'
            cx='65.1'
            cy='65.1'
            r='62.1'
          />
          <polyline
            className='path check'
            fill='none'
            stroke='#00b56c'
            strokeWidth='8'
            strokeLinecap='round'
            strokeMiterlimit='10'
            points='100.2,40.2 51.5,88.8 29.8,67.5 '
          />
        </svg>
      )}
    </div>
  );
};

export default SendAgain;
