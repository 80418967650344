import React from "react";
import "./BurgerIcon.scss";

const BurgerIcon = ({ value, setValue }) => {
  return (
    <div
      className={value ? "BurgerIconLanding change" : "BurgerIconLanding"}
      onClick={() => {
        setValue((state) => !state);
      }}
    >
      <div className='bar1'></div>
      <div className='bar2'></div>
      <div className='bar3'></div>
    </div>
  );
};

export default BurgerIcon;
