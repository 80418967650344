import React, { Fragment, useState } from "react";
import "./ShowPrePaymentModalItem.scss";

import { useTranslation } from "react-i18next";

import { FiEdit2, FiTrash2 } from "react-icons/fi";

import { useMoney } from "../../../../../../../helper/useMoney";

import DeletePrePaymentModal from "../../DeletePrePaymentModal/DeletePrePaymentModal";
import EditPrePaymentModal from "../../EditPrePaymentModal/EditPrePaymentModal";

const ShowPrePaymentModalItem = ({ order, item }) => {
  const { t } = useTranslation();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const formattedMoney = useMoney(item?.amount || 0, item?.currency?.symbol || "-");

  return (
    <Fragment>
      <li id='ShowPrePaymentModalItem'>
        <div className='ShowPrePaymentModalItemLeft'>
          <div className='ShowPrePaymentModalItemTitle'>
            <h1>{item?.payment_date || "-"}</h1>
            {item.comment ? (
              <h3>{item.comment}</h3>
            ) : (
              <p className='noComment'>{t("tms.modals.others.No comment")}</p>
            )}
          </div>
        </div>
        <div className='ShowPrePaymentModalItemRight'>
          <div className='ShowPrePaymentModalItemContent'>
            <p>{formattedMoney}</p>
          </div>
          <div className='actions'>
            <div
              className='icon delete'
              onClick={() => {
                setOpenDeleteModal(true);
              }}
            >
              <FiTrash2 />
            </div>
            <div
              className='icon'
              onClick={() => {
                setOpenEditModal(true);
              }}
            >
              <FiEdit2 />
            </div>
          </div>
        </div>
      </li>
      {openDeleteModal && (
        <DeletePrePaymentModal
          order={order}
          item={item}
          isOpen={openDeleteModal}
          isClose={() => setOpenDeleteModal(false)}
        />
      )}
      {openEditModal && (
        <EditPrePaymentModal
          order={order}
          item={item}
          isOpen={openEditModal}
          isClose={() => setOpenEditModal(false)}
        />
      )}
    </Fragment>
  );
};

export default ShowPrePaymentModalItem;
