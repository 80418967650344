import _request from "../_request";

const getPartners = async (ISO, TOKEN, data) => {
  let url = "/tms-partner?";
  const { page, keyword, orderId, route_id, trailer_type_id, region_id } = data || {};

  const routeIds = route_id ? [route_id] : [];
  region_id && routeIds.push(region_id);

  url += page ? `&page=${page}` : "";
  url += keyword ? `&keyword=${keyword}` : "";
  url += orderId ? `&order=${orderId}` : "";
  url += routeIds?.length > 0 ? `&route_id[]=${routeIds}` : "";
  url += trailer_type_id ? `&trailer_type_id=${trailer_type_id}` : "";
  const config = {
    url,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const getPartner = async (ISO, TOKEN, id) => {
  const config = {
    url: `/tms-partner/${id}`,
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const createPartner = async (ISO, TOKEN, data) => {
  const config = {
    url: "/tms-partner",
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const updatePartner = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `/tms-partner/${id}`,
    method: "put",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const deletePartner = async (ISO, TOKEN, id) => {
  const config = {
    url: `/tms-partner/${id}`,
    method: "delete",
    token: TOKEN,
    ISO,
  };
  return _request(config);
};

const ratePartner = async (ISO, TOKEN, id, data) => {
  const config = {
    url: `/tms-partner/${id}/review`,
    method: "post",
    token: TOKEN,
    ISO,
  };
  return _request(config, data);
};

const partnerService = {
  getPartners,
  getPartner,
  createPartner,
  updatePartner,
  deletePartner,
  ratePartner,
};

export default partnerService;
