import React from "react";
import "./ContactSeaFreight.scss";

import { useTranslation } from "react-i18next";

import ContactFreightCover from "./../Components/ContactFreightCover/ContactFreightCover";
import ContactFreightForm from "./../Components/ContactFreightForm/ContactFreightForm";

const ContactSeaFreight = () => {
  const { t } = useTranslation();

  return (
    <section id='ContactSeaFreight'>
      <div className='wrapper'>
        <div className='main'>
          <ContactFreightCover url='/assets/images/static/cover26.jfif' />
          <ContactFreightForm
            title={t("landing.titles.landingMainTitles.Get in touch with us")}
            smallTitle={t("landing.titles.landingMainTitlesDesc.Let’s talk")}
            description={t(
              "landing.titles.landingMainTitlesDesc.Stay in the loop reach out today! Our team is here to assist you every step of the way. Feel free to get in touch with us for any inquiries or updates."
            )}
          />
        </div>
      </div>
    </section>
  );
};

export default ContactSeaFreight;
