import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderSettingsService from "./orderSettingsService";

const initialState = {
  // ალკოჰოლი - საკვები და სასმელი
  getCargoTypesLoading: true,
  getCargoTypesResult: null,
  getCargoTypesSelect: null,
  getCargoTypesError: null,

  //get Order Payment Type
  getPaymentTypeLoading: true,
  getPaymentTypeResult: null,
  getPaymentTypeSelect: null,
  getPaymentTypeError: null,

  //get order services list
  getOrderServicesListLoading: true,
  getOrderServicesListResult: null,
  getOrderServicesListError: null,

  //get cargo package list
  getCargoPackageListLoading: true,
  getCargoPackageListResult: null,
  getCargoPackageListSelect: null,
  getCargoPackageListError: null,
};

// ალკოჰოლი - საკვები და სასმელი
export const getCargoTypes = createAsyncThunk(
  "orderSettings/getCargoTypes",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderSettingsService.getCargoTypes(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// getPaymentType
export const getPaymentType = createAsyncThunk(
  "orderSettings/getPaymentType",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderSettingsService.getPaymentType(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get order services list
export const getOrderServicesList = createAsyncThunk(
  "orderSettings/getOrderServicesList",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderSettingsService.getOrderServicesList(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get cargo package list
export const getCargoPackageList = createAsyncThunk(
  "orderSettings/getCargoPackageList",
  async (_, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await orderSettingsService.getCargoPackageList(ISO, TOKEN);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orderSettingsSlice = createSlice({
  name: "orderSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // ალკოჰოლი - საკვები და სასმელი
      .addCase(getCargoTypes.pending, (state) => {
        state.getCargoTypesLoading = true;
        state.getCargoTypesResult = null;
        state.getCargoTypesSelect = null;
        state.getCargoTypesError = null;
      })
      .addCase(getCargoTypes.fulfilled, (state, action) => {
        state.getCargoTypesLoading = false;
        state.getCargoTypesResult = action.payload;
        state.getCargoTypesSelect = action.payload.map((item) => ({
          value: item,
          label: item.name,
        }));
        state.getCargoTypesError = null;
      })
      .addCase(getCargoTypes.rejected, (state, action) => {
        state.getCargoTypesLoading = false;
        state.getCargoTypesResult = null;
        state.getCargoTypesSelect = null;
        state.getCargoTypesError = action.payload;
      })

      //get Order Payment Type
      .addCase(getPaymentType.pending, (state) => {
        state.getPaymentTypeLoading = true;
        state.getPaymentTypeResult = null;
        state.getPaymentTypeSelect = null;
        state.getPaymentTypeError = null;
      })
      .addCase(getPaymentType.fulfilled, (state, action) => {
        state.getPaymentTypeLoading = false;
        state.getPaymentTypeResult = action.payload;
        state.getPaymentTypeSelect = action.payload.map((item) => ({
          label: item.name,
          value: item,
        }));
        state.getPaymentTypeError = null;
      })
      .addCase(getPaymentType.rejected, (state, action) => {
        state.getPaymentTypeLoading = false;
        state.getPaymentTypeResult = null;
        state.getPaymentTypeSelect = null;
        state.getPaymentTypeError = action.payload;
      })

      //get order services list
      .addCase(getOrderServicesList.pending, (state) => {
        state.getOrderServicesListLoading = true;
        state.getOrderServicesListResult = null;
        state.getOrderServicesListError = null;
      })
      .addCase(getOrderServicesList.fulfilled, (state, action) => {
        state.getOrderServicesListLoading = false;
        state.getOrderServicesListResult = action.payload;
        state.getOrderServicesListError = null;
      })
      .addCase(getOrderServicesList.rejected, (state, action) => {
        state.getOrderServicesListLoading = false;
        state.getOrderServicesListResult = null;
        state.getOrderServicesListError = action.payload;
      })

      // get cargo package list
      .addCase(getCargoPackageList.pending, (state) => {
        state.getCargoPackageListLoading = true;
        state.getCargoPackageListResult = null;
        state.getCargoPackageListSelect = null;
        state.getCargoPackageListError = null;
      })
      .addCase(getCargoPackageList.fulfilled, (state, action) => {
        state.getCargoPackageListLoading = false;
        state.getCargoPackageListResult = action.payload;
        state.getCargoPackageListSelect = action.payload.map((item) => ({
          label: item.name,
          value: item,
        }));
        state.getCargoPackageListError = null;
      })
      .addCase(getCargoPackageList.rejected, (state, action) => {
        state.getCargoPackageListLoading = false;
        state.getCargoPackageListResult = null;
        state.getCargoPackageListSelect = null;
        state.getCargoPackageListError = action.payload;
      });
  },
});

// export const {} = ordersSlice.actions;
export default orderSettingsSlice.reducer;
