import React from "react";
import "./Shipper.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "../../../componentsAdditional/Wrapper";
import CoverShipper from "../../../componentsLanding/Landing/Covers/CoverShipper/CoverShipper";
import JoinUsShipper from "./../../../componentsLanding/Landing/Joins/JoinUsShipper/JoinUsShipper";
import ContactShipper from "../../../componentsLanding/Landing/Contacts/ContactShipper/ContactShipper";
import ProductShipper from "./../../../componentsLanding/Landing/ProductShipper/ProductShipper";
import FaqSectionShipper from "./../../../componentsLanding/Landing/FAQs/FaqSectionShipper/FaqSectionShipper";
import ForWorkflowShipper from "../../../componentsLanding/Landing/Workflows/ForWorkflowShipper/ForWorkflowShipper";
import ForAboutShipper from "./../../../componentsLanding/Landing/Abouts/ForAboutShipper/ForAboutShipper";

const Shipper = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("landing.documentTitles.Shipper")}>
      <CoverShipper />
      <ForWorkflowShipper />
      <ForAboutShipper />
      <ProductShipper />
      <JoinUsShipper />
      <FaqSectionShipper />
      <ContactShipper />
    </Wrapper>
  );
};

export default Shipper;
