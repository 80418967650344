import React from "react";
import "./ButtonLoader.scss";

const ButtonLoader = () => {
  return (
    <div id='ButtonLoader' className='center'>
      <div className='lds-spinner'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default ButtonLoader;
