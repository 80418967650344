import React, { Fragment, useState, useEffect } from "react";
import "./EditCustomerContactModal.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { FiPlus } from "react-icons/fi";

import {
  updateClientContact,
  resetUpdateClientContact,
} from "../../../../../store/customer/customerContacts/customerContactsSlice";

import DModal from "../../../../Modal/DModal/DModal";
import DInputs from "./../../../../Form/DInputs/DInputs";
import DSelect from "./../../../../Form/DSelect/DSelect";

const EditCustomerContactModal = ({ isOpen, isClose, id, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    updateClientContactLoading,
    updateClientContactResult,
    updateClientContactError,
    getClientContactPositionsLoading,
    getClientContactPositionsSelect,
  } = useSelector((state) => state.customerContacts);

  const [fullname, setFullname] = useState(item?.name || "");
  const [position, setPosition] = useState(
    item.position
      ? {
          label: item?.position?.name,
          value: item?.position,
        }
      : null
  );
  const [email, setEmail] = useState(item?.email || "");
  const [phone, setPhone] = useState(item?.phone || "");

  const submitHandler = () => {
    const data = {
      id: id,
      contactId: item.id,
      position_id: position?.value?.id || null,
      name: fullname,
      email: email,
      phone: phone,
    };
    dispatch(updateClientContact(data));
  };

  useEffect(() => {
    if (updateClientContactResult) {
      dispatch(resetUpdateClientContact());
      isClose();
    }
  }, [dispatch, isClose, updateClientContactResult]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateClientContact());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose()}
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        title={t("tms.modals.titles.Edit contact")}
        status='success'
        icon={<FiPlus />}
        loading={updateClientContactLoading}
      >
        <div id='EditCustomerContactModal'>
          <div className='group'>
            <DInputs
              value={fullname}
              setValue={setFullname}
              placeholder={t("tms.modals.placeholder.Enter full name")}
              label={t("tms.modals.labels.Full name")}
              required
              id='fullname'
              error={updateClientContactError?.errors?.name?.[0] ?? null}
            />
            <DSelect
              id='position'
              value={position}
              setValue={setPosition}
              placeholder={t("tms.modals.placeholder.Enter position")}
              label={t("tms.modals.labels.Position")}
              options={getClientContactPositionsSelect}
              loading={getClientContactPositionsLoading}
              required
              search
              error={updateClientContactError?.errors?.position_id?.[0] ?? null}
            />
            <DInputs
              value={email}
              setValue={setEmail}
              placeholder={t("tms.modals.placeholder.Enter email")}
              label={t("tms.modals.labels.Email")}
              id='Email'
              error={updateClientContactError?.errors?.email?.[0] ?? null}
            />
            <DInputs
              value={phone}
              setValue={setPhone}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              label={t("tms.modals.labels.Phone number")}
              id='Phone '
              error={updateClientContactError?.errors?.phone?.[0] ?? null}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditCustomerContactModal;
