import React, { Fragment, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

import {
  disconnectProvider,
  resetDisconnectProvider,
} from "../../../store/GPSTraker/GPSTrakerSlice";

import DModal from "./../../Modal/DModal/DModal";

const GPSCompanyItem = ({ item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { disconnectProviderLoading, disconnectProviderResult } = useSelector(
    (state) => state.GPSTraker
  );

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const logoutHandler = () => {
    dispatch(disconnectProvider(item.value.id));
  };

  useEffect(() => {
    if (disconnectProviderResult) {
      dispatch(resetDisconnectProvider());
      setShowLogoutModal(false);
    }
  }, [dispatch, disconnectProviderResult]);

  return (
    <Fragment>
      <li id='GPSCompany'>
        <div>
          <h3>{item?.value?.provider?.name || null}</h3>
          <h4>{t("tms.desc.Logged in")}</h4>
        </div>
        <div className='icon success'>
          <FaCheckCircle />
        </div>
        <div className='icon logout' onClick={() => setShowLogoutModal(true)}>
          <FaTimesCircle />
        </div>
      </li>
      {showLogoutModal && (
        <DModal
          loading={disconnectProviderLoading}
          status='danger'
          submitText={t("tms.modals.buttons.Logout")}
          title={t("tms.modals.titles.Logout to tracking device")}
          isOpen={showLogoutModal}
          isClose={() => setShowLogoutModal(false)}
          onClick={() => logoutHandler()}
          icon={<FaTimesCircle />}
        >
          <p>{t("tms.modals.desc.Are you sure you want to logout?")}</p>
        </DModal>
      )}
    </Fragment>
  );
};

export default GPSCompanyItem;
