import React from "react";
import "./CoverCarrierSubscribe.scss";

import { useTranslation } from "react-i18next";

import FadeTextAnimation from "../../../UI/Animations/FadeTextAnimation/FadeTextAnimation";

const CoverCarrierSubscribe = () => {
  const { t } = useTranslation();
  return (
    <section
      id='CarrierSubscribeNowCover'
      className='CarrierSubscribeNowCover'
      style={{
        backgroundImage: `url(${`/assets/images/static/carrierSubscribeCover.png`})`,
      }}
    >
      <div className='blar'>
        <div className='wrapper'>
          <div className='container'>
            <div className='text'>
              <FadeTextAnimation
                text={t("landing.titles.landingMainTitles.Maximize your earning potential")}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoverCarrierSubscribe;
