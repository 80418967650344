import React, { useState } from "react";
import "./ContainerTrackingItem.scss";

import { FaCheckCircle } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { TbProgress } from "react-icons/tb";
import { RiShipLine } from "react-icons/ri";

import ContainerTrackingItemEvent from "../ContainerTrackingItemEvent/ContainerTrackingItemEvent";

const ContainerTrackingItem = ({ title, icon, done, data }) => {
  const [open, setOpen] = useState(true);

  return (
    <div id='ContainerTrackingItem'>
      <div
        className='header'
        style={{ cursor: data ? "pointer" : "" }}
        onClick={() => data && setOpen(!open)}
      >
        <div className='titleContainer'>
          <div className='icon'>{icon}</div>
          <div className='title'>{title}</div>
          {data && (
            <div className='icon'>
              <IoIosArrowDown style={{ transform: open ? "rotate(180deg)" : "" }} />
            </div>
          )}
        </div>
        <div className={`statusIcon ${done ? "done" : ""}`}>
          {done ? <FaCheckCircle /> : <TbProgress />}
        </div>
      </div>
      {data && open && (
        <div className='trackingInfoContainer'>
          {data?.map((item, index) => {
            return (
              <div key={index} className='trackingInfoItem'>
                <div className='leftSide'>{item?.location}</div>
                <div className='rightSide'>
                  {item?.events?.map((event, index) => (
                    <div className='rightSideContainer' key={index}>
                      <div className='center'>
                        <div className={`icon ${event?.current_location ? "current" : ""}`}>
                          <RiShipLine
                            style={{
                              color: event?.current_location
                                ? "#3577F1"
                                : event?.actual
                                ? "#0AB39C"
                                : "#878A99",
                            }}
                          />
                        </div>
                        <div
                          className={`line ${
                            event?.actual && !event?.current_location ? "actual" : ""
                          }`}
                          style={{
                            backgroundColor:
                              !event?.actual || event?.current_location ? "#878A99" : "#0AB39C",
                          }}
                        />
                      </div>
                      <ContainerTrackingItemEvent event={event} />
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ContainerTrackingItem;
