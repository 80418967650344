import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import uploadService from "./uploadServices";

const initialState = {
  uploadCompanyLogoResult: null,
  uploadCompanyLogoLoading: false,
  uploadCompanyLogoError: null,

  uploadProfileAvatarResult: null,
  uploadProfileAvatarLoading: false,
  uploadProfileAvatarError: null,
};

export const uploadCompanyLogo = createAsyncThunk(
  "upload/uploadCompanyLogo",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const file = data.file;
      const formData = new FormData();
      formData.append("avatar", file);
      const result = await uploadService.uploadCompanyLogo(ISO, TOKEN, formData);
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const uploadProfileAvatar = createAsyncThunk(
  "upload/uploadProfileAvatar",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      const file = data.file;
      const formData = new FormData();
      formData.append("avatar", file);
      const result = await uploadService.uploadFile(ISO, TOKEN, formData);
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    resetUploadCompanyLogo: (state) => {
      state.uploadCompanyLogoLoading = false;
      state.uploadCompanyLogoResult = null;
      state.uploadCompanyLogoError = null;
    },
    resetUploadProfileAvatar: (state) => {
      state.uploadProfileAvatarLoading = false;
      state.uploadProfileAvatarResult = null;
      state.uploadProfileAvatarError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadCompanyLogo.pending, (state) => {
        state.uploadCompanyLogoLoading = true;
        state.uploadCompanyLogoResult = null;
        state.uploadCompanyLogoError = null;
      })
      .addCase(uploadCompanyLogo.fulfilled, (state, action) => {
        state.uploadCompanyLogoLoading = false;
        state.uploadCompanyLogoResult = action.payload;
        state.uploadCompanyLogoError = null;
      })
      .addCase(uploadCompanyLogo.rejected, (state, action) => {
        state.uploadCompanyLogoLoading = false;
        state.uploadCompanyLogoError = action.payload;
        state.uploadCompanyLogoResult = null;
      })

      .addCase(uploadProfileAvatar.pending, (state) => {
        state.uploadProfileAvatarLoading = true;
        state.uploadProfileAvatarResult = null;
        state.uploadProfileAvatarError = null;
      })
      .addCase(uploadProfileAvatar.fulfilled, (state, action) => {
        state.uploadProfileAvatarLoading = false;
        state.uploadProfileAvatarResult = action.payload;
        state.uploadProfileAvatarError = null;
      })
      .addCase(uploadProfileAvatar.rejected, (state, action) => {
        state.uploadProfileAvatarLoading = false;
        state.uploadProfileAvatarError = action.payload;
        state.uploadProfileAvatarResult = null;
      });
  },
});

export const { resetUploadCompanyLogo, resetUploadProfileAvatar } =
  uploadSlice.actions;
export default uploadSlice.reducer;
