import React from "react";
import "./SortSelect.scss";

import { useTranslation } from "react-i18next";

import Select, { components } from "react-select";
import { FaCheckCircle } from "react-icons/fa";

const SortSelect = ({ value, setValue, options }) => {
  const { t } = useTranslation();

  const CustomSingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        {t("tms.filters.placeholders.Sort by")}:{" "}
        <span className='bold'>{children}</span>
      </components.SingleValue>
    );
  };

  return (
    <div id='SortSelect'>
      <Select
        classNamePrefix='select'
        name='sortSelect'
        className='selectComponent'
        options={options}
        placeholder={t("tms.filters.placeholders.Sort by")}
        value={value}
        onChange={(state) => {
          setValue(state);
        }}
        isClearable
        components={{
          Option: ({ innerProps, ...props }) => (
            <div {...innerProps}>
              <div className='select__option'>
                <div>{props?.data?.label}</div>
                {props?.isSelected && <FaCheckCircle color='#00B56C' />}
              </div>
            </div>
          ),
          SingleValue: CustomSingleValue,
        }}
        isSearchable={false}
      />
    </div>
  );
};

export default SortSelect;
