import React from "react";
import "./AirPackageItem.scss";

import { useTranslation } from "react-i18next";
import { useCalculateVolume } from "../../../../../../helper/useCalculateVolume";

import DInputs from "../../../../../Form/DInputs/DInputs";
import DPackageTypeSelect from "../../../../../Form/DSelect/DPackageTypeSelect/DPackageTypeSelect";
import DQuantitySelect from "../../../../../Form/DSelect/DQuantitySelect/DQuantitySelect";
import CreateOrderAdrSection from "../../../Components/CreateOrderAdrSection/CreateOrderAdrSection";
import CreateOrderRemoveItemBtn from "./../../../Components/CreateOrderRemoveItemBtn/CreateOrderRemoveItemBtn";
import CreateOrderProductType from "./../../../Components/CreateOrderProductType/CreateOrderProductType";
import CreateOrderSectionSecondaryTitle from "../../../Components/CreateOrderSectionSecondaryTitle/CreateOrderSectionSecondaryTitle";
import CreateOrderDimentionGroup from "../../../Components/CreateOrderDimentionGroup/CreateOrderDimentionGroup";
import CreateOrderDimentionDescription from "./../../../Components/CreateOrderDimentionGroup/CreateOrderDimentionDescription/CreateOrderDimentionDescription";

const AirPackageItem = ({ edit, group, setGroup, index, item }) => {
  const { t } = useTranslation();

  const cargoTypeHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].cargoType = value;
    setGroup(updatedGroup);
  };
  const setPackageType = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].packageType = value;
    setGroup(updatedGroup);
  };
  const HSCodeHandler = () => {
    const updatedGroup = [...group];
    updatedGroup[index].HSCode = !item.HSCode;
    updatedGroup[index].HSCodeValue = "";
    setGroup(updatedGroup);
  };
  const HSCodeValueHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].HSCodeValue = value;
    setGroup(updatedGroup);
  };
  const adrHandler = () => {
    const updatedGroup = [...group];
    updatedGroup[index].adr = !item.adr;
    updatedGroup[index].adrNumber = "";
    updatedGroup[index].adrClass = "";
    updatedGroup[index].adrFile = [];
    setGroup(updatedGroup);
  };
  const adrNumberHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrNumber = value;
    setGroup(updatedGroup);
  };
  const adrClassHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrClass = value;
    setGroup(updatedGroup);
  };
  const adrFileHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].adrFile = value;
    setGroup(updatedGroup);
  };
  /////
  const setLength = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].dimensions[0].length = value;
    setGroup(updatedGroup);
  };
  const setWidth = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].dimensions[0].width = value;
    setGroup(updatedGroup);
  };
  const setHeigth = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].dimensions[0].height = value;
    setGroup(updatedGroup);
  };
  const setWeight = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].dimensions[0].weight = value;
    setGroup(updatedGroup);
  };
  const countDown = (value) => {
    if (value > 1) {
      const updatedGroup = [...group];
      updatedGroup[index].dimensions[0].count = value - 1;
      setGroup(updatedGroup);
    }
  };
  const countUp = (value) => {
    if (value < 100) {
      const updatedGroup = [...group];
      updatedGroup[index].dimensions[0].count = value + 1;
      setGroup(updatedGroup);
    }
  };
  ///////
  const removeItemHandler = () => {
    const updatedGroup = [...group];
    updatedGroup.splice(index, 1);
    setGroup(updatedGroup);
  };

  const volume =
    useCalculateVolume(
      item.dimensions[0].length,
      item.dimensions[0].width,
      item.dimensions[0].height
    ) * item.dimensions[0].count;
  const roundedVolume = volume.toFixed(5);
  const roundedVolumeNumber = parseFloat(roundedVolume);

  const weight = item.dimensions[0].weight * item.dimensions[0].count;
  const roundedWeight = weight.toFixed(2);
  const roundedWeightNumber = parseFloat(roundedWeight);

  return (
    <div id='ShipperAirPackageItem'>
      <CreateOrderRemoveItemBtn
        show={group.length > 1 ? true : false}
        onClick={removeItemHandler}
      />

      <div className='productAndPackageContainer'>
        <div className='productWrapper'>
          <CreateOrderProductType
            cargoType={item.cargoType}
            setCargoType={(value) => cargoTypeHandler(value)}
            cargoTypeError={item.cargoTypeError}
            HSCode={item.HSCode}
            setHSCode={() => HSCodeHandler()}
            HSCodeValue={item.HSCodeValue}
            setHSCodeValue={(value) => HSCodeValueHandler(value)}
            HSCodeValueError={item.HSCodeValueError}
          />
        </div>
        <div className='packageTypeContainer'>
          <DPackageTypeSelect
            required
            value={item?.packageType}
            setValue={(value) => setPackageType(value)}
            placeholder={t("shipper.forms.content.Select type")}
            label={t("shipper.forms.content.Package type")}
            error={item?.packageTypeError}
          />
        </div>
      </div>
      <div className='productAdrWrapper'>
        <CreateOrderAdrSection
          edit={edit}
          adr={item.adr}
          setAdr={(value) => adrHandler(value)}
          adrError={item.adrError}
          adrNumber={item.adrNumber}
          setAdrNumber={(value) => adrNumberHandler(value)}
          adrNumberError={item.adrNumberError}
          adrClass={item.adrClass}
          setAdrClass={(value) => adrClassHandler(value)}
          adrClassError={item.adrClassError}
          adrFile={item.adrFile}
          setAdrFile={(value) => adrFileHandler(value)}
        />
      </div>
      <CreateOrderSectionSecondaryTitle title={t("shipper.forms.titles.Dimensions per package")} />

      <div className='packageDimentionForm'>
        <div className='BlockA'>
          <CreateOrderDimentionGroup
            index={index}
            length={item.dimensions[0].length}
            setLength={setLength}
            lengthError={item.dimensions[0].lengthError}
            width={item.dimensions[0].width}
            setWidth={setWidth}
            widthError={item.dimensions[0].widthError}
            height={item.dimensions[0].height}
            setHeigth={setHeigth}
            heightError={item.dimensions[0].heightError}
          />
        </div>
        <div className='line'></div>

        <div className='BlockB'>
          <DInputs
            required
            type={"number"}
            id={`ShipperAirPackageItemTotalWeight${index}}`}
            placeholder={t("shipper.forms.content.Per weight")}
            label={t("shipper.forms.content.Package Weight (KG)")}
            value={item.dimensions[0].weight}
            setValue={(value) => setWeight(value)}
            error={item.dimensions[0].weightError}
          />
          <DQuantitySelect
            label={t("shipper.forms.content.Package quantity")}
            value={item.dimensions[0].count}
            setCountIn={countUp}
            setCountDec={countDown}
            error={null}
          />
        </div>
      </div>

      <CreateOrderDimentionDescription volume={roundedVolumeNumber} weight={roundedWeightNumber} />
    </div>
  );
};

export default AirPackageItem;
