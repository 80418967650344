import React, { useState, useEffect } from "react";
import "./BidsList.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getBids, resetGetBids } from "./../../../../../store/loadBoards/loadBoardsSlice";

import Wrapper from "../../../../../componentsAdditional/Wrapper";
import PageSwitcher from "../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import LoadBidTable from "../../../../../componentsDashboard/LoadBoards/LoadBidTable/LoadBidTable";
import LoadingPage from "./../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import MainTitle from "../../../../../componentsDashboard/MainTitle/MainTitle";

const BidsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { getBidsLoading, getBidsResult, getBidsMeta, biddingBoardResult, takeBoardResult } =
    useSelector((state) => state.loadBoards);

  const [page, setPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1);

  useEffect(() => {
    dispatch(getBids({ page }));
    return () => {
      dispatch(resetGetBids());
    };
  }, [dispatch, page, biddingBoardResult, takeBoardResult]);

  return (
    <Wrapper documentTitle={t("tms.documentTitles.Bids Boards")}>
      <section id='BidsList'>
        <MainTitle title={t("tms.titles.Load Boards")} />
        <PageSwitcher name='bids' />
        {getBidsLoading && <LoadingPage />}
        {getBidsResult && !getBidsLoading && (
          <LoadBidTable
            items={getBidsResult}
            page={page}
            setPage={setPage}
            pageCount={getBidsMeta.last_page}
            total={getBidsMeta.total}
          />
        )}
      </section>
    </Wrapper>
  );
};

export default BidsList;
