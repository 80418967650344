import React, { Fragment, useState, useEffect } from "react";
import "./GPSTruckTableItem.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { FiCheck, FiEdit2 } from "react-icons/fi";
import { FaLocationCrosshairs } from "react-icons/fa6";

import {
  connectTruckToDevice,
  resetConnectTruckToDevice,
} from "../../../../store/GPSTraker/GPSTrakerSlice";

import RowField from "./../../../Form/TableFields/RowField/RowField";
import TruckField from "./../../../Form/TableFields/TruckField/TruckField";
import TruckGPSStatusField from "./../../../Form/TableFields/TruckGPSStatusField/TruckGPSStatusField";
import CenterField from "./../../../Form/TableFields/CenterField/CenterField";
import DButton from "./../../../Form/DButtons/DButton/DButton";
import DModal from "./../../../Modal/DModal/DModal";
import TextField from "./../../../Form/TableFields/TextField/TextField";
import GPSTruckTableSelect from "../GPSTruckTableSelect/GPSTruckTableSelect";

const GPSTruckTableItem = ({ truck }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    getConnectionsLoading,
    getConnectionsResponce,
    getDevicesLoading,
    getDevicesResponce,
    connectTruckToDeviceLoading,
    connectTruckToDeviceResponce,
  } = useSelector((state) => state.GPSTraker);

  const [connection, setConnection] = useState();

  useEffect(() => {
    if (truck && truck.device && getConnectionsResponce) {
      const findConnection = getConnectionsResponce.find(
        (connection) => connection.value.id === truck.device.credential_id
      );
      setConnection(findConnection);
    }
  }, [truck, getConnectionsResponce]);

  const [device, setDevice] = useState(null);

  const [showSaveModal, setShowSaveModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const finDevice =
      truck.device &&
      getDevicesResponce &&
      getDevicesResponce.find(
        (device) => device.value.id.toString() === truck.device.id.toString()
      );
    setDevice(finDevice);
  }, [getDevicesResponce, truck.device]);

  const submitHandler = () => {
    const data = {
      truck_id: truck.id,
      device_id: device.value.id,
    };
    dispatch(connectTruckToDevice(data));
  };

  useEffect(() => {
    if (connectTruckToDeviceResponce) {
      dispatch(resetConnectTruckToDevice());
      setShowSaveModal(false);
    }
  }, [dispatch, connectTruckToDeviceResponce]);

  return (
    <Fragment>
      {getConnectionsResponce && getDevicesResponce && (
        <RowField>
          <TruckField max={40} truck={truck} />
          {truck.device && !editMode ? (
            <TextField label={connection?.label || "-"} />
          ) : (
            <td id='DGPSConnectionsSelectField'>
              <GPSTruckTableSelect
                id='DGPSConnectionsSelect'
                value={connection}
                setValue={(state) => {
                  setConnection(state);
                  setDevice(null);
                }}
                placeholder={t("tms.tables.others.Choose company")}
                loading={getConnectionsLoading}
                search={false}
                error={null}
                required
                options={getConnectionsResponce}
                emptyText={t("tms.tables.noInfo.Connections not found")}
              />
            </td>
          )}

          {truck.device && !editMode ? (
            <TextField label={truck.device.name} />
          ) : (
            <td id='DGPSConnectionsDevicesField'>
              <GPSTruckTableSelect
                disabled={!connection}
                value={device}
                setValue={setDevice}
                placeholder={t("tms.tables.others.Choose device")}
                loading={getDevicesLoading}
                search={false}
                error={null}
                required
                options={
                  getDevicesResponce && connection
                    ? getDevicesResponce.filter(
                        (device) =>
                          device.value.credential_id === connection.value.id
                      )
                    : []
                }
                emptyText={t("tms.tables.noInfo.Devices not found")}
              />
            </td>
          )}

          <TruckGPSStatusField check={truck.has_connected_device} />
          <CenterField>
            {truck.has_connected_device && !editMode ? (
              <div id='DGPSConnectionsActionsFieldEdit'>
                <DButton
                  title={t("tms.tables.buttons.Edit")}
                  rightIcon={<FiEdit2 />}
                  onClick={() => setEditMode(true)}
                />
              </div>
            ) : (
              <div id='DGPSConnectionsActionsFieldSave'>
                <DButton
                  disabled={!connection || !device}
                  title={t("tms.tables.buttons.Confirm")}
                  rightIcon={<FiCheck />}
                  onClick={() => setShowSaveModal(true)}
                />
              </div>
            )}
          </CenterField>
          <DModal
            loading={connectTruckToDeviceLoading}
            isOpen={showSaveModal}
            isClose={() => setShowSaveModal(false)}
            title={t("tms.modals.titles.Confirm")}
            onClick={() => submitHandler()}
            submitText={t("tms.modals.buttons.Confirm")}
            icon={<FaLocationCrosshairs />}
          >
            <p>{t("tms.modals.desc.Are you sure to connect this device?")}</p>
          </DModal>
        </RowField>
      )}
    </Fragment>
  );
};

export default GPSTruckTableItem;
