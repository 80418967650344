import React from "react";
import "./WorkflowItem.scss";

const WorkflowItem = ({ title, content, icon, delay }) => {
  return (
    <div
      data-aos='fade-right'
      data-aos-duration='1500'
      data-aos-once='true'
      data-aos-delay={delay ? delay : "0"}
    >
      <div id='WorkflowItem' className='WorkflowItem'>
        <div className='left'>
          <div className='icon'>{icon}</div>
        </div>
        <div className='right'>
          <h3>{title}</h3>
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
};

export default WorkflowItem;
