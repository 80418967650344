import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import DSelect from "../DSelect";

const DPackageTypeSelect = ({ value, setValue, error, required, label, placeholder, leftIcon }) => {
  const { t } = useTranslation();
  const { getCargoPackageListLoading, getCargoPackageListSelect } = useSelector(
    (state) => state.orderSettings
  );

  const filterOption = (option, inputValue) => {
    const { value } = option;
    return value.name.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <DSelect
      id='DCargoTypesSelect'
      label={label ? label : t("shipper.forms.content.Package type")}
      placeholder={placeholder ? placeholder : t("shipper.forms.content.Select type")}
      required={required}
      value={value}
      setValue={setValue}
      loading={getCargoPackageListLoading}
      search={true}
      error={error}
      filterOption={filterOption}
      options={getCargoPackageListSelect}
      leftIcon={leftIcon ? leftIcon : null}
    />
  );
};

export default DPackageTypeSelect;
