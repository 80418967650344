import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import APPTrackerService from "./APPTrackerService";

const initialState = {
  getDriversLoading: true,
  getDriversResult: null,
  getDriversError: null,

  sendInvitationLoading: false,
  sendInvitationResult: null,
  sendInvitationError: null,
};

export const getDrivers = createAsyncThunk(
  "APPTracker/getDrivers",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await APPTrackerService.getDrivers(ISO, TOKEN, data);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendInvitation = createAsyncThunk(
  "APPTracker/sendInvitation",
  async (driver_id, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await APPTrackerService.sendInvitation(ISO, TOKEN, driver_id);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const APPTrackerSlice = createSlice({
  name: "APPTracker",
  initialState,
  reducers: {
    resetGetDrivers: (state) => {
      state.getDriversLoading = false;
      state.getDriversResult = null;
      state.getDriversError = null;
    },

    resetSendInvitation: (state) => {
      state.sendInvitationLoading = false;
      state.sendInvitationResult = null;
      state.sendInvitationError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDrivers.pending, (state) => {
        state.getDriversLoading = true;
        state.getDriversResult = null;
        state.getDriversError = null;
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        state.getDriversLoading = false;
        state.getDriversResult = action.payload;
        state.getDriversError = null;
      })
      .addCase(getDrivers.rejected, (state, action) => {
        state.getDriversLoading = false;
        state.getDriversResult = null;
        state.getDriversError = action.payload;
      })

      .addCase(sendInvitation.pending, (state) => {
        state.sendInvitationLoading = true;
        state.sendInvitationResult = null;
        state.sendInvitationError = null;
      })
      .addCase(sendInvitation.fulfilled, (state, action) => {
        state.sendInvitationLoading = false;
        state.sendInvitationResult = action.payload;
        state.sendInvitationError = null;
      })
      .addCase(sendInvitation.rejected, (state, action) => {
        state.sendInvitationLoading = false;
        state.sendInvitationResult = null;
        state.sendInvitationError = action.payload;
      });
  },
});

export const { resetGetDrivers, resetSendInvitation } = APPTrackerSlice.actions;
export default APPTrackerSlice.reducer;
