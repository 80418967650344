import React, { useState, useEffect } from "react";
import "./DCustomersSelect.scss";

import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
import { getCustomers, resetGetCustomers } from "./../../../../store/customer/customerSlice";

import DSelect from "../DSelect";

const DCustomersSelect = ({ value, setValue, error }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");

  const {
    getCustomersSelect,
    getCustomersLoading,
    createCustomerResult,
    updateCustomerResult,
    deleteCustomerResult,
  } = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(getCustomers({ keyword: keyword }));

    return () => {
      dispatch(resetGetCustomers());
    };
  }, [dispatch, keyword, createCustomerResult, updateCustomerResult, deleteCustomerResult]);

  return (
    <DSelect
      id='DCustomersSelect'
      label={t("tms.forms.labels.Customers")}
      value={value}
      setValue={setValue}
      placeholder={t("tms.forms.placeholder.Choose customer")}
      loading={getCustomersLoading}
      search={true}
      error={error}
      required
      options={getCustomersSelect}
      emptyText={t("tms.forms.noInfo.Customer not found")}
      setKeyword={setKeyword}
    />
  );
};

export default DCustomersSelect;
