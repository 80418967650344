import React from "react";
import "./TruckGPSStatusField.scss";

import { useTranslation } from "react-i18next";
import { FiCheck, FiX } from "react-icons/fi";

const TruckGPSStatusField = ({ check }) => {
  const { t } = useTranslation();

  return (
    <td id='TruckGPSStatusField'>
      {check ? (
        <div className='TruckGPSStatusField'>
          <div className='icon'>
            <FiCheck />
          </div>
          <p>{t("tms.tables.others.Matched")}</p>
        </div>
      ) : (
        <div className='nocheck TruckGPSStatusField'>
          <div className='icon'>
            <FiX />
          </div>
          <p>{t("tms.tables.others.Not matched")}</p>
        </div>
      )}
    </td>
  );
};

export default TruckGPSStatusField;
