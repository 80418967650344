import React from "react";
import "./TrackingDriverData.scss";

import { useTranslation } from "react-i18next";

import Character from "../../../UI/Character/Character";
import { useConvertShippingType } from "../../../../helper/useConvertShippingType";

const TrackingDriverData = ({ order }) => {
  const { t } = useTranslation();
  const { shippingTypeIcon } = useConvertShippingType(order?.type || null);

  return (
    <div id='ShipperTrackingSectionDrivers'>
      <div className='item'>
        <div className='icon'>{shippingTypeIcon}</div>
        <div className='content'>
          {order?.transports?.length > 0 ? (
            <ul>
              {order?.transports.map((transport) => (
                <li key={transport?.id}>{transport?.number}</li>
              ))}
            </ul>
          ) : (
            <h6>
              <Character max={30} title={t("shipper.forms.content.No information")} />
            </h6>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackingDriverData;
