import React from "react";
import "./ContactFreightCover.scss";

const ContactFreightCover = ({ url }) => {
  return (
    <div
      id='ContactFreightCover'
      style={{
        backgroundImage: `url('${url}')`,
      }}
    ></div>
  );
};

export default ContactFreightCover;
