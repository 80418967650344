import React from "react";

import DOverlay from "../../../../componentsDashboard/Modal/DOverlay/DOverlay";
import CarrierSubscribeForm from "../CarrierSubscribeForm/CarrierSubscribeForm";

const SubscribeModal = ({ isOpen, isClose }) => {
  return (
    <DOverlay isOpen={isOpen} isClose={isClose}>
      <CarrierSubscribeForm />
    </DOverlay>
  );
};

export default SubscribeModal;
