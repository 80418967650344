import React from "react";
import "./TrackingTruckList.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import TrackingTruckListItem from "./TrackingTruckListItem/TrackingTruckListItem";
import DSearch from "../../../FilterForms/DSearch/DSearch";
import LoadingPage from "../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";

const TrackingTruckList = ({
  keyword,
  setKeyword,
  items,
  activeItem,
  setActiveItem,
}) => {
  const { t } = useTranslation();
  const { getTrackingLoading } = useSelector((state) => state.trackerManager);

  return (
    <div id='TrackingTruckList'>
      <div className='filterHeader'>
        <DSearch value={keyword} setValue={setKeyword} />
        <div className='TrackingTruckListSelect'></div>
      </div>

      {getTrackingLoading && <LoadingPage />}

      {items && (
        <div className='TrackingTruckListContainer'>
          {items.filter((item) => item.location !== null).length > 0 ? (
            <ul>
              {items.map((item, index) => (
                <TrackingTruckListItem
                  key={index}
                  item={item}
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                />
              ))}
            </ul>
          ) : (
            <h3 className='NoTrucksList'>
              {t("tms.desc.Not available to track")}
            </h3>
          )}
        </div>
      )}
    </div>
  );
};

export default TrackingTruckList;
