import React from "react";
import "./ShipperCalendarTable.scss";

import { useTranslation } from "react-i18next";

import ShipperCalendarOrder from "../ShipperCalendarOrder/ShipperCalendarOrder";

const ShipperCalendarTable = ({ orders, startDate }) => {
  const { t } = useTranslation();
  return (
    <div id='ShipperCalendarTable'>
      {Array.from({ length: 14 }).map((_, index) => (
        <div key={index} className='day' style={{ left: `${(100 / 14) * index}%` }} />
      ))}

      {orders?.length > 0 ? (
        orders?.map((order, index) => (
          <div key={index} className='orderContainer'>
            <ShipperCalendarOrder order={order} index={index} startDate={startDate} />
          </div>
        ))
      ) : (
        <div className='noOrders'>
          <h1>{t(`shipper.table.desc.No orders in this range`)}</h1>
        </div>
      )}
    </div>
  );
};

export default ShipperCalendarTable;
