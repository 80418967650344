import React from "react";
import "./DCheckBox.scss";

import { FiCheck } from "react-icons/fi";

const DCheckBox = ({ value, setValue, label }) => {
  return (
    <div id='DCheckBox' className={value ? "checked" : ""}>
      <div
        className='checkbox'
        onClick={() => {
          setValue((state) => !state);
        }}
      >
        <div className={value ? "checked icon" : "icon"}>
          {value && <FiCheck />}
        </div>
        <p>{label}</p>
      </div>
    </div>
  );
};

export default DCheckBox;
