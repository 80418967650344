import React from "react";
import "./SideBarSticky.scss";

import { useSelector } from "react-redux";

const SideBarSticky = () => {
  const { openSidebar } = useSelector((state) => state.userUI);

  return <div id='SideBarSticky' className={openSidebar ? "max" : "min"}></div>;
};

export default SideBarSticky;
