import React, { useState, useEffect } from "react";
import "./PartnerFleetTable.scss";

import { useTranslation } from "react-i18next";

import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getPartners, resetGetPartners } from "../../../../../store/partner/partnerSlice";
import { getOrderFilters } from "../../../../../store/orders/ordersSlice";

import AssignPartnerTable from "./AssignPartnerTable/AssignPartnerTable";
import SendBidTable from "./SendBidTable/SendBidTable";

const PartnerFleetTable = ({
  setBidScreen,
  isOpen,
  isClose,
  order,
  tab,
  setTab,
  partnerTab,
  setPartnerTab,
  partnersBids,
  setPartnersBids,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1);
  const [keyword, setKeyword] = useState("");
  const [trailerTypeFilter, setTrailerTypeFilter] = useState(null);
  const [routesFilter, setRoutesFilter] = useState(null);
  const [regionFilter, setRegionFilter] = useState(null);

  const { createPartnerResult } = useSelector((state) => state.partner);

  useEffect(() => {
    const data = {
      page,
      keyword,
      orderId: order?.id,
      route_id: routesFilter,
      region_id: regionFilter,
      trailer_type_id: trailerTypeFilter,
    };
    if (isOpen && tab === "partnerFleet") {
      dispatch(getPartners(data));
    }
    return () => {
      dispatch(resetGetPartners());
    };
  }, [
    dispatch,
    keyword,
    tab,
    page,
    isOpen,
    order?.id,
    createPartnerResult,
    routesFilter,
    trailerTypeFilter,
    regionFilter,
  ]);

  useEffect(() => {
    if (isOpen && tab === "partnerFleet") {
      dispatch(getOrderFilters(order?.id));
    }
  }, [dispatch, isOpen, tab, order?.id]);

  return (
    <div id='PartnerFleet'>
      <p className='PartnerFleetDescription'>
        {t(
          "tms.desc.Assign partner (then carrier) by selecting it or you can send bids to “Auction to partners” page, wait for them to offer there prices and then decide who you want to assign."
        )}
      </p>
      <div className='PartnerFleetSwitcher'>
        <div
          className={partnerTab === "assign" ? "item active" : "item"}
          onClick={() => setPartnerTab("assign")}
        >
          {t("tms.menu.Assign partner")}
        </div>
        <div
          className={partnerTab === "bid" ? "item active" : "item"}
          onClick={() => setPartnerTab("bid")}
        >
          {t("tms.menu.Auction to partners")}
        </div>
      </div>
      {partnerTab === "assign" ? (
        <AssignPartnerTable
          setBidScreen={setBidScreen}
          isOpen={isOpen}
          isClose={isClose}
          order={order}
          tab={tab}
          setTab={setTab}
          partnerTab={partnerTab}
          setPartnerTab={setPartnerTab}
          page={page}
          setPage={setPage}
          keyword={keyword}
          setKeyword={setKeyword}
          partnersBids={partnersBids}
          setPartnersBids={setPartnersBids}
          trailerTypeFilter={trailerTypeFilter}
          setTrailerTypeFilter={setTrailerTypeFilter}
          routeFilter={routesFilter}
          setRouteFilter={setRoutesFilter}
          regionFilter={regionFilter}
          setRegionFilter={setRegionFilter}
        />
      ) : (
        <SendBidTable
          setBidScreen={setBidScreen}
          isOpen={isOpen}
          isClose={isClose}
          order={order}
          tab={tab}
          setTab={setTab}
          partnerTab={partnerTab}
          setPartnerTab={setPartnerTab}
          page={page}
          setPage={setPage}
          keyword={keyword}
          setKeyword={setKeyword}
          partnersBids={partnersBids}
          setPartnersBids={setPartnersBids}
          trailerTypeFilter={trailerTypeFilter}
          setTrailerTypeFilter={setTrailerTypeFilter}
          routeFilter={routesFilter}
          setRouteFilter={setRoutesFilter}
          regionFilter={regionFilter}
          setRegionFilter={setRegionFilter}
        />
      )}
    </div>
  );
};

export default PartnerFleetTable;
