import React, { useState, Fragment, useEffect } from "react";
import "./EditExpensesModal.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FiEdit2 } from "react-icons/fi";

import {
  resetUpdateMaintenance,
  updateMaintenance,
} from "../../../../store/fleet/maintenance/maintenanceSlice";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";
import DCheckBox from "../../../Form/DCheckBox/DCheckBox";
import DDatepicker from "../../../Form/DDatepicker/DDatepicker";
import DCurrencyInput from "../../../Form/DCurrencyInput/DCurrencyInput";

const EditExpensesModal = ({ isOpen, isClose, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateMaintenanceLoading, updateMaintenanceResult, updateMaintenanceError } = useSelector(
    (state) => state.maintenance
  );

  const { getCurrenciesSelect } = useSelector((state) => state.settings);

  const [currencyValue, setCurrencyValue] = useState(null);
  const [service, setService] = useState(item?.service);
  const [serviceProvider, setServiceProvider] = useState(item?.provider);
  const [cost, setCost] = useState(item?.cost);
  const [downtimeShow, setDowntimeShow] = useState(
    item.downtime_end || item.downtime_start ? true : false
  );
  const [startDate, setStartDate] = useState(
    item.downtime_start ? moment(item.downtime_start, "YYYY-MM-DD").toDate() : null
  );
  const [endDate, setEndDate] = useState(
    item.downtime_end ? moment(item.downtime_end, "YYYY-MM-DD").toDate() : null
  );

  const submitHandler = () => {
    const data = {
      id: item.id,
      service,
      provider: serviceProvider,
      cost,
      currency_id: currencyValue && currencyValue.value.id,
      downtime_start: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      downtime_end: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
    };
    dispatch(updateMaintenance(data));
  };

  useEffect(() => {
    if (getCurrenciesSelect) {
      const findSelectedCurrency = getCurrenciesSelect.find(
        (currency) => currency.value.id.toString() === item.currency.id.toString()
      );
      setCurrencyValue(findSelectedCurrency);
    }
  }, [getCurrenciesSelect, item]);

  useEffect(() => {
    if (updateMaintenanceResult) {
      dispatch(resetUpdateMaintenance());
      isClose();
    }
  }, [dispatch, isClose, updateMaintenanceResult]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Edit Expense")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiEdit2 />}
        status='success'
        onClick={() => submitHandler()}
        loading={updateMaintenanceLoading}
      >
        <div id='EditExpensesModal'>
          <div className='EditExpensesModalGroup'>
            <DInputs
              label={t("tms.modals.labels.Service")}
              placeholder={t("tms.modals.placeholder.Enter service")}
              value={service}
              setValue={setService}
              id='chooseService'
              required
              error={updateMaintenanceError?.errors?.service?.[0] ?? null}
            />
            <DInputs
              label={t("tms.modals.labels.Service provider")}
              placeholder={t("tms.modals.placeholder.Enter service provider")}
              id='serviceProvider'
              value={serviceProvider}
              setValue={setServiceProvider}
              required
              error={updateMaintenanceError?.errors?.provider?.[0] ?? null}
            />
            <DCurrencyInput
              label={t("tms.modals.labels.Cost")}
              placeholder={t("tms.modals.placeholder.Enter cost")}
              id='expenseCost'
              value={cost}
              setValue={setCost}
              currencyValue={currencyValue}
              setCurrencyValue={setCurrencyValue}
              required
              error={updateMaintenanceError?.errors?.cost?.[0] ?? null}
            />
            <div className='groupItem'>
              <DCheckBox
                value={downtimeShow}
                setValue={setDowntimeShow}
                label={t("tms.modals.labels.Downtime")}
              />
              {downtimeShow && (
                <div className='downtimeContainer'>
                  <DDatepicker
                    label={t("tms.modals.labels.Start date")}
                    placeholder={t("tms.modals.placeholder.Start date")}
                    value={startDate}
                    setValue={setStartDate}
                    error={updateMaintenanceError?.errors?.downtime_start?.[0] ?? null}
                  />
                  <DDatepicker
                    label={t("tms.modals.labels.End date")}
                    placeholder={t("tms.modals.placeholder.End date")}
                    value={endDate}
                    setValue={setEndDate}
                    error={updateMaintenanceError?.errors?.downtime_end?.[0] ?? null}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditExpensesModal;
