import React from "react";
import "./Dashboard.scss";

import { useTranslation } from "react-i18next";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import ShipperOverviewHeader from "./../../../../componentsDashboard/ShipperOverview/Components/ShipperOverviewHeader/ShipperOverviewHeader";
import ShipperCards from "../../../../componentsDashboard/ShipperOverview/ShipperCards/ShipperCards";
import ShipperCharts from "../../../../componentsDashboard/ShipperOverview/ShipperCharts/ShipperCharts";
import ActivityTable from "../../../../componentsDashboard/ShipperOverview/ActivityTable/ActivityTable";
import QuotesTable from "../../../../componentsDashboard/ShipperOverview/QuotesTable/QuotesTable";

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <Wrapper documentTitle={t("shipper.titles.Overview")}>
      <section id='SDashboard'>
        <ShipperOverviewHeader />
        <div className='SDashboardStatistic'>
          <ShipperCards />
          <ShipperCharts />
        </div>
        <div className='SDashboardTables'>
          <ActivityTable />
          <QuotesTable />
        </div>
      </section>
    </Wrapper>
  );
};

export default Dashboard;
