import React, { Fragment, useState } from "react";
import "./SendInvitationButton.scss";

import { useTranslation } from "react-i18next";
import { MdOutlineEmail } from "react-icons/md";

import DButton from "../../../../Form/DButtons/DButton/DButton";
import SendInvitationModal from "./SendInvitationModal/SendInvitationModal";

const SendInvitationButton = ({ driver }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <td id='SendInvitationButton'>
        <DButton
          title={t("tms.tables.buttons.Send invitation")}
          rightIcon={<MdOutlineEmail />}
          onClick={() => setOpen(true)}
        />
      </td>
      {open && (
        <SendInvitationModal
          driverId={driver.id}
          isOpen={open}
          isClose={() => setOpen(false)}
        />
      )}
    </Fragment>
  );
};

export default SendInvitationButton;
