import React from "react";
import "./MobileHeader.scss";

import ViewInstructionButton from "../ViewInstructionButton/ViewInstructionButton";

const MobileHeader = ({ FILTERS, active, setActive }) => {
    
  return (
    <div id='ConnectMobileHeader'>
      <div className='ConnectMobileFilters'>
        {FILTERS.map((filter) => (
          <div
            key={filter.id}
            className={active.id === filter.id ? "item active" : "item"}
            onClick={() => {
              setActive(filter);
            }}
          >
            {filter.name}
          </div>
        ))}
      </div>
      <ViewInstructionButton />
    </div>
  );
};

export default MobileHeader;
