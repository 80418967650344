import React from "react";
import "./RoadWeightGroupItem.scss";

import { useTranslation } from "react-i18next";

import DInputs from "../../../../../../../Form/DInputs/DInputs";

const RoadWeightGroupItem = ({ index, item, group, setGroup, totalWeight }) => {
  const { t } = useTranslation();

  const weigthHandler = (value) => {
    const updatedGroup = [...group];
    updatedGroup[index].weight = value;
    setGroup(updatedGroup);
  };

  return (
    <div id='RoadWeightGroupItem'>
      <h4>
        #{index + 1} <span>{item?.trailerType?.value?.name || ""}</span>
      </h4>

      <div className='formGroup'>
        <div className={item.weightError ? "error formGrouptem" : "formGrouptem"}>
          <DInputs
            type='number'
            value={item.weight}
            setValue={(value) => weigthHandler(value)}
          />
          <h6>{t("shipper.units.KG")}</h6>
        </div>
      </div>
    </div>
  );
};

export default RoadWeightGroupItem;
