import React from "react";
import "./NavItemMobile.scss";

import { NavLink } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";

const NavItemMobile = ({ title, to }) => {
  return (
    <li id='NavItemMobile'>
      <NavLink to={to}>
        <div className='item'>
          <p>{title}</p>
          <div className='arrow'>
            <FiChevronRight />
          </div>
        </div>
      </NavLink>
    </li>
  );
};

export default NavItemMobile;
