import React, { Fragment, useEffect, useState } from "react";
import "./RejectOrderList.scss";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

import {
  getShipperRejectOrders,
  resetGetShipperRejectOrders,
} from "../../../../../../store/orders/ordersSlice";

import Wrapper from "../../../../../../componentsAdditional/Wrapper";
import PageSwitcher from "../../../../../../componentsDashboard/PageSwitcher/PageSwitcher";
import ShipperOrderListHeader from "../../../../../../componentsDashboard/ShipperOrderList/ShipperOrderListHeader/ShipperOrderListHeader";
import LoadingPage from "../../../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import EmptyList from "../../../../../../componentsDashboard/UI/EmptyList/EmptyList";
import ShipperRejectOrdersTable from "../../../../../../componentsDashboard/ShipperOrderList/ShipperRejectOrdersTable";
import MainTitle from "../../../../../../componentsDashboard/MainTitle/MainTitle";
import BookModal from "../../../../../../componentsDashboard/ShipperHeader/Components/BookShipment/BookModal/BookModal";
import PageNotFound from "../../../../../../componentsBids/PageNotFound/PageNotFound";
import AddVatModal from "../../../../../../componentsDashboard/LoadBoards/Modals/AddVatModal/AddVatModal";

const RejectOrderList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const {
    getShipperRejectOrdersLoading,
    getShipperRejectOrdersResult,
    getShipperRejectOrdersMeta,
    getShipperRejectOrdersError,
  } = useSelector((state) => state.orders);
  const { rejectOrderResult, takeOrderResult } = useSelector((state) => state.shipperOrders);

  const { getShipperSummaryLoading, getShipperSummaryResult } = useSelector(
    (state) => state.summary
  );
  const { user } = useSelector((state) => state.auth);

  const hasVat = user?.company?.legal_id;

  const [showVatModal, setShowVatModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [keyword, setKeyword] = useState("");
  const [shippingType, setShippingType] = useState(null);
  const [dateRange, setDateRange] = useState({});
  const [route, setRoute] = useState({});
  const [trailerType, setTrailerType] = useState(null);
  const [productCategory, setProductCategory] = useState(null);

  const clearFilters = () => {
    if (keyword) setKeyword("");
    if (shippingType) setShippingType(null);
    if (Object.keys(dateRange).length > 0) setDateRange({});
    if (Object.keys(route).length > 0) setRoute({});
    if (trailerType) setTrailerType(null);
    if (productCategory) setProductCategory(null);
  };

  useEffect(() => {
    const data = {
      page,
      keyword,
      pickup_date_min:
        Object.keys(dateRange).length > 0 && dateRange?.startDate
          ? moment(dateRange.startDate).format("YYYY/MM/DD")
          : null,
      pickup_date_max:
        Object.keys(dateRange).length > 0 && dateRange?.endDate
          ? moment(dateRange.endDate).format("YYYY/MM/DD")
          : null,
      pickup_address: route?.fromCountry?.label || null,
      dropoff_address: route?.toCountry?.label || null,
      trailer_type_id: trailerType?.value?.id || null,
      cargo_type_id: productCategory?.value?.id || null,
      type_groups: shippingType ? [shippingType] : null,
    };
    dispatch(getShipperRejectOrders(data));
    return () => {
      dispatch(resetGetShipperRejectOrders());
    };
  }, [
    dispatch,
    page,
    keyword,
    dateRange,
    trailerType,
    productCategory,
    route,
    shippingType,
    rejectOrderResult,
    takeOrderResult,
  ]);

  return (
    <Wrapper documentTitle={t("shipper.titles.Declined")}>
      <section id='RejectOrderList'>
        <MainTitle title={t("shipper.titles.My bookings")} />
        <PageSwitcher
          name={"shipperOrdersReject"}
          badge={getShipperSummaryResult?.active_quotations_count}
        />
        {getShipperSummaryLoading ? (
          <LoadingPage />
        ) : getShipperSummaryResult?.rejected_quotations_count === 0 ? (
          <EmptyList
            image={"/assets/images/dashboard/cover1.svg"}
            title={t("shipper.titles.No bookings")}
            description={t("shipper.desc.Add booking and take full control of your orders")}
            onClick={() => (hasVat ? setShowCreateModal(true) : setShowVatModal(true))}
            buttonText={t("shipper.buttons.New request")}
            icon={<FiPlus />}
          />
        ) : (
          <Fragment>
            <ShipperOrderListHeader
              keyword={keyword}
              setKeyword={setKeyword}
              shippingType={shippingType}
              setShippingType={setShippingType}
              dateRange={dateRange}
              setDateRange={setDateRange}
              route={route}
              setRoute={setRoute}
              trailerType={trailerType}
              setTrailerType={setTrailerType}
              productCategory={productCategory}
              setProductCategory={setProductCategory}
              onClear={clearFilters}
            />
            {getShipperRejectOrdersLoading && <LoadingPage />}
            {getShipperRejectOrdersError && <PageNotFound />}
            {getShipperRejectOrdersResult && (
              <ShipperRejectOrdersTable
                orders={getShipperRejectOrdersResult}
                page={page}
                setPage={setPage}
                pageCount={getShipperRejectOrdersMeta.last_page}
                total={getShipperRejectOrdersMeta.total}
              />
            )}
          </Fragment>
        )}
        {showCreateModal && (
          <BookModal isOpen={showCreateModal} isClose={() => setShowCreateModal(false)} />
        )}
        {showVatModal && (
          <AddVatModal isOpen={showVatModal} isClose={() => setShowVatModal(false)} />
        )}
      </section>
    </Wrapper>
  );
};

export default RejectOrderList;
