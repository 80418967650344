import React, { useEffect, useRef, useState } from "react";
import "./TimeSelectDropdown.scss";

import { useTranslation } from "react-i18next";

import { FaRegClock } from "react-icons/fa6";

import DLabel from "../../../../../Form/DLabel/DLabel";
import DSelect from "../../../../../Form/DSelect/DSelect";
import DButton from "../../../../../Form/DButtons/DButton/DButton";

const timeOptions = [
  { value: "00:00", label: "00:00" },
  { value: "01:00", label: "01:00" },
  { value: "02:00", label: "02:00" },
  { value: "03:00", label: "03:00" },
  { value: "04:00", label: "04:00" },
  { value: "05:00", label: "05:00" },
  { value: "06:00", label: "06:00" },
  { value: "07:00", label: "07:00" },
  { value: "08:00", label: "08:00" },
  { value: "09:00", label: "09:00" },
  { value: "10:00", label: "10:00" },
  { value: "11:00", label: "11:00" },
  { value: "12:00", label: "12:00" },
  { value: "13:00", label: "13:00" },
  { value: "14:00", label: "14:00" },
  { value: "15:00", label: "15:00" },
  { value: "16:00", label: "16:00" },
  { value: "17:00", label: "17:00" },
  { value: "18:00", label: "18:00" },
  { value: "19:00", label: "19:00" },
  { value: "20:00", label: "20:00" },
  { value: "21:00", label: "21:00" },
  { value: "22:00", label: "22:00" },
  { value: "23:00", label: "23:00" },
];

const TimeSelectDropdown = ({ time, setTime }) => {
  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (time?.length > 0) {
      setStartTime({ label: time[0], value: time[0] });
      setEndTime({ label: time[1], value: time[1] });
    }
  }, [time]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };
    setTimeout(() => {
      document.addEventListener("mouseup", handleOutsideClick);
    }, 0);

    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [dropdownOpen, setDropdownOpen]);

  return (
    <div id='TimeSelectDropdown'>
      <DLabel label={t("tms.forms.labels.Time")} />
      <div className={`dropdown ${dropdownOpen ? "open" : ""}`}>
        <div
          className='dropdown-container'
          onMouseUp={() =>
            setDropdownOpen((prevDropdownOpen) => !prevDropdownOpen)
          }
        >
          <input
            className='dropdownTitle'
            readOnly
            placeholder={t("tms.forms.placeholder.Select time")}
            value={time?.length > 0 ? `${time[0]} - ${time[1]}` : ""}
          />
          <div className='dropdownIcon'>
            <FaRegClock />
          </div>
        </div>
        <div ref={dropdownRef} className='dropdown-content'>
          <div className='dropdownItems'>
            <DSelect
              id={"PickUpStartTime"}
              label={t("tms.forms.labels.Start time")}
              placeholder={t("tms.forms.placeholder.Select time")}
              value={startTime}
              setValue={setStartTime}
              clear
              separator
              options={timeOptions}
            />
            <DSelect
              id={"PickUpEndTime"}
              label={t("tms.forms.labels.End time")}
              placeholder={t("tms.forms.placeholder.Select time")}
              value={endTime}
              setValue={setEndTime}
              clear
              separator
              options={timeOptions}
            />
            <div className='dropdownSaveButton'>
              <DButton
                id={"PickUpTimeSave"}
                title={t("tms.forms.buttons.Save")}
                disabled={!startTime || !endTime}
                onClick={() => {
                  setTime([startTime?.value, endTime?.value]);
                  setDropdownOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSelectDropdown;
