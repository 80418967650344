import React, { Fragment, useState, useEffect } from "react";
import "./DAttachemntModal.scss";

import { FiFile, FiTrash2 } from "react-icons/fi";
import { MdUpload } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { IoDocumentTextOutline } from "react-icons/io5";

import {
  temporaryAttachment,
  resetTemporaryAttachment,
} from "../../../store/orders/attachments/attachmentsSlice";

import DModal from "./../../Modal/DModal/DModal";
import DErrorText from "./../DErrorText/DErrorText";
import Character from "../../UI/Character/Character";

const DAttachemntModal = ({ isOpen, isClose, title, subTitle, type_id, value, setValue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { temporaryAttachmentLoading, temporaryAttachmentResult, temporaryAttachmentError } =
    useSelector((state) => state.attachments);

  const [file, setFile] = useState(null);

  useEffect(() => {
    if (temporaryAttachmentResult) {
      setFile(null);
      setValue([...value, temporaryAttachmentResult]);
      dispatch(resetTemporaryAttachment());
      isClose();
    }
  }, [dispatch, isClose, value, setValue, temporaryAttachmentResult]);

  useEffect(() => {
    return () => {
      dispatch(resetTemporaryAttachment());
    };
  }, [dispatch]);

  const submitHandler = () => {
    const data = {
      type_id: type_id,
      document: file,
    };
    dispatch(temporaryAttachment(data));
  };

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={isClose}
        title={title}
        icon={<FiFile />}
        status='success'
        onClick={() => submitHandler()}
        submitText={t("tms.modals.buttons.Save")}
        loading={temporaryAttachmentLoading}
      >
        <div id='DAttachemntModal'>
          <div className='DAttachemntModalgroup'>
            {file ? (
              <div id='DFileFormItemModal' className={temporaryAttachmentError ? "error" : ""}>
                <div className='Document'>
                  <div className='title'>
                    <div className='fileIcon'>
                      <IoDocumentTextOutline />
                    </div>

                    <h6>
                      <Character max={15} title={file?.name || "-"} />
                    </h6>
                  </div>

                  <div
                    className='removeIcon'
                    onClick={() => {
                      setFile(null);
                      dispatch(resetTemporaryAttachment());
                    }}
                  >
                    <FiTrash2 />
                  </div>
                </div>
                <DErrorText
                  error={
                    (temporaryAttachmentError?.errors?.type_id?.[0] ?? null) ||
                    (temporaryAttachmentError?.message ?? null) ||
                    (temporaryAttachmentError === "Network Error" &&
                      t("shipper.modal.Please reduce the size of all files"))
                  }
                />
              </div>
            ) : (
              <div id='DFileFormModal' className={temporaryAttachmentError ? "error" : ""}>
                <div className='noDocument'>
                  <div className='icon'>
                    <MdUpload />
                  </div>
                  <div className='noDocumentcontent'>
                    <h3>{title}</h3>
                    <h4>{subTitle}</h4>
                  </div>
                  <input
                    type='file'
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                      dispatch(resetTemporaryAttachment());
                    }}
                  />
                </div>
                <DErrorText
                  error={
                    (temporaryAttachmentError?.errors?.type_id?.[0] ?? null) ||
                    (temporaryAttachmentError?.message ?? null) ||
                    (temporaryAttachmentError === "Network Error" &&
                      t("shipper.modal.Please reduce the size of all files"))
                  }
                />
              </div>
            )}
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default DAttachemntModal;
