import React, { useState } from "react";

import { useSelector } from "react-redux";

import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./ShipperCardsCalendar.scss";

import ShipperCardSelectDate from "./ShipperCardSelectDate/ShipperCardSelectDate";

const ShipperCardsCalendar = ({ date, setDate }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const handleDateChange = (date) => {
    setDate(date);
    setIsOpen(false);
  };

  return (
    <div id='ShipperCardsCalendar'>
      <ShipperCardSelectDate
        date={date}
        setDate={setDate}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        placeholder={moment(date).format("MMMM")}
      >
        <Calendar
          className='calendar'
          onChange={handleDateChange}
          value={date}
          minDetail='year'
          maxDetail='year'
          formatMonth={(_, date) => moment(date).format("MMM")}
          tileDisabled={({ date, view }) =>
            view === "year" &&
            (date.getFullYear() > new Date().getFullYear() ||
              (date.getFullYear() === new Date().getFullYear() &&
                date.getMonth() > new Date().getMonth()))
          }
        />
      </ShipperCardSelectDate>
    </div>
  );
};

export default ShipperCardsCalendar;
