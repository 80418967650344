import React, { Fragment, useEffect, useState } from "react";
import "./ShipmentUpdates.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MdOutlineUpdate, MdPinDrop, MdLocationOn, MdTimelapse } from "react-icons/md";
import { IoSend } from "react-icons/io5";
import { BiSolidCommentAdd, BiSolidCommentMinus } from "react-icons/bi";

import {
  getOrderStatusUpdates,
  getRoadStatuses,
  resetGetOrderStatusUpdates,
  resetStoreStatusUpdate,
  storeStatusUpdate,
} from "../../../store/statusUpdates/statusUpdatesSlice";

import DButton from "../../Form/DButtons/DButton/DButton";
import DSelect from "../../Form/DSelect/DSelect";
import DInputs from "../../Form/DInputs/DInputs";
import DDatepicker from "../../Form/DDatepicker/DDatepicker";
import DTextarea from "../../Form/DTextarea/DTextarea";
import ButtonLoader from "../../Form/DButtons/ButtonLoader/ButtonLoader";
import StatusHistoryModal from "../StatusHistoryModal/StatusHistoryModal";

const ShipmentUpdates = ({ order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    getRoadStatusesSelect,
    getRoadStatusesResult,
    storeStatusUpdateResult,
    storeStatusUpdateLoading,
    storeStatusUpdateError,
    getOrderStatusUpdatesResult,
  } = useSelector((state) => state.statusUpdates);

  const [showStatusHistoryModal, setShowStatusHistoryModal] = useState(false);

  const [status, setStatus] = useState(null);
  const [date, setDate] = useState(null);
  const [location, setLocation] = useState("");

  const [openComment, setOpenComment] = useState(false);
  const [comment, setComment] = useState("");

  const handleUpdate = () => {
    const data = [
      {
        order_id: order.id,
        order_type_group_id: 1,
        location,
        status_id: status?.value?.id,
        comment: comment || null,
        est: moment(date).format("YYYY-MM-DD"),
      },
    ];
    dispatch(storeStatusUpdate(data));
  };

  useEffect(() => {
    if (storeStatusUpdateResult) {
      dispatch(resetStoreStatusUpdate());
      setStatus(null);
      setDate(null);
      setLocation("");
      setComment("");
    }
  }, [dispatch, storeStatusUpdateResult]);

  useEffect(() => {
    dispatch(getOrderStatusUpdates(order.id));
    return () => {
      dispatch(resetGetOrderStatusUpdates());
    };
  }, [dispatch, order.id, storeStatusUpdateResult]);

  useEffect(() => {
    if (!getRoadStatusesResult) {
      dispatch(getRoadStatuses());
    }
  }, [dispatch, getRoadStatusesResult]);

  return (
    <Fragment>
      <div id='ShipmentUpdates'>
        <div className='ShipmentUpdatesHeader'>
          <div className='icon'>
            <MdOutlineUpdate />
          </div>
          <div className='titleContainer'>
            <h1>{t("tms.titles.Shipment update")}</h1>
            {order?.statusTms?.id > 2 && (
              <DButton
                title={t("tms.forms.buttons.Show status history")}
                rightIcon={<MdOutlineUpdate />}
                onClick={() => setShowStatusHistoryModal(true)}
              />
            )}
          </div>
        </div>
        <div className='container'>
          <div className='content'>
            {order?.statusTms?.id === 3 ? (
              <Fragment>
                <div className='formContainer'>
                  <div className='selectContainer'>
                    <DSelect
                      value={status}
                      setValue={setStatus}
                      placeholder={t("tms.forms.placeholder.Select status")}
                      options={getRoadStatusesSelect || []}
                    />
                  </div>
                  <div className='inputContainer'>
                    <DInputs
                      required
                      placeholder={t("tms.forms.placeholder.Type Current location")}
                      value={location}
                      setValue={setLocation}
                      icon={<MdPinDrop />}
                      error={storeStatusUpdateError?.errors?.["0.location"]?.[0]}
                    />
                    <DDatepicker
                      placeholder={t("tms.forms.placeholder.Estimated date")}
                      value={date}
                      setValue={setDate}
                      icon={<MdPinDrop />}
                    />
                  </div>
                  <div className='commentContainer'>
                    <div
                      className='commentButton'
                      onClick={() => setOpenComment((state) => !state)}
                    >
                      <div className='icon'>
                        {openComment ? <BiSolidCommentMinus /> : <BiSolidCommentAdd />}
                      </div>
                      <div className='text'>{t("tms.forms.buttons.Add comment")}</div>
                    </div>
                    {openComment && (
                      <div className='commentInput'>
                        <DTextarea
                          value={comment}
                          setValue={setComment}
                          placeholder={t("tms.forms.placeholder.Your text ...")}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`buttonContainer ${!status || !location || !date ? "disabled" : ""}`}
                  style={{ marginBottom: openComment ? "2px" : "28px" }}
                  onClick={() => (!status || !location || !date ? {} : handleUpdate())}
                >
                  {storeStatusUpdateLoading ? <ButtonLoader /> : <IoSend />}
                </div>
              </Fragment>
            ) : (
              <div className='noProperStatusMessage'>
                {t(
                  "tms.forms.noInfo.Order need to be on 'Pick-Up' status to send shipment updates."
                )}
              </div>
            )}
          </div>
        </div>
        <div className='updatesHeader'>{t("tms.forms.labels.Last update")}</div>
        <div className='updatesContainer'>
          {getOrderStatusUpdatesResult?.length > 0 ? (
            <div className='content'>
              <div className='topContainer'>
                <div className='titleDetailsContainer'>
                  <div className='title'>{getOrderStatusUpdatesResult?.[0]?.status}</div>
                  <div
                    className='status'
                    style={{
                      background: order?.statusTms?.color2,
                      color: order?.statusTms?.color1,
                    }}
                  >
                    {order?.statusTms?.name}
                  </div>
                </div>
              </div>

              <div className='bottomContainer'>
                <div className='leftContainer'>
                  <div className='itemsContainer'>
                    <div className='icon'>{<MdLocationOn />}</div>
                    <div className='text'>
                      {getOrderStatusUpdatesResult?.[0]?.location?.formatted_address}
                    </div>
                  </div>
                  <div className='itemsContainer'>
                    <div className='icon'>
                      <MdTimelapse />
                    </div>
                    <div className='text'>{getOrderStatusUpdatesResult?.[0]?.est}</div>
                  </div>
                </div>
                <div className='date'>
                  {moment(getOrderStatusUpdatesResult?.[0]?.create_date).format("YYYY-MM-DD HH:mm")}
                </div>
              </div>
            </div>
          ) : (
            <div className='noUpdates'>{t("tms.forms.noInfo.No updates")}</div>
          )}
        </div>
      </div>
      <StatusHistoryModal
        isOpen={showStatusHistoryModal}
        isClose={() => setShowStatusHistoryModal(false)}
      />
    </Fragment>
  );
};

export default ShipmentUpdates;
