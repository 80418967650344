import React from "react";
import "./ContainerTrackingItemEvent.scss";
import { useTrackingEvent } from "../../../../../../helper/useTrackingEvent";

const ContainerTrackingItemEvent = ({ event }) => {
  const eventName = useTrackingEvent(event?.event_type_code);
  const vesselInfo =
    event?.vessel_name && event?.event_type_code
      ? `${event?.vessel_name} (${eventName})`
      : event?.vessel_name
      ? event?.vessel_name
      : event?.event_type_code
      ? eventName
      : "";

  return (
    <div className='ContainerTrackingItemEvent'>
      <div className='event'>{vesselInfo}</div>
      <div className='eventDate'>{event?.event_time}</div>
    </div>
  );
};

export default ContainerTrackingItemEvent;
